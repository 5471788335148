import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"

export const getPriceGroupProductsThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/get-products-by-price-group-for-toolsets",
    apiCall: Api.toolsets.getPossibleToolsetProducts,
    errorMessage: "Не удалось получить список продуктов для комплектации"
})

export const getToolsetProductsThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/get-toolset-products",
    apiCall: Api.toolsets.getToolsetProducts,
    errorMessage: "Не удалось получить список продуктов комплектации"
})

export const getAllPictogramsThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/get-all-pictograms",
    apiCall: Api.pictograms.getAllPictograms,
    errorMessage: "Не удалось получить список пиктограмм"
})

export const addProductsToToolsetThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/add-products-to-toolset",
    apiCall: Api.toolsets.addProductsToToolset,
    errorMessage: "Не удалось добавить продукты в комплектацию"
})

export const getOrReserveThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/get-or-reserve-product-group",
    apiCall: Api.productGroups.getOrReserve,
    errorMessage: "Не удалось получить информацию о группе продуктов"
})

export const isGroupContainsToolsetThunk = createAutogenApiThunk({
    typePrefix: "toolset-products/is-contains-toolset",
    apiCall: Api.productGroups.containsProduct,
    errorMessage: "Не удалось получить информацию"
})