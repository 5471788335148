import {AppState} from "../index"
import {createSelector} from "@reduxjs/toolkit"
import Constants from "../../../domain/Constants"

export class ImageTypeSelectors {
    static getFilteredImageTypes = createSelector([
        (state: AppState) => state.imageTypesState.imageTypes,
        (state: AppState) => state.imageTypesState.filter],
    (x, filter) => x
        .filter(x => x.id != Constants.YoutubeImageType)
        .filter(x => !filter.includes(x.id))
    )

    static getImageTypes = (state: AppState) => state.imageTypesState.imageTypes

    static getSelected = (state: AppState) => state.imageTypesState.selected
}