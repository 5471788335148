import {ICategoryViewModel} from "../../library/smartComponents/tables/productGroupTable/types"
import Constants from "../Constants"

export class CategoryTree {
    static searchByName(categories: ICategoryViewModel[], name: string): ICategoryViewModel[] {
        const result: ICategoryViewModel[] = []
        name = name.toLowerCase().trim()
        for (let i = 0; i < categories.length; i++) {
            CategoryTree.searchByNameInTree(categories[i], name, result)
        }
        return CategoryTree.buildTree(categories, result)
    }

    static findCategory(categories: ICategoryViewModel[], id: number): ICategoryViewModel | null {
        for (let i = 0; i < categories.length; i++) {
            const category = CategoryTree.searchInTree(categories[i], id)
            if (category != null) {
                return category
            }
        }
        return null
    }

    static getLeaves(categories: ICategoryViewModel[], id: number)
            : ICategoryViewModel[] {
        const find = this.findCategory(categories, id)
        if (find !== null)
        {
            return find.children
        }
        return []
    }

    private static buildTree(categories: ICategoryViewModel[], filteredCategories: ICategoryViewModel[]): ICategoryViewModel[] {
        const result: ICategoryViewModel[] = []
        CategoryTree.buildTreeInternal(categories, filteredCategories, result)
        return result
    }

    private static buildTreeInternal(categories: ICategoryViewModel[], filteredCategories: ICategoryViewModel[], result: ICategoryViewModel[]): void {
        for (let i = 0; i < filteredCategories.length; i++) {
            const category = filteredCategories[i]
            if (category.parentId === Constants.PrintBaseParent || category.parentId === Constants.WebBaseParent) {
                if (!result.some(x => x.id === category.id)) {
                    result.push(category)
                }
            } else {
                let parent = CategoryTree.findCategory(categories, category.parentId)
                if (parent != null) {
                    parent = {...parent, children: [category]}
                    this.buildTreeInternal(categories, [parent], result)
                }
            }
        }
    }

    private static searchByNameInTree(category: ICategoryViewModel, name: string, result: ICategoryViewModel[]): ICategoryViewModel[] {
        if (category.children != null) {
            for (let i = 0; i < category.children.length; i++) {
                CategoryTree.searchByNameInTree(category.children[i], name, result)
            }
        }
        if (category.name.toLowerCase().includes(name)) {
            result.push({...category, children: []})
        }
        return result
    }

    private static searchInTree(category: ICategoryViewModel, id: number): ICategoryViewModel | null {
        if (category.id === id) {
            return category
        } else if (category.children != null) {
            let result: ICategoryViewModel | null = null
            for (let i = 0; result == null && i < category.children.length; i++) {
                result = CategoryTree.searchInTree(category.children[i], id)
            }
            return result
        }
        return null
    }
}