import React, { useEffect } from "react"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { useDispatch, useSelector } from "react-redux"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import AdDefaultInput from "../../../library/inputs/AdDefaultInput"
import { actions } from "../../../redux/reducers/pages/productPage"
import KeysCodes from "../../../utils/KeysCodes"

export function ProductAttributesTable() {
	const product = useSelector(ProductPageSelectors.getProduct)
	if (product === null) {
		return <></>
	}

	const productAttributes = useSelector(
		ProductPageSelectors.getProductAttributes
	)
	const language = useSelector(LanguageSelectors.getSelected)
	const dispatch = useDispatch()

	useEffect(() => {
		if (product)
			dispatch(
				ProductPageThunks.getProductAttributes({
					productId: product.id,
					languageId: language.id,
				})
			)
	}, [])

	const attrOrder = useSelector(
		ProductPageSelectors.getProductAttributesOrder
	)

	const setAttributeValue = (attributeId: number, value: string) =>
		dispatch(actions.setAttributeValue({ attributeId, value }))

	const onAttributesEnter = (
		e: React.KeyboardEvent<HTMLInputElement>,
		attributeId: number,
		attributeValue: string
	) => {
		if (e.key === KeysCodes.ENTER) {
			e.preventDefault()
			dispatch(
				ProductPageThunks.saveAttributeValue({
					productGroupId: product.groupId,
					values: [
						{
							productId: product.id,
							attributeId: attributeId,
							value: attributeValue,
						},
					],
				})
			)
		}
	}

	return (
		<>
			<table className={"u-table"}>
				<thead className={"u-thead"}>
					<tr>
						{attrOrder.map((attributeId) => {
							const attribute = productAttributes.find(
								(x) => x.attributeId == attributeId
							)
							if (!attribute) return null

							return (
								<th key={attribute.attributeId}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{attribute.attributeName}
										{attribute.isImportant ? (
											<div
												className={
													"orange-circle-with-number d" +
													attribute.importantSort
												}
											>
												{attribute.importantSort ===
												1 ? (
													<SvgIcon name={"star"} />
												) : (
													attribute.importantSort
												)}
											</div>
										) : null}
									</div>
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					<tr>
						{attrOrder.map((attributeId) => {
							const attribute = productAttributes.find(
								(x) => x.attributeId == attributeId
							)
							return (
								<td
									key={attributeId}
									style={{ padding: "0.875em 0.325em" }}
								>
									<AdDefaultInput
										value={attribute?.value ?? ""}
										noControls={true}
										onChange={(e) => {
											setAttributeValue(attributeId, e)
										}}
										onKeyDown={(e) => {
											e.stopPropagation()
											onAttributesEnter(
												e,
												attributeId,
												attribute?.value ?? ""
											)
										}}
									/>
								</td>
							)
						})}
					</tr>
				</tbody>
			</table>
		</>
	)
}
