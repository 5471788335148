import React from "react"
import "./productGroupList.scss"
import ProductGroupListToolbar from "./ProductGroupListToolbar"
import ProductGroupListTable from "./ProductGroupListTable"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { addProductGroupToCatsThunk } from "../../../redux/reducers/pages/categoryPage/thunk"
import { useDispatch, useSelector } from "react-redux"
import { actions as informerActions } from "../../../redux/reducers/informer/index"
import { CategoriesPageSelector } from "../../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import { CategoriesSelectors } from "../../../redux/reducers/categories/categoriesSelectors"
import { CatalogSelectors } from "../../../redux/reducers/catalogs/catalogSelectors"
import { CatalogGroupSelectors } from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { CatalogGroup } from "../../../domain/types"
import { actions as categoryActions } from "../../../redux/reducers/categories"

export default function ProductGroupList() {
	const isShowImageSettings = useSelector(CategoriesSelectors.isShowImageSettings)

	return (
		<div className="cat-product-group-list-container u-droppod">
			<HeaderWrapper />
			{!isShowImageSettings && <ProductGroupListToolbar />}
			{!isShowImageSettings && <ProductGroupListTable />}
		</div>
	)
}

function HeaderWrapper() {
	const isAddingGroupsToCategory = useSelector(
		CategoriesPageSelector.isAddingToCategory
	)
	const selectedGroups = useSelector(CategoriesPageSelector.getSelectedGroups)
	const checkedCategories = useSelector(
		CategoriesSelectors.getCheckCategories
	)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
	const isShowImageSettings = useSelector(CategoriesSelectors.isShowImageSettings)

	const dispatch = useDispatch()

	function addProductGroupsToCats() {
		if (isAddingGroupsToCategory) return

		if (checkedCategories.isEmpty()) {
			dispatch(
				informerActions.inform(
					"Выберите категории куда переместить группы"
				)
			)
			return
		}

		if (selectedGroups.isEmpty()) {
			dispatch(informerActions.inform("Выберите группы для перемещения"))
			return
		}

		const withChildrenCategory = checkedCategories.find((x) =>
			x.children.notEmpty()
		)
		if (withChildrenCategory) {
			dispatch(
				informerActions.inform(
					"Одна из выбранных вами категорий не является категорией последнего уровня"
				)
			)
			return
		}
		if (
			checkedCategories.length > 1 &&
			catalogGroup.id == CatalogGroup.Printed
		) {
			dispatch(
				informerActions.inform(
					"Для печатных каталогов можно выбрать только одну категорию"
				)
			)
			return
		}

		dispatch(
			addProductGroupToCatsThunk({
				productGroupIds: selectedGroups.map((x) => x.id),
				catalogGroup: catalogGroup.id,
				catalogId: catalog.id,
				categoriesIds: checkedCategories.map((x) => x.id),
			})
		)
	}

	const viewSettings = () => {
		if (!isShowImageSettings)
			return (<>
				<div onClick={() => changeView(true)} className="product-group-settings">
					<SvgIcon name={"eye-close"} />
					<div>Скрыть блок</div>
				</div>
			</>);

		return (<>
			<div onClick={() => changeView(false)} className="product-group-settings">
				<SvgIcon name={"eye"} />
				<div>Показать блок</div>
			</div>
		</>)
	}

	const changeView = (view: boolean) => dispatch(categoryActions.setIsShowImageSettings(view))

	return (
		<div className="u-droppod-header">
			<div className="header-text">Группы товаров</div>
			<div>
				{viewSettings()}
			</div>
			<button
				onClick={() => addProductGroupsToCats()}
				title="Добавить выделенные карточки в категорию"
				type="button"
				className="u-button square accent"
			>
				{isAddingGroupsToCategory ? (
					<SvgIcon name="refresh-cw" className="u-rotating" />
				) : (
					<SvgIcon name="circle-plus" />
				)}
			</button>
		</div>
	)
}
