import NullableSelect from "../../../../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import React from "react"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent"
import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { ToolsetComponentSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/toolsetComponentSelectors"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

interface IToolsetPictogramControllerProps {
	toolsetProductId: string
}

export function ToolsetPictogramController(
	props: IToolsetPictogramControllerProps
) {
	const group = useSelector(GroupPageSelectors.getGroup)
	const pictograms = useSelector(ToolsetComponentSelectors.getPictograms)
	const selectedPictogram = useSelector(
		ToolsetComponentSelectors.getSelectedPictogram
	)
	const selectedToolsetProduct = useSelector(
		ToolsetComponentSelectors.getSelectedToolsetProduct
	)

	const dispatch = useDispatch()

	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlToolsetPictograms
	)

	const setSelectedPictogram = (pictogramId: number | null) => {
		dispatch(actions.setSelectedPictogram(pictogramId))
	}

	const changePictogram = () => {
		if (selectedToolsetProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для изменения"))
			return
		}
		if (selectedPictogram === null) {
			dispatch(informerActions.inform("Выберите пиктограму"))
			return
		}
		if (selectedToolsetProduct.pictogramId === selectedPictogram.id) return
		dispatch(
			ToolsetComponentThunks.changePictGroup({
				productGroupId: group.id,
				toolsetId: props.toolsetProductId,
				productId: selectedToolsetProduct.productId,
				pictogramId: selectedPictogram.id,
			})
		)
	}

	return (
		<>
			{canAccess ? (
				<>
					<NullableSelect
						value={selectedPictogram}
						options={pictograms}
						isDisabled={!canAccess}
						onChange={(value) =>
							setSelectedPictogram(value as number)
						}
						toOption={ToOptionProvider.pictogramToOption}
						className={"selector selector--name"}
						placeholder={"Выберите пиктограмму"}
					/>

					<button
						className={"u-button square primary"}
						onClick={() => changePictogram()}
						disabled={!canAccess}
					>
						<SvgIcon name={"pencil-create"} />
					</button>
				</>
			) : (
				<></>
			)}
		</>
	)
}
