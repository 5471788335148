import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { useEffect } from "react"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { ProductPageScopesSelectors } from "../../../redux/reducers/pages/productPage/scopes/ProductPageScopesSelectors"
import { actions } from "../../../redux/reducers/pages/productPage/scopes"
import { actions as informerActions } from "../../../redux/reducers/informer"
import AdScopeApplication from "../../../library/scopes/AdScopeApplication"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export function ProductScopesBlock() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	const language = useSelector(LanguageSelectors.getSelected)
	if (!productId) return <></>

	useEffect(() => {
		dispatch(ProductPageThunks.getScopes({ languageId: language.id }))
		dispatch(
			ProductPageThunks.getProductScopes({
				productId: productId,
				languageId: language.id,
			})
		)
	}, [language.id])

	const dispatch = useDispatch()

	const scopes = useSelector(ProductPageScopesSelectors.getScopes)
	const productScopes = useSelector(
		ProductPageScopesSelectors.getProductScopes
	)
	const selectedScope = useSelector(
		ProductPageScopesSelectors.getSelectedScope
	)
	const canAddScope = useSelector(ProductPageAuthSelectors.getCanAddSelected)
	const canChangeScope = useSelector(
		ProductPageAuthSelectors.getCanChangeSelected
	)
	const canRemoveScope = useSelector(
		ProductPageAuthSelectors.getCanRemoveSelected
	)
	const canChooseScope = useSelector(
		ProductPageAuthSelectors.getCanChooseSelected
	)
	const setSelectedScope = (id: number) =>
		dispatch(actions.setSelectedScope(id))

	const setSelectedProductScope = (id: number) =>
		dispatch(actions.setSelectedProductScope(id))

	const addProductToScope = () => {
		if (!selectedScope) {
			dispatch(
				informerActions.inform(
					"Выберите область применения для добавления"
				)
			)
			return
		}
		dispatch(
			ProductPageThunks.addProductToScope({
				productId: productId,
				scopeId: selectedScope.id,
			})
		)
	}

	const removeProductFromScope = () => {
		const selectedCurrentScope = productScopes.find((x) => x.selected)
		if (!selectedCurrentScope) {
			dispatch(
				informerActions.inform(
					"Выберите область применения для удаления"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.removeProductFromScope({
				productId: productId,
				scopeId: selectedCurrentScope.id,
			})
		)
	}

	const changeProductScope = () => {
		const selectedCurrentScope = productScopes.find((x) => x.selected)
		if (!selectedCurrentScope) {
			dispatch(
				informerActions.inform(
					"Выберите область применения для изменения"
				)
			)
			return
		}
		if (!selectedScope) {
			dispatch(
				informerActions.inform(
					"Выберите область применения из списка, чтобы изменить текущую"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.changeProductScopes({
				productId: productId,
				scopeId: selectedCurrentScope.id,
				targetScopeId: selectedScope.id,
			})
		)
	}

	return (
		<>
			<AdScopeApplication
				canControl={true}
				selectedScope={selectedScope}
				scopes={scopes}
				currentScopes={productScopes}
				addScope={addProductToScope}
				changeScope={changeProductScope}
				removeScope={removeProductFromScope}
				setSelectedScope={setSelectedScope}
				setSelectedCurrentScope={setSelectedProductScope}
				canAddScope={canAddScope}
				canChangeScope={canChangeScope}
				canRemoveScope={canRemoveScope}
				canChooseScope={canChooseScope}
			/>
		</>
	)
}
