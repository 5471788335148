import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { CategoryTabSelectors } from "../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors"
import { actions as actions1 } from "../../../../../../redux/reducers/pages/groupPage/categoryTabComponent"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import _ from "lodash"
import AdSearchInput from "../../../../../../library/inputs/AdSearchInput"
import { CategoryTabComponentThunks } from '../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/thunks';
import { LanguageSelectors } from "../../../../../../redux/reducers/languages/languageSelectors"
import { BsX } from "react-icons/bs"
import { ListRedactorTable } from "./Components/ListRedactorTable"
import { MassAppointmentTable } from "./Components/MassAppointmentTable"
import TextButton from "../../../../../common/basic/buttons/TextButton"
import { IScopeOfApplication } from "../../../../../../domain/models/scopes/IScopeOfApplication"

export default function AreaEditorModal() {
	const dispatch = useDispatch()

	const scopes = useSelector(CategoryTabSelectors.getScopes)
	const language = useSelector(LanguageSelectors.getSelected)
	const isOpened = useSelector(CategoryTabSelectors.getIsAreaEditorOpen)
	const handleClose = () => {
		dispatch(actions1.setIsAreaEditorMenuOpen(false))
	}
	useEffect(() => {
		dispatch(CategoryTabComponentThunks.getScopesOfApplication({ languageId: language.id }))
	}, []);
	// Штука для открытия таблицы
	const [isTableVisible, setIsTableVisible] = useState(false)
	const toggleTableVisibility = () => {
		setIsTableVisible(!isTableVisible)
	}
	const [isSecondTableVisible, setIsTwoTableVisible] = useState(false)
	const secondToggleTableVisibility = () => {
		setIsTwoTableVisible(!isSecondTableVisible)
	}

	//Поиск
	const [searchValue, setSearchValue] = useState("")
	useEffect(() => setFilteredScopes(scopes
		.filter(s => s.name
			.toLowerCase()
			.includes(searchValue
				.toLowerCase())
		)
	), [scopes])
	const [filteredScopes, setFilteredScopes] = useState(scopes);
	const [showAddOption, setShowAddOption] = useState(false) // показывать ли кнопку добавить
	const handleSearchChange = (newValue) => {
		setSearchValue(newValue)
		const lowerCaseValue = newValue.toLowerCase()
		const filtered = scopes.filter((scope) =>
			scope.name.toLowerCase().includes(lowerCaseValue)
		)
		setFilteredScopes(filtered)
		
		// Показываем кнопку "Добавить" только если нет совпадений и поисковая строка не пуста
		setShowAddOption(filtered.length === 0 && newValue.trim().length > 0)
	}

	//Добавление, изменение, удаление областей
	const addScope = async (newScopeName) => {
		try {
			await dispatch(
				CategoryTabComponentThunks.addScopeApplication({
					name: newScopeName,
					languageId: language.id,
				})
			)
			// Обновляем состояние списка
			setFilteredScopes(scopes)
			// Очищаем поле поиска и скрываем кнопку "Добавить"
			handleSearchChange("")
			setShowAddOption(false)

			console.log("Новая область применения успешно добавлена!")
		} catch (error) {
			console.error("Ошибка при добавлении области применения:", error)
		}
		await dispatch(CategoryTabComponentThunks.getScopesOfApplication({ languageId: language.id }))
	}

	return (
		<>
			<BsX
				onClick={handleClose}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					cursor: "pointer",
				}}
			/>
			<Modal
				show={isOpened}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={handleClose}
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						<strong>Редактор области применения</strong>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
						}}
					>
						<button
							onClick={toggleTableVisibility}
							style={{
								display: "flex",
								alignItems: "center",
								border: "none",
								background: "none",
								cursor: "pointer",
								fontWeight: "bold",
								fontSize: "1.2rem",
								maxWidth: "300px",
								height: "30px",
								outline: "none",
								minWidth: "300px",
							}}
						>
							Редактировать список
							{isTableVisible ? (
								<SvgIcon
									name="chevron-top"
									className="rem-icon"
								></SvgIcon>
							) : (
								<SvgIcon
									name="chevron-bottom"
									className="rem-icon"
								></SvgIcon>
							)}
						</button>
						{isTableVisible && (
							<div
								style={{
									display: "flex",
									marginLeft: "auto",
									width: "100%",
									height: "60px",
								}}
							>
								<AdSearchInput
									onChange={handleSearchChange}
									value={searchValue}
									placeholder="Поиск"
									style={{ width: "100%" }}
								/>
								{showAddOption && (
									
									<button
										onClick={() => addScope(searchValue)}
										className="u-button accent large"
										style={{marginLeft: "10px",height: "50px"}}
									>
										Добавить
									</button>
								)}
							</div>
						)}
					</div>
						{isTableVisible && ( <ListRedactorTable filteredScopes={filteredScopes} setFilteredScopes={setFilteredScopes}/> )}
					<p />
					<button
						onClick={secondToggleTableVisibility}
						style={{
							display: "flex",
							alignItems: "center",
							border: "none",
							background: "none",
							cursor: "pointer",
							fontWeight: "bold",
							fontSize: "1.2rem", // Увеличение размера шрифта
							width: "400px", // Установка ширины кнопки
							height: "30px", // Установка высоты кнопки
							outline: "none",
						}}
					>
						Массовое назначение
						{isSecondTableVisible ? (
							<SvgIcon
								name="chevron-top"
								className="rem-icon"
							></SvgIcon>
						) : (
							<SvgIcon
								name="chevron-bottom"
								className="rem-icon"
							></SvgIcon>
						)}
					</button>
						{isSecondTableVisible && ( <MassAppointmentTable /> )}
				</Modal.Body>
			</Modal>
		</>
	)
}
