import { IProductIdentity } from "../../domain/models/products/ProductIdentity"
import React, { useEffect, useState } from "react"
import { ISelectable } from "../../redux/types"
import { SvgIcon } from "../icons/SvgIcon"
import { Modal } from "react-bootstrap"
import AdSearchInput from "../inputs/AdSearchInput"
import { useDebouncedCallback } from "use-debounce"

export function AdLinkProductsModal(props: {
	isOpen: boolean
	close: () => void
	products: IProductIdentity[]
	addProductsToCurrentEntity: (ids: string[]) => void
	isLoading?: boolean
	header?: string
}) {
	const convert = (product: IProductIdentity) => {
		return {
			...product,
			selected: false,
		}
	}

	const debounceSetSearch = useDebouncedCallback((value: string) => {
		setSearch(value)
	}, 500)

	const [products, setProducts] = useState<
		(IProductIdentity & ISelectable)[]
	>(() => props.products.map(convert))

	const [search, setSearch] = useState("")
	const [filteredProducts, setFilteredProducts] =
		useState<(IProductIdentity & ISelectable)[]>(products)

	const [selectedProducts, setSelectedProducts] = useState(
		products.filter((x) => x.selected)
	)

	useEffect(() => {
		setProducts(props.products.map(convert))
	}, [props.products.length])

	useEffect(() => {
		if (search.length === 0) {
			setFilteredProducts(products)
			return
		}
		setFilteredProducts(
			products.filter(
				(x) =>
					x.name.toLowerCase().includes(search.toLowerCase()) ||
					x.id.toLowerCase().includes(search.toLowerCase())
			)
		)
	}, [search, products.length])

	const setSelectedProduct = (id: string) => {
		const product = products.find((x) => x.id === id)
		if (!product) return

		product.selected = !product.selected
		setProducts([...products])
		setSelectedProducts(products.filter((x) => x.selected))
	}

	const addProducts = () => {
		const selected = products.filter((x) => x.selected)
		if (selected.length === 0) return

		props.addProductsToCurrentEntity(selected.map((x) => x.id))
		props.close()
	}

	return (
		<Modal
			show={props.isOpen}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<Modal.Title
					id="contained-modal-title-vcenter"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flex: 1,
					}}
				>
					{props.header ?? "Выберите товары"}
					<AdSearchInput
						style={{ fontSize: "var(--size-text-1)", flex: 0 }}
						onChange={(e) => debounceSetSearch(e)}
						placeholder={"Поиск"}
					/>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.isLoading ? (
					<>
						<SvgIcon name="loader" className="loader-icon" />
						<div>Загрузка...</div>
					</>
				) : (
					<>
						<div
							className={"u-table__scroll-wrapper"}
							style={{ height: "60vh" }}
						>
							<table className="u-table">
								<thead className={"u-thead fixed-head"}>
									<tr>
										<th></th>
										<th>Код</th>
										<th>Наименование</th>
									</tr>
								</thead>
								<tbody className={"u-tbody"}>
									{filteredProducts.map((x) => {
										return (
											<tr
												key={x.id}
												onClick={() =>
													setSelectedProduct(x.id)
												}
											>
												<td>
													<input
														type="checkbox"
														checked={x.selected}
														onChange={() => {
															setSelectedProduct(
																x.id
															)
														}}
													/>
												</td>
												<td>{x.id}</td>
												<td>{x.name}</td>
											</tr>
										)
									})}
									<tr></tr>
								</tbody>
							</table>
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<button
					className={"u-button large primary"}
					onClick={() => props.close()}
				>
					Закрыть
				</button>
				<button
					className={"u-button accent large"}
					disabled={selectedProducts.length === 0}
					onClick={() => addProducts()}
				>
					Добавить выбранные товары
				</button>
			</Modal.Footer>
		</Modal>
	)
}
