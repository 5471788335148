import React, { useRef, useState } from "react"
import "./AddDragAndDrop.scss"
import { SvgIcon } from "../../library/icons/SvgIcon"
import { useCSVReader, usePapaParse } from "react-papaparse"

export interface IAddDragAndDropProps {
    onFileAdded: (file: File, data: string[][]) => void
    onFileCleared?: () => void
    fileType: string
    file?: File | null
    style?: any
    error: null | IAddDragAndDropError | undefined
}

export interface IAddDragAndDropError {
    header: string | null,
    body: string | null
}

export default function AddDragAndDrop(props: IAddDragAndDropProps) {
    const [dragActive, setDragActive] = useState(false)
    const [dragName, setDragName] = useState(props.file != null
        ? props.file?.name?.length > 15
            ? props.file?.name?.slice(0, 15) + "..."
            : props.file?.name
        : "")
    const [dragTime, setDragTime] = useState<string | null>(null)
    const [dragLength, setDragLength] = useState<number | null>(null)
    const [error, setError] = useState<IAddDragAndDropError | null>(null)
    const { readString } = usePapaParse();
    // ref
    const inputRef = useRef<HTMLInputElement>(null)

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault()
        e.stopPropagation()

        if (!validateFile(e))
            return;
    }

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault()
        setDragName(e.target.files[0].name)
        if (!validateFile(e))
            return;
        e.target.value = ""
    }

    async function readFileAsString(file) {
        const result_base64 = await new Promise((resolve) => {
            const fileReader = new FileReader();
            setDragActive(true)
            fileReader.onprogress = (e: any) => {
                if (e.lengthComputable) {
                    const percentLoaded = Math.round((e.loaded / e.total) * 100);
                    if (percentLoaded < 100) {
                        setDragLength(percentLoaded);
                    }
                }
            };
            fileReader.onload = (e) => {
                readString(fileReader.result as string, {
                    worker: true,
                    complete: (results) => {
                        resolve({ file, results })
                    },
                    delimiter: ";"
                });
            };

            fileReader.onerror = (e: any) => setError({
                header: "Неизвестная ошибка при чтении файла",
                body: "Поддерживаемые форматы: CSV"
            } as IAddDragAndDropError);
            fileReader.readAsText(file);
        });

        return result_base64;
    }

    const validateFile = function (e) {
        setError(null)
        const date = new Date();
        setDragTime(`${date.getHours()}:${date.getMinutes()}`)

        if (!/.csv$/.test(e.target.files[0].name.toLowerCase())) {
            setError({
                header: "Неверный формат файла",
                body: "Поддерживаемые форматы: CSV"
            } as IAddDragAndDropError);
            setDragActive(false)
            return false;
        }

        readFileAsString(e.target.files[0])
            .then(({ file, results }: any) => {

                setDragActive(false)
                setDragLength(null)

                if (results.data.length == 0
                    || results.data[0].length == 0
                    || results.data[0][0].trim() == "") {
                    setError({
                        header: "Некорректное содержимое файла",
                        body: "Поддерживаемые форматы: CSV"
                    } as IAddDragAndDropError);
                }

                props.onFileAdded(file, results.data)
            });
        return true;
    }

    const closeImport = () => {
        if (props.onFileCleared)
            props?.onFileCleared();

        setDragActive(false)
        setDragTime(null)
        setDragLength(null)
        setError(null)
        setDragName("");
    }

    if (error)
        return (<>
            <div className="file-import active error">
                <div>
                    <SvgIcon
                        name={"close-danger"}
                        className={"close-file"}
                    />
                </div>
                <div>
                    <div>
                        <div className="file-name">{dragName}</div>
                    </div>
                    <div className="error-text">{error.header}</div>
                    {error.body && <div className="error-body">{error.body}</div>}
                    <div className="file-replace">
                        <label>
                            Заменить файл
                            <input
                                accept={props.fileType}
                                ref={inputRef}
                                type="file"
                                multiple={false}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>
                <div className="file-import-time">{dragTime}</div>
                <div className="file-import-close">
                    <SvgIcon onClick={closeImport}
                        name={"close"}
                    />
                </div>
            </div>
        </>)

    if (dragActive)
        return (<>
            <div className="file-import active">
                <div>
                    <SvgIcon
                        name={"loading-file"}
                        className={"loading-file"}
                    />
                </div>
                <div>
                    <div>
                        <div className="file-name">{dragName}</div>
                    </div>
                    <div className="orange-text">Идет загрузка...</div>
                    {dragLength && <div className="file-import-percent">{dragLength.toString()}%</div>}
                </div>
                <div className="file-import-time">{dragTime}</div>
                <div className="file-import-close">
                    <SvgIcon
                        onClick={closeImport}
                        name={"close"}
                    />
                </div>
            </div>
        </>)

    if (!props.file)
        return (<>
            <form
                className="form-file-import"
                style={props.style}
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <label
                    className="file-import"
                >
                    <input
                        accept={props.fileType}
                        ref={inputRef}
                        type="file"
                        className="file-import-input"
                        multiple={false}
                        onChange={handleChange}
                    />
                    <div>
                        <SvgIcon
                            name={"import-file"}
                            className={"link-icon"}
                        />
                    </div>

                    <div className="body">
                        <span className="file-orange-text">
                            Выберите файл
                        </span>{" "}
                        или перетащите его сюда
                        <br />
                        CSV
                    </div>
                </label>
            </form >
        </>)


    return (
        <>
            <div className="file-import success">
                <div>
                    <SvgIcon
                        name={"check-success"}
                    />
                </div>
                <div>
                    <div>
                        <div className="file-name">{dragName}</div>
                    </div>
                    <div className="text">Загружено</div>
                    <div className="file-replace">
                        <label>
                            Заменить файл
                            <input
                                accept={props.fileType}
                                ref={inputRef}
                                type="file"
                                multiple={false}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>
                <div className="file-import-time">{dragTime}</div>
            </div>
        </>
    )
}
