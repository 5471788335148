import Constants from "./Constants"

export enum GroupMembership {
    Nothing,
    Russian,
    Latvian
}

export function getGroupMembership(priceGroupId: number | null): GroupMembership {
    if(priceGroupId === null || priceGroupId === 0)
        return GroupMembership.Nothing

    return priceGroupId === Constants.RigaPriceGroupId
        ? GroupMembership.Latvian
        : GroupMembership.Russian
}