import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { TablePartSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/tablePartSelectors"
import { MessageBusSelectors } from "../../../../../../redux/reducers/messageBus/messageBusSelectors"
import React, { useEffect } from "react"
import { actions as messageBusActions } from "../../../../../../redux/reducers/messageBus"
import KeysCodes from "../../../../../../utils/KeysCodes"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import { swapProductSortCommon } from "./ProductAttributeCommonExecutor"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

/**
 * Функционал сортировки продуктов/важных атрибутов
 * */
export function ProductSorterController() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const selectedAttributeColumn = useSelector(
		TablePartSelectors.getSelectedAttributeColumn
	)
	const selectedGroupProduct = useSelector(
		TablePartSelectors.getSelectedGroupProduct
	)
	const dispatch = useDispatch()
	const attributesOrder = useSelector(TablePartSelectors.getAttributesOrder)
	const groupProducts = useSelector(TablePartSelectors.getGroupProducts)

	const canControlImportantAttributes = useSelector(
		GroupPageAuthSelectors.getCanControlImportantAttrs
	)
	const canSortProducts = useSelector(
		GroupPageAuthSelectors.getCanSortProducts
	)
	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "p-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.ARROWDOWN:
					changeSort(1)
					break
				case KeysCodes.ARROWUP:
					changeSort(-1)
					break
			}
			console.log(message)
		}
	}, [message.rand])

	const changeSort = async (addition: number) => {
		if (!selectedGroupProduct && !selectedAttributeColumn) {
			dispatch(
				informerActions.inform(
					"Выберите продукт или атрибут для перемещения"
				)
			)
			return
		}
		if (selectedGroupProduct && selectedAttributeColumn) {
			dispatch(
				informerActions.inform(
					"Состояние блока продуктов имеет невалидное значение. " +
						"Нельзя выбрать продукт и атрибут одновременно"
				)
			)
			return
		}
		if (selectedGroupProduct) {
			if (!canSortProducts) {
				dispatch(
					informerActions.inform(
						"Недостаточно прав для изменения сортировки продуктов"
					)
				)
				return
			}
			await swapProductSort(addition)

			const table = document.getElementById("p-table")

			if (table) {
				const current_line: Element = table.getElementsByClassName(
					"table-row--selected"
				)[0]
				const next_line: Element = current_line.nextSibling as Element
				const previous_line: Element =
					(current_line.previousSibling as Element) ||
					table.getElementsByTagName("thead")[0]

				current_line.scrollIntoView({
					block: "nearest",
					behavior: "smooth",
				})
				if (addition < 0) {
					if (!previous_line) return
					previous_line.scrollIntoView({
						block: "nearest",
						behavior: "smooth",
					})
				} else {
					if (!next_line) return
					next_line.scrollIntoView({
						block: "nearest",
						behavior: "smooth",
					})
				}
			}
		}
		if (selectedAttributeColumn) {
			if (!canControlImportantAttributes) {
				dispatch(
					informerActions.inform(
						"Недостаточно прав для изменения сортировки атрибутов"
					)
				)
				return
			}
			swapImportAttributeSort(addition)
		}
	}

	const swapProductSort = async (addition: number) => {
		if (!selectedGroupProduct) {
			dispatch(informerActions.inform("Выберите продукт для перемещения"))
			return
		}
		const targetSort = selectedGroupProduct.sort! + addition
		return await swapProductSortCommon(
			selectedGroupProduct,
			groupProducts,
			group.id,
			targetSort,
			dispatch
		)
	}

	const swapImportAttributeSort = (addition: number) => {
		addition = addition * -1
		if (
			!selectedAttributeColumn ||
			!attributesOrder[selectedAttributeColumn].isImportant
		) {
			dispatch(
				informerActions.inform(
					"Изменение сортировки возможно только для важных атрибутов"
				)
			)
			return
		}

		if (Number(selectedAttributeColumn) === group.mainAttributeId) {
			dispatch(
				informerActions.inform(
					"Нельзя изменить сортировку главного атрибута"
				)
			)
			return
		}

		const targetSort =
			attributesOrder[selectedAttributeColumn].importantSort! + addition
		if (targetSort < 2) {
			dispatch(
				informerActions.inform(
					"Только главный аттрибут может иметь сортировку равную 1"
				)
			)
			return
		}

		const importantCount = Object.values(attributesOrder).filter(
			(x) => x.isImportant
		).length
		if (targetSort > importantCount) {
			dispatch(
				informerActions.inform(
					"Максимальная сортировка для важного аттрибута достигнута"
				)
			)
			return
		}
		const targetAttributeId = Object.entries(attributesOrder).find(
			(x) => x[1].importantSort === targetSort
		)?.[0]

		dispatch(
			TablePartComponentThunks.changeAttributeImportantSort({
				productGroupId: group.id,
				attributeId: Number(selectedAttributeColumn),
				targetSort: targetSort,
				currentSort:
					attributesOrder[selectedAttributeColumn].importantSort!,
				targetAttributeId: Number(targetAttributeId),
			})
		)
	}

	return (
		<>
			{canControlImportantAttributes || canSortProducts ? (
				<>
					<button
						className={"u-button square primary"}
						onClick={() => changeSort(-1)}
						disabled={
							(canControlImportantAttributes &&
								!selectedAttributeColumn) ||
							(canSortProducts && !selectedGroupProduct)
						}
					>
						<SvgIcon name={"arrow-top"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => changeSort(1)}
						disabled={
							(canControlImportantAttributes &&
								!selectedAttributeColumn) ||
							(canSortProducts && !selectedGroupProduct)
						}
					>
						<SvgIcon name={"arrow-bottom"} />
					</button>
				</>
			) : null}
		</>
	)
}
