import React, { useState } from "react"
import { SvgIcon } from "../icons/SvgIcon"
import AdDragAndDrop from "../drapAndDrop/AdDragAndDrop"
import "./adFileLoadModal.scss"

export default function AdFileLoadModal(props: {
	isOpen: boolean
	children: JSX.Element
	loadingFilesCount: number
	loadedFilesCount: number
	addFileToLoad: (files: FileList) => void
	possibleFileTypeMask: string
	canAddImages: boolean
}) {
	const [isMinimized, setIsMinimized] = useState(false)
	const [isAddingNewFilesMinimized, setIsAddingNewFilesMinimized] =
		useState(false)
	const [isFileViewerMinimized, setIsFileViewerMinimized] = useState(false)

	const percent = (props.loadingFilesCount / props.loadedFilesCount) * 100

	return props.isOpen ? (
		<div className={"load-modal u-droppod"}>
			<div className={"u-droppod-section-header header-with-toolbar"}>
				Загрузка файлов
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						{isMinimized ? (
							<SvgIcon
								name={"chevron-bottom"}
								className={"u-icon"}
								onClick={() => setIsMinimized(!isMinimized)}
							/>
						) : (
							<SvgIcon
								name={"chevron-top"}
								className={"u-icon"}
								onClick={() => setIsMinimized(!isMinimized)}
							/>
						)}
					</div>
				</div>
			</div>

			{isMinimized ? (
				<></>
			) : (
				<>
					<>
						{props.loadingFilesCount > 0 ? (
							<div className={"progressbar"}>
								<div className={"progressbar-percent"}>
									{percent}%
								</div>
								<div className={"progressbar-wrapper"}>
									<div
										className={"progressbar-slider"}
										style={{
											width: `calc(${
												100 - percent
											}% + 0.625em`,
										}}
									/>
								</div>
								<div className={"progressbar-text"}>
									Загружено {props.loadedFilesCount} из{" "}
									{props.loadingFilesCount}
								</div>
							</div>
						) : (
							<></>
						)}
					</>
					<AdFileLoadModelAddNewFiles
						isMinimized={isAddingNewFilesMinimized}
						setIsMinimized={setIsAddingNewFilesMinimized}
						addFileToLoad={props.addFileToLoad}
						possibleFileTypeMask={props.possibleFileTypeMask}
						canAddImages={props.canAddImages}
					/>
					<AdFileLoadModelAddedFilesViewer
						isMinimized={isFileViewerMinimized}
						setIsMinimized={setIsFileViewerMinimized}
					>
						{props.children}
					</AdFileLoadModelAddedFilesViewer>
				</>
			)}
		</div>
	) : (
		<></>
	)
}

function AdFileLoadModelAddNewFiles(props: {
	addFileToLoad: (files: FileList) => void
	possibleFileTypeMask: string
	isMinimized: boolean
	setIsMinimized: (value: boolean) => void
	canAddImages: boolean
}) {
	return (
		<div>
			<div
				className={
					"u-droppod-section-header__small header-with-toolbar"
				}
				style={{ marginTop: 0 }}
			>
				Добавить файлы
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						{props.isMinimized ? (
							<SvgIcon
								name={"chevron-bottom"}
								className={"u-icon"}
								onClick={() =>
									props.setIsMinimized(!props.isMinimized)
								}
							/>
						) : (
							<SvgIcon
								name={"chevron-top"}
								className={"u-icon"}
								onClick={() =>
									props.setIsMinimized(!props.isMinimized)
								}
							/>
						)}
					</div>
				</div>
			</div>
			{props.isMinimized ? (
				<></>
			) : (
				<>
					<AdDragAndDrop
						file={null}
						fileType={props.possibleFileTypeMask}
						onFileAdded={props.addFileToLoad}
						onFileCleared={() => {
							console.log("")
						}}
						canAddImages={props.canAddImages}
					/>
				</>
			)}
		</div>
	)
}

function AdFileLoadModelAddedFilesViewer(props: {
	children: JSX.Element
	isMinimized: boolean
	setIsMinimized: (value: boolean) => void
}) {
	return (
		<div>
			<div
				className={
					"u-droppod-section-header__small header-with-toolbar"
				}
				style={{ marginTop: 0 }}
			>
				Приложенные файлы
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						{props.isMinimized ? (
							<SvgIcon
								name={"chevron-bottom"}
								className={"u-icon"}
								onClick={() =>
									props.setIsMinimized(!props.isMinimized)
								}
							/>
						) : (
							<SvgIcon
								name={"chevron-top"}
								className={"u-icon"}
								onClick={() =>
									props.setIsMinimized(!props.isMinimized)
								}
							/>
						)}
					</div>
				</div>
			</div>
			{props.isMinimized ? (
				<></>
			) : (
				<div className={"files-holder"}>{props.children}</div>
			)}
		</div>
	)
}
