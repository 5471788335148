export default class DebounceProvider {
    private static debounceTime: number | undefined

    static getDebounce() {
        if(this.debounceTime === undefined) {
            this.debounceTime = process.env.REACT_APP_DEBOUNCE_MS === undefined
                ? 1000
                : Number(process.env.REACT_APP_DEBOUNCE_MS)
        }
        return this.debounceTime
    }
}