import AdDefaultInput from "../../../../../../library/inputs/AdDefaultInput"
import NullableSelect from "../../../../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToolsetComponentSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/toolsetComponentSelectors"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../../domain/GroupMembership"
import useAuth from "../../../../../../hooks/authorizationHook"
import { Role } from "../../../../../../domain/Role"
import { MessageBusSelectors } from "../../../../../../redux/reducers/messageBus/messageBusSelectors"
import { actions as messageBusActions } from "../../../../../../redux/reducers/messageBus"
import KeysCodes from "../../../../../../utils/KeysCodes"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import { LanguageSelectors } from "../../../../../../redux/reducers/languages/languageSelectors"

interface IToolsetProductAppendControllerProps {
	toolsetProductId: string
}

export function ToolsetProductAppendController(
	props: IToolsetProductAppendControllerProps
) {
	const language = useSelector(LanguageSelectors.getSelected)
	const group = useSelector(GroupPageSelectors.getGroup)
	const article = useSelector(ToolsetComponentSelectors.getArticle)
	const products = useSelector(ToolsetComponentSelectors.getProducts)
	const selectedProduct = useSelector(
		ToolsetComponentSelectors.getSelectedProduct
	)
	const toolsetProducts = useSelector(
		ToolsetComponentSelectors.getToolsetProducts
	)
	const selectedPictogram = useSelector(
		ToolsetComponentSelectors.getSelectedPictogram
	)
	const selectedToolsetProduct = useSelector(
		ToolsetComponentSelectors.getSelectedToolsetProduct
	)

	const dispatch = useDispatch()

	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlToolsetProducts
	)

	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "tpb-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.DELETE:
					removeProduct()
					break
				default:
					break
			}
		}
	}, [message.rand])

	const setArticle = (article: string) => {
		dispatch(actions.setArticle(article))
	}

	const setSelectedProducts = (productId: string | null) => {
		dispatch(actions.setSelectedProduct(productId))
	}

	const onArticleKeyUp = (event) => {
		if (event.key === "Enter") {
			if (article === "") return
			const product = products.find((p) => p.id === article)
			console.log(product)
			if (!product) {
				dispatch(
					informerActions.inform(
						"Продукт не может быть добавлен или не существует"
					)
				)
				return
			}

			dispatch(
				ToolsetComponentThunks.addProductsToToolset({
					toolsetId: props.toolsetProductId,
					products: [
						{
							productId: article,
							pictogramId: selectedPictogram?.id ?? null,
							sort: 0,
						},
					],
					productGroupId: group.id,
				})
			)
			dispatch(actions.setArticle(""))
		}
		if (event.key === "Escape") {
			dispatch(actions.setArticle(""))
		}
	}

	const addProductToToolset = () => {
		if (selectedProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для добавления"))
			return
		}

		const alreadyAdded =
			toolsetProducts.find((x) => x.productId === selectedProduct.id) !=
			undefined
		if (alreadyAdded) {
			dispatch(informerActions.inform("Продукт уже добавлен"))
			return
		}

		dispatch(
			ToolsetComponentThunks.addProductsToToolset({
				toolsetId: props.toolsetProductId,
				products: [
					{
						productId: selectedProduct.id,
						pictogramId: selectedPictogram?.id ?? null,
						sort: 0,
					},
				],
				productGroupId: group.id,
			})
		)
	}

	useEffect(() => {
		dispatch(
			ToolsetComponentThunks.getPossibleToolsetProducts({
				toolsetId: props.toolsetProductId,
				languageId: language.id,
				productGroupId: group.id,
			})
		)
	}, [toolsetProducts])

	const changeAssignment = () => {
		if (selectedProduct === null) {
			dispatch(
				informerActions.inform(
					"Выберите продукт на который будет заменен выбранный"
				)
			)
			return
		}

		if (selectedToolsetProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для замены"))
			return
		}

		if (toolsetProducts.find((x) => x.productId === selectedProduct.id)) {
			dispatch(informerActions.inform("Продукт уже добавлен"))
			return
		}

		dispatch(
			ToolsetComponentThunks.changeAssignment({
				productGroupId: group.id,
				productId: selectedToolsetProduct.productId,
				toolsetId: props.toolsetProductId,
				anotherProductId: selectedProduct.id,
			})
		)
	}

	const removeProduct = () => {
		if (!selectedToolsetProduct) {
			dispatch(informerActions.inform("Выберите продукт для удаления"))
			return
		}
		dispatch(
			ToolsetComponentThunks.removeProductFromToolset({
				productGroupId: group.id,
				productId: selectedToolsetProduct.productId,
				toolsetId: props.toolsetProductId,
			})
		)
	}

	return (
		<>
			{canAccess ? (
				<>
					<AdDefaultInput
						noControls={true}
						disabled={!canAccess}
						className="attribute-input"
						placeholder={"Артикул"}
						value={article}
						onChange={setArticle}
						onKeyUp={(event) => {
							event.nativeEvent.stopPropagation()
							onArticleKeyUp(event)
						}}
					/>

					<NullableSelect
						value={selectedProduct}
						isDisabled={!canAccess}
						options={products}
						onChange={(value) =>
							setSelectedProducts(value as string)
						}
						toOption={ToOptionProvider.productIdentityToOption}
						className={"selector selector--name"}
						height={31}
						noOptionsMessage={"No data available"}
						placeholder={"Наименование товара"}
					/>

					<button
						className={"u-button square primary"}
						onClick={() => {
							addProductToToolset()
						}}
						disabled={!canAccess}
					>
						<SvgIcon name={"circle-plus"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => changeAssignment()}
						disabled={!canAccess}
					>
						<SvgIcon name={"pencil-create"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => removeProduct()}
						disabled={!canAccess}
					>
						<SvgIcon name={"circle-x"} />
					</button>
				</>
			) : (
				<></>
			)}
		</>
	)
}
