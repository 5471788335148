import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	actions,
	BullfactsSelectors,
} from "../../redux/reducers/pages/bullfactsPage"
import TextCheckbox from "../common/basic/checkboxes/TextCheckbox"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import { useDebouncedCallback } from "use-debounce"
import DebounceProvider from "../../utils/DebounceProvider"
import { CatalogGroup } from "../../domain/types"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"

export default function BullfactsToolbar() {
	const language = useSelector(BullfactsSelectors.getSelectedLanguage)
	const languages = useSelector(LanguageSelectors.getLanguages)
	const translateTypes = useSelector(BullfactsSelectors.getTranslateTypes)
	const selectedTranslateType = useSelector(
		BullfactsSelectors.getSelectedTranslateType
	)
	const isSemanticSearchDisabled = useSelector(
		BullfactsSelectors.getIsSemanticSearchDisabled
	)
	const translateSource = useSelector(
		BullfactsSelectors.getSelectedTranslateSource
	)
	const translateSources = useSelector(BullfactsSelectors.getTranslateSources)
	const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)

	const catalogs = wasCatalogsInit
		? useSelector(
				CatalogSelectors.getByCatalogGroup(CatalogGroup.All)
		  ).concat([{ id: 0, name: "Без каталога", isPrinted: true }])
		: useSelector(CatalogSelectors.getByCatalogGroup(CatalogGroup.All))

	const catalog = useSelector(BullfactsSelectors.getSelectedCatalog)
	const isLoading = useSelector(BullfactsSelectors.getIsLoading)

	const dispatch = useDispatch()

	const setSearch = (search: string) => {
		dispatch(actions.setSearch(search))
	}
	const debouncedSetSearch = useDebouncedCallback((args) => {
		if (isLoading) return
		setSearch(args)
	}, DebounceProvider.getDebounce())

	const setSemanticSearch = (search: string) => {
		dispatch(actions.setSemanticSearch(search))
	}
	const debouncedSetSemanticSearch = useDebouncedCallback(
		(args) => setSemanticSearch(args),
		DebounceProvider.getDebounce()
	)

	const setIsSemanticSearch = () => {
		dispatch(actions.setIsSemanticSearch())
	}

	const setLocalLanguage = (languageId: number) => {
		const language = languages.find((x) => x.id === languageId)
		if (!language) return
		dispatch(actions.setLanguage(language))
	}

	const setTranslateType = (value: number) =>
		dispatch(actions.setTranslateType(value))
	const setTranslateSource = (value: number) =>
		dispatch(actions.setTranslatedSource(value))
	const setSelectedCatalog = (catalogId: number) => {
		const catalog = catalogs.find((x) => x.id === catalogId)
		if (!catalog) return
		dispatch(actions.setSelectedCatalog(catalog))
	}

	return (
		<div className="u-toolbar">
			<div className="toolbar-row">
				<AdSearchInput
					onChange={debouncedSetSearch}
					className="u-width-auto"
				/>

				<TextCheckbox
					onChange={() => setIsSemanticSearch()}
					text={"Текст полностью"}
					isChecked={isSemanticSearchDisabled}
				/>

				<SimpleSelect
					value={language}
					options={languages}
					onChange={setLocalLanguage}
					toOption={ToOptionProvider.languageToOption}
					className={"selector"}
				/>
				<SimpleSelect
					className="selector"
					value={selectedTranslateType}
					options={translateTypes}
					onChange={(e) => setTranslateType(e)}
					toOption={(x) => x}
				/>
				<SimpleSelect
					className="selector"
					value={catalog}
					options={catalogs}
					onChange={(e) => setSelectedCatalog(e)}
					toOption={ToOptionProvider.catalogToOption}
				/>
				<div hidden={true}>
					<CatalogSelector
						catalogGroup={CatalogGroup.Printed}
						isDisabled={isLoading}
					/>
				</div>
				<SimpleSelect
					className="selector"
					value={translateSource}
					options={translateSources}
					onChange={(e) => setTranslateSource(e)}
					toOption={(x) => x}
				/>
			</div>
			{isSemanticSearchDisabled && (
				<div className="toolbar-row">
					<AdSearchInput
						onChange={debouncedSetSemanticSearch}
						placeholder="Поиск по смыслу"
					/>
				</div>
			)}
		</div>
	)
}
