import Api from "../../../../../api"
import {createAutogenApiThunk, createResultFactoryApiThunk} from "../../../../createApiThunkV2"

export class TablePartComponentThunks {
    static getAttributes = createAutogenApiThunk({
        typePrefix: "new-product/get-attributes",
        apiCall: Api.attributes.getAttributes,
        errorMessage: "Не удалось получить список атрибутов"
    })

    static getProductsWithAttributes = createResultFactoryApiThunk({
        typePrefix: "new-product/get-products-with-attributes",
        apiCall: Api.products.getProductsWithAttributes,
        resultFactory: (args, apiRes, state) => {
            const attributesOrder = state.pages.productGroupPage.common.productGroup.attributesColumnOrder!
            return {
                attributesOrder: attributesOrder,
                mainAttributeId: state.pages.productGroupPage.common.productGroup.mainAttributeId,
                products: apiRes
            }
        },
        errorMessage: "Не удалось получить список продуктов группы"
    })

    static addProductToGroup = createAutogenApiThunk({
        typePrefix: "new-product/add-product-to-grop",
        apiCall: Api.products.addProductToGroup,
        errorMessage: "Не удалось добавить продукт в группу. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу.",
        isOptimistic: true
    })

    static replaceProductInGroup = createAutogenApiThunk({
        typePrefix: "new-product/replace-products-in-group",
        apiCall: Api.products.replaceProductsInGroup,
        errorMessage: "Не удалось заменить продукты в группе. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу.",
        isOptimistic: true
    })

    static removeProductFromGroup = createAutogenApiThunk({
        typePrefix: "new-product/remove-product-from-group",
        apiCall: Api.products.removeProductFromGroup,
        isOptimistic: true,
        errorMessage: "Не удалось удалить продукт из группы. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static getProductsWithoutGroup = createAutogenApiThunk({
        typePrefix: "new-product/get-products-without-group",
        apiCall: Api.products.getProductsIdentityWithoutGroup,
        errorMessage: "Не удалось получить список продуктов без группы"
    })

    static swapProductSort = createAutogenApiThunk({
        typePrefix: "new-product/swap-product-sorting",
        apiCall: Api.products.swapProductSort,
        isOptimistic: true,
        errorMessage: "Не удалось поменять местами продукты. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static addAttribute = createAutogenApiThunk({
        typePrefix: "new-product/add-attribute-to-product",
        apiCall: Api.attributes.addAttribute,
        isOptimistic: true,
        errorMessage: "Не удалось добавить атрибут к продукту. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static removeAttribute = createAutogenApiThunk({
        typePrefix: "new-product/remove-attribute-from-product",
        apiCall: Api.attributes.removeAttribute,
        isOptimistic: true,
        errorMessage: "Не удалось удалить атрибут. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeAttributeOrder = createAutogenApiThunk({
        typePrefix: "new-product/change-attribute-order",
        apiCall: Api.attributes.changeAttributeOrder,
        isOptimistic: true,
        errorMessage: "Не удалось изменить порядок атрибутов. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeAttributesValues = createAutogenApiThunk({
        typePrefix: "new-product/change-attribute-values",
        apiCall: Api.products.changeAttributesValues,
        isOptimistic: true,
        errorMessage: "Не удалось изменить значения атрибутов. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeAttributeImportantStatus = createAutogenApiThunk({
        typePrefix: "new-product/change-attribute-important-status",
        apiCall: Api.attributes.changeImportantStatus,
        isOptimistic: true,
        errorMessage: "Не удалось сделать аттрибут основным. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeAttributeImportantSort = createAutogenApiThunk({
        typePrefix: "new-product/change-attribute-important-sort",
        apiCall: Api.attributes.changeImportantSort,
        isOptimistic: true,
        errorMessage: "Не удалось изменить порядок основных атрибутов. Произошла ошибка. Состояние блока \"Аттрибуты продуктов\" имеет невалидное значение. Перезагрузите страницу."
    })
}

