import React from "react"
import Protector from "../../library/smartComponents/authorization/Protector"
import "./adminForm.scss"
import RouteProvider from "../../utils/RouteProvider"

export default function AdminForm() {
    return <Protector availableRoles={RouteProvider.System.roles!}>
        <div className="u-droppod admin-page">
            <div className="u-droppod-header">
                <div className="header-text">
                    Система
                </div>
            </div>
            <div className="u-droppod-section-header">
                Основные настройки
            </div>
        </div>
    </Protector>
}