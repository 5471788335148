import {AppState} from "../../index"
import {createSelector} from "@reduxjs/toolkit"

export class CategoriesPageSelector {
    static getIsGroupsLoading = (state: AppState) => state.pages.categoryPage.isGroupsLoading
    static getFilter = (state: AppState) => state.pages.categoryPage.groupFilter
    static getGroups = (state: AppState) => state.pages.categoryPage.productGroups
    static getSelectedGroups = (state: AppState) => state.pages.categoryPage.selectedGroups
    static getNewCategoryName  = (state: AppState) => state.pages.categoryPage.newCategoryName
    static isAddingToCategory = (state: AppState) => state.pages.categoryPage.isAddingGroupToCategory
    static getGroupCategories = (state: AppState) => state.pages.categoryPage.groupCategories

    static getCategoriesPathMemoized = createSelector([(state: AppState) => state.pages.categoryPage.groupCategories],
        (groupWithCategories) => 
            groupWithCategories.flatMap(x => x.categoryPath.map(id => id)))
}