import { IProductIdentity } from "../../../../../domain/models/products/ProductIdentity"
import { IEntityRelatedProduct } from "../../../../../domain/models/relatedProducts/IRelatedProduct"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"
import _ from "lodash"

type ProductsRelatedProductsState = {
	products: IProductIdentity[]
	selectedProduct: IProductIdentity | null

	related: IEntityRelatedProduct[]
}

const INITIAL_STATE: ProductsRelatedProductsState = {
	products: [],
	selectedProduct: null,

	related: [],
}

const slice = createSlice({
	name: "productsRelatedProducts",
	initialState: INITIAL_STATE,
	reducers: {
		setRelatedSelected: (state, { payload }: PayloadAction<string>) => {
			const currentRelated = state.related.find((x) => x.selected)
			if (currentRelated) {
				currentRelated.selected = false
			}
			const newRelated = state.related.find(
				(x) => x.relatedId === payload
			)
			if (
				newRelated &&
				newRelated.relatedId !== currentRelated?.relatedId
			) {
				newRelated.selected = true
			}
		},
		setSelectedProduct: (
			state,
			{ payload }: PayloadAction<string | null>
		) => {
			if (payload === null) {
				state.selectedProduct = null
				return
			}
			const product = state.products.find((x) => x.id === payload)
			if (product) {
				state.selectedProduct = product
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getRelatedProducts.fulfilled,
			(state, action) => {
				state.related = action.payload.map((x) => ({
					...x,
					selected: false,
				}))
			}
		)
		builder.addCase(
			ProductPageThunks.getPossibleRelatedProducts.fulfilled,
			(state, action) => {
				const currentRelatedIds = state.related.map((x) => x.relatedId)
				state.products = action.payload.filter(
					(x) => !currentRelatedIds.includes(x.id)
				)
			}
		)
		builder.addCase(
			ProductPageThunks.addRelatedProduct.fulfilled,
			(
				state,
				{
					meta: {
						arg: { productId, relatedProductsIds },
					},
				}
			) => {
				const products = state.products.filter((x) =>
					relatedProductsIds.includes(x.id)
				)
				const maxSort = _.maxBy(state.related, (x) => x.sort)?.sort || 0
				let sort = maxSort + 1

				for (const product of products) {
					state.related.push({
						relatedId: product.id,
						name: product.name,
						selected: false,
						sort: sort,
						productId: productId,
					})
					sort++
				}

				state.products = state.products.filter(
					(x) => !relatedProductsIds.includes(x.id)
				)
				state.selectedProduct = null
			}
		)
		builder.addCase(
			ProductPageThunks.removeRelatedProduct.fulfilled,
			(
				state,
				{
					meta: {
						arg: { relatedProductId },
					},
				}
			) => {
				const related = state.related.find(
					(x) => x.relatedId === relatedProductId
				)
				if (related) {
					state.related = state.related.filter(
						(x) => x.relatedId !== relatedProductId
					)
					for (const relatedElement of state.related) {
						if (relatedElement.sort > related.sort)
							relatedElement.sort--
					}
					state.products.push({
						id: related.relatedId,
						name: related.name,
						priceGroupId: 0,
					})
				}
			}
		)
		builder.addCase(
			ProductPageThunks.swapRelatedProductSort.fulfilled,
			(
				state,
				{
					meta: {
						arg: { currentRelatedId, targetRelatedId },
					},
				}
			) => {
				const currentRelated = state.related.find(
					(x) => x.relatedId === currentRelatedId
				)
				const targetRelated = state.related.find(
					(x) => x.relatedId === targetRelatedId
				)
				if (currentRelated && targetRelated) {
					const currentSort = currentRelated.sort
					currentRelated.sort = targetRelated.sort
					targetRelated.sort = currentSort
				}
			}
		)
		builder.addCase(
			ProductPageThunks.replaceRelatedProduct.fulfilled,
			(
				state,
				{
					meta: {
						arg: { currentRelatedId, targetRelatedId },
					},
				}
			) => {
				const currentRelated = state.related.find(
					(x) => x.relatedId === currentRelatedId
				)
				const targetRelated = state.products.find(
					(x) => x.id === targetRelatedId
				)

				if (currentRelated && targetRelated) {
					const temp = _.clone(currentRelated)

					currentRelated.relatedId = targetRelated.id
					currentRelated.name = targetRelated.name

					targetRelated.id = temp.relatedId
					targetRelated.name = temp.name
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
