import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import Loader from "../../library/loaders/Loader"
import "./productPage.scss"
import { ProductPageSelectors } from "../../redux/reducers/pages/productPage/productPageSelectors"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { ProductPageThunks } from "../../redux/reducers/pages/productPage/thunks"
import ProductPageToolbar from "./productPageToolbar/ProductPageToolbar"
import ProductPageTabs from "./productTabs/ProductPageTabs"

export default function ProductPage() {
	const [fixedProductName, setFixedProductName] = React.useState<
		string | null
	>(null)
	const product = useSelector(ProductPageSelectors.getProduct)
	const language = useSelector(LanguageSelectors.getSelected)
	const isLoading = useSelector(ProductPageSelectors.getIsProductLoading)
	const dispatch = useDispatch()
	const [searchParams] = useSearchParams()
	const productId = searchParams.get("productId")

	useEffect(() => {
		if (productId == null) return
		dispatch(
			ProductPageThunks.getProduct({
				productId: productId,
				languageId: language.id,
			})
		)
	}, [language])

	useEffect(() => {
		if (fixedProductName === null && product !== null) {
			setFixedProductName(product?.name ?? "")
		}
	}, [product])

	return productId == null ? (
		<>Переданы некорректные параметры</>
	) : isLoading ? (
		<Loader />
	) : (
		<div className="new-product u-droppod">
			<div className="u-droppod-header">
				<div className="header-text">{fixedProductName ?? ""}</div>
			</div>
			<ProductPageToolbar />
			<div className="u-droppod-section-header">Параметры</div>
			<ProductPageTabs />
		</div>
	)
}
