import { Role } from "../domain/Role"

export default class RouteProvider {
	public static products: IProductRoute = {
		path: "/products",
		ruName: "Товары",
	}
	public static categories: IProductRoute = {
		path: "/categories",
		roles: [Role.ContentManagerRF],
		ruName: "Категории",
	}
	public static tree: IProductRoute = {
		path: "/tree",
		roles: [Role.ContentManagerRF, Role.Photographer],
		ruName: "Дерево",
	}
	public static translate: IProductRoute = {
		path: "/translate",
		roles: [Role.ContentManagerLv, Role.Translator, Role.ContentManagerRg],
		ruName: "Переводы",
	}
	public static productPage: IProductExtendedRoute<{
		productId: string
	}> = {
			generateParameterPath: (routeParameters) =>
				generatePath("/products/product-page", routeParameters),
			path: "/products/product-page",
			ruName: "Товар",
			parentRoute: RouteProvider.products,
		}
	public static productGroup: IProductExtendedRoute<{
		productGroupId: string | null
		productId?: string[] | null
	}> = {
			path: "/products/product-group",
			generateParameterPath: (routeParameters) =>
				generatePath("/products/product-group", routeParameters),
			ruName: "Группа товаров",
			parentRoute: RouteProvider.products,
		}
	public static recommendations: IProductExtendedRoute<{
		productGroupId: string
	}> = {
			path: "/recommendations",
			generateParameterPath: (routeParameters) =>
				generatePath("/recommendations", routeParameters),
			roles: [Role.ContentManagerRF],
			ruName: "Рекомендации",
		}
	public static bullfacts: IProductRoute = {
		path: "/translate/bullfacts",
		roles: [Role.ContentManagerLv, Role.Translator, Role.ContentManagerRg],
		ruName: "Справочник переводов",
		parentRoute: RouteProvider.translate,
	}
	public static possibleProducts: IProductExtendedRoute<{
		productGroupId: string
		productId: string
	}> = {
			path: "/products/product-group/possible-products",
			generateParameterPath: (routeParameters) =>
				generatePath(
					"/products/product-group/possible-products",
					routeParameters
				),
			parentRoute: RouteProvider.productGroup,
			ruName: "Продукты",
		}
	public static possibleToolsetProducts: IProductExtendedRoute<{
		productGroupId: string
		toolsetId: string
	}> = {
			path: "/products/product-group/possible-toolset-products",
			generateParameterPath: (routeParameters) =>
				generatePath(
					"/products/product-group/possible-toolset-products",
					routeParameters
				),
			roles: [Role.ContentManagerRF],
			parentRoute: RouteProvider.productGroup,
			ruName: "Продукты набора",
		}
	public static translatorForm: IProductRoute = {
		path: "/translate/translator-form",
		roles: [Role.ContentManagerLv, Role.ContentManagerRg],
		parentRoute: RouteProvider.translate,
		ruName: "Форма переводчика",
	}
	public static compareContent: IProductRoute = {
		path: "/translate/compare-content",
		roles: [Role.ContentManagerLv, Role.ContentManagerRg],
		ruName: "Сравнение контента",
		parentRoute: RouteProvider.translate,
	}
	public static uploadDataToWebsite: IProductRoute = {
		path: "/translate/upload-data-to-website",
		roles: [Role.ContentManagerLv, Role.ContentManagerRg],
		parentRoute: RouteProvider.translate,
		ruName: "Выборочная загрузка на сайт",
	}

	public static System: IProductRoute = {
		path: "/system",
		roles: [Role.Admin],
		ruName: "Система",
	}

	public static Watchtower: IProductRoute = {
		path: "/watchtower",
		roles: [Role.Admin],
		ruName: "Контроль заказов",
	}

	public static allAnother: IProductRoute = {
		path: "/*",
	}
	public static login: IProductRoute = {
		path: "login",
	}
	public static generatePreviewPdf: IProductRoute = {
		path: "/translate/preview-pdf",
		roles: [Role.ContentManagerLv, Role.ContentManagerRg, Role.ContentManagerLv],
		ruName: "Предпросмотр PDF",
	}

	public static findRoute(path: string) {
		return (
			Object.values(RouteProvider).find((x) => x.path == path) ||
			RouteProvider.allAnother
		)
	}
}

export type RouteParameter = {
	[key in string]: string | number | boolean | null | string[]
}

export interface IProductRoute {
	path: string
	roles?: Role[]
	ruName?: string
	parentRoute?: IProductRoute
}

export interface IProductExtendedRoute<RP extends RouteParameter | null>
	extends IProductRoute {
	generateParameterPath: (routeParameters: RP) => string
}

const generatePath = (
	defaultPath: string,
	routeParameters: RouteParameter | null
): string => {
	if (routeParameters === null) return defaultPath

	const paramArray = Object.keys(routeParameters)
		.map((x) => {
			const value = routeParameters[x]
			if (value === null) return null
			if (Array.isArray(value)) {
				return value.map((val) => `${x}=${val}`).join("&")
			}
			return `${x}=${value}`
		})
		.filter((x) => x != null)

	if (paramArray.length === 0) return defaultPath

	return defaultPath + "?" + paramArray.join("&")
}
