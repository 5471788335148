import { useEffect } from "react"
import { ProductScopesBlock } from "../../modules/ProductScopesBlock"
import { ProductWebCategories } from "../../modules/ProductWebCategories"

export default function ProductCategoryTab(props: { onMount: () => void }) {
	useEffect(() => {
		props.onMount()
	}, [])

	return (
		<>
			<ProductWebCategories />
			<ProductScopesBlock />
		</>
	)
}
