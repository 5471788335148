import { createSelector } from "@reduxjs/toolkit"
import { AuthSelectors } from "../../auth/authSelectors"
import { ProductsPageSelectors } from "./productsPageSelectors"
import Constants from "../../../../domain/Constants"
import { PriceGroupSelectors } from "../../priceGroups/priceGroupSelectors"

export class ProductPageAuthSelectors {
	static canAddProduct = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			PriceGroupSelectors.getSelected,
			ProductsPageSelectors.getCheckedPriceGroup,
		],
		(isContentLv, isContentRg, isContentRf, selectedPriceGroup, checkedPriceGroup) => {
			console.log(
				`isContentLv: ${isContentLv} isContentRf: ${isContentRf} checkedPriceGroup: ${selectedPriceGroup}`
			)
			const isRigaChecked =
				checkedPriceGroup &&
				checkedPriceGroup === Constants.RigaPriceGroupId

			const isRigaSelected =
				selectedPriceGroup.id === Constants.RigaPriceGroupId

			if (isRigaSelected || isRigaChecked) {
				return isContentLv || isContentRg
			}
			return isContentRf
		}
	)
}
