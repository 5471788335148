import Api from "../../../api"
import { createAutogenNoArgsApiThunk } from "../../createApiThunkV2"

export class PriceGroupThunks {
	static getPriceGroups = createAutogenNoArgsApiThunk({
		typePrefix: "get/priceGroups",
		apiCall: Api.priceGroup.getPriceGroups,
		errorMessage: "Ошибка при получении списка номенклатурных групп",
	})
}
