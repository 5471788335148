import React from "react"
import {useEffect, useLayoutEffect, useRef, useState} from "react"
import {IMountableProps} from "../../../../../redux/types"
import RecommendationBlock from "./RecommendationBlock"
import GroupCatalogsBlock from "./GroupCatalogsBlock"
import CategoryTab from "../categoryTab/CategoryTab"
import {Scrollbars} from "react-custom-scrollbars-2"

export default function AdditionalInfoTab(props: IMountableProps) {

    useEffect(() => {
        props.onMount()
        window.addEventListener("resize", resize)

        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    const ref = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState(0)

    const [headerHeight, setHeaderHeight] = useState(0)

    useLayoutEffect(() => {
        setHeight(ref.current?.clientHeight || 0)
    }, [])

    function resize () {
        setHeight(ref.current?.clientHeight || 0)
    }

    return (
    // @ts-ignore
        <div ref={ref} className="u-droppod-scroll-wrapper" style={{"--offset-height": height + "px", "--header-height": headerHeight + "px"}}>
            <div className="u-droppod">
                <RecommendationBlock setHeaderHeight={setHeaderHeight}/>
                <GroupCatalogsBlock/>
            </div>
        </div>
    )
}