import { AppState } from "../../../../index"
import { createSelector } from "@reduxjs/toolkit"
import { TimeFilterEnum } from "../../../../../../domain/models/TimeFilterEnum"
import { IOptionType } from "../../../../../../app/common/basic/selectors/SimpleSelect"

export class ProductUnresolvedFilesSelectors {
	static getDeletedFiles = (state: AppState) =>
		state.pages.productPage.filesState.unresolvedFiles.deletedFiles

	static getFilesToChangeType = (state: AppState) =>
		state.pages.productPage.filesState.unresolvedFiles.unresolvedFiles.filter(
			(x) => x.fileSubTypeId !== 0
		)

	static getFormatFilter = (state: AppState) =>
		state.pages.productPage.filesState.unresolvedFiles.formatFilter

	static getTimeFilter = (state: AppState) =>
		state.pages.productPage.filesState.unresolvedFiles.timeFilter

	static getPossibleFormatFilters(state: AppState) {
		const files =
			state.pages.productPage.filesState.unresolvedFiles.unresolvedFiles
		const filters = files.map((x) => x.extension)
		const distinctFilters = [...new Set(filters)]

		const _allFormats = { value: 0, label: "Все форматы" }
		const result = distinctFilters.map((x, index) => {
			return { value: index + 1, label: x }
		})

		result.unshift(_allFormats)
		return result
	}

	static getPossibleTimeFilters = (): IOptionType[] => [
		{
			value: TimeFilterEnum.ToOld,
			label: "От нового к старому",
		},
		{
			value: TimeFilterEnum.ToNew,
			label: "От старого к новому",
		},
	]

	private static getFilteredByFormatUnresolvedFiles = createSelector(
		[
			(state: AppState) =>
				state.pages.productPage.filesState.unresolvedFiles
					.unresolvedFiles,
			ProductUnresolvedFilesSelectors.getFormatFilter,
			ProductUnresolvedFilesSelectors.getPossibleFormatFilters,
		],
		(unresolvedFiles, filter, possibleFormats) => {
			if (filter.value === 0) return unresolvedFiles

			const extension = possibleFormats.find(
				(x) => x.label === filter.label
			)?.label

			return filter.value === 0
				? unresolvedFiles
				: unresolvedFiles.filter((x) => x.extension === extension)
		}
	)

	private static getOrderedByTimeUnresolvedFiles = createSelector(
		[
			ProductUnresolvedFilesSelectors.getFilteredByFormatUnresolvedFiles,
			ProductUnresolvedFilesSelectors.getTimeFilter,
		],
		(unresolvedFiles, timeFilter) => {
			return unresolvedFiles.sort((a, b) => {
				const aDate = new Date(a.modifyDate)
				const bDate = new Date(b.modifyDate)
				return timeFilter.value === TimeFilterEnum.ToOld
					? bDate.getTime() - aDate.getTime()
					: aDate.getTime() - bDate.getTime()
			})
		}
	)

	static getUnresolvedFiles =
		ProductUnresolvedFilesSelectors.getOrderedByTimeUnresolvedFiles
}
