import React from "react"
import "./CompareContent.scss"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../redux/reducers/pages/compareContentPage"
import {Table} from "react-bootstrap"
import {useEffect} from "react"
import {CompareContentThunks} from "../../redux/reducers/pages/compareContentPage/thunks"
import {useDebouncedCallback} from "use-debounce"
import DebounceProvider from "../../utils/DebounceProvider"
import InformationTableRow from "../../library/tables/ErrorTableRow"
import {CatalogGroup, WebsiteSchema} from "../../domain/types"
import {CatalogSelectors} from "../../redux/reducers/catalogs/catalogSelectors"
import {LanguageSelectors} from "../../redux/reducers/languages/languageSelectors"
import {WebsiteSelectors} from "../../redux/reducers/webSites/websiteSelectors"
import {CompareContentPageSelectors} from "../../redux/reducers/pages/compareContentPage/compareContentPageSelectors"
import Protector from "../../library/smartComponents/authorization/Protector"
import RouteProvider from "../../utils/RouteProvider"
import useAuth from "../../hooks/authorizationHook"
import {Role} from "../../domain/Role"
import AdSearchInput from "../../library/inputs/AdSearchInput"

export default function CompareContent() {
    const groups = useSelector(CompareContentPageSelectors.getProductGroups)
    const search = useSelector(CompareContentPageSelectors.getSearch)

    const catalog = useSelector(CatalogSelectors.getSelected)
    const language = useSelector(LanguageSelectors.getSelected)
    const website = useSelector(WebsiteSelectors.getSelected)

    const canAccessAnySite = useAuth([Role.Admin])
    const dispatch = useDispatch()

    useEffect(() => {
        if(catalog.id == 0 || !catalog.isPrinted)
            return
        if(website.id == 0)
            return

        dispatch(CompareContentThunks.getCatalogDifference({
            catalogId: catalog.id,
            languageId: language.id,
            websiteId: website.id,
            search: search
        }))
    }, [search, catalog.id, website.id, language.id])

    const setSearch = (search: string) => dispatch(actions.setSearch(search))
    const debouncedSearch = useDebouncedCallback(args => setSearch(args), DebounceProvider.getDebounce())

    return <Protector availableRoles={RouteProvider.compareContent.roles!}>
        <div className="compare-content u-droppod">
            <div className="u-droppod-header">
                <div className="header-text">
                    Сравнение контента
                </div>

                <div className="u-toolbar">
                    <div className="toolbar-row">
                        <AdSearchInput onChange={debouncedSearch} style={{marginLeft: "auto", width: "auto"}} />
                        <WebsiteSelector schema={canAccessAnySite ? undefined : WebsiteSchema.Latvian}/>
                        <CatalogSelector catalogGroup={CatalogGroup.Printed}/>
                    </div>
                </div>
            </div>

            <div className="compare-content__table-wrapper u-table__scroll-wrapper">
                <table className="u-table">
                    <thead className="u-thead">
                        <tr className="u-wide-th">
                            <th>Код</th>
                            <th>Наименование</th>
                        </tr>
                    </thead>
                    <tbody className="u-tbody">
                        {
                            groups.isEmpty()
                                ? <InformationTableRow colSpan={2} text={"No matching records found"}/>
                                : groups.map(x => {
                                    return <tr key={x.id}>
                                        <td>{x.id}</td>
                                        <td>{x.name}</td>
                                    </tr>
                                })
                        }
                        <tr></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Protector>
}