import React, { useRef, useState } from "react"
import "./adDragAndDrop.scss"
import { SvgIcon } from "../icons/SvgIcon"

export interface IAdDragAndDropProps {
	onFileAdded: (files: FileList) => void
	onFileCleared?: () => void
	className?: string
	removeBr?: boolean
	fileType: string
	file?: File | null
	style?: any
	multiple?: boolean
	canAddImages: boolean
}

export default function AdDragAndDrop(props: IAdDragAndDropProps) {
	const [dragActive, setDragActive] = useState(false)
	// ref
	const inputRef = useRef<HTMLInputElement>(null)

	const clearFile = () => {
		if (props.onFileCleared === undefined) return
		props.onFileCleared()
	}

	// handle drag events
	const handleDrag = function (e) {
		//console.log(e)
		e.preventDefault()
		e.stopPropagation()
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true)
		} else if (e.type === "dragleave") {
			setDragActive(false)
		}
	}

	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault()
		console.log(e.dataTransfer.files[0])
		e.stopPropagation()
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			props.onFileAdded(e.dataTransfer.files)
		}
	}

	// triggers when file is selected with click
	const handleChange = function (e) {
		console.log(e)

		e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			props.onFileAdded(e.target.files)
		}
		e.target.value = ""
	}

	const title =
		props.file != null
			? props.file.name.length > 15
				? props.file.name.slice(0, 15) + "..."
				: props.file.name
			: ""

	return (
		<>
			{!props.file ? (
				<form
					className={"form-file-upload " + props.className}
					style={props.style}
					onDragEnter={handleDrag}
					onSubmit={(e) => e.preventDefault()}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				>
					<label
						className={
							"label-file-upload " +
							(dragActive ? "drag-active" : "")
						}
					>
						<input
							accept={props.fileType}
							ref={inputRef}
							type="file"
							className="input-file-upload"
							multiple={props.multiple ?? false}
							onChange={handleChange}
							disabled={!props.canAddImages}
						/>
						<div className={"grid-wrapper"}>
							<SvgIcon
								name={"paperclip"}
								className={"link-icon"}
							/>
							<p>
								<span className="orange-text">
									Выберите файл
								</span>{" "}
								или {props.removeBr ? "" : <br />}перетащите его
								сюда
							</p>
						</div>
					</label>
				</form>
			) : (
				<form
					className={"form-file-upload " + props.className}
					onDragEnter={handleDrag}
					onSubmit={(e) => e.preventDefault()}
				>
					<label
						className={
							"label-file-upload " +
							(dragActive ? "drag-active" : "")
						}
					>
						<div className={"grid-wrapper"}>
							<SvgIcon
								name={"paperclip"}
								className={"link-icon"}
							/>
							<div
								className={"file-name"}
								title={props?.file?.name ?? ""}
							>
								{title}
							</div>
							<SvgIcon
								name={"circle-x"}
								onClick={(e) => {
									e.preventDefault()
									clearFile()
								}}
							/>
						</div>
					</label>
				</form>
			)}
		</>
	)
}
