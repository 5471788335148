import "./YoutubePlayer.scss"
import { DateTimeLocaleProvider } from "../../utils/DateTimeLocaleProvider"

export default function YoutubePlayer(props: IYoutubePlayerProps) {
	return (
		<div className={"video-responsive " + props.className}>
			<iframe
				width="853"
				height="480"
				src={props.url}
				frameBorder="0"
				allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
			<div className="video-info">
				<>
					{props.name ? (
						<div className="video-info__name">{props.name}</div>
					) : (
						<></>
					)}
					{props.modifyDate ? (
						<div className="video-info__date">
							{props.modifyDate.toLocaleDateString(
								DateTimeLocaleProvider.getDefaultLocale(),
								DateTimeLocaleProvider.getDefaultLocaleOptions()
							)}
						</div>
					) : (
						<></>
					)}
				</>
			</div>
		</div>
	)
}

interface IYoutubePlayerProps {
	url: string
	className?: string
	name?: string
	modifyDate?: Date
}
