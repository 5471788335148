import { SvgIcon } from "../icons/SvgIcon"
import { keyDownMessage } from "../../utils/KeysCodes"
import InformationTableRow from "../tables/ErrorTableRow"
import _ from "lodash"
import React, { ReactNode } from "react"
import { useDispatch } from "react-redux"

export interface IAdEntityCatalog {
	catalogCategoryId?: number
	catalogName: string
	catalogParentCategoryId?: number | null
	showStatus: boolean
	selected: boolean
	webCategoryId?: number
	sort?: number | null
	catalogId: number
}

export default function AdEntityCatalogsBlock(props: {
	entityCatalogs: IAdEntityCatalog[]
	setShowStatus: () => void
	setSelected: (entityCatalog: IAdEntityCatalog) => void
	isChangingStatusOnSite: boolean
	canAccessToCatalog: (catalog: IAdEntityCatalog) => boolean
	canChangeDisplay: boolean
	additionalControls: ReactNode
}) {
	const dispatch = useDispatch()

	return (
		<>
			<div className="u-droppod-section-header header-with-toolbar">
				Отображение
				<div className="u-toolbar">
					<div className="toolbar-row">
						{props.additionalControls}
						<button
							type="button"
							className="u-button large primary"
							disabled={
								props.isChangingStatusOnSite ||
								!props.canChangeDisplay
							}
							onClick={() => props.setShowStatus()}
						>
							<SvgIcon name="eye" className="link-icon" />
							Изменить отображение
						</button>
					</div>
				</div>
			</div>

			<div className="u-table__scroll-wrapper almost-full-table">
				<table
					className="u-table"
					tabIndex={0}
					id="gcat-table"
					onKeyDown={(e) => {
						keyDownMessage(e, "gcat-table", dispatch)
					}}
				>
					<thead className="u-thead">
						<tr>
							<th></th>
							<th>Каталог</th>
							<th>№</th>
							<th>Раздел</th>
							<th>Подраздел</th>
							<th>Выводить в кат.</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{props.entityCatalogs.isEmpty() ? (
							<InformationTableRow
								text={"Пока ничего нет"}
								colSpan={6}
							/>
						) : (
							_.orderBy(
								props.entityCatalogs,
								(value) => value.webCategoryId
							).map((x) => {
								const key = `${x.catalogCategoryId}${x.webCategoryId}${x.catalogParentCategoryId}${x.catalogName}`
								return (
									<tr
										onClick={() => props.setSelected(x)}
										className={
											x.selected
												? "--selected --grey"
												: ""
										}
										key={key}
									>
										<td>
											<input
												disabled={
													!props.canAccessToCatalog(x)
												}
												type={"checkbox"}
												readOnly={true}
												checked={x.selected}
											/>
										</td>
										<td>{x.catalogName}</td>
										<td>{x.sort ?? "-"}</td>
										<td>{x.catalogCategoryId ?? "-"}</td>
										<td>
											{x.catalogParentCategoryId ??
												x.webCategoryId}
										</td>
										<td>
											<input
												type={"checkbox"}
												checked={x.showStatus}
												disabled={true}
											/>
										</td>
									</tr>
								)
							})
						)}
						<tr></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
