import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IGuideForPdf } from "../../domain/models/guide/GuideForPdf"

export default class GuideApi extends BaseApi {

	getGuide = async (data: {
		catalogId: number,
		websiteId: number, 
		languageId: number
	}): Promise<IApplicationResponse<IGuideForPdf>> =>
		this.sendQuery<IGuideForPdf>(
			"/api/product-group/guide",
			data,
			actionTypes.get,
			true,
            180000*60
		)
}
