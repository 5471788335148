import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AllProductPageThunks } from "./thunks"
import { IProductIdentity } from "../../../../domain/models/products/ProductIdentity"

export type AllProductsState = {
	products: IProductIdentity[]
	search: string
}

const INITIAL_STATE: AllProductsState = {
	products: [],
	search: "",
}

const slice = createSlice({
	name: "all-products",
	initialState: INITIAL_STATE,
	reducers: {
		setSearch(state: AllProductsState, action: PayloadAction<string>) {
			state.search = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			AllProductPageThunks.getProductsIdentityByGroupPriceGroupThunk
				.fulfilled,
			(state, action) => {
				state.products = action.payload
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
