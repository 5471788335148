import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"

export class CompareContentThunks {
    static getCatalogDifference = createAutogenApiThunk({
        typePrefix: "compare-content/get-difference",
        apiCall: Api.compareContent.getDifference,
        errorMessage: "Не удалось получить информацию",
        showLoader: true
    })
}