import {AppState} from "../../index"

export class TranslatorPageSelectors {
    static getTranslates = (state: AppState) => state.pages.translatorPage.translates
    static canTranslate = (state: AppState) => state.pages.translatorPage.canTranslate
    static canSaveTranslates = (state: AppState) => state.pages.translatorPage.canSaveTranslates 
    static getSelectedPhraseType = (state: AppState) => state.pages.translatorPage.selectedPhraseType
    static getPhraseTypes = (state: AppState) => state.pages.translatorPage.phraseTypes
    static getSearch = (state: AppState) => state.pages.translatorPage.search
    static getTargetLanguage = (state: AppState) => state.pages.translatorPage.selectedLanguage
    static shouldEndWorkWithPage = (state: AppState) => state.pages.translatorPage.shouldEndWorkWithPage
}