import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { SvgIcon } from "../../library/icons/SvgIcon"
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	Title,
	DoughnutController,
} from "chart.js"
import { Chart } from "react-chartjs-2"
import { WatchtowerPageThunk } from "../../redux/reducers/pages/watchtower/thunk"
import { WatchtowerSelectors } from "../../redux/reducers/pages/watchtower/watchtowerSelectors"

export default function Statistic() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(WatchtowerPageThunk.getLastStatistic())
	}, [])

	ChartJS.register(ArcElement, Tooltip, Legend, Title, DoughnutController)
	const statistic = useSelector(WatchtowerSelectors.getStatistic)

	const statisticCards = [
		{
			name: "Проверенных фото",
			iconName: "circle-checked",
			value: statistic.approvedImageCount,
			invoiceCount: statistic.approvedImageInvoiceCount,
			productCount: statistic.approvedImageProductCount,
		},
		{
			name: "Незагруженных фото",
			iconName: "cameraWarning",
			value: statistic.pendingImageCount,
			invoiceCount: statistic.pendingImageInvoiceCount,
			productCount: statistic.pendingImageProductCount,
		},
		{
			name: "Непроверенных фото",
			iconName: "minusWarning",
			value: statistic.pendingCheckImageCount,
			invoiceCount: statistic.pendingCheckImageInvoiceCount,
			productCount: statistic.pendingCheckImageProductCount,
		},
		{
			name: "Замечания к фото",
			iconName: "notificationRed",
			value: statistic.declinedImageCount,
			invoiceCount: statistic.declinedImageInvoiceCount,
			productCount: statistic.declinedImageProductCount,
		},
	]

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				text: "Назначенные товары",
				color: "#000",
				font: {
					size: 16,
					width: 500,
				},
			},
		},
	}

	const [legends, setLegends] = useState<
		{
			element: JSX.Element
			isShow: boolean
			name: string
			index: number
			dataset: number
			color: string
		}[]
	>([])

	const [labelTexts, setLabelTexts] = useState<string[]>([])
	const [dataset, setDataset] = useState<number[]>([])
	const [colors, setColors] = useState<string[]>([])

	useEffect(() => {
		console.log(legends)
		console.log(labelTexts)
		console.log(dataset)
		console.log(colors)
	}, [legends, colors, dataset, labelTexts])

	useEffect(() => {
		const legendsTemplates = [
			{
				element: (
					<div key={0} className="legend-text">
						<span>Проверено</span>
						<span>
							{statistic.approvedProductCount + " товара"}
						</span>
					</div>
				),
				isShow: true,
				name: "Проверено",
				index: 0,
				dataset: statistic.approvedProductCount,
				color: "#159469",
			},
			{
				element: (
					<div key={1} className="legend-text">
						<span>С замечаниями</span>
						<span>
							{statistic.declinedProductCount + " товара"}
						</span>
					</div>
				),
				isShow: true,
				name: "С замечаниями",
				index: 1,
				dataset: statistic.declinedProductCount,
				color: "#C92D3A",
			},
			{
				element: (
					<div key={2} className="legend-text">
						<span>Не проверено</span>
						<span>{statistic.pendingProductCount + " товара"}</span>
					</div>
				),
				name: "Не проверено",
				isShow: true,
				index: 2,
				dataset: statistic.pendingProductCount,
				color: "#F98B36",
			},
		]
		setLegends(legendsTemplates)
		setLabelTexts(legendsTemplates.map((item) => item.name))
		setDataset(legendsTemplates.map((item) => item.dataset))
		setColors(legendsTemplates.map((item) => item.color))
	}, [statistic])

	const onLabelClicked = (e, index: number) => {
		e.target.parentNode.classList.toggle("legend-text-line-through")

		const newLegends = [...legends]
		const changedLegend = newLegends.find((item) => item.index === index)
		if (!changedLegend) return

		changedLegend.isShow = !changedLegend.isShow

		setLegends(newLegends)
		const texts = newLegends
			.filter((x) => x.isShow)
			.map((item) => item.name)
		const datasets = newLegends
			.filter((x) => x.isShow)
			.map((item) => item.dataset)
		const colors = newLegends
			.filter((x) => x.isShow)
			.map((item) => item.color)

		setLabelTexts(texts)
		setDataset(datasets)
		setColors(colors)
	}

	const conf = {
		labels: labelTexts,
		datasets: [
			{
				data: dataset,
				backgroundColor: colors,
				borderWidth: 0,
				offset: 10,
			},
		],
	}

	return (
		<div className="ststistic-container">
			<div className="statistic">
				<div className="statistic-wrapper">
					<div className="statistic-invoices-info">
						<div className="total-invoices invoice-item">
							Всего заказов {statistic.invoiceCount}
						</div>
						<div className="ready-to-ship-invoices invoice-item">
							Готово к отгрузке{" "}
							{statistic.readyToShipInvoiceCount}
						</div>
						<div className="in-process-invoices invoice-item">
							В процессе {statistic.inProcessInvoiceCount}
						</div>
					</div>
					<div className="statistic-container">
						{statisticCards.map((item, index) => {
							return (
								<div
									key={index}
									className="u-droppod statistic-card"
								>
									<div className="inline-item">
										<SvgIcon
											name={item.iconName}
											className="link-icon"
										/>
										<span className="inline-item-text">
											{item.name}
										</span>
									</div>

									<div className="statistic-value">
										{item.value}
									</div>
									<div className="statistic-sub-value">
										<div className="statistic-sub-value-item">
											{item.invoiceCount} заказов
										</div>
										<div className="statistic-sub-value-item">
											{item.productCount} товара
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="u-droppod statistic-chart">
					<div className={"chart-wrapper"}>
						<Chart
							width="130"
							height="130"
							type="doughnut"
							data={conf}
							options={options}
						></Chart>
						<span className="statistic-total-products-val">
							{dataset.reduce((a, b) => a + b, 0)}
						</span>
					</div>
					<div className={"legend-wrapper"}>
						<div className={"legend-title"}>Назначенные товары</div>
						<div className={"legend-body"}>
							{legends.map((item, index) => {
								return (
									<div
										key={index}
										className="legend-item"
										onClick={(e) =>
											onLabelClicked(e, item.index)
										}
									>
										<div
											className="legend-color"
											style={{
												backgroundColor: item.color,
											}}
										></div>
										<>{item.element}</>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
