import React from "react"
import "./menubar.scss"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { actions as productActions } from "../../../redux/reducers/pages/productsPage/productList"
import RouteProvider from "../../../utils/RouteProvider"
import OptimisticController from "../optimisticController/OptimisticController"
import { useState } from "react"
import Protector from "../authorization/Protector"
import { Role } from "../../../domain/Role"
import { SvgIcon } from "../../icons/SvgIcon"
import { ReactComponent as ImgAvtodeloLogo } from "../../../assets/logos/img-avtodelo-logo.svg"
import { ReactComponent as ImgAvtodeloLogoPic } from "../../../assets/logos/img-avtodelo-logo-pic.svg"
import useAuth from "../../../hooks/authorizationHook"
import { ProductListSelectors } from "../../../redux/reducers/pages/productsPage/productList/productListSelectors"

function Menubar() {
	const history = useLocation()
	const [isWide, setIsWide] = useState<boolean>(true)

	return (
		<div className={"menubar-wrapper " + (isWide ? "" : "--small")}>
			<nav className={"menubar"}>
				<a className={"logo-wrapper"}>
					<ImgAvtodeloLogoPic className="logo-pic" />
					<ImgAvtodeloLogo className="logo-text" />
				</a>
				<div className="link-section">
					<Link
						to={RouteProvider.products.path}
						className={
							"u-button secondary " +
							(isWide ? "large" : "square") +
							(history.pathname == RouteProvider.products.path
								? " active"
								: "")
						}
					>
						<SvgIcon name="cart" className="link-icon" />
						<span className="link-name">Товары</span>
					</Link>
					<Protector
						availableRoles={RouteProvider.categories.roles!}
						justHide={true}
					>
						<Link
							to={RouteProvider.categories.path}
							className={
								"u-button secondary " +
								(isWide ? "large" : "square") +
								(history.pathname ==
								RouteProvider.categories.path
									? " active"
									: "")
							}
						>
							<SvgIcon name="tag" className="link-icon" />
							<span className="link-name">Категории</span>
						</Link>
					</Protector>
					<Protector
						availableRoles={RouteProvider.tree.roles!}
						justHide={true}
					>
						<Link
							to={RouteProvider.tree.path}
							className={
								"u-button secondary " +
								(isWide ? "large" : "square") +
								(history.pathname == RouteProvider.tree.path
									? " active"
									: "")
							}
						>
							<SvgIcon name="tree-icon" className="link-icon" />
							<span className="link-name">Дерево</span>
						</Link>
					</Protector>
					<Protector
						availableRoles={RouteProvider.translate.roles!}
						justHide={true}
					>
						<Link
							to={RouteProvider.translate.path}
							className={
								"u-button secondary " +
								(isWide ? "large" : "square") +
								(history.pathname ==
								RouteProvider.translate.path
									? " active"
									: "")
							}
						>
							<SvgIcon name="paper-text" className="link-icon" />
							<span className="link-name">Переводы</span>
						</Link>
					</Protector>
					<Protector
						availableRoles={RouteProvider.System.roles!}
						justHide={true}
					>
						<Link
							to={RouteProvider.System.path}
							className={
								"u-button secondary " +
								(isWide ? "large" : "square") +
								(history.pathname ==
								RouteProvider.System.path
									? " active"
									: "")
							}
						>
							<SvgIcon
								name="settings"
								className="link-icon"
							/>
							<span className="link-name">Система</span>
						</Link>
					</Protector>
					<Protector
						availableRoles={RouteProvider.Watchtower.roles!}
						justHide={true}
					>
						<Link
							to={RouteProvider.Watchtower.path}
							className={
								"u-button secondary " +
								(isWide ? "large" : "square") +
								(history.pathname ==
								RouteProvider.Watchtower.path
									? " active"
									: "")
							}
						>
							<SvgIcon
								name="checked"
								className="link-icon"
							/>
							<span className="link-name">Контроль заказов</span>
						</Link>
					</Protector>
				</div>
				<NewProductGroupButtonInMenu isWide={isWide} />
				<OptimisticController />

				<div className="support-link">
					<Link
						to={"/support"}
						className="u-button small secondary support-button"
					>
						<SvgIcon name="info" className="link-icon" />
						Поддержка
					</Link>

					<button
						className="u-button square secondary"
						onClick={() => setIsWide(!isWide)}
					>
						<SvgIcon
							name="chevrons-right"
							className="u-icon"
							style={{
								transform: `rotate(${isWide ? 180 : 0}deg)`,
							}}
						/>
					</button>
				</div>
			</nav>
		</div>
	)
}

interface NewProductGroupButtonInMenuProps {
	isWide: boolean
}

function NewProductGroupButtonInMenu(props: NewProductGroupButtonInMenuProps) {
	const dispatch = useDispatch()
	//const priceGroup = useSelector(PriceGroupSelectors.getSelected)
	const isContentLv = useAuth([Role.ContentManagerLv])
	const isContentRf = useAuth([Role.ContentManagerRF])
	const isContentRg = useAuth([Role.ContentManagerRg])

	const selectedProducts = useSelector(
		ProductListSelectors.getSelectedProducts
	)
	const shouldShow = isContentRf || isContentLv || isContentRg

	const parameterPath = RouteProvider.productGroup.generateParameterPath({
		productGroupId: null,
		productId: selectedProducts.map((x) => x.id),
	})
	return shouldShow ? (
		<div className="button-section">
			<Link
				onClick={() =>
					//для помещения в ассинхронную очередь разгрузки, она происходит после редиректа
					setTimeout(() => {
						dispatch(productActions.removeSelectedFromList())
					})
				}
				to={parameterPath}
				target="_blank"
				className={
					"u-button accent " + (props.isWide ? "large" : "square")
				}
			>
				<SvgIcon name="clipboard-text" className="link-icon" />
				<span className="link-name">Новая группа товаров</span>
			</Link>
		</div>
	) : (
		<></>
	)
}

export default Menubar
