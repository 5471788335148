import {
	IAttribute,
	IAttributeValue,
	IGroupAttributeDescr,
	IProductWithAttributes,
	IToolsetProduct,
} from "../../../../../domain/types"
import { ISelectable } from "../../../../types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TablePartComponentThunks } from "./thunk"
import _ from "lodash"
import Constants from "../../../../../domain/Constants"
import { reducer as toolsetReducer } from "./toolsetComponent"
import { combineReducers } from "redux"
import { IProductIdentity } from "../../../../../domain/models/products/ProductIdentity"

export type ProductWithAttributes = IProductWithAttributes &
	ISelectable & { newIdentifier: string }

export type TableTabState = {
	isProductGroupsLoading: boolean
	groupProducts: ProductWithAttributes[]
	selectedGroupProduct: ProductWithAttributes | null
	selectedAttributeColumn: number | null
	accumulatedChanges: IProductWithAttributes[]
	attributesOrder: Record<number, IGroupAttributeDescr>
	selectedGroupAttribute: number | null
	mainGroupAttribute: number | null

	attributes: IAttribute[]
	selectedAttribute: IAttribute

	isProductsLoading: boolean
	products: IProductIdentity[]
	selectedProduct: IProductIdentity | null

	article: string
}

const INITIAL_ATTRIBUTES: IAttribute[] = [{ id: -1, name: "loading" }]
const INITIAL_PRODUCTS: IProductIdentity[] = [
	{ id: "1", name: "loading", isInStatistic: true, stockRus: 0, priceGroupId: 0 },
]

const INITIAL_STATE: TableTabState = {
	isProductGroupsLoading: true,
	groupProducts: [],
	selectedGroupProduct: null,
	selectedAttributeColumn: null,
	accumulatedChanges: [],
	attributesOrder: {},
	selectedGroupAttribute: null,
	mainGroupAttribute: null,

	attributes: INITIAL_ATTRIBUTES,
	selectedAttribute: INITIAL_ATTRIBUTES[0],

	isProductsLoading: true,
	products: INITIAL_PRODUCTS,
	selectedProduct: INITIAL_PRODUCTS[0],
	article: "",
}

const slice = createSlice({
	name: "new-product-table-tab",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedColumn(state: TableTabState, action: PayloadAction<number>) {
			if (state.selectedAttributeColumn === action.payload)
				state.selectedAttributeColumn = null
			else state.selectedAttributeColumn = action.payload

			state.groupProducts.forEach((x) => (x.selected = false))
			state.selectedGroupProduct = null
		},
		setSelectedAttribute(
			state: TableTabState,
			action: PayloadAction<number>
		) {
			state.selectedAttribute = state.attributes.find(
				(x) => x.id === action.payload
			)!
		},
		setSelectedProduct(
			state: TableTabState,
			action: PayloadAction<string | null>
		) {
			if (action.payload === null) {
				state.selectedProduct = null
				return
			}
			state.selectedProduct = state.products.find(
				(x) => x.id === action.payload
			)!
		},
		setNewId(
			state: TableTabState,
			action: PayloadAction<{ productId: string; newIdentifier: string }>
		) {
			const product = state.groupProducts.find(
				(x) => x.id === action.payload.productId
			)
			if (product === undefined) return
			product.newIdentifier = action.payload.newIdentifier
		},
		setMainGroupAttribute(
			state: TableTabState,
			action: PayloadAction<number | null>
		) {
			const previousMain = state.mainGroupAttribute
			state.mainGroupAttribute = action.payload
			if (action.payload) {
				const mainAttribute = state.attributesOrder[action.payload]
				if (mainAttribute) {
					mainAttribute.importantSort = 1
					mainAttribute.isImportant = true
				}
			} else if (previousMain) {
				const prev = state.attributesOrder[previousMain]
				if (prev) {
					prev.importantSort = null
					prev.isImportant = false
				}
			}
		},
		setAttributeValue(
			state: TableTabState,
			action: PayloadAction<{
				productId: string
				attributeId: number
				value: string
			}>
		) {
			const product = state.groupProducts.find(
				(x) => x.id === action.payload.productId
			)
			if (product === undefined) return
			const attribute = product.attributeValues.find(
				(x) => x.id === action.payload.attributeId
			)
			if (attribute === undefined) return
			attribute.value = action.payload.value
			if (attribute.id === Constants.SortAttributeId) {
				attribute.value = action.payload.value
				return
			}
			const productChanges = state.accumulatedChanges.find(
				(x) => x.id === action.payload.productId
			)
			if (productChanges !== undefined) {
				const attributeChanges = productChanges.attributeValues.find(
					(x) => x.id === action.payload.attributeId
				)
				if (attributeChanges !== undefined)
					attributeChanges.value = action.payload.value
				else
					productChanges.attributeValues.push({
						id: action.payload.attributeId,
						value: action.payload.value,
					})
			} else {
				state.accumulatedChanges.push({
					id: product.id,
					name: product.name,
					stockRus: product.stockRus,
					isInStatistic: product.isInStatistic,
					sort: product.sort,
					attributeValues: new Array({
						id: action.payload.attributeId,
						value: action.payload.value,
					}),
					priceGroupId: product.priceGroupId,
				})
			}
		},
		setIsInStatisticValue(
			state: TableTabState,
			action: PayloadAction<{
				productId: string
				value: boolean
			}>
		) {
			const product = state.groupProducts.find(
				(x) => x.id === action.payload.productId
			)
			if (product === undefined) return
			product.isInStatistic = action.payload.value
			const productChanges = state.accumulatedChanges.find(
				(x) => x.id === action.payload.productId
			)
			if (productChanges !== undefined) {
				product.isInStatistic = action.payload.value
			} else {
				state.accumulatedChanges.push({
					id: product.id,
					name: product.name,
					sort: product.sort,
					isInStatistic: true,
					stockRus: 0,
					attributeValues: product.attributeValues,
					priceGroupId: product.priceGroupId,
				})
			}
		},
		setAttributesOrder(
			state: TableTabState,
			action: PayloadAction<Record<number, IGroupAttributeDescr>>
		) {
			state.attributesOrder = action.payload
		},
		setArticle(state: TableTabState, action: PayloadAction<string>) {
			state.article = action.payload
		},
		setProductRowSelected(
			state: TableTabState,
			action: PayloadAction<string>
		) {
			for (const product of state.groupProducts) {
				if (product.id === action.payload) {
					product.selected = true
					state.selectedGroupProduct = product
				} else {
					product.selected = false
				}
			}

			if (state.selectedGroupProduct?.id !== action.payload)
				state.selectedGroupProduct = null
			state.selectedAttributeColumn = null
		},
		clearStateOnUnmount() {
			return INITIAL_STATE
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			TablePartComponentThunks.getAttributes.fulfilled,
			(state, action) => {
				state.attributes = action.payload.filter(
					(attr) =>
						attr.id !== Constants.SortAttributeId &&
						attr.id !== Constants.WeightInGramAttributeId
				)
				state.selectedAttribute = state.attributes[0]
			}
		)

		builder.addCase(
			TablePartComponentThunks.getProductsWithAttributes.pending,
			(state) => {
				state.isProductGroupsLoading = true
			}
		)
		builder.addCase(
			TablePartComponentThunks.getProductsWithAttributes.fulfilled,
			(state, action) => {
				state.groupProducts = action.payload.products.map((x) => {
					return {
						id: x.id,
						name: x.name,
						attributeValues: x.attributeValues,
						isInStatistic: x.isInStatistic,
						stockRus: x.stockRus,
						sort: x.sort,
						selected: false,
						priceGroupId: x.priceGroupId,
						newIdentifier: x.id,
					}
				})
				state.attributesOrder = action.payload.attributesOrder
				state.mainGroupAttribute = action.payload.mainAttributeId
				state.isProductGroupsLoading = false
			}
		)

		builder.addCase(
			TablePartComponentThunks.getProductsWithAttributes.rejected,
			(state, action) => {
				state.isProductGroupsLoading = false
			}
		)

		builder.addCase(
			TablePartComponentThunks.getProductsWithoutGroup.pending,
			(state) => {
				state.products = INITIAL_PRODUCTS
				state.selectedProduct = INITIAL_PRODUCTS[0]
				state.isProductsLoading = true
			}
		)
		builder.addCase(
			TablePartComponentThunks.getProductsWithoutGroup.fulfilled,
			(state, action) => {
				state.products = _.orderBy(action.payload, (x) => x.name)
				state.selectedProduct = null
				state.isProductsLoading = false
			}
		)
		builder.addCase(
			TablePartComponentThunks.getProductsWithoutGroup.rejected,
			(state, action) => {
				state.isProductsLoading = false
			}
		)

		builder.addCase(
			TablePartComponentThunks.swapProductSort.pending,
			(state, action) => {
				const first = state.groupProducts.find(
					(x) => x.id === action.meta.arg.firstProductId
				)
				const second = state.groupProducts.find(
					(x) => x.id === action.meta.arg.secondProductId
				)
				if (first === undefined || second === undefined) return
				const temp = first.sort
				first.sort = second.sort
				second.sort = temp

				//в случе если есть аттрибут Sort, тогда обновляем его
				const shouldUpdateSortAttribute =
					Object.keys(state.attributesOrder).find(
						(x) => Number(x) === Constants.SortAttributeId
					) !== undefined
				if (shouldUpdateSortAttribute)
					state.groupProducts.forEach((x) => {
						x.attributeValues.forEach((attr) => {
							if (attr.id === Constants.SortAttributeId)
								attr.value = x.sort!.toString()
						})
					})
				//сортируем, чтобы не делать свап всех полей
				state.groupProducts = _.orderBy(
					state.groupProducts,
					(value) => value.sort
				)
				//выставляем выбранный продукт
				state.selectedGroupProduct = first
			}
		)

		builder.addCase(
			TablePartComponentThunks.addProductToGroup.pending,
			(state, action) => {
				let productSort =
					_.maxBy(state.groupProducts, (x) => x.sort)?.sort ?? 0
				for (const productId of action.meta.arg.productIds) {
					const product = state.products.find(
						(x) => x.id === productId
					)
					if (product === null) {
						return
					}
				}
				state.selectedProduct = null

				for (const productId of action.meta.arg.productIds) {
					const product = state.products.find(
						(x) => x.id === productId
					)
					const attributes: IAttributeValue[] = Object.keys(
						state.attributesOrder
					)
						.map((x) => Number(x))
						.filter((x) => x !== Constants.IgnoreAttributeId)
						.map((x) => {
							if (x === Constants.SortAttributeId)
								productSort += 1
							return {
								id: x,
								value:
									x === Constants.SortAttributeId
										? productSort.toString()
										: "",
							}
						})
					if (product && product.stockRus != undefined && product.isInStatistic != undefined)
					{
						state.groupProducts.push({
							id: product.id,
							selected: false,
							sort: productSort,
							name: product.name,
							stockRus: product.stockRus,
							isInStatistic: product.isInStatistic,
							attributeValues: attributes,
							priceGroupId: product.priceGroupId,
							newIdentifier: product.id,
						})
					}
				}
			}
		)

		builder.addCase(
			TablePartComponentThunks.addProductToGroup.fulfilled,
			() => {
				console.log("addProductToGroup.fulfilled")
			}
		)

		builder.addCase(
			TablePartComponentThunks.replaceProductInGroup.pending,
			(state, action) => {
				const index = state.groupProducts.findIndex(
					(x) => x.id === action.meta.arg.productId
				)
				if (index === -1) {
					console.error("Cant find product to change")
					return
				}
				const productIdentity: IProductIdentity = {
					id: state.groupProducts[index].id,
					name: state.groupProducts[index].name,
					priceGroupId: state.groupProducts[index].priceGroupId,
				}

				const newProductId = action.meta.arg.newProductId
				const newProduct = state.products.find(
					(x) => x.id === newProductId
				)

				//изменяем список продуктов
				state.products.push(productIdentity)
				state.products = state.products.filter(
					(x) => x.id !== newProductId
				)

				//изменяем запись в таблице
				state.groupProducts[index].id = newProduct!.id
				state.groupProducts[index].name = newProduct!.name
				state.groupProducts[index].attributeValues.forEach((x) => {
					x.value =
						x.id === Constants.SortAttributeId
							? state.groupProducts[index].sort!.toString()
							: ""
				})
				state.selectedProduct = null
				state.selectedGroupProduct = state.groupProducts[index]
			}
		)

		builder.addCase(
			TablePartComponentThunks.removeProductFromGroup.pending,
			(state, action) => {
				const productToRemove = state.groupProducts.find(
					(x) => x.id === action.meta.arg.productId
				)
				//Уменьшаем у всех значение Sort без лишнего запроса к серверу за новыми данными
				const shouldUpdateSortAttribute =
					Object.keys(state.attributesOrder).find(
						(x) => Number(x) === Constants.SortAttributeId
					) !== undefined
				state.groupProducts.forEach((x) => {
					if (x.sort! > productToRemove!.sort!) {
						x.sort = x.sort! - 1
						//в случае если в списке аттрибутов есть Sort, то обновляем его
						if (shouldUpdateSortAttribute) {
							x.attributeValues.forEach((attr) => {
								if (attr.id === Constants.SortAttributeId)
									attr.value = x.sort!.toString()
							})
						}
					}
				})
				//удаляем запись в таблице
				state.groupProducts = state.groupProducts.filter(
					(x) => x.id !== action.meta.arg.productId
				)
				//зануляем выбранный ряд в таблице
				state.selectedGroupProduct = null
				state.products.push({
					id: productToRemove!.id,
					name: productToRemove!.name,
					priceGroupId: productToRemove!.priceGroupId,
				})
			}
		)

		builder.addCase(
			TablePartComponentThunks.addAttribute.pending,
			(state, action) => {
				const attribute = action.meta.arg.attributeId
				const isImportant = state.mainGroupAttribute === attribute

				//добавляем аттрибут в список
				state.attributesOrder[attribute] = {
					importantSort: isImportant ? 1 : null,
					isImportant: isImportant,
					sort: Object.keys(state.attributesOrder).length + 1,
				}
				state.groupProducts.forEach((x) => {
					x.attributeValues.push({
						id: attribute,
						value: "",
					})
				})
			}
		)

		builder.addCase(
			TablePartComponentThunks.removeAttribute.pending,
			(state, action) => {
				const attributeId = action.meta.arg.attributeId
				//удаляем аттрибут из списка аттрибутов и у каждого продукта
				state.groupProducts.forEach((x) => {
					x.attributeValues = x.attributeValues.filter(
						(x) => x.id !== attributeId
					)
				})
				Object.keys(state.attributesOrder).forEach((x) => {
					const attribute = state.attributesOrder[x]
					if (
						attribute.sort! >
						state.attributesOrder[attributeId].sort!
					)
						attribute.sort = attribute.sort! - 1
					if (
						attribute.isImportant &&
						attribute.importantSort! >
							state.attributesOrder[attributeId].importantSort!
					)
						attribute.importantSort = attribute.importantSort! - 1
				})
				delete state.attributesOrder[attributeId]
			}
		)

		builder.addCase(
			TablePartComponentThunks.changeAttributeOrder.pending,
			(state, action) => {
				const source =
					state.attributesOrder[action.meta.arg.attributeId]
				const target =
					state.attributesOrder[action.meta.arg.targetAttributeId]

				const tmp = source.sort
				source.sort = target.sort
				target.sort = tmp
			}
		)

		builder.addCase(
			TablePartComponentThunks.changeAttributesValues.pending,
			(state) => {
				state.accumulatedChanges = []
			}
		)

		builder.addCase(
			TablePartComponentThunks.changeAttributeImportantStatus.pending,
			(state, action) => {
				const attributeId = action.meta.arg.attributeId
				const attribute = state.attributesOrder[attributeId]
				attribute.isImportant = !attribute.isImportant
				if (attribute.isImportant) {
					if (attributeId == state.mainGroupAttribute) {
						attribute.importantSort = 1
					} else {
						const curSorts = Object.values(
							state.attributesOrder
						).map((x) => x.importantSort ?? 0)
						let maxId = Math.max(...curSorts)
						if (maxId == 0) maxId = 2
						else maxId = maxId + 1
						attribute.importantSort = maxId
					}
				} else {
					const attrIds = Object.keys(state.attributesOrder)
					for (let i = 0; i < attrIds.length; i++) {
						const id = attrIds[i]
						if (Number(id) === attributeId) continue
						const attr = state.attributesOrder[id]
						if (!attr.importantSort) continue
						if (attr.importantSort > attribute.importantSort!) {
							attr.importantSort = attr.importantSort! - 1
						}
					}
					attribute.importantSort = null
				}
			}
		)

		builder.addCase(
			TablePartComponentThunks.changeAttributeImportantSort.pending,
			(state, action) => {
				const attribute =
					state.attributesOrder[action.meta.arg.attributeId]
				const targetAttribute = Object.values(
					state.attributesOrder
				).first((x) => x.importantSort === action.meta.arg.targetSort)

				const temp = attribute.importantSort
				attribute.importantSort = targetAttribute.importantSort
				targetAttribute.importantSort = temp
			}
		)
	},
})

const actions = slice.actions
const reducer = combineReducers({
	tablePartState: slice.reducer,
	toolsetState: toolsetReducer,
})

export { actions, reducer }
