import { IChangableEcmFileDescription } from "../../../../../../domain/models/files/EcmFileDescription"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../../thunks"
import { FileSubtype } from "../../../../../../domain/models/files/FileSubtype"
import { IOptionType } from "../../../../../../app/common/basic/selectors/SimpleSelect"
import { TimeFilterEnum } from "../../../../../../domain/models/TimeFilterEnum"
import { IFileType } from "../../../../../../domain/models/files/FileType"

export type ProductTextFilesState = {
	textFiles: IChangableEcmFileDescription[]
	deletedFiles: IChangableEcmFileDescription[]

	formatFilter: IOptionType
	timeFilter: IOptionType
	typeFilter: IFileType
}

const INITIAL_STATE: ProductTextFilesState = {
	textFiles: [],
	deletedFiles: [],

	formatFilter: { value: 0, label: "Все форматы" },
	timeFilter: { value: TimeFilterEnum.ToOld, label: "От нового к старому" },
	typeFilter: {
		id: 0,
		name: "Все типы",
		subType: FileSubtype.text,
		fileFormats: [],
	},
}

const slice = createSlice({
	name: "product/TextFiles",
	initialState: INITIAL_STATE,
	reducers: {
		setFileTypeFilter: (state, action: PayloadAction<IFileType>) => {
			state.typeFilter = action.payload
		},
		setTimeFilter: (state, action: PayloadAction<IOptionType>) => {
			state.timeFilter = action.payload
		},
		setFormatFilter: (state, action: PayloadAction<IOptionType>) => {
			state.formatFilter = action.payload
		},
		setFileType: (
			state,
			action: PayloadAction<{ fileId: number; fileTypeId: number }>
		) => {
			const file = state.textFiles.find(
				(x) => x.fileId === action.payload.fileId
			)
			if (!file) return
			file.fileTypeId = action.payload.fileTypeId
			file.wasChanged = true
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getFiles.fulfilled,
			(state, action) => {
				state.textFiles = action.payload
					.filter((file) => file.fileSubTypeId === FileSubtype.text)
					.map((x) => ({ ...x, wasChanged: false }))
			}
		)
		builder.addCase(
			ProductPageThunks.uploadFile.fulfilled,
			(state, { payload }) => {
				if (payload.fileSubTypeId === FileSubtype.text)
					state.textFiles.push({ ...payload, wasChanged: false })
			}
		)
		builder.addCase(
			ProductPageThunks.changeFileType.fulfilled,
			(state, { meta }) => {
				const textFiles = meta.arg.files
					.filter((file) => file.fileSubTypeId === FileSubtype.text)
					.filter(
						(file) =>
							!state.textFiles.some(
								(x) =>
									x.name === file.name &&
									x.extension === file.extension
							)
					)

				state.textFiles = state.textFiles.concat(
					textFiles.map((x) => ({ ...x, wasChanged: false }))
				)
			}
		)
		builder.addCase(
			ProductPageThunks.deleteFile.fulfilled,
			(state, { meta }) => {
				const file = state.textFiles.find(
					(x) => x.fileId === meta.arg.fileId
				)
				if (!file) return

				state.textFiles = state.textFiles.filter(
					(x) => x.fileId !== file.fileId
				)

				state.deletedFiles.push(file)
			}
		)
	},
})

const { actions, reducer } = slice

export { actions, reducer }
