import { IEntityProductCatalog } from "../../../../../domain/models/catalogs/ProductCatalog"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"

export type ProductsCatalogsState = {
	productsCatalogs: IEntityProductCatalog[]
	isChangingShowStatus: boolean
	isOnSite: boolean | null
}

const INITIAL_STATE: ProductsCatalogsState = {
	productsCatalogs: [],
	isChangingShowStatus: false,
	isOnSite: null,
}

const slice = createSlice({
	name: "productsCatalogs",
	initialState: INITIAL_STATE,
	reducers: {
		setProductCatalogsSelected: (
			state,
			{
				payload,
			}: PayloadAction<{
				catalogId: number
				catalogCategoryId?: number
				catalogWebCategoryId?: number
				catalogParentCategoryId?: number
				showStatus: boolean
			}>
		) => {
			const productCatalog = payload.catalogWebCategoryId
				? state.productsCatalogs.find(
						(x) =>
							x.catalogId === payload.catalogId &&
							x.catalogWebCategoryId ===
								payload.catalogWebCategoryId &&
							x.showStatus === payload.showStatus
				  )
				: state.productsCatalogs.find(
						(x) =>
							x.catalogId === payload.catalogId &&
							x.catalogCategoryId === payload.catalogCategoryId &&
							x.showStatus === payload.showStatus
				  )
			if (productCatalog) {
				productCatalog.selected = !productCatalog.selected
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getProductCatalogs.fulfilled,
			(state, { payload }) => {
				state.productsCatalogs = payload.map((x) => {
					return {
						...x,
						selected: false,
					}
				})
			}
		)
		builder.addCase(ProductPageThunks.changeShowStatus.pending, (state) => {
			state.isChangingShowStatus = true
		})
		builder.addCase(
			ProductPageThunks.changeShowStatus.fulfilled,
			(state, { meta: { arg } }) => {
				const productCatalogs = arg.map((x) => {
					return x.webCategoryId
						? state.productsCatalogs.find(
								(x) =>
									x.catalogId === x.catalogId &&
									x.productId === x.productId &&
									x.catalogWebCategoryId ===
										x.catalogWebCategoryId
						  )
						: state.productsCatalogs.find(
								(x) =>
									x.catalogId === x.catalogId &&
									x.productId === x.productId &&
									x.catalogCategoryId === x.catalogCategoryId
						  )
				})
				productCatalogs.forEach((x) => {
					if (x) {
						x.showStatus = !x.showStatus
						if (x.selected) {
							x.selected = false
						}
					}
				})

				state.isChangingShowStatus = false
			}
		)
		builder.addCase(
			ProductPageThunks.changeShowStatus.rejected,
			(state) => {
				state.isChangingShowStatus = false
			}
		)
		builder.addCase(
			ProductPageThunks.isOnSite.fulfilled,
			(state, { payload }) => {
				state.isOnSite = payload
			}
		)
		builder.addCase(ProductPageThunks.isOnSite.pending, (state) => {
			state.isOnSite = null
		})

		builder.addCase(ProductPageThunks.removeFromSite.fulfilled, (state) => {
			state.isOnSite = false
		})

		builder.addCase(
			ProductPageThunks.addProductToSite.fulfilled,
			(state) => {
				state.isOnSite = true
			}
		)
	},
})

const { actions, reducer } = slice
export { actions, reducer }
