import { createSelector } from "@reduxjs/toolkit"
import { AuthSelectors } from "../../../auth/authSelectors"
import { ProductPageSelectors } from "../productPageSelectors"
import Constants from "../../../../../domain/Constants"

export class ProductPageAuthSelectors {
	// Может ли пользователь изменять название продукта
	static getCanChangeProductName = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Проверка фотографии
	static getCanChangePhotoVerification = createSelector(
		[
			AuthSelectors.isPhotographer,
			AuthSelectors.isAdmin,
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			ProductPageSelectors.getProduct,
		],
		(isPhotographer, isAdmin, isContentRf, isContentLv, isContentRg, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isPhotographer || isContentRg
				: isContentRf || isAdmin || isPhotographer
	)

	// Проверка изменения описания продукта
	static getCanChangeDescriptionVerification = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь удалять продукт
	static getCanDeleteProduct = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin
				: isContentRf || isAdmin
	)
	// Может ли пользователь добавлять  характеристики продукта
	static getCanChangeProductCharacteristics = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)

	// Может ли пользователь добавлять видео
	static getCanAddVideo = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			AuthSelectors.isPhotographer,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, isPhotographer, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin || isPhotographer
	)

	// Может ли пользователь загружать файлы
	static getCanLoadFiles = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь включать режим редактирования
	static getCanEditFiles = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			AuthSelectors.isPhotographer,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, isPhotographer, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin || isPhotographer
	)
	// Может ли пользователь загружать изображение
	static getCanLoadImages = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			AuthSelectors.isPhotographer,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, isPhotographer, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin || isPhotographer
	)
	// Может ли пользователь добавлять изображения
	static getCanAddImages = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			AuthSelectors.isPhotographer,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, isPhotographer, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin || isPhotographer
	)
	// Может ли пользователь редактировать файлы продуктов
	static getCanEditProductFiles = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь добавлять категорию
	static getCanAddProductCategory = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь изменять категорию
	static getCanChangeProductCategory = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь удалять категорию
	static getCanRemoveProductCategory = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь делать основной категорию
	static getCanDoMainProductCategory = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь выбирать категорию
	static getCanChooseProductCategory = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь добавлять область применения
	static getCanAddSelected = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь изменять область применения
	static getCanChangeSelected = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь удалять область применения
	static getCanRemoveSelected = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь выбирать область применения
	static getCanChooseSelected = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь добавлять сопутствующие товары
	static getCanAddRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь изменять сопутствующие товары
	static getCanChangeRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь удалять сопутствующие товары
	static getCanRemoveRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь производить сортировку сопутствующих товаров
	static getCanSwapSortRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)

	// Может ли пользователь добавлять видео к сопутствующим товарам
	static getCanAddVideoRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь выбирать сопутствующие товары
	static getCanChooseRelatedProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  добавлять аналогичные товары
	static getCanAddSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  изменять аналогичные товары
	static getCanChangeSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  удалять аналогичные товары
	static getCanRemoveSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  производить сортировку аналогичных товаров
	static getCanSwapSortSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)

	// Может ли пользователь  добавить видео к аналогичным товарам
	static getCanAddVideoSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  добавить аналог к аналогичным товарам
	static getCanChooseAnAnalogSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  добавить аналог выбрать аналогичный товар
	static getCanChooseSimilarProducts = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
	// Может ли пользователь  изменить отображение
	static getCanChangeDisplay = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)

	static getCanUploadToSite = createSelector(
		[
			AuthSelectors.isContentRf,
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isAdmin,
			ProductPageSelectors.getProduct,
		],
		(isContentRf, isContentLv, isContentRg, isAdmin, product) =>
			product?.priceGroupId == Constants.RigaPriceGroupId
				? isContentLv || isAdmin || isContentRg
				: isContentRf || isAdmin
	)
}
