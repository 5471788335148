import AdFiles from "../../../library/files/AdFiles"
import AdFileLoader from "../../../library/files/AdFileLoader"
import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { actions } from "../../../redux/reducers/pages/productPage/files"
import { actions as unresolvedFileActions } from "../../../redux/reducers/pages/productPage/files/unresolvedFiles"
import { actions as textFileActions } from "../../../redux/reducers/pages/productPage/files/textFiles"
import { actions as graphicFileActions } from "../../../redux/reducers/pages/productPage/files/graphicFiles"
import { useEffect } from "react"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { ProductFilesSelectors } from "../../../redux/reducers/pages/productPage/files/ProductFilesSelectors"
import {
	IEcmFileDescription,
	IShortEcmFileDescription,
} from "../../../domain/models/files/EcmFileDescription"
import { getFullname } from "../../../utils/FileUtils"
import { ProductUnresolvedFilesSelectors } from "../../../redux/reducers/pages/productPage/files/unresolvedFiles/ProductUnresolvedFilesSelectors"
import { IFileType } from "../../../domain/models/files/FileType"
import { FileSubtype } from "../../../domain/models/files/FileSubtype"
import SimpleSelect from "../../common/basic/selectors/SimpleSelect"
import { ProductTextFilesSelectors } from "../../../redux/reducers/pages/productPage/files/textFiles/ProductTextFilesSelectors"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import { ProductGraphicFilesSelectors } from "../../../redux/reducers/pages/productPage/files/graphicFiles/ProductGraphicFilesSelectors"
import AdGraphicFiles from "../../../library/files/AdGraphicFiles"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export default function ProductFiles() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(ProductPageThunks.getFileTypes())
		dispatch(ProductPageThunks.getFiles({ productId }))
	}, [])

	return (
		<div className={"product-files-tab u-flex-column"}>
			<ProductFileLoader />
			<UnresolvedProductFiles />
			<TextProductFiles />
			<GraphicProductFiles />
		</div>
	)
}

function UnresolvedProductFiles() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const dispatch = useDispatch()

	const fileTypes = useSelector(ProductFilesSelectors.getFileTypes)

	const unresolvedFiles = useSelector(
		ProductUnresolvedFilesSelectors.getUnresolvedFiles
	)

	const deletedFiles = useSelector(
		ProductUnresolvedFilesSelectors.getDeletedFiles
	)

	const changedFiles = useSelector(
		ProductUnresolvedFilesSelectors.getFilesToChangeType
	)

	const formatFilter = useSelector(
		ProductUnresolvedFilesSelectors.getFormatFilter
	)

	const possibleFormatFilter = useSelector(
		ProductUnresolvedFilesSelectors.getPossibleFormatFilters
	)

	const timeFilter = useSelector(
		ProductUnresolvedFilesSelectors.getTimeFilter
	)

	const possibleTimeFilter = useSelector(
		ProductUnresolvedFilesSelectors.getPossibleTimeFilters
	)

	const setFormatFilter = (formatValue: number) => {
		const format = possibleFormatFilter.find((x) => x.value === formatValue)
		if (!format) return
		dispatch(unresolvedFileActions.setFormatFilter(format))
	}

	const setTimeFilter = (timeFilterValue: number) => {
		const timeFilter = possibleTimeFilter.find(
			(x) => x.value === timeFilterValue
		)
		if (!timeFilter) return
		dispatch(unresolvedFileActions.setTimeFilter(timeFilter))
	}

	const saveUnresolvedFiles = () => {
		if (changedFiles.length === 0) return

		dispatch(
			ProductPageThunks.changeFileType({ productId, files: changedFiles })
		)
	}

	const changeSubtype = (
		file: IEcmFileDescription,
		subtype: FileSubtype,
		fileTypes: IFileType[]
	) => {
		dispatch(
			unresolvedFileActions.setSubtype({
				fileDescr: file,
				subType: subtype,
				fileTypes,
			})
		)
	}

	const changeType = (file: IEcmFileDescription, type: IFileType) => {
		dispatch(
			unresolvedFileActions.setFileType({
				fileDescr: file,
				fileType: type,
			})
		)
	}

	const deleteFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.deleteFile({ productId, fileId: file.fileId })
		)
	}

	const downloadFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.downloadFile({ productId, fileId: file.fileId })
		)
	}

	const downloadAllFiles = () => {
		dispatch(
			ProductPageThunks.downloadFiles({
				productId,
				subType: FileSubtype.unknown,
			})
		)
	}

	const onFileLoaderModalClose = () => {
		dispatch(actions.clearFilesToLoad())
	}

	return (
		<>
			{unresolvedFiles.length == 0 ? (
				<></>
			) : (
				<AdFiles
					header={"Неопределенные файлы"}
					fileTypes={fileTypes}
					files={unresolvedFiles}
					deletedFiles={deletedFiles}
					deleteFile={deleteFile}
					isUnresolved={true}
					onSave={saveUnresolvedFiles}
					onSubtypeChanged={changeSubtype}
					onTypeChanged={changeType}
					downloadFile={downloadFile}
					downloadFiles={downloadAllFiles}
					onModalClose={() => onFileLoaderModalClose()}
					canEditProductFiles={true}
				>
					<>
						<SimpleSelect
							value={formatFilter}
							options={possibleFormatFilter}
							onChange={(e) => {
								setFormatFilter(e)
							}}
							toOption={(e) => e}
						/>
						<SimpleSelect
							value={timeFilter}
							options={possibleTimeFilter}
							onChange={(e) => setTimeFilter(e)}
							toOption={(e) => e}
						/>
					</>
				</AdFiles>
			)}
		</>
	)
}

function TextProductFiles() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const dispatch = useDispatch()

	const typeFilter = useSelector(ProductTextFilesSelectors.getTypeFilter)
	const formatFilter = useSelector(ProductTextFilesSelectors.getFormatFilter)
	const timeFilter = useSelector(ProductTextFilesSelectors.getTimeFilter)

	const possibleTypeFilter = useSelector(
		ProductTextFilesSelectors.getPossibleTypeFilters
	)
	const possibleFormatFilter = useSelector(
		ProductTextFilesSelectors.getPossibleFormatFilters
	)
	const possibleTimeFilter = useSelector(
		ProductTextFilesSelectors.getPossibleTimeFilters
	)

	const fileTypes = useSelector(ProductFilesSelectors.getFileTypes)

	const textFiles = useSelector(ProductTextFilesSelectors.getTextFiles)
	const deletedFiles = useSelector(
		ProductTextFilesSelectors.getDeletedTextFiles
	)

	const changedFiles = useSelector(
		ProductTextFilesSelectors.getChangedTextFiles
	)

	const setFormatFilter = (formatValue: number) => {
		const format = possibleFormatFilter.find((x) => x.value === formatValue)
		if (!format) return
		dispatch(textFileActions.setFormatFilter(format))
	}

	const setTimeFilter = (timeFilterValue: number) => {
		const timeFilter = possibleTimeFilter.find(
			(x) => x.value === timeFilterValue
		)
		if (!timeFilter) return
		dispatch(textFileActions.setTimeFilter(timeFilter))
	}

	const setTypeFilter = (typeFilterValue: number) => {
		const typeFilter = possibleTypeFilter.find(
			(x) => x.id === typeFilterValue
		)
		if (!typeFilter) return
		dispatch(textFileActions.setFileTypeFilter(typeFilter))
	}

	const changeFileType = (file: IEcmFileDescription, fileType: IFileType) => {
		dispatch(
			textFileActions.setFileType({
				fileId: file.fileId,
				fileTypeId: fileType.id,
			})
		)
	}

	const deleteFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.deleteFile({ productId, fileId: file.fileId })
		)
	}

	const saveChanges = () => {
		if (changedFiles.length === 0) return

		dispatch(
			ProductPageThunks.changeFileType({ productId, files: changedFiles })
		)
	}

	const downloadFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.downloadFile({ productId, fileId: file.fileId })
		)
	}

	const downloadAllFiles = () => {
		dispatch(
			ProductPageThunks.downloadFiles({
				productId,
				subType: FileSubtype.text,
			})
		)
	}

	const onFileLoaderModalClose = () => {
		dispatch(actions.clearFilesToLoad())
	}
	const canEditProductFiles = useSelector(
		ProductPageAuthSelectors.getCanEditProductFiles
	)

	return (
		<>
			<AdFiles
				header={"Текстовые файлы"}
				fileTypes={fileTypes}
				files={textFiles}
				isUnresolved={false}
				onSave={saveChanges}
				onTypeChanged={changeFileType}
				deleteFile={deleteFile}
				deletedFiles={deletedFiles}
				onSubtypeChanged={() => {
					console.log("Subtype changed")
				}}
				downloadFile={downloadFile}
				downloadFiles={downloadAllFiles}
				onModalClose={onFileLoaderModalClose}
				canEditProductFiles={canEditProductFiles}
			>
				<>
					<SimpleSelect
						value={typeFilter}
						options={possibleTypeFilter}
						onChange={(e) => setTypeFilter(e)}
						toOption={ToOptionProvider.fileTypeToOption}
					/>
					<SimpleSelect
						value={formatFilter}
						options={possibleFormatFilter}
						onChange={(e) => setFormatFilter(e)}
						toOption={(e) => e}
					/>
					<SimpleSelect
						value={timeFilter}
						options={possibleTimeFilter}
						onChange={(e) => setTimeFilter(e)}
						toOption={(e) => e}
					/>
				</>
			</AdFiles>
		</>
	)
}

function GraphicProductFiles() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const dispatch = useDispatch()

	const typeFilter = useSelector(ProductGraphicFilesSelectors.getTypeFilter)
	const formatFilter = useSelector(
		ProductGraphicFilesSelectors.getFormatFilter
	)
	const timeFilter = useSelector(ProductGraphicFilesSelectors.getTimeFilter)

	const possibleTypeFilter = useSelector(
		ProductGraphicFilesSelectors.getPossibleTypeFilters
	)
	const possibleFormatFilter = useSelector(
		ProductGraphicFilesSelectors.getPossibleFormatFilters
	)
	const possibleTimeFilter = useSelector(
		ProductGraphicFilesSelectors.getPossibleTimeFilters
	)

	const fileTypes = useSelector(ProductFilesSelectors.getFileTypes)

	const graphicFiles = useSelector(
		ProductGraphicFilesSelectors.getGraphicFiles
	)
	const deletedFiles = useSelector(
		ProductGraphicFilesSelectors.getDeletedGraphicFiles
	)

	const changedFiles = useSelector(
		ProductGraphicFilesSelectors.getChangedGraphicFiles
	)

	const setFormatFilter = (formatValue: number) => {
		const format = possibleFormatFilter.find((x) => x.value === formatValue)
		if (!format) return
		dispatch(graphicFileActions.setFormatFilter(format))
	}

	const setTimeFilter = (timeFilterValue: number) => {
		const timeFilter = possibleTimeFilter.find(
			(x) => x.value === timeFilterValue
		)
		if (!timeFilter) return
		dispatch(graphicFileActions.setTimeFilter(timeFilter))
	}

	const setTypeFilter = (typeFilterValue: number) => {
		const typeFilter = possibleTypeFilter.find(
			(x) => x.id === typeFilterValue
		)
		if (!typeFilter) return
		dispatch(graphicFileActions.setFileTypeFilter(typeFilter))
	}

	const changeFileType = (file: IEcmFileDescription, fileType: IFileType) => {
		dispatch(
			graphicFileActions.setFileType({
				fileId: file.fileId,
				fileTypeId: fileType.id,
			})
		)
	}

	const deleteFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.deleteFile({ productId, fileId: file.fileId })
		)
	}

	const saveChanges = () => {
		if (changedFiles.length === 0) return

		dispatch(
			ProductPageThunks.changeFileType({ productId, files: changedFiles })
		)
	}

	const downloadFile = (file: IEcmFileDescription) => {
		dispatch(
			ProductPageThunks.downloadFile({ productId, fileId: file.fileId })
		)
	}

	const downloadAllFiles = () => {
		dispatch(
			ProductPageThunks.downloadFiles({
				productId,
				subType: FileSubtype.graphic,
			})
		)
	}

	const onFileLoaderModalClose = () => {
		dispatch(actions.clearFilesToLoad())
	}
	const canEditGraphicFiles = useSelector(
		ProductPageAuthSelectors.getCanEditProductFiles
	)
	return (
		<>
			<AdGraphicFiles
				header={"Графические файлы"}
				files={graphicFiles}
				deletedFiles={deletedFiles}
				fileTypes={fileTypes}
				onTypeChanged={changeFileType}
				onSave={saveChanges}
				deleteFile={deleteFile}
				downloadFile={downloadFile}
				downloadFiles={downloadAllFiles}
				onModalClose={() => onFileLoaderModalClose()}
				canEditGraphicFiles={canEditGraphicFiles}
			>
				<>
					<SimpleSelect
						value={typeFilter}
						options={possibleTypeFilter}
						onChange={(e) => setTypeFilter(e)}
						toOption={ToOptionProvider.fileTypeToOption}
					/>
					<SimpleSelect
						value={formatFilter}
						options={possibleFormatFilter}
						onChange={(e) => setFormatFilter(e)}
						toOption={(e) => e}
					/>
					<SimpleSelect
						value={timeFilter}
						options={possibleTimeFilter}
						onChange={(e) => setTimeFilter(e)}
						toOption={(e) => e}
					/>
				</>
			</AdGraphicFiles>
		</>
	)
}

function ProductFileLoader() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const dispatch = useDispatch()
	const possibleFileTypeMask = useSelector(
		ProductFilesSelectors.getFileTypesMask
	)
	const filesToLoad = useSelector(ProductFilesSelectors.getFilesToLoad)
	const loadingFilesCount = useSelector(
		ProductFilesSelectors.getLoadingFilesCount
	)
	const loadedFilesCount = useSelector(
		ProductFilesSelectors.getLoadedFilesCount
	)
	const fileTypes = useSelector(ProductFilesSelectors.getFileTypes)
	const canUploadFiles = useSelector(ProductFilesSelectors.getCanUploadFiles)
	const fileToUpload = useSelector(ProductFilesSelectors.getFilesToUpload)
	const canAddImages = useSelector(ProductPageAuthSelectors.getCanAddImages)

	const addFileToLoad = (file: IShortEcmFileDescription) => {
		dispatch(actions.addFileToLoad(file))
	}
	const canAuth = useSelector(ProductPageAuthSelectors.getCanChangeDisplay)
	const clearFilesToLoad = () => {
		dispatch(actions.clearFilesToLoad())
	}

	const uploadFiles = () => {
		if (!canUploadFiles) return

		for (const file of fileToUpload) {
			if (!file.file) continue
			if (!file.typeId) continue
			if (!file.subtypeId) continue

			dispatch(
				ProductPageThunks.uploadFile({
					productId: productId,
					path: getFullname(file.file.name, file.file.extension),
					fileTypeId: file.typeId,
				})
			)
		}
	}

	const setFileToLoadType = (
		file: IShortEcmFileDescription,
		fileType: number
	) => {
		dispatch(actions.setFileToLoadType({ file, typeId: fileType }))
	}

	const setFileToLoadSubType = (
		file: IShortEcmFileDescription,
		fileSubType: number
	) => {
		dispatch(actions.setFileToLoadSubtype({ file, subtypeId: fileSubType }))
	}

	return (
		<AdFileLoader
			setFileToLoadSubType={setFileToLoadSubType}
			setFileToLoadType={setFileToLoadType}
			fileTypes={fileTypes}
			uploadFiles={uploadFiles}
			canUploadFiles={canUploadFiles}
			onClose={clearFilesToLoad}
			loadingImagesCount={loadingFilesCount}
			loadedImagesCount={loadedFilesCount}
			possibleFileTypeMask={possibleFileTypeMask}
			filesToLoad={filesToLoad}
			onFileToLoadAdded={addFileToLoad}
			canAddImages={canAddImages}
			canAuth={canAuth}
		/>
	)
}
