import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IProductIdentity } from "../../domain/models/products/ProductIdentity"
import BaseApi from "../BaseApi"
import { ISimilarProduct } from "../../domain/models/similarProducts/ISimilarProduct"

export class SimilarProductApi extends BaseApi {
	getSimilarProducts = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<ISimilarProduct[]>> =>
		this.sendQuery<ISimilarProduct[]>(
			"/api/similar-products",
			data,
			actionTypes.get,
			true
		)

	getPossibleSimilarProducts = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/similar-products/possible",
			data,
			actionTypes.get,
			true
		)

	getRecommendedSimilarProducts = async (data: {
		productId: string
		count: number
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/similar-products/recommended",
			data,
			actionTypes.get,
			true
		)

	addSimilarProducts = async (data: {
		productId: string
		similarProductsIds: string[]
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/similar-products",
			data,
			actionTypes.post,
			true
		)

	removeSimilarProduct = async (data: {
		productId: string
		similarProductId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/similar-products",
			data,
			actionTypes.delete,
			true
		)

	replaceSimilarProduct = async (data: {
		productId: string
		currentSimilarId: string
		targetSimilarId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/similar-products/replace",
			data,
			actionTypes.put,
			true
		)

	swapSimilarProductSort = async (data: {
		productId: string
		currentSimilarId: string
		targetSimilarId: string
		currentSort: number
		targetSort: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/similar-products/swap-sort",
			data,
			actionTypes.put,
			true
		)
}
