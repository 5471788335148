import { IEcmFileDescription } from "../../domain/models/files/EcmFileDescription"
import { IFileType } from "../../domain/models/files/FileType"
import { FileNameLabelProvider } from "../../utils/FileNameLabelProvider"
import "./AdFiles.scss"
import React, { useState } from "react"
import { ProductFileTypeFilters } from "../../redux/reducers/pages/productPage/files/ProductFileTypeFilters"
import { SvgTag } from "../icons/SvgTag"
import { SvgIcon } from "../icons/SvgIcon"
import { DateTimeLocaleProvider } from "../../utils/DateTimeLocaleProvider"
import SimpleSelect from "../../app/common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import { FileSubtype } from "../../domain/models/files/FileSubtype"
import { Menu, MenuItem } from "@szhsin/react-menu"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API

export default function AdFiles(props: {
	header: string
	files: IEcmFileDescription[]
	deletedFiles: IEcmFileDescription[]
	fileTypes: IFileType[]
	onSubtypeChanged: (
		file: IEcmFileDescription,
		subtype: FileSubtype,
		fileTypes: IFileType[]
	) => void
	onTypeChanged: (file: IEcmFileDescription, type: IFileType) => void
	onSave: () => void
	isUnresolved: boolean
	deleteFile: (file: IEcmFileDescription) => void
	downloadFile: (file: IEcmFileDescription) => void
	downloadFiles: () => void
	children?: React.ReactNode
	onModalClose: () => void
	canEditProductFiles: boolean
}) {
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
	const [isAnyChangesWereDone, setIsAnyChangesWereDone] = useState(false)

	const onSubtypeChanged = (
		file: IEcmFileDescription,
		subtype: FileSubtype
	) => {
		props.onSubtypeChanged(file, subtype, props.fileTypes)
		setIsAnyChangesWereDone(true)
	}

	const onTypeChanged = (file: IEcmFileDescription, type: number) => {
		const fileType = props.fileTypes.find((x) => x.id === type)
		if (!fileType) return
		props.onTypeChanged(file, fileType)
		setIsAnyChangesWereDone(true)
	}

	const onSave = () => {
		props.onSave()
		setIsAnyChangesWereDone(false)
		setIsEditModeEnabled(false)
	}

	return props.files.length !== 0 || props.deletedFiles.length !== 0 ? (
		<>
			<div
				className={
					"u-droppod-section-header__small header-with-toolbar"
				}
			>
				{props.header}
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						{props.children}
						{!isEditModeEnabled ? (
							<>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => setIsEditModeEnabled(true)}
									disabled={
										//false ||
										!props.canEditProductFiles
									}
								>
									<SvgIcon
										name={"pencil-create"}
										className={"u-icon"}
									/>
								</button>
								<button
									className={"u-button primary large"}
									onClick={props.downloadFiles}
								>
									Скачать все файлы
								</button>
							</>
						) : (
							<>
								<button
									type="button"
									className="u-button large primary"
									onClick={() => setIsEditModeEnabled(false)}
									disabled={false}
								>
									Выйти из режима редактирования
								</button>
								{isAnyChangesWereDone ? (
									<button
										className="u-button square primary"
										onClick={onSave}
									>
										<SvgIcon
											name={"floppy"}
											className={"u-icon"}
										/>
									</button>
								) : (
									<></>
								)}
								<Menu
									menuButton={
										<div>
											<SvgIcon
												name={"more-horizontal"}
												className={"u-icon"}
											/>
										</div>
									}
								>
									<MenuItem onClick={props.downloadFiles}>
										Скачать все файлы
									</MenuItem>
									{/*<MenuItem>Удалить все</MenuItem>*/}
								</Menu>
							</>
						)}
					</div>
				</div>
			</div>

			<div className={"images-grid-wrapper-7"}>
				{props.files.map((x) => (
					<AdFile
						key={x.name}
						file={x}
						downloadFile={props.downloadFile}
						fileTypes={props.fileTypes}
						onSubtypeChanged={onSubtypeChanged}
						onTypeChanged={onTypeChanged}
						isUnresolved={props.isUnresolved}
						deleteFile={props.deleteFile}
						isEditable={isEditModeEnabled}
					/>
				))}
				{props.deletedFiles.map((file) => {
					return (
						<div key={file.name} className={"deleted-block"}>
							<div>{file.name}</div>
							<div>Файл удален</div>
						</div>
					)
				})}
			</div>
		</>
	) : (
		<></>
	)
}

export function AdFile(props: {
	file: IEcmFileDescription
	fileTypes: IFileType[]
	onSubtypeChanged: (file: IEcmFileDescription, subtype: FileSubtype) => void
	onTypeChanged: (file: IEcmFileDescription, type: number) => void
	isUnresolved: boolean
	deleteFile: (file: IEcmFileDescription) => void
	downloadFile: (file: IEcmFileDescription) => void
	isEditable?: boolean
}) {
	const fileType = props.fileTypes.find((x) => x.id === props.file.fileTypeId)
	if (!fileType) return null

	const possibleFileSubTypes = props.isUnresolved
		? ProductFileTypeFilters.getPossibleFileSubtypesByFile(
				props.file,
				props.fileTypes
		  )
		: [props.file.fileSubTypeId]

	const subType = possibleFileSubTypes.find(
		(x) => x === props.file.fileSubTypeId
	)

	const extensionsTagName = FileNameLabelProvider.getFileLabelTagName(
		props.file.extension
	)

	const possibleTypes = ProductFileTypeFilters.getPossibleFileTypesByFile(
		props.file,
		props.fileTypes
	).filter((x) => x.subType == props.file.fileSubTypeId)

	const typeTagName = FileNameLabelProvider.getFileTypeTagName(fileType.id)

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false)

	return (
		<div className={"block-wrapper"}>
			{props.isEditable ? (
				<div className={"selectors-edit-wrapper"}>
					{props.isUnresolved ? (
						<>
							<SimpleSelect
								value={subType ?? FileSubtype.unknown}
								options={possibleFileSubTypes}
								onChange={(s) =>
									props.onSubtypeChanged(props.file, s)
								}
								toOption={ToOptionProvider.fileSubtypeToOption}
							/>
						</>
					) : (
						<></>
					)}
					{props.file.fileSubTypeId === FileSubtype.unknown ? (
						<></>
					) : (
						<SimpleSelect
							value={fileType}
							options={possibleTypes}
							onChange={(t) => props.onTypeChanged(props.file, t)}
							toOption={ToOptionProvider.fileTypeToOption}
						/>
					)}
				</div>
			) : (
				<></>
			)}
			<div className={"image-wrapper"}>
				<img
					src={`${staticServer}/${props.file.thumbnailPath}`}
					alt={props.file.name}
					className={"middle-block__ala-image"}
				/>
				{props.isEditable ? (
					<></>
				) : (
					<div className={"tags-wrapper"}>
						<SvgTag name={extensionsTagName} className={"tag"} />
						<SvgTag name={typeTagName} className={"tag"} />
					</div>
				)}
				{props.isEditable ? (
					<SvgIcon
						name={"close"}
						className={"u-icon in-round close-i"}
						onClick={() => {
							setIsDeleteConfirmationDialogOpen(true)
						}}
					/>
				) : (
					<></>
				)}
				<div className={"share-wrapper"}>
					<SvgIcon
						name={"download"}
						className={"u-icon in-round"}
						onClick={() => props.downloadFile(props.file)}
					/>
					{/*
					<SvgIcon name={"share"} className={"u-icon in-round"} />
*/}
				</div>
			</div>

			<div className={"texts"}>
				<div className={"image-name"}>{props.file.name}</div>
				<div className={"image-date"}>
					{new Date(props.file.modifyDate).toLocaleDateString(
						DateTimeLocaleProvider.getDefaultLocale(),
						DateTimeLocaleProvider.getDefaultLocaleOptions()
					)}
				</div>
			</div>

			{isDeleteConfirmationDialogOpen ? (
				<DeleteImageConfirmationDialog
					onCancel={() => setIsDeleteConfirmationDialogOpen(false)}
					onConfirm={() => {
						setIsDeleteConfirmationDialogOpen(false)
						props.deleteFile(props.file)
					}}
				/>
			) : (
				<></>
			)}
		</div>
	)
}

function DeleteImageConfirmationDialog(props: {
	onCancel: () => void
	onConfirm: () => void
}) {
	return (
		<div className={"delete-image-confirmation"}>
			<div className={"header"}>Вы уверены, что хотите удалить файл?</div>
			<br />
			Данное действие нельзя отменить.
			<div className={"buttons-wrapper"}>
				<button
					onClick={props.onCancel}
					className={"u-button small outline"}
				>
					Назад
				</button>
				<button
					onClick={props.onConfirm}
					className={"u-button small accent"}
				>
					Удалить
				</button>
			</div>
		</div>
	)
}
