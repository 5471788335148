import { AppState } from "../../index"

export class ProductsPageSelectors {
	/**
	 * Возвращает выбранную ценовую группу при нажатии на продукт или группу, по ней фильтруются продукты загруженные с сервера
	 * фильтрация про priceGroup идет в памяти, это на случай, если выбраны все ценовые(номенклатурные) группы
	 * */
	static getCheckedPriceGroup = (state: AppState) =>
		state.pages.productsPage.common.chosenPriceGroup
	/**
	 * Возвращает выбрана ли хоть один продукт
	 * */
	static getIsProductChecked = (state: AppState) =>
		state.pages.productsPage.common.isProductChecked

	static getSelectedWebsite = (state: AppState) =>
		state.pages.productsPage.common.selectedWebsite
}
