import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { IProductInfo } from "../../../../domain/models/products/ProductInfo"
import { ProductPageThunks } from "./thunks"
import { IProductAttribute } from "../../../../domain/models/products/ProductAttribute"
import { reducer as characteristicsReducer } from "./characteristics"
import { reducer as imagesReducer } from "./images"
import { reducer as scopesReducer } from "./scopes"
import { reducer as webCategoriesReducer } from "./webCategories"
import { reducer as relatedProductsReducer } from "./relatedProducts"
import { reducer as similarProductsReducer } from "./similarProducts"
import { reducer as productCatalogsReducer } from "./productCatalogs"
import { reducer as filesReducer } from "./files"

export type ProductPageCommonState = {
	product: IProductInfo | null
	productAttributes: IProductAttribute[]
	isProductLoading: boolean
	isProductUpdating: boolean
	isProductRemoving: boolean
}

const INITIAL_STATE: ProductPageCommonState = {
	product: null,
	productAttributes: [],
	isProductLoading: false,
	isProductUpdating: false,
	isProductRemoving: false,
}

const slice = createSlice({
	name: "productPage",
	initialState: INITIAL_STATE,
	reducers: {
		setName: (state, { payload }: PayloadAction<string>) => {
			if (state.product !== null) {
				state.product.name = payload
			}
		},
		setPhotoChecked: (state, { payload }: PayloadAction<boolean>) => {
			if (state.product !== null) {
				state.product.isImagesChecked = payload
			}
		},
		setDescriptionChecked: (state, { payload }: PayloadAction<boolean>) => {
			if (state.product !== null) {
				state.product.isDescriptionChecked = payload
			}
		},
		setAttributeValue: (
			state,
			{ payload }: PayloadAction<{ attributeId: number; value: string }>
		) => {
			const productAttribute = state.productAttributes.find(
				(x) => x.attributeId === payload.attributeId
			)
			if (productAttribute !== undefined) {
				productAttribute.value = payload.value
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(ProductPageThunks.getProduct.pending, (state) => {
			state.isProductLoading = true
		})
		builder.addCase(
			ProductPageThunks.getProduct.fulfilled,
			(state, { payload }) => {
				state.isProductLoading = false
				state.product = payload
			}
		)
		builder.addCase(ProductPageThunks.getProduct.rejected, (state) => {
			state.isProductLoading = false
		})
		builder.addCase(
			ProductPageThunks.getProductAttributes.fulfilled,
			(state, { payload }) => {
				state.productAttributes = payload
			}
		)
		builder.addCase(ProductPageThunks.updateProduct.pending, (state) => {
			state.isProductUpdating = true
		})
		builder.addCase(ProductPageThunks.updateProduct.fulfilled, (state) => {
			state.isProductUpdating = false
		})
		builder.addCase(ProductPageThunks.updateProduct.rejected, (state) => {
			state.isProductUpdating = false
		})
		builder.addCase(ProductPageThunks.deleteProduct.pending, (state) => {
			state.isProductRemoving = true
		})
		builder.addCase(ProductPageThunks.deleteProduct.fulfilled, (state) => {
			state.isProductRemoving = false
		})
		builder.addCase(ProductPageThunks.deleteProduct.rejected, (state) => {
			state.isProductRemoving = false
		})
	},
})

const actions = slice.actions

const reducer = combineReducers({
	common: slice.reducer,
	characteristicsState: characteristicsReducer,
	imagesState: imagesReducer,
	scopesState: scopesReducer,
	webCategoriesState: webCategoriesReducer,
	relatedProductsState: relatedProductsReducer,
	similarProductsState: similarProductsReducer,
	productCatalogsState: productCatalogsReducer,
	filesState: filesReducer,
})

export { actions, reducer }
