import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"

export class TreeThunks {
    static getGroups = createAutogenApiThunk({
        typePrefix: "tree-page/get-product-groups",
        apiCall: Api.productGroups.getProductsGroupsByCategory,
        errorMessage: "Не удалось получить список групп продуктов"
    })

    static getProductByGroups = createAutogenApiThunk({
        typePrefix: "tree-page/get-product-by-group",
        apiCall: Api.products.getProductsIdentityByGroup,
        errorMessage: "Не удалось получить список продуктов"
    })

    static recountSort = createAutogenApiThunk({
        typePrefix: "tree-page/recount-product-groups-sort",
        apiCall: Api.category.recountProductGroupSort,
        errorMessage: "Не удалось пересчитать порядок карточек",
        showLoader: true,
        showSuccess: true
    })

    static changeSort = createAutogenApiThunk({
        typePrefix: "tree-page/change-product-groups-sort",
        apiCall: Api.category.changeProductGroupSort,
        isOptimistic: true,
        errorMessage: "Не удалось изменить порядок карточек, перезагрузите страницу",
        errorMessagesByStatusCode: {
            409: "Значение сортировки выходит за пределы допустимого диапазона"
        }
    })

    static removeGroupFromCat = createAutogenApiThunk({
        typePrefix: "tree-page/remove-product-groups-from-cats",
        apiCall: Api.category.removeProductGroupsFromCats,
        isOptimistic: true,
        errorMessage: "Не удалось удалить группы из категорий, перезагрузите страницу"
    })

    static getCategoriesSortBorders = createAutogenApiThunk({
        typePrefix: "tree-page/get-categories-sort-borders",
        apiCall: Api.category.getCategoriesSortBorders,
        errorMessage: "Не удалось получить границы сортировки категорий, невозможно пересчитывать сортировку"
    })
}