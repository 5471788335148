import React from "react"
import CategoryTree from "../../library/smartComponents/category/CategoryTree"
import CategoryGroupToolbar from "./CategoryGroupToolbar"

export default function CategoryForm(props: ICategoryFormProps) {
    return <div className="cat-product-cat-list-container u-droppod">
        <div className="u-droppod-header">
            <div className="header-text">
                Категории
            </div>
        </div>
        <CategoryGroupToolbar shouldDisableControls={props.shouldDisableControls}/>
        <CategoryTree highlightedRows={props.highlightedCategories}/>
    </div>
}

interface ICategoryFormProps {
    highlightedCategories: number[],
    shouldDisableControls?: boolean
}