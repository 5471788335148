import BaseApi from "../BaseApi"
import actionTypes, {IApplicationResponse} from "../baseTypes"
import {IAttribute} from "../../domain/types"

export default class AttributeApi extends BaseApi {

    getAttributes = async (data: { languageId: number }): Promise<IApplicationResponse<IAttribute[]>> =>
        this.sendQuery<IAttribute[]>("/api/attributes", data, actionTypes.get, true)

    changeImportantStatus = async (data: {
        groupId: string,
        attributeId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/important-status", data, actionTypes.put, true)

    addAttribute = async (data: { productGroupId: string, attributeId: number }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/add-attribute", data, actionTypes.post, true)

    removeAttribute = async (data: {
        productGroupId: string,
        attributeId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/remove-attribute", data, actionTypes.delete, true)

    changeAttributeOrder = async (data: {
        productGroupId: string,
        attributeId: number,
        targetAttributeId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/change-attribute-ordering", data, actionTypes.put, true)

    changeImportantSort = async (data: {
        productGroupId: string,
        attributeId: number,
        targetAttributeId: number,
        currentSort: number,
        targetSort: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/important-sort", data, actionTypes.put, true)

    changeAttributeShortname = async (data: {
        attributeId: number,
        shortname: string,
        languageId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/change-attribute-shortname", data, actionTypes.put, true)

    changeAttributeUnit = async (data: {
        attributeId: number,
        unit: string,
        languageId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/attributes/change-attribute-unit", data, actionTypes.put, true)
}