import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import {BrowserRouter as Router} from "react-router-dom"
import {App} from "./app/App"
import "./default-colors.scss"
import "./library/smartComponents/tables/tables-styles.scss"
import "./utils.scss"
import {initializeExtension} from "./domain/extensions"

initializeExtension()

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
    <Router>
        <App/>
    </Router>
)
