import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { ICatalog, IProductGroupCatalog } from "../../domain/types"
import { IProductCatalog } from "../../domain/models/catalogs/ProductCatalog"

export default class CatalogsApi extends BaseApi {
	getCatalogs = (): Promise<IApplicationResponse<ICatalog[]>> =>
		this.sendQuery<ICatalog[]>("/api/catalogs", null, actionTypes.get, true)

	getProductGroupCatalogs = async (data: {
		productGroupId: string
	}): Promise<IApplicationResponse<IProductGroupCatalog[]>> =>
		this.sendQuery<IProductGroupCatalog[]>(
			"/api/catalogs/group-catalogs",
			data,
			actionTypes.get,
			true
		)

	getProductCatalogs = async (data: {
		productId: string
	}): Promise<IApplicationResponse<IProductCatalog[]>> =>
		this.sendQuery<IProductCatalog[]>(
			"/api/catalogs/product-catalogs",
			data,
			actionTypes.get,
			true
		)

	changeProductShowStatus = async (
		data: {
			productId: string
			catalogCategoryId: number | null
			webCategoryId: number | null
			catalogId: number
			showStatus: boolean
		}[]
	): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/catalogs/change-product-show-status",
			data,
			actionTypes.put,
			true
		)

	changeShowStatus = async (
		data: {
			productGroupId: string
			catalogCategoryId: number | null
			webCategoryId: number | null
			catalogId: number
			showStatus: boolean
		}[]
	): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/catalogs/change-show-status",
			data,
			actionTypes.put,
			true
		)
}
