import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { ProductPageImageSelectors } from "../../../redux/reducers/pages/productPage/images/ProductPageImageSelectors"
import { useEffect } from "react"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { INamedImage } from "../../../domain/models/images/NamedImage"
import { actions as imagesActions } from "../../../redux/reducers/pages/productPage/images"
import AdImages from "../../../library/images/AdImages"
import { IShortEcmFileDescription } from "../../../domain/models/files/EcmFileDescription"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export function ProductImages() {
	const dispatch = useDispatch()

	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	useEffect(() => {
		return () => {
			dispatch(imagesActions.clearLoadImages())
		}
	}, [])

	const images = useSelector(ProductPageImageSelectors.getImages)
	const filteredImageTypes = useSelector(
		ProductPageImageSelectors.getFilteredImageTypes
	)
	const imageTypes = useSelector(ProductPageImageSelectors.getImageTypes)
	const imagesToLoad = useSelector(ProductPageImageSelectors.getImagesToLoad)
	const possibleFileTypeMask = useSelector(
		ProductPageImageSelectors.getPossibleFileTypesMask
	)
	const canUploadImages = useSelector(
		ProductPageImageSelectors.getCanUploadImages
	)
	const imagesToUpload = useSelector(
		ProductPageImageSelectors.getImagesToUpload
	)
	const loadingImagesCount = useSelector(
		ProductPageImageSelectors.getLoadingImagesCount
	)
	const loadedImagesCount = useSelector(
		ProductPageImageSelectors.getLoadedImagesCount
	)
	const deletedImages = useSelector(
		ProductPageImageSelectors.getDeletedImages
	)
	const canAddVideo = useSelector(ProductPageAuthSelectors.getCanAddVideo)
	const canLoadFiles = useSelector(ProductPageAuthSelectors.getCanLoadFiles)
	const canEditFiles = useSelector(ProductPageAuthSelectors.getCanEditFiles)
	const canLoadImage = useSelector(ProductPageAuthSelectors.getCanLoadImages)
	const canAddImages = useSelector(ProductPageAuthSelectors.getCanAddImages)

	useEffect(() => {
		dispatch(ProductPageThunks.getImages({ productId: productId }))
		dispatch(ProductPageThunks.getImageTypes())
	}, [])

	const uploadImages = () => {
		if (!canUploadImages) return

		for (const image of imagesToUpload) {
			if (!image.file) continue
			if (!image.typeId) continue
			dispatch(
				ProductPageThunks.uploadImage({
					productId: productId,
					imageName: image.file.name + "." + image.file.extension,
					imageType: image.typeId,
				})
			)
		}
	}

	const updateImageType = (image: INamedImage, targetTypeId: number) => {
		dispatch(
			ProductPageThunks.updateImageType({
				productId: productId,
				currentTypeId: image.typeId,
				targetTypeId: targetTypeId,
			})
		)
	}

	const addImageToLoad = (file: IShortEcmFileDescription) => {
		dispatch(imagesActions.addImageToLoad(file))
	}

	const setImageToLoadType = (
		file: IShortEcmFileDescription,
		imageType: number
	) => {
		dispatch(
			imagesActions.setImageToLoadType({
				file: file,
				typeId: imageType,
			})
		)
	}

	const deleteImage = (image: INamedImage) => {
		dispatch(
			ProductPageThunks.removeImage({
				productId: productId,
				imageType: image.typeId,
			})
		)
	}

	const uploadVideo = (url: string) => {
		dispatch(
			ProductPageThunks.uploadVideo({
				productId: productId,
				videoUrl: url,
			})
		)
	}

	const clearImagesToLoad = () => {
		dispatch(imagesActions.clearLoadImages())
	}

	const downloadImages = () => {
		dispatch(ProductPageThunks.downloadImages({ productId: productId }))
	}

	return (
		<>
			<AdImages
				possibleFileTypeMask={possibleFileTypeMask}
				setImageToLoadType={(file, imageType) =>
					setImageToLoadType(file, imageType)
				}
				allImageTypes={imageTypes}
				filteredImageTypes={filteredImageTypes}
				images={images}
				deletedImages={deletedImages}
				imageToLoad={imagesToLoad}
				addImageToLoad={addImageToLoad}
				uploadImages={uploadImages}
				updateImageType={updateImageType}
				deleteImage={deleteImage}
				uploadVideo={uploadVideo}
				canEdit={true}
				canAdd={true}
				loadingImagesCount={loadingImagesCount}
				loadedImagesCount={loadedImagesCount}
				canUploadImages={canUploadImages}
				onModalClose={clearImagesToLoad}
				downloadImages={downloadImages}
				canAddVideo={canAddVideo}
				canLoadFiles={canLoadFiles}
				canEditFiles={canEditFiles}
				canLoadImage={canLoadImage}
				canAddImages={canAddImages}
			/>
		</>
	)
}
