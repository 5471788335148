import React, {useEffect, useState} from "react"
import {SvgIcon} from "../icons/SvgIcon"
import {isEscape} from "../../utils/KeysCodes"

interface AdSearchInputProps {
    placeholder?: string;
    value?: string;
    onChange: (newValue: string) => void;
    onKeyUp?: (e: any) => void;
    style?: any;
    className?: string;
    hidden?: boolean
    disabled?: boolean
}

export default function AdSearchInput(props: AdSearchInputProps) {
    const [focus, setFocus] = useState(false)

    const [value, setValue] = useState<string>(props.value || "")

    const iconClassName = "u-icon input-icon " + (value.length == 0 ? "--hide " : "")

    useEffect(() => {
        props.onChange(value)
    }, [value])

    const onKeyUp = (e) => {
        if(isEscape(e)) {
            setValue("")
        }
        else {
            props.onKeyUp && props.onKeyUp(e)
        }
    }

    return (
        <div style={props.style}
            className={(props.className || "") + " u-search-input " + ((focus || value.length > 0) ? "dark-text" : "")}
            hidden={props.hidden}>
            <div className="input-with-button-wrapper">
                <input type="text"
                    value={value}
                    onChange={e => setValue(e.currentTarget.value)}
                    onKeyUp={e => onKeyUp(e)}
                    placeholder={props.placeholder || "Поиск"}
                    disabled={props.disabled ?? false}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
                <SvgIcon name="search" className="search-icon"/>
                <SvgIcon name="circle-x"
                    className={iconClassName}
                    onClick={() => {
                        setValue("")
                    }}
                />
            </div>
        </div>
    )
}