import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IPriceGroup } from "../../../domain/types"
import _ from "lodash"
import { PriceGroupThunks } from "./thunk"
import Constants from "../../../domain/Constants"

export type PriceGroupState = {
	priceGroups: IPriceGroup[]
	selected: IPriceGroup
}

const defaultPriceGroup = { id: -1, name: "Загрузка" }
//мб вынести в бд в отдельную запись
const allPriceGroups = {
	id: Constants.AllPriceGroupsId,
	name: "Все номенклатурные группы",
}

const INITIAL_STATE: PriceGroupState = {
	priceGroups: [defaultPriceGroup],
	selected: defaultPriceGroup,
}

const priceGroup = createSlice({
	name: "priceGroups",
	initialState: INITIAL_STATE,
	reducers: {
		setSelected(state: PriceGroupState, action: PayloadAction<number>) {
			state.selected =
				state.priceGroups.find((x) => x.id === action.payload) ??
				state.selected
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			PriceGroupThunks.getPriceGroups.fulfilled,
			(state, { payload }) => {
				state.priceGroups = _.orderBy(payload, (x) => x.name)
				state.priceGroups.unshift(allPriceGroups)
				if (state.selected.id === -1)
					state.selected = state.priceGroups[0]
				if (payload.some((x) => x.id === state.selected.id))
					state.selected =
						payload.find((x) => x.id === state.selected.id) ??
						state.selected
				else {
					const avtoDelo = payload.find(
						(x) => x.id === Constants.AvtodeloPriceGroupId
					)
					if (avtoDelo) {
						state.selected = avtoDelo
					} else {
						state.selected = state.priceGroups[0]
					}
				}
			}
		)
		builder.addCase(PriceGroupThunks.getPriceGroups.rejected, (state) => {
			state.priceGroups = [
				{ id: -1, name: "Ошибка загрузки номенклатурных групп" },
			]
		})
	},
})

const reducer = priceGroup.reducer
const actions = priceGroup.actions

export { reducer, actions }
