import {ILanguage, ITranslateWithUrl} from "../../../../domain/types"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {TranslatorPageThunks} from "./thunks"
import {PhraseType} from "../translatePage"

export type ITrackedTranslateWithUrl = ITranslateWithUrl & {newTranslate: string | undefined}

export type TranslatorPageState = {
    translates: ITrackedTranslateWithUrl[],
    canTranslate: boolean,
    canSaveTranslates: boolean,
    selectedPhraseType: PhraseType
    phraseTypes: PhraseType[]
    search: string,
    selectedLanguage: ILanguage,
    shouldEndWorkWithPage: boolean
}

const INITIAL_STATE: TranslatorPageState = {
    translates: [],
    canTranslate: false,
    canSaveTranslates: false,
    selectedPhraseType: PhraseType.NotTranslated,
    phraseTypes: [PhraseType.All, PhraseType.NotTranslated],
    search: "",
    selectedLanguage: {id: 0, name: "Loading..."},
    shouldEndWorkWithPage: false
}

const checkCanSaveTranslates = (translates : ITrackedTranslateWithUrl[]) : boolean => {
    return translates
        .filter(t => t.translate != t.newTranslate)
        .length > 0
}

const slice = createSlice({
    name: "translator-page",
    initialState: INITIAL_STATE,
    reducers: {
        setPhraseType(state: TranslatorPageState, {payload: phraseType}: PayloadAction<number>) {
            if(phraseType === PhraseType.All || phraseType === PhraseType.NotTranslated)
                state.selectedPhraseType = phraseType
        },
        setSearch(state: TranslatorPageState, {payload: search}: PayloadAction<string>) {
            state.search = search
        },
        setLanguage(state: TranslatorPageState, {payload: language}: PayloadAction<ILanguage>) {
            state.selectedLanguage = language
        },
        setTranslatedValue(state: TranslatorPageState, {payload: data}: PayloadAction<{translate: ITrackedTranslateWithUrl, newValue: string}>)
        {
            const translatedValue = state.translates
                .find(x => x.translateId == data.translate.translateId)
                
            if(translatedValue != null)
            {
                translatedValue.newTranslate = data.newValue
            }
            
            state.canSaveTranslates = checkCanSaveTranslates(state.translates)
        }
    },
    extraReducers: builder => {
        builder.addCase(TranslatorPageThunks.getTranslatesWithUrl.fulfilled, (state, action) => {
            state.translates = action.payload.map(x => ({
                ...x,
                translate: x.translate ?? "",
                newTranslate: x.translate ?? ""
            }))
            state.canTranslate = state.translates
                .filter(t => t.russian != null && t.russian != "")
                .length > 0
        })
        builder.addCase(TranslatorPageThunks.translateValuesByTranslator.fulfilled, (state, action) =>
        {
            state.translates.forEach(t =>
            {
                const translatedValue = action.payload
                    .find(x => x.original == t.russian)
                        
                if (translatedValue != null)
                {
                    t.newTranslate = translatedValue.translated
                }
            })
                
            state.canSaveTranslates= checkCanSaveTranslates(state.translates)
        })
        builder.addCase(TranslatorPageThunks.saveTranslatedValues.fulfilled, (state) =>
        {
            state.shouldEndWorkWithPage = true
        })
    }
})

const actions = slice.actions
const reducer = slice.reducer

export {actions, reducer}