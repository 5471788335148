import Api from "../../../api"
import {createAutogenApiThunk, createAutogenNoArgsApiThunk} from "../../createApiThunkV2"

export class LanguagesThunks {
    static getLanguages = createAutogenNoArgsApiThunk({
        typePrefix: "get/languages",
        apiCall: Api.translate.getLanguages,
        errorMessage: "Не удалось получить список языков"
    })

    static addLanguage = createAutogenApiThunk({
        typePrefix: "add/language",
        apiCall: Api.translate.addNewLanguage,
        showLoader: true,
        errorMessage: "Не удалось добавить язык",
        errorMessagesByStatusCode: {
            409: "Язык с таким именем уже существует"
        }
    })

    static removeLanguage = createAutogenApiThunk({
        typePrefix: "remove/language",
        apiCall: Api.translate.removeLanguage,
        showLoader: true,
        errorMessage: "Не удалось удалить язык"
    })
    
    static cleanUpTranslations = createAutogenApiThunk({
        typePrefix: "translator-page/download-translates",
        apiCall: Api.translate.cleanUpTranslations,
        errorMessage: "Не удалось удалить переводы.",
        successMessage: "Переводы успешно удалены",
        showLoader: true
    })
}