import { AppState } from "../../index"
import { AuthSelectors } from "../../auth/authSelectors"
import { createSelector } from "@reduxjs/toolkit"
import { Role } from "../../../../domain/Role"
import { LanguageSelectors } from "../../languages/languageSelectors"
import Constants from "../../../../domain/Constants"
import { GroupPageSelectors } from "./groupPageSelectors"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../domain/GroupMembership"

export class GroupPageAuthSelectors {
	static getHasRightsCreateGroup = createSelector(
		[
			(state: AppState) =>
				AuthSelectors.hasOneOfRoles(state, [
					Role.ContentManagerLv,
					Role.ContentManagerRF,
					Role.ContentManagerRg
				]),
		],
		(hasRoles) => hasRoles
	)

	static getCanCreateGroup = createSelector(
		[
			GroupPageAuthSelectors.getHasRightsCreateGroup,
			GroupPageSelectors.getGroup,
			LanguageSelectors.getSelected,
		],
		(hasRights, group, language) =>
			hasRights &&
			!group.wasCreate &&
			language.id === Constants.RussianLanguage
	)

	static getCanChangeId = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			return (isContentLv || isContentRf || isContentRg) && !group.wasCreate
		}
	)

	static getCanChangeName = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
			LanguageSelectors.getSelected,
		],
		(isContentLv, isContentRg, isContentRf, group, language) => {
			let canChangeRusName = false
			let canChangeNotRusName = false
			const isRussianLanguage = language.id == Constants.RussianLanguage

			const groupMembership = getGroupMembership(group.priceGroupId)

			switch (groupMembership) {
				case GroupMembership.Nothing:
					canChangeRusName =
						(isContentRf && isRussianLanguage) ||
						((isContentLv || isContentRg) && !group.wasCreate) ||
						((isContentLv || isContentRg) && !isRussianLanguage)
					canChangeNotRusName = isContentLv || isContentRg && !isRussianLanguage
					break
				case GroupMembership.Russian:
					canChangeRusName = isContentRf && isRussianLanguage
					canChangeNotRusName = isContentLv || isContentRg && !isRussianLanguage
					break
				case GroupMembership.Latvian:
					canChangeRusName = isContentLv || isContentRg
					canChangeNotRusName = isContentLv || isContentRg
					break
			}

			return canChangeRusName || canChangeNotRusName
		}
	)

	static getCanChangeIsToolset = createSelector(
		[GroupPageSelectors.getGroup],
		(group) => !group.wasCreate
	)

	static getCanChangeDescriptionChecked = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			console.log(`${isContentLv} ${isContentRg} ${isContentRf} ${groupMembership} ${group.priceGroupId}`);

			switch (groupMembership) {
				case GroupMembership.Nothing:
					return (isContentRf || isContentLv || isContentRg) && group.wasCreate
				case GroupMembership.Russian:
					return isContentRf && group.wasCreate
				case GroupMembership.Latvian:
					return (isContentLv || isContentRg) && group.wasCreate
			}
		}
	)

	static getCanChangeImageChecked = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			AuthSelectors.isPhotographer,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, isPhotographer, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					return (
						(isContentRf || isContentLv || isPhotographer || isContentRg) &&
						group.wasCreate
					)
				case GroupMembership.Latvian:
					return (isContentLv || isContentRg) && group.wasCreate
			}
		}
	)

	static getCanDeleteGroup = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			switch (groupMembership) {
				case GroupMembership.Nothing:
					return isContentLv || isContentRf || isContentRg
				case GroupMembership.Russian:
					return isContentRf
				case GroupMembership.Latvian:
					return isContentLv
			}
		}
	)

	static getCanControlRecommendations = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			let canControl = false

			const groupMembership = getGroupMembership(group.priceGroupId)
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg || isContentRg
					break
			}
			return canControl
		}
	)

	static getCanChangeShowStatusToPrintedCatalogs = createSelector(
		[AuthSelectors.isContentRf],
		(isContentRf) => isContentRf
	)

	static getCanChangeShowStatusToWebCatalogs = createSelector(
		[AuthSelectors.isContentLv, AuthSelectors.isContentRg],
		(isContentLv, isContentRg) => isContentLv || isContentRg
	)

	static getCanChangeShowStatusToAutoinstrumentCatalog = createSelector(
		[AuthSelectors.isPhotographer, AuthSelectors.isAdmin],
		(isPhotographer, isAdmin) => isPhotographer || isAdmin
	)

	static getCanAccessToRussianWebsites = createSelector(
		[AuthSelectors.isPhotographer],
		(isPhotographer) => isPhotographer
	)

	static getCanAccessToLatvianWebsites = createSelector(
		[AuthSelectors.isContentLv, AuthSelectors.isContentRg],
		(isContentLv, isContentRg) => isContentLv || isContentRg
	)

	static getCanControlScopes = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			let canControl = false

			const groupMembership = getGroupMembership(group.priceGroupId)
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}
			return canControl
		}
	)

	static getShouldShowPrintCategories = createSelector(
		[GroupPageSelectors.getGroup],
		(group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			return groupMembership !== GroupMembership.Latvian
		}
	)

	static getCanControlPrintCategories = createSelector(
		[AuthSelectors.isContentRf],
		(isContentRf) => {
			return isContentRf
		}
	)

	static getCanControlWebCategories = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			return groupMembership == GroupMembership.Latvian
				? isContentLv || isContentRg
				: isContentLv || isContentRf || isContentRg
		}
	)

	static getCanControlDescription = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			AuthSelectors.isAdmin,
			LanguageSelectors.getSelected,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, isAdmin, language, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			let canAccessRussian = false
			let canAccessNotRussian = false

			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canAccessRussian =
						isContentRf && language.id == Constants.RussianLanguage
					canAccessNotRussian = false
					break
				case GroupMembership.Latvian:
					canAccessRussian = isContentLv || isContentRg
					canAccessNotRussian = isContentLv || isContentRg
					break
			}

			if (isAdmin) {
				canAccessRussian = true
				canAccessNotRussian = true
			}

			return language.id == Constants.RussianLanguage
				? canAccessRussian
				: canAccessNotRussian
		}
	)

	static getCanControlImages = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			AuthSelectors.isPhotographer,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, isPhotographer, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)

			let canControl = false

			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf || isPhotographer
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
			}
			return canControl
		}
	)

	static getCanControlPictograms = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			AuthSelectors.isPhotographer,

			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, isPhotographer, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)

			let canControl = false

			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf || isPhotographer
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
			}
			return canControl
		}
	)

	static getCanControlImportantAttrs = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			let canAccessToChangeImportantStatusAttrs = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canAccessToChangeImportantStatusAttrs =
						isContentLv || isContentRf || isContentRg
					break
				case GroupMembership.Latvian:
					canAccessToChangeImportantStatusAttrs = isContentLv || isContentRg
					break
			}

			return canAccessToChangeImportantStatusAttrs
		}
	)

	static getCanControlAttributes = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)

			let canControl = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}

			return canControl
		}
	)

	static getCanPickAttributes = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			let canPickAttributes = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canPickAttributes = isContentLv || isContentRg || isContentRf
					break
				case GroupMembership.Latvian:
					canPickAttributes = isContentLv || isContentRg
					break
			}

			return canPickAttributes
		}
	)

	static getCanControlProducts = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)

			let canControl = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
					canControl = isContentRf || isContentLv
					break
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}
			return canControl
		}
	)

	static getCanSortProducts = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)

			let canControl = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}
			return canControl
		}
	)

	static getCanControlToolsetPictograms = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			let canControl = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}

			return canControl
		}
	)

	static getCanControlToolsetProducts = createSelector(
		[
			AuthSelectors.isContentLv,
			AuthSelectors.isContentRg,
			AuthSelectors.isContentRf,
			GroupPageSelectors.getGroup,
		],
		(isContentLv, isContentRg, isContentRf, group) => {
			const groupMembership = getGroupMembership(group.priceGroupId)
			let canControl = false
			switch (groupMembership) {
				case GroupMembership.Nothing:
				case GroupMembership.Russian:
					canControl = isContentRf
					break
				case GroupMembership.Latvian:
					canControl = isContentLv || isContentRg
					break
			}

			return canControl
		}
	)
}
