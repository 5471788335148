import { IApplicationResponse } from "../baseTypes"
import BaseApi from "../BaseApi"

export default class ProductSiteApi extends BaseApi {
	uploadCatalog = async (data: {
		priceGroupId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-site/upload-catalog",
			data,
			"post",
			true,
			1800 * 1000
		)

	isOnSite = async (data: {
		productId: string
	}): Promise<IApplicationResponse<boolean>> =>
		this.sendQuery<boolean>(
			"/api/product-site/is-product-on-site",
			data,
			"get",
			true
		)

	addToSite = async (data: {
		productId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-site/upload-product",
			data,
			"post",
			true
		)

	removeFromSite = async (data: {
		productId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-site/remove-product-from-site",
			data,
			"delete",
			true
		)
}
