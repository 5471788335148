import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ICatalog} from "../../../domain/types"
import _ from "lodash"
import {CatalogsThunks} from "./thunk"


export type CatalogState = {
    catalogs: ICatalog[],
    selected: ICatalog,
    wasInit: boolean
};

const initalCatalog: ICatalog = {
    id: 0,
    name: "Loading",
    isPrinted: true
}

const INITIAL_STATE: CatalogState = {
    catalogs: [initalCatalog],
    selected: initalCatalog,
    wasInit: false
}

const catalogSlice = createSlice({
    name: "catalog",
    initialState: INITIAL_STATE,
    reducers: {
        setSelected(state: CatalogState, action: PayloadAction<number>){
            state.selected = state.catalogs.find(x => x.id === action.payload) ?? state.selected
            return state
        }
    },
    extraReducers: builder => {
        builder.addCase(CatalogsThunks.getCatalogs.fulfilled, (state, {payload}) => {
            if(payload.length === 0) {
                state.catalogs.first().name = "Нет каталогов"
                state.selected = state.catalogs.first()
            }else {
                state.catalogs = _.orderBy(payload, x => x.name)
                if(state.selected.id === initalCatalog.id)
                    state.selected = state.catalogs.first()
            }
            state.wasInit = true
        })
    }
})

const reducer = catalogSlice.reducer
const actions = catalogSlice.actions

export {reducer, actions}