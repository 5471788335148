import React, { useEffect } from "react"
import { SvgIcon } from "../icons/SvgIcon"
import "./adResultModal.scss"
import {isAnyOfDefaultKeys, isEscape} from "../../utils/KeysCodes"


export default function AdResultModal(props: IAdResultModalProps) {
	const ref = React.createRef<HTMLDivElement>()

	let prevFocus

    function onKeyDown(e) {
        if(isEscape(e))
            props.onClick()
    }

	useEffect(() => {
		prevFocus = document.activeElement
		document.addEventListener("keydown", onKeyDown)
		ref.current?.focus()

		return () => {
			console.log("remove listener")
			document.removeEventListener("keydown", onKeyDown)
			if (prevFocus) prevFocus.focus()
		}
	}, [])

	return (
		<div
			className="ad-result-modal__background"
			onClick={(_) => props.onClick()}
		>
			<div
				ref={ref}
				tabIndex={0}
				className={"ad-result-modal u-droppod"}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				<SvgIcon
					name={
						props.status === ModalStatus.loading
							? "loader"
							: props.status === ModalStatus.success
							? "circle-checked"
							: props.status === ModalStatus.info
							? "info"
							: "circle-x"
					}
					className={
						props.status === ModalStatus.loading
							? "loader-icon"
							: props.status === ModalStatus.info
							? "u-status-icon"
							: props.status === ModalStatus.success
							? "u-status-icon checked"
							: "u-status-icon not-checked"
					}
				/>
				{props.text}
			</div>
		</div>
	)
}

interface IAdResultModalProps {
	status: ModalStatus
	text: string
	onClick: () => void
}

export enum ModalStatus {
	success,
	error,
	loading,
	info,
}