import { useMemo, useState } from "react"
import "./UploadDataToWebsite.scss"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import { useDispatch, useSelector } from "react-redux"
import TextCheckbox from "../common/basic/checkboxes/TextCheckbox"
import { actions } from "../../redux/reducers/pages/uploadDataToWebsitePage"
import CatalogOnSiteModal from "./CatalogOnSiteModal"
import { UploadDataToSitesThunk } from "../../redux/reducers/pages/uploadDataToWebsitePage/thunks"
import { CatalogGroup } from "../../domain/types"
import RouteProvider from "../../utils/RouteProvider"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { UploadDataSelectors } from "../../redux/reducers/pages/uploadDataToWebsitePage/uploadDataSelectors"
import Protector from "../../library/smartComponents/authorization/Protector"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import AdDefaultInput, { AdInputStatus } from "../../library/inputs/AdDefaultInput"
import { SvgIcon } from "../../library/icons/SvgIcon"
import { actions as informerActions } from "../../redux/reducers/informer"
import NotReadyToDeployTable from "./NotReadyToDeployTable"
import SimpleSelect, {
	IOptionType,
} from "../common/basic/selectors/SimpleSelect"
import { Role } from "../../domain/Role"
import PriceGroupSelector from "../../library/smartComponents/priceGroupSelector/PriceGroupSelector"
import { PriceGroupSelectors } from "../../redux/reducers/priceGroups/priceGroupSelectors"
import "./DeleteDataToProductSite.scss"
import AddDragAndDrop from "./AddDragAndDrop"
import { IHideGroupByProductRequest } from "../../redux/reducers/pages/uploadDataToWebsitePage/type"
import { WebCategoryOptions } from "../../domain/database/WebCategoryType"

export default function UploadDataToWebsite() {
	const dispatch = useDispatch()

	const language = useSelector(LanguageSelectors.getSelected)

	const productGroupId = useSelector(UploadDataSelectors.getProductGroupId)
	const group = useSelector(UploadDataSelectors.getGroup)
	const isGroupExists = useSelector(UploadDataSelectors.getIsGroupExists)
	const isGroupOnSite = useSelector(UploadDataSelectors.getIsGroupOnSite)
	const isShowGroupOnSite = useSelector(UploadDataSelectors.getIsShowGroupOnSite)
	const website = useSelector(WebsiteSelectors.getSelected)

	const setProductGroupId = (id: string) =>
		dispatch(actions.setProductGroupId(id))

	const findGroup = () => {
		if (productGroupId.isNullOrEmpty()) {
			dispatch(actions.setProductGroupAsNull())
			return
		}
		dispatch(
			UploadDataToSitesThunk.checkGroup({
				languageId: language.id,
				productGroupId: productGroupId,
				productsIds: [],
				websiteId: website.id,
			})
		)
	}

	const addGroupToSite = () => {
		if (!group) {
			dispatch(informerActions.inform("Группа не выбрана"))
			return
		}
		dispatch(
			UploadDataToSitesThunk.addGroupToSite({
				productGroupId: group.id,
				websiteId: website.id,
			})
		)
	}

	const removeGroupFromSite = () => {
		if (!group) {
			dispatch(informerActions.inform("Непредвиденная ошибка"))
			return
		}
		dispatch(
			UploadDataToSitesThunk.removeGroupFromSite({
				websiteId: website.id,
				productGroupId: group.id,
			})
		)
	}

	function onEnter(e) {
		if (e.key === "Enter") {
			findGroup()
		}
	}

	const blockShowOnSite = () => {
		const showGroup = () => {
			if (!group) {
				dispatch(informerActions.inform("Непредвиденная ошибка"))
				return
			}
			dispatch(
				UploadDataToSitesThunk.showGroupOnSite({
					websiteId: website.id,
					productGroupId: group.id,
				})
			)
		}

		const unShowGroup = () => {
			if (!group) {
				dispatch(informerActions.inform("Непредвиденная ошибка"))
				return
			}

			dispatch(
				UploadDataToSitesThunk.unSowGroupOnSite({
					websiteId: website.id,
					productGroupId: group.id,
				})
			)
		}

		if (isShowGroupOnSite)
			return (<>
				<div className="toolbar-row" style={{ cursor: "pointer" }} onClick={unShowGroup}>
					<SvgIcon
						name="eye-close"
						className="link-icon"
					/>
					<div>Скрыть на сайте</div>
				</div>
			</>);

		return <>
			<div className="toolbar-row" style={{ cursor: "pointer" }} onClick={showGroup}>
				<SvgIcon
					name="eye"
					className="link-icon"
				/>
				<div>Показать на сайте</div>
			</div>
		</>;
	}

	return (
		<Protector availableRoles={RouteProvider.uploadDataToWebsite.roles!}>
			<>
				<div className="upload-data u-droppod">
					<div className="upload-data-header">
						<div className="header-text header-text-bold">
							Выборочная загрузка на сайт
						</div>
						<AdSearchInput
							onChange={setProductGroupId}
							onKeyUp={onEnter}
							placeholder="Поиск группы по артикулу"
						/>
						<AdDefaultInput
							className=""
							value={group?.name || ""}
							disabled={true}
							type="text"
							status={isGroupExists != null && !isGroupExists ? AdInputStatus.error : AdInputStatus.default}
							placeholder={isGroupExists != null && !isGroupExists ? "Группа не найдена" : "Наименование группы"}
						/>
					</div>

					<div className="u-toolbar">
						<div className="toolbar-row">
							<span>Группа товаров Id</span>
							<AdDefaultInput
								style={{ width: "86px" }}
								disabled={true}
								value={group?.id ?? ""}
							/>
							<span>Группа товаров Id на сайте</span>
							<AdDefaultInput
								style={{ width: "86px" }}
								disabled={true}
								value={String(group?.siteId ?? "")}
							/>
							<TextCheckbox
								readonly={true}
								text={"Фото"}
								isChecked={group?.isImageChecked ?? false}
							/>
							<TextCheckbox
								readonly={true}
								text={"Набор"}
								isChecked={group?.isToolset ?? false}
							/>
							{group && <div
								style={{ marginLeft: "auto", marginTop: "0" }}
								className="toolbar-row">
								<div>
									{isGroupOnSite && blockShowOnSite()}
								</div>
								<button
									className="u-button large accent"
									onClick={() => {
										isGroupOnSite ? removeGroupFromSite() : addGroupToSite()
									}}
									style={{ marginLeft: "30px" }}
								>
									<SvgIcon
										name={isGroupOnSite ? "circle-x" : "circle-plus"}
										className="link-icon"
									/>
									{isGroupOnSite ? "Удалить " : "Добавить "}
									группу
								</button>
							</div>}
						</div>
						<CatalogOnSiteController />
					</div>

					<div className={"u-toolbar"}>
						<div className={"toolbar-grid"}>
							<div>
								<PartialRefresh />
								<UploadDataToProductSite />
							</div>
							<DeletedDataToProductSite />
						</div>
					</div>

					<CatalogOnSiteModal />
					<NotReadyToDeployTable />
				</div>
			</>
		</Protector>
	)
}

function CatalogOnSiteController() {
	const dispatch = useDispatch()
	const openIsOnSiteModal = () =>
		dispatch(actions.setIsCatalogOnSiteMenuOpen(true))

	const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)
	const wasWebsitesInit = useSelector(WebsiteSelectors.getWasInit)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const website = useSelector(WebsiteSelectors.getSelected)
	const selectedTable = useSelector(UploadDataSelectors.getSelectedTable)
	const websiteTables = useSelector(UploadDataSelectors.getWebsiteTables)

	const refreshCatalogOnSite = () => {
		if (wasCatalogsInit && wasWebsitesInit) {
			dispatch(
				UploadDataToSitesThunk.refreshCatalogOnSite({
					websiteId: website.id,
					catalogId: catalog.id,
				})
			)
		}
	}

	const onChange = (table: number) => {
		dispatch(actions.setSelectedTable(table))
	}

	const fillWebsiteTable = () => {
		dispatch(
			UploadDataToSitesThunk.fillWebsiteTable({
				websiteId: website.id,
				catalogId: catalog.id,
				table: selectedTable.value,
			})
		)
	}

	return (
		<div className="toolbar-row second" style={{ marginTop: "21px" }}>
			<CatalogSelector catalogGroup={CatalogGroup.All} className="" />
			<WebsiteSelector className="" />
			<Protector availableRoles={[Role.Admin, Role.ContentManagerLv]} justHide>
				<SimpleSelect
					className={"selector"}
					placeholder={"Выборочное заполнение таблиц"}
					value={selectedTable}
					options={websiteTables}
					onChange={(e) => onChange(e)}
					toOption={(x) => x}
				/>

				<button
					className="u-button large primary"
					onClick={() => fillWebsiteTable()}
				>
					Заполнить таблицы
				</button>
				<button
					className="u-button large primary"
					onClick={() => openIsOnSiteModal()}
				>
					Управлять каталогом
				</button>
				<button
					className="u-button large primary"
					onClick={() => refreshCatalogOnSite()}
				>
					Обновить товары
				</button>
			</Protector>
		</div>
	)
}

function PartialRefresh() {
	const dispatch = useDispatch()
	const website = useSelector(WebsiteSelectors.getSelected)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const availableFunctions: IOptionType[] = useMemo(() => {
		return [
			{
				value: 0,
				label: "Обновить вес продуктов",
			},
			{
				value: 1,
				label: "Обновить наименования",
			},
			{
				value: 2,
				label: "Обновить список категорий",
			},
			{
				value: 3,
				label: "Обновить фото разделов категорий",
			},
		]
	}, [])

	const webCategoryOptions: IOptionType[] = useMemo(() => WebCategoryOptions.map(u => {
		return {
			value: u.id,
			label: u.name
		} as IOptionType
	}), []);

	const [selectedFunction, setSelectedFunction] = useState(
		availableFunctions[0]
	)

	const [selectedWebCategory, setWebCategory] = useState<IOptionType | null>(null)

	const onSelectedFunctionChanged = (value: number) => {
		const selectedFunction = availableFunctions.find(
			(x) => x.value === value
		)
		if (!selectedFunction) return
		setSelectedFunction(selectedFunction)
	}

	const onSelectedWebCategory = (value: number) => {
		const selectedFunction = webCategoryOptions.find(
			(x) => x.value === value
		)
		if (!selectedFunction) return
		setWebCategory(selectedFunction)
	}

	const onExecuteButtonClicked = () => {
		dispatch(
			UploadDataToSitesThunk.partialWebsiteRefresh({
				websiteId: website.id,
				table: selectedFunction.value,
				catalogId: catalog.id,
				type: selectedWebCategory?.value ?? webCategoryOptions[0].value
			})
		)
	}

	return (
		<div>
			<Protector availableRoles={[Role.Admin, Role.ContentManagerLv]} justHide>
				<div className="u-droppod-section-header">
					Выборочная загрузка
				</div>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "minmax(auto, 750px) auto",
						gridGap: "10px",
						paddingRight: "50px",
					}}
				>
					<SimpleSelect
						className={"selector"}
						placeholder={"Выборочное заполнение таблиц"}
						value={selectedFunction}
						options={availableFunctions}
						onChange={onSelectedFunctionChanged}
						toOption={(x) => x}
					/>

					<button
						className="u-button large primary"
						onClick={onExecuteButtonClicked}
					>
						Выполнить
					</button>

					{selectedFunction.value == 3 && <SimpleSelect
						className={"selector"}
						placeholder="Выберите схему сайта"
						value={selectedWebCategory}
						options={webCategoryOptions}
						onChange={onSelectedWebCategory}
						toOption={(x) => x}
						key={selectedFunction.value}
					/>}
				</div>
			</Protector>
		</div>
	)
}

function UploadDataToProductSite() {
	const dispatch = useDispatch()
	const priceGroup = useSelector(PriceGroupSelectors.getSelected)

	const onExecuteButtonClicked = () => {
		dispatch(
			UploadDataToSitesThunk.refreshProductSite({
				priceGroupId: priceGroup.id,
			})
		)
	}

	return (
		<div>
			<Protector availableRoles={[Role.Admin, Role.ContentManagerLv]} justHide>
				<div className="u-droppod-section-header">Выгрузка АвтоТех</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "0.625em",
					}}
				>
					<PriceGroupSelector />

					<button
						className="u-button large primary"
						onClick={onExecuteButtonClicked}
					>
						Выполнить
					</button>
				</div>
			</Protector>
		</div>
	)
}
function DeletedDataToProductSite() {
	const dispatch = useDispatch()

	const fileGroup = useSelector(UploadDataSelectors.getFile)

	const [file, setFile] = useState<File | null>(null)
	const [data, setData] = useState<any | null>(null)

	const website = useSelector(WebsiteSelectors.getSelected)

	const [selectedFunction, setSelectedFunction] = useState<IOptionType | undefined>()

	const availableFunctions: IOptionType[] = useMemo(() => {

		const arr: IOptionType[] = [];

		if (!fileGroup.options.isGroup || (fileGroup.options.isGroup && fileGroup.taxonomy.isProduct)) {
			arr.push({
				value: 0,
				label: "Удаление товаров",
			})
		}

		if (fileGroup.options.isGroup) {
			if (fileGroup.taxonomy.isGroup) {
				arr.push({
					value: fileGroup.taxonomy.isShow ? 1 : 2,
					label: fileGroup.taxonomy.isShow ? "Скрыть группы" : "Показать группы",
				})
			}
		}
		else {
			arr.push({
				value: 4,
				label: "Скрыть группы по товарам",
			})
		}
		setSelectedFunction(undefined);

		return arr
	}, [fileGroup.taxonomy, fileGroup.options.isGroup])

	const onSelectedFunctionChanged = (value: number) => {
		const _selectedFunction = availableFunctions.find(
			(x) => x.value === value
		)
		if (!_selectedFunction) return

		setSelectedFunction(_selectedFunction);
	}

	const setActionSelectedFunction = () => {
		switch (selectedFunction?.value) {
			case 0: setIsDeleteConfirmationDialogOpen(true); break;
			case 1: changeStatusGroups(false); break;
			case 2: changeStatusGroups(true); break;
			case 4: hideGroupsByProducts(); break;
		}
	}

	const chageFile = (file: File, data: any) => {
		setSelectedFunction(undefined);
		setFile(file)
		setData(data);

		if (!data[0][1] || data[0][1]?.trim() == "") {
			dispatch(
				UploadDataToSitesThunk.checkFirstGroupInFile({
					websiteId: website.id,
					productGroupId: data[0][0],
				})
			)
		}
		else {
			dispatch(actions.setFileOptionIsGroup(false))
		}
	}

	//const hasManagerAccess = useAuth([Role.ContentManagerLv, Role.ContentManagerRg])

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false)

	const removeProductFromSite = (/*file: IEcmFileDescription*/) => {
		if (file !== null) {
			dispatch(
				UploadDataToSitesThunk.removeProductFromSite({
					file: file,
					websiteId: website.id,
				})
			)
		} else {
			dispatch(informerActions.inform("Непредвиденная ошибка"))
			return
		}
	}

	const changeStatusGroups = (show: boolean) => {
		dispatch(
			UploadDataToSitesThunk.changeStatusGoupsOnSite({
				websiteId: website.id,
				productGroupIds: data.filter(u => u && u[0] && u[0].trim() != "").map(u => u[0].trim()),
				show
			})
		)
	}

	const hideGroupsByProducts = () => {
		const values: IHideGroupByProductRequest[] = data.reduce(function (arr: IHideGroupByProductRequest[], curr) {
			if (!curr || !curr[0] || curr[0].trim() == "")
				return arr

			if (!curr || !curr[1] || curr[1].trim() == "")
				return arr

			const count = parseInt(curr[1]);
			if (isNaN(count))
				return arr

			arr.push({
				model: curr[0],
				count
			} as IHideGroupByProductRequest);

			return arr;
		}, [])

		dispatch(
			UploadDataToSitesThunk.hideGroupByProductsOnSite({
				websiteId: website.id,
				values
			})
		)
	}

	return (
		<div style={{ display: "flex" }} className="">
			<div>
				<Protector availableRoles={[Role.Admin, Role.ContentManagerLv]} justHide>
					<div className="u-droppod-section-header">
						Удаление товаров
					</div>
					<div>
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "minmax(auto, 590px) auto",
								gridGap: "10px",
							}}
						>
							<SimpleSelect
								className={"selector"}
								placeholder={"Выберите действие для групп"}
								value={selectedFunction}
								options={availableFunctions}
								onChange={onSelectedFunctionChanged}
								toOption={(x) => x}
								isDisabled={!file || fileGroup.group.loading}
								key={JSON.stringify(selectedFunction)}
							/>

							<button
								className="u-button large primary"
								disabled={!file || fileGroup.group.loading}
								onClick={setActionSelectedFunction}
							>
								Выполнить
							</button>

							<AddDragAndDrop
								onFileAdded={(file, data) => chageFile(file, data)}
								onFileCleared={() => {
									setFile(null);
									setData([]);
									setSelectedFunction(undefined)
								}}
								file={file}
								fileType={".csv"}
								error={null}
							/>
						</div>
					</div>
				</Protector>
			</div>
			{isDeleteConfirmationDialogOpen ? (
				<DeleteProductsConfirmationDialog
					onCancel={() => setIsDeleteConfirmationDialogOpen(false)}
					onConfirm={() => {
						setIsDeleteConfirmationDialogOpen(false)
						removeProductFromSite()
					}}
				/>
			) : (
				<></>
			)}
		</div>
	)

	function DeleteProductsConfirmationDialog(props: {
		onCancel: () => void
		onConfirm: () => void
	}) {
		return (
			<div>
				{isDeleteConfirmationDialogOpen ? (
					<div className="modal-backdrop" />
				) : null}
				<div className={"delete-product-confirmation"}>
					<div className={"header"} style={{ fontWeight: "bold" }}>
						Вы уверены, что хотите удалить загруженные товары?
					</div>
					<br />
					<div style={{ paddingLeft: "20px" }}>
						Данное действие нельзя отменить.
					</div>
					<div className={"buttons-wrapper"}>
						<button
							onClick={props.onCancel}
							className={"u-button small outline"}
						>
							Отменить
						</button>
						<button
							onClick={props.onConfirm}
							className={"u-button small accent"}
						>
							Удалить товары
						</button>
					</div>
				</div>
			</div>
		)
	}
}
