import { INamedImage } from "../../../../../domain/models/images/NamedImage"
import { IImageType } from "../../../../../domain/models/imageTypes/ImageType"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"
import { IImageLoadModel } from "../../../../../domain/models/files/FileLoadModel"
import { FileStatus } from "../../../../../domain/models/files/FileStatus"
import Constants from "../../../../../domain/Constants"
import { IShortEcmFileDescription } from "../../../../../domain/models/files/EcmFileDescription"

//https://asvdev.atlassian.net/wiki/spaces/AutoDeloER/pages/3211265
export type ImagesState = {
	images: INamedImage[]
	imageTypes: IImageType[]
	imagesToLoad: IImageLoadModel[]

	possibleFileTypes: string[]
	possibleFileTypesMask: string

	/**
	 * Количество загружаемых на сервер изображений
	 * */
	loadingImagesCount: number
	/**
	 * Количество загруженных на сервер изображений
	 * */
	loadedImagesCount: number

	deletedImages: INamedImage[]
}

const INITIAL_STATE: ImagesState = {
	images: [],
	imageTypes: [],
	imagesToLoad: [],

	possibleFileTypes: ["jpeg", "png", "jpg", "gif"],
	possibleFileTypesMask: ".jpg, .jpeg, .png, .gif",

	loadingImagesCount: 0,
	loadedImagesCount: 0,

	deletedImages: [],
}

const slice = createSlice({
	name: "images",
	initialState: INITIAL_STATE,
	reducers: {
		setImageType: (
			state,
			{
				payload,
			}: PayloadAction<{ currentTypeId: number; newTypeId: number }>
		) => {
			const { currentTypeId, newTypeId } = payload
			const image = state.images.find(
				(image) => image.typeId === currentTypeId
			)
			if (image) {
				image.typeId = newTypeId
			}
		},
		addImageToLoad: (
			state,
			{ payload }: PayloadAction<IShortEcmFileDescription>
		) => {
			let loadStatus = FileStatus.MoreInfoNeeded
			if (!state.possibleFileTypes.includes(payload.extension)) {
				loadStatus = FileStatus.Error
			}
			state.imagesToLoad.push({
				file: payload,
				loadStatus: loadStatus,
			})
		},
		setImageToLoadType: (
			state,
			{
				payload,
			}: PayloadAction<{ file: IShortEcmFileDescription; typeId: number }>
		) => {
			const { file, typeId } = payload
			const image = state.imagesToLoad.find(
				(x) =>
					x.file.name === file.name &&
					x.file.extension === file.extension
			)

			if (image) {
				image.typeId = typeId
				image.loadStatus = FileStatus.Loaded
			}
		},
		clearLoadImages: (state) => {
			state.imagesToLoad = []
			state.loadingImagesCount = 0
			state.loadedImagesCount = 0
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getImages.fulfilled,
			(state, { payload }) => {
				state.images = payload
			}
		)

		builder.addCase(
			ProductPageThunks.getImageTypes.fulfilled,
			(state, { payload }) => {
				state.imageTypes = payload
			}
		)

		builder.addCase(ProductPageThunks.uploadImage.pending, (state) => {
			state.loadingImagesCount++
		})

		builder.addCase(
			ProductPageThunks.uploadImage.fulfilled,
			(state, { payload, meta }) => {
				state.images.push(payload)
				const file = state.imagesToLoad.find(
					(x) => x.file.name === meta.arg.imageName
				)
				if (file) {
					file.loadStatus = FileStatus.LoadedToServer
				}
				state.loadedImagesCount++
			}
		)

		builder.addCase(
			ProductPageThunks.uploadImage.rejected,
			(state, { meta }) => {
				const file = state.imagesToLoad.find(
					(x) => x.file.name === meta.arg.imageName
				)
				if (file) {
					file.loadStatus = FileStatus.Error
				}
				state.loadedImagesCount++
			}
		)

		builder.addCase(
			ProductPageThunks.updateImageType.fulfilled,
			(state, { meta }) => {
				const image = state.images.find(
					(x) => x.typeId === meta.arg.currentTypeId
				)
				if (image) {
					image.typeId = meta.arg.targetTypeId
				}
			}
		)

		builder.addCase(
			ProductPageThunks.uploadVideo.fulfilled,
			(state, { payload }) => {
				state.images.push({
					name: payload.name,
					typeId: Constants.YoutubeImageType,
					imageUrl: payload.imageUrl,
					modifyDate: payload.modifyDate,
					extension: "mp4",
					key: 0,
				})
			}
		)

		builder.addCase(
			ProductPageThunks.removeImage.fulfilled,
			(state, { meta }) => {
				const image = state.images.find(
					(x) => x.typeId === meta.arg.imageType
				)
				if (image) {
					state.deletedImages.push(image)
					state.images = state.images.filter(
						(x) => x.typeId !== meta.arg.imageType
					)
				}
			}
		)
	},
})

const reducer = slice.reducer
const actions = slice.actions

export { reducer, actions }
