import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import "./ProductAttributesBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent"
import ToolsetProductsBlock from "../toolsets/ToolsetProductsBlock"
import { useSearchParams } from "react-router-dom"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { LanguageSelectors } from "../../../../../../redux/reducers/languages/languageSelectors"
import useAuth from "../../../../../../hooks/authorizationHook"
import { Role } from "../../../../../../domain/Role"
import { TablePartSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/tablePartSelectors"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { actions as productGroupActions } from "../../../../../../redux/reducers/pages/groupPage"
import { MessageBusSelectors } from "../../../../../../redux/reducers/messageBus/messageBusSelectors"
import { actions as messageBusActions } from "../../../../../../redux/reducers/messageBus"
import KeysCodes from "../../../../../../utils/KeysCodes"
import { ProductToGroupAppendController } from "./ProductToGroupAppendController"
import { ProductSorterController } from "./ProductSorterController"
import { ProductAttributeController } from "./ProductAttributeController"
import { ProductAttributeTable } from "./ProductAttributeTable"
import Constants from "../../../../../../domain/Constants"
import { AuthSelectors } from "../../../../../../redux/reducers/auth/authSelectors"
import { GroupStatisticFilter } from "../../../../../../domain/GroupStatisticFilter"

export default function ProductAttributesBlock(props: {
	setHeaderHeight: (value: number) => void
}) {
	const [shouldOpenToolset, setShouldOpenToolset] = useState(false)

	const group = useSelector(GroupPageSelectors.getGroup)

	const language = useSelector(LanguageSelectors.getSelected)
	const isProductsLoading = useSelector(
		TablePartSelectors.getIsProductsLoading
	)
	const selectedGroupProduct = useSelector(
		TablePartSelectors.getSelectedGroupProduct
	)
	const groupProducts = useSelector(TablePartSelectors.getGroupProducts)

	const selectedAttributeColumn = useSelector(
		TablePartSelectors.getSelectedAttributeColumn
	)
	const isTableRefreshRequested = useSelector(
		GroupPageSelectors.getIsTableRefreshRequested
	)

	const isContentRf = useSelector(AuthSelectors.isContentRf)
	const isContentLv = useSelector(AuthSelectors.isContentLv)
	const isContentRg = useSelector(AuthSelectors.isContentRg)

	const dispatch = useDispatch()

	const ref = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		props.setHeaderHeight(ref.current?.clientHeight || 0)
	}, [])

	useEffect(() => {
		dispatch(actions.setMainGroupAttribute(group.mainAttributeId))
		dispatch(actions.setAttributesOrder(group.attributesColumnOrder))
	}, [group.mainAttributeId, group.attributesColumnOrder])

	useEffect(() => {
		//мы должны загрузить все продукты без групп, в случае если у группы priceGroup=0 и нет продуктов
		//для риги только рижские продукты
		const priceGroup =
			group.priceGroupId === 0
				? group.priceGroupId === 0 && groupProducts.length == 0
					? (isContentLv || isContentRg) && !isContentRf
						? Constants.RigaPriceGroupId
						: null
					: 0
				: group.priceGroupId

		dispatch(
			TablePartComponentThunks.getProductsWithoutGroup({
				priceGroupId: priceGroup,
				languageId: language.id,
				statisticFilter: GroupStatisticFilter.Any,
			})
		)
	}, [group.priceGroupId, language.id])

	useEffect(() => {
		dispatch(
			TablePartComponentThunks.getProductsWithAttributes({
				productGroupId: group.id,
				languageId: language.id,
			})
		)
		dispatch(
			TablePartComponentThunks.getAttributes({ languageId: language.id })
		)
	}, [language.id])

	//выставляем PriceGroup у продуктовой группы
	useEffect(() => {
		//в случае если у группы нет продуктов, то и PriceGroup не должно быть
		if (groupProducts.length == 0 && !isProductsLoading)
			dispatch(productGroupActions.setPriceGroup(0))
		//при добавлении первого продукта мы присваиваем группе PriceGroup
		if (groupProducts.length >= 1) {
			dispatch(
				productGroupActions.setPriceGroup(groupProducts[0].priceGroupId)
			)
		}
	}, [groupProducts.length])

	useEffect(() => {
		if (!isTableRefreshRequested) return
		dispatch(
			TablePartComponentThunks.getProductsWithAttributes({
				productGroupId: group.id,
				languageId: language.id,
			})
		)
		dispatch(productGroupActions.tableRefreshed())
	}, [isTableRefreshRequested])

	useEffect(() => {
		if (group.isToolset && selectedGroupProduct) {
			setShouldOpenToolset(true)
		} else {
			setShouldOpenToolset(false)
		}
	}, [selectedGroupProduct])

	const removeSomething = () => {
		if (selectedGroupProduct !== null) {
			dispatch(
				TablePartComponentThunks.removeProductFromGroup({
					productGroupId: group.id,
					productId: selectedGroupProduct.id,
				})
			)
			return
		} else if (selectedAttributeColumn !== null) {
			dispatch(
				TablePartComponentThunks.removeAttribute({
					productGroupId: group.id,
					attributeId: selectedAttributeColumn!,
				})
			)
			return
		}
		dispatch(
			informerActions.inform("Выберите колонку или продукт для удаления")
		)
		return
	}

	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "p-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.DELETE:
					removeSomething()
					break
				default:
					break
			}
			console.log(message)
		}
	}, [message.rand])

	return (
		<>
			<div className={"u-toolbar"} ref={ref}>
				<div className={"toolbar-row"}>
					<ProductToGroupAppendController />
					<ProductSorterController />
					<ProductAttributeController />
				</div>
			</div>
			<div className="pab-table-wrapper u-table__scroll-wrapper">
				<ProductAttributeTable />
			</div>
			{shouldOpenToolset ? (
				selectedGroupProduct ? (
					<ToolsetProductsBlock
						toolsetProductId={selectedGroupProduct!.id}
						toolsetName={selectedGroupProduct!.name}
					/>
				) : (
					<></>
				)
			) : (
				<></>
			)}
		</>
	)
}
