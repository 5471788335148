import React from "react"

export default function NotAuthorize(props?: INotAuthorizeProps) {
    const text = props?.text ?? "У вас недостаточно прав, чтобы взаимодействовать с этой страницей"
    return <div>
        {text}
    </div>
}

interface INotAuthorizeProps {
    text? : string
}