import { useDispatch, useSelector } from "react-redux"
import { keyDownMessage } from "../../../../../../utils/KeysCodes"
import React from "react"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { TablePartSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/tablePartSelectors"
import useAuth from "../../../../../../hooks/authorizationHook"
import { Role } from "../../../../../../domain/Role"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../../domain/GroupMembership"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import RouteProvider from "../../../../../../utils/RouteProvider"
import Constants from "../../../../../../domain/Constants"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import AdDefaultInput from "../../../../../../library/inputs/AdDefaultInput"
import {
	saveAttributeValues,
	swapProductSortCommon,
} from "./ProductAttributeCommonExecutor"
import "./ProductAttributesBlock.scss"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import {
	IProductIdentity,
	IProductWithAttributes,
} from "../../../../../../domain/types"

export function ProductAttributeTable() {
	const dispatch = useDispatch()

	return (
		<table
			className={"u-table"}
			tabIndex={0}
			id="p-table"
			onKeyDown={(e) => {
				keyDownMessage(e, "p-table", dispatch)
			}}
		>
			<ProductAttributeTableHeader />
			<ProductAttributeTableBody />
		</table>
	)
}

function ProductAttributeTableHeader() {
	const attributesOrderBySort = useSelector(
		TablePartSelectors.getAttributesOrderKeysBySort
	)
	const attributesOrder = useSelector(TablePartSelectors.getAttributesOrder)
	const attributes = useSelector(TablePartSelectors.getAttributes)
	const selectedAttributeColumn = useSelector(
		TablePartSelectors.getSelectedAttributeColumn
	)
	const dispatch = useDispatch()

	const canPickAttributes = useSelector(
		GroupPageAuthSelectors.getCanPickAttributes
	)

	const setColumnSelected = (id: number) => {
		if (!canPickAttributes) return
		dispatch(actions.setSelectedColumn(id))
	}

	return (
		<thead className={"u-thead"}>
			<tr>
				<th>Порядок</th>
				<th>Код</th>
				<th style={{ width: "100%" }}>Наименование</th>
				{attributesOrderBySort
					.map((x) => {
						const attr = attributes.find((a) => a.id == x)
						if (attr === undefined) return null
						return (
							<th
								key={attr.id}
								onClick={() => {
									if (!canPickAttributes) return
									setColumnSelected(attr!.id)
								}}
								className={
									selectedAttributeColumn == attr!.id
										? "bg-selected"
										: ""
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									{attr!.name}
									{attributesOrder[x].isImportant ? (
										<div
											className={
												"orange-circle-with-number d" +
												attributesOrder[x].importantSort
											}
										>
											{attributesOrder[x]
												.importantSort === 1 ? (
												<SvgIcon name={"star"} />
											) : (
												attributesOrder[x].importantSort
											)}
										</div>
									) : null}
								</div>
							</th>
						)
					})
					.filter((x) => x !== null)}
			</tr>
		</thead>
	)
}

function ProductAttributeTableBody() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const groupProducts = useSelector(TablePartSelectors.getGroupProducts)
	const products = useSelector(TablePartSelectors.getProducts)
	const attributes = useSelector(TablePartSelectors.getAttributes)
	const attributesOrder = useSelector(TablePartSelectors.getAttributesOrder)
	const attributesOrderBySort = useSelector(
		TablePartSelectors.getAttributesOrderKeysBySort
	)
	const accumulatedChanges = useSelector(
		TablePartSelectors.getAccumulatedChanges
	)
	const selectedGroupProduct = useSelector(
		TablePartSelectors.getSelectedGroupProduct
	)

	const dispatch = useDispatch()

	const isContentRf = useAuth([Role.ContentManagerRF])
	const isContentLv = useAuth([Role.ContentManagerLv])
	const isContentRg = useAuth([Role.ContentManagerRg])

	const groupMembership = getGroupMembership(group.priceGroupId)
	let canAccess = false

	switch (groupMembership) {
		case GroupMembership.Nothing:
			canAccess = isContentLv || isContentRf || isContentRg
			break
		case GroupMembership.Russian:
			canAccess = isContentRf
			break
		case GroupMembership.Latvian:
			canAccess = isContentLv || isContentRg
			break
	}

	const setRowSelected = (id: string) => {
		dispatch(actions.setProductRowSelected(id))
	}

	const setNewId = (productId: string, newId: string) => {
		if (!canAccess) return
		dispatch(
			actions.setNewId({ productId: productId, newIdentifier: newId })
		)
	}

	const replaceProductInGroupOnEnter = (
		event,
		currId: string,
		newId: string
	) => {
		if (event.key === "Enter") {
			if (currId === newId) return

			dispatch(
				TablePartComponentThunks.replaceProductInGroup({
					productGroupId: group.id,
					productId: currId,
					newProductId: newId,
				})
			)
		}
		if (event.key === "Escape") {
			setNewId(currId, currId)
		}
	}

	function openAllProductsPage(productGroupId: string, productId: string) {
		if (!canAccess) return
		window.open(
			RouteProvider.possibleProducts.generateParameterPath({
				productGroupId: productGroupId,
				productId: productId,
			})
		)
	}

	const setAttributeValue = (
		productId: string,
		attrId: number,
		value: string
	) => {
		if (!canAccess) return
		dispatch(
			actions.setAttributeValue({
				productId: productId,
				value: value,
				attributeId: attrId,
			})
		)
	}

	const onAttributesEnter = (event, productId: string, attrId: number) => {
		if (event.key === "Enter") {
			if (attrId !== Constants.SortAttributeId) {
				saveAttributeValues(
					accumulatedChanges,
					group.id,
					canAccess,
					dispatch
				)
				return
			} else {
				const targetSort = Number(event.currentTarget.value)
				if (isNaN(targetSort)) {
					dispatch(
						informerActions.inform("Неправильный формат сортировки")
					)
					return
				}
				swapProductSortCommon(
					selectedGroupProduct,
					groupProducts,
					group.id,
					targetSort,
					dispatch
				)
			}
		}
	}

	return (
		<tbody className={"u-tbody"}>
			{groupProducts.isEmpty() ? (
				<>
					<tr>
						<td colSpan={Object.keys(attributesOrder).length + 3}>
							No matching records found
						</td>
					</tr>
				</>
			) : (
				<>
					{groupProducts.map((x) => {
						const highlightColor: string = x.isInStatistic
							? ""
							: x.stockRus === 0 || x.stockRus === null
							? " out-of-statistic-with-stock "
							: " out-of-statistic"
						const toolTipText: string | undefined= x.isInStatistic
							? undefined
							: x.stockRus === 0 || x.stockRus === null
							? "Товар выведен из статистики, нет складских остатков"
							: "товар выведен из статистики"
						return (
							<tr
								title={toolTipText}
								key={x.id}
								onClick={() => setRowSelected(x.id)}
								className={
									(x.selected
										? "table-row--selected"
										: "") + highlightColor
								}
							>
								<td>{x.sort}</td>
								<td>
									
									<AdDefaultInput
										disabled={!canAccess}
										noControls={true}
										value={x.newIdentifier}
										onChange={(e) => setNewId(x.id, e)}
										onKeyUp={(event) =>
											replaceProductInGroupOnEnter(
												event,
												x.id,
												x.newIdentifier
											)
										}
									/>
								</td>
								<td
									onDoubleClick={() =>
										openAllProductsPage(group.id, x.id)
									}
								>
									{x.name}
								</td>
								{attributes === null ? (
									<></>
								) : (
									attributesOrderBySort
										?.map((a, i) => {
											if (
												a ===
													Constants.IgnoreAttributeId ||
												a ===
													Constants.SortAttributeId ||
												a ===
													Constants.WeightInGramAttributeId
											)
												return null
											const attrValue =
												x.attributeValues.find(
													(x) => x.id == a
												)
											if (attrValue === undefined)
												return null
											return (
												<td
													className={
														attributesOrder[a]
															.isImportant
															? "important-attribute"
															: ""
													}
													key={
														x.id.toString() +
														i.toString()
													}
												>
													<AdDefaultInput
														value={
															attrValue?.value ??
															""
														}
														disabled={!canAccess}
														noControls={true}
														onChange={(e) =>
															setAttributeValue(
																x.id,
																attrValue!.id,
																e
															)
														}
														onKeyDown={(e) =>
															onAttributesEnter(
																e,
																x.id,
																attrValue!.id
															)
														}
													/>
												</td>
											)
										})
										.filter((x) => x !== null)
								)}
							</tr>
						)
					})}
				</>
			)}
		</tbody>
	)
}
