import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"

type EditableValue = {
	value: string
	isEditing: boolean
}

type CharacteristicsState = {
	characteristics: Record<number, EditableValue>
	//используем для сброса изменений
	sourceCharacteristics: Record<number, string>
}

const INITIAL_STATE: CharacteristicsState = {
	characteristics: {},
	sourceCharacteristics: {},
}

const slice = createSlice({
	name: "characteristics",
	initialState: INITIAL_STATE,
	reducers: {
		addCharacteristic: (state) => {
			let maxSort = 0
			for (const sort of Object.keys(state.characteristics)) {
				if (Number(sort) > maxSort) {
					maxSort = Number(sort)
				}
			}
			for (const sort of Object.keys(state.characteristics)) {
				state.characteristics[Number(sort)].isEditing = false
			}
			state.characteristics[maxSort + 1] = {
				value: "",
				isEditing: true,
			}
		},
		removeCharacteristic: (state, { payload }: PayloadAction<number>) => {
			delete state.characteristics[payload]
		},
		setCharacteristicEditing: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			if (!(payload in state.characteristics)) {
				return
			}
			if (state.characteristics[payload].isEditing) {
				if (payload in state.sourceCharacteristics) {
					state.characteristics[payload].isEditing = false
				} else {
					delete state.characteristics[payload]
				}
				return
			}

			state.characteristics[payload].isEditing = true
			for (const sort of Object.keys(state.characteristics)) {
				if (Number(sort) !== payload) {
					state.characteristics[Number(sort)].isEditing = false
				}
			}
		},
		reset: () => INITIAL_STATE,
		/*resetToSource: (state) => {
			state.characteristics = state.sourceCharacteristics
		},*/
		setCharacteristic: (
			state,
			{
				payload: { sort, value },
			}: PayloadAction<{ sort: number; value: string }>
		) => {
			if (sort in state.characteristics) {
				state.characteristics[sort].value = value
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getProductCharacteristics.fulfilled,
			(state, { payload }) => {
				const keys = Object.keys(payload)
				for (const key of keys) {
					state.characteristics[Number(key)] = {
						value: payload[Number(key)],
						isEditing: false,
					}
				}
				state.sourceCharacteristics = payload
			}
		)

		builder.addCase(
			ProductPageThunks.addProductCharacteristic.fulfilled,
			(state, { payload, meta }) => {
				state.characteristics[payload] = {
					value: meta.arg.value,
					isEditing: false,
				}
				state.sourceCharacteristics[payload] = meta.arg.value
			}
		)

		builder.addCase(
			ProductPageThunks.deleteProductCharacteristic.fulfilled,
			(state, { meta }) => {
				delete state.characteristics[meta.arg.sort]
				delete state.sourceCharacteristics[meta.arg.sort]
			}
		)

		builder.addCase(
			ProductPageThunks.changeProductCharacteristic.fulfilled,
			(
				state,
				{
					meta: {
						arg: { sort, value },
					},
				}
			) => {
				state.characteristics[sort] = {
					value: value,
					isEditing: false,
				}
				state.sourceCharacteristics[sort] = value
			}
		)
	},
})

const reducer = slice.reducer
const actions = slice.actions

export { actions, reducer }
