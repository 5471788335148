import { AppState } from "../../index"
import _ from "lodash"
import Constants from "../../../../domain/Constants"
import { createSelector } from "@reduxjs/toolkit"

export class ProductPageSelectors {
	static getProduct = (state: AppState) =>
		state.pages.productPage.common.product

	static getProductId = (state: AppState) =>
		state.pages.productPage.common.product?.id

	static getProductAttributes = createSelector(
		[(state: AppState) => state.pages.productPage.common.productAttributes],
		(productAttributes) =>
			productAttributes.filter(
				(x) =>
					x.attributeId != Constants.IgnoreAttributeId &&
					x.attributeId != Constants.WeightInGramAttributeId &&
					x.attributeId != Constants.SortAttributeId
			)
	)

	static getProductAttributesOrder = createSelector(
		[(state: AppState) => state.pages.productPage.common.productAttributes],
		(productAttributes) => {
			return _.sortBy(productAttributes, (x) => x.sort)
				.map((x) => x.attributeId)
				.filter(
					(x) =>
						x != Constants.IgnoreAttributeId &&
						x != Constants.WeightInGramAttributeId &&
						x != Constants.SortAttributeId
				)
		}
	)

	static getIsProductLoading = (state: AppState) =>
		state.pages.productPage.common.isProductLoading

	static getIsProductUpdating = (state: AppState) =>
		state.pages.productPage.common.isProductUpdating

	static getIsProductRemoving = (state: AppState) =>
		state.pages.productPage.common.isProductRemoving

	static getProductSiteId = (state: AppState) =>
		state.pages.productPage.common.product?.siteId

	// мои изменения
	// static getProductPriceGroupId = createSelector(
	// 	[ProductPageSelectors.getProduct],
	// 	(product) => product?.priceGroupId || null
	// )
}
