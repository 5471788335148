import BaseApi from "../BaseApi"
import { IApplicationResponse } from "../baseTypes"

export default class ProductCharacteristicsApi extends BaseApi {
	getProductCharacteristics = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<Record<number, string>>> =>
		this.sendQuery<Record<number, string>>(
			"/api/product/characteristics",
			data,
			"get",
			true
		)

	addProductCharacteristic = async (data: {
		value: string
		languageId: number
		productId: string
	}): Promise<IApplicationResponse<number>> =>
		this.sendQuery<number>(
			"/api/product/characteristics",
			data,
			"post",
			true
		)

	deleteProductCharacteristic = async (data: {
		productId: string
		sort: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/characteristics",
			data,
			"delete",
			true
		)

	changeProductCharacteristic = async (data: {
		productId: string
		value: string
		sort: number
		languageId: number
	}) =>
		this.sendQuery<void>("/api/product/characteristics", data, "put", true)
}
