import React from "react"
import _ from "lodash"
import { ISelectableIndexModel } from "../../redux/types"
import "./adCategoryDynamicTable.scss"
import { CatalogGroup } from "../../domain/types"
import { Guid } from "guid-typescript"
import { ICategory } from "../../domain/models/categories/ICategory"

export default function AdCategoryDynamicTable(
	props: ICategoryDynamicTableProps
) {
	const maxColumnLength = _.max(props.rows.map((x) => x.model.length))
	const row = _.orderBy(props.rows, (value) => value.index)
	return (
		<div className="u-table__scroll-wrapper">
			<table className="u-table">
				<tbody className="u-tbody">
					{row.isEmpty() ? (
						<>
							<tr className="top-border">
								<td>Пока ничего нет</td>
							</tr>
						</>
					) : (
						<>
							{row
								.sort((x) => x.index)
								.map((row) => {
									return (
										<tr
											key={row.index}
											className={
												row.selected
													? "table-row--selected"
													: ""
											}
											onClick={() => {
												if (props.isDisabled) return
												props.onRowClicked(row)
											}}
										>
											{
												<>
													{Array.from(
														Array(
															maxColumnLength
														).keys()
													).map((columnNumber, i) => {
														const column =
															row.model[
																columnNumber
															]
														if (!column) {
															return (
																<td
																	key={Guid.create().toString()}
																/>
															)
														}
														return (
															<td
																key={
																	(i + 1) *
																		row.index +
																	column.id
																}
															>
																{column.name}
															</td>
														)
													})}

													{
														//в случае если это веб категории, до добавляем отображение Main
														props.catalogGroup ==
														CatalogGroup.Web || CatalogGroup.Web_Latvia ? (
															<td>
																{Number(
																	_.last(
																		row.model
																	)
																		?.mainCategory ??
																		false
																)}
															</td>
														) : (
															<></>
														)
													}
												</>
											}
										</tr>
									)
								})}
						</>
					)}
					<tr></tr>
				</tbody>
			</table>
		</div>
	)
}

interface ICategoryDynamicTableProps {
	catalogGroup: CatalogGroup
	rows: ISelectableIndexModel<ICategory>[]
	onRowClicked: (row: ISelectableIndexModel<ICategory>) => void
	isDisabled?: boolean
}
