import React, {useLayoutEffect} from "react"
import "./tree.scss"
import TreeGroupList from "./treeGroupTable/TreeGroupList"
import {useDispatch} from "react-redux"
import {actions} from "../../redux/reducers/pages/treePage"
import Protector from "../../library/smartComponents/authorization/Protector"
import RouteProvider from "../../utils/RouteProvider"
import CategoryFormInTree from "./CategoryFormInTree"

export default function Tree() {
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        return function () {
            dispatch(actions.clearStateOnUnmount())
        }
    }, [])

    return (
        <Protector availableRoles={RouteProvider.tree.roles!}>
            <CategoryFormInTree highlightedCategories={[]}/>
            <TreeGroupList/>
        </Protector>
    )
}