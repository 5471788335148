import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"


export class GuidePrroductGroupWithProductThunks {
	static getGuide = createAutogenApiThunk({
		typePrefix: "guide/get-guide",
		apiCall: Api.guide.getGuide,
		errorMessage: "Не удалось получить справочник",
        showLoader: true,
	})
}
