import { ICategoryViewModel, mapCategoryToModel } from "../../../library/smartComponents/tables/productGroupTable/types"
import { CatalogGroup, ICatalogGroup, IWebCategoryType } from "../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { addProductGroupToCatsThunk } from "../pages/categoryPage/thunk"
import Constants from "../../../domain/Constants"
import { CategoriesComponentThunks } from "./thunk"
import { CategoryTree } from "../../../domain/services/CategoryTree"
import BaseType from "../../../domain/models/BaseModel"
import { WebCategoryOptions } from "../../../domain/database/WebCategoryType";

export type ImageSettingsState = {
    isShow: boolean,
    group: ICategoryViewModel | null
}

export type CategoriesState = {
    filter: string,
    categories: ICategoryViewModel[],
    isLoading: boolean,
    selected: ICategoryViewModel | null
    selectedGroups: ICatalogGroup[] | null,
    checkCategories: ICategoryViewModel[]
    loadingState: {
        isAddingCat: boolean,
        isChangingCat: boolean,
        isRemovingCat: boolean
    },
    imageSettings: ImageSettingsState,    
    webCategories: IWebCategoryType[],
    selectedWebCategory: IWebCategoryType
}

const INITIAL_STATE: CategoriesState = {
    filter: "",
    categories: [],
    isLoading: false,
    selected: null,
    selectedGroups: null,
    checkCategories: [],
    loadingState: {
        isAddingCat: false,
        isChangingCat: false,
        isRemovingCat: false
    },
    imageSettings: {
        isShow: false,
        group: null
    },
    webCategories: WebCategoryOptions,
    selectedWebCategory: WebCategoryOptions[0]
}

const categorySlice = createSlice({
    name: "categories",
    initialState: INITIAL_STATE,
    reducers: {
        setCategoriesLoading(state: CategoriesState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setFilter(state: CategoriesState, action: PayloadAction<string>) {
            state.filter = action.payload
        },
        setSelectedCategory(state: CategoriesState, action: PayloadAction<{ selected: boolean, categoryId: number }>) {
            const category = CategoryTree.findCategory(state.categories, action.payload.categoryId)
            if (category != null) {
                if (state.selected !== null && state.selected.id === action.payload.categoryId) {
                    state.selected = null
                    category.selected = false

                    return state
                }

                if (state.selected != null) {
                    const previousCategory = CategoryTree.findCategory(state.categories, state.selected.id)
                    previousCategory!.selected = false
                }
                category.selected = action.payload.selected
                state.selected = category
            }

            return state
        },
        setCategoryChecked(state: CategoriesState, action: PayloadAction<{ checked: boolean, categoryId: number }>) {
            const category = CategoryTree.findCategory(state.categories, action.payload.categoryId)
            if (category != null) {
                if (action.payload.checked)
                    state.checkCategories.push(category)
                else
                    state.checkCategories = state.checkCategories.filter(x => x.id !== category.id)
                category.checked = action.payload.checked
            }
        },
        clearToolbarState(state: CategoriesState) {
            state.selected = null
        },
        clearOnUnmount() {
            return { ...INITIAL_STATE }
        },
        setSelectedGroups(state: CategoriesState, action: PayloadAction<ICatalogGroup[]>) {
            state.selectedGroups = action.payload;
        },
        setIsShowImageSettings(state: CategoriesState, action: PayloadAction<boolean>) {
            state.imageSettings.isShow = action.payload
        },
        setGroupImageSettings(state: CategoriesState, action: PayloadAction<ICategoryViewModel>) {
            state.imageSettings.group = action.payload
        },
        setSelectedWebCategory(state: CategoriesState, action: PayloadAction<IWebCategoryType>) {
            state.selectedWebCategory = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(CategoriesComponentThunks.getCategories.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(CategoriesComponentThunks.getCategories.fulfilled, (state, action) => {
            const categories = action.payload
            state.isLoading = false
            state.categories = categories.map(x => mapCategoryToModel(x))
        })
        builder.addCase(CategoriesComponentThunks.getCategories.rejected, (state, action) => {
            state.isLoading = false
        })
        builder.addCase(CategoriesComponentThunks.updateCategoryName.pending, (state) => {
            state.loadingState.isChangingCat = true
        })
        builder.addCase(CategoriesComponentThunks.updateCategoryName.fulfilled, (state, action) => {
            state.loadingState.isChangingCat = false
            const category = CategoryTree.findCategory(state.categories, action.meta.arg.id)
            if (category != null) {
                category.name = action.meta.arg.name
            }
        })
        builder.addCase(CategoriesComponentThunks.updateCategoryName.rejected, (state, action) => {
            state.loadingState.isChangingCat = false
        })
        builder.addCase(CategoriesComponentThunks.createCategory.pending, (state) => {
            state.loadingState.isAddingCat = true
        })
        builder.addCase(CategoriesComponentThunks.createCategory.fulfilled, (state, action) => {
            state.loadingState.isAddingCat = false
            const { name, parentId, catalogGroup } = { ...action.meta.arg }
            const isPrinted = catalogGroup == CatalogGroup.Printed
            const id = action.payload

            if (!parentId) {
                const baseParent = isPrinted ? 0 : 2000
                const calcedParent = parentId ?? baseParent
                const sort = isPrinted
                    ? Math.max(...state.categories.map(x => x.sort)) + 1
                    : 0
                state.categories.push(createCategory(calcedParent, action.payload, action.meta.arg.name, sort))
            } else {
                const category = CategoryTree.findCategory(state.categories, parentId)
                const sort = isPrinted
                    ? Math.max(...category!.children.map(x => x.sort)) + 1
                    : 0
                category!.children.push(createCategory(parentId, id, name, sort))
                //после добавления новой категории - выставляем selected заново, чтобы у selected были children
                state.selected = category
            }
        })
        builder.addCase(CategoriesComponentThunks.createCategory.rejected, (state, action) => {
            state.loadingState.isAddingCat = false
        })
        builder.addCase(CategoriesComponentThunks.deleteCategory.pending, (state) => {
            state.loadingState.isRemovingCat = true
        })
        builder.addCase(CategoriesComponentThunks.deleteCategory.fulfilled, (state, action) => {
            state.loadingState.isRemovingCat = false
            const id = action.meta.arg.id
            const category = CategoryTree.findCategory(state.categories, id)
            if (category !== null) {
                if (category.parentId !== Constants.PrintBaseParent && category.parentId !== Constants.WebBaseParent) {
                    const parent = CategoryTree.findCategory(state.categories, category.parentId)
                    if (parent !== null) {
                        parent.children = parent.children.filter(x => x.id !== category.id)
                    }
                } else {
                    state.categories = state.categories.filter(x => x.id !== category.id)
                }
                //после удаления категории нужно выставить selected = null
                if (state.selected && state.selected.id === category.id) {
                    state.selected = null
                }
            }
        })
        builder.addCase(CategoriesComponentThunks.deleteCategory.rejected, (state, action) => {
            state.loadingState.isRemovingCat = false
        })

        //Реагируем на присвоение продуктовой группы категориям на странице categories
        builder.addCase(addProductGroupToCatsThunk.pending, (state) => {
            state.checkCategories.forEach(x => {
                const category = CategoryTree.findCategory(state.categories, x.id)
                if (category !== null) {
                    category.checked = false
                }
            })
            state.checkCategories = []
        })

        builder.addCase(
            CategoriesComponentThunks.addImage.fulfilled,
            (state, action) => {
                const categoryId = action.meta.arg.categoryId

                const category = state.categories.find(u => u.id == categoryId)
                if (category) {
                    category.imageUrl ??= {}
                    category.imageUrl[action.meta.arg.type] = action.payload
                }

                if(state.imageSettings.group){
                    state.imageSettings.group!.imageUrl ??={};
                    state.imageSettings.group!.imageUrl[action.meta.arg.type] = action.payload
                }
            }
        )

        builder.addCase(
            CategoriesComponentThunks.deleteImage.fulfilled,
            (state, action) => {
                const categoryId = action.meta.arg.categoryId

                const category = state.categories.find(u => u.id == categoryId)
                if (category)
                    delete category.imageUrl[action.meta.arg.type]

                if(state.imageSettings.group){
                    delete state.imageSettings.group!.imageUrl[action.meta.arg.type]
                }
            }
        )
    }
})


function createCategory(parentId: number, id: number, name: string, sort: number): ICategoryViewModel {
    return {
        name: name,
        id: id,
        checked: false,
        children: [],
        imageUrl: {},
        selected: false,
        parentId: parentId,
        sort: sort
    }
}


const actions = categorySlice.actions
const reducer = categorySlice.reducer

export { actions, reducer }