import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/reducers/pages/translatePage"
import "./TranslateForm.scss"
import { TranslateThunks } from "../../redux/reducers/pages/translatePage/thunks"
import RouteProvider from "../../utils/RouteProvider"
import {
	CatalogGroup,
	TranslateSource,
	WebsiteSchema,
} from "../../domain/types"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"
import { TranslateSelectors } from "../../redux/reducers/pages/translatePage/translateSelectors"
import useAuth from "../../hooks/authorizationHook"
import Protector from "../../library/smartComponents/authorization/Protector"
import { Role } from "../../domain/Role"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { SvgIcon } from "../../library/icons/SvgIcon"
import { actions as informActions } from "../../redux/reducers/informer"
import AdDragAndDrop from "../../library/drapAndDrop/AdDragAndDrop"
import { ProductNameAttributeStrategy } from "../../domain/ProductNameAttributeStrategy"

export function TranslateForm() {
	const hasManagerAccessLv = useAuth([Role.ContentManagerLv])
	const hasManagerAccess = useAuth([Role.ContentManagerLv, Role.ContentManagerRg])
	const hasAdminAccess = useAuth([Role.Admin])

	const catalog = useSelector(CatalogSelectors.getSelected)
	const website = useSelector(WebsiteSelectors.getSelected)
	const language = useSelector(TranslateSelectors.getSelectedLanguage)
	const selectedLanguage = useSelector(TranslateSelectors.getSelectedLanguage)
	const canAccessAnySite = useAuth([Role.Admin])
	const languages = useSelector(LanguageSelectors.getLanguages)
	const systemLanguage = useSelector(LanguageSelectors.getSelected)
	const translateCategories = useSelector(
		TranslateSelectors.getTranslateCategories
	)

	const possibleAttributeGenerationStrategies = hasAdminAccess
		? [
			ProductNameAttributeStrategy.ByAttributeShortName,
			ProductNameAttributeStrategy.ByAttributeName,
			ProductNameAttributeStrategy.ByAttributeShortNameAndAttributeName,
		]
		: [ProductNameAttributeStrategy.ByAttributeShortNameAndAttributeName]
	const attributeGenerationStrategy = useSelector(
		TranslateSelectors.getProductNameAttributeGenerationStrategy
	)

	const [file, setFile] = useState<File | null>(null)
	const dispatch = useDispatch()

	// const setFileFromEvent = (event) => {
	//     setFile(event.target.files[0])
	// }

	function setLocalLanguage(languageId: number) {
		const language = languages.find((x) => x.id === languageId)
		if (!language) return
		dispatch(actions.setLanguage(language))
	}

	const openUploadDataToWebsite = () => {
		if (!hasManagerAccess) return
		const url = RouteProvider.uploadDataToWebsite.path
		window.open(url, "_blank")
	}

	const importTranslateFromExcel = async () => {
		if (!hasManagerAccess) return
		if (!file) {
			dispatch(informActions.inform("Выберите файл"))
			return
		}

		dispatch(TranslateThunks.uploadTranslate({ translateExcel: file }))
	}

	const setGenerateProductNameAttributeStrategy = (
		strategy: ProductNameAttributeStrategy
	) => {
		dispatch(actions.setProductAttributeGenerationStrategy(strategy))
	}

	const updateSourceFromTranslates = async () => {
		if (!hasManagerAccess) return
		const types: TranslateSource[] = []
		if (translateCategories.isCatCategoriesChecked)
			types.push(TranslateSource.CatKat)
		if (translateCategories.isWebCatChecked)
			types.push(TranslateSource.CatWeb)
		if (translateCategories.IsTechDescriptionChecked)
			types.push(TranslateSource.PictGroupDesc)
		if (translateCategories.IsPictogramsChecked)
			types.push(TranslateSource.PictGroup)
		if (translateCategories.isAttributesChecked)
			types.push(TranslateSource.AttrNames)
		if (types.length === 0) return

		dispatch(
			TranslateThunks.updateSourcesFromTranslates({
				languageId: systemLanguage.id,
				types: types,
			})
		)
	}

	function generateProductShortnames() {
		dispatch(
			TranslateThunks.generateShortnames({
				attributeNameStrategy: attributeGenerationStrategy,
			})
		)
	}

	async function generateCatInfo() {
		if (!hasManagerAccess) return
		dispatch(
			TranslateThunks.getCatalogInfoAsZip({
				catalogId: catalog.id,
				languageId: language.id,
				websiteId: website.id,
			})
		)
	}

	return (
		<Protector availableRoles={[Role.ContentManagerLv, Role.ContentManagerRg]} justHide={true}>
			<div className="translate-form u-droppod">
				<div className="u-droppod-header">
					<div className="header-text">Переводы</div>
					<button
						hidden={!hasManagerAccess}
						className="u-button accent large"
						onClick={() => updateSourceFromTranslates()}
					>
						<SvgIcon name="note-text" className="link-icon" />
						Обновить переводы
					</button>
				</div>
				<div className="u-droppod-scroll-wrapper">
					<div className="u-droppod-section-header">
						Обновление данных
					</div>

					<button
						hidden={!hasManagerAccess}
						className="u-button primary large"
						onClick={() => openUploadDataToWebsite()}
					>
						Загрузить информацию на сайт
					</button>
					<div style={{ display: "flex", gap: 6 }}>
						<button
							hidden={!hasManagerAccessLv}
							className="u-button primary large"
							onClick={() => generateProductShortnames()}
						>
							Сгенерировать короткие имена продуктов
						</button>
						<SimpleSelect
							value={attributeGenerationStrategy}
							options={possibleAttributeGenerationStrategies}
							onChange={(e) =>
								setGenerateProductNameAttributeStrategy(e)
							}
							toOption={
								ToOptionProvider.productNameAttributeStrategyToOption
							}
						/>
					</div>
					<div className="u-droppod-section-header">
						Экспорт данных
					</div>
					<div className="u-droppod-item-header">
						Выгрузка данных в Excel
					</div>

					<div className="selectors-column-wrapper">
						<div className="translate-settings__select">
							<SimpleSelect
								value={selectedLanguage}
								options={languages}
								onChange={setLocalLanguage}
								toOption={ToOptionProvider.languageToOption}
								className={"selector"}
							/>
						</div>
						<div className="translate-settings__select">
							<CatalogSelector catalogGroup={CatalogGroup.All} />
						</div>
						<div className="translate-settings__select">
							<WebsiteSelector
								schema={
									canAccessAnySite
										? undefined
										: WebsiteSchema.Latvian
								}
							/>
						</div>
					</div>

					<div className="u-droppod-info">
						Воспользуйтесь фильтрами выше для формирования таблицы
						экспорта.
					</div>

					<button
						className="u-button primary large"
						hidden={!hasManagerAccess}
						onClick={() => generateCatInfo()}
					>
						Выгрузить данные
					</button>

					<Protector availableRoles={[Role.Admin, Role.ContentManagerLv]} justHide>
						<div className="u-droppod-section-header">
							Импорт данных
						</div>
						<div className="u-droppod-item-header">Добавить файл</div>

						<div
							style={{ width: "fit-content" }}
						>
							<AdDragAndDrop
								onFileAdded={(f) => setFile(f[0])}
								onFileCleared={() => setFile(null)}
								removeBr={true}
								file={file}
								fileType={
									".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								}
								canAddImages={true}
							/>
						</div>

						<div className="u-droppod-info">
							Файл должен иметь формат XLS, XLSX или CSV.
						</div>
						<button
							className="u-button primary large"
							disabled={!file}
							onClick={() => importTranslateFromExcel()}
						>
							Загрузить данные
						</button>
					</Protector>

					<div className="u-droppod-section-header">
						Генерация PDF
					</div>
					<button
						hidden={!hasManagerAccess}
						className="u-button primary large"
						onClick={() => {
							window.open(RouteProvider.generatePreviewPdf.path, "_blank")
						}}
					>
						<SvgIcon name="note-text" className="link-icon" />
						Сгенерировать PDF
					</button>
				</div>
			</div>
		</Protector>
	)
}
