import React, {useEffect} from "react"
import {IGroupWithCatViewModel} from "../../../library/smartComponents/tables/productGroupTable/types"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/pages/treePage"
import {CatalogGroup} from "../../../domain/types"
import ExpandedProductGroupTable
    from "../../../library/smartComponents/tables/productGroupTable/ExpandedProductGroupTable"
import {CategoriesSelectors} from "../../../redux/reducers/categories/categoriesSelectors"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import {LanguageSelectors} from "../../../redux/reducers/languages/languageSelectors"
import {TreePageSelectors} from "../../../redux/reducers/pages/treePage/treePageSelectors"
import {TreeThunks} from "../../../redux/reducers/pages/treePage/thunks"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"
import {Role} from "../../../domain/Role"
import useAuth from "../../../hooks/authorizationHook"
import {actions as categoryActions} from "../../../redux/reducers/categories"

export default function TreeGroupTable() {

    const language = useSelector(LanguageSelectors.getSelected)
    const category = useSelector(CategoriesSelectors.getSelected)
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const catalog = useSelector(CatalogSelectors.getSelected)
    const cardType = useSelector(TreePageSelectors.getSelectedCardType)
    const filter = useSelector(TreePageSelectors.getFilter)
    const isProductGroupsLoading = useSelector(TreePageSelectors.getIsProductGroupsLoading)
    const groups = useSelector(TreePageSelectors.getOrderedGroups(catalogGroup))

    const canAccess = useAuth([Role.ContentManagerRF])
    console.log(canAccess)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.setProductGroupsEmpty())
        dispatch(actions.clearSelected())
        dispatch(categoryActions.clearToolbarState())
    }, [catalogGroup.id])

    useEffect(() => {
        if (category) {
            dispatch(TreeThunks.getGroups({
                languageId: language.id,
                catalogGroup: catalogGroup.id,
                catalogId: catalogGroup.id === CatalogGroup.Printed
                    ? catalog.id
                    : null,
                validationType: cardType.value,
                categoryId: category.id,
                searchString: filter
            }))
        } else {
            dispatch(actions.setProductGroupsEmpty())
        }
        dispatch(actions.clearSelected())
    }, [
        language.id,
        cardType.value,
        category?.id,
        catalog.id,
        filter
    ])

    function loadProducts(productGroup: IGroupWithCatViewModel) {
        dispatch(TreeThunks.getProductByGroups({
            productGroupId: productGroup.id,
            languageId: language.id
        }
        ))
    }

    function setSelected(productGroup: IGroupWithCatViewModel) {
        dispatch(actions.setSelectedProductGroup(productGroup))
    }

    return <div className="table-sm u-table__scroll-wrapper">
        <ExpandedProductGroupTable
            isProductGroupsLoading={isProductGroupsLoading}
            productGroups={groups}
            loadProducts={model => loadProducts(model as IGroupWithCatViewModel)}
            onRowClick={model => {
                setSelected(model as IGroupWithCatViewModel)
            }}
            onCheckBoxClick={model => {
                setSelected(model as IGroupWithCatViewModel)
            }}
            disabled={!canAccess}
            id={"tg-table"}
            showSortColumn={catalogGroup.id === CatalogGroup.Printed}/>
    </div>
}
