import { AppState } from "../../index"

export class GroupPageSelectors {
	static getGroup = (state: AppState) =>
		state.pages.productGroupPage.common.productGroup

	static getSigns = (state: AppState) =>
		state.pages.productGroupPage.common.signs

	static getSelectedSign = (state: AppState) =>
		state.pages.productGroupPage.common.selectedSign

	static getSeries = (state: AppState) =>
		state.pages.productGroupPage.common.series

	static getSelectedSeries = (state: AppState) =>
		state.pages.productGroupPage.common.selectedSeries

	static getAttributes = (state: AppState) =>
		state.pages.productGroupPage.common.attributes

	static getSelectedAttribute = (state: AppState) =>
		state.pages.productGroupPage.common.selectedAttribute

	static getSellmarks = (state: AppState) =>
		state.pages.productGroupPage.common.sellmarks

	static getSelectedSellmark = (state: AppState) =>
		state.pages.productGroupPage.common.selectedSellmark

	static getIsPageLoading = (state: AppState) =>
		state.pages.productGroupPage.common.loadingState.isPageLoading

	static getIsSaveLoading = (state: AppState) =>
		state.pages.productGroupPage.common.loadingState.isSaveLoading

	static getIsRejectLoading = (state: AppState) =>
		state.pages.productGroupPage.common.loadingState.isRejectLoading

	static getIsTableRefreshRequested = (state: AppState) =>
		state.pages.productGroupPage.common.isTableRefreshRequested
}
