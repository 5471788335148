import AuthApi from "./auth/AuthApi"
import TranslateApi from "./translate/TranslateApi"
import PriceGroupApi from "./priceGroups/PriceGroupApi"
import ProductApi from "./product/ProductApi"
import CatalogsApi from "./catalogs/CatalogApi"
import SellmarkApi from "./sellmark/SellmarkApi"
import ProductGroupApi from "./productGroup/ProductGroupApi"
import CategoryApi from "./category/CategoryApi"
import WebsitesApi from "./websites/WebsitesApi"
import AttributeApi from "./attribute/AttributeApi"
import SeriesApi from "./series/SeriesApi"
import SignApi from "./sign/SignApi"
import ScopeOfApplicationApi from "./scopeOfApplication/ScopeOfApplicationApi"
import ImageApi from "./imageApi/ImageApi"
import PictogramApi from "./pictograms/PictogramApi"
import RecommendationApi from "./recommendations/RecommendationApi"
import ToolsetApi from "./toolsets/ToolsetApi"
import FileApi from "./files/FileApi"
import CompareContentApi from "./compareContent/CompareContentApi"
import BeaconApi from "./beacon/BeaconApi"
import PageMutexApi from "./ws/PageMutexApi"
import RelatedProductApi from "./relatedProducts/RelatedProductApi"
import { SimilarProductApi } from "./similarProducts/SimilarProductApi"
import { FileTypeApi } from "./fileTypes/FileTypeApi"
import ProductFilesApi from "./productFiles/ProductFilesApi"
import ProductCharacteristicsApi from "./productCharacteristics/ProductCharacteristicsApi"
import WatchtowerApi from "./watchtower/WatchtowerApi"
import ProductSiteApi from "./productSite/ProductSiteApi"
import GuideApi from "./guide/GuideApi"

interface IApplicationApi {
	auth: AuthApi
	beacon: BeaconApi
	translate: TranslateApi
	priceGroup: PriceGroupApi
	products: ProductApi
	catalogs: CatalogsApi
	sellmarks: SellmarkApi
	productGroups: ProductGroupApi
	category: CategoryApi
	websites: WebsitesApi
	attributes: AttributeApi
	series: SeriesApi
	sign: SignApi
	scopes: ScopeOfApplicationApi
	images: ImageApi
	pictograms: PictogramApi
	recommendations: RecommendationApi
	toolsets: ToolsetApi
	files: FileApi
	compareContent: CompareContentApi
	relatedProducts: RelatedProductApi
	pageMutex: PageMutexApi
	similarProducts: SimilarProductApi
	fileTypes: FileTypeApi
	productFiles: ProductFilesApi
	productCharacteristics: ProductCharacteristicsApi
	watchtower: WatchtowerApi
	guide: GuideApi
}

/**
 Api should be ARROW FUNCTIONS because of context visibility and should accept only objects because of thunk handling
 for example:
 login = async (authData: {username: string, password: string}): Promise<IApplicationResponse<string>> =>
	this.sendQuery<string>('/api/auth/', authData, actionTypes.get, false);
 NOT
 async login(username: string, password: string): Promise<IApplicationResponse<string>> {
		const response = this.sendQuery<string>('/api/auth/', {
			username: username,
			password: password
		}, actionTypes.get, false);
		return response;
	}
 **/
export class ApiGateway implements IApplicationApi {
	static Api = new ApiGateway()

	auth: AuthApi
	beacon: BeaconApi
	translate: TranslateApi
	priceGroup: PriceGroupApi
	products: ProductApi
	catalogs: CatalogsApi
	sellmarks: SellmarkApi
	productGroups: ProductGroupApi
	productSites: ProductSiteApi
	category: CategoryApi
	websites: WebsitesApi
	attributes: AttributeApi
	series: SeriesApi
	sign: SignApi
	scopes: ScopeOfApplicationApi
	images: ImageApi
	pictograms: PictogramApi
	recommendations: RecommendationApi
	toolsets: ToolsetApi
	files: FileApi
	compareContent: CompareContentApi
	pageMutex: PageMutexApi
	relatedProducts: RelatedProductApi
	similarProducts: SimilarProductApi
	watchtower: WatchtowerApi
	fileTypes: FileTypeApi
	productFiles: ProductFilesApi
	productCharacteristics: ProductCharacteristicsApi
	guide: GuideApi

	constructor() {
		this.beacon = new BeaconApi()
		this.auth = new AuthApi()
		this.translate = new TranslateApi()
		this.priceGroup = new PriceGroupApi()
		this.products = new ProductApi()
		this.catalogs = new CatalogsApi()
		this.sellmarks = new SellmarkApi()
		this.productGroups = new ProductGroupApi()
		this.category = new CategoryApi()
		this.websites = new WebsitesApi()
		this.attributes = new AttributeApi()
		this.series = new SeriesApi()
		this.sign = new SignApi()
		this.scopes = new ScopeOfApplicationApi()
		this.images = new ImageApi()
		this.pictograms = new PictogramApi()
		this.recommendations = new RecommendationApi()
		this.toolsets = new ToolsetApi()
		this.files = new FileApi()
		this.compareContent = new CompareContentApi()
		this.pageMutex = new PageMutexApi()
		this.relatedProducts = new RelatedProductApi()
		this.similarProducts = new SimilarProductApi()
		this.watchtower = new WatchtowerApi()
		this.fileTypes = new FileTypeApi()
		this.productFiles = new ProductFilesApi()
		this.productCharacteristics = new ProductCharacteristicsApi()
		this.productSites = new ProductSiteApi()
		this.guide = new GuideApi()
	}
}
