import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {AppState} from "../../redux/reducers"
import {actions, AllProductsState} from "../../redux/reducers/pages/allProductsPage"
import {useEffect, useRef, useState} from "react"
import {LanguageState} from "../../redux/reducers/languages"
import {useSearchParams} from "react-router-dom"
import {useDebouncedCallback} from "use-debounce"
import Api from "../../api"
import DebounceProvider from "../../utils/DebounceProvider"
import _ from "lodash"
import Protector from "../../library/smartComponents/authorization/Protector"
import {Role} from "../../domain/Role"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import {SvgIcon} from "../../library/icons/SvgIcon"
import {AllProductPageThunks} from "../../redux/reducers/pages/allProductsPage/thunks"

export default function AllProductsTable() {
    const [searchParams] = useSearchParams()
    const [wasScrolled, setWasScrolled] = useState(false)
    const local = useSelector<AppState, AllProductsState>(x => x.pages.allProductPage)
    const languageState = useSelector<AppState, LanguageState>(x => x.languageState)
    const dispatch = useDispatch()
    const paramGroupId = searchParams.get("productGroupId")
    const productId = searchParams.get("productId")
    const ref = useRef()

    //берем заново данные с сервера при изменении поиска или языка
    useEffect(() => {
        if(paramGroupId != null) {
            dispatch(AllProductPageThunks.getProductsIdentityByGroupPriceGroupThunk({productGroupId: paramGroupId, search: local.search, languageId: languageState.selected.id}))
        }
    },[languageState.selected.id, local.search])

    useEffect(() => {
        if(ref && ref.current && !wasScrolled) {
            // @ts-ignore
            ref.current.scrollIntoView({ behavior: "smooth" /*or auto*/, block: "center" })
            setWasScrolled(true)
        }
    }, [local.products.length])

    const downloadFile = async (fileType = 5) => {
        if (paramGroupId != null) {
            await Api.files.getProductFileByGroupPriceGroup({productGroupId: paramGroupId,
                search: local.search,
                languageId: languageState.selected.id,
                fileType: fileType
            })
        }
    }

    const setSearch = (search: string) => dispatch(actions.setSearch(search))
    const debounceSearch = useDebouncedCallback((e) => setSearch(e), DebounceProvider.getDebounce())

    return <Protector availableRoles={[Role.ContentManagerRF, Role.ContentManagerLv, Role.ContentManagerRg]}>
        <div className={"all-products u-droppod"}>
            <div className="u-droppod-header">
                <div className="header-text">
                    Возможные продукты
                </div>
            </div>
            {
                paramGroupId === null
                    ? <div>Переданы неправильные аргументы</div>
                    : <>
                        <div className="u-toolbar">
                            <div className="toolbar-row">
                                <AdSearchInput onChange={debounceSearch} className="u-width-auto" />
                                <button className="u-button large accent" onClick={() => downloadFile()}>
                                    <SvgIcon name="table" className="link-icon" />
                                    Сохранить
                                </button>
                                {/*<CommonDropdownButton title={"Сохранить"} rows={INITIAL_SAVE_TYPES} onRowClicked={e => {downloadFile(e.value)}}/>*/}
                            </div>
                        </div>
                        <div className="u-table__scroll-wrapper">
                            <table className="u-table">
                                <thead className="u-thead fixed-head">
                                    <tr>
                                        <th>Код</th>
                                        <th>Наименование</th>
                                    </tr>
                                </thead>

                                <tbody className="u-tbody">
                                    {
                                        _.orderBy(local.products, x => x.name.toLowerCase()).map(x => {
                                            return x.id === paramGroupId || x.id === productId
                                            // @ts-ignore
                                                ? <tr ref={ref} key={x.id} className={"table-row--selected"}>
                                                    <td>{x.id}</td>
                                                    <td>{x.name}</td>
                                                </tr>
                                                : <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.name}</td>
                                                </tr>
                                        })
                                    }
                                    <tr></tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </>
            }
        </div>
    </Protector>
}