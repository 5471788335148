import React, { useEffect } from "react"
import "./GroupCatalogsBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import {
	actions,
	GroupCatalog,
} from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent"
import WebsiteSelector from "../../../../../library/smartComponents/websiteSelector/WebsiteSelector"
import { AdditionalTabThunks } from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent/thunks"
import _ from "lodash"
import { WebsiteSelectors } from "../../../../../redux/reducers/webSites/websiteSelectors"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { CategoryTabSelectors } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors"
import { AdditionalTabSelectors } from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent/additionalTabSelectors"
import { Role } from "../../../../../domain/Role"
import { WebsiteSchema } from "../../../../../domain/types"
import Protector from "../../../../../library/smartComponents/authorization/Protector"
import InformationTableRow from "../../../../../library/tables/ErrorTableRow"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import { actions as informActions } from "../../../../../redux/reducers/informer"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import KeysCodes, { keyDownMessage } from "../../../../../utils/KeysCodes"
import { MessageBusSelectors } from "../../../../../redux/reducers/messageBus/messageBusSelectors"
import { actions as messageBusActions } from "../../../../../redux/reducers/messageBus"
export default function GroupCatalogsBlock() {
	const website = useSelector(WebsiteSelectors.getSelected)
	const group = useSelector(GroupPageSelectors.getGroup)
	const printedCategories = useSelector(
		CategoryTabSelectors.getCurrentPrintedCats
	)
	const webCategories = useSelector(CategoryTabSelectors.getCurrentWebCats)
	const groupCatalogs = useSelector(AdditionalTabSelectors.getGroupCatalogs)
	const isOnSite = useSelector(AdditionalTabSelectors.getIsOnSite)
	const isChangingStatusOnSite = useSelector(
		AdditionalTabSelectors.getIsChangingStatusOnSite
	)
	const isAddingOrRemovingFromSite = useSelector(
		AdditionalTabSelectors.getIsAddingOrRemovingFromSite
	)

	const canAccessOnlyToWeb = useSelector(
		GroupPageAuthSelectors.getCanChangeShowStatusToWebCatalogs
	)
	const canAccessToPrinted = useSelector(
		GroupPageAuthSelectors.getCanChangeShowStatusToPrintedCatalogs
	)
	const canAccessToAutoinstrument = useSelector(
		GroupPageAuthSelectors.getCanChangeShowStatusToAutoinstrumentCatalog
	)

	const canAccessLatvianSchema = useSelector(
		GroupPageAuthSelectors.getCanAccessToLatvianWebsites
	)
	const canAccessRussianSchema = useSelector(
		GroupPageAuthSelectors.getCanAccessToRussianWebsites
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			AdditionalTabThunks.getProductGroupCatalogs({
				productGroupId: group.id,
			})
		)
	}, [printedCategories.length, webCategories.length])

	useEffect(() => {
		if (website.id === 0) return
		dispatch(
			AdditionalTabThunks.isOnSite({
				productGroupId: group.id,
				websiteId: website.id,
			})
		)
	}, [website.id])

	const setSelected = (catalog: GroupCatalog) => {
		if (!canAccessToCatalog(catalog)) return
		dispatch(actions.setSelectedCatalog(catalog))
	}

	const canAccessToCatalog = (catalog: GroupCatalog): boolean => {
		const autoinstrumentRu = 21

		if (catalog.catalogCategoryId && canAccessToPrinted) {
			return true
		}

		if (
			canAccessToAutoinstrument &&
			catalog.catalogId === autoinstrumentRu
		) {
			return true
		}
		if (
			canAccessOnlyToWeb &&
			catalog.webCategoryId &&
			catalog.catalogId !== autoinstrumentRu
		) {
			return true
		}
		return false
	}

	const setShowStatus = () => {
		const selectedCatalogs = groupCatalogs
			.filter((x) => x.selected)
			.filter((x) => canAccessToCatalog(x))
		if (selectedCatalogs.length === 0) {
			dispatch(informActions.inform("Выберите каталог для изменения"))
			return
		}
		const args = selectedCatalogs.map((x) => {
			return {
				productGroupId: x.productGroupId,
				catalogId: x.catalogId,
				showStatus: !x.showStatus,
				webCategoryId: x.webCategoryId,
				catalogCategoryId: x.catalogCategoryId,
			}
		})
		dispatch(AdditionalTabThunks.changeShowStatus(args))
	}

	const addOrRemoveFromSite = () => {
		if (website.schema === WebsiteSchema.Latvian && !canAccessLatvianSchema)
			return
		if (website.schema === WebsiteSchema.Russian && !canAccessRussianSchema)
			return
		if (isOnSite)
			dispatch(
				AdditionalTabThunks.removeGroupFromSite({
					productGroupId: group.id,
					websiteId: website.id,
				})
			)
		else {
			if (
				groupCatalogs.filter((value) => value.webCategoryId !== null)
					.length === 0
			) {
				dispatch(
					informActions.inform(
						"Добавьте группу к веб каталогу, прежде чем добавлять на сайт"
					)
				)
				return
			}
			dispatch(
				AdditionalTabThunks.addGroupToSite({
					productGroupId: group.id,
					websiteId: website.id,
				})
			)
		}
	}

	const addOrRemoveFromSiteButton = (
		<button
			disabled={isAddingOrRemovingFromSite}
			className={"u-button large primary"}
			onClick={() => addOrRemoveFromSite()}
		>
			{!isAddingOrRemovingFromSite && (
				<SvgIcon
					name={!isOnSite ? "circle-plus" : "circle-x"}
					className="link-icon"
				/>
			)}
			{isAddingOrRemovingFromSite
				? "Loading"
				: !isOnSite
				? "Добавить на сайт"
				: "Удалить с сайта"}
		</button>
	)

	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "gcat-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.ENTER:
					setShowStatus()
					break
			}
		}
	}, [message.rand])

	return (
		<>
			<div className="u-droppod-section-header header-with-toolbar">
				Отображение
				<div className="u-toolbar">
					<div className="toolbar-row">
						<Protector
							availableRoles={[
								Role.Photographer,
								Role.ContentManagerLv,
								Role.ContentManagerRg
							]}
							justHide={true}
						>
							<WebsiteSelector height={31} />
						</Protector>
						<button
							type="button"
							className="u-button large primary"
							disabled={isChangingStatusOnSite}
							onClick={() => setShowStatus()}
						>
							<SvgIcon name="eye" className="link-icon" />
							Изменить отображение
						</button>
						<Protector
							availableRoles={[
								Role.Photographer,
								Role.ContentManagerLv,
								Role.ContentManagerRg
							]}
							justHide={true}
						>
							{isOnSite === null ? (
								<></>
							) : website.schema === WebsiteSchema.Latvian ? (
								canAccessLatvianSchema ? (
									addOrRemoveFromSiteButton
								) : (
									<></>
								)
							) : website.schema === WebsiteSchema.Russian ? (
								canAccessRussianSchema ? (
									addOrRemoveFromSiteButton
								) : (
									<></>
								)
							) : (
								<></>
							)}
						</Protector>
					</div>
				</div>
			</div>

			<div className="u-table__scroll-wrapper almost-full-table">
				<table
					className="u-table"
					tabIndex={0}
					id="gcat-table"
					onKeyDown={(e) => {
						keyDownMessage(e, "gcat-table", dispatch)
					}}
				>
					<thead className="u-thead">
						<tr>
							<th></th>
							<th>Каталог</th>
							<th>№</th>
							<th>Раздел</th>
							<th>Подраздел</th>
							<th>Выводить в кат.</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{groupCatalogs.isEmpty() ? (
							<InformationTableRow
								text={"Пока ничего нет"}
								colSpan={6}
							/>
						) : (
							_.orderBy(
								groupCatalogs,
								(value) => value.webCategoryId
							).map((x) => {
								const key = `${x.catalogCategoryId}${x.webCategoryId}${x.catalogParentCategoryId}${x.catalogName}`
								return (
									<tr
										onClick={() => setSelected(x)}
										className={
											x.selected
												? "--selected --grey"
												: ""
										}
										key={key}
									>
										<td>
											<input
												disabled={
													!canAccessToCatalog(x)
												}
												type={"checkbox"}
												readOnly={true}
												checked={x.selected}
											/>
										</td>
										<td>{x.catalogName}</td>
										<td>{x.sort ?? "-"}</td>
										<td>{x.catalogCategoryId ?? "-"}</td>
										<td>
											{x.catalogParentCategoryId ??
												x.webCategoryId}
										</td>
										<td>
											<input
												type={"checkbox"}
												checked={x.showStatus}
												disabled={true}
											/>
										</td>
									</tr>
								)
							})
						)}
						<tr></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
