import {
	IEntityScopeOfApplication,
	IScopeOfApplication,
} from "../../../../../domain/models/scopes/IScopeOfApplication"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"

export type ProductScopeApplicationsState = {
	productScopes: IEntityScopeOfApplication[]

	allScopes: IScopeOfApplication[]
	/**
	 * Выбранная область применения в селекторе
	 * */
	selectedScope: IScopeOfApplication | null
}

const INITIAL_STATE: ProductScopeApplicationsState = {
	productScopes: [],
	allScopes: [],
	selectedScope: null,
}

const slice = createSlice({
	name: "productScopeApplications",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedScope: (state, { payload }: PayloadAction<number>) => {
			const selectedScope = state.allScopes.find(
				(scope) => scope.id === payload
			)
			state.selectedScope = selectedScope || null
		},
		setSelectedProductScope: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			const currentSelectedScope = state.productScopes.find(
				(x) => x.selected
			)
			if (currentSelectedScope) {
				currentSelectedScope.selected = false
			}
			if (currentSelectedScope?.id === payload) {
				return
			}
			const selectedScope = state.productScopes.find(
				(scope) => scope.id === payload
			)
			if (selectedScope) {
				selectedScope.selected = true
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getScopes.fulfilled,
			(state, { payload }) => {
				state.allScopes = payload
			}
		)
		builder.addCase(
			ProductPageThunks.getProductScopes.fulfilled,
			(state, { payload }) => {
				state.productScopes = payload.map((scope) => {
					return {
						...scope,
						selected: false,
					}
				})
			}
		)
		builder.addCase(
			ProductPageThunks.addProductToScope.fulfilled,
			(state, { meta: { arg } }) => {
				const newScope = state.allScopes.find(
					(x) => x.id === arg.scopeId
				)
				if (newScope) {
					state.productScopes.push({
						...newScope,
						selected: false,
					})
				}
				state.allScopes = state.allScopes.filter(
					(x) => x.id !== arg.scopeId
				)
				state.selectedScope = null
			}
		)
		builder.addCase(
			ProductPageThunks.removeProductFromScope.fulfilled,
			(state, action) => {
				const removedScope = state.productScopes.find(
					(x) => x.id === action.meta.arg.scopeId
				)
				if (removedScope) {
					state.allScopes.push({
						...removedScope,
					})
				}
				state.productScopes = state.productScopes.filter(
					(x) => x.id !== action.meta.arg.scopeId
				)
			}
		)
		builder.addCase(
			ProductPageThunks.changeProductScopes.fulfilled,
			(state, action) => {
				const newScope = state.allScopes.find(
					(x) => x.id === action.meta.arg.targetScopeId
				)
				const removedScope = state.productScopes.find(
					(x) => x.id === action.meta.arg.scopeId
				)
				if (newScope && removedScope) {
					state.productScopes.push({
						...newScope,
						selected: true,
						sort: removedScope.sort,
					})
					state.productScopes = state.productScopes.filter(
						(x) => x.id !== removedScope.id
					)
					state.allScopes.push({
						...removedScope,
						sort: 0,
					})
					state.allScopes = state.allScopes.filter(
						(x) => x.id !== newScope.id
					)
					state.selectedScope = null
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
