import React from "react"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/imageTypes"
import SimpleSelect from "../../../app/common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import {LanguageSelectors} from "../../../redux/reducers/languages/languageSelectors"
import {ImageTypeThunks} from "../../../redux/reducers/imageTypes/thunk"
import {ImageTypeSelectors} from "../../../redux/reducers/imageTypes/imageTypeSelectors"

interface IImageTypeSelectorProps {
    height?: number
    disabled?: boolean
}

function ImageTypeSelector(props: IImageTypeSelectorProps) {

    const language = useSelector(LanguageSelectors.getSelected)
    const imageTypes = useSelector(ImageTypeSelectors.getFilteredImageTypes)
    const selected = useSelector(ImageTypeSelectors.getSelected)

    const dispatch = useDispatch()


    useEffect(() => {
        if(language.id > 0)
            dispatch(ImageTypeThunks.getImageTypes({languageId: language.id}))
    }, [language.id])

    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={ToOptionProvider.imageTypeToOption}
        isDisabled={props.disabled ?? false}
        traceName={"ImageTypes"}
        options={imageTypes}
        className={"selector"}
        onChange={newValue => changeSelected(newValue)}
        height={props.height}
        value={selected}
    />
}

export default ImageTypeSelector