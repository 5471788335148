import queryTypes, {ActionType, IApplicationResponse} from "./baseTypes"
import HttpActions from "./HttpActions"

export default class BaseApi {
    protected baseUrl: string;
    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_API === undefined ? "http://localhost:5000" : process.env.REACT_APP_SERVER_API
        if(this.baseUrl.at(this.baseUrl.length - 1) === "/")
            this.baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1)
    }

    protected async sendQuery<T>(url: string, data: object | null, method: ActionType, authorized: boolean, timeout?: number): Promise<IApplicationResponse<T>> {
        url = this.baseUrl + url
        const responseMethods = {
            [queryTypes.post]: async () => HttpActions.post<T>(url, data, authorized, timeout),
            [queryTypes.get]: async () => HttpActions.get<T>(url, data, authorized, timeout),
            [queryTypes.put]: async () => HttpActions.put<T>(url, data, authorized, timeout),
            [queryTypes.delete]: async () => HttpActions.delete<T>(url, data, authorized, timeout),
            [queryTypes.postFile]: async () => HttpActions.postFile<T>(url, data, authorized, timeout),
            [queryTypes.postNullableFile]: async () => HttpActions.postNullableFile<T>(url, data, authorized, timeout),
            [queryTypes.putFile]: async () => HttpActions.putFile<T>(url, data, authorized, timeout)
        }

        return await responseMethods[method]()
    }

    protected async downloadFile(url: string, data: object | null, authorized: boolean): Promise<IApplicationResponse<void>> {
        url = this.baseUrl + url
        return await HttpActions.downloadFile(url, data, authorized, 180 * 1000)
    }

    /*  protected signalRConnection = (url: string): HubConnection => {
        const fullUrl = this.baseUrl + url
        console.log(`Connecting to ${fullUrl}`)
        return new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(fullUrl, {
                withCredentials: false,
                accessTokenFactory(): string | Promise<string> {
                    return LocalStorageProvider.getToken() ?? ""
                }
            })
            .withAutomaticReconnect()
            .build()
    }*/
}