import { AppState } from "../../../index"
import { FileStatus } from "../../../../../domain/models/files/FileStatus"
import { createSelector } from "@reduxjs/toolkit"

export class ProductFilesSelectors {
	static getFileTypes = (state: AppState) =>
		state.pages.productPage.filesState.common.fileTypes

	static getFilesToLoad = (state: AppState) =>
		state.pages.productPage.filesState.common.filesToLoad

	static getFileTypesMask = () => "*"

	static getFilesToUpload = (state: AppState) =>
		state.pages.productPage.filesState.common.filesToLoad.filter(
			(x) => x.loadStatus === FileStatus.Loaded
		)

	static getCanUploadFiles = createSelector(
		[ProductFilesSelectors.getFilesToLoad],
		(images) =>
			images.length > 0 &&
			!images.some((x) => x.loadStatus === FileStatus.MoreInfoNeeded) &&
			images.some((x) => x.loadStatus === FileStatus.Loaded)
	)

	static getLoadingFilesCount = (state: AppState) =>
		state.pages.productPage.filesState.common.loadingFilesCount

	static getLoadedFilesCount = (state: AppState) =>
		state.pages.productPage.filesState.common.loadedFilesCount
}
