import { ILanguage } from "../../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LanguagesThunks } from "../../languages/thunk"
import { ProductNameAttributeStrategy } from "../../../../domain/ProductNameAttributeStrategy"

export type TranslateComponentState = {
	phraseType: PhraseType
	translateCategories: ITranslateCategory
	selectedLanguage: ILanguage
	isLoading: boolean
	newLanguage: string
	languageToRemove: ILanguage
	productNameAttributeGenerationStrategy: ProductNameAttributeStrategy
}

export enum PhraseType {
	Nothing,
	All,
	NotTranslated,
}

interface ITranslateCategory {
	isCatCategoriesChecked: boolean
	isWebCatChecked: boolean
	isAttributesChecked: boolean
	IsPictogramsChecked: boolean
	IsTechDescriptionChecked: boolean
	IsK2UChecked: boolean
}

const INITIAL_STATE: TranslateComponentState = {
	phraseType: PhraseType.Nothing,
	selectedLanguage: { id: 11, name: "Русский" },
	translateCategories: {
		isAttributesChecked: true,
		IsK2UChecked: true,
		isCatCategoriesChecked: true,
		IsPictogramsChecked: true,
		isWebCatChecked: true,
		IsTechDescriptionChecked: true,
	},
	productNameAttributeGenerationStrategy:
		ProductNameAttributeStrategy.ByAttributeShortNameAndAttributeName,
	isLoading: false,
	newLanguage: "",
	languageToRemove: { id: -1, name: "Loading" },
}

const slice = createSlice({
	name: "translate-page",
	initialState: INITIAL_STATE,
	reducers: {
		setPhaseType(
			state: TranslateComponentState,
			action: PayloadAction<PhraseType>
		) {
			if (state.phraseType === action.payload)
				state.phraseType = PhraseType.Nothing
			else state.phraseType = action.payload
		},
		setProductAttributeGenerationStrategy(
			state: TranslateComponentState,
			action: PayloadAction<ProductNameAttributeStrategy>
		) {
			state.productNameAttributeGenerationStrategy = action.payload
		},
		setLanguage(
			state: TranslateComponentState,
			action: PayloadAction<ILanguage | undefined>
		) {
			if (action.payload !== undefined)
				state.selectedLanguage = action.payload
		},
		setTranslateCategoryChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.IsTechDescriptionChecked = action.payload
			state.translateCategories.isWebCatChecked = action.payload
			//state.translateCategories.IsK2UChecked = action.payload;
			state.translateCategories.IsPictogramsChecked = action.payload
			state.translateCategories.isAttributesChecked = action.payload
			state.translateCategories.isCatCategoriesChecked = action.payload
		},
		setCatCategoriesChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.isCatCategoriesChecked = action.payload
		},
		setAttributesChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.isAttributesChecked = action.payload
		},
		setPictogramsChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.IsPictogramsChecked = action.payload
		},
		setK2UChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.IsK2UChecked = action.payload
		},
		setWebCategoriesChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.isWebCatChecked = action.payload
		},
		setTechDescriptionChecked(
			state: TranslateComponentState,
			action: PayloadAction<boolean>
		) {
			state.translateCategories.IsTechDescriptionChecked = action.payload
		},
		setNewLanguage(
			state: TranslateComponentState,
			action: PayloadAction<string>
		) {
			state.newLanguage = action.payload
		},
		setLanguageToRemove(
			state: TranslateComponentState,
			action: PayloadAction<ILanguage>
		) {
			state.languageToRemove = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			LanguagesThunks.addLanguage.fulfilled,
			(state, action) => {
				state.newLanguage = ""
			}
		)
	},
})

const reducer = slice.reducer
const actions = slice.actions

export { reducer, actions }
