import {AppState} from "../index"
import {createSelector} from "@reduxjs/toolkit"
import _ from "lodash"

export class PictogramsSelectors {
    static getPictograms = createSelector(
        [(state: AppState) => state.pictogramsState.pictograms],
        (x) => _.orderBy(x, x => x.sort))

    static getFilteredPictograms = createSelector([
        (state: AppState) => state.pictogramsState.pictograms,
        (state: AppState) => state.pictogramsState.filter],
    (x, filter) =>  _.orderBy(x.filter(x => !filter.includes(x.id), x => x.sort)))

    static getSelected = (state: AppState) => state.pictogramsState.selected
}