import { Tab, Tabs } from "react-bootstrap"
import React, { useState } from "react"
import "./productPageTabs.scss"
import { useDispatch } from "react-redux"

import ProductDescriptionTab from "./tabs/ProductDescriptionTab"
import { AdditionalTab } from "./tabs/AdditionalTab"
import ProductCategoryTab from "./tabs/ProductCategoryTab"
import { FilesTab } from "./tabs/FilesTab"

export default function ProductPageTabs() {
	// const local = useSelector<AppState, NewProductState>(
	// 	(x) => x.pages.productGroupPage.common
	// )
	const [local, setLocal] = useState({ currentTab: "description" })
	const dispatch = useDispatch()
	const [isDescriptionTabLoaded, setDescriptionTabLoaded] = useState(false)

	const [isCategoryLoaded, setCategoryLoaded] = useState(false)
	const [isFilesTabLoaded, setFilesTabLoaded] = useState(false)
	const [isAdditionalLoaded, setAdditionalLoaded] = useState(false)

	return (
		<Tabs
			activeKey={local.currentTab}
			onSelect={(k) => setLocal({ currentTab: k! })}
			className="tabs-wrapper"
		>
			<Tab
				eventKey={"description"}
				title="Описание"
				className="u-flex-column"
			>
				{local.currentTab === "description" ? (
					<div className="u-droppod-scroll-wrapper">
						<ProductDescriptionTab
							onMount={async () => setDescriptionTabLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>
			<Tab
				eventKey={"category"}
				title="Категории"
				className="u-flex-column"
			>
				{
					//избегаем unmount
					local.currentTab === "category" ? (
						<div className="u-droppod-scroll-wrapper">
							<ProductCategoryTab
								onMount={async () => setCategoryLoaded(true)}
							/>
						</div>
					) : (
						<></>
					)
				}
			</Tab>
			<Tab
				eventKey={"additional"}
				title="Дополнительно"
				className="u-flex-column"
			>
				{local.currentTab === "additional" ? (
					<div className="u-droppod-scroll-wrapper">
						<AdditionalTab
							onMount={async () => setAdditionalLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>
			<Tab eventKey={"files"} title="Файлы" className="u-flex-column">
				{local.currentTab === "files" ? (
					<div className="u-droppod-scroll-wrapper">
						<FilesTab
							onMount={async () => setFilesTabLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>

			{/*<Tab
				eventKey={"description"}
				title="Описание"
				className="u-flex-column"
			>
				{local.currentTab === "description" || isTablePartLoaded ? (
					<div className="u-droppod-scroll-wrapper">
						<DescriptionTab
							onMount={async () => setGraphicLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>*/}
			{}
		</Tabs>
	)
}
