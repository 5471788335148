import { useDispatch, useSelector } from "react-redux"
import ProductPageCharacteristicsSelectors from "../../../redux/reducers/pages/productPage/characteristics/ProductPageCharacteristicsSelectors"
import { actions } from "../../../redux/reducers/pages/productPage/characteristics"
import AdCharacteristics from "../../../library/characteristics/AdCharacteristics"
import { useEffect } from "react"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { actions as informActions } from "../../../redux/reducers/informer"
import Constants from "../../../domain/Constants"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export function ProductCharacteristic() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	const language = useSelector(LanguageSelectors.getSelected)
	if (!productId) return <></>

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			ProductPageThunks.getProductCharacteristics({
				productId,
				languageId: language.id,
			})
		)
	}, [])

	const characteristics = useSelector(
		ProductPageCharacteristicsSelectors.getCharacteristics
	)
	const sourceCharacteristics = useSelector(
		ProductPageCharacteristicsSelectors.getSourceCharacteristics
	)

	const saveCharacteristic = (sort: number) => {
		const value = characteristics[sort].value
		if (!value) {
			dispatch(informActions.inform("Заполните значение"))
			return
		}
		dispatch(
			ProductPageThunks.changeProductCharacteristic({
				productId,
				sort,
				value,
				languageId: language.id,
			})
		)
	}
	const createCharacteristic = (sort: number) => {
		const value = characteristics[sort].value
		if (!value) {
			dispatch(informActions.inform("Заполните значение"))
			return
		}
		dispatch(
			ProductPageThunks.addProductCharacteristic({
				productId,
				value,
				languageId: language.id,
			})
		)
	}

	const rus = language.id === Constants.RussianLanguage
	const setCharacteristic = (sort: number, value: string) => {
		if (!rus) {
			dispatch(informActions.inform("Введите текст на русском языке"))
			return
		} else {
			dispatch(actions.setCharacteristic({ sort, value }))
		}
	}
	/*const setCharacteristic = (sort: number, value: string) =>
		dispatch(actions.setCharacteristic({ sort, value }))
	*/
	function addNewRow() {
		dispatch(actions.addCharacteristic())
	}

	function setCharacteristicEditing(sort: number) {
		dispatch(actions.setCharacteristicEditing(sort))
	}

	const removeNewCharacteristics = (sort: number) =>
		dispatch(actions.removeCharacteristic(sort))

	const deleteCharacteristics = (sort: number) => {
		dispatch(
			ProductPageThunks.deleteProductCharacteristic({
				productId,
				sort,
			})
		)
	}

	const canAddNewRows = language.id === Constants.RussianLanguage
	const canChangeProductCharacteristics = useSelector(
		ProductPageAuthSelectors.getCanChangeProductCharacteristics
	)

	return (
		<>
			<AdCharacteristics
				characteristics={characteristics}
				sourceCharacteristics={sourceCharacteristics}
				onChanged={setCharacteristic}
				setCharacteristicEditing={setCharacteristicEditing}
				saveCharacteristic={saveCharacteristic}
				createCharacteristic={createCharacteristic}
				canEdit={true}
				canAddNewRows={canAddNewRows}
				onAddNewRow={addNewRow}
				deleteCharacteristics={deleteCharacteristics}
				onDeleteNewRow={removeNewCharacteristics}
				canChangeProductCharacteristics={
					canChangeProductCharacteristics
				}
			/>
		</>
	)
}
