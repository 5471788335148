import {AppState} from "../../../index"
import _ from "lodash"

export class TablePartSelectors {
    static getIsProductsLoading = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.isProductsLoading
    static getProducts = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.products
    static getSelectedProduct = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.selectedProduct
    static getGroupProducts = (state: AppState) => _.orderBy(state.pages.productGroupPage.tableTabState.tablePartState.groupProducts, x => x.sort)
    static getSelectedGroupProduct = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.selectedGroupProduct
    static getAttributes = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.attributes
    static getAttributesOrder = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.attributesOrder
    static getAttributesOrderKeysBySort = (state: AppState) =>
        _.orderBy(Object.keys(state.pages.productGroupPage.tableTabState.tablePartState.attributesOrder),
            (key) => state.pages.productGroupPage.tableTabState.tablePartState.attributesOrder[key].sort)
            .map(x => Number(x))
    static getSelectedAttributeColumn = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.selectedAttributeColumn
    static getSelectedAttribute = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.selectedAttribute
    static getAccumulatedChanges = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.accumulatedChanges
    static getArticle = (state: AppState) => state.pages.productGroupPage.tableTabState.tablePartState.article
}