import {createSlice, PayloadAction} from "@reduxjs/toolkit"


export type messageBusState = {
    table_id: string | null,
    key: string | null,
    rand: number | null // уникальный ключ сообщения, чтобы в useEffect можно было отличать, то ли это сообщение, или уже другое
};


const INITIAL_STATE: messageBusState = {
    table_id: null,
    key: null,
    rand: null
}

const messageBusSlice = createSlice({
    name: "messageBus",
    initialState: INITIAL_STATE,
    reducers: {
        setMessage(state: messageBusState, action: PayloadAction<{table_id: string | null, key: string}>){
            console.log(action.payload)
            state.table_id = action.payload.table_id
            state.key = action.payload.key
            state.rand = Math.random()
        },
        clearMessage(state: messageBusState, action: PayloadAction){
            state.table_id = null
            state.key = null
            state.rand = null
        }
    }
})

const reducer = messageBusSlice.reducer
const actions = messageBusSlice.actions

export {reducer, actions}