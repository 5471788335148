import Api from "../../../../../api"
import {createAutogenApiThunk} from "../../../../createApiThunkV2"

export class ProductsListThunks {
    static getProducts = createAutogenApiThunk({
        typePrefix: "product-page/get-products",
        apiCall: Api.products.getProductsIdentityWithoutGroup,
        errorMessage: "Не удалось получить список продуктов"
    })

    static addProductsToGroup = createAutogenApiThunk({
        typePrefix: "product-page/add-products-to-group",
        apiCall: Api.products.addProductToGroup,
        errorMessage: "Не удалось добавить продукты в группу"
    })
}