import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/groupPage"
import { useNavigate, useSearchParams } from "react-router-dom"
import GroupSelectorBlock from "./GroupSelectorBlock"
import { NewProductThunks } from "../../../redux/reducers/pages/groupPage/thunks"
import "./groupToolbar.scss"
import TextCheckbox from "../../common/basic/checkboxes/TextCheckbox"
import RouteProvider from "../../../utils/RouteProvider"
import { GroupPageSelectors } from "../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import AdDefaultInput from "../../../library/inputs/AdDefaultInput"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { actions as informerActions } from "../../../redux/reducers/informer"
import Constants from "../../../domain/Constants"
import { GroupPageAuthSelectors } from "../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import { TablePartComponentThunks } from "../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"

export default function GroupToolbar() {
	const language = useSelector(LanguageSelectors.getSelected)
	const group = useSelector(GroupPageSelectors.getGroup)
	const isSaveLoading = useSelector(GroupPageSelectors.getIsSaveLoading)
	const isRejectLoading = useSelector(GroupPageSelectors.getIsRejectLoading)
	const sign = useSelector(GroupPageSelectors.getSelectedSign)
	const series = useSelector(GroupPageSelectors.getSelectedSeries)
	const attribute = useSelector(GroupPageSelectors.getSelectedAttribute)
	const sellmark = useSelector(GroupPageSelectors.getSelectedSellmark)

	//прикрепляем продукты из параметров
	const [searchParams, setSearchParams] = useSearchParams()
	const productIds = searchParams.getAll("productId")

	const canChangeId = useSelector(GroupPageAuthSelectors.getCanChangeId)
	const canChangeName = useSelector(GroupPageAuthSelectors.getCanChangeName)
	const canChangeDescriptionChecked = useSelector(
		GroupPageAuthSelectors.getCanChangeDescriptionChecked
	)
	const canChangeImageChecked = useSelector(
		GroupPageAuthSelectors.getCanChangeImageChecked
	)
	const canChangeIsToolset = useSelector(
		GroupPageAuthSelectors.getCanChangeIsToolset
	)
	const canDelete = useSelector(GroupPageAuthSelectors.getCanDeleteGroup)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const canSaveChanges =
		canChangeDescriptionChecked ||
		canChangeId ||
		canChangeImageChecked ||
		canChangeName

	const setId = (id: string) => {
		dispatch(actions.setId(id))
	}
	const setName = (name: string) => {
		dispatch(actions.setName(name))
	}
	const setIsToolset = (isToolset: boolean) => {
		dispatch(actions.setIsToolset(isToolset))
	}
	const setIsDescriptionChecked = (isDescrChecked: boolean) => {
		dispatch(actions.setIsDescrChecked(isDescrChecked))
	}
	const setIsPhotoChecked = (isPhotoChecked: boolean) => {
		dispatch(actions.setIsImageChecked(isPhotoChecked))
	}

	const requestTableRefresh = () => {
		dispatch(actions.requestTableRefresh())
	}

	const createOrUpdate = async () => {
		if (isSaveLoading) return

		if (!group.wasCreate) {
			if (canSaveChanges) {
				if (language.id != Constants.RussianLanguage) {
					dispatch(
						informerActions.inform(
							"Группы можно создавать только на русском языке"
						)
					)
					return
				}
				if (group.name == null || group.name.isNullOrEmpty()) {
					dispatch(informerActions.inform("Введите наименование"))
					return
				}
				await dispatch(
					NewProductThunks.createProductGroup({
						id: group.id,
						autoId: group.autoId,
						name: group.name,
						seriesId: series?.id ?? null,
						signId: sign?.id ?? null,
						sellmarkId: sellmark?.id ?? null,
						languageId: language.id,
						isToolset: group.isToolset,
						mainAttributeId: sign?.id ?? null,
						productIds: productIds,
					})
				)
				//прикрепляем продукты из параметров
				if (productIds.isEmpty()) return

				searchParams.delete("productId")
				setSearchParams(searchParams, { replace: true })

				return
			}
		} else {
			if (canSaveChanges) {
				await dispatch(
					NewProductThunks.updateProductGroup({
						id: group.id,
						name: group.name,
						description: group.description,
						descriptionWeb: group.descriptionWeb,
						seriesId: series?.id == 0 ? null : series?.id ?? null,
						signId: sign?.id == 0 ? null : sign?.id ?? null,
						sellmarkId:
							sellmark?.id == 0 ? null : sellmark?.id ?? null,
						priceGroupId: group.priceGroupId,
						mainAttributeId:
							attribute?.id == 0 ? null : attribute?.id ?? null,
						siteId: group.siteId,
						isDescriptionChecked: group.isDescriptionChecked,
						isToolset: group.isToolset,
						isImageChecked: group.isImageChecked,
						languageId: language.id,
					})
				)
			}
		}
	}

	const deleteOrDiscard = async () => {
		if (isRejectLoading) return
		if (group.wasCreate) {
			const res = await dispatch(
				NewProductThunks.deleteProductGroup({ id: group.id })
			)
			// @ts-ignore
			if (!res.meta.rejectedWithValue)
				navigate(RouteProvider.products.path)
			else
				dispatch(
					informerActions.inform(
						"Не удалось удалить группу продуктов"
					)
				)
		} else {
			await dispatch(NewProductThunks.discardReserve({ id: group.id }))
			navigate(RouteProvider.products.path)
		}
	}

	return (
		<div className="u-toolbar">
			<div className="new-product-toolbar__first-row-wrapper toolbar-row">
				<AdDefaultInput
					className="input--id"
					value={group.id}
					onChange={setId}
					disabled={!canChangeId}
					placeholder="№"
				/>
				<AdDefaultInput
					className="input--name"
					value={group.name ?? ""}
					onChange={setName}
					disabled={!canChangeName}
					placeholder="Наименование новой карточки"
				/>
				<TextCheckbox
					onChange={setIsToolset}
					text={"Набор"}
					isChecked={group.isToolset}
					readonly={!canChangeIsToolset}
				/>
				{group.wasCreate ? (
					<>
						<TextCheckbox
							onChange={setIsDescriptionChecked}
							text={"Описание"}
							isChecked={group.isDescriptionChecked}
							readonly={!canChangeDescriptionChecked}
						/>
						<TextCheckbox
							onChange={setIsPhotoChecked}
							text={"Фото"}
							isChecked={group.isImageChecked}
							readonly={!canChangeImageChecked}
						/>
					</>
				) : (
					<></>
				)}
				{canSaveChanges ? (
					<button
						className="u-button primary square"
						onClick={createOrUpdate}
						title="Сохранить изменения"
					>
						{isSaveLoading ? (
							<SvgIcon name="refresh-cw" className="u-rotating" />
						) : (
							<SvgIcon name="floppy" />
						)}
					</button>
				) : (
					<></>
				)}
				{canDelete ? (
					<button
						className="u-button primary square"
						onClick={deleteOrDiscard}
						title="Удалить карточку"
					>
						{isRejectLoading ? (
							<SvgIcon name="refresh-cw" className="u-rotating" />
						) : (
							<SvgIcon name="circle-x" />
						)}
					</button>
				) : (
					<></>
				)}
				<button
					className="u-button primary square"
					onClick={() => navigator.clipboard.writeText("•")}
					title="В буфер обмена"
				>
					<SvgIcon name="circle" />
				</button>
				{group.wasCreate ? (
					<button
						className="u-button accent large"
						onClick={requestTableRefresh}
					>
						Обновить
					</button>
				) : (
					<></>
				)}
			</div>
			<GroupSelectorBlock />
		</div>
	)
}
