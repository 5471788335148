import React, { useEffect } from "react"
import "./CatalogsBlock.scss"
import { CatalogGroup, ICatalogGroup } from "../../../../../domain/types"
import CatalogSelector from "../../../../../library/smartComponents/catalogSelector/CatalogSelector"
import AdCategoryDynamicTable from "../../../../../library/categories/AdCategoryDynamicTable"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent"
import { CategoryTabComponentThunks } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent/thunks"
import { ISelectableIndexModel } from "../../../../../redux/types"
import { LanguageSelectors } from "../../../../../redux/reducers/languages/languageSelectors"
import { CatalogSelectors } from "../../../../../redux/reducers/catalogs/catalogSelectors"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { CategoryTabSelectors } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import { actions as informerActions } from "../../../../../redux/reducers/informer"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import { ICategory } from "../../../../../domain/models/categories/ICategory"
import AdCategorySelectRow from "../../../../../library/categories/AdCategorySelectRow"
import { CatalogGroupSelectors } from "../../../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import SimpleSelect, { IOptionType } from "../../../../common/basic/selectors/SimpleSelect"
import { CatalogGroupsOptions } from "../../../../../domain/database/CatalogGroup"

const toOption = (catalog: ICatalogGroup): IOptionType => {
	return {
		value: catalog.id,
		label: catalog.name
	}
}

export default function CatalogsBlock() {
	const language = useSelector(LanguageSelectors.getSelected)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const groups = CatalogGroupsOptions.filter(u=>u.isTree);
	const selectedCatalogGroup = useSelector(CategoryTabSelectors.getSelectedCatalogGroup)

	const webCategories = useSelector(CategoryTabSelectors.getWebCategories)
	const printCategories = useSelector(
		CategoryTabSelectors.getPrintedCategories
	)
	const group = useSelector(GroupPageSelectors.getGroup)

	const currentWebCats = useSelector(CategoryTabSelectors.getCurrentWebCats)
	const currentPrintedCats = useSelector(
		CategoryTabSelectors.getCurrentPrintedCats
	)

	const selectedWebCats = useSelector(CategoryTabSelectors.getSelectedWebCats)
	const selectedPrintCats = useSelector(
		CategoryTabSelectors.getSelectedPrintedCats
	)

	const selectedCurrentWebCat = useSelector(
		CategoryTabSelectors.getSelectedCurrentWebCat
	)
	const selectedCurrentPrintedCat = useSelector(
		CategoryTabSelectors.getSelectedCurrentPrintedCat
	)

	const shouldResetWeb = useSelector(CategoryTabSelectors.getShouldResetWeb)
	const shouldResetPrinted = useSelector(
		CategoryTabSelectors.getShouldResetPrinted
	)

	const dispatch = useDispatch()

	const shouldShowPrintCats = useSelector(
		GroupPageAuthSelectors.getShouldShowPrintCategories
	)
	const canControlPrintedCats = useSelector(
		GroupPageAuthSelectors.getCanControlPrintCategories
	)
	const canControlWebCats = useSelector(
		GroupPageAuthSelectors.getCanControlWebCategories
	)

	useEffect(() => {
		dispatch(
			CategoryTabComponentThunks.getCategories({
				catalogGroup: CatalogGroup.Printed,
				languageId: language.id,
			})
		)
	}, [language.id])

	useEffect(() => {
		dispatch(
			CategoryTabComponentThunks.getCategories({
				catalogGroup: selectedCatalogGroup.id,
				languageId: language.id,
			})
		)
	}, [language.id, selectedCatalogGroup.id])

	useEffect(() => {
		const isCategoriesLoaded =
			webCategories.length != 0 && printCategories.length > 1
		const isCatalogReady = shouldShowPrintCats ? catalog.id !== 0 : true
		const isReady = group.wasCreate && isCategoriesLoaded && isCatalogReady
		if (isReady) {
			if (shouldShowPrintCats)
				dispatch(
					CategoryTabComponentThunks.getProductGroupCategories({
						productGroupId: group.id,
						catalogGroup: CatalogGroup.Printed,
						languageId: language.id,
						catalogId: catalog.id,
					})
				)
			dispatch(
				CategoryTabComponentThunks.getProductGroupCategories({
					productGroupId: group.id,
					catalogGroup: selectedCatalogGroup.id,
					languageId: language.id,
				})
			)
		}
	}, [language.id, printCategories.length, webCategories.length, catalog.id, selectedCatalogGroup.id])

	const setCategoryPath = (
		category: ICategory | null,
		catalogGroup: CatalogGroup,
		level: number
	) => {
		dispatch(
			actions.setRowPath({
				category: category,
				catalogGroup: catalogGroup,
				level: level,
			})
		)
	}

	const onCategoryRowReset = (catalog: CatalogGroup) => {
		dispatch(actions.setShouldReset(catalog))
	}
	const setSelectedCategory = (
		tableRow: ISelectableIndexModel<ICategory>,
		catalogGroup: CatalogGroup
	) => {
		dispatch(
			actions.setSelectedCategory({
				rowIndex: tableRow.index,
				catalogGroup: catalogGroup,
			})
		)
	}

	const getCategoryIdByPath = (catalogGroup: CatalogGroup): number | null => {
		let lastLevelCategoryIds: number[]
		if (catalogGroup === CatalogGroup.Printed)
			lastLevelCategoryIds = selectedPrintCats
				.filter((x) => x.children.length === 0)
				.map((x) => x.id)
		else
			lastLevelCategoryIds = selectedWebCats
				.filter((x) => x.children.length === 0)
				.map((x) => x.id)

		if (lastLevelCategoryIds.length > 1) {
			dispatch(
				informerActions.inform(
					"Произошла внутренняя ошибка, перезагрузите страницу"
				)
			)
			return null
		}

		if (lastLevelCategoryIds.length !== 1) {
			dispatch(
				informerActions.inform("Выберите категорию последнего уровня")
			)
			return null
		}

		return lastLevelCategoryIds[0]
	}

	const isCategoryAdded = (
		categoryId: number,
		catalogGroup: CatalogGroup
	): boolean => {
		let existingCategories: ICategory[]
		if (catalogGroup === CatalogGroup.Printed) {
			existingCategories = currentPrintedCats.flatMap((x) => x.model)
		} else {
			existingCategories = currentWebCats.flatMap((x) => x.model)
		}
		const category = existingCategories.find((x) => x.id == categoryId)
		return category !== undefined
	}

	const addCategory = (catalogGroup: CatalogGroup) => {
		const categoryId = getCategoryIdByPath(catalogGroup)
		if (categoryId === null) return
		const isAdded = isCategoryAdded(categoryId, catalogGroup)
		if (isAdded) {
			dispatch(informerActions.inform("Категория уже добавлена"))
			return
		}
		dispatch(
			CategoryTabComponentThunks.addProductGroupToCats({
				productGroupIds: Array.of(group.id),
				categoriesIds: Array.of(categoryId),
				catalogGroup: catalogGroup,
				catalogId: catalog.id,
			})
		)
	}

	const removeCategory = (catalogGroup: CatalogGroup) => {
		let categoryToRemove: number | null
		if (catalogGroup === CatalogGroup.Printed) {
			categoryToRemove = selectedCurrentPrintedCat?.id ?? null
		} else {
			categoryToRemove = selectedCurrentWebCat?.id ?? null
		}
		if (categoryToRemove === null) {
			dispatch(informerActions.inform("Выберите категорию для удаления"))
			return
		}
		dispatch(
			CategoryTabComponentThunks.removeProductGroupFromCats({
				productGroupIds: Array.of(group.id),
				categoryId: categoryToRemove,
				catalogGroup: catalogGroup,
				catalogId: catalog.id,
			})
		)
	}

	const changeCategory = (catalogGroup: CatalogGroup) => {
		let currentCatId: number | null
		const newCategoryId = getCategoryIdByPath(catalogGroup)
		if (newCategoryId === null) return
		if (isCategoryAdded(newCategoryId, catalogGroup)) {
			dispatch(informerActions.inform("Категория уже добавлена"))
			return
		}

		if (catalogGroup === CatalogGroup.Printed)
			currentCatId = selectedCurrentPrintedCat?.id ?? null
		else currentCatId = selectedCurrentWebCat?.id ?? null

		if (currentCatId === null) {
			dispatch(
				informerActions.inform("Выберите категорию для обновления")
			)
			return
		}
		dispatch(
			CategoryTabComponentThunks.changeProductGroupCategory({
				productGroupId: group.id,
				categoryId: currentCatId,
				catalogGroup: catalogGroup,
				catalogId: catalog.id,
				newCategoryId: newCategoryId,
			})
		)
	}

	const setCategoryAsMain = (catalogGroup: CatalogGroup) => {
		const currentCatId = selectedCurrentWebCat?.id ?? null
		if (currentCatId === null) {
			dispatch(
				informerActions.inform("Выберите категорию для обновления")
			)
			return
		}

		dispatch(
			CategoryTabComponentThunks.setCategoryAsMain({
				categoryId: currentCatId,
				productGroupId: group.id,
				catalogGroup
			})
		)
	}

	const selectCatalogGroup = (id: number) => {
		dispatch(
			actions.setSelectedCatalogGroup(id)
		)
	}

	return (
		<>
			{shouldShowPrintCats ? (
				<>
					<div className="u-droppod-section-header header-with-toolbar">
						Категории в каталоге
						<div className="u-toolbar">
							<div className="toolbar-row">
								{canControlPrintedCats ? (
									<>
										<button
											type="button"
											className="u-button square primary"
											disabled={
												currentPrintedCats.length > 0
											}
											onClick={() =>
												addCategory(
													CatalogGroup.Printed
												)
											}
										>
											<SvgIcon name="circle-plus" />
										</button>
										<button
											type="button"
											className="u-button square primary"
											onClick={() =>
												changeCategory(
													CatalogGroup.Printed
												)
											}
										>
											<SvgIcon name="pencil-create" />
										</button>
										<button
											type="button"
											className="u-button square primary"
											onClick={() =>
												removeCategory(
													CatalogGroup.Printed
												)
											}
										>
											<SvgIcon name="circle-x" />
										</button>
									</>
								) : null}
								<CatalogSelector
									catalogGroup={CatalogGroup.Printed}
									height={31}
								/>
								{canControlPrintedCats ? (
									<>
										<AdCategorySelectRow
											shouldReset={shouldResetPrinted}
											onReset={() =>
												onCategoryRowReset(
													CatalogGroup.Printed
												)
											}
											categories={printCategories}
											height={31}
											onChange={(cat, level) => {
												setCategoryPath(
													cat,
													CatalogGroup.Printed,
													level
												)
											}}
										/>
									</>
								) : null}
							</div>
						</div>
					</div>

					<AdCategoryDynamicTable
						isDisabled={!canControlPrintedCats}
						catalogGroup={CatalogGroup.Printed}
						onRowClicked={(e) =>
							setSelectedCategory(e, CatalogGroup.Printed)
						}
						rows={currentPrintedCats}
					/>
				</>
			) : null}

			<div className="u-droppod-section-header header-with-toolbar">
				<div style={{ display: "flex", alignItems: "center" }}>
					<div>Категории на сайт</div>
					<div className="u-toolbar">
						<SimpleSelect toOption={toOption}
							options={groups}
							className={"selector"}
							onChange={newValue => selectCatalogGroup(newValue)}
							value={selectedCatalogGroup}
						/>
					</div>
				</div>
				{canControlWebCats ? (
					<div className="u-toolbar">
						<div className="toolbar-row">
							<button
								type="button"
								className="u-button square primary"
								onClick={() => addCategory(selectedCatalogGroup.id)}
							>
								<SvgIcon name="circle-plus" />
							</button>
							<button
								type="button"
								className="u-button square primary"
								onClick={() => changeCategory(selectedCatalogGroup.id)}
							>
								<SvgIcon name="pencil-create" />
							</button>
							<button
								type="button"
								className="u-button square primary"
								onClick={() => removeCategory(selectedCatalogGroup.id)}
							>
								<SvgIcon name="circle-x" />
							</button>
							<button
								type="button"
								className="u-button large primary make-main"
								onClick={() => setCategoryAsMain(selectedCatalogGroup.id)}
							>
								<SvgIcon name="star" className="link-icon" />
								Сделать главной
							</button>
							<button
								type="button"
								className="u-button square primary make-main__small"
								onClick={() => setCategoryAsMain(selectedCatalogGroup.id)}
							>
								<SvgIcon name="star" />
							</button>
							<AdCategorySelectRow
								shouldReset={shouldResetWeb}
								onReset={() =>
									onCategoryRowReset(selectedCatalogGroup.id)
								}
								categories={webCategories}
								height={31}
								onChange={(cat, level) => {
									setCategoryPath(
										cat,
										selectedCatalogGroup.id,
										level
									)
								}}
							/>
						</div>
					</div>
				) : null}
			</div>

			<AdCategoryDynamicTable
				isDisabled={!canControlWebCats}
				catalogGroup={selectedCatalogGroup.id}
				onRowClicked={(e) => {
console.log(e, selectedCatalogGroup.id);

					setSelectedCategory(e, selectedCatalogGroup.id)
				}}
				rows={currentWebCats}
			/>
		</>
	)
}
