import {
	createAutogenApiThunk,
	createAutogenNoArgsApiThunk,
} from "../../../createApiThunkV2"
import Api from "../../../../api"

export class ProductPageThunks {
	static getProduct = createAutogenApiThunk({
		typePrefix: "product-page/get-product",
		apiCall: Api.products.getProduct,
		errorMessage: "Не удалось получить продукт",
	})

	static getProductAttributes = createAutogenApiThunk({
		typePrefix: "product-page/get-product-attributes",
		apiCall: Api.products.getProductAttributes,
		errorMessage: "Не удалось получить атрибуты продукта",
	})

	static updateProduct = createAutogenApiThunk({
		typePrefix: "product-page/update-product",
		apiCall: Api.products.updateProduct,
		showSuccess: true,
		successMessage: "Продукт успешно обновлен",
	})

	static deleteProduct = createAutogenApiThunk({
		typePrefix: "product-page/delete-product",
		apiCall: Api.products.deleteProduct,
	})

	static saveAttributeValue = createAutogenApiThunk({
		typePrefix: "product-page/save-attribute-value",
		apiCall: Api.products.changeAttributesValues,
		showSuccess: true,
		successMessage: "Значение атрибута успешно обновлено",
	})

	static getImages = createAutogenApiThunk({
		typePrefix: "product-page/get-images",
		apiCall: Api.images.getProductImages,
	})

	static downloadImages = createAutogenApiThunk({
		typePrefix: "product-page/get-images-as-zip",
		apiCall: Api.images.getProductsImagesAsZip,
	})

	static getImageTypes = createAutogenNoArgsApiThunk({
		typePrefix: "product-page/get-image-types",
		apiCall: Api.images.getImageTypes,
	})

	static uploadImage = createAutogenApiThunk({
		typePrefix: "product-page/upload-image",
		apiCall: Api.images.uploadProductImage,
		notShowError: true,
	})

	static uploadVideo = createAutogenApiThunk({
		typePrefix: "product-page/upload-video",
		apiCall: Api.images.addProductVideo,
	})

	static updateImageType = createAutogenApiThunk({
		typePrefix: "product-page/change-image-type",
		apiCall: Api.images.updateProductImageType,
	})

	static removeImage = createAutogenApiThunk({
		typePrefix: "product-page/delete-image",
		apiCall: Api.images.removeProductImage,
	})

	static getScopes = createAutogenApiThunk({
		typePrefix: "product-page/get-scopes",
		apiCall: Api.scopes.getScopes,
	})

	static getProductScopes = createAutogenApiThunk({
		typePrefix: "product-page/get-product-scopes",
		apiCall: Api.scopes.getProductScopes,
	})

	static addProductToScope = createAutogenApiThunk({
		typePrefix: "product-page/add-product-to-scope",
		apiCall: Api.scopes.addProductToScope,
	})

	static removeProductFromScope = createAutogenApiThunk({
		typePrefix: "product-page/remove-product-from-scope",
		apiCall: Api.scopes.removeProductFromScope,
	})

	static changeProductScopes = createAutogenApiThunk({
		typePrefix: "product-page/change-product-scopes",
		apiCall: Api.scopes.changeProductScope,
	})

	static getWebCategories = createAutogenApiThunk({
		typePrefix: "product-page/get-web-categories",
		apiCall: Api.category.getCategoriesByCatalogGroups,
	})

	static getProductWebCategories = createAutogenApiThunk({
		typePrefix: "product-page/get-product-web-categories",
		apiCall: Api.category.getProductCats,
	})

	static addProductToWebCategory = createAutogenApiThunk({
		typePrefix: "product-page/add-product-to-web-category",
		apiCall: Api.category.addProductToCat,
	})

	static removeProductFromWebCategory = createAutogenApiThunk({
		typePrefix: "product-page/remove-product-from-web-category",
		apiCall: Api.category.removeProductFromCat,
	})

	static changeProductWebCategory = createAutogenApiThunk({
		typePrefix: "product-page/change-product-web-category",
		apiCall: Api.category.changeProductCategory,
	})

	static setCategoryAsMain = createAutogenApiThunk({
		typePrefix: "product-page/set-category-as-main",
		apiCall: Api.category.setProductCategoryAsMain,
	})

	static getRelatedProducts = createAutogenApiThunk({
		typePrefix: "product-page/get-related-products",
		apiCall: Api.relatedProducts.getRelatedProducts,
	})

	static getPossibleRelatedProducts = createAutogenApiThunk({
		typePrefix: "product-page/get-possible-related-products",
		apiCall: Api.relatedProducts.getPossibleRelatedProducts,
	})

	static addRelatedProduct = createAutogenApiThunk({
		typePrefix: "product-page/add-related-product",
		apiCall: Api.relatedProducts.addRelatedProduct,
	})

	static removeRelatedProduct = createAutogenApiThunk({
		typePrefix: "product-page/remove-related-product",
		apiCall: Api.relatedProducts.removeRelatedProduct,
	})

	static replaceRelatedProduct = createAutogenApiThunk({
		typePrefix: "product-page/replace-related-product",
		apiCall: Api.relatedProducts.replaceRelatedProduct,
	})

	static swapRelatedProductSort = createAutogenApiThunk({
		typePrefix: "product-page/swap-related-product-sort",
		apiCall: Api.relatedProducts.swapRelatedProductSort,
	})

	static getSimilarProducts = createAutogenApiThunk({
		typePrefix: "product-page/get-similar-products",
		apiCall: Api.similarProducts.getSimilarProducts,
	})

	static getPossibleSimilarProducts = createAutogenApiThunk({
		typePrefix: "product-page/get-possible-similar-products",
		apiCall: Api.similarProducts.getPossibleSimilarProducts,
	})

	static getRecommendedSimilarProducts = createAutogenApiThunk({
		typePrefix: "product-page/get-recommended-similar-products",
		apiCall: Api.similarProducts.getRecommendedSimilarProducts,
	})

	static addSimilarProducts = createAutogenApiThunk({
		typePrefix: "product-page/add-similar-product",
		apiCall: Api.similarProducts.addSimilarProducts,
	})

	static removeSimilarProduct = createAutogenApiThunk({
		typePrefix: "product-page/remove-similar-product",
		apiCall: Api.similarProducts.removeSimilarProduct,
	})

	static replaceSimilarProduct = createAutogenApiThunk({
		typePrefix: "product-page/replace-related-product",
		apiCall: Api.similarProducts.replaceSimilarProduct,
	})

	static swapSimilarProductSort = createAutogenApiThunk({
		typePrefix: "product-page/swap-related-product-sort",
		apiCall: Api.similarProducts.swapSimilarProductSort,
	})

	static getProductCatalogs = createAutogenApiThunk({
		typePrefix: "product-page/get-product-catalogs",
		apiCall: Api.catalogs.getProductCatalogs,
	})

	static isOnSite = createAutogenApiThunk({
		typePrefix: "product-page/is-on-site",
		apiCall: Api.productSites.isOnSite,
	})

	static removeFromSite = createAutogenApiThunk({
		typePrefix: "product-page/remove-from-site",
		apiCall: Api.productSites.removeFromSite,
		showSuccess: true,
		successMessage: "Продукт успешно удален с сайта",
		showLoader: true,
	})

	static addProductToSite = createAutogenApiThunk({
		typePrefix: "product-page/add-to-site",
		apiCall: Api.productSites.addToSite,
		showSuccess: true,
		successMessage: "Продукт успешно добавлен на сайт",
		showLoader: true,
	})

	static changeShowStatus = createAutogenApiThunk({
		typePrefix: "product-page/change-show-status",
		apiCall: Api.catalogs.changeProductShowStatus,
	})

	static getFileTypes = createAutogenNoArgsApiThunk({
		typePrefix: "product-page/get-file-types",
		apiCall: Api.fileTypes.getFileTypes,
	})

	static getFiles = createAutogenApiThunk({
		typePrefix: "product-page/get-files",
		apiCall: Api.productFiles.getProductFiles,
	})

	static downloadFile = createAutogenApiThunk({
		typePrefix: "product-page/download-file",
		apiCall: Api.productFiles.getFile,
	})

	static downloadFiles = createAutogenApiThunk({
		typePrefix: "product-page/download-files",
		apiCall: Api.productFiles.getFiles,
	})

	static uploadFile = createAutogenApiThunk({
		typePrefix: "product-page/add-file",
		apiCall: Api.productFiles.addProductFile,
		notShowError: true,
	})

	static deleteFile = createAutogenApiThunk({
		typePrefix: "product-page/delete-file",
		apiCall: Api.productFiles.deleteProductFile,
	})

	static changeFileType = createAutogenApiThunk({
		typePrefix: "product-page/change-file-type",
		apiCall: Api.productFiles.changeProductFileType,
	})

	//#region Product Characteristics

	static getProductCharacteristics = createAutogenApiThunk({
		typePrefix: "product-page/get-product-characteristics",
		apiCall: Api.productCharacteristics.getProductCharacteristics,
	})

	static addProductCharacteristic = createAutogenApiThunk({
		typePrefix: "product-page/add-product-characteristic",
		apiCall: Api.productCharacteristics.addProductCharacteristic,
	})

	static deleteProductCharacteristic = createAutogenApiThunk({
		typePrefix: "product-page/delete-product-characteristic",
		apiCall: Api.productCharacteristics.deleteProductCharacteristic,
	})

	static changeProductCharacteristic = createAutogenApiThunk({
		typePrefix: "product-page/change-product-characteristic",
		apiCall: Api.productCharacteristics.changeProductCharacteristic,
	})

	//#endregion
}
