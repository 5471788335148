import {
	IProductGroupCatalog,
	IRecommendation,
} from "../../../../../domain/types"
import { ISelectable } from "../../../../types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AdditionalTabThunks } from "./thunks"
import _ from "lodash"
import { IProductIdentity } from "../../../../../domain/models/products/ProductIdentity"

export type GroupRecommendation = IRecommendation & ISelectable
export type GroupCatalog = IProductGroupCatalog & ISelectable

const areEqual = (f: GroupCatalog, s: GroupCatalog) => {
	return (
		f.catalogId === s.catalogId &&
		f.catalogCategoryId === s.catalogCategoryId &&
		f.showStatus === s.showStatus &&
		f.sort === s.sort &&
		f.catalogName === s.catalogName &&
		f.catalogParentCategoryId === s.catalogParentCategoryId &&
		f.webCategoryId === s.webCategoryId
	)
}

export type AdditionalInfoState = {
	allRecommendations: IProductIdentity[]
	selectedRecommendation: IProductIdentity | null
	isRecommendationsLoading: boolean

	groupRecommendations: GroupRecommendation[]
	selectedGroupRecommendation: GroupRecommendation | null

	groupCatalogs: GroupCatalog[]
	isOnSite: boolean | null
	isAddingOrRemovingFromSite: boolean
	isChangingStatusOnSite: boolean
}

const ALL_RECOMMENDATIONS_STATE: IProductIdentity[] = [
	{ id: "-1", name: "loading", priceGroupId: 0 },
]

const INITIAL_STATE: AdditionalInfoState = {
	allRecommendations: ALL_RECOMMENDATIONS_STATE,
	selectedRecommendation: ALL_RECOMMENDATIONS_STATE[0],
	isRecommendationsLoading: false,

	groupRecommendations: [],
	selectedGroupRecommendation: null,

	groupCatalogs: [],

	isOnSite: null,
	isAddingOrRemovingFromSite: false,
	isChangingStatusOnSite: false,
}

const slice = createSlice({
	name: "new-product-add-tab",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedRec(
			state: AdditionalInfoState,
			action: PayloadAction<string>
		) {
			state.selectedRecommendation =
				state.allRecommendations.find((x) => x.id === action.payload) ??
				null
		},
		setSelectedGroupRec(
			state: AdditionalInfoState,
			action: PayloadAction<string>
		) {
			state.groupRecommendations.forEach((x) => {
				if (x.productId === action.payload) {
					x.selected = true
					state.selectedGroupRecommendation = x
				} else x.selected = false
			})
			if (state.selectedGroupRecommendation?.productId !== action.payload)
				state.selectedGroupRecommendation = null
		},
		setSelectedCatalog(
			state: AdditionalInfoState,
			action: PayloadAction<GroupCatalog>
		) {
			const catalog = state.groupCatalogs.find((x) =>
				areEqual(x, action.payload)
			)
			if (catalog !== undefined) catalog.selected = !catalog.selected
		},
		clearStateOnUnmount() {
			return INITIAL_STATE
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			AdditionalTabThunks.getAllRecommendation.pending,
			(state) => {
				state.isRecommendationsLoading = true
			}
		)
		builder.addCase(
			AdditionalTabThunks.getAllRecommendation.fulfilled,
			(state, action) => {
				state.allRecommendations = _.orderBy(
					action.payload,
					(x) => x.name
				)
				state.selectedRecommendation = null
				state.isRecommendationsLoading = false
			}
		)
		builder.addCase(
			AdditionalTabThunks.getAllRecommendation.rejected,
			(state) => {
				state.isRecommendationsLoading = false
			}
		)

		builder.addCase(
			AdditionalTabThunks.getGroupRecommendation.fulfilled,
			(state, action) => {
				state.groupRecommendations = action.payload.map((x) => {
					return {
						selected: false,
						productId: x.productId,
						sort: x.sort,
						name: x.name,
					}
				})
				state.selectedGroupRecommendation = null
			}
		)
		builder.addCase(
			AdditionalTabThunks.addRecommendations.pending,
			(state, action) => {
				for (const productId of action.meta.arg.productsIds) {
					const index = state.allRecommendations.findIndex(
						(x) => x.id === productId
					)
					const maxId =
						_.maxBy(state.groupRecommendations, (x) => x.sort)
							?.sort ?? 0
					state.groupRecommendations.push({
						productId: state.allRecommendations[index].id,
						name: state.allRecommendations[index].name,
						sort: maxId + 1,
						selected: false,
					})
					state.selectedRecommendation = null
					state.allRecommendations.splice(index, 1)
				}
			}
		)
		builder.addCase(
			AdditionalTabThunks.removeRecommendation.pending,
			(state, action) => {
				const index = state.groupRecommendations.findIndex(
					(x) => x.productId === action.meta.arg.productId
				)
				const sort = state.groupRecommendations[index].sort!
				state.groupRecommendations.forEach((x) => {
					if (x.sort! > sort) x.sort! -= 1
				})
				if (
					state.allRecommendations.findIndex(
						(x) =>
							x.id === state.groupRecommendations[index].productId
					) === -1
				)
					state.allRecommendations.push({
						id: state.groupRecommendations[index].productId,
						name: state.groupRecommendations[index].name,
						priceGroupId:
							state.allRecommendations[0]?.priceGroupId ?? 0,
					})
				state.groupRecommendations.splice(index, 1)
				state.selectedGroupRecommendation = null
			}
		)

		builder.addCase(
			AdditionalTabThunks.swapRecommendationSort.pending,
			(state, action) => {
				const first = state.groupRecommendations.find(
					(x) => x.productId === action.meta.arg.firstProductId
				)!
				const second = state.groupRecommendations.find(
					(x) => x.productId === action.meta.arg.secondProductId
				)!
				const temp = first.sort
				first.sort = second.sort
				second.sort = temp
				state.selectedGroupRecommendation = first
			}
		)

		builder.addCase(
			AdditionalTabThunks.changeRecommendation.pending,
			(state, action) => {
				const groupRec = state.groupRecommendations.find(
					(x) => x.productId === action.meta.arg.currentProductId
				)
				const targetRec = state.allRecommendations.find(
					(x) => x.id === action.meta.arg.targetProductId
				)
				if (groupRec && targetRec) {
					state.groupRecommendations.splice(
						state.groupRecommendations.indexOf(groupRec),
						1
					)
					state.groupRecommendations.push({
						productId: targetRec.id,
						name: targetRec.name,
						sort: groupRec.sort,
						selected: false,
					})
					state.allRecommendations.splice(
						state.allRecommendations.indexOf(targetRec),
						1
					)
					state.allRecommendations.push({
						id: groupRec.productId,
						name: groupRec.name,
						priceGroupId:
							state.allRecommendations[0]?.priceGroupId ?? 0,
					})
				}
				state.selectedRecommendation = null
				state.selectedGroupRecommendation = null
			}
		)
		builder.addCase(
			AdditionalTabThunks.getProductGroupCatalogs.fulfilled,
			(state, action) => {
				state.groupCatalogs = action.payload.map((x) => {
					return {
						catalogName: x.catalogName,
						sort: x.sort,
						showStatus: x.showStatus,
						catalogId: x.catalogId,
						productGroupId: x.productGroupId,
						catalogCategoryId: x.catalogCategoryId,
						catalogParentCategoryId: x.catalogParentCategoryId,
						webCategoryId: x.webCategoryId,
						selected: false,
					}
				})
			}
		)

		builder.addCase(
			AdditionalTabThunks.isOnSite.fulfilled,
			(state, action) => {
				state.isOnSite = action.payload
			}
		)

		builder.addCase(AdditionalTabThunks.addGroupToSite.pending, (state) => {
			state.isAddingOrRemovingFromSite = true
		})
		builder.addCase(
			AdditionalTabThunks.addGroupToSite.fulfilled,
			(state) => {
				state.isOnSite = !state.isOnSite
				state.isAddingOrRemovingFromSite = false
			}
		)
		builder.addCase(
			AdditionalTabThunks.addGroupToSite.rejected,
			(state) => {
				state.isAddingOrRemovingFromSite = false
			}
		)

		builder.addCase(
			AdditionalTabThunks.removeGroupFromSite.pending,
			(state) => {
				state.isAddingOrRemovingFromSite = true
			}
		)
		builder.addCase(
			AdditionalTabThunks.removeGroupFromSite.fulfilled,
			(state) => {
				state.isAddingOrRemovingFromSite = false
				state.isOnSite = !state.isOnSite
			}
		)
		builder.addCase(
			AdditionalTabThunks.removeGroupFromSite.rejected,
			(state, action) => {
				state.isAddingOrRemovingFromSite = false
			}
		)

		builder.addCase(
			AdditionalTabThunks.changeShowStatus.pending,
			(state) => {
				state.isChangingStatusOnSite = true
			}
		)
		builder.addCase(
			AdditionalTabThunks.changeShowStatus.fulfilled,
			(state, action) => {
				state.isChangingStatusOnSite = false
				action.meta.arg.forEach((x) => {
					const catalog = state.groupCatalogs.find(
						(gc) =>
							gc.catalogId === x.catalogId &&
							gc.catalogCategoryId === x.catalogCategoryId &&
							gc.webCategoryId === x.webCategoryId
					)
					if (catalog !== undefined) {
						catalog.showStatus = x.showStatus
						catalog.selected = false
					}
				})
			}
		)
		builder.addCase(
			AdditionalTabThunks.changeShowStatus.rejected,
			(state) => {
				state.isChangingStatusOnSite = false
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
