import React from "react"
import {TranslateForm} from "./TranslateForm"
import {TranslateSettings} from "./TranslateSettings"
import "./Translate.scss"

export default function Translate() {
    return (
        <>
            <TranslateForm/>
            <TranslateSettings/>
        </>
    )
}