import React from "react"
import CatalogSelector from "../../../library/smartComponents/catalogSelector/CatalogSelector"
import "./selectorBlock.scss"
import PriceGroupSelector from "../../../library/smartComponents/priceGroupSelector/PriceGroupSelector"
import SellmarkSelector from "../../../library/smartComponents/sellmarkSelector/SellmarkSelector"
import CatalogGroupSelector from "../../../library/smartComponents/catalogGroupsSelector/CatalogGroupSelector"
import {useDispatch, useSelector} from "react-redux"
import {useEffect} from "react"
import {actions} from "../../../redux/reducers/catalogs"
import {CatalogGroup} from "../../../domain/types"
import {LocalStorageProvider} from "../../../api/LocalStorageProvider"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"


export default function SelectorBlock() {
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const catalog = useSelector(CatalogSelectors.getSelected)
    const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)

    const dispatch = useDispatch()

    useEffect(() => {
        if (wasCatalogsInit) {
            const catalogId = LocalStorageProvider.getCategoriesPrintCatalog()
            dispatch(actions.setSelected(catalogId))
        }
    }, [wasCatalogsInit])

    useEffect(() => {
        if (catalog.isPrinted)
            LocalStorageProvider.setCategoriesPrintCatalog(catalog.id)
    }, [catalog.id])

    return <>
        <>
            {
                catalogGroup.id === CatalogGroup.Printed ?
                    <>
                        <CatalogSelector catalogGroup={catalogGroup.id}/>
                    </>
                    : <></>
            }
        </>
        <PriceGroupSelector/>
        <SellmarkSelector/>
        <CatalogGroupSelector/>
    </>
}