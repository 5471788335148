import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { useDispatch, useSelector } from "react-redux"
import { ProductProductCatalogsSelectors } from "../../../redux/reducers/pages/productPage/productCatalogs/ProductProductCatalogsSelectors"
import AdEntityCatalogsBlock, {
	IAdEntityCatalog,
} from "../../../library/catalogs/AdEntityCatalogs"
import React, { useEffect } from "react"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { actions } from "../../../redux/reducers/pages/productPage/productCatalogs"
import { actions as informerActions } from "../../../redux/reducers/informer"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export default function ProductCatalogBlock() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	if (!productId) return <></>

	const productCatalogs = useSelector(
		ProductProductCatalogsSelectors.getProductCatalogs
	)
	const canChangeDisplay = useSelector(
		ProductPageAuthSelectors.getCanChangeDisplay
	)
	const canUploadToSite = useSelector(
		ProductPageAuthSelectors.getCanUploadToSite
	)

	const isOnSite = useSelector(ProductProductCatalogsSelectors.getIsOnSite)
	const isChangingShowStatus = useSelector(
		ProductProductCatalogsSelectors.getIsChangingShowStatus
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(ProductPageThunks.getProductCatalogs({ productId }))
		dispatch(ProductPageThunks.isOnSite({ productId }))
	}, [])

	const setSelected = (entityCatalog: IAdEntityCatalog) => {
		dispatch(
			actions.setProductCatalogsSelected({
				catalogId: entityCatalog.catalogId,
				catalogWebCategoryId: entityCatalog.webCategoryId,
				catalogCategoryId: entityCatalog.catalogCategoryId,
				catalogParentCategoryId: 0,
				showStatus: entityCatalog.showStatus,
			})
		)
	}

	const deleteFromSite = () => {
		dispatch(ProductPageThunks.removeFromSite({ productId }))
	}

	const addToSite = () => {
		dispatch(ProductPageThunks.addProductToSite({ productId }))
	}

	const changeShowStatus = () => {
		const selectedCatalogs = productCatalogs.filter(
			(catalog) => catalog.selected
		)
		if (selectedCatalogs.length === 0) {
			dispatch(informerActions.inform("Выберите каталог для изменения"))
			return
		}

		dispatch(
			ProductPageThunks.changeShowStatus(
				selectedCatalogs.map((x) => {
					return {
						catalogId: x.catalogId,
						showStatus: !x.showStatus,
						webCategoryId: x.catalogWebCategoryId ?? null,
						catalogCategoryId: x.catalogCategoryId ?? null,
						catalogParentCategoryId:
							x.catalogParentCategoryId ?? null,
						productId: productId,
					}
				})
			)
		)
	}

	return (
		<AdEntityCatalogsBlock
			canAccessToCatalog={() => true}
			entityCatalogs={productCatalogs.map((productCatalog) => ({
				...productCatalog,
				webCategoryId: productCatalog.catalogWebCategoryId,
			}))}
			isChangingStatusOnSite={isChangingShowStatus}
			setSelected={setSelected}
			setShowStatus={changeShowStatus}
			canChangeDisplay={canChangeDisplay}
			additionalControls={
				<div>
					{canUploadToSite ? (
						<>
							{isOnSite === null ? (
								<></>
							) : (
								<>
									{isOnSite ? (
										<button
											type="button"
											className="u-button large primary"
											onClick={deleteFromSite}
										>
											Удалить с сайта
										</button>
									) : (
										<button
											type="button"
											className="u-button large primary"
											onClick={addToSite}
										>
											Добавить на сайт
										</button>
									)}
								</>
							)}
						</>
					) : (
						<></>
					)}
				</div>
			}
		/>
	)
}
