import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {useEffect} from "react"
import SimpleSelect from "../../../app/common/basic/selectors/SimpleSelect"
import {actions} from "../../../redux/reducers/webSites"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import {WebsiteThunks} from "../../../redux/reducers/webSites/thunks"
import {WebsiteSelectors} from "../../../redux/reducers/webSites/websiteSelectors"
import {IWebsite, WebsiteSchema} from "../../../domain/types"
import {AppState} from "../../../redux/reducers"

interface IWebsiteSelectorProps {
    height?: number
    isDisabled?: boolean
    className?: string
    schema?: WebsiteSchema
}

export default function WebsiteSelector(props: IWebsiteSelectorProps) {
    const website = useSelector(WebsiteSelectors.getSelected)
    const websites = useSelector<AppState, IWebsite[]>(x => WebsiteSelectors.getWebsites(x, props.schema))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(WebsiteThunks.getWebsites())
    }, [])

    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={ToOptionProvider.websiteToOption}
        isDisabled={props.isDisabled}
        options={websites}
        className={"selector " + props.className}
        onChange={newValue => changeSelected(newValue)}
        value={website}
        height={props.height}
    />
}
