import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {IPictogram} from "../../../domain/types"
import _ from "lodash"
import {PictogramsThunks} from "./thunk"

export type PictogramState = {
    pictograms: IPictogram[],
    selected: IPictogram | null,
    filter: number[]
};

const defaultPictogram: IPictogram = {id: -1, name: "Загрузка", imageUrl: "", sort: 0, isSet: false}

const INITIAL_STATE: PictogramState = {
    pictograms: [defaultPictogram],
    selected: defaultPictogram,
    filter: []
}

const slice = createSlice({
    name: "pictograms",
    initialState: INITIAL_STATE,
    reducers: {
        setSelected(state: PictogramState, action: PayloadAction<number | null>) {
            if(action.payload) {
                state.selected = state.pictograms.find(x => x.id === action.payload) ?? state.selected
            }
            else {
                state.selected = null
            }
        },
        setFilter(state: PictogramState, action: PayloadAction<number[]>) {
            state.filter = action.payload
            if(state.selected && state.filter.includes(state.selected.id))
                state.selected = null
        },
    },
    extraReducers: builder => {
        builder.addCase(PictogramsThunks.getPictograms.fulfilled, (state, {payload}) => {
            if(payload.length === 0) {
                state.pictograms.first().name = "Нет пиктограмм"
                state.selected = state.pictograms.first()
            }else {
                state.pictograms = _.orderBy(payload, x => x.name)
                if(state.selected) {
                    if (payload.some(x => x.id === state.selected!.id))
                        state.selected = payload.find(x => x.id === state.selected!.id) ?? null
                    else
                        state.selected = null
                }
            }
        })
        builder.addCase(PictogramsThunks.getPictograms.rejected, (state) => {
            state.pictograms = [{...defaultPictogram, name: "Ошибка"}]
            state.selected = state.pictograms[0]
        })
    }
})

const reducer = slice.reducer
const actions = slice.actions

export {reducer, actions}