import { AppState } from "../../../index"
import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"

export class ProductRelatedProductsSelectors {
	static getRelatedProducts = createSelector(
		[
			(state: AppState) =>
				state.pages.productPage.relatedProductsState.related,
		],
		(related) => _.orderBy(related, (x) => x.sort)
	)

	static getPossibleRelatedProducts = (state: AppState) =>
		state.pages.productPage.relatedProductsState.products
	/*createSelector(
		[
			ProductRelatedProductsSelectors.getRelatedProducts,
			(state: AppState) =>
				state.pages.productPage.relatedProductsState.products,
		],
		(related, products) => {
			const relatedIds = related.map((x) => x.relatedId)
			return products.filter((x) => !relatedIds.includes(x.id))
		}
	)*/

	static getSelectedProduct = (state: AppState) =>
		state.pages.productPage.relatedProductsState.selectedProduct
}
