import React, {useEffect} from "react"
import {IMountableProps} from "../../../../../redux/types"
import Characteristics from "./Characteristics"
import Graphics from "./Graphics"

export default function DescriptionTab(props: IMountableProps){

    useEffect(() => {
        props.onMount()
    }, [])

    return (
        <div className="row-or-column">
            <Characteristics/>
            <Graphics/>
        </div>
    ) 
}