import React, {useEffect, useState} from "react"
import "./TranslatorPage.scss"
import {useDispatch, useSelector} from "react-redux"
import {TranslatorPageThunks} from "../../redux/reducers/pages/translatorPage/thunks"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"
import {CatalogGroup, TranslateSource} from "../../domain/types"
import {LanguageSelectors} from "../../redux/reducers/languages/languageSelectors"
import {TranslatorPageSelectors} from "../../redux/reducers/pages/translatorPage/translatorPageSelectors"
import Protector from "../../library/smartComponents/authorization/Protector"
import RouteProvider from "../../utils/RouteProvider"
import {CatalogSelectors} from "../../redux/reducers/catalogs/catalogSelectors"
import {WebsiteSelectors} from "../../redux/reducers/webSites/websiteSelectors"
import {PhraseType} from "../../redux/reducers/pages/translatePage"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import {actions, ITrackedTranslateWithUrl} from "../../redux/reducers/pages/translatorPage"
import {useDebouncedCallback} from "use-debounce"
import DebounceProvider from "../../utils/DebounceProvider"
import ToOptionProvider from "../../utils/ToOptionProvider"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import {SvgIcon} from "../../library/icons/SvgIcon"
import {AdNotFoundRow} from "../../library/tables/AdNotFoundRow"
import AdDynamicTextarea from "../../library/inputs/AdDynamicTextarea"
import {actions as informerActions} from "../../redux/reducers/informer/index"

import {ITranslatedValue} from "../../domain/types"

const EXCEL_FILE_TYPE = 5

// Идентификатор исходного языка по умолчанию (русский).
const DEFAULT_SOURCE_LANGUAGE_ID = 11

export default function TranslatorPage() {
    const translates = useSelector(TranslatorPageSelectors.getTranslates)
    const canTranslate = useSelector(TranslatorPageSelectors.canTranslate)
    const canSaveTranslates = useSelector(TranslatorPageSelectors.canSaveTranslates)
    const catalog = useSelector(CatalogSelectors.getSelected)
    const website = useSelector(WebsiteSelectors.getSelected)
    const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)
    const wasWebsitesInit = useSelector(WebsiteSelectors.getWasInit)
    const search = useSelector(TranslatorPageSelectors.getSearch)
    const phraseType = useSelector(TranslatorPageSelectors.getSelectedPhraseType)
    const phraseTypes = useSelector(TranslatorPageSelectors.getPhraseTypes)
    const targetLanguage = useSelector(TranslatorPageSelectors.getTargetLanguage)
    const languages = useSelector(LanguageSelectors.getLanguages)
    const [wasInitialLoading, setWasInitialLoading] = useState(false)
    const shouldEndWorkWithPage = useSelector(TranslatorPageSelectors.shouldEndWorkWithPage)

    const dispatch = useDispatch()

    const setSearch = (search: string) => {
        dispatch(actions.setSearch(search))
    }

    const debounce = DebounceProvider.getDebounce()
    const debounceSearch = useDebouncedCallback((e) => setSearch(e), debounce)

    const setPhraseType = (phraseType: PhraseType) => dispatch(actions.setPhraseType(phraseType))
    const setTargetLanguage = (languageId: number) => {
        const language = languages.find(x => x.id === languageId)
        if (language)
            dispatch(actions.setLanguage(language))
    }

    useEffect(() => {
        if (shouldEndWorkWithPage)
        {
            window.setTimeout(function()
            {
                window.location.replace("/translate")
            }, 1500)
        }
    }, [shouldEndWorkWithPage])

    useEffect(() => {
        setTargetLanguage(languages.first()?.id ?? 0)
    }, [languages.length])

    const loadTranslates = () => {
        if (wasCatalogsInit && wasWebsitesInit) {
            dispatch(TranslatorPageThunks.getTranslatesWithUrl({
                targetLanguageId: targetLanguage.id,
                catalogId: catalog.id,
                websiteId: website.id,
                isEmpty: phraseType === PhraseType.NotTranslated,
                search: search,
            }))
            if(!wasInitialLoading)
                setWasInitialLoading(true)
        }
    }

    // Обработчик события нажатия на кнопку "Перевести".
    const onTranslateValuesButtonClicked = () =>
    {
        if(canTranslate)
        {
            const values: string[] = translates
                .map(t => t.russian)
                .filter(v => v != null && v != "")

            if(values.length === 0){
                dispatch(informerActions.inform("Нет не пустых значений для перевода"))
                return
            }

            dispatch(TranslatorPageThunks.translateValuesByTranslator({
                sourceLanguageId: DEFAULT_SOURCE_LANGUAGE_ID,
                targetLanguageId: targetLanguage.id,
                values: values
            }))
        }
    }

    // Обработчик события нажатия на кнопку "Сохранить переводы".
    const onSaveTranslatedValuesButtonClicked = () =>
    {
        if(canSaveTranslates)
        {
            const values: ITranslatedValue[] = translates
                .filter(x => x.translate != x.newTranslate)
                .map(function(t)
                {
                    return {
                        translateId: t.translateId,
                        sourceId: t.sourceId,
                        sourceTable: t.source,
                        translate: t.newTranslate ?? ""
                    }
                })
                .filter(v => v.translate != null && v.translate != "")
            
            dispatch(TranslatorPageThunks.saveTranslatedValues({
                targetLanguageId: targetLanguage.id,
                translator: "deepl",
                translates: values
            }))
        }
    }

    // Обработчик события изменения переведенного значения.
    const onChangeTranslatedValue = (translate:ITrackedTranslateWithUrl, newValue: string) => {
        dispatch(actions.setTranslatedValue({translate, newValue}))
    }

    const downloadFile = async (fileType: number) => {
        if (wasCatalogsInit && wasWebsitesInit) {
            dispatch(TranslatorPageThunks.downloadTranslates({
                catalogId: catalog.id,
                targetLanguageId: targetLanguage.id,
                websiteId: website.id,
                isEmpty: false,
                fileType: fileType,
                search: search,
            }))
        }
    }

    return <Protector availableRoles={RouteProvider.translatorForm.roles!}>
        <div className="translator-page u-droppod">
            <div className="u-droppod-header">
                <div className="header-text">
                    Форма переводов
                </div>
            </div>
            <div className="u-toolbar">
                <div className="toolbar-row">
                    <AdSearchInput onChange={e => debounceSearch(e)} className={"flex-none"}/>
                    <CatalogSelector catalogGroup={CatalogGroup.All} className={"flex-1"}/>
                    <WebsiteSelector className={"flex-1"}/>
                    <SimpleSelect value={phraseType}
                        options={phraseTypes}
                        onChange={setPhraseType}
                        toOption={ToOptionProvider.phraseTypeToOption}
                        className={"flex-1"}/>
                    <SimpleSelect value={targetLanguage}
                        options={languages}
                        onChange={setTargetLanguage}
                        toOption={ToOptionProvider.languageToOption}
                        className={"flex-1"}/>
                    <button className="u-button square accent"
                        onClick={() => loadTranslates()}
                    >
                        <SvgIcon name={"refresh-cw"} />
                    </button>
                    <button className="u-button square accent"
                        onClick={() => onTranslateValuesButtonClicked()}
                        disabled={!canTranslate}
                        title="Перевести">
                        <SvgIcon name={"translate"} />
                    </button>
                    <button className="u-button square accent"
                        onClick={() => onSaveTranslatedValuesButtonClicked()}
                        disabled={!canSaveTranslates}
                        title="Сохранить переводы">
                        <SvgIcon name={"floppy"} />
                    </button>
                    <button className="u-button large accent"
                        onClick={() => downloadFile(EXCEL_FILE_TYPE)}>
                        <SvgIcon name={"table"} className={"link-icon"} />
                        Выгрузить
                    </button>
                </div>
            </div>
            <div className="translator-page__table u-table__scroll-wrapper">
                <table className="u-table">
                    <thead className="u-thead">
                        <tr>
                            <th>Код</th>
                            <th style={{width: "25%"}}>Русский</th>
                            <th style={{width: "25%"}}>{targetLanguage.name ?? "Зарубежный"}</th>
                            <th>Источник</th>
                            <th style={{minWidth: 200}}>Количество символов</th>
                            <th style={{width: "25%"}}>Ссылка</th>
                        </tr>
                    </thead>
                    <tbody className="u-tbody">
                        {
                            translates.notEmpty()
                                ? translates.map(x => {
                                    return <tr key={x.translateId}>
                                        <td>{x.translateId}</td>
                                        <td>{x.russian}</td>
                                        <td>
                                            <AdDynamicTextarea
                                                rows={5}
                                                value={x.newTranslate}
                                                onChange={(newValue) => onChangeTranslatedValue(x, newValue)} />
                                        </td>
                                        <td>{TranslateSource[x.source]}</td>
                                        <td>{x.symbolCount}</td>
                                        <td><a href={`https://${x.url}`}>{x.url}</a></td>
                                    </tr>
                                })
                                : <AdNotFoundRow
                                    colSpan={7}
                                    text={!wasInitialLoading ? "Выберите параметры и нажмите 🔄" : null}
                                    shouldShowIcon={wasInitialLoading}
                                />
                        }
                        <tr></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Protector>
}