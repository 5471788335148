import React from "react" 
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/catalogs"
import {useEffect, useState} from "react"
import {CatalogGroup} from "../../../domain/types"
import CatalogSelector from "../../../library/smartComponents/catalogSelector/CatalogSelector"
import CatalogGroupSelector from "../../../library/smartComponents/catalogGroupsSelector/CatalogGroupSelector"
import {LocalStorageProvider} from "../../../api/LocalStorageProvider"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"

export default function TreeSelectorBlock() {
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const catalogs = useSelector(CatalogSelectors.getByCatalogGroup(catalogGroup.id))
    const catalog = useSelector(CatalogSelectors.getSelected)
    const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)

    const dispatch = useDispatch()
    const [firstUse, setFirstUse] = useState(true)

    useEffect(() => {
        const selected = catalogs.first()
        if(selected)
            dispatch(actions.setSelected(selected.id))
    },[catalogGroup.id])

    useEffect(() => {
        if(catalogGroup.id === 0)
            return
        if(!wasCatalogsInit)
            return
        const catalogId = LocalStorageProvider.getTreePrintCatalog()
        dispatch(actions.setSelected(catalogId))
    }, [catalogGroup.id])

    useEffect(() => {
        if(catalog.id === 0 || [CatalogGroup.Web, CatalogGroup.Web_Latvia].includes(catalogGroup.id))
            return
        if(firstUse) {
            setFirstUse(false)
            const catalogId = LocalStorageProvider.getTreePrintCatalog()
            dispatch(actions.setSelected(catalogId))
        }
        else {
            if(catalog.isPrinted)
                LocalStorageProvider.setTreePrintCatalog(catalog.id)
        }
    }, [catalog.id])

    return (
        <>
            {
                catalogGroup.id === CatalogGroup.Printed
                    ?
                    <CatalogSelector catalogGroup={catalogGroup.id}/>
                    :
                    <></>
            }
            <CatalogGroupSelector/>
        </>
    )
}