import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit"
import { IRejectQueryThunk } from "./types"
import { actions as optimisticAction } from "./reducers/optimisticController"
import { actions } from "./reducers/auth"
import { actions as loaderActions } from "./reducers/informer"
import { IApplicationResponse, IQueryException } from "../api/baseTypes"
import { ModalStatus } from "../library/modal/AdResultModal"
import { AppState } from "./reducers"

export function createAutogenApiThunk<TArguments, TApiResult>(
	options: AutogenApiThunkOptions<TArguments, TApiResult>
) {
	return createAsyncThunk<TApiResult, TArguments, { rejectValue: IRejectQueryThunk }>(
		options.typePrefix,
		async (args, thunkAPI) => {
			const apiResult = await executeQuery(
				args,
				options,
				thunkAPI.dispatch
			)
			if (apiResult !== undefined && apiResult.success)
				return apiResult.data as TApiResult
			return thunkAPI.rejectWithValue({
				exception: apiResult?.exception?.text ?? "Ошибка",
				statusCode: apiResult?.status ?? 0,
			})
		}
	)
}

export function createAutogenNoArgsApiThunk<TApiResult>(
	options: AutogenApiThunkOptions<void, TApiResult>
) {
	return createAutogenApiThunk(options)
}

export function createResultFactoryApiThunk<TArguments, TApiResult, TReturnResult>(
	options: ResultFactoryApiThunkOptions<TArguments, TApiResult, TReturnResult>
) {
	return createAsyncThunk<
		TReturnResult,
		TArguments,
		{ rejectValue: IRejectQueryThunk }
	>(
		options.typePrefix,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		async (args, thunkAPI) => {
			options.resultFactory =
				options.resultFactory ??
				((apiRes: TApiResult) => apiRes as unknown as TReturnResult)

			const apiResult = await executeQuery(
				args,
				options,
				thunkAPI.dispatch
			)
			if (apiResult !== undefined)
				return options.resultFactory(
					args,
					apiResult.data as TApiResult,
					thunkAPI.getState() as AppState
				)

			thunkAPI.rejectWithValue({
				exception: "net::ERR_CONNECTION_REFUSED",
				statusCode: 0,
			})
		}
	)
}

export async function executeQueryLite<TArguments, TApiReturnType>(
	args: TArguments,
	method: (arg: TArguments) => Promise<any>,
	dispatch: Dispatch,
	showErrors = false
): Promise<QueryResult<TApiReturnType> | undefined> {
	try {
		const response = await method(args)
		if (response.success)
			return response;

		const errorResult = {
			success: false,
			data: undefined,
			exception: response.exception,
			status: response.status,
		}

		if (response.status === 401) {
			dispatch(actions.clearCredentials())
			return errorResult
		}

		if (!showErrors) return errorResult

		if (response.status === 0) {
			dispatch(
				loaderActions.showLoader({
					status: ModalStatus.error,
					text: "Сервер недоступен",
				})
			)
			return
		}
	} catch (e) {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.error,
				text: "Сервер недоступен",
			})
		)
	}

	return undefined
}

export async function executeQuery<TArguments, TApiReturnType>(
	args: TArguments,
	options: AutogenApiThunkOptions<TArguments, TApiReturnType>,
	dispatch: Dispatch
): Promise<QueryResult<TApiReturnType> | undefined> {
	try {
		if (options.isOptimistic) dispatch(optimisticAction.addQuery())
		if (options.showLoader) {
			dispatch(
				loaderActions.showLoader({
					status: ModalStatus.loading,
					text: "Загрузка",
				})
			)
		}

		const response = await options.apiCall(args)
		if (!response.success) {
			const errorResult = {
				success: false,
				data: undefined,
				exception: response.exception,
				status: response.status,
			}

			if (response.status === 401) {
				dispatch(actions.clearCredentials())
				return errorResult
			}

			if (options.notShowError) return errorResult

			if (response.status === 0) {
				dispatch(
					loaderActions.showLoader({
						status: ModalStatus.error,
						text: "Сервер недоступен",
					})
				)
				return
			}
			let errorText = ""
			if (options.errorMessagesByStatusCode)
				errorText = options.errorMessagesByStatusCode[response.status]
			if (!errorText)
				errorText =
					options.errorMessage ??
					response.exception?.text ??
					"Непредвиденная ошибка при обращении к серверу"

			dispatch(
				loaderActions.showLoader({
					status: ModalStatus.error,
					text: errorText,
				})
			)

			return errorResult
		} else {
			if (options.showSuccess)
				dispatch(
					loaderActions.showLoader({
						status: ModalStatus.success,
						text: options.successMessage ?? "Успешно",
					})
				)
			else if (options.showLoader) {
				dispatch(loaderActions.closeLoader())
			}

			return {
				data: response.data as TApiReturnType,
				exception: null,
				status: response.status,
				success: true,
			}
		}
	} catch (e) {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.error,
				text: "Сервер недоступен",
			})
		)
	} finally {
		if (options.isOptimistic) dispatch(optimisticAction.removeQuery())
	}

	return undefined
}

type QueryResult<TApiReturnType> = {
	success: boolean
	data: TApiReturnType | undefined
	exception?: IQueryException | null
	status: number
}

type AutogenApiThunkOptions<TArguments, TApiReturnType> = {
	apiCall:
	| ((args: TArguments) => Promise<IApplicationResponse<TApiReturnType>>)
	| (() => Promise<IApplicationResponse<TApiReturnType>>)
	typePrefix: string
	isOptimistic?: boolean
	showLoader?: boolean
	showSuccess?: boolean
	successMessage?: string
	notShowError?: boolean
	errorMessage?: string
	errorMessagesByStatusCode?: Record<number, string>
}

type ResultFactoryApiThunkOptions<TArguments, TApiReturnType, TReturnResult> =
	AutogenApiThunkOptions<TArguments, TApiReturnType> & {
		resultFactory: (
			args: TArguments,
			apiResponse: TApiReturnType,
			state: AppState
		) => TReturnResult
	}
