import { useEffect } from "react"
import { ProductImages } from "../../modules/ProductImages"
import { ProductCharacteristic } from "../../modules/ProductCharacteristic"
import "./productDescriptionTab.scss"
export default function ProductDescriptionTab(props: { onMount: () => void }) {
	useEffect(() => {
		props.onMount()
		return () => {
			console.log("unmount")
		}
	}, [])

	return (
		<div className={"product-description-tab u-flex-1-inside"}>
			<ProductCharacteristic />
			<ProductImages />
		</div>
	)
}
