import React, { useEffect, useState } from "react"
import { ICategoryViewModel } from "../tables/productGroupTable/types"
import "./categoryExpandedList.scss"
import { SvgIcon } from "../../icons/SvgIcon"
import { AdInputStatus } from "../../inputs/AdDefaultInput"
import { useDispatch, useSelector } from "react-redux"
import { CategoriesSelectors } from "../../../redux/reducers/categories/categoriesSelectors"
import { CategoriesComponentThunks } from "../../../redux/reducers/categories/thunk"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { CatalogGroupSelectors } from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { isEnter, isEscape } from "../../../utils/KeysCodes"
import { actions as informerActions } from "../../../redux/reducers/informer/index"
import AdDynamicTextarea from "../../inputs/AdDynamicTextarea"
import { CatalogGroup } from "../../../domain/types"

export default function CategoryExpandedList(
	props: ICategoryExpandedListProps
) {
	return (
		<>
			{props.categories
				.orderBy((x) => x.sort)
				.map((x) => (
					<CategoryExpanded
						key={x.id}
						spanLevel={props.spanLevel}
						category={x}
						highlightedRows={props.highlightedRows}
						onCheckboxClicked={props.onCheckboxClicked}
						onRowClicked={props.onRowClicked}
					/>
				))}
		</>
	)
}

interface ICategoryExpandedListProps {
	categories: ICategoryViewModel[]
	onRowClicked: (category: ICategoryViewModel) => void
	onCheckboxClicked: (category: ICategoryViewModel) => void
	spanLevel: number
	highlightedRows: number[]
}

function CategoryExpanded(props: ICategoryExpandedProps) {
	const [isToggled, setToggled] = useState<boolean>(false)
	const [edit, toggleEdit] = useState<boolean>(false)
	const [inputStatus, setInputStatus] = useState<AdInputStatus>(
		AdInputStatus.default
	)
	const [newName, setNewName] = useState<string>(props.category.name)

	const dispatch = useDispatch()

	const selected = useSelector(CategoriesSelectors.getSelected)

	const language = useSelector(LanguageSelectors.getSelected)
	const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
	const selectedWebCategory = useSelector(CategoriesSelectors.getSelectedWebCategory)
	const selectedGroup = useSelector(CatalogGroupSelectors.getSelected)


	function updateCategoryName() {
		if (!selected) {
			dispatch(informerActions.inform("Выберите категорию"))
			return false
		}

		if (selected.name !== newName) {
			return dispatch(
				CategoriesComponentThunks.updateCategoryName({
					catalogGroup: catalogGroup.id,
					name: newName,
					languageId: language.id,
					id: selected.id,
				})
			)
		}
	}

	function deleteCategory() {
		if (props.category.children.notEmpty()) {
			dispatch(informerActions.inform("У категории есть суб категории"))
			return
		}
		dispatch(
			CategoriesComponentThunks.deleteCategory({
				id: props.category.id,
				catalogGroup: catalogGroup.id,
			})
		)
	}

	async function onEnter(e) {
		if (isEnter(e)) {
			e.preventDefault()
			const res = await updateCategoryName()
			setInputStatus(res ? AdInputStatus.success : AdInputStatus.error)
			e.target.blur()
		}
		if (isEscape(e)) {
			setInputStatus(AdInputStatus.default)
			e.currentTarget.textContent = props.category.name
			setNewName(props.category.name)
			e.target.blur()
		}
	}

	useEffect(() => {
		if (selected?.id !== props.category.id) {
			toggleEdit(false)
		}
	}, [selected])

	useEffect(() => {
		if (!edit) {
			setInputStatus(AdInputStatus.default)
		}
	}, [edit])

	const isNoCatalogImage = (category: ICategoryViewModel) => {
		if (![CatalogGroup.Web, CatalogGroup.Web_Latvia].includes(selectedGroup.id))
			return false;

		if(!category.imageUrl)
			return true

		return !category.imageUrl[selectedWebCategory.id];
	}

	return (
		<>
			{/* @ts-ignore */}
			<tr
				className={`cat-category-group-list__element ${props.category.selected ? "table-row--selected" : ""
					} ${props.highlightedRows.findIndex(
						(x) => props.category.id === x
					) > -1
						? "--highlighted"
						: ""
					} ${props.spanLevel ? "shifted-row" : ""}`}
				// @ts-ignore
				style={{ "--shift-amount": props.spanLevel }}
			>
				<td
					className="u-table-column-checkbox-wrapper"
					onClick={() => props.onCheckboxClicked(props.category)}
				>
					<input
						type="checkbox"
						id={String(props.category.id)}
						checked={props.category.checked}
						readOnly={true}
					/>
				</td>
				{props.category.children.length !== 0 ? (
					<td
						className="product-right-column-plus-wrapper"
						onClick={() => setToggled(!isToggled)}
					>
						{isToggled ? (
							<SvgIcon
								name="chevron-top"
								className="rem-icon"
							></SvgIcon>
						) : (
							<SvgIcon
								name="chevron-bottom"
								className="rem-icon"
							></SvgIcon>
						)}
					</td>
				) : (
					<></>
				)}
				<td
					className={
						"squeeze " +
						(selected?.id === props.category.id && edit
							? "input-inside"
							: "")
					}
					onClick={() => {
						if (!edit) props.onRowClicked(props.category)
					}}
					colSpan={props.category.children.length !== 0 ? 1 : 2}
				>
					{selected?.id === props.category.id && edit ? (
						<AdDynamicTextarea
							value={newName}
							onChange={setNewName}
							onKeyDown={onEnter}
							status={inputStatus}
						/>
					) : (
						props.category.name
					)}
				</td>
				<td>
					{isNoCatalogImage(props.category) && <SvgIcon name="photo-orange" />}
				</td>
				<td className="move">
					<div className="instruments-holder">
						<SvgIcon
							name="pencil-create"
							onClick={() => {
								if (selected?.id !== props.category.id)
									props.onRowClicked(props.category)
								toggleEdit(!edit)
							}}
						/>
						<SvgIcon name="circle-x" onClick={deleteCategory} />
					</div>
				</td>
			</tr>

			{!isToggled ? (
				<></>
			) : (
				<CategoryExpandedList
					spanLevel={props.spanLevel + 1}
					highlightedRows={props.highlightedRows}
					categories={props.category.children}
					onCheckboxClicked={props.onCheckboxClicked}
					onRowClicked={props.onRowClicked}
				/>
			)}
		</>
	)
}

interface ICategoryExpandedProps {
	category: ICategoryViewModel
	onRowClicked: (category: ICategoryViewModel) => void
	onCheckboxClicked: (category: ICategoryViewModel) => void
	spanLevel: number
	highlightedRows: number[]
}
