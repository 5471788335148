import Api from "../../../../../api"
import {createAutogenApiThunk, createResultFactoryApiThunk} from "../../../../createApiThunkV2"

export class GraphicComponentsThunks {
    static getProductImages = createAutogenApiThunk({
        typePrefix: "new-product/get-product-group-images",
        apiCall: Api.images.getImages,
        errorMessage: "Не удалось получить список изображений группы продуктов"
    })

    static uploadVideo = createAutogenApiThunk({
        typePrefix: "new-product/upload-video",
        apiCall: Api.images.addVideo,
        errorMessage: "Не удалось добавить видео"
    })

    static uploadImage = createAutogenApiThunk({
        typePrefix: "new-product/upload-image",
        apiCall: Api.images.uploadImage,
        errorMessage: "Не удалось загрузить изображение"
    })

    static removeImage = createAutogenApiThunk({
        typePrefix: "new-product/remove-image",
        apiCall: Api.images.removeImage,
        errorMessage: "Не удалось удалить изображение"
    })

    static updateImage = createAutogenApiThunk({
        typePrefix: "new-product/update-image",
        apiCall: Api.images.updateImage,
        errorMessage: "Не удалось обновить изображение"
    })

    static updateImageType = createAutogenApiThunk({
        typePrefix: "new-product/update-image-type",
        apiCall: Api.images.updateImageType,
        errorMessage: "Не удалось обновить тип изображения"
    })

    static getGroupPictograms = createAutogenApiThunk({
        typePrefix: "new-product/get-group-pictograms",
        apiCall: Api.pictograms.getProductGroupPictograms,
        errorMessage: "Не удалось получить список пиктограмм группы продуктов"
    })

    static addPictogramToGroup = createResultFactoryApiThunk({
        typePrefix: "new-product/add-pictogram",
        apiCall: Api.pictograms.addPictogramToGroup,
        errorMessage: "Не удалось добавить пиктограмму",
        resultFactory: (args, apiResponse, state) => {
            return state.pictogramsState.pictograms
                .find(x => x.id === args.pictogramId)
        }
    })

    static removePictogramFromGroup = createAutogenApiThunk({
        typePrefix: "new-product/remove-pictogram",
        apiCall: Api.pictograms.removePictogramToGroup,
        errorMessage: "Не удалось удалить пиктограмму"
    })

    static changeGroupPictogram = createResultFactoryApiThunk({
        typePrefix: "new-product/change-pictogram",
        apiCall: Api.pictograms.changeGroupPictogram,
        errorMessage: "Не удалось изменить пиктограмму",
        resultFactory: (args, apiResponse, state) => {
            return state.pictogramsState.pictograms
                .find(x => x.id === args.newPictogramId)
        }
    })
}

