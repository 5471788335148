import Api from "../../../api"
import {createAutogenApiThunk} from "../../createApiThunkV2"

export class CategoriesComponentThunks {

    static getCategories = createAutogenApiThunk({
        typePrefix: "get/categories",
        apiCall: Api.category.getCategoriesByCatalogGroups,
        errorMessage: "Не удалось загрузить категории"
    })

    static updateCategoryName = createAutogenApiThunk({
        typePrefix: "update/categories-name",
        apiCall: Api.category.updateCategoryName,
        errorMessage: "Не удалось обновить название категории"
    })

    static createCategory = createAutogenApiThunk({
        typePrefix: "create/category",
        apiCall: Api.category.createCategory,
        errorMessage: "Не удалось создать категорию",
        errorMessagesByStatusCode: {
            409: "У категории есть прикрепленные группы. Невозможно создать новую субкатегорию."
        }
    })

    static deleteCategory = createAutogenApiThunk({
        typePrefix: "delete/category",
        apiCall: Api.category.deleteCategory,
        errorMessage: "Не удалось удалить категорию",
        errorMessagesByStatusCode: {
            403: "Невозможно удалить категорию, так как она прикреплена к группе продуктов"
        }
    })

    static addImage = createAutogenApiThunk({
        typePrefix: "add/images-category",
        apiCall: Api.category.addImage,
        errorMessage: "Не удалось загрузить изображение категории по каталогу",
    })
    
    static deleteImage = createAutogenApiThunk({
        typePrefix: "delete/images-category",
        apiCall: Api.category.deleteImage,
        errorMessage: "Не удалось удалить изображение категории по каталогу",
    })
}