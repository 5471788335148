import {createSlice, PayloadAction} from "@reduxjs/toolkit"

import {ServerPageMutexMessages} from "../../../domain/ServerPageMutexMessages"

export type AcquireMutexState = {
    status: ServerPageMutexMessages
    previousStatus: ServerPageMutexMessages
}

const INITIAL_STATE: AcquireMutexState = {
    status: ServerPageMutexMessages.None,
    previousStatus: ServerPageMutexMessages.None
}

const slice = createSlice({
    name: "acquiredMutex",
    initialState: INITIAL_STATE,
    reducers: {
        setStatus(state: AcquireMutexState, action: PayloadAction<ServerPageMutexMessages>) {
            state.previousStatus = state.status
            state.status = action.payload
        }
    }
})

const reducer = slice.reducer
const actions = slice.actions

export {reducer, actions}