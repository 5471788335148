import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ICardDistributionType, IGroupStatisticFilterType} from "../../../../../domain/types"
import {GroupsListThunks,} from "./thunk"
import {IGroupViewModel} from "../../../../../library/smartComponents/tables/productGroupTable/types"
import _ from "lodash"
import {ProductsListThunks} from "../productList/thunks"
import { GroupStatisticFilter } from "../../../../../domain/GroupStatisticFilter"
import { IOType } from "child_process"
import { IOptionType } from "../../../../../app/common/basic/selectors/SimpleSelect"

export type GroupListState = {
    groups: IGroupViewModel[],
    isGroupsLoading: boolean,
    filter: string
    selectedCardType: ICardDistributionType,
    cardTypes: ICardDistributionType[],
    groupStatisticFilterTypes: IGroupStatisticFilterType[],
    selectedGroup: IGroupViewModel | null,
    selectedGroupStatisticFilter: IGroupStatisticFilterType,
    isImagesUpdating: boolean
}

const INITIAL_STATE: GroupListState = {
    isGroupsLoading: false,
    groups: [],
    selectedGroup: null,
    filter: "",
    cardTypes: [
        {label: "Все карточки", value: 0},
        {label: "Непроверенное описание", value: 1},
        {label: "Непроверенное фото", value: 2}
    ],
    groupStatisticFilterTypes: [
        {label: "Все", value: GroupStatisticFilter.Any},
        // {label: "В статистике", value: GroupStatisticFilter.InStatistic},
        {label: "Выведены из статистики", value: GroupStatisticFilter.OutOfStatistic},
        {label: "Выведены товары", value: GroupStatisticFilter.OutProduct}
    ],
    selectedCardType: {label: "Все карточки", value: 0},
    selectedGroupStatisticFilter: {label: "Все", value: GroupStatisticFilter.Any},
    isImagesUpdating: false
}

const productGroupComponentSlice = createSlice({
    name: "productPage/groupList",
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedGroupFilter(state: GroupListState, action: PayloadAction<GroupStatisticFilter>) {
            state.selectedGroupStatisticFilter = state.groupStatisticFilterTypes.find(x => x.value === action.payload) ?? state.selectedGroupStatisticFilter
        },
        setFilter(state: GroupListState, action: PayloadAction<string>) {
            state.filter = action.payload.toLowerCase()
        },
        setSelectedCardType(state: GroupListState, action: PayloadAction<number>) {
            state.selectedCardType = state.cardTypes.find(x => x.value === action.payload) ?? state.selectedCardType
        },
        setSelectedProductGroup(state: GroupListState, action: PayloadAction<IGroupViewModel>) {
            const group = state.groups.find(x => x.id === action.payload.id)
            if (!group)
                return
            if (!state.selectedGroup) {
                state.selectedGroup = group
                group.checked = true
            } else {
                if (state.selectedGroup.id === action.payload.id) {
                    state.selectedGroup = null
                    group.checked = false
                } else {
                    const previous = state.groups.find(x => x.id === state.selectedGroup?.id)
                    if (previous) {
                        previous.checked = false
                    }
                    state.selectedGroup = group
                    group.checked = true
                }
            }
        },
        clearStateOnUnmount() {
            return {...INITIAL_STATE}
        }
    },
    extraReducers: builder => {
        builder.addCase(GroupsListThunks.getGroups.pending, (state) => {
            state.isGroupsLoading = true
        })
        builder.addCase(GroupsListThunks.getGroups.fulfilled, (state, action) => {
            const groups = action.payload
            state.groups = _.orderBy(groups.map(x => {
                return {
                    ...x,
                    checked: false,
                    products: null,
                    isLoading: false,
                    isLastActive: false,
                    shouldHighlight: false
                }
            }), x => x.name)
            state.isGroupsLoading = false
            if(state.selectedGroup) {
                const groupFromList = state.groups.find(x => x.id === state.selectedGroup!.id)
                if(groupFromList) {
                    state.selectedGroup = groupFromList
                    groupFromList.checked = true
                }
                else {
                    if(state.groups.length > 0) {
                        if(state.groups.first().priceGroupId != state.selectedGroup.priceGroupId)
                        {
                            state.selectedGroup = null
                        }
                    }
                }
            }
        })
        builder.addCase(GroupsListThunks.getGroups.rejected, (state) => {
            state.isGroupsLoading = false
        })
        builder.addCase(GroupsListThunks.getProductsByGroup.pending, (state, action) => {
            const groupId = action.meta.arg.productGroupId
            const group = state.groups.find(x => x.id === groupId)
            if (group)
                group.isLoading = true
        })
        builder.addCase(GroupsListThunks.getProductsByGroup.fulfilled, (state, action) => {
            const products = action.payload
            const groupId = action.meta.arg.productGroupId
            const group = state.groups.find(x => x.id === groupId)
            if (group) {
                group.products = products
                group.isLoading = false
            }
        })
        builder.addCase(GroupsListThunks.updateGroupImages.pending, (state) => {
            state.isImagesUpdating = true
        })
        builder.addCase(GroupsListThunks.updateGroupImages.fulfilled, (state) => {
            state.isImagesUpdating = false
        })
        builder.addCase(GroupsListThunks.updateGroupImages.rejected, (state) => {
            state.isImagesUpdating = false
        })
    }
})

const actions = productGroupComponentSlice.actions
const reducer = productGroupComponentSlice.reducer

export {actions, reducer}

