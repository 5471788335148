import {
	IAttribute,
	IGroupAttributeDescr,
	IProductGroup,
	ISellmark,
	ISeries,
	ISign,
} from "../../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NewProductThunks } from "./thunks"
import { combineReducers } from "redux"
import { reducer as catTabReducer } from "./categoryTabComponent"
import { reducer as tableTabReducer } from "./tablePartComponent"
import { reducer as graphicTabReducer } from "./graphicComponent"
import { reducer as additionalInfoReducer } from "./additionalInfoComponent"
import { TablePartComponentThunks } from "./tablePartComponent/thunk"

//поле JustCreated нужно, чтобы можно было отложить загрузку продуктов до тех пор пока не получим
//полную информацию по свежесозданной группе
type ProductGroupState = IProductGroup & { autoId: string, justCreated: boolean }

export type NewProductState = {
	productGroup: ProductGroupState
	series: ISeries[]
	selectedSeries: ISeries | null
	signs: ISign[]
	selectedSign: ISign | null
	attributes: IAttribute[]
	selectedAttribute: IAttribute | null
	sellmarks: ISellmark[]
	selectedSellmark: ISellmark | null
	loadingState: INewProductLoadingState
	currentTab: TabKey
	isTableRefreshRequested: boolean
}

interface INewProductLoadingState {
	isSaveLoading: boolean
	isRejectLoading: boolean
	isPageLoading: boolean
}

export enum TabKey {
	Category = "category",
	TablePart = "table",
	Graphic = "graphic",
	Additional = "additional",
}

const INITIAL_SERIES: ISeries[] = [
	{ id: 0, name: "loading", imageUrl: "", titleEng: "" },
]
const INITIAL_SIGNS: ISign[] = [{ id: 0, name: "loading", imageUrl: "" }]
const INITIAL_ATTRIBUTES: IAttribute[] = [{ id: 0, name: "loading" }]
const INITIAL_SELLMARKS: ISellmark[] = [{ id: 0, name: "loading" }]

const INITIAL_STATE: NewProductState = {
	productGroup: {
		id: "",
		autoId: "",
		name: "",
		attributesColumnOrder: [],
		description: "",
		descriptionWeb: "",
		isToolset: false,
		isImageChecked: false,
		isDescriptionChecked: false,
		mainAttributeId: null,
		priceGroupId: 0,
		sellmarkId: null,
		seriesId: null,
		signId: null,
		siteId: 0,
		wasCreate: false,
		justCreated: false,
	},
	series: INITIAL_SERIES,
	selectedSeries: INITIAL_SERIES[0],
	signs: INITIAL_SIGNS,
	selectedSign: INITIAL_SIGNS[0],
	attributes: INITIAL_ATTRIBUTES,
	selectedAttribute: INITIAL_ATTRIBUTES[0],
	sellmarks: INITIAL_SELLMARKS,
	selectedSellmark: INITIAL_SELLMARKS[0],
	loadingState: {
		isRejectLoading: false,
		isSaveLoading: false,
		isPageLoading: true,
	},
	isTableRefreshRequested: false,
	currentTab: TabKey.Graphic,
}

const TEST_INITIAL_STATE = { ...INITIAL_STATE }

const slice = createSlice({
	name: "new-product",
	initialState: INITIAL_STATE,
	reducers: {
		setId(state: NewProductState, action: PayloadAction<string>) {
			state.productGroup.id = action.payload
		},
		setName(state: NewProductState, action: PayloadAction<string>) {
			state.productGroup.name = action.payload
		},
		setIsToolset(state: NewProductState, action: PayloadAction<boolean>) {
			state.productGroup.isToolset = action.payload
		},
		setIsImageChecked(
			state: NewProductState,
			action: PayloadAction<boolean>
		) {
			state.productGroup.isImageChecked = action.payload
		},
		setIsDescrChecked(
			state: NewProductState,
			action: PayloadAction<boolean>
		) {
			state.productGroup.isDescriptionChecked = action.payload
		},
		setSelectedSeries(
			state: NewProductState,
			action: PayloadAction<number | null>
		) {
			const series = state.series.find((x) => x.id === action.payload)
			if (series === undefined) {
				state.selectedSeries = null
			} else {
				state.selectedSeries = series
			}
		},
		setSelectedSign(
			state: NewProductState,
			action: PayloadAction<number | null>
		) {
			const series = state.signs.find((x) => x.id === action.payload)
			if (series === undefined) {
				state.selectedSign = null
			} else {
				state.selectedSign = series
			}
		},
		setSelectedAttribute(
			state: NewProductState,
			action: PayloadAction<number | null>
		) {
			const attribute = state.attributes.find(
				(x) => x.id === action.payload
			)
			if (attribute === undefined) {
				state.selectedAttribute = null
			} else {
				state.selectedAttribute = attribute
			}
		},
		setSelectedSellmark(
			state: NewProductState,
			action: PayloadAction<number | null>
		) {
			const sellmark = state.sellmarks.find(
				(x) => x.id === action.payload
			)
			if (sellmark === undefined) state.selectedSellmark = null
			else state.selectedSellmark = sellmark
		},
		setPriceGroup(
			state: NewProductState,
			action: PayloadAction<number | null>
		) {
			state.productGroup.priceGroupId = action.payload
		},
		setDescription(state: NewProductState, action: PayloadAction<string>) {
			state.productGroup.description = action.payload
		},
		setWebDescription(
			state: NewProductState,
			action: PayloadAction<string>
		) {
			state.productGroup.descriptionWeb = action.payload
		},
		setSelectedTab(state: NewProductState, action: PayloadAction<TabKey>) {
			state.currentTab = action.payload
		},
		setAttributeOrder(
			state: NewProductState,
			action: PayloadAction<Record<number, IGroupAttributeDescr>>
		) {
			state.productGroup.attributesColumnOrder = action.payload
		},
		requestTableRefresh(state: NewProductState) {
			state.isTableRefreshRequested = true
		},
		tableRefreshed(state: NewProductState) {
			state.isTableRefreshRequested = false
		},
		clearStateOnUnmount() {
			return INITIAL_STATE
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			NewProductThunks.getSigns.fulfilled,
			(state, action) => {
				state.signs = action.payload
				state.selectedSign =
					action.payload.find(
						(x) => x.id === state.productGroup.signId
					) ?? null
			}
		)
		builder.addCase(
			NewProductThunks.getSeries.fulfilled,
			(state, action) => {
				state.series = action.payload
				state.selectedSeries =
					action.payload.find(
						(x) => x.id === state.productGroup.seriesId
					) ?? null
			}
		)
		builder.addCase(
			NewProductThunks.getAttributes.fulfilled,
			(state, action) => {
				state.attributes = action.payload
				state.selectedAttribute =
					action.payload.find(
						(x) => x.id === state.productGroup.mainAttributeId
					) ?? null
			}
		)
		builder.addCase(
			NewProductThunks.getSellmarks.fulfilled,
			(state, action) => {
				state.sellmarks = action.payload
				state.selectedSellmark =
					action.payload.find(
						(x) => x.id === state.productGroup.sellmarkId
					) ?? null
			}
		)
		builder.addCase(NewProductThunks.getOrReserve.pending, (state) => {
			state.loadingState.isPageLoading = true
		})
		builder.addCase(
			NewProductThunks.getOrReserve.fulfilled,
			(state, action) => {
				state.productGroup = {
					id: action.payload.id,
					autoId: action.payload.id,
					name: action.payload.name,
					priceGroupId: action.payload.priceGroupId,
					sellmarkId: action.payload.sellmarkId,
					mainAttributeId: action.payload.mainAttributeId,
					seriesId: action.payload.seriesId,
					signId: action.payload.signId,
					isDescriptionChecked: action.payload.isDescriptionChecked,
					isImageChecked: action.payload.isImageChecked,
					attributesColumnOrder: action.payload.attributesColumnOrder,
					description: action.payload.description,
					justCreated: false,
					descriptionWeb: action.payload.descriptionWeb,
					isToolset: action.payload.isToolset,
					siteId: action.payload.siteId,
					wasCreate: action.payload.wasCreate,
				}
				state.loadingState.isPageLoading = false
			}
		)
		builder.addCase(NewProductThunks.getOrReserve.rejected, (state) => {
			state.loadingState.isPageLoading = false
		})

		builder.addCase(
			NewProductThunks.createProductGroup.pending,
			(state) => {
				state.loadingState.isSaveLoading = true
			}
		)
		builder.addCase(
			NewProductThunks.createProductGroup.fulfilled,
			(state) => {
				console.log("Product group created")
				state.productGroup.wasCreate = true
				state.loadingState.isSaveLoading = false
				state.productGroup.justCreated = true
			}
		)
		builder.addCase(
			NewProductThunks.createProductGroup.rejected,
			(state, action) => {
				state.loadingState.isSaveLoading = false
			}
		)

		builder.addCase(NewProductThunks.discardReserve.pending, (state) => {
			state.loadingState.isRejectLoading = true
		})
		builder.addCase(NewProductThunks.discardReserve.fulfilled, (state) => {
			state.loadingState.isRejectLoading = false
		})
		builder.addCase(NewProductThunks.discardReserve.rejected, (state) => {
			state.loadingState.isRejectLoading = false
		})

		builder.addCase(
			NewProductThunks.deleteProductGroup.pending,
			(state) => {
				state.loadingState.isRejectLoading = true
			}
		)
		builder.addCase(
			NewProductThunks.deleteProductGroup.fulfilled,
			(state) => {
				state.loadingState.isRejectLoading = false
			}
		)
		builder.addCase(
			NewProductThunks.deleteProductGroup.rejected,
			(state, action) => {
				state.loadingState.isRejectLoading = false
				console.log(
					`Can't delete product group. Status code: '${action.payload?.statusCode}'. Text: '${action.payload?.exception}'`
				)
			}
		)

		builder.addCase(
			NewProductThunks.updateProductGroup.pending,
			(state) => {
				state.loadingState.isSaveLoading = true
			}
		)
		builder.addCase(
			NewProductThunks.updateProductGroup.fulfilled,
			(state, action) => {
				const group = state.productGroup
				//выделяем эти поля т.к. изменяем их отдельно, чтобы отслеживать их статус
				const attributes = group.attributesColumnOrder
				const attributeIds = Object.keys(attributes)
				const curMain = action.meta.arg.mainAttributeId
				const oldMain = group.mainAttributeId

				if (oldMain != curMain) {
					if (oldMain && attributes[oldMain]) {
						for (let i = 0; i < attributeIds.length; i++) {
							const attrId = attributeIds[i]
							const attribute = attributes[attrId]
							if (attribute.isImportant)
								attribute.importantSort! += 1
						}
					}
					if (curMain) {
						if (attributes[curMain]) {
							const mainAttribute = attributes[curMain]
							if (mainAttribute.isImportant) {
								for (let i = 0; i < attributeIds.length; i++) {
									const attrId = attributeIds[i]
									const attribute = attributes[attrId]
									if (attribute.isImportant) {
										if (
											attribute.importantSort! >
											mainAttribute.importantSort!
										) {
											attribute.importantSort! -= 1
										}
									}
								}
							}
							mainAttribute.isImportant = true
							mainAttribute.importantSort = 1
						}
					}
				}

				group.sellmarkId = action.meta.arg.sellmarkId
				group.mainAttributeId = curMain
				group.seriesId = action.meta.arg.seriesId
				group.signId = action.meta.arg.signId

				state.loadingState.isSaveLoading = false
			}
		)
		builder.addCase(
			NewProductThunks.updateProductGroup.rejected,
			(state, action) => {
				state.loadingState.isSaveLoading = false
			}
		)

		//todo: переделать state, убрать отсюда всю логику связанную с аттрибутами, чтобы не нужно было реагировать на чужие thunk
		builder.addCase(
			TablePartComponentThunks.addAttribute.pending,
			(state, action) => {
				const attribute = action.meta.arg.attributeId
				const isImportant =
					state.productGroup.mainAttributeId === attribute

				let maxSort = 0
				Object.keys(state.productGroup.attributesColumnOrder).forEach(
					(x) => {
						const attr = state.productGroup.attributesColumnOrder[x]
						if (attr.sort! > maxSort) maxSort = attr.sort!
					}
				)
				maxSort += 1
				//добавляем аттрибут в список
				state.productGroup.attributesColumnOrder[attribute] = {
					importantSort: isImportant ? 1 : null,
					isImportant: isImportant,
					sort: maxSort,
				}
			}
		)

		//todo: переделать state, убрать отсюда всю логику связанную с аттрибутами, чтобы не нужно было реагировать на чужие thunk
		builder.addCase(
			TablePartComponentThunks.removeAttribute.pending,
			(state, action) => {
				const attributeId = action.meta.arg.attributeId
				const deletedAttribute =
					state.productGroup.attributesColumnOrder[attributeId]

				Object.keys(state.productGroup.attributesColumnOrder).forEach(
					(x) => {
						const attribute =
							state.productGroup.attributesColumnOrder[x]
						if (
							attribute.sort! >
							state.productGroup.attributesColumnOrder[
								attributeId
							].sort!
						)
							attribute.sort = attribute.sort! - 1
						if (
							deletedAttribute.isImportant &&
							attribute.isImportant &&
							attribute.importantSort! >
								deletedAttribute.importantSort!
						) {
							attribute.importantSort =
								attribute.importantSort! - 1
						}
					}
				)
				delete state.productGroup.attributesColumnOrder[attributeId]
			}
		)

		//todo: переделать state, убрать отсюда всю логику связанную с аттрибутами, чтобы не нужно было реагировать на чужие thunk
		builder.addCase(
			TablePartComponentThunks.changeAttributeImportantStatus.pending,
			(state, action) => {
				const attributeId = action.meta.arg.attributeId
				const attribute =
					state.productGroup.attributesColumnOrder[attributeId]
				if (attribute) {
					attribute.isImportant = !attribute.isImportant
					if (attribute.isImportant) {
						if (attributeId == state.productGroup.mainAttributeId) {
							attribute.importantSort = 1
						} else {
							const curSorts = Object.values(
								state.productGroup.attributesColumnOrder
							).map((x) => x.importantSort ?? 0)
							let maxId = Math.max(...curSorts)
							if (maxId == 0) maxId = 2
							else maxId = maxId + 1
							attribute.importantSort = maxId
						}
					} else {
						const attrIds = Object.keys(
							state.productGroup.attributesColumnOrder
						)
						for (let i = 0; i < attrIds.length; i++) {
							const id = attrIds[i]
							if (Number(id) === attributeId) continue
							const attr =
								state.productGroup.attributesColumnOrder[id]
							if (!attr.importantSort) continue
							if (attr.importantSort > attribute.importantSort!) {
								attr.importantSort = attr.importantSort! - 1
							}
						}
						attribute.importantSort = null
					}
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = combineReducers({
	common: slice.reducer,
	categoryState: catTabReducer,
	tableTabState: tableTabReducer,
	graphicState: graphicTabReducer,
	additionalInfoState: additionalInfoReducer,
})

export { actions, reducer, TEST_INITIAL_STATE }
