import "./adCategorySelectRow.scss"
import { useEffect, useState } from "react"
import { ICategory } from "../../domain/models/categories/ICategory"
import CategoryTreeUtils from "../../CategoryTreeUtils"
import NullableSelect from "../../app/common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"

export default function AdCategorySelectRow(props: {
	categories: ICategory[]
	onChange: (category: ICategory | null, level: number) => void
	shouldReset: boolean
	onReset: () => void
	height?: number
	isDisabled?: boolean
	//canChooseProductCategory: boolean
}) {
	const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
		null
	)

	const onChange = (id: number | null) => {
		if (id === null) {
			setSelectedCategory(null)
		} else {
			const category = CategoryTreeUtils.findCategory(
				id,
				props.categories
			)
			setSelectedCategory(category)
		}
	}

	useEffect(() => {
		if (props.shouldReset) {
			props.onReset()
			setSelectedCategory(null)
		}
	}, [props.shouldReset])

	return (
		<>
			<NullableSelect
				value={selectedCategory}
				options={props.categories}
				onChange={(e) => {
					onChange(e as number)
				}}
				toOption={ToOptionProvider.categoryToOption}
				className={"selector category-selector"}
				height={props.height}
				isDisabled={props.isDisabled ?? false}
				placeholder={"Выберите категорию"}
			/>
			{selectedCategory != null &&
			selectedCategory.children.length != 0 ? (
				<AdCategorySelectRowChild
					level={1}
					parent={selectedCategory}
					onChange={props.onChange}
					height={props.height}
					key={selectedCategory.id}
					categories={selectedCategory.children}
				/>
			) : (
				<></>
			)}
		</>
	)
}

function AdCategorySelectRowChild(props: {
	categories: ICategory[]
	parent: ICategory
	onChange: (category: ICategory | null, level: number) => void
	level: number
	height?: number
	isDisabled?: boolean
}) {
	const initial = props.parent.children[0]
	const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
		initial
	)

	useEffect(() => {
		if (
			selectedCategory === null ||
			selectedCategory.children.length === 0
		) {
			props.onChange(selectedCategory, props.level)
		}
	}, [selectedCategory])

	const onChange = (id: number | null) => {
		if (id === null) {
			setSelectedCategory(null)
		} else {
			const category = CategoryTreeUtils.findCategory(
				id,
				props.categories
			)
			setSelectedCategory(category)
		}
	}

	return (
		<>
			<NullableSelect
				value={selectedCategory}
				options={props.categories}
				onChange={(e) => {
					onChange(e as number)
				}}
				toOption={ToOptionProvider.categoryToOption}
				className={"selector category-selector"}
				height={props.height}
				isDisabled={props.isDisabled ?? false}
				placeholder={"Выберите категорию"}
			/>
			{selectedCategory != null &&
			selectedCategory.children.length != 0 ? (
				<AdCategorySelectRowChild
					level={props.level + 1}
					parent={selectedCategory}
					onChange={props.onChange}
					key={selectedCategory.id}
					height={props.height}
					categories={selectedCategory.children}
				/>
			) : (
				<></>
			)}
		</>
	)
}
