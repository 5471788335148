import BaseApi from "../BaseApi"
import actionTypes, {IApplicationResponse} from "../baseTypes"

export default class FileApi extends BaseApi {
    getProductFileByGroupPriceGroup = (data: {
        search: string;
        productGroupId: string;
        languageId: number;
        fileType: number
    }): Promise<IApplicationResponse<void>> =>
        this.downloadFile("/api/file/products", data, true)

    getCatalogInfoAsZip = (data: {
        catalogId: number;
        websiteId: number;
        languageId: number;
    }): Promise<IApplicationResponse<void>> =>
        this.downloadFile("/api/file/catalog-info", data, true)

    getTranslatesInfo = (data: {
        catalogId: number,
        targetLanguageId: number,
        websiteId: number,
        isEmpty: boolean,
        fileType: number,
        search: string
    }): Promise<IApplicationResponse<void>> =>
        this.downloadFile("/api/file/translates-info", data, true)

    uploadTranslate = (data: { translateExcel: File }): Promise<IApplicationResponse<string>> =>
        this.sendQuery<string>("/api/file/import-translates", data, actionTypes.postFile, true, 600 * 1000)
}