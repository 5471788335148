import {AppState} from "../../index"
import {CatalogGroup, ICatalogGroup} from "../../../../domain/types"
import _ from "lodash"
import {IGroupWithCatViewModel} from "../../../../library/smartComponents/tables/productGroupTable/types"

export class TreePageSelectors {
    static getOrderedGroups = (catalogGroup: ICatalogGroup) => (state: AppState): IGroupWithCatViewModel[] =>
        catalogGroup.id === CatalogGroup.Printed
            ? _.orderBy(state.pages.treePage.groups, x => x.sort)
            : state.pages.treePage.groups
    static getIsProductGroupsLoading = (state: AppState) => state.pages.treePage.isProductGroupsLoading
    static getSortNumber = (state: AppState) => state.pages.treePage.sortNumber
    static getLastSelected = (state: AppState) => state.pages.treePage.selectedGroups.peek()
    static getFilter = (state: AppState) => state.pages.treePage.filter
    static getCardTypes = (state: AppState) => state.pages.treePage.cardTypes
    static getSelectedCardType = (state: AppState) => state.pages.treePage.selectedCardType
    static getMinSort = (state: AppState) => state.pages.treePage.minSort
    static getMaxSort = (state: AppState) => state.pages.treePage.maxSort
    static getSelectedGroups = (state: AppState) => state.pages.treePage.selectedGroups
}