import Api from "../../../../../api"
import { createAutogenApiThunk } from "../../../../createApiThunkV2"

export class CategoryTabComponentThunks {
	static getCategories = createAutogenApiThunk({
		typePrefix: "new-product/get-categories",
		apiCall: Api.category.getCategoriesByCatalogGroups,
		errorMessage: "Не удалось получить список категорий",
	})

	static getProductGroupCategories = createAutogenApiThunk({
		typePrefix: "new-product/get-product-group-cats",
		apiCall: Api.category.getProductGroupCats,
		errorMessage: "Не удалось получить список категорий группы продуктов",
	})

	static addProductGroupToCats = createAutogenApiThunk({
		typePrefix: "new-product/add-product-group-to-cat",
		apiCall: Api.category.addProductGroupToCats,
		errorMessage: "Не удалось добавить группу продуктов к категории",
	})

	static removeProductGroupFromCats = createAutogenApiThunk({
		typePrefix: "new-product/remove-product-group-from-cat",
		apiCall: Api.category.removeProductGroupsFromCats,
		errorMessage: "Не удалось удалить группу продуктов из категории",
	})

	static changeProductGroupCategory = createAutogenApiThunk({
		typePrefix: "new-product/change-product-group-cat",
		apiCall: Api.category.changeProductGroupCategory,
		errorMessage: "Не удалось изменить категорию группы продуктов",
	})

	static setCategoryAsMain = createAutogenApiThunk({
		typePrefix: "new-product/set-category-as-main",
		apiCall: Api.category.setCategoryAsMain,
		errorMessage: "Не удалось установить категорию как основную",
	})

	static getScopesOfApplication = createAutogenApiThunk({
		typePrefix: "new-product/get-scopes",
		apiCall: Api.scopes.getScopes,
		errorMessage: "Не удалось получить список сфер применения",
	})

	static getProductGroupsScopes = createAutogenApiThunk({
		typePrefix: "new-product/get-product-scopes",
		apiCall: Api.scopes.getProductGroupsScopes,
		errorMessage:
			"Не удалось получить список сфер применения группы продуктов",
	})

	static addProductGroupsToScope = createAutogenApiThunk({
		typePrefix: "new-product/add-product-group-to-scope",
		apiCall: Api.scopes.addProductGroupsToScope,
		errorMessage: "Не удалось установить сферу применения группы продуктов",
	})

	static removeProductGroupsFromScope = createAutogenApiThunk({
		typePrefix: "new-product/remove-product-group-from-scope",
		apiCall: Api.scopes.removeProductGroupsFromScope,
		errorMessage: "Не удалось удалить сферу применения группы продуктов",
	})

	static changeProductGroupsScope = createAutogenApiThunk({
		typePrefix: "new-product/change-product-group-scope",
		apiCall: Api.scopes.changeProductGroupsScope,
		errorMessage: "Не удалось изменить сферу применения группы продуктов",
	})

	static changeScopeApplication = createAutogenApiThunk({
		typePrefix: "new-product/change-scope-application-name",
		apiCall: Api.scopes.changeScopeApplication,
		errorMessage: "Не удалось изменить сферу применения ",
	})
	static removeScopeApplication = createAutogenApiThunk({
		typePrefix: "new-product/remove-scope-application",
		apiCall: Api.scopes.removeScopeApplication,
		errorMessage: "Не удалось удалить сферу применения ",
	})
	static addScopeApplication = createAutogenApiThunk({
		typePrefix: "new-product/add-scope-application",
		apiCall: Api.scopes.addScopeApplication,
		errorMessage: "Не удалось добавить сферу применения ",
	})
	static getGroups = createAutogenApiThunk({
		typePrefix: "new-product/get-all-groups",
		apiCall: Api.scopes.getProductsGroups,
		errorMessage: "Не удалось получить список групп продуктов",
	})
	static addAllGroupsToScope = createAutogenApiThunk({
		typePrefix: "new-product/add-product-groups-to-scope",
		apiCall: Api.scopes.addAllGroupsToScope,
		errorMessage: "Не удалось установить сферу применения групп продуктов",
	})
}
