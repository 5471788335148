import { AppState } from "../../../index"

export class ProductPageWebCategorySelectors {
	static getCategories = (state: AppState) =>
		state.pages.productPage.webCategoriesState.categories

	static getSelectedCategories = (state: AppState) =>
		state.pages.productPage.webCategoriesState.selectedCategories

	static getProductCategories = (state: AppState) =>
		state.pages.productPage.webCategoriesState.productCategories

	static getSelectedProductCategory = (state: AppState) =>
		state.pages.productPage.webCategoriesState.selectedProductCategory

	static getShouldReset = (state: AppState) =>
		state.pages.productPage.webCategoriesState.shouldReset
}
