import _ from "lodash"

export {}

type Action<T, TResult> = (
	value: T,
	index: number,
	collection: Array<T>
) => TResult
type Many<T> = T | ReadonlyArray<T>

/*eslint no-extend-native: ["error", { "exceptions": ["Array", "String"] }]*/
declare global {
	interface Array<T> {
		peek(): T | null
		isEmpty(): boolean
		notEmpty(): boolean
		orderBy(predicate: Many<Action<T, unknown>>): Array<T>
		first(predicate?: (val: T) => boolean): T
		firstOrDefault(predicate?: (val: T) => boolean): T | null
	}

	interface String {
		isNullOrEmpty(): boolean
	}
}

Array.prototype.peek = function () {
	if (this.length === 0) return null
	return this[this.length - 1]
}

Array.prototype.notEmpty = function () {
	return this.length !== 0
}

Array.prototype.isEmpty = function () {
	return this.length === 0
}

Array.prototype.orderBy = function <T>(predicate: Many<Action<T, unknown>>) {
	return _.orderBy(this, predicate)
}

Array.prototype.first = function <T>(predicate?: (val: T) => unknown) {
	if (!predicate) return this[0]
	return this.filter(predicate)[0]
}

Array.prototype.firstOrDefault = function <T>(predicate?: (val: T) => unknown) {
	if (this.length === 0) return null
	if (!predicate) return this[0]

	const filtered = this.filter(predicate)
	if (filtered.length === 0) return null
	return filtered[0]
}

String.prototype.isNullOrEmpty = function () {
	const trimmed = this?.trim()
	return trimmed === null || trimmed === undefined || trimmed === ""
}

/*
 * This file is used to initialize all extensions
 */

export function initializeExtension() {
	//do nothing, just init prototypes
}
