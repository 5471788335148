import React from "react"
import LanguageSelector from "../languageSelector/LanguageSelector"
import "./navbar.scss"
import {Link, useSearchParams} from "react-router-dom"
import {useLocation} from "react-router"
import {useDispatch} from "react-redux"
import RouteProvider, {IProductExtendedRoute, IProductRoute} from "../../../utils/RouteProvider"
import {AuthThunks} from "../../../redux/reducers/auth/thunks"
import {SvgIcon} from "../../icons/SvgIcon"


function Navbar() {
    const history = useLocation()
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(AuthThunks.logout())
    }
    const getLink = (pathObject: IProductRoute) => {
        // eslint-disable-next-line no-prototype-builtins
        if (pathObject.hasOwnProperty("generateParameterPath")) {
            const queryObject = {}
            searchParams.forEach((value, key) => {
                queryObject[key] = value
            })

            return (pathObject as IProductExtendedRoute<any>).generateParameterPath(queryObject)
        } else {
            return pathObject.path
        }
    }

    const getParentPath = (pathObject: IProductRoute) => {
        if (pathObject.parentRoute)
            return (
                <>
                    <SvgIcon name="chevron-right" className="shevron-icon"/>
                    <Link to={getLink(pathObject.parentRoute)} className="u-button text small path-link">
                        <span>
                            {pathObject.parentRoute.ruName}
                        </span>
                    </Link>
                    {
                        getParentPath(pathObject.parentRoute)
                    }
                </>
            )
    }


    return (
        <div className={"navbar-wrapper u-droppod"}>


            <div className="path-wrapper">
                <Link to={getLink(RouteProvider.findRoute(history.pathname))} className="u-button text small path-link">
                    <span>
                        {RouteProvider.findRoute(history.pathname)?.ruName}
                    </span>
                </Link>
                {
                    getParentPath(RouteProvider.findRoute(history.pathname))
                }
            </div>
            <div className="buttons-wrapper">
                <button className="u-button large primary">
                    <SvgIcon name="camera" className="link-icon" />
                    <span>Обновить фото</span>
                </button>
                <LanguageSelector/>
                <SvgIcon name="logout" onClick={logout} className="logout-icon" />
            </div>
        </div>
    )
}

export default Navbar