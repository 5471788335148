import { Tab, Tabs } from "react-bootstrap"
import TablePartTab from "./tabs/tablePartTab/TablePartTab"
import DescriptionTab from "./tabs/descriptionTab/DescriptionTab"
import AdditionalInfoTab from "./tabs/additionalInfoTab/AdditionalInfoTab"
import React, { useState } from "react"
import CategoryTab from "./tabs/categoryTab/CategoryTab"
import "./groupTabs.scss"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../redux/reducers"
import {
	actions,
	NewProductState,
	TabKey,
} from "../../../redux/reducers/pages/groupPage"
import { Role } from "../../../domain/Role"
import useAuth from "../../../hooks/authorizationHook"

export default function GroupTabs() {
	const local = useSelector<AppState, NewProductState>(
		(x) => x.pages.productGroupPage.common
	)
	const dispatch = useDispatch()
	const [isCategoryLoaded, setCategoryLoaded] = useState(false)
	const [isTablePartLoaded, setTablePartLoaded] = useState(false)
	const [isGraphicLoaded, setGraphicLoaded] = useState(false)
	const [isAdditionalLoaded, setAdditionalLoaded] = useState(false)

	const canAccessAdditionalPart = useAuth([
		Role.ContentManagerRF,
		Role.ContentManagerLv,
		Role.ContentManagerRg,
		Role.Photographer,
	])

	return (
		<Tabs
			activeKey={local.currentTab}
			onSelect={(k) => dispatch(actions.setSelectedTab(k! as TabKey))}
			className="tabs-wrapper"
		>
			<Tab
				eventKey={TabKey.Graphic.toString()}
				title="Описание"
				className="u-flex-column"
			>
				{local.currentTab === TabKey.Graphic.toString() ||
				isGraphicLoaded ? (
					<div className="u-droppod-scroll-wrapper">
						<DescriptionTab
							onMount={async () => setGraphicLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>
			<Tab
				eventKey={TabKey.TablePart.toString()}
				title="Табличная часть"
				className="u-flex-column"
			>
				{local.currentTab === TabKey.TablePart.toString() ||
				isTablePartLoaded ? (
					<div className="u-droppod-scroll-wrapper">
						<TablePartTab
							onMount={async () => setTablePartLoaded(true)}
						/>
					</div>
				) : (
					<></>
				)}
			</Tab>
			<Tab
				eventKey={TabKey.Category.toString()}
				title="Категории"
				className="u-flex-column"
			>
				{
					//избегаем unmount
					local.currentTab === TabKey.Category.toString() ||
					isCategoryLoaded ? (
						<div className="u-droppod-scroll-wrapper">
							<CategoryTab
								onMount={async () => setCategoryLoaded(true)}
							/>
						</div>
					) : (
						<></>
					)
				}
			</Tab>
			{canAccessAdditionalPart ? (
				<Tab
					eventKey={TabKey.Additional.toString()}
					title="Доп. информация"
					className="u-flex-column"
				>
					{local.currentTab === TabKey.Additional.toString() ||
					isAdditionalLoaded ? (
						<AdditionalInfoTab
							onMount={async () => setAdditionalLoaded(true)}
						/>
					) : (
						<></>
					)}
				</Tab>
			) : null}
		</Tabs>
	)
}
