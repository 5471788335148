import React from "react"
import TreeGroupToolbar from "./TreeGroupToolbar"
import TreeGroupTable from "./TreeGroupTable"

export default function TreeGroupList() {
    return <div className="u-droppod">
        <div className="u-droppod-header">
            <div className="header-text">
                Каталог
            </div>
        </div>
        <TreeGroupToolbar/>
        <TreeGroupTable/>
    </div>
}