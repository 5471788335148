import { AppState } from "../../../index"
import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"

export class ProductPageScopesSelectors {
	static getProductScopes = (state: AppState) =>
		_.orderBy(
			state.pages.productPage.scopesState.productScopes,
			(x) => x.id
		)

	static getScopes = createSelector(
		[
			ProductPageScopesSelectors.getProductScopes,
			(state: AppState) => state.pages.productPage.scopesState.allScopes,
		],
		(productScopes, allScopes) => {
			return allScopes.filter(
				(sc) => !productScopes.some((x) => x.id === sc.id)
			)
		}
	)

	static getSelectedScope = (state: AppState) =>
		state.pages.productPage.scopesState.selectedScope
}
