import AdDefaultInput from "../../../../../../library/inputs/AdDefaultInput"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { ToolsetComponentSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/toolsetComponentSelectors"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent"
import { MessageBusSelectors } from "../../../../../../redux/reducers/messageBus/messageBusSelectors"
import { actions as messageBusActions } from "../../../../../../redux/reducers/messageBus"
import KeysCodes from "../../../../../../utils/KeysCodes"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

const digitRegex = new RegExp("^\\d+$")

interface IToolsetSorterControllerProps {
	toolsetProductId: string
}

export function ToolsetSorterController(props: IToolsetSorterControllerProps) {
	const group = useSelector(GroupPageSelectors.getGroup)
	const selectedToolsetProduct = useSelector(
		ToolsetComponentSelectors.getSelectedToolsetProduct
	)
	const toolsetProducts = useSelector(
		ToolsetComponentSelectors.getToolsetProducts
	)
	const targetSort = useSelector(ToolsetComponentSelectors.getTargetSort)
	const dispatch = useDispatch()

	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlToolsetProducts
	)

	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "tpb-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.ARROWDOWN:
					swapSortByAddition(1)
					break
				case KeysCodes.ARROWUP:
					swapSortByAddition(-1)
					break
				default:
					break
			}
		}
	}, [message.rand])

	const setNewSort = (newSort: string) => {
		dispatch(actions.setNewSort(newSort))
	}

	const onTargetSortKeyUp = (event) => {
		if (event.key === "Enter") {
			const currentProduct = selectedToolsetProduct
			if (currentProduct === null) {
				dispatch(
					informerActions.inform("Выберите продукт для перемещения")
				)
				return
			}
			if (!digitRegex.test(targetSort)) {
				dispatch(
					informerActions.inform("Неправильный формат сортировки")
				)
				return
			}

			const sort = Number(targetSort)
			if (sort === 0) {
				dispatch(
					informerActions.inform("Сортировка должна быть больше нуля")
				)
				return
			}

			const targetProduct = toolsetProducts.find((x) => x.sort === sort)
			const canBeUsed = sort <= toolsetProducts.length
			if (targetProduct === undefined && !canBeUsed) {
				dispatch(
					informerActions.inform("Выходит за пределы сортировки")
				)
				return
			}
			if (targetProduct === currentProduct) return

			dispatch(
				ToolsetComponentThunks.swapSort({
					productGroupId: group.id,
					toolsetId: props.toolsetProductId,
					productId: currentProduct.productId,
					currentSort: currentProduct.sort,
					targetSort: sort,
					targetProductId: targetProduct!.productId,
				})
			)
		}
		if (event.key === "Escape") {
			dispatch(actions.setNewSort(""))
		}
	}

	const swapSortByAddition = async (addition: number) => {
		const currentProduct = selectedToolsetProduct
		if (currentProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для перемещения"))
			return
		}
		const targetSort = currentProduct.sort + addition
		if (targetSort < 1) {
			dispatch(informerActions.inform("Порядковый номер максимальный"))
			return
		}
		if (targetSort > toolsetProducts.length) {
			dispatch(informerActions.inform("Порядковый номер минимальный"))
			return
		}

		/*await dispatch(ToolsetComponentThunks.swapSort({*/
		const targetProduct = toolsetProducts.find((x) => x.sort === targetSort)
		if (targetProduct === undefined) {
			dispatch(
				informerActions.inform("Продукт c целевым номером не найден")
			)
			return
		}

		await dispatch(
			ToolsetComponentThunks.swapSort({
				productGroupId: group.id,
				toolsetId: props.toolsetProductId,
				productId: currentProduct.productId,
				currentSort: currentProduct.sort,
				targetProductId: targetProduct.productId,
				targetSort: targetSort,
			})
		)

		const table = document.getElementById("tpb-table")

		if (table) {
			const current_line: Element = table.getElementsByClassName(
				"table-row--selected"
			)[0]
			const next_line: Element = current_line.nextSibling as Element
			const previous_line: Element =
				(current_line.previousSibling as Element) ||
				table.getElementsByTagName("thead")[0]

			current_line.scrollIntoView({
				block: "nearest",
				behavior: "smooth",
			})
			if (addition < 0) {
				if (!previous_line) return
				previous_line.scrollIntoView({
					block: "nearest",
					behavior: "smooth",
				})
			} else {
				if (!next_line) return
				next_line.scrollIntoView({
					block: "nearest",
					behavior: "smooth",
				})
			}
		}
	}

	return (
		<>
			{canAccess ? (
				<>
					<AdDefaultInput
						noControls={true}
						disabled={!canAccess}
						className="attribute-input"
						placeholder={"№"}
						value={targetSort}
						onChange={setNewSort}
						onKeyUp={(e) => onTargetSortKeyUp(e)}
					/>

					<button
						className={"u-button square primary"}
						onClick={() => swapSortByAddition(-1)}
						disabled={!canAccess}
					>
						<SvgIcon name={"arrow-top"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => swapSortByAddition(1)}
						disabled={!canAccess}
					>
						<SvgIcon name={"arrow-bottom"} />
					</button>
				</>
			) : (
				<></>
			)}
		</>
	)
}
