import React from "react"
import "./productGroupListTable.scss"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/productsPage/groupList"
import { useEffect } from "react"
import { GroupsListThunks } from "../../../redux/reducers/pages/productsPage/groupList/thunk"
import ExpandedProductGroupTable from "../../../library/smartComponents/tables/productGroupTable/ExpandedProductGroupTable"
import { IGroupViewModel } from "../../../library/smartComponents/tables/productGroupTable/types"
import "../../../library/smartComponents/tables/tables-styles.scss"
import { GroupListSelectors } from "../../../redux/reducers/pages/productsPage/groupList/groupListSelectors"
import { PriceGroupSelectors } from "../../../redux/reducers/priceGroups/priceGroupSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import Constants from "../../../domain/Constants"
import { ProductsPageSelectors } from "../../../redux/reducers/pages/productsPage/productsPageSelectors"
import { actions as productPageActions } from "../../../redux/reducers/pages/productsPage"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productsPage/productPageAuthSelectors"
import { WebsiteSelectors } from "../../../redux/reducers/webSites/websiteSelectors"

function ProductGroupListTable() {
	const priceGroup = useSelector(PriceGroupSelectors.getSelected)
	const language = useSelector(LanguageSelectors.getSelected)
	const isGroupsLoading = useSelector(GroupListSelectors.getIsGroupsLoading)
	const filter = useSelector(GroupListSelectors.getFilter)
	const selectedCardType = useSelector(GroupListSelectors.getSelectedCardType)
	const groups = useSelector(
		GroupListSelectors.getFilteredByPriceGroupsMemoized
	)
	const groupStatisticFilter = useSelector(
		GroupListSelectors.getSelectedGroupStatisticFilter
	)
	const checkedPriceGroup = useSelector(
		ProductsPageSelectors.getCheckedPriceGroup
	)
	const isProductsChecked = useSelector(
		ProductsPageSelectors.getIsProductChecked
	)

	const canAddToGroup = useSelector(ProductPageAuthSelectors.canAddProduct)

	const dispatch = useDispatch()
	const website = useSelector(ProductsPageSelectors.getSelectedWebsite)
	const wasWebsitesInit = useSelector(WebsiteSelectors.getWasInit)

	useEffect(() => {
		if (isGroupsLoading) return

		if (priceGroup.id > -1 && wasWebsitesInit) {
			const priceGroupId =
				priceGroup.id === Constants.AllPriceGroupsId
					? null
					: priceGroup.id

			dispatch(
				GroupsListThunks.getGroups({
					priceGroupId: priceGroupId,
					languageId: language.id,
					searchString: filter,
					checkType: selectedCardType.value,
					groupFilter: groupStatisticFilter.value,
					websiteId: website?.id ?? null,
				})
			)
		}
	}, [
		priceGroup.id,
		language.id,
		selectedCardType,
		filter,
		groupStatisticFilter.value,
		website,
		wasWebsitesInit,
	])

	function onSelect(productGroup: IGroupViewModel) {
		dispatch(actions.setSelectedProductGroup(productGroup))

		const isNoGroupOrProductChecked = checkedPriceGroup === null

		if (isNoGroupOrProductChecked) {
			dispatch(
				productPageActions.setChosenPriceGroup(
					productGroup.priceGroupId
				)
			)
		} else if (!isProductsChecked && productGroup.checked) {
			dispatch(productPageActions.setChosenPriceGroup(null))
		}
	}

	function loadProducts(productGroup: IGroupViewModel) {
		dispatch(
			GroupsListThunks.getProductsByGroup({
				productGroupId: productGroup.id,
				languageId: language.id,
			})
		)
	}

	return (
		<div className="product-right-column-table u-table__scroll-wrapper">
			<ExpandedProductGroupTable
				showSortColumn={false}
				isProductGroupsLoading={isGroupsLoading}
				productGroups={groups}
				loadProducts={loadProducts}
				onRowClick={onSelect}
				onCheckBoxClick={onSelect}
				disabled={!canAddToGroup}
			/>
		</div>
	)
}

export default ProductGroupListTable
