import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IRelatedProduct } from "../../domain/models/relatedProducts/IRelatedProduct"
import { IProductIdentity } from "../../domain/models/products/ProductIdentity"

export default class RelatedProductApi extends BaseApi {
	getRelatedProducts = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IRelatedProduct[]>> =>
		this.sendQuery<IRelatedProduct[]>(
			"/api/related-products",
			data,
			actionTypes.get,
			true
		)

	getPossibleRelatedProducts = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/related-products/possible",
			data,
			actionTypes.get,
			true
		)

	addRelatedProduct = async (data: {
		productId: string
		relatedProductsIds: string[]
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/related-products",
			data,
			actionTypes.post,
			true
		)

	removeRelatedProduct = async (data: {
		productId: string
		relatedProductId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/related-products",
			data,
			actionTypes.delete,
			true
		)

	replaceRelatedProduct = async (data: {
		productId: string
		currentRelatedId: string
		targetRelatedId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/related-products/replace",
			data,
			actionTypes.put,
			true
		)

	swapRelatedProductSort = async (data: {
		productId: string
		currentRelatedId: string
		targetRelatedId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/related-products/swap-sort",
			data,
			actionTypes.put,
			true
		)
}
