import React, { useEffect, useState } from "react"
import "./ScopeBlock.scss"
import NullableSelect from "../../../../common/basic/selectors/NullableSelect"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent"
import ToOptionProvider from "../../../../../utils/ToOptionProvider"
import { CategoryTabComponentThunks } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent/thunks"
import _ from "lodash"
import { LanguageSelectors } from "../../../../../redux/reducers/languages/languageSelectors"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { CategoryTabSelectors } from "../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors"
import { Role } from "../../../../../domain/Role"
import useAuth from "../../../../../hooks/authorizationHook"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import { actions as informerActions } from "../../../../../redux/reducers/informer"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../domain/GroupMembership"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import AreaEditorModal from "./AreaEditorModal/AreaEditorModal"
import { GroupListSelectors } from "../../../../../redux/reducers/pages/productsPage/groupList/groupListSelectors"
import { ProductsPageSelectors } from "../../../../../redux/reducers/pages/productsPage/productsPageSelectors"
import { CategoriesPageSelector } from "../../../../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import { IGroupViewModel } from "../../../../../library/smartComponents/tables/productGroupTable/types"

export default function ScopeBlock() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const language = useSelector(LanguageSelectors.getSelected)

	const wasScopesInit = useSelector(CategoryTabSelectors.getIsScopesWasInit)
	const scopes = useSelector(CategoryTabSelectors.getScopes)
	const currentScopes = useSelector(CategoryTabSelectors.getCurrentScopes)
	const selectedScope = useSelector(CategoryTabSelectors.getSelectedScope)
	const selectedCurrentScope = useSelector(
		CategoryTabSelectors.getSelectedCurrentScope
	)

	const dispatch = useDispatch()

	const canControl = useSelector(GroupPageAuthSelectors.getCanControlScopes)

	useEffect(() => {
		dispatch(
			CategoryTabComponentThunks.getScopesOfApplication({
				languageId: language.id,
			})
		)
	}, [language.id])

	useEffect(() => {
		dispatch(
			CategoryTabComponentThunks.getGroups({
				languageId: language.id,
			})
		)
	}, [language])

	useEffect(() => {
		if (wasScopesInit)
			dispatch(
				CategoryTabComponentThunks.getProductGroupsScopes({
					productGroupId: group.id,
				})
			)
	}, [wasScopesInit])

	const setSelectedScope = (id: number | null) =>
		dispatch(actions.setSelectedScope(id))
	const setSelectedCurrentScope = (id: number) =>
		dispatch(actions.setSelectedCurrentScope(id))

	const addProductGroupToScope = () => {
		if (selectedScope === null) {
			dispatch(
				informerActions.inform(
					"Выберите область применения для добавления"
				)
			)
			return
		}
		if (
			currentScopes.find((x) => x.id === selectedScope!.id) !== undefined
		) {
			dispatch(informerActions.inform("Область применения уже добавлена"))
			return
		}
		dispatch(
			CategoryTabComponentThunks.addProductGroupsToScope({
				productGroupId: group.id,
				scopeId: selectedScope.id,
			})
		)
	}

	const changeProductGroupScope = () => {
		if (selectedScope === null) {
			dispatch(
				informerActions.inform(
					"Выберите область применения из списка для изменения"
				)
			)
			return
		}
		if (selectedCurrentScope === null) {
			dispatch(
				informerActions.inform(
					"Выберите область применения в таблице для изменения"
				)
			)
			return
		}

		if (
			currentScopes.find((x) => x.id === selectedScope!.id) !== undefined
		) {
			return
		}

		dispatch(
			CategoryTabComponentThunks.changeProductGroupsScope({
				productGroupId: group.id,
				scopeId: selectedCurrentScope.id,
				newScopeId: selectedScope.id,
			})
		)
	}

	const removeProductGroupFromScope = () => {
		if (!selectedCurrentScope) {
			dispatch(
				informerActions.inform(
					"Выберите область применения в таблице для удаления"
				)
			)
			return
		}

		dispatch(
			CategoryTabComponentThunks.removeProductGroupsFromScope({
				productGroupId: group.id,
				scopeId: selectedCurrentScope.id,
			})
		)
	}

	const openAreaEditorModal = () =>
		dispatch(actions.setIsAreaEditorMenuOpen(true))

	return (
		<>
			<div className="u-droppod-section-header header-with-toolbar">
				Область применения
				{canControl ? (
					<>
						<div className="u-toolbar">
							<div className="toolbar-row">
								<NullableSelect
									value={selectedScope}
									isDisabled={!canControl}
									options={scopes}
									onChange={(value) =>
										setSelectedScope(value as number)
									}
									toOption={ToOptionProvider.scopeToOption}
									className={"selector"}
									height={31}
									placeholder={"Выберите область применения"}
								/>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										addProductGroupToScope()
									}}
								>
									<SvgIcon name="circle-plus" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										changeProductGroupScope()
									}}
								>
									<SvgIcon name="pencil-create" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										removeProductGroupFromScope()
									}}
								>
									<SvgIcon name="circle-x" />
								</button>
								<button
									className="u-button large accent"
									title="Редактировать область"
									onClick={() => openAreaEditorModal()}
								>
									<SvgIcon name="pencil-create" />
									Редактировать область
								</button>
								<AreaEditorModal />
							</div>
						</div>
					</>
				) : null}
			</div>

			<div className="u-table__scroll-wrapper">
				<table className="u-table">
					{currentScopes.isEmpty() ? (
						<tbody className="u-tbody">
							<tr className="top-border">
								<td colSpan={2}>Пока ничего нет</td>
							</tr>
						</tbody>
					) : (
						<>
							<thead className="u-thead">
								<tr>
									<th>Код</th>
									<th>Наименование</th>
								</tr>
							</thead>
							<tbody className="u-tbody">
								{_.orderBy(currentScopes, (x) => x.id).map(
									(x) => {
										return (
											<tr
												key={x.id}
												className={
													x.selected
														? "table-row--selected"
														: ""
												}
												onClick={() => {
													if (!canControl) return
													setSelectedCurrentScope(
														x.id
													)
												}}
											>
												<td>{x.id}</td>
												<td>{x.name}</td>
											</tr>
										)
									}
								)}
								<tr></tr>
							</tbody>
						</>
					)}
				</table>
			</div>
		</>
	)
}
