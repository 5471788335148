import { createAsyncThunk } from "@reduxjs/toolkit"
import Api from "../../../../api"
import { createAutogenApiThunk, executeQuery, executeQueryLite } from "../../../createApiThunkV2"
import { IApplicationResponse } from "../../../../api/baseTypes"
import { ICheckGroupThunkRequest, ICheckGroupThunkResponse, ITaxonomyGroupResponse } from "./type"
import { IProductGroup } from "../../../../domain/types"
import { actions } from "."
import { actions as loaderActions } from "../../informer"
import { ModalStatus } from "../../../../library/modal/AdResultModal"

export class UploadDataToSitesThunk {
	static getNotReadyToDeploy = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/get-not-ready-to-deploy",
		apiCall: Api.websites.getNotReadyToDeploy,
		errorMessage: "Не удалось получить неготовых к выгрузке продуктов",
		showLoader: true,
	})

	static getProductGroup = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/get-product-group",
		apiCall: Api.productGroups.getOrReserve,
		notShowError: true,
		errorMessage: "Не удалось получить информацию о группе продуктов",
	})

	static isGroupOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/is-on-site",
		apiCall: Api.websites.isOnSite,
		errorMessage:
			"Не удалось получить информацию загружена группа на сайт или нет",
	})

	static isShowGroupOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/is-show-on-site",
		apiCall: Api.websites.isShowOnSite,
		errorMessage:
			"Не удалось получить информацию скрыта группа на сайте или нет",
	})

	static checkGroup = createAsyncThunk(
		"upload-data-to-site/check-group",
		async (args: ICheckGroupThunkRequest, thunkAPI) => {
			const { languageId, productGroupId, productsIds, websiteId } = args;

			const payload: ICheckGroupThunkResponse = {
				group: {
					element: null,
					isLoadSuccess: false
				},
				isOnSite: false,
				isShowOnSite: false
			};
			try {
				const groupResponse = await executeQueryLite({
					languageId,
					productGroupId,
					productsIds
				}, Api.productGroups.getOrReserve, thunkAPI.dispatch)

				payload.group.element = groupResponse?.data as IProductGroup;
				payload.group.isLoadSuccess = groupResponse?.success ?? false;

				if (!groupResponse?.success || websiteId == 0)
					return payload;

				const isOnSiteResponse = await executeQueryLite({
					productGroupId,
					websiteId
				}, Api.websites.isOnSite, thunkAPI.dispatch)

				payload.isOnSite = isOnSiteResponse?.data as boolean ?? false;

				if (!groupResponse?.success) {
					thunkAPI.dispatch(
						loaderActions.showLoader({
							status: ModalStatus.error,
							text: "Ошибка при проверке наличия группы на сайте",
						})
					)
					return payload;
				}

				if (!payload.isOnSite)
					return payload

				const isShowOnSiteResponse = await executeQueryLite({
					productGroupId,
					websiteId
				}, Api.websites.isShowOnSite, thunkAPI.dispatch)

				if (!groupResponse?.success) {
					thunkAPI.dispatch(
						loaderActions.showLoader({
							status: ModalStatus.error,
							text: "Ошибка при проверке отображения группы на сайте",
						})
					)
					return payload;
				}

				payload.isShowOnSite = isShowOnSiteResponse?.data as boolean ?? false;

			}
			catch (e: any) {
				console.log("error", e);
			}
			return payload;
		},
	)

	static checkFirstGroupInFile = createAsyncThunk(
		"upload-data-to-site/check-first-group-in-file",
		async (args: any, thunkAPI) => {
			const { productGroupId, websiteId } = args;
			thunkAPI.dispatch(actions.setFileGroupLoading(true))
			thunkAPI.dispatch(actions.setFileOptionIsGroup(true))
			const payload: ITaxonomyGroupResponse = { isShow: false, isGroup: false, isProduct: false }

			try {
				const taxonomyResponse = await executeQueryLite({
					productGroupId,
					websiteId
				}, Api.websites.taxonomyOnSite, thunkAPI.dispatch)

				if (!taxonomyResponse?.success)
					thunkAPI.dispatch(
						loaderActions.showLoader({
							status: ModalStatus.error,
							text: "Ошибка при проверке наличия группы на сайте",
						})
					)

				return taxonomyResponse!.data as ITaxonomyGroupResponse;
			}
			catch (e: any) {
				thunkAPI.dispatch(
					loaderActions.showLoader({
						status: ModalStatus.error,
						text: "Ошибка при проверке наличия группы на сайте",
					})
				)
			}
			thunkAPI.dispatch(actions.setFileGroupLoading(true))
			return payload;
		},
	)

	static showGroupOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/show-on-site",
		apiCall: Api.websites.showOnSite,
		errorMessage:
			"Не удалось обновить отображение группы на сайте",
	})

	static unSowGroupOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/unshow-on-site",
		apiCall: Api.websites.unShowOnSite,
		errorMessage:
			"Не удалось скрыть группу на сайте",
	})

	static changeStatusGoupsOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/change-group-statuses",
		apiCall: Api.websites.changeStatusGoupsOnSite,
		errorMessage: "Не удалось обновить видимость групп на сайте",
		showLoader: true,
		showSuccess: true,
		successMessage: "Видимость групп успешно обновлена",
	})

	// static hideGroupByProductsOnSite = createAutogenApiThunk({
	// 	typePrefix: "upload-data-to-site/hide-by-products",
	// 	apiCall: Api.websites.hideGroupByProductsOnSite,
	// 	errorMessage: "Не удалось обновить видимость для продуктов и групп на сайте",
	// 	showLoader: true,
	// 	showSuccess: true,
	// 	successMessage: "Видимость для продуктов и групп успешно обновлена",
	// })


	static hideGroupByProductsOnSite = createAsyncThunk(
		"upload-data-to-site/hide-by-products",
		async (args: any, thunkAPI) => {
			try {
				const options = {
					typePrefix: "upload-data-to-site/hide-by-products",
					apiCall: Api.websites.hideGroupByProductsOnSite,
					errorMessage: "Не удалось обновить видимость для продуктов и групп на сайте",
					showLoader: true,
					showSuccess: true,
					successMessage: "Видимость для продуктов и групп успешно обновлена",
				};
				const response = await executeQuery(args, options, thunkAPI.dispatch)

				if (response && response.success && response.data && response.data.length > 0) {
					const data: any = response!.data;
					const blob = new Blob([data.join("\n")], { type: 'text/csv;charset=utf-8;' });

					const link = document.createElement("a");
					if (link.download !== undefined) {
						const url = URL.createObjectURL(blob);
						link.setAttribute("href", url);
						link.setAttribute("download", "hide-groups-by-products.csv");
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}

				return;
			}
			catch (e: any) {
				thunkAPI.dispatch(
					loaderActions.showLoader({
						status: ModalStatus.error,
						text: "Не удалось обновить видимость для продуктов и групп на сайте",
					})
				)
			}
			return;
		},
	)

	static addGroupToSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/add-Group-To-Site",
		apiCall: Api.websites.addGroupToSite,
		//errorMessage: "Не удалось добавить группу на сайт",
	})

	static removeGroupFromSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/remove-group-from-site",
		apiCall: Api.websites.removeFromSite,
		//errorMessage: "Не удалось удалить группу с сайта",
	})

	static addCatalogToSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/add-catalog-to-site",
		apiCall: Api.websites.addCatalogToSite,
		errorMessage: "Не удалось добавить каталог на сайт",
		showLoader: true,
	})

	static removeCatalogFromSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/remove-catalog-to-site",
		apiCall: Api.websites.removeCatalogFromSite,
		errorMessage: "Не удалось удалить каталог с сайта",
		showLoader: true,
	})

	static refreshCatalogOnSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/refresh-catalog-on-site",
		apiCall: Api.websites.refreshCatalogOnSite,
		errorMessage: "Не удалось обновить каталог на сайте",
		showLoader: true,
		showSuccess: true,
		successMessage: "Каталог успешно обновлен",
	})

	static fillWebsiteTable = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/fill-website-table",
		apiCall: Api.websites.fillWebsiteTable,
		errorMessage: "Не удалось заполнить таблицу сайта",
		showLoader: true,
		showSuccess: true,
		successMessage: "Таблица сайта успешно заполнена",
	})

	static partialWebsiteRefresh = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/partial-website-refresh",
		apiCall: Api.websites.partialWebsiteRefresh,
		errorMessage: "Не удалось частично обновить сайт",
		showLoader: true,
		showSuccess: true,
		successMessage: "Сайт успешно обновлен",
	})

	static refreshProductSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/refresh-product-site",
		apiCall: Api.productSites.uploadCatalog,
		errorMessage:
			"Не удалось обновить Автотех. Обратитесь к администратору!",
		showLoader: true,
		showSuccess: true,
		successMessage: "Сайт Автотех успешно обновлен",
	})

	static removeProductFromSite = createAutogenApiThunk({
		typePrefix: "upload-data-to-site/remove-product-to-site",
		apiCall: Api.websites.removeProductFromSite,
		errorMessage: "Не удалось удалить продукты с сайта.",
		showLoader: true,
		showSuccess: true,
		successMessage: "Продукты успешно удалены",
	})
}
