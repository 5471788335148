import React from "react"
import {SvgIcon} from "../icons/SvgIcon"
import "./loader.scss"

export default function Loader () {

    return (
        <div className="loader-wrapper">
            <SvgIcon name="loader" className="loader-icon" />
        </div>
    )
}