import Api from "../../../../../api" 
import {createAutogenApiThunk} from "../../../../createApiThunkV2"

export class GroupsListThunks {
    static getGroups = createAutogenApiThunk({
        typePrefix: "product-page/get-product-groups",
        apiCall: Api.productGroups.getProductsGroupsIdentity,
        errorMessage: "Не удалось получить список групп продуктов"
    })

    static getProductsByGroup = createAutogenApiThunk({
        typePrefix: "product-page/get-product-by-group",
        apiCall: Api.products.getProductsIdentityByGroup,
        errorMessage: "Не удалось получить список продуктов группы"
    })

    static updateGroupImages = createAutogenApiThunk({
        typePrefix: "product-page/update-images",
        apiCall: Api.productGroups.updateProductGroupImages,
        errorMessage: "Не удалось обновить изображения"
    })
}