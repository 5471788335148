import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IProductWithAttributes } from "../../domain/types"
import { ProductNameAttributeStrategy } from "../../domain/ProductNameAttributeStrategy"
import { IProductIdentity } from "../../domain/models/products/ProductIdentity"
import { IProductInfo } from "../../domain/models/products/ProductInfo"
import { IProductAttribute } from "../../domain/models/products/ProductAttribute"
import { GroupStatisticFilter } from "../../domain/GroupStatisticFilter"

export default class ProductApi extends BaseApi {
	getProduct = (args: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductInfo>> =>
		this.sendQuery<IProductInfo>(
			"/api/product",
			args,
			actionTypes.get,
			true
		)

	getProductAttributes = (args: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductAttribute[]>> =>
		this.sendQuery<IProductAttribute[]>(
			"/api/product/product-attributes",
			args,
			actionTypes.get,
			true
		)

	updateProduct = (args: {
		productId: string
		name: string
		isImagesChecked: boolean
		isDescriptionChecked: boolean
		languageId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>("/api/product", args, actionTypes.put, true)

	deleteProduct = (args: {
		productId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>("/api/product", args, actionTypes.delete, true)

	getProductsIdentityWithoutGroup = (args: {
		priceGroupId: number | null
		languageId: number
		statisticFilter: GroupStatisticFilter
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/product/identity/no-group",
			args,
			actionTypes.get,
			true
		)

	getProductsIdentityByGroup = (data: {
		productGroupId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/product/identity/by-group",
			data,
			actionTypes.get,
			true
		)

	getProductsIdentityByGroupPriceGroup = (data: {
		productGroupId: string
		search: string
		languageId: number
	}): Promise<IApplicationResponse<IProductIdentity[]>> =>
		this.sendQuery<IProductIdentity[]>(
			"/api/product/identity/by-group-price-group",
			data,
			actionTypes.get,
			true
		)

	addProductToGroup = async (data: {
		productGroupId: string
		productIds: string[]
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/add-product-to-group",
			data,
			actionTypes.post,
			true
		)

	removeProductFromGroup = async (data: {
		productGroupId: string
		productId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/remove-product-from-group",
			data,
			actionTypes.delete,
			true
		)

	replaceProductsInGroup = async (data: {
		productGroupId: string
		productId: string
		newProductId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/replace-products-in-group",
			data,
			actionTypes.put,
			true
		)

	changeAttributesValues = async (data: {
		productGroupId: string
		values: { attributeId: number; value: string; productId: string }[]
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/change-attribute-value-in-group",
			data,
			actionTypes.put,
			true
		)

	swapProductSort = async (data: {
		productGroupId: string
		firstProductId: string
		secondProductId: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/swap-product-sort",
			data,
			actionTypes.put,
			true
		)

	getProductsWithAttributes = async (data: {
		productGroupId: string
		languageId: number
	}): Promise<IApplicationResponse<IProductWithAttributes[]>> =>
		this.sendQuery<IProductWithAttributes[]>(
			"/api/product/products-with-attr/by-groups",
			data,
			actionTypes.get,
			true
		)

	generateShortNames = async (data: {
		attributeNameStrategy: ProductNameAttributeStrategy
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product/generate-short-names",
			data,
			actionTypes.put,
			true
		)
}
