import {AppState} from "../../index" 

export class guideSelectors {
    static getData = (state: AppState) => state.guide.data
    static getParentCategories= (state: AppState) => state.guide.parentCategories
    static getPictograms = (state: AppState) => state.guide.pictograms
    static getAttributes = (state: AppState) => state.guide.attributes
    static getSeries = (state: AppState) => state.guide.series
    static getSigns = (state: AppState) => state.guide.signs
    static getLoaded = (state: AppState) => state.guide.loaded
    static getCreated = (state: AppState) => state.guide.created
    static getOptions = (state: AppState) => state.guide.options
    static getSelectedGroup = (state: AppState) => state.guide.selectedGroup
}