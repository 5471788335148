import Api from "../../../../../../api"
import {createAutogenApiThunk} from "../../../../../createApiThunkV2"

export class ToolsetComponentThunks {
    static getToolsetProducts = createAutogenApiThunk({
        typePrefix: "new-product/get-toolset-products",
        apiCall: Api.toolsets.getToolsetProducts,
        errorMessage: "Не удалось получить список комплектации"
    })

    static getPossibleToolsetProducts = createAutogenApiThunk({
        typePrefix: "new-product/get-products-without-group",
        apiCall: Api.toolsets.getPossibleToolsetProducts,
        errorMessage: "Не удалось получить список комплектации"
    })

    static addProductsToToolset = createAutogenApiThunk({
        typePrefix: "new-product/add-products-to-toolset",
        apiCall: Api.toolsets.addProductsToToolset,
        isOptimistic: true,
        errorMessage: "Не удалось добавить продукты в комплектацию. Произошла ошибка. Состояние блока \"Комплектация\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeAssignment = createAutogenApiThunk({
        typePrefix: "new-product/change-toolset-assignment",
        apiCall: Api.toolsets.changeAssignment,
        isOptimistic: true,
        errorMessage: "Не удалось изменить принадлежность продукта к комплектации. Произошла ошибка. Состояние блока \"Комплектация\" имеет невалидное значение. Перезагрузите страницу."
    })

    static removeProductFromToolset = createAutogenApiThunk({
        typePrefix: "new-product/remove-products-from-toolset",
        apiCall: Api.toolsets.removeProductFromToolset,
        isOptimistic: true,
        errorMessage: "Не удалось удалить продукт из комплектации. Произошла ошибка. Состояние блока \"Комплектация\" имеет невалидное значение. Перезагрузите страницу."
    })

    static swapSort = createAutogenApiThunk({
        typePrefix: "new-product/swap-toolset-sort",
        apiCall: Api.toolsets.swapSort,
        isOptimistic: true,
        errorMessage: "Не удалось изменить порядок сортировки продуктов в комплектации. Произошла ошибка. Состояние блока \"Комплектация\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changePictGroup = createAutogenApiThunk({
        typePrefix: "new-product/change-pict-group",
        apiCall: Api.toolsets.changePictGroup,
        isOptimistic: true,
        errorMessage: "Не удалось изменить пиктограммы комплектации. Произошла ошибка. Состояние блока \"Комплектация\" имеет невалидное значение. Перезагрузите страницу."
    })

    static getPriceGroupProducts = createAutogenApiThunk({
        typePrefix: "new-product/get-products-by-price-group-for-toolsets",
        apiCall: Api.products.getProductsIdentityByGroupPriceGroup,
        errorMessage: "Не удалось получить список продуктов для комплектации"
    })

    static getPictograms = createAutogenApiThunk({
        typePrefix: "new-product/get-pictograms-for-toolsets",
        apiCall: Api.pictograms.getAllPictograms,
        errorMessage: "Не удалось получить список пиктограмм"
    })
}

