import Menubar from "../../library/smartComponents/menubar/Menubar"
import { Navigate, Route, Routes } from "react-router-dom"
import Products from "../products/Products"
import React from "react"
import "./layout.scss"
import CategoriesPage from "../categories/CategoriesPage"
import Tree from "../tree/Tree"
import Translate from "../translates/Translate"
import Group from "../group/Group"
import Bullfacts from "../bullfacts/Bullfacts"
import Recommendations from "../recommendations/Recommendations"
import AllProductsTable from "../allProducts/AllProductsTable"
import ToolsetProducts from "../toolsetProduct/ToolsetProducts"
import TranslatorPage from "../translator-page/TranslatorPage"
import CompareContent from "../compareContent/CompareContent"
import UploadDataToWebsite from "../uploadDataToWebsite/UploadDataToWebsite"
import RouteProvider from "../../utils/RouteProvider"
import AdminForm from "../admin/AdminForm"
import Navbar from "../../library/smartComponents/navbar/Navbar"
import ProductPage from "../productPage/ProductPage"
import Watchtower from "../watchtower/Watchtower"
import PreviewPdf from "../previewPdf/PreviewPdf"

function Layout() {
	return (
		<>
			{
				<div className="layout">
					<Menubar />
					<div className={"layout-main"}>
						<Navbar />
						<div className={"layout-body"}>
							<Routes>
								<Route
									path={RouteProvider.products.path}
									element={<Products />}
								/>
								<Route
									path={RouteProvider.categories.path}
									element={<CategoriesPage />}
								/>
								<Route
									path={RouteProvider.tree.path}
									element={<Tree />}
								/>
								<Route
									path={RouteProvider.translate.path}
									element={<Translate />}
								/>
								<Route
									path={RouteProvider.productGroup.path}
									element={<Group />}
								/>
								<Route
									path={RouteProvider.productGroup.path}
									element={<Group />}
								/>
								<Route
									path={RouteProvider.products.path}
									element={<ProductPage />}
								/>
								<Route
									path={RouteProvider.recommendations.path}
									element={<Recommendations />}
								/>
								<Route
									path={RouteProvider.bullfacts.path}
									element={<Bullfacts />}
								/>
								<Route
									path={RouteProvider.possibleProducts.path}
									element={<AllProductsTable />}
								/>
								<Route
									path={
										RouteProvider.possibleToolsetProducts
											.path
									}
									element={<ToolsetProducts />}
								/>
								<Route
									path={RouteProvider.translatorForm.path}
									element={<TranslatorPage />}
								/>
								<Route
									path={RouteProvider.compareContent.path}
									element={<CompareContent />}
								/>
								<Route
									path={
										RouteProvider.uploadDataToWebsite.path
									}
									element={<UploadDataToWebsite />}
								/>
								<Route
									path={RouteProvider.productPage.path}
									element={<ProductPage />}
								/>
								<Route
									path={RouteProvider.System.path}
									element={<AdminForm />}
								/>
								<Route
									path={RouteProvider.Watchtower.path}
									element={<Watchtower />}
								/>
								<Route
									path={RouteProvider.allAnother.path}
									element={
										<Navigate
											to={RouteProvider.products.path}
											replace
										/>
									}
								/>
								<Route
									path={RouteProvider.generatePreviewPdf.path}
									element={<PreviewPdf />} />
							</Routes>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default Layout
