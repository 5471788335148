import Api from "../../../../../api"
import {createAutogenApiThunk} from "../../../../createApiThunkV2"

export class AdditionalTabThunks {
    static getAllRecommendation = createAutogenApiThunk({
        typePrefix: "new-product/get-all-rec",
        apiCall: Api.recommendations.getAllRecommendations,
        errorMessage: "Не удалось получить список всех потенциальных рекомендаций"
    })

    static getGroupRecommendation = createAutogenApiThunk({
        typePrefix: "new-product/get-group-rec",
        apiCall: Api.recommendations.getGroupRecommendations,
        errorMessage: "Не удалось получить список рекомендаций группы"
    })

    static addRecommendations = createAutogenApiThunk({
        typePrefix: "new-product/add-rec",
        apiCall: Api.recommendations.addRecommendations,
        isOptimistic: true,
        errorMessage: "Не удалось добавить рекомендации. Состояние блока \"Рекомендации продукта\" имеет невалидное значение. Перезагрузите страницу."
    })

    static removeRecommendation = createAutogenApiThunk({
        typePrefix: "new-product/remove-rec",
        apiCall: Api.recommendations.removeRecommendation,
        isOptimistic: true,
        errorMessage: "Не удалось удалить рекомендацию. Состояние блока \"Рекомендации продукта\" имеет невалидное значение. Перезагрузите страницу."
    })

    static swapRecommendationSort = createAutogenApiThunk({
        typePrefix: "new-product/change-sort-rec",
        apiCall: Api.recommendations.swapRecommendationSort,
        isOptimistic: true,
        errorMessage: "Не удалось изменить порядок рекомендаций. Состояние блока \"Рекомендации продукта\" имеет невалидное значение. Перезагрузите страницу."
    })

    static changeRecommendation = createAutogenApiThunk({
        typePrefix: "new-product/change-rec",
        apiCall: Api.recommendations.changeRecommendation,
        isOptimistic: true,
        errorMessage: "Не удалось изменить рекомендацию. Состояние блока \"Рекомендации продукта\" имеет невалидное значение. Перезагрузите страницу."
    })

    static getProductGroupCatalogs = createAutogenApiThunk({
        typePrefix: "new-product/get-group-catalogs",
        apiCall: Api.catalogs.getProductGroupCatalogs,
        errorMessage: "Не удалось получить список каталогов группы"
    })

    static isOnSite = createAutogenApiThunk({
        typePrefix: "new-product/is-on-site",
        apiCall: Api.websites.isOnSite,
        errorMessage: "Не удалось получить информацию о наличии группы на сайте"
    })

    static addGroupToSite = createAutogenApiThunk({
        typePrefix: "new-product/add-to-site",
        apiCall: Api.websites.addGroupToSite,
        showSuccess: true,
        showLoader: true,
        successMessage: "Группа успешно добавлена на сайт",
        //errorMessage: "Не удалось добавить группу на сайт",
    })

    static removeGroupFromSite = createAutogenApiThunk({
        typePrefix: "new-product/remove-from-site",
        apiCall: Api.websites.removeFromSite,
        showSuccess: true,
        showLoader: true,
        successMessage: "Группа успешно удалена с сайта",
        //errorMessage: "Не удалось удалить группу с сайта",
    })

    static changeShowStatus = createAutogenApiThunk({
        typePrefix: "new-product/change-show-status",
        apiCall: Api.catalogs.changeShowStatus,
        errorMessage: "Не удалось изменить статус отображения группы"
    })
}
