import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    addProductGroupToCatsThunk,
    getProductGroupCatsThunk,
    getProductGroupsByCatalogsThunk,
    getProductsByGroupThunk
} from "./thunk"
import {
    IGroupViewModel
} from "../../../../library/smartComponents/tables/productGroupTable/types"
import { IGroupWithCategories, IImagesCatalogGroups } from "../../../../domain/types"
import QueryTrimmer from "../../../../utils/QueryTrimmer"
import BaseType from "../../../../domain/models/BaseModel"

const queryTrimmer = new QueryTrimmer()

export type ImageSettingsState = {
    isShow: boolean,
    group: BaseType | null,
    items: IImagesCatalogGroups
}

export type CategoryComponentState = {
    groupFilter: string,
    newCategoryName: string,
    isGroupsLoading: boolean
    productGroups: IGroupViewModel[]
    selectedGroups: IGroupViewModel[]
    groupCategories: IGroupWithCategories[],
    isAddingGroupToCategory: boolean
}

const INITIAL_STATE: CategoryComponentState = {
    groupFilter: "",
    newCategoryName: "",
    isGroupsLoading: false,
    productGroups: [],
    selectedGroups: [],
    groupCategories: [],
    isAddingGroupToCategory: false
}

const categorySlice = createSlice({
    name: "categoryPage",
    initialState: INITIAL_STATE,
    reducers: {
        setFilter(state: CategoryComponentState, action: PayloadAction<string>) {
            state.groupFilter = action.payload
            return state
        },
        setNewCategoryName(state: CategoryComponentState, action: PayloadAction<string>) {
            state.newCategoryName = action.payload
        },
        removeProductGroupWithCatPath(state: CategoryComponentState, action: PayloadAction<string>) {
            state.groupCategories = state.groupCategories.filter(x => x.productGroupId !== action.payload)
        },
        setSelectedProductGroup(state: CategoryComponentState, action: PayloadAction<IGroupViewModel>) {
            const selectedIndex = state.selectedGroups.findIndex(x => x.id === action.payload.id)
            const index = state.productGroups.findIndex(x => x.id === action.payload.id)

            if (selectedIndex > -1) {
                state.selectedGroups.splice(selectedIndex, 1)
                state.groupCategories.filter(x => x.productGroupId != state.productGroups[index].id)
                state.groupCategories = state.groupCategories.filter(x => x.productGroupId !== action.payload.id)
            }
            else {
                state.selectedGroups.push(action.payload)
            }
            if (index > -1) {
                state.productGroups[index].checked = !action.payload.checked
            }
        },
        clearStateOnUnmount(state: CategoryComponentState) {
            state.productGroups = []
            state.selectedGroups = []
            state.groupCategories = []
            state.groupFilter = ""
        }
    },
    extraReducers: builder => {
        builder.addCase(getProductGroupsByCatalogsThunk.pending, (state, action) => {
            queryTrimmer.addQuery(action.meta.requestId)
            state.isGroupsLoading = true

        })
        builder.addCase(getProductGroupsByCatalogsThunk.fulfilled, (state, action) => {
            if (queryTrimmer.shouldBeProcessed(action.meta.requestId)) {
                state.selectedGroups = []
                state.productGroups = action.payload.map(x => {
                    return {
                        id: x.id,
                        name: x.name,
                        checked: false,
                        products: null,
                        isDescriptionChecked: x.isDescriptionChecked,
                        isLoading: false,
                        isImageChecked: x.isImageChecked,
                        sort: null,
                        isLastActive: false,
                        shouldHighlight: false,
                        priceGroupId: x.priceGroupId,
                    }
                })
                state.isGroupsLoading = false
                state.groupCategories = []
            }
        })

        builder.addCase(getProductGroupsByCatalogsThunk.rejected, (state) => {
            state.isGroupsLoading = false
        })

        builder.addCase(getProductsByGroupThunk.pending, (state, action) => {
            const index = state.productGroups.findIndex(x => x.id === action.meta.arg.productGroupId)
            if (index >= 0)
                state.productGroups[index].isLoading = true
        })

        builder.addCase(getProductsByGroupThunk.fulfilled, (state, action) => {
            const index = state.productGroups.findIndex(x => x.id === action.meta.arg.productGroupId)
            if (index >= 0) {
                state.productGroups[index].products = action.payload
                state.productGroups[index].isLoading = false
            }
        })
        builder.addCase(getProductsByGroupThunk.rejected, (state, action) => {
            const index = state.productGroups.findIndex(x => x.id === action.meta.arg.productGroupId)
            if (index >= 0)
                state.productGroups[index].isLoading = false
        })

        builder.addCase(getProductGroupCatsThunk.fulfilled, (state, action) => {
            state.groupCategories.push(...action.payload)
        })
        builder.addCase(addProductGroupToCatsThunk.pending, (state, action) => {
            //удаляем присвоенные группы из списка
            state.productGroups = state.productGroups.filter(x => action.meta.arg.productGroupIds.findIndex(id => id === x.id) === -1)
            state.selectedGroups = []
            state.isAddingGroupToCategory = true
        })
        builder.addCase(addProductGroupToCatsThunk.fulfilled, (state) => {
            state.isAddingGroupToCategory = false
        })
        builder.addCase(addProductGroupToCatsThunk.rejected, (state) => {
            state.isAddingGroupToCategory = false
        })
    }
})


const actions = categorySlice.actions
const reducer = categorySlice.reducer

export { actions, reducer }


