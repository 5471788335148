import {ModalStatus} from "../../../library/modal/AdResultModal"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface InformerState {
    status: ModalStatus
    text: string,
    shouldShow: boolean
}

const INITIAL_STATE: InformerState = {
    status: ModalStatus.success,
    text: "",
    shouldShow: false
}

const loaderSlice = createSlice({
    name: "loader",
    initialState: INITIAL_STATE,
    reducers: {
        showLoader(state: InformerState, action: PayloadAction<{status: ModalStatus, text: string}>) {
            const {status, text} = action.payload

            state.shouldShow = true
            state.status = status
            state.text = text
        },
        inform(state: InformerState, action: PayloadAction<string>) {
            state.shouldShow = true
            state.status = ModalStatus.info
            state.text = action.payload
        },
        closeLoader() {
            return {...INITIAL_STATE}
        }
    }
})

const reducer = loaderSlice.reducer
const actions = loaderSlice.actions

export {reducer, actions}