import { AppState } from "../../../index"
import { createSelector } from "@reduxjs/toolkit"
import { FileStatus } from "../../../../../domain/models/files/FileStatus"

export class ProductPageImageSelectors {
	static getImages = (state: AppState) =>
		state.pages.productPage.imagesState.images

	static getFilteredImageTypes = createSelector(
		[
			(state: AppState) => state.pages.productPage.imagesState.imageTypes,
			ProductPageImageSelectors.getImages,
		],
		(imageTypes, images) =>
			imageTypes.filter((x) => !images.some((y) => y.typeId === x.id))
	)

	static getImageTypes = (state: AppState) =>
		state.pages.productPage.imagesState.imageTypes

	static getImagesToLoad = (state: AppState) =>
		state.pages.productPage.imagesState.imagesToLoad

	static getPossibleFileTypesMask = (state: AppState) =>
		state.pages.productPage.imagesState.possibleFileTypesMask

	static getImagesToUpload = (state: AppState) =>
		state.pages.productPage.imagesState.imagesToLoad.filter(
			(x) => x.loadStatus === FileStatus.Loaded
		)

	static getCanUploadImages = createSelector(
		[ProductPageImageSelectors.getImagesToLoad],
		(images) =>
			images.length > 0 &&
			!images.some((x) => x.loadStatus === FileStatus.MoreInfoNeeded) &&
			images.some((x) => x.loadStatus === FileStatus.Loaded)
	)

	static getLoadingImagesCount = (state: AppState) =>
		state.pages.productPage.imagesState.loadingImagesCount

	static getLoadedImagesCount = (state: AppState) =>
		state.pages.productPage.imagesState.loadedImagesCount

	static getDeletedImages = (state: AppState) =>
		state.pages.productPage.imagesState.deletedImages
}
