import React, { useEffect } from "react"
import "./PictogramBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import { GraphicComponentsThunks } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/thunks"
import ToOptionProvider from "../../../../../utils/ToOptionProvider"
import { LanguageSelectors } from "../../../../../redux/reducers/languages/languageSelectors"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { GraphicComponentSelectors } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/graphicComponentSelectors"
import useAuth from "../../../../../hooks/authorizationHook"
import { Role } from "../../../../../domain/Role"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import { PictogramsSelectors } from "../../../../../redux/reducers/pictograms/pictogramsSelectors"
import PictogramSelector from "../../../../../library/smartComponents/pictogramSelector/PictogramSelector"
import SimpleSelect from "../../../../common/basic/selectors/SimpleSelect"
import { actions as informerActions } from "../../../../../redux/reducers/informer"
import {
	actions,
	IPictogramViewModel,
} from "../../../../../redux/reducers/pages/groupPage/graphicComponent"
import { actions as pictogramActions } from "../../../../../redux/reducers/pictograms"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../domain/GroupMembership"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API

export default function PictogramBlock() {
	const language = useSelector(LanguageSelectors.getSelected)
	const group = useSelector(GroupPageSelectors.getGroup)
	const pictograms = useSelector(PictogramsSelectors.getPictograms)
	const filteredPictograms = useSelector(
		PictogramsSelectors.getFilteredPictograms
	)
	const pictogram = useSelector(PictogramsSelectors.getSelected)
	const groupPictograms = useSelector(
		GraphicComponentSelectors.getGroupPictograms
	)

	const canControlPictograms = useSelector(
		GroupPageAuthSelectors.getCanControlPictograms
	)

	const dispatch = useDispatch()

	useEffect(() => {
		if (language.id > 0) {
			dispatch(
				GraphicComponentsThunks.getGroupPictograms({
					productGroupId: group.id,
					languageId: language.id,
				})
			)
		}
	}, [language.id])

	useEffect(() => {
		if (!canControlPictograms) return
		dispatch(pictogramActions.setFilter(groupPictograms.map((x) => x.id)))
	}, [groupPictograms])

	const addPictogram = () => {
		if (!canControlPictograms) return
		if (!pictogram) {
			dispatch(
				informerActions.inform("Выберите пиктограмму для добавления")
			)
			return
		}
		if (groupPictograms.findIndex((x) => x.id === pictogram!.id) > -1) {
			dispatch(informerActions.inform("Пиктограмма уже добавлена"))
			return
		}
		dispatch(
			GraphicComponentsThunks.addPictogramToGroup({
				productGroupId: group.id,
				pictogramId: pictogram.id,
			})
		)
	}

	const removePictogram = (pictogram: IPictogramViewModel) => {
		if (!canControlPictograms) return
		if (!pictogram) {
			dispatch(informerActions.inform("Произошла ошибка"))
			return
		}
		dispatch(
			GraphicComponentsThunks.removePictogramFromGroup({
				pictogramId: pictogram.id,
				productGroupId: group.id,
			})
		)
	}

	const changePictogram = (pictogram: IPictogramViewModel, newId: number) => {
		if (!canControlPictograms) return
		dispatch(
			actions.setNewPictogram({
				pictogram: pictogram,
				newPictogramId: newId,
			})
		)
	}

	const updatePictogram = (pictogram: IPictogramViewModel) => {
		if (!canControlPictograms) return
		dispatch(
			GraphicComponentsThunks.changeGroupPictogram({
				pictogramId: pictogram.id,
				newPictogramId: pictogram.selectedPictogramId,
				productGroupId: group.id,
			})
		)
	}

	return (
		<div className="">
			<div className="pictogram-block">
				<div className="wrapper-grid">
					<div className={"pictogram-block-wrapper"}>
						<PictogramSelector disabled={!canControlPictograms} />
						{pictogram ? (
							<>
								<img
									className="pictogram-image"
									src={`${staticServer}/${pictogram.imageUrl}`}
									alt={`${pictogram.name}`}
								/>
								<button
									className={"u-button large primary"}
									disabled={!canControlPictograms}
									onClick={addPictogram}
								>
									Добавить пиктограмму
								</button>
							</>
						) : null}
					</div>
					{groupPictograms.notEmpty()
						? groupPictograms.map((x) => {
								const selected = pictograms.find(
									(p) => p.id == x.selectedPictogramId
								)
								return selected ? (
									<div
										className={"pictogram-block-wrapper"}
										key={x.id}
									>
										<SimpleSelect
											value={selected}
											options={filteredPictograms}
											onChange={(e) =>
												changePictogram(x, e)
											}
											toOption={
												ToOptionProvider.pictogramToOption
											}
											placeholder={"Выберите пиктограмму"}
											isDisabled={!canControlPictograms}
										/>
										<img
											className="pictogram-image"
											src={`${staticServer}/${x.imageUrl}`}
											alt={x.name}
										/>
										{!canControlPictograms ? null : (
											<div className={"control-buttons"}>
												{x.selectedPictogramId !=
												x.id ? (
													<SvgIcon
														name={"pencil-create"}
														className="edit-image"
														onClick={() =>
															updatePictogram(x)
														}
													/>
												) : null}
												<SvgIcon
													name={"circle-x"}
													className="remove-image"
													onClick={() =>
														removePictogram(x)
													}
												/>
											</div>
										)}
									</div>
								) : null
						  })
						: null}
				</div>
			</div>
		</div>
	)
}
