import {IWebsite} from "../../../domain/types"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {WebsiteThunks} from "./thunks"
import _ from "lodash"

export type WebSitesState = {
    websites: IWebsite[],
    selected: IWebsite,
    wasInit: boolean
};

const initialWebsite: IWebsite = {
    id: 0,
    name: "Loading",
    schema: -1
}

const INITIAL_STATE: WebSitesState = {
    websites: [initialWebsite],
    selected: initialWebsite,
    wasInit: false
}

const slice = createSlice({
    name: "websites",
    initialState: INITIAL_STATE,
    reducers: {
        setSelected(state: WebSitesState, action: PayloadAction<number>){
            state.selected = state.websites.find(x => x.id === action.payload) ?? state.selected
        }
    },
    extraReducers: builder  => {
        builder.addCase(WebsiteThunks.getWebsites.fulfilled, (state, {payload}) => {
            if(payload.length === 0) {
                state.websites.first().name = "Все сайты не активны"
                state.selected = state.websites.first()
            }
            else {
                state.websites = _.orderBy(payload, x => x.name)
                state.selected = state.websites.first()
            }
            state.wasInit = true
        })
        builder.addCase(WebsiteThunks.getWebsites.rejected, (state, action) => {
            console.error(`Cant download price groups: Status code: '${action.payload?.statusCode}'. Text: '${action.payload?.exception}'`)
        })
    }
})

const reducer = slice.reducer
const actions = slice.actions

export {reducer, actions}