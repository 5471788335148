import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/reducers/pages/translatePage"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import "./TranslateSettings.scss"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { LanguagesThunks } from "../../redux/reducers/languages/thunk"
import { TranslateSelectors } from "../../redux/reducers/pages/translatePage/translateSelectors"
import Protector from "../../library/smartComponents/authorization/Protector"
import { Role } from "../../domain/Role"
import useAuth from "../../hooks/authorizationHook"
import AdDefaultInput from "../../library/inputs/AdDefaultInput"
import { TranslateThunks } from "../../redux/reducers/pages/translatePage/thunks"
import RouteProvider from "../../utils/RouteProvider"
import { SvgIcon } from "../../library/icons/SvgIcon"
import { actions as informerActions } from "../../redux/reducers/informer"

export function TranslateSettings() {
	const hasManagerAccess = useAuth([Role.ContentManagerLv, Role.ContentManagerRg])
	const hasManagerAccessLv = useAuth([Role.ContentManagerLv])

	const languages = useSelector(LanguageSelectors.getLanguages)
	const newLangName = useSelector(TranslateSelectors.getNewLanguage)
	const languageToRemove = useSelector(TranslateSelectors.getLanguageToRemove)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(actions.setLanguageToRemove(languages.first()))
	}, [languages.length])

	const openTranslatorPage = () => {
		if (!hasManagerAccess) return

		const url = RouteProvider.translatorForm.path
		window.open(url, "_blank")
	}

	const generateNewTranslate = () => {
		if (!hasManagerAccess) return
		dispatch(TranslateThunks.generateNewTranslates())
	}

	const openBullfacts = () =>
		window.open(RouteProvider.bullfacts.path, "_blank")

	const openCompareContentPage = () => {
		if (!hasManagerAccess) return
		const url = RouteProvider.compareContent.path
		window.open(url, "_blank")
	}

	const setNewLanguage = (newLanguage: string) =>
		dispatch(actions.setNewLanguage(newLanguage))

	const setLanguageToRemove = (languageId: number) => {
		const language = languages.find((x) => x.id === languageId)
		dispatch(actions.setLanguageToRemove(language!))
	}

	async function addNewLanguage() {
		if (newLangName.trim().length < 1) {
			dispatch(informerActions.inform("Введите название языка"))
			return
		}

		dispatch(LanguagesThunks.addLanguage({ language: newLangName }))
	}

	async function removeLanguage() {
		if (languageToRemove.id < 1) {
			dispatch(informerActions.inform("Выберите язык"))
			return
		}
		await dispatch(
			LanguagesThunks.removeLanguage({ languageId: languageToRemove.id })
		)
		actions.setLanguageToRemove(languages.first())
	}

	async function cleanUpTranslations() {
		await dispatch(LanguagesThunks.cleanUpTranslations(null))
	}

	return (
		<Protector
			availableRoles={[Role.ContentManagerLv, Role.Translator, Role.ContentManagerRg]}
			justHide={true}
		>
			<div className="translate-settings u-flex-column">
				<div className="languages u-droppod">
					<div className="u-droppod-header">
						<div className="header-text">Справочники</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "0.625em",
							}}
						>
							<button
								hidden={!hasManagerAccess}
								className="u-button large accent"
								onClick={() => generateNewTranslate()}
							>
								<SvgIcon name="server" className="link-icon" />
								Обновить справочники
							</button>
						</div>
					</div>
					<div className="u-droppod-scroll-wrapper">
						<div className="u-droppod-section-header">Переводы</div>

						<div style={{ display: "flex" }}>
							<button
								className="u-button large primary"
								onClick={() => openBullfacts()}
							>
								Справочник переводов
							</button>
							<button
								hidden={!hasManagerAccess}
								className="u-button large text"
								onClick={() => cleanUpTranslations()}
							>
								<SvgIcon name="server" className="link-icon" />
								Очистить переводы
							</button>
						</div>
						<button
							className="u-button large primary"
							hidden={!hasManagerAccess}
							onClick={() => openTranslatorPage()}
						>
							Форма для переводчика
						</button>

						<div hidden={!hasManagerAccess}>
							<div className="u-droppod-section-header">
								Контент
							</div>
							<button
								className="u-button large primary"
								hidden={!hasManagerAccess}
								onClick={() => openCompareContentPage()}
							>
								Сравнить
							</button>
							<div className="u-droppod-info">
								Сравнение контета сайта с блоком каталогов.
							</div>
						</div>
					</div>
				</div>

				<div className="languages u-droppod" hidden={!hasManagerAccessLv}>
					<div className="u-droppod-header">
						<div className="header-text">Языки</div>
					</div>
					<div className="u-droppod-scroll-wrapper">
						<div className="u-droppod-section-header">
							Добавить язык
						</div>
						<div className="language-with-button-wrapper">
							<AdDefaultInput
								placeholder="Язык"
								value={newLangName}
								onChange={setNewLanguage}
							/>
							<button
								className="u-button large primary"
								onClick={async () => await addNewLanguage()}
							>
								Добавить
							</button>
						</div>

						<div className="u-droppod-section-header">
							Удалить язык
						</div>
						<div className="language-with-button-wrapper">
							<SimpleSelect
								value={languageToRemove}
								options={languages}
								onChange={setLanguageToRemove}
								toOption={ToOptionProvider.languageToOption}
								className={"selector"}
							/>
							<button
								className="u-button large primary"
								onClick={async () => await removeLanguage()}
							>
								Удалить
							</button>
						</div>
					</div>
				</div>
			</div>
		</Protector>
	)
}
