import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import {
	IImagesCatalogGroups as IImagesCatalogCategories,
	IProductGroup,
	IProductGroupIdentity,
	IProductGroupIdentityWithCategory,
} from "../../domain/types"
import { GroupStatisticFilter } from "../../domain/GroupStatisticFilter"

export default class ProductGroupApi extends BaseApi {
	updateProductGroup = async (data: {
		id: string
		name: string
		description: string
		descriptionWeb: string
		seriesId: number | null
		signId: number | null
		sellmarkId: number | null
		priceGroupId: number | null
		mainAttributeId: number | null
		isToolset: boolean
		isImageChecked: boolean
		isDescriptionChecked: boolean
		siteId: number | null
		languageId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-group/update",
			data,
			actionTypes.put,
			true
		)

	getProductsGroupsIdentity = async (data: {
		priceGroupId: number | null
		languageId: number
		searchString: string
		checkType: number
		groupFilter: GroupStatisticFilter
		websiteId: number | null
	}): Promise<IApplicationResponse<IProductGroupIdentity[]>> =>
		this.sendQuery<IProductGroupIdentity[]>(
			"/api/product-group/identity",
			data,
			actionTypes.get,
			true
		)

	getProductsGroupsFromCatalog = async (data: {
		priceGroupId: number
		languageId: number
		searchString: string
		distributionType: number
		catalogGroup: number
		catalogId: number
		sellmarkId: number
	}): Promise<IApplicationResponse<IProductGroupIdentity[]>> =>
		this.sendQuery<IProductGroupIdentity[]>(
			"/api/product-group/identity/by-catalog",
			data,
			actionTypes.get,
			true
		)

	getProductsGroupsByCategory = async (data: {
		languageId: number
		searchString: string
		catalogGroup: number
		validationType: number
		catalogId: number | null
		categoryId: number
	}): Promise<
		IApplicationResponse<{
			products: IProductGroupIdentityWithCategory[]
			minSort: number
			maxSort: number
		}>
	> =>
		this.sendQuery<{
			products: IProductGroupIdentityWithCategory[]
			minSort: number
			maxSort: number
		}>(
			"/api/product-group/identity/by-category",
			data,
			actionTypes.get,
			true
		)

	getOrReserve = async (data: {
		languageId: number
		productGroupId: string | null
		productsIds: string[] | null
	}): Promise<IApplicationResponse<IProductGroup>> =>
		this.sendQuery<IProductGroup>(
			"/api/product-group/get-or-reserve",
			data,
			actionTypes.post,
			true
		)

	createProductGroup = async (data: {
		id: string
		autoId: string
		isToolset: boolean
		name: string
		seriesId: number | null
		signId: number | null
		sellmarkId: number | null
		mainAttributeId: number | null
		languageId: number | null
		productIds: string[] | undefined
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-group/create",
			data,
			actionTypes.post,
			true
		)

	deleteProductGroup = async (data: {
		id: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-group",
			data,
			actionTypes.delete,
			true
		)

	discardReserve = async (data: {
		id: string
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-group/discard-reserve",
			data,
			actionTypes.delete,
			true
		)

	containsProduct = async (data: {
		productGroupId: string
		productId: string
	}): Promise<IApplicationResponse<boolean>> =>
		this.sendQuery<boolean>(
			"/api/product-group/contains-product",
			data,
			actionTypes.get,
			true
		)

	updateProductGroupImages = async (data: {
		priceGroupId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/product-group/update-group-image",
			data,
			actionTypes.put,
			true
		)
}
