import { actions } from "../redux/reducers/messageBus"
import { Dispatch } from "@reduxjs/toolkit"

enum KeysCodes {
	ENTER = "Enter",
	ESCAPE = "Escape",
	ARROWUP = "ArrowUp",
	ARROWDOWN = "ArrowDown",
	ARROWRIGHT = "ArrowRight",
	ARROWLEFT = "ArrowLeft",
	DELETE = "Delete",
}

export function keyDownMessage(
	e: React.KeyboardEvent<HTMLTableElement>,
	id: string | null,
	dispatch: Dispatch<any>
): void {
	console.log()
	if (Object.values(KeysCodes).includes(e.key as KeysCodes)) {
		e.preventDefault()
	}
	dispatch(actions.setMessage({ table_id: id, key: e.key }))
}

export function isEnter(e: KeyboardEvent): boolean {
	return e.key === KeysCodes.ENTER
}

export function isEscape(e: KeyboardEvent): boolean {
	return e.key === KeysCodes.ESCAPE
}

export function isAnyOfDefaultKeys(e: KeyboardEvent): boolean {
	return isEnter(e) || isEscape(e)
}

export function isAnyOfKeys(e: KeyboardEvent, keys: KeysCodes[]): boolean {
	return keys.some((x) => e.key === x)
}

export default KeysCodes
