import { combineReducers } from "redux"
import { reducer as loginComponentReducer } from "./loginPage"
import { reducer as productsPageReducer } from "./productsPage"
import { reducer as categoryReducer } from "./categoryPage"
import { reducer as treeReducer } from "./treePage"
import { reducer as translateReducer } from "./translatePage"
import { reducer as newProductReducer } from "./groupPage"
import { reducer as recommendationReducer } from "./recommendationsPage"
import { reducer as bullfactReducer } from "./bullfactsPage"
import { reducer as allProductsReducer } from "./allProductsPage"
import { reducer as toolsetProductsReducer } from "./toolsetProductsPage"
import { reducer as translatorPageReducer } from "./translatorPage"
import { reducer as compareContentReducer } from "./compareContentPage"
import { reducer as uploadDataToWebsiteReducer } from "./uploadDataToWebsitePage"
import { reducer as productPageReducer } from "./productPage"
import { reducer as watchtower } from "./watchtower"
import { reducer as guide } from "./guidePage"

const pagesReducer = combineReducers({
	loginPage: loginComponentReducer,
	categoryPage: categoryReducer,
	treePage: treeReducer,
	translatePage: translateReducer,
	productGroupPage: newProductReducer,
	recommendationPage: recommendationReducer,
	bullfactsPage: bullfactReducer,
	allProductPage: allProductsReducer,
	toolsetProductsPage: toolsetProductsReducer,
	translatorPage: translatorPageReducer,
	compareContentPage: compareContentReducer,
	uploadDataToWebsitePage: uploadDataToWebsiteReducer,
	productsPage: productsPageReducer,
	productPage: productPageReducer,
	watchtower: watchtower,
	guide: guide
})

export default pagesReducer
