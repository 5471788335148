import { IImageType } from "../../domain/models/imageTypes/ImageType"
import { INamedImage } from "../../domain/models/images/NamedImage"
import React, { useEffect, useState } from "react"
import "./AdImages.scss"
import AdDragAndDrop from "../drapAndDrop/AdDragAndDrop"
import { SvgIcon } from "../icons/SvgIcon"
import { AdVideoModal } from "./AdVideoModal"
import AdFileLoadModal from "../modal/AdFileLoadModal"
import { FileStatus } from "../../domain/models/files/FileStatus"
import ToOptionProvider from "../../utils/ToOptionProvider"
import NullableSelect from "../../app/common/basic/selectors/NullableSelect"
import IFileLoadModel from "../../domain/models/files/FileLoadModel"
import { toNumber } from "lodash"
import Constants from "../../domain/Constants"
import YoutubePlayer from "./YoutubePlayer"
import { Guid } from "guid-typescript"
import SimpleSelect from "../../app/common/basic/selectors/SimpleSelect"
import { FileNameLabelProvider } from "../../utils/FileNameLabelProvider"
import { SvgTag } from "../icons/SvgTag"
import { DateTimeLocaleProvider } from "../../utils/DateTimeLocaleProvider"
import { IShortEcmFileDescription } from "../../domain/models/files/EcmFileDescription"
import { getNameAndExtension } from "../../utils/FileUtils"
import { createPortal } from "react-dom"
import { Modal } from "react-bootstrap"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API

export default function AdImages(props: {
	allImageTypes: IImageType[]
	filteredImageTypes: IImageType[]
	images: INamedImage[]
	imageToLoad: IFileLoadModel[]
	addImageToLoad: (file: IShortEcmFileDescription) => void
	setImageToLoadType: (
		file: IShortEcmFileDescription,
		imageType: number
	) => void
	uploadImages: () => void
	updateImageType: (image: INamedImage, newTypeId: number) => void
	deleteImage: (image: INamedImage) => void
	uploadVideo: (url: string) => void
	canEdit: boolean
	canAdd: boolean
	possibleFileTypeMask: string
	loadingImagesCount: number
	loadedImagesCount: number
	canUploadImages: boolean
	deletedImages: INamedImage[]
	onModalClose: () => void
	downloadImages: () => void
	canAddVideo: boolean
	canLoadFiles: boolean
	canEditFiles: boolean
	canLoadImage: boolean
	canAddImages: boolean
}) {
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
	const [showVideoModal, setShowVideoModal] = useState(false)
	const [showImageModal, setShowImageModal] = useState(
		props.imageToLoad.length > 0
	)

	useEffect(() => {
		return () => {
			closeImageModal()
		}
	}, [])

	useEffect(() => {
		if (props.imageToLoad.length > 0) {
			setShowImageModal(true)
		} else {
			setShowImageModal(false)
		}
	}, [props.imageToLoad])

	const addImagesToLoad = (files: FileList) => {
		if (!props.canAdd) return

		for (const file of files) {
			const [filename, extension] = getNameAndExtension(file.name)
			if (props.imageToLoad.some((x) => x.file.name === filename))
				continue

			props.addImageToLoad({
				name: filename,
				extension: extension,
			})
		}
		setShowImageModal(true)
	}

	const uploadImages = () => {
		props.uploadImages()

		setTimeout(() => {
			closeImageModal()
		}, 3000)
	}

	const closeImageModal = () => {
		if (
			props.imageToLoad.length > props.loadingImagesCount &&
			props.loadingImagesCount > 0
		)
			return
		setShowImageModal(false)
		props.onModalClose()
	}

	return (
		<div className={"ad-images u-flex-column"} style={{ gap: "0.625em" }}>
			<div
				className="u-droppod-section-header header-with-toolbar"
				style={{ marginTop: 0, marginBottom: "0.625em" }}
			>
				Графическая часть
				<div className="u-toolbar">
					<div className="toolbar-row">
						<button
							type="button"
							className="u-button large primary"
							onClick={() => setShowVideoModal(true)}
							disabled={!props.canAdd || !props.canAddVideo}
						>
							<SvgIcon name={"video"} className={"link-icon"} />
							Добавить видео
						</button>
						{!isEditModeEnabled ? (
							<button
								type="button"
								className="u-button square primary"
								onClick={() => setIsEditModeEnabled(true)}
								disabled={!props.canAdd || !props.canEditFiles}
							>
								<SvgIcon
									name={"pencil-create"}
									className={"u-icon"}
								/>
							</button>
						) : (
							<button
								type="button"
								className="u-button large primary"
								onClick={() => setIsEditModeEnabled(false)}
								disabled={!props.canAdd}
							>
								Выйти из режима редактирования
							</button>
						)}
						<button
							type="button"
							className="u-button large primary"
							onClick={() => props.downloadImages()}
							disabled={!props.canLoadFiles}
						>
							<SvgIcon
								name={"download"}
								className={"link-icon"}
							/>
							Загрузить файлы
						</button>
					</div>
				</div>
			</div>
			{/*
			<div style={{ fontSize: "var(--size-header-6)" }}>Изображения</div>
*/}
			<AdDragAndDrop
				onFileAdded={addImagesToLoad}
				fileType={props.possibleFileTypeMask}
				multiple={true}
				canAddImages={props.canAddImages}
			/>
			<div className={"image-blocks-grid"}>
				<ImageList
					allImageTypes={props.allImageTypes}
					filteredImageTypes={props.filteredImageTypes}
					deletedImages={props.deletedImages}
					images={props.images}
					updateImageType={props.updateImageType}
					deleteImage={props.deleteImage}
					isEditable={isEditModeEnabled}
				/>
				<AdVideoModal
					closeModal={() => setShowVideoModal(false)}
					show={showVideoModal}
					uploadVideo={props.uploadVideo}
				/>
				{createPortal(
					<AdFileLoadModal
						isOpen={showImageModal}
						loadedFilesCount={props.loadedImagesCount}
						loadingFilesCount={props.loadingImagesCount}
						addFileToLoad={addImagesToLoad}
						possibleFileTypeMask={props.possibleFileTypeMask}
						canAddImages={props.canAddImages}
					>
						<AdFileLoadModalBody
							canUploadImages={props.canUploadImages}
							files={props.imageToLoad}
							imageTypes={props.filteredImageTypes}
							uploadFiles={uploadImages}
							setImageToLoadType={props.setImageToLoadType}
							onClose={closeImageModal}
							canGetLoadImage={props.canLoadImage}
						/>
					</AdFileLoadModal>,
					document.body
				)}
			</div>
		</div>
	)
}

function AdFileLoadModalBody(props: {
	files: IFileLoadModel[]
	imageTypes: IImageType[]
	uploadFiles: () => void
	setImageToLoadType: (
		file: IShortEcmFileDescription,
		imageType: number
	) => void
	canUploadImages: boolean
	onClose: () => void
	canGetLoadImage: boolean
}) {
	const getStatusInfo = (
		status: FileStatus
	): [statusText: string, statusColor: string] => {
		let statusText = ""
		let statusColor = ""
		switch (status) {
			case FileStatus.Loaded:
				statusText = "Загружено"
				statusColor = "green"
				break
			case FileStatus.Error:
				statusText = "Ошибка"
				statusColor = "red"
				break
			case FileStatus.MoreInfoNeeded:
				statusText = "Выберите тип изображения"
				statusColor = "orange"
				break
			case FileStatus.LoadedToServer:
				statusText = "Сохранено"
				statusColor = "green"
				break
		}

		return [statusText, statusColor]
	}

	return (
		<>
			{props.files.map((file) => {
				const [statusText, statusColor] = getStatusInfo(file.loadStatus)
				return (
					<div
						key={file.file.name}
						className={`file-load-modal-body-item ${statusColor}`}
					>
						<>
							<SvgIcon
								name={"loader"}
								className={"big-icon load"}
							/>
							<SvgIcon
								name={"circle-checked"}
								className={"big-icon good"}
							/>
							<SvgIcon
								name={"circle-x"}
								className={"big-icon bad"}
							/>
						</>
						<div className={"info-holder"}>
							{file.file.name}
							<div className={"status-text"}>{statusText}</div>
							{file.loadStatus === FileStatus.MoreInfoNeeded ? (
								<>
									<NullableSelect
										options={props.imageTypes}
										isMenuTop={true}
										onChange={(imageTypeId) =>
											props.setImageToLoadType(
												file.file,
												toNumber(imageTypeId)
											)
										}
										placeholder={"Тип изображения"}
										value={null}
										toOption={
											ToOptionProvider.imageTypeToOption
										}
									/>
								</>
							) : (
								<></>
							)}
						</div>
						<div className={"time"}>
							{new Date().toLocaleTimeString([], {
								hour: "2-digit",
								minute: "2-digit",
							})}
						</div>
					</div>
				)
			})}
			{!props.canUploadImages ? (
				<></>
			) : (
				<>
					<button
						className={"u-button large accent"}
						onClick={() => props.uploadFiles()}
						disabled={!props.canGetLoadImage}
					>
						Сохранить
					</button>
				</>
			)}
			<button
				className={"u-button outline large"}
				onClick={() => props.onClose()}
			>
				Закрыть
			</button>
		</>
	)
}

function ImageList(props: {
	filteredImageTypes: IImageType[]
	allImageTypes: IImageType[]
	images: INamedImage[]
	deletedImages: INamedImage[]
	isEditable: boolean
	updateImageType: (image: INamedImage, newTypeId: number) => void
	deleteImage: (image: INamedImage) => void
}) {
	return (
		<>
			{props.allImageTypes.length > 0 ? (
				props.images.map((image) => {
					return image.typeId == Constants.YoutubeImageType ? (
						<YoutubeWrapper
							image={image}
							key={image.name}
							isEditable={props.isEditable}
							deleteImage={props.deleteImage}
						/>
					) : (
						<Image
							key={image.name}
							image={image}
							filteredImageTypes={props.filteredImageTypes}
							allImageTypes={props.allImageTypes}
							isEditable={props.isEditable}
							changeImageType={props.updateImageType}
							deleteImage={props.deleteImage}
						/>
					)
				})
			) : (
				<></>
			)}
			{props.deletedImages.map((image) => {
				return (
					<div key={image.name} className={"deleted-block"}>
						<div>{image.name}</div>
						<div>Файл удален</div>
					</div>
				)
			})}
		</>
	)
}

function YoutubeWrapper(props: {
	image: INamedImage
	isEditable: boolean
	deleteImage: (image: INamedImage) => void
}) {
	const goToYoutube = (url: string) => {
		window.open(url, "_blank")
	}

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false)

	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<div
			className={"youtube-player-preview block-wrapper"}
			key={props.image.imageUrl}
		>
			{props.isEditable ? (
				<div className={"selectors-edit-wrapper"}></div>
			) : null}
			<div className={"image-wrapper"}>
				<img
					key={props.image.name}
					alt={props.image.name}
					src={`https://img.youtube.com/vi/${props.image.imageUrl
						.split("/")
						.pop()}/default.jpg`}
					className={"middle-block__ala-image"}
				/>
				<div className={"tags-wrapper"}>
					<SvgTag
						name={"link"}
						onClick={() => goToYoutube(props.image.imageUrl)}
						className={"tag"}
					/>
					<SvgTag name={"youtube"} className={"tag"} />
				</div>
				{props.isEditable ? (
					<SvgIcon
						name={"close"}
						className={"u-icon in-round close-i"}
						onClick={() => {
							setIsDeleteConfirmationDialogOpen(true)
						}}
					/>
				) : (
					<></>
				)}
				{/*<div className={"share-wrapper"}>
								<SvgIcon
									name={"download"}
									className={"u-icon in-round"}
								/>
								<SvgIcon
									name={"share"}
									className={"u-icon in-round"}
								/>
							</div>*/}

				<SvgIcon
					name={"play-circle"}
					onClick={() => setIsModalOpen(true)}
					className={"big-icon play-circle"}
				/>
			</div>

			<div className={"texts"}>
				<div className={"image-name"}>Видео</div>
				<div className={"image-date"}>
					{new Date(props.image.modifyDate).toLocaleDateString(
						DateTimeLocaleProvider.getDefaultLocale(),
						DateTimeLocaleProvider.getDefaultLocaleOptions()
					)}
				</div>
			</div>

			{isDeleteConfirmationDialogOpen ? (
				<DeleteImageConfirmationDialog
					onCancel={() => setIsDeleteConfirmationDialogOpen(false)}
					onConfirm={() => {
						setIsDeleteConfirmationDialogOpen(false)
						props.deleteImage(props.image)
					}}
				/>
			) : (
				<></>
			)}

			<Modal
				show={isModalOpen}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className={"youtube-modal"}
			>
				<Modal.Body>
					<SvgIcon
						name={"close"}
						className={"u-icon close-video"}
						onClick={() => {
							setIsModalOpen(false)
						}}
					/>
					<YoutubePlayer url={props.image.imageUrl} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

function Image(props: {
	image: INamedImage
	filteredImageTypes: IImageType[]
	allImageTypes: IImageType[]
	isEditable: boolean
	changeImageType: (image: INamedImage, newTypeId: number) => void
	deleteImage: (image: INamedImage) => void
}) {
	const guid = Guid.create()
	const imageType = props.allImageTypes.find(
		(x) => x.id === props.image.typeId
	)
	if (imageType === undefined) throw new Error("imageType is undefined")

	const [isModalOpen, setIsModalOpen] = useState(false)

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false)

	const extensionsTagName = FileNameLabelProvider.getImageLabelTagName(
		props.image.extension
	)

	const imageTypeTagName = FileNameLabelProvider.getImageTypeTagName(
		imageType.id
	)

	return (
		<div className={"block-wrapper"}>
			{props.isEditable ? (
				<div className={"selectors-edit-wrapper"}>
					<SimpleSelect
						value={imageType}
						toOption={ToOptionProvider.imageTypeToOption}
						options={props.filteredImageTypes}
						onChange={(newTypeId) =>
							props.changeImageType(props.image, newTypeId)
						}
						placeholder={"Выберите тип"}
					/>{" "}
				</div>
			) : null}

			<div className={"image-wrapper"}>
				<img
					className="middle-block__ala-image"
					key={props.image.name}
					src={`${staticServer}/${props.image.imageUrl}?${guid}`}
					alt={props.image.typeId.toString()}
					onClick={() => setIsModalOpen(true)}
				/>
				{props.isEditable ? (
					<></>
				) : (
					<div className={"tags-wrapper"}>
						<SvgTag name={extensionsTagName} />
						<SvgTag name={imageTypeTagName} />
					</div>
				)}
				{props.isEditable ? (
					<SvgIcon
						name={"close"}
						className={"u-icon in-round close-i"}
						onClick={() => {
							setIsDeleteConfirmationDialogOpen(true)
						}}
					/>
				) : (
					<></>
				)}
			</div>

			<div className={"texts"}>
				<div className={"image-name"}>{props.image.name}</div>
				<div className={"image-date"}>
					{new Date(props.image.modifyDate).toLocaleDateString(
						DateTimeLocaleProvider.getDefaultLocale(),
						DateTimeLocaleProvider.getDefaultLocaleOptions()
					)}
				</div>
			</div>

			{isDeleteConfirmationDialogOpen ? (
				<DeleteImageConfirmationDialog
					onCancel={() => setIsDeleteConfirmationDialogOpen(false)}
					onConfirm={() => {
						setIsDeleteConfirmationDialogOpen(false)
						props.deleteImage(props.image)
					}}
				/>
			) : (
				<></>
			)}

			<Modal
				show={isModalOpen}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className={"youtube-modal"}
			>
				<Modal.Body>
					<SvgIcon
						name={"close"}
						className={"u-icon close-video"}
						onClick={() => {
							setIsModalOpen(false)
						}}
					/>
					<img
						src={`${staticServer}/${props.image.imageUrl}?${guid}`}
						alt={props.image.typeId.toString()}
					/>
				</Modal.Body>
			</Modal>
		</div>
	)
}

function DeleteImageConfirmationDialog(props: {
	onCancel: () => void
	onConfirm: () => void
}) {
	return (
		<div className={"delete-image-confirmation"}>
			<div className={"header"}>Вы уверены, что хотите удалить файл?</div>
			<br />
			Данное действие нельзя отменить.
			<div className={"buttons-wrapper"}>
				<button
					onClick={props.onCancel}
					className={"u-button small outline"}
				>
					Назад
				</button>
				<button
					onClick={props.onConfirm}
					className={"u-button small accent"}
				>
					Удалить
				</button>
			</div>
		</div>
	)
}
