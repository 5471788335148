import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {numericRestrictions} from "../../../../utils/regexpUtlis"
import {CatalogGroup, ICardDistributionType, ICategorySortBorder} from "../../../../domain/types"
import {TreeThunks} from "./thunks"
import {IGroupWithCatViewModel} from "../../../../library/smartComponents/tables/productGroupTable/types"
import _ from "lodash"

export type TreePageState = {
    groups: IGroupWithCatViewModel[],
    selectedGroups: IGroupWithCatViewModel[],
    categoriesSortBorders: ICategorySortBorder[],
    minSort: number,
    maxSort: number,
    filter: string,
    isProductGroupsLoading: boolean,
    sortNumber: string,
    selectedCardType: ICardDistributionType,
    cardTypes: ICardDistributionType[],
}

const INITIAL_STATE: TreePageState = {
    selectedGroups: [],
    groups: [],
    categoriesSortBorders: [],
    minSort: -1,
    maxSort: -1,
    filter: "",
    isProductGroupsLoading: false,
    sortNumber: "",
    cardTypes: [
        {label: "Все карточки", value: 0},
        {label: "Непроверенное описание", value: 1},
        {label: "Непроверенное фото", value: 2},
        {label: "Проверено все", value: 3}
    ],
    selectedCardType: {label: "Все карточки", value: 0},
}

const sortValidationRegex = numericRestrictions()

const slice = createSlice({
    name: "treePage/groupList",
    initialState: INITIAL_STATE,
    reducers: {
        setFilter(state: TreePageState, action: PayloadAction<string>) {
            state.filter = action.payload.toLowerCase()
        },
        setSortNumber(state: TreePageState, action: PayloadAction<string>) {
            const noRestrictions = sortValidationRegex.test(action.payload)
            if(noRestrictions) {
                state.sortNumber = action.payload
            }
        },
        setSelectedCardType(state: TreePageState, action: PayloadAction<number>) {
            state.selectedCardType = state.cardTypes.find(x => x.value === action.payload) ?? state.selectedCardType
        },
        clearSelected(state: TreePageState) {
            state.selectedGroups = []
        },
        setSelectedProductGroup(state: TreePageState, action: PayloadAction<IGroupWithCatViewModel>) {
            const group = state.groups.find(x => x.id === action.payload.id)
            if(!group)
                return
            if(group.id === state.selectedGroups.peek()?.id) {
                state.selectedGroups.pop()
                group.checked = false
                group.isLastActive = false

                const previousId = state.selectedGroups.peek()?.id
                const previous = state.groups.find(x => x.id === previousId)
                if(previous) {
                    previous.isLastActive = true
                    state.sortNumber = previous.sort?.toString() ?? ""
                } else {
                    state.sortNumber = ""
                }
                return
            }

            if(state.selectedGroups.some(x => x.id === group.id)) {
                state.selectedGroups = state.selectedGroups.filter(x => x.id !== group.id)
                group.checked = false

                return
            }

            const previousId = state.selectedGroups.peek()?.id
            const previous = state.groups.find(x => x.id === previousId)
            if(previous){
                previous.isLastActive = false
            }
            state.selectedGroups.push(group)
            group.checked = true
            group.isLastActive = true
            state.sortNumber = group.sort?.toString() ?? ""
        },
        setProductGroupsEmpty(state: TreePageState) {
            state.isProductGroupsLoading = false
            state.groups = []
            state.sortNumber = ""
            state.selectedGroups = []
        },
        clearStateOnUnmount() {
            return {...INITIAL_STATE}
        }
    },
    extraReducers: builder => {
        builder.addCase(TreeThunks.getGroups.pending, (state) => {
            state.isProductGroupsLoading = true
        })
        builder.addCase(TreeThunks.getGroups.fulfilled, (state, action) => {
            state.sortNumber = ""
            if(action.meta.arg.catalogGroup === CatalogGroup.Web) {
                state.groups = action.payload.products.map(x => {
                    return {...x,
                        checked: false,
                        isLoading: false,
                        products: null,
                        isLastActive: false,
                        shouldHighlight: false,
                        catalogCategoryId: null}
                })
            }
            else {
                const grouped = action.payload.products.reduce((r, a) => {
                    r[a.categoryId!] = r[a.categoryId!] || []
                    console.log(a)
                    r[a.categoryId!].push(a)
                    return r
                }, Object.create(null))
                const keys = Object.keys(grouped)
                state.groups = []
                for(let i = 0; i < keys.length; i++) {
                    //const shouldHighlight = i % 2 !== 0;
                    const key = keys[i]
                    const groups = grouped[key]
                    for (const group of groups) {
                        state.groups.push({
                            id: group.id,
                            name: group.name,
                            checked: false,
                            isImageChecked: group.isImageChecked,
                            isLoading: false,
                            products: null,
                            isDescriptionChecked: group.isDescriptionChecked,
                            sort: group.sort,
                            isLastActive: false,
                            catalogCategoryId: Number(key),
                            priceGroupId: group.priceGroupId,
                        })
                    }
                }
            }

            state.minSort = action.payload.minSort
            state.maxSort = action.payload.maxSort

            state.isProductGroupsLoading = false
        })
        builder.addCase(TreeThunks.getGroups.rejected, (state) => {
            state.isProductGroupsLoading = false
            state.groups = []
            state.minSort = 0
            state.maxSort = 0
        })
        builder.addCase(TreeThunks.getProductByGroups.pending, (state, action) => {
            const {productGroupId} = action.meta.arg
            const group = state.groups.find(x => x.id === productGroupId)
            if(group) {
                group.isLoading = true
            }
        })
        builder.addCase(TreeThunks.getProductByGroups.fulfilled, (state, action) => {
            const {productGroupId} = action.meta.arg
            const group = state.groups.find(x => x.id === productGroupId)
            if(group) {
                group.products = action.payload
                group.isLoading = false
            }
        })
        builder.addCase(TreeThunks.getProductByGroups.rejected, (state, action) => {
            const {productGroupId} = action.meta.arg
            const group = state.groups.find(x => x.id === productGroupId)
            if(group) {
                group.isLoading = false
            }
        })

        builder.addCase(TreeThunks.recountSort.pending, (state) => {
            state.isProductGroupsLoading = true
        })

        builder.addCase(TreeThunks.recountSort.fulfilled, (state, action) => {
            state.groups.forEach(pg => {
                const pgSort = action.payload.find(x => x.id === pg.id)
                if(pgSort) {
                    pg.sort = pgSort.sort
                }
            })
            state.isProductGroupsLoading = false
        })

        builder.addCase(TreeThunks.recountSort.rejected, (state) => {
            state.isProductGroupsLoading = false
        })

        builder.addCase(TreeThunks.changeSort.pending, (state, action) => {
            const {productGroupId, targetSort, currentSort} = action.meta.arg
            const cur = state.groups.find(x => x.id === productGroupId)!
            const target = state.groups.find(x => x.sort === targetSort)

            if(currentSort === 0) {
                for (const productGroup of state.groups) {
                    if(productGroup.sort! >= targetSort)
                        productGroup.sort! += 1
                }
                cur.sort = targetSort
            }
            else {
                const targetSort = target!.sort!
                const currentSort = cur.sort!
                const diff = Math.abs(cur.sort! - target!.sort!)
                if(diff > 1) {
                    const groupsToChange = state.groups
                        .filter(x => currentSort > targetSort
                            ? x!.sort! >= targetSort
                            : x!.sort! > currentSort)
                        .filter(x => currentSort > targetSort
                            ? x!.sort! < currentSort
                            : x!.sort! <= targetSort)
                    const additional = currentSort > targetSort ? 1 : -1
                    for (const group of groupsToChange) {
                        group.sort! += additional
                    }
                    cur.sort = targetSort
                }
                else {
                    cur.sort = targetSort
                    target!.sort = currentSort
                }
            }

            state.selectedGroups.peek()!.sort = targetSort
            state.sortNumber = targetSort.toString()
        })

        builder.addCase(TreeThunks.removeGroupFromCat.pending, (state, action) => {
            const {productGroupIds} = action.meta.arg
            const removedGroups = _.orderBy(state.groups.filter(x => productGroupIds.findIndex(id => x.id === id) > -1), x => x.sort)

            for (const removedGroup of removedGroups) {
                for (const productGroup of state.groups) {
                    if(productGroup.sort! > removedGroup.sort!) {
                        productGroup.sort! -= 1
                    }
                }
            }

            //удаляем присвоенные группы из списка
            state.groups = state.groups.filter(x => productGroupIds.findIndex(id => id === x.id) === -1)
            state.sortNumber = ""
        })

        builder.addCase(TreeThunks.getCategoriesSortBorders.fulfilled, (state, {payload: categoryBorders}) => {
            state.categoriesSortBorders = categoryBorders
        })
    }
})

const actions = slice.actions
const reducer = slice.reducer

export {actions, reducer}