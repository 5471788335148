import React, { useEffect, useLayoutEffect } from "react"
import GroupToolbar from "./groupToolbar/GroupToolbar"
import GroupTabs from "./groupTabs/GroupTabs"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { NewProductThunks } from "../../redux/reducers/pages/groupPage/thunks"
import { actions } from "../../redux/reducers/pages/groupPage"
import { actions as tablePartAction } from "../../redux/reducers/pages/groupPage/tablePartComponent/index"
import { actions as toolsetAction } from "../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/index"
import { actions as graphicActions } from "../../redux/reducers/pages/groupPage/graphicComponent/index"
import { actions as categoryActions } from "../../redux/reducers/pages/groupPage/categoryTabComponent/index"
import { actions as additionalAction } from "../../redux/reducers/pages/groupPage/additionalInfoComponent/index"
import RouteProvider from "../../utils/RouteProvider"
import Api from "../../api"
import { GroupPageSelectors } from "../../redux/reducers/pages/groupPage/groupPageSelectors"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import NotAuthorize from "../notAuthorize/NotAuthorize"
import Loader from "../../library/loaders/Loader"
import "./group.scss"
import { GroupPageAuthSelectors } from "../../redux/reducers/pages/groupPage/groupPageAuthSelectors"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { CatalogsThunks } from "../../redux/reducers/catalogs/thunk"
import { actions as catalogActions } from "../../redux/reducers/catalogs"
import { SellmarkToCatalogProvider } from "../../domain/SellmarkToCatalogProvider"
import Constants from "../../domain/Constants";

const refValue = { groupId: "" }

export default function Group() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const catalogWasInit = useSelector(CatalogSelectors.getWasInit)
	const language = useSelector(LanguageSelectors.getSelected)
	const isPageLoading = useSelector(GroupPageSelectors.getIsPageLoading)
	const haveRightsToCreateGroup = useSelector(
		GroupPageAuthSelectors.getHasRightsCreateGroup
	)
	const canCreateGroup = useSelector(GroupPageAuthSelectors.getCanCreateGroup)

	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const paramGroupId = searchParams.get("productGroupId")
	const productIds = searchParams.getAll("productId")
	console.log("productIds", productIds)
	const navigate = useNavigate()
	refValue.groupId = paramGroupId ?? ""

	if (
		(paramGroupId == null && group.id != "") ||
		(paramGroupId != null && group.id != "" && paramGroupId != group.id)
	) {
		const path = RouteProvider.productGroup.generateParameterPath({
			productGroupId: group.id,
			productId: productIds,
		})
		console.log(path)
		navigate(path, { replace: true })
	}

	//подписываемся на отмену брони Id в случае, если пользователь закрывает страницу
	useEffect(() => {
		window.onbeforeunload = () => {
			discardReserve(refValue.groupId)
			return null
		}
	}, [])

	useEffect(() => {
		//перезагружаем группу после создания
		if (group.justCreated)
			dispatch(
				NewProductThunks.getOrReserve({
					languageId: language.id,
					productGroupId: paramGroupId,
					productsIds: productIds
				})
			)
	}, [group.justCreated])

	//подгружаем каталоги
	useEffect(() => {
		if(!catalogWasInit)
			dispatch(
				CatalogsThunks.getCatalogs()
			)
	}, [catalogWasInit])

	//выставляем по умолчанию печатный каталог на вкладке категорий в зависимости от номенклатурной группы или торговой группы
	const sellMarkToCatalogProxy = SellmarkToCatalogProvider.sellMarkToCatalogProxy;
	useEffect(() => {
		//Для берил делаем исключение. Выставляем каталог в зависимости от номенклатурной группы
		if(group.priceGroupId == Constants.BerilPriceGroupId)
		{
			dispatch(catalogActions.setSelected(Constants.BerilCatalogId))
			return;
		}
		//для всех остальных групп выставляем каталог в зависимости от торговой группы
		if(group.sellmarkId !== null) 
			dispatch(catalogActions.setSelected(sellMarkToCatalogProxy(group.sellmarkId)))
	}, [catalogWasInit, group.wasCreate])

	useEffect(() => {
		dispatch(
			NewProductThunks.getOrReserve({
				languageId: language.id,
				productGroupId: paramGroupId,
				productsIds: productIds
			})
		)
	}, [language.id])

	useLayoutEffect(() => {
		return function () {
			//отменяем подписку
			window.onbeforeunload = null
			discardReserve(refValue.groupId)

			dispatch(tablePartAction.clearStateOnUnmount())
			dispatch(toolsetAction.clearStateOnUnmount())
			dispatch(actions.clearStateOnUnmount())
			dispatch(graphicActions.clearStateOnUnmount())
			dispatch(categoryActions.clearStateOnUnmount())
			dispatch(additionalAction.clearStateOnUnmount())
		}
	}, [])

	const discardReserve = (groupId: string | null) => {
		//отменяем резервирование id на сервере
		if (groupId !== null) {
			const form = new FormData()
			form.set("id", groupId)
			navigator.sendBeacon(Api.beacon.getDiscardBeaconUrl(), form)
		}
	}

	return (
		<div className="new-product u-droppod">
			<div className="u-droppod-header">
				<div className="header-text">Группа товаров</div>
			</div>
			{isPageLoading ? (
				<Loader />
			) : (
				<>
					{!group.wasCreate && !haveRightsToCreateGroup ? (
						<NotAuthorize
							text={"У вас нет прав создавать группы продуктов"}
						/>
					) : (
						<>
							{!group.wasCreate && !canCreateGroup ? (
								<>
									Группы можно создавать только на русском
									языке
								</>
							) : (
								<>
									<GroupToolbar />
									{group.wasCreate && !group.justCreated ? (
										<GroupTabs />
									) : (
										<></>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}
