import React from "react"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/pictograms"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import {PictogramsSelectors} from "../../../redux/reducers/pictograms/pictogramsSelectors"
import {PictogramsThunks} from "../../../redux/reducers/pictograms/thunk"
import {LanguageSelectors} from "../../../redux/reducers/languages/languageSelectors"
import NullableSelect from "../../../app/common/basic/selectors/NullableSelect"

interface IPictogramSelectorProps {
    height?: number
    disabled?: boolean
}

function PictogramSelector(props: IPictogramSelectorProps) {

    const language = useSelector(LanguageSelectors.getSelected)
    const pictograms = useSelector(PictogramsSelectors.getFilteredPictograms)
    const selected = useSelector(PictogramsSelectors.getSelected)

    const dispatch = useDispatch()


    useEffect(() => {
        if(language.id > 0)
            dispatch(PictogramsThunks.getPictograms({languageId: language.id}))
    }, [language.id])

    const changeSelected = (id: number | null) => {
        dispatch(actions.setSelected(id))
    }

    return <NullableSelect toOption={ToOptionProvider.pictogramToOption}
        isDisabled={props.disabled ?? false}
        noOptionsMessage={"Выберите пиктограмму"}
        options={pictograms}
        className={"selector"}
        onChange={newValue => changeSelected(newValue as number)}
        height={props.height}
        value={selected}
    />
}

export default PictogramSelector