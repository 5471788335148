import {AuthThunks} from "./thunks"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {LocalStorageProvider} from "../../../api/LocalStorageProvider"
import {Role} from "../../../domain/Role"
import jwtDecode from "jwt-decode"

export type AuthState = {
    username: string | null;
    token: string | null;
    roles: Role[];
};

const roleClaimTitle = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"

const getRolesFromToken = (token: string | null): Role[] => {
    if (!token)
        return []
    const roles = jwtDecode<object>(token)[roleClaimTitle]
    //особенности библиотеки jwt-decode
    return Array.isArray(roles) ? roles.map(x => Role[x as keyof typeof Role]) : [Role[roles as keyof typeof Role]]
}

const initialToken = LocalStorageProvider.getToken()
const roles = getRolesFromToken(initialToken)

const INITIAL_STATE: AuthState = {
    username: initialToken,
    token: LocalStorageProvider.getUser(),
    roles: roles
}

const TEST_INITIAL_STATE: AuthState = {
    username: "",
    token: "",
    roles: []
}

const authSliceCreator = (initialState: AuthState) => createSlice({
    name: "Auth",
    initialState: initialState,
    reducers: {
        setCredentials(state: AuthState, action: PayloadAction<{ username: string, token: string }>) {
            const {username, token} = {...action.payload}

            LocalStorageProvider.setToken(token)
            LocalStorageProvider.setUser(username)

            state.roles = getRolesFromToken(action.payload.token)
            state.username = action.payload.username
            state.token = action.payload.token
        },
        clearCredentials(state: AuthState) {
            LocalStorageProvider.removeToken()
            LocalStorageProvider.removeUser()

            state.username = null
            state.token = null
            state.roles = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(AuthThunks.logout.fulfilled, (state) => {
            LocalStorageProvider.removeToken()
            LocalStorageProvider.removeUser()

            state.username = null
            state.token = null
            state.roles = []
        })
    }
})

const authSlice = authSliceCreator(INITIAL_STATE)
const reducer = authSlice.reducer
const actions = authSlice.actions

export {reducer, actions, TEST_INITIAL_STATE}