import React from "react"
import {SvgIcon} from "../icons/SvgIcon"
import "./adNotFoundRow.scss"

export function AdNotFoundRow(props: IAdNotFoundRowProps) {

    const text = props.text ?? "К сожалению, ничего не найдено"
    const shouldShowIcon = props.shouldShowIcon ?? true

    return <tr className={"no-hover nofound-wrapper"}>
        <td colSpan={props.colSpan} className="td-not-found">
            <div className={"wrapper"}>
                {
                    shouldShowIcon
                        ? <SvgIcon name={"file-delete"} className={"not-found-icon"}/>
                        : null
                }
                <div className={"not-found-text"}>{text}</div>
            </div>
        </td>
    </tr>
}

interface IAdNotFoundRowProps {
    colSpan: number,
    text?: string | null,
    shouldShowIcon?: boolean
}