import React from "react"
import "./CatalogOnSiteModal.scss"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/reducers/pages/uploadDataToWebsitePage"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"
import { useEffect } from "react"
import ToOptionProvider from "../../utils/ToOptionProvider"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { UploadDataSelectors } from "../../redux/reducers/pages/uploadDataToWebsitePage/uploadDataSelectors"
import { CatalogGroup, IWebsite, WebsiteSchema } from "../../domain/types"
import { UploadDataToSitesThunk } from "../../redux/reducers/pages/uploadDataToWebsitePage/thunks"
import useAuth from "../../hooks/authorizationHook"
import { Role } from "../../domain/Role"
import { AppState } from "../../redux/reducers"

export default function CatalogOnSiteModal() {
	const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)
	const wasWebsitesInit = useSelector(WebsiteSelectors.getWasInit)
	const catalogs = useSelector(
		CatalogSelectors.getByCatalogGroup(CatalogGroup.All)
	)

	const canAccessAnySite = useAuth([Role.Admin])
	const websites = useSelector<AppState, IWebsite[]>((x) =>
		WebsiteSelectors.getWebsites(
			x,
			canAccessAnySite ? undefined : WebsiteSchema.Latvian
		)
	)
	const selectedCatalog = useSelector(CatalogSelectors.getSelected)

	const website = useSelector(UploadDataSelectors.getSelectedWebsite)
	const catalog = useSelector(UploadDataSelectors.getSelectedCatalog)
	const isOpen = useSelector(UploadDataSelectors.getIsCatalogOnSiteOpen)

	const dispatch = useDispatch()

	useEffect(() => {
		if (catalog.id === 0 && wasCatalogsInit) {
			const anyPrinted = catalogs.first()
			dispatch(actions.setSelectedCatalogOnSiteMenu(anyPrinted))
		}
		if (website.id === 0 && wasWebsitesInit) {
			const any = websites.first()
			dispatch(actions.setSelectedWebsiteOnSiteMenu(any))
		}
	}, [wasWebsitesInit, wasCatalogsInit])

	const closeModal = () => dispatch(actions.setIsCatalogOnSiteMenuOpen(false))

	const setCatalog = (catalogId: number) => {
		const catalog = catalogs.find((x) => x.id === catalogId)
		if (catalog) dispatch(actions.setSelectedCatalogOnSiteMenu(catalog))
	}

	const setWebsite = (websiteId: number) => {
		const website = websites.find((x) => x.id === websiteId)
		if (website) dispatch(actions.setSelectedWebsiteOnSiteMenu(website))
	}

	const addCatalogToSite = async () => {
		await dispatch(
			UploadDataToSitesThunk.addCatalogToSite({
				catalogId: catalog.id,
				websiteId: website.id,
			})
		)
		const isSameCatalog = catalog.id === selectedCatalog.id
		if (isSameCatalog)
			dispatch(actions.setShouldUpdateNotDeployedGroups(true))
		closeModal()
	}

	const removeCatalogFromSite = async () => {
		await dispatch(
			UploadDataToSitesThunk.removeCatalogFromSite({
				catalogId: catalog.id,
				websiteId: website.id,
			})
		)
		const isSameCatalog = catalog.id === selectedCatalog.id
		if (isSameCatalog)
			dispatch(actions.setShouldUpdateNotDeployedGroups(true))
		closeModal()
	}

	return (
		<Modal
			show={isOpen}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					Товары каталога на сайт
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div>Товары каталога на сайт</div>
					<SimpleSelect
						value={catalog}
						options={catalogs}
						onChange={(e) => setCatalog(e as number)}
						toOption={ToOptionProvider.catalogToOption}
					/>
					<p></p>
					<div>На указанном сайте</div>
					<SimpleSelect
						value={website}
						options={websites}
						onChange={(e) => setWebsite(e)}
						toOption={ToOptionProvider.websiteToOption}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					className={"u-button large primary"}
					onClick={() => closeModal()}
				>
					Закрыть
				</button>
				<button
					className={"u-button large primary"}
					onClick={() => removeCatalogFromSite()}
				>
					Удалить
				</button>
				<button
					className={"u-button large accent"}
					onClick={() => addCatalogToSite()}
				>
					Добавить
				</button>
			</Modal.Footer>
		</Modal>
	)
}
