import React, {useEffect} from "react"
import SimpleSelect from "../../common/basic/selectors/SimpleSelect"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/pages/treePage"
import {CatalogGroup} from "../../../domain/types"
import {TreeThunks} from "../../../redux/reducers/pages/treePage/thunks"
import "./TreeGroupToolbar.scss"
import _ from "lodash"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import {CategoriesSelectors} from "../../../redux/reducers/categories/categoriesSelectors"
import useAuth from "../../../hooks/authorizationHook"
import {Role} from "../../../domain/Role"
import {TreePageSelectors} from "../../../redux/reducers/pages/treePage/treePageSelectors"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"
import AdSearchInput from "../../../library/inputs/AdSearchInput"
import AdDefaultInput from "../../../library/inputs/AdDefaultInput"
import {actions as informActions} from "../../../redux/reducers/informer"
import {actions as messageBusActions} from "../../../redux/reducers/messageBus"
import {SvgIcon} from "../../../library/icons/SvgIcon"
import {actions as informerActions} from "../../../redux/reducers/informer"
import {MessageBusSelectors} from "../../../redux/reducers/messageBus/messageBusSelectors"
import KeysCodes from "../../../utils/KeysCodes"
export default function TreeGroupToolbar() {
    const canAccessControls = useAuth([Role.ContentManagerRF])

    const category = useSelector(CategoriesSelectors.getSelected)
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const catalog = useSelector(CatalogSelectors.getSelected)

    const lastSelected = useSelector(TreePageSelectors.getLastSelected)
    const groups = useSelector(TreePageSelectors.getOrderedGroups(catalogGroup))
    const minSort = useSelector(TreePageSelectors.getMinSort)
    const maxSort = useSelector(TreePageSelectors.getMaxSort)
    const sortNumber = useSelector(TreePageSelectors.getSortNumber)
    const selectedGroups = useSelector(TreePageSelectors.getSelectedGroups)

    const cardTypes = useSelector(TreePageSelectors.getCardTypes)
    const selectedCardType = useSelector(TreePageSelectors.getSelectedCardType)
    const filter = useSelector(TreePageSelectors.getFilter)

    const message = useSelector(MessageBusSelectors.getMessage)

    useEffect(() => {
        if (message.table_id === "tg-table") {
            dispatch(messageBusActions.clearMessage())
            switch (message.key) {
            case KeysCodes.ARROWDOWN :
                changeSort(1)
                break
            case KeysCodes.ARROWUP :
                changeSort(-1)
                break
            case KeysCodes.DELETE :
                removeProductGroupsFromCategories()
                break
            default:
                break
            }
            console.log(message)
        }
    }, [message.rand])

    const dispatch = useDispatch()

    function setSortNumber(newValue: string) {
        dispatch(actions.setSortNumber(newValue))
    }

    function selectedCardDistributionType(id: number) {
        dispatch(actions.setSelectedCardType(id))
    }

    function setFilter(newValue: string) {
        dispatch(actions.setFilter(newValue))
    }

    function recountProductGroupsSort() {
        const catalogId = catalog.id
        dispatch(TreeThunks.recountSort({catalogId: catalogId}))
    }

    async function changeSort(addition: number) {
        const selected = lastSelected
        if (!selected) {
            dispatch(informActions.inform("Выберите группу для изменения сортировки"))
            return
        }
        let target: number
        if (selected.sort === 0) {
            if (addition < 0) {
                dispatch(informActions.inform("Новое значение сортировки выходит за границы доступного"))
                return
            }

            const filtered = groups
                .filter(x => x.catalogCategoryId === selected.catalogCategoryId && x.sort !== 0)
            if (filtered.length > 1) {
                target = _.orderBy(filtered, x => x.sort)[0].sort!
            } else {
                target = minSort
            }
            if (target === 0) {
                dispatch(informActions.inform("Невозможно произвести расчеты. Используйте автоматическую сортировку"))
                return
            }
        } else {
            // @ts-ignore
            // компилятор тупит и не смотря на проверку выше - не билдит, поэтому игнорим ее
            target = selected!.sort + addition
        }

        const groupCountWithTargetSort = groups.filter(x => x.sort === target).length
        if (groupCountWithTargetSort > 1) {
            dispatch(informActions.inform("Несколько групп имеют одну и ту же сортировку. Необходимо использователь автоматическую сортировку."))
            return
        }

        if (target < 1 || target < minSort) {
            if (minSort !== 0 && maxSort !== 0) {
                dispatch(informActions.inform("Новое значение сортировки выходит за границы доступного"))
                return
            }
        }
        if (target > maxSort) {
            dispatch(informActions.inform("Новое значение сортировки выходит за границы доступного"))
            return
        }
        const targetSortCategory = groups.find(x => x.sort == target)!.catalogCategoryId
        if (selected!.catalogCategoryId != targetSortCategory) {
            dispatch(informActions.inform("Новое значение сортировки выходит за границы доступного"))
            return
        }

        await dispatch(TreeThunks.changeSort({
            catalogId: catalog.id,
            // @ts-ignore
            currentSort: selected.sort,
            // @ts-ignore
            productGroupId: selected.id,
            // @ts-ignore
            catalogCategoryId: selected.catalogCategoryId,
            targetSort: target
        }))



        const table = document.getElementById("tg-table")

        if (table) {
            const current_line: Element = table.getElementsByClassName("table-row--lastActive")[0]
            const next_line: Element = current_line.nextSibling as Element
            const previous_line: Element = current_line.previousSibling as Element || table.getElementsByTagName("thead")[0]

            current_line.scrollIntoView({block: "nearest", behavior: "smooth"})
            if (addition < 0) {
                if(!previous_line)
                    return
                previous_line.scrollIntoView({block: "nearest", behavior: "smooth"})
            } else {
                if(!next_line)
                    return
                next_line.scrollIntoView({block: "nearest", behavior: "smooth"})
            }
        }
    }

    function setSpecificSort() {
        const selected = lastSelected
        if (selected == null) {
            dispatch(informerActions.inform("Выберите продуктовую группу для изменения сортировки"))
            return
        }
        const target = Number(sortNumber)
        if (isNaN(target)) {
            dispatch(informerActions.inform("Введено неккоректное число"))
            return
        }
        if (target < 1) {
            dispatch(informerActions.inform("Новое значение сортировки выходит за границы доступного"))
            return
        }
        if (target === selected.sort)
            return

        const targetSortCategory = groups.find(x => x.sort == target)!.catalogCategoryId
        if (selected!.catalogCategoryId != targetSortCategory) {
            dispatch(informerActions.inform("Новое значение сортировки выходит за границы доступного"))
            return
        }

        // компилятор тупит и не смотря на проверку выше - не билдит, поэтому игнорим ее
        dispatch(TreeThunks.changeSort({
            catalogId: catalog.id,
            // @ts-ignore
            currentSort: selected.sort,
            // @ts-ignore
            productGroupId: selected.id,
            // @ts-ignore
            catalogCategoryId: selected.catalogCategoryId,
            targetSort: target
        }))
    }

    function removeProductGroupsFromCategories() {
        if (selectedGroups.isEmpty()) {
            dispatch(informerActions.inform("Выберите группы продуктов, которые должны быть удалены из категории"))
            return
        }
        if (!category) {
            dispatch(informerActions.inform("Выберите категорию"))
            return
        }
        if (category.children.notEmpty()) {
            dispatch(informerActions.inform("Выберите категорию последнего уровня"))
            return
        }
        dispatch(TreeThunks.removeGroupFromCat({
            productGroupIds: selectedGroups.map(x => x.id),
            catalogGroup: catalogGroup.id,
            catalogId: catalog.id,
            categoryId: category.id
        }))
    }

    return <div className="tree-group-toolbar u-toolbar">
        <div className="toolbar-row">
            <AdSearchInput onChange={setFilter} value={filter}/>
            <SimpleSelect value={selectedCardType}
                options={cardTypes}
                onChange={value => selectedCardDistributionType(value)}
                toOption={e => e}
                className={"selector"}/>
        </div>
        <div className="toolbar-row">
            {
                catalogGroup.id === CatalogGroup.Printed
                    ? <>
                        {
                            minSort == 0 && maxSort === 0
                                ? <></>
                                : <>
                                    <AdDefaultInput className="tree-group-toolbar__input"
                                        value={sortNumber}
                                        onChange={setSortNumber}
                                        disabled={!canAccessControls}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                setSpecificSort()
                                            }
                                        }}/>
                                </>

                        }
                        <button className="u-button square accent"
                            disabled={!canAccessControls}
                            onClick={() => changeSort(-1)}>
                            <SvgIcon name="arrow-top"/>
                        </button>
                        <button className="u-button square accent"
                            disabled={!canAccessControls}
                            onClick={() => changeSort(1)}>
                            <SvgIcon name="arrow-bottom"/>
                        </button>
                        {
                            minSort == 0 && maxSort === 0
                                ? <></>
                                : <>
                                    <button className="u-button square accent"
                                        disabled={!canAccessControls}
                                        onClick={() => setSpecificSort()}>
                                        <SvgIcon name="pencil-create"/>
                                    </button>
                                </>

                        }
                    </>
                    : <></>
            }
            <button disabled={!canAccessControls}
                className="u-button square accent"
                title="Удалить выбранные карточки из раздела"
                onClick={() => removeProductGroupsFromCategories()}>
                <SvgIcon name="circle-x"/>
            </button>

            {
                catalogGroup.id === CatalogGroup.Printed
                    ? <button disabled={!canAccessControls}
                        className="u-button large accent"
                        title="Пересчитать порядок карточек"
                        onClick={() => recountProductGroupsSort()}>
                        <SvgIcon name="calculator"/>
                        Пересчитать порядок карточек
                    </button>
                    : <></>
            }
        </div>
    </div>
}