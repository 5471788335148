import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { TablePartSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/tablePartSelectors"
import { MessageBusSelectors } from "../../../../../../redux/reducers/messageBus/messageBusSelectors"
import React, { useEffect } from "react"
import { actions as messageBusActions } from "../../../../../../redux/reducers/messageBus"
import KeysCodes from "../../../../../../utils/KeysCodes"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import { Role } from "../../../../../../domain/Role"
import SimpleSelect from "../../../../../common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../../../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../../domain/GroupMembership"
import useAuth from "../../../../../../hooks/authorizationHook"
import { saveAttributeValues } from "./ProductAttributeCommonExecutor"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

export function ProductAttributeController() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const accumulatedChanges = useSelector(
		TablePartSelectors.getAccumulatedChanges
	)
	const selectedAttribute = useSelector(
		TablePartSelectors.getSelectedAttribute
	)
	const attributes = useSelector(TablePartSelectors.getAttributes)
	const attributesOrder = useSelector(TablePartSelectors.getAttributesOrder)
	const selectedAttributeColumn = useSelector(
		TablePartSelectors.getSelectedAttributeColumn
	)
	const dispatch = useDispatch()

	const canAccessToChangeImportantStatusAttrs = useSelector(
		GroupPageAuthSelectors.getCanControlImportantAttrs
	)
	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlAttributes
	)

	const message = useSelector(MessageBusSelectors.getMessage)
	useEffect(() => {
		if (message.table_id === "p-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.ARROWRIGHT:
					changeAttributeOrder(1)
					break
				case KeysCodes.ARROWLEFT:
					changeAttributeOrder(-1)
					break
				default:
					break
			}
		}
	}, [message.rand])

	const setSelectedAttribute = (id: number) =>
		dispatch(actions.setSelectedAttribute(id))

	const addAttribute = () => {
		if (selectedAttribute === null) {
			dispatch(informerActions.inform("Выберите аттрибут для добавления"))
			return
		}
		if (selectedAttribute.id in attributesOrder) {
			dispatch(informerActions.inform("Колонка уже добавлена"))
			return
		}

		dispatch(
			TablePartComponentThunks.addAttribute({
				attributeId: selectedAttribute.id,
				productGroupId: group.id,
			})
		)
	}

	const removeAttribute = () => {
		if (selectedAttributeColumn === null) {
			dispatch(informerActions.inform("Выберите колонку для удаления"))
			return
		}
		dispatch(
			TablePartComponentThunks.removeAttribute({
				productGroupId: group.id,
				attributeId: selectedAttributeColumn!,
			})
		)
	}

	const changeAttributeImportantStatus = () => {
		if (!selectedAttributeColumn) return
		dispatch(
			TablePartComponentThunks.changeAttributeImportantStatus({
				groupId: group.id,
				attributeId: selectedAttributeColumn,
			})
		)
	}

	const changeAttributeOrder = (addition: number) => {
		const selectedAttribute = selectedAttributeColumn
		if (selectedAttribute === null) {
			dispatch(informerActions.inform("Выберите колонку для изменения"))
			return
		}
		const selected = attributesOrder[selectedAttribute]
		if (!selected) {
			dispatch(
				informerActions.inform(
					"Произошла ошибка, перезагрузите страницу"
				)
			)
			return
		}
		let target: number | null = null
		const attributes = Object.keys(attributesOrder)
		for (let i = 0; i < attributes.length; i++) {
			const key = attributes[i]
			if (attributesOrder[key].sort === selected.sort! + addition) {
				target = Number(key)
				break
			}
		}
		if (!target) return

		dispatch(
			TablePartComponentThunks.changeAttributeOrder({
				attributeId: selectedAttribute,
				productGroupId: group.id,
				targetAttributeId: target,
			})
		)
	}

	return (
		<>
			{canAccess ? (
				<>
					<div>Атрибут</div>
					<SimpleSelect
						value={selectedAttribute}
						options={attributes}
						isDisabled={!canAccess}
						onChange={(e) => setSelectedAttribute(e)}
						className={"selector min-width-auto"}
						toOption={ToOptionProvider.attributeToOption}
					/>

					<button
						className={"u-button square primary"}
						onClick={() => addAttribute()}
						disabled={!canAccess}
					>
						<SvgIcon name={"circle-plus"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => removeAttribute()}
						disabled={!canAccess}
					>
						<SvgIcon name={"circle-x"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => changeAttributeOrder(-1)}
						disabled={!canAccess}
					>
						<SvgIcon name={"arrow-left"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => changeAttributeOrder(1)}
						disabled={!canAccess}
					>
						<SvgIcon name={"arrow-right"} />
					</button>
				</>
			) : null}
			{canAccessToChangeImportantStatusAttrs ? (
				<button
					className={"u-button square primary"}
					onClick={() => changeAttributeImportantStatus()}
					disabled={
						!selectedAttributeColumn ||
						selectedAttributeColumn == group.mainAttributeId ||
						!canAccessToChangeImportantStatusAttrs
					}
				>
					<SvgIcon name={"star"} />
				</button>
			) : null}
			{canAccess ? (
				<>
					<button
						disabled={accumulatedChanges.isEmpty()}
						className="u-button square primary"
						onClick={() =>
							saveAttributeValues(
								accumulatedChanges,
								group.id,
								canAccess,
								dispatch
							)
						}
					>
						<SvgIcon name={"floppy"} />
					</button>
				</>
			) : null}
		</>
	)
}
