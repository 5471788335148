import { useDispatch, useSelector } from "react-redux"
import { CategoryTabSelectors } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors"
import { useState } from "react"
import AdDefaultInput from "../../../../../../../library/inputs/AdDefaultInput"
import { CategoryTabComponentThunks } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/thunks"
import { LanguageSelectors } from "../../../../../../../redux/reducers/languages/languageSelectors"
import { SvgIcon } from "../../../../../../../library/icons/SvgIcon"
import { IScopeOfApplication } from "../../../../../../../domain/models/scopes/IScopeOfApplication"
import { actions } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/index"

interface IListRedactorProps
{
    filteredScopes: IScopeOfApplication[]
    setFilteredScopes: React.Dispatch<React.SetStateAction<IScopeOfApplication[]>>
}

export function ListRedactorTable(props: IListRedactorProps)
{
    // const scopes = useSelector(CategoryTabSelectors.getScopes)
    const scopes = useSelector(CategoryTabSelectors.getScopes)
    const language = useSelector(LanguageSelectors.getSelected)
    const dispatch = useDispatch();
    
    const [editingScope, setEditingScope] = useState<{
		id: number
		name: string
	} | null>(null)
    
    const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			// Сохраняем изменения на бэкенд
			if (editingScope) {
				changeNameScope(editingScope.id, editingScope.name)
			} else {
				console.error(
					"Не удалось изменить имя области применения: editingScope равен null"
				)
			}
			// Завершаем редактирование
			setEditingScope(null)
		}
	}
	const changeNameScope = async (id, newName) => {
        dispatch(actions.setScopes(scopes
            .map(s => 
                {
                    if(s.id == id)
                    {
                        return { id: id, name: newName, sort: s.sort }
                    }
                    return s
                }
            )
        ))
		try {
			await dispatch(
				CategoryTabComponentThunks.changeScopeApplication({
					name: newName,
					scopeId: id,
					languageId: language.id,
				})
			)
			console.log("Изменения сохранены успешно")
		} catch (error) {
			console.error("Ошибка при сохранении изменений:", error)
		}
	}
    const removeScopeApplication = async (scopeId: number) => {
        dispatch(actions.setScopes(scopes.filter(s => s.id != scopeId)))
        await dispatch(
			CategoryTabComponentThunks.removeScopeApplication({
				scopeId: scopeId,
			})
		)//запрашиваю обновлённую информацию
	}
    return (
        <div className="u-table__scroll-wrapper" style={{maxHeight: "770px"}}>
            <table className="u-table">
                <thead className="u-thead">
                    <tr>
                        <th
                            colSpan={3}
                            style={{ width: "100%" }}
                        >
                            Наименование
                        </th>
                    </tr>
                </thead>
                <tbody className="u-tbody">
                    {props.filteredScopes.map((x) => (
                        <tr key={x.id}>
                            <td>
                                {editingScope &&
                                    editingScope.id === x.id ? (
                                        <AdDefaultInput
                                            type="text"
                                            value={
                                                editingScope.name
                                            }
                                            onChange={(value) =>
                                                setEditingScope({
                                                    ...editingScope,
                                                    name: value,
                                                })
                                            }
                                            onKeyDown={handleKeyPress}
                                            autoFocus={true}
                                        />
                                    ) : (
                                    x.name
                                )}
                            </td>
                            <td className="move">
                                <div className="instruments-holder">
                                    <SvgIcon
                                        name="pencil-create"
                                        onClick={() =>
                                            setEditingScope({
                                                id: x.id,
                                                name: x.name,
                                            })
                                        }
                                    />
                                    <SvgIcon
                                        name="circle-x"
                                        onClick={() => {
                                            removeScopeApplication(
                                                x.id
                                            )
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr />
                </tbody>
            </table>
        </div>
    )
}