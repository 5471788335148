import React, { useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ICategoryViewModel } from "../tables/productGroupTable/types"
import CategoryExpandedList from "./CategoryExpandedList"
import { CategoriesComponentThunks } from "../../../redux/reducers/categories/thunk"
import { actions } from "../../../redux/reducers/categories"
import { actions as categoryPageAction } from "../../../redux/reducers/pages/categoryPage"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { CatalogGroupSelectors } from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { CategoriesSelectors } from "../../../redux/reducers/categories/categoriesSelectors"
import { AdLoadingRow } from "../../tables/AdLoadingRow"

export default function CategoryTree(props: ICategoryGroupTreeProps) {
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const language = useSelector(LanguageSelectors.getSelected)
    const categories = useSelector(CategoriesSelectors.getCategories(catalogGroup.id))
    const isCatLoading = useSelector(CategoriesSelectors.getIsLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CategoriesComponentThunks.getCategories({ catalogGroup: catalogGroup.id, languageId: language.id }))
    }, [catalogGroup.id, language.id])

    function setChecked(category: ICategoryViewModel) {
        dispatch(actions.setCategoryChecked({ categoryId: category.id, checked: !category.checked }))
    }

    function onRowClicked(category: ICategoryViewModel) {
        dispatch(actions.setSelectedCategory({ categoryId: category.id, selected: !category.selected }))
        dispatch(actions.setGroupImageSettings(category))
    }

    useLayoutEffect(() => {
        return function () {
            dispatch(actions.clearOnUnmount())
        }
    }, [])


    return <>

        <div className="u-table__scroll-wrapper">
            <table className="u-table">
                <thead className="u-thead">
                    <tr>
                        <th className="u-table-column-checkbox-wrapper" />
                        <th className="u-table-column-plus-wrapper" />
                        <th className="u-width-100%">Наименование</th>
                        <th className="">Статус</th>
                        <th colSpan={2} className="u-align-center">···</th>
                    </tr>
                </thead>
                <tbody className="u-tbody">
                    {
                        isCatLoading
                            ? <AdLoadingRow colSpan={5} />
                            : <CategoryExpandedList
                                highlightedRows={props.highlightedRows}
                                spanLevel={0}
                                categories={categories}
                                onRowClicked={onRowClicked}
                                onCheckboxClicked={setChecked} />
                    }
                    <tr></tr>
                </tbody>
            </table>
        </div>
    </>
}

interface ICategoryGroupTreeProps {
    highlightedRows: number[],
}

