import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import _ from "lodash"
import { ProductsListThunks } from "./thunks"
import { IProductIdentity } from "../../../../../domain/models/products/ProductIdentity"

export type CheckedProduct = IProductIdentity & { checked: boolean }

export type ProductListComponentState = {
	products: CheckedProduct[]
	filter: string
	isLoading: boolean
	selectedProducts: CheckedProduct[]
}

const INITIAL_STATE: ProductListComponentState = {
	products: [],
	filter: "",
	isLoading: true,
	selectedProducts: [],
}

const slice = createSlice({
	name: "productPage/productList",
	initialState: INITIAL_STATE,
	reducers: {
		setSelected(
			state: ProductListComponentState,
			action: PayloadAction<string>
		) {
			const product = state.products.find((x) => x.id === action.payload)
			if (!product) return
			const selectedIndex = state.selectedProducts.findIndex(
				(x) => x.id === action.payload
			)

			if (selectedIndex > -1)
				state.selectedProducts.splice(selectedIndex, 1)
			else state.selectedProducts.push(product)

			product.checked = !product.checked
		},
		setFilter(
			state: ProductListComponentState,
			action: PayloadAction<string>
		) {
			state.filter = action.payload.toLowerCase()
		},
		setLoading(
			state: ProductListComponentState,
			action: PayloadAction<boolean>
		) {
			state.isLoading = action.payload
		},
		removeSelectedFromList(state: ProductListComponentState) {
			state.products = _.orderBy(
				state.products.filter(
					(x) =>
						state.selectedProducts.findIndex(
							(y) => y.id === x.id
						) === -1
				),
				(x) => x.name
			)
			state.selectedProducts = []
		},
		clearStateOnUnmount() {
			return { ...INITIAL_STATE }
		},
		clearSelected(state: ProductListComponentState) {
			state.selectedProducts = []
		},
	},
	extraReducers: (builder) => {
		builder.addCase(ProductsListThunks.getProducts.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(
			ProductsListThunks.getProducts.fulfilled,
			(state, action) => {
				state.isLoading = false
				state.products = _.orderBy(
					action.payload.map((x) => {
						return {
							id: x.id,
							name: x.name,
							checked: false,
							priceGroupId: x.priceGroupId,
						}
					}),
					(x) => x.name
				)
				if (state.selectedProducts.length > 0) {
					for (const product of state.products) {
						const isSelected = state.selectedProducts.some(
							(x) => x.id === product.id
						)
						if (isSelected) {
							product.checked = true
						}
					}
				}
			}
		)
		builder.addCase(ProductsListThunks.getProducts.rejected, (state) => {
			state.isLoading = false
		})
		builder.addCase(
			ProductsListThunks.addProductsToGroup.fulfilled,
			(state, action) => {
				state.products = _.orderBy(
					state.products.filter((p) => {
						const index = action.meta.arg.productIds.findIndex(
							(x) => x === p.id
						)
						return index === -1
					}),
					(x) => x.name
				)
				state.selectedProducts = []
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
