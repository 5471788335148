import React from "react"
import "./groupSelectorBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/groupPage"
import { useEffect } from "react"
import { NewProductThunks } from "../../../redux/reducers/pages/groupPage/thunks"
import NullableSelect from "../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { GroupPageSelectors } from "../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { Role } from "../../../domain/Role"
import useAuth from "../../../hooks/authorizationHook"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../domain/GroupMembership"

export default function GroupSelectorBlock() {
	const language = useSelector(LanguageSelectors.getSelected)
	const series = useSelector(GroupPageSelectors.getSeries)
	const seria = useSelector(GroupPageSelectors.getSelectedSeries)
	const signs = useSelector(GroupPageSelectors.getSigns)
	const sign = useSelector(GroupPageSelectors.getSelectedSign)
	const attributes = useSelector(GroupPageSelectors.getAttributes)
	const attribute = useSelector(GroupPageSelectors.getSelectedAttribute)
	const sellmarks = useSelector(GroupPageSelectors.getSellmarks)
	const sellmark = useSelector(GroupPageSelectors.getSelectedSellmark)
	const group = useSelector(GroupPageSelectors.getGroup)

	const isContentRf = useAuth([Role.ContentManagerRF])
	const isContentLv = useAuth([Role.ContentManagerLv])
	const isContentRg = useAuth([Role.ContentManagerRg])
	const priceGroupMembership = getGroupMembership(group.priceGroupId)

	let canChange = false
	let canChangeMainAttribute = false

	switch (priceGroupMembership) {
		case GroupMembership.Nothing:
			canChange = isContentRf || isContentLv || isContentRg
			canChangeMainAttribute = isContentRf || isContentLv || isContentRg
			break
		case GroupMembership.Russian:
			canChange = isContentRf
			canChangeMainAttribute = isContentRf || isContentLv || isContentRg
			break
		case GroupMembership.Latvian:
			canChange = isContentLv || isContentRg
			canChangeMainAttribute = isContentLv || isContentRg
	}

	const dispatch = useDispatch()

	useEffect(() => {
		if (language.id > 0) {
			dispatch(NewProductThunks.getSigns())
			dispatch(NewProductThunks.getSeries())
			dispatch(
				NewProductThunks.getAttributes({ languageId: language.id })
			)
			dispatch(NewProductThunks.getSellmarks())
		}
	}, [language.id])

	const setSeries = (seriesId: number | null) => {
		dispatch(actions.setSelectedSeries(seriesId))
	}
	const setSigns = (signId: number | null) => {
		dispatch(actions.setSelectedSign(signId))
	}
	const setAttribute = (attributeId: number | null) => {
		dispatch(actions.setSelectedAttribute(attributeId))
	}
	const setSellmark = (sellmarkId: number | null) => {
		dispatch(actions.setSelectedSellmark(sellmarkId))
	}

	return (
		<div className="new-product-selector-block toolbar-row u-flex-1-inside">
			<NullableSelect
				value={seria}
				options={series}
				placeholder={"Серия"}
				onChange={(e) => setSeries(e as number)}
				toOption={ToOptionProvider.seriesToOption}
				height={31}
				isDisabled={!canChange}
				className={"selector"}
			/>{" "}
			{/*TODO: null некорректно отображается */}
			<NullableSelect
				value={sign}
				options={signs}
				placeholder={"Значок"}
				onChange={(e) => setSigns(e as number)}
				height={31}
				toOption={ToOptionProvider.signToOption}
				isDisabled={!canChange}
				className={"selector"}
			/>
			<NullableSelect
				value={attribute}
				options={attributes}
				placeholder={"Главный аттрибут"}
				onChange={(e) => setAttribute(e as number)}
				toOption={ToOptionProvider.attributeToOption}
				height={31}
				isDisabled={!canChangeMainAttribute}
				className={"selector"}
			/>
			<NullableSelect
				value={sellmark}
				options={sellmarks}
				placeholder={"Торговая марка"}
				onChange={(e) => setSellmark(e as number)}
				toOption={ToOptionProvider.priceGroupToOption}
				height={31}
				isDisabled={!canChange}
				className={"selector"}
			/>
		</div>
	)
}
