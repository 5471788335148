import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"


export class AllProductPageThunks {
    static getProductsIdentityByGroupPriceGroupThunk = createAutogenApiThunk({
        typePrefix: "all-products",
        apiCall: Api.products.getProductsIdentityByGroupPriceGroup
    })

    static getOrReserveThunk = createAutogenApiThunk({
        typePrefix: "toolset-products/get-or-reserve-product-group",
        apiCall: Api.productGroups.getOrReserve,
        errorMessage: "Не удалось получить информацию о группе продуктов"
    })
}
