import { IProductGroup } from "../../../../domain/types"
import { ISelectable } from "../../../types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RecommendationThunks } from "./thunks"
import { IProductIdentity } from "../../../../domain/models/products/ProductIdentity"

export type Recommendation = IProductIdentity & ISelectable

export type RecommendationsState = {
	recommendations: Recommendation[]
	searchString: string
	productGroup: IProductGroup | null
}

const INITIAL_STATE: RecommendationsState = {
	searchString: "",
	recommendations: [],
	productGroup: null,
}

const slice = createSlice({
	name: "recommendations",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedRecommendation(
			state: RecommendationsState,
			action: PayloadAction<string>
		) {
			const recommendation = state.recommendations.find(
				(x) => x.id === action.payload
			)
			if (recommendation === undefined) return
			recommendation.selected = !recommendation.selected
		},
		setSearch(state: RecommendationsState, action: PayloadAction<string>) {
			state.searchString = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			RecommendationThunks.getOrReserve.fulfilled,
			(state, action) => {
				state.productGroup = action.payload
			}
		)
		builder.addCase(
			RecommendationThunks.getAllRecommendation.fulfilled,
			(state, action) => {
				state.recommendations = action.payload.map((x) => {
					return {
						id: x.id,
						name: x.name,
						priceGroupId: x.priceGroupId,
						selected: false,
					}
				})
			}
		)
		builder.addCase(
			RecommendationThunks.addRecommendations.fulfilled,
			(state, action) => {
				const addedProducts = action.meta.arg.productsIds
				state.recommendations = state.recommendations.filter(
					(x) => addedProducts.findIndex((a) => a === x.id) === -1
				)
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
