import {AppState} from "../../../index"
import {createSelector} from "@reduxjs/toolkit"
import _ from "lodash"

export class GraphicComponentSelectors {
    static getGroupImages = createSelector([(state: AppState) => state.pages.productGroupPage.graphicState.groupImages],
        x => _.orderBy(x, x => x.typeId))

    static getShouldOpenVideoModel = (state: AppState) => state.pages.productGroupPage.graphicState.shouldOpenVideoModel
    static getGroupPictograms = createSelector(
        [(state:AppState) => state.pages.productGroupPage.graphicState.groupPictograms],
        x => _.orderBy(x, x => x.sort))
    static getVideoLink = (state: AppState) => state.pages.productGroupPage.graphicState.videoLink
}