import React from "react"
import PriceGroupSelector from "../../../library/smartComponents/priceGroupSelector/PriceGroupSelector"
import "./productListToolbar.scss"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/productsPage/productList"
import { useDebouncedCallback } from "use-debounce"
import DebounceProvider from "../../../utils/DebounceProvider"
import AdSearchInput from "../../../library/inputs/AdSearchInput"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { actions as informerActions } from "../../../redux/reducers/informer"
import { ProductsListThunks } from "../../../redux/reducers/pages/productsPage/productList/thunks"
import { GroupListSelectors } from "../../../redux/reducers/pages/productsPage/groupList/groupListSelectors"
import { ProductListSelectors } from "../../../redux/reducers/pages/productsPage/productList/productListSelectors"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productsPage/productPageAuthSelectors"

function ProductListToolbar() {
	const dispatch = useDispatch()
	const debouncedFilter = useDebouncedCallback(
		(args) => dispatch(actions.setFilter(args)),
		DebounceProvider.getDebounce()
	)

	const selectedGroup = useSelector(GroupListSelectors.getSelectedGroup)
	const selectedProducts = useSelector(
		ProductListSelectors.getSelectedProducts
	)
	const loadedGroupsLength = useSelector(
		GroupListSelectors.getLoadedGroupsLength
	)

	const canAddToGroup = useSelector(ProductPageAuthSelectors.canAddProduct)

	const addProductsToGroup = () => {
		if (selectedProducts.isEmpty()) {
			dispatch(
				informerActions.inform(
					"Выберите продукты для добавления в группу"
				)
			)
			return
		}
		const group = selectedGroup
		if (!group) {
			dispatch(informerActions.inform("Выберите группу продуктов"))
			return
		}

		if (loadedGroupsLength === 0) {
			dispatch(
				informerActions.inform(
					"Выбранная вами группа скрыта фильтрами. Уберите фильтры, убедитесь, " +
						"что выбранная группа верна и повторите попытку."
				)
			)
			return
		}

		const productIds = selectedProducts.map((x) => x.id)
		dispatch(
			ProductsListThunks.addProductsToGroup({
				productGroupId: group.id,
				productIds: productIds,
			})
		)
	}

	return (
		<>
			<div className="u-droppod-header">
				<div className="header-text">Товары</div>
				{canAddToGroup ? (
					<button
						onClick={() => addProductsToGroup()}
						title="Добавить выделенный товар в выделенную карточку"
						type="button"
						className="u-button square accent"
					>
						<SvgIcon name="circle-plus" />
					</button>
				) : (
					<></>
				)}
			</div>
			<div className="product-left-column-toolbar u-toolbar">
				<div className="toolbar-row">
					<AdSearchInput
						onChange={debouncedFilter}
						placeholder="Поиск"
						style={{ width: "100%" }}
					/>
				</div>
				<div className="toolbar-row" style={{ marginTop: "10px" }}>
					{" "}
					<PriceGroupSelector
						shouldShowAllPriceGroupsOption={true}
						shouldShowRigaPriceGroupOption={true}
					/>
				</div>
			</div>
		</>
	)
}

export default ProductListToolbar
