import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"

export class RecommendationThunks {
    static getAllRecommendation = createAutogenApiThunk({
        typePrefix: "recommendations/get-all-rec",
        apiCall: Api.recommendations.getAllRecommendations,
        errorMessage: "Не удалось получить список возможных рекомендаций"
    })

    static addRecommendations = createAutogenApiThunk({
        typePrefix: "recommendations/add-rec",
        apiCall: Api.recommendations.addRecommendations,
        errorMessage: "Не удалось добавить рекомендации"
    })

    static getOrReserve = createAutogenApiThunk({
        typePrefix: "recommendations/get-or-reserve-product-group",
        apiCall: Api.productGroups.getOrReserve,
        errorMessage: "Не удалось получить информацию о группе продуктов"
    })
}

