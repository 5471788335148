import { useEffect } from "react"
import ProductFiles from "../../modules/ProductFiles"

export function FilesTab(props: { onMount: () => void }) {
	useEffect(() => {
		props.onMount()
	}, [])

	return (
		<>
			<ProductFiles />
		</>
	)
}
