import Api from "../../../../api"
import {
	createAutogenApiThunk,
	createAutogenNoArgsApiThunk,
} from "../../../createApiThunkV2"

export class TranslateThunks {
	static generateNewTranslates = createAutogenNoArgsApiThunk({
		typePrefix: "translate-page/gen-new-translate",
		apiCall: Api.translate.generateNewTranslates,
		errorMessage: "Не удалось сгенерировать новые переводы",
		showLoader: true,
		showSuccess: true,
		successMessage: "Переводы успешно сгенерированы",
	})

	static updateSourcesFromTranslates = createAutogenApiThunk({
		typePrefix: "translate-page/update-sources-from-translates",
		apiCall: Api.translate.updateSourcesFromTranslates,
		showLoader: true,
		showSuccess: true,
		successMessage: "Источники успешно обновлены",
		errorMessage: "Не удалось обновить источники",
	})

	static generateShortnames = createAutogenApiThunk({
		typePrefix: "translate-page/generate-shortnames",
		apiCall: Api.products.generateShortNames,
		showLoader: true,
		showSuccess: true,
		successMessage: "Короткие названия успешно сгенерированы",
		errorMessage: "Не удалось сгенерировать короткие названия",
	})

	static uploadTranslate = createAutogenApiThunk({
		typePrefix: "translate-page/upload-translate",
		apiCall: Api.files.uploadTranslate,
		showSuccess: true,
		showLoader: true,
		successMessage: "Переводы успешно загружены",
		errorMessage: "Не удалось загрузить переводы",
	})

	static getCatalogInfoAsZip = createAutogenApiThunk<
		{ catalogId: number; websiteId: number; languageId: number },
		void
	>({
		typePrefix: "translate-page/get-catalog-info-as-zip",
		apiCall: Api.files.getCatalogInfoAsZip,
		showLoader: true,
		errorMessage: "Не удалось скачать архив с информацией о каталоге",
	})
}
