import { keyDownMessage } from "../../../../../../utils/KeysCodes"
import { AdLoadingRow } from "../../../../../../library/tables/AdLoadingRow"
import { AdNotFoundRow } from "../../../../../../library/tables/AdNotFoundRow"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import AdDefaultInput from "../../../../../../library/inputs/AdDefaultInput"
import { IToolsetProduct } from "../../../../../../domain/types"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { ToolsetComponentSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/toolsetComponentSelectors"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../../domain/GroupMembership"
import useAuth from "../../../../../../hooks/authorizationHook"
import { Role } from "../../../../../../domain/Role"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

interface IToolsetProductsProps {
	toolsetProductId: string
	toolsetName: string
}

export function ToolsetProductsTable(props: IToolsetProductsProps) {
	const group = useSelector(GroupPageSelectors.getGroup)
	const products = useSelector(ToolsetComponentSelectors.getProducts)
	const toolsetProducts = useSelector(
		ToolsetComponentSelectors.getToolsetProducts
	)
	const isToolsetProductsLoading = useSelector(
		ToolsetComponentSelectors.getIsToolsetProductsLoading
	)
	const selectedToolsetProduct = useSelector(
		ToolsetComponentSelectors.getSelectedToolsetProduct
	)

	const dispatch = useDispatch()

	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlToolsetProducts
	)

	const setSelectedToolsetProduct = (productId: string | null) => {
		if (!canAccess) return
		if (productId === selectedToolsetProduct?.productId) {
			dispatch(actions.setSelectedToolsetProduct(null))
		} else {
			dispatch(actions.setSelectedToolsetProduct(productId))
		}
	}

	const changeAssignmentByIds = (curId: string, newId: string) => {
		if (!canAccess) return
		if (curId === newId) return
		const product = products.find((x) => x.id === newId)
		if (product === undefined) {
			dispatch(informerActions.inform("Введен неверный артикул"))
			return
		}
		dispatch(
			ToolsetComponentThunks.changeAssignment({
				productGroupId: group.id,
				productId: curId,
				toolsetId: props.toolsetProductId,
				anotherProductId: newId,
			})
		)
	}

	return (
		<table
			className={"u-table"}
			id="tpb-table"
			tabIndex={0}
			onKeyDown={(e) => {
				keyDownMessage(e, "tpb-table", dispatch)
			}}
		>
			<thead className={"u-thead"}>
				<tr>
					<th>Порядок</th>
					<th>Код</th>
					<th>Набор</th>
					<th>Наименование</th>
					<th>Раздел</th>
				</tr>
			</thead>
			<tbody className={"u-tbody"}>
				{isToolsetProductsLoading ? (
					<AdLoadingRow colSpan={7} />
				) : toolsetProducts.isEmpty() ? (
					<AdNotFoundRow colSpan={7} />
				) : (
					_.orderBy(toolsetProducts, (x) => x.sort).map((x) => (
						<Row
							key={x.productId}
							isSelected={
								selectedToolsetProduct?.productId ===
								x.productId
							}
							toolsetProductId={props.toolsetProductId}
							toolsetName={props.toolsetName}
							toolsetProduct={x}
							onRowClicked={(e) => setSelectedToolsetProduct(e)}
							onEnter={(currentId, newId) =>
								changeAssignmentByIds(currentId, newId)
							}
							isDisabled={!canAccess}
						/>
					))
				)}
			</tbody>
		</table>
	)
}

function Row(props: IRowProps) {
	const [article, setArticle] = useState(props.toolsetProduct.productId)

	useEffect(() => {
		setArticle(props.toolsetProduct.productId)
	}, [props.toolsetProduct])

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			props.onEnter(props.toolsetProduct.productId, article)
		} else if (event.key === "Escape") {
			setArticle(props.toolsetProduct.productId)
		}
	}

	return (
		<tr
			key={props.toolsetProduct.productId}
			onClick={() => props.onRowClicked(props.toolsetProduct.productId)}
			className={props.isSelected ? "table-row--selected" : ""}
		>
			<td>{props.toolsetProduct.sort}</td>
			<td>
				<AdDefaultInput
					disabled={props.isDisabled}
					value={article}
					onChange={(e) => setArticle(e)}
					onKeyUp={(e) => onKeyUp(e)}
				/>
			</td>
			<td>{props.toolsetName}</td>
			<td>{props.toolsetProduct.name}</td>
			<td>{props.toolsetProduct.pictogramName}</td>
		</tr>
	)
}

interface IRowProps {
	isSelected: boolean
	toolsetProductId: string
	toolsetName: string
	toolsetProduct: IToolsetProduct
	onRowClicked: (id: string) => void
	onEnter: (currentId: string, newId: string) => void
	isDisabled?: boolean
}
