import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

import { reducer as groupsReducer } from "./groupList"
import { reducer as productsReducer } from "./productList"
import { IWebsite } from "../../../../domain/types"

export type ProductsPageCommonState = {
	chosenPriceGroup: number | null
	//поле нужно для оптимизации рендера списка групп при нажатии на продукт
	//Если использовать selectedProducts.length, то при нажатии на продукт будет меняться селектор у ProductGroupList и ExpandedProductGroupTable
	//будет получать новые пропсы и перерендериваться
	isProductChecked: boolean
	selectedWebsite: IWebsite | null
}

const INITIAL_STATE: ProductsPageCommonState = {
	chosenPriceGroup: null,
	isProductChecked: false,
	selectedWebsite: null,
}

const slice = createSlice({
	name: "productsPage",
	initialState: INITIAL_STATE,
	reducers: {
		setChosenPriceGroup(
			state: ProductsPageCommonState,
			{ payload }: PayloadAction<number | null>
		) {
			state.chosenPriceGroup = payload
		},
		setProductChecked(
			state: ProductsPageCommonState,
			{ payload }: PayloadAction<boolean>
		) {
			state.isProductChecked = payload
		},
		setSelectedWebsite(
			state: ProductsPageCommonState,
			{ payload }: PayloadAction<IWebsite | null>
		) {
			state.selectedWebsite = payload
		},
	},
})

const actions = slice.actions
const reducer = combineReducers({
	common: slice.reducer,
	products: productsReducer,
	groups: groupsReducer,
})

export { actions, reducer }
