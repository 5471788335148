import React from "react"
import { useState } from "react"
import { SvgIcon } from "../icons/SvgIcon"

interface AdDefaultInputProps {
	label?: string
	helperText?: string
	successText?: string
	errorText?: string
	placeholder?: string
	value: string
	onChange?: (newValue: string) => void
	onKeyUp?: (any) => void
	onKeyDown?: (any) => void
	disabled?: boolean
	className?: string
	status?: AdInputStatus
	type?: "password" | "text"
	noControls?: boolean
	size?: number
	autoFocus?: boolean,
	style?: any
}

export enum AdInputStatus {
	default = 0,
	error = 1,
	success = 2,
}

const AdInputDefaultStyles: { [key in AdInputStatus]?: string } = {
	[AdInputStatus.default]: "",
	[AdInputStatus.error]: "error",
	[AdInputStatus.success]: "success",
}

export default function AdDefaultInput(props: AdDefaultInputProps) {
	const [type, setType] = useState(props.type || "text")
	const [img, setImg] = useState(
		props.type == "text" || props.type == undefined ? "circle-x" : "eye"
	)
	const iconClassName =
		"u-icon input-icon " +
		((props.type == "text" || props.type == undefined) &&
		props.value.length == 0
			? "--hide "
			: "") +
		(props.type == "text" || props.type == undefined
			? "text-input-icon "
			: "")

	const infoText =
		(props.status === AdInputStatus.error && props.errorText) ||
		(props.status === AdInputStatus.success && props.successText) ||
		(props.status === AdInputStatus.default && props.helperText)

	return (		
		<div style={props.style}>
			<div
			className={
				props.className +
				" " +
				AdInputDefaultStyles[props.status || AdInputStatus.default] +
				" u-input" +
				(props.noControls ? " no-controls" : "")
			}
		>
			<label>
				{props.label && <span className="label">{props.label}</span>}
				<div className="input-with-button-wrapper">
					<input
						type={type}
						value={props.value}
						onChange={(e) => {
							if (props.onChange)
								props.onChange(e.currentTarget.value)
						}}
						onKeyUp={(e) => {
							if (props.onKeyUp) props.onKeyUp(e)
						}}
						onKeyDown={(e) => {
							if (props.onKeyDown) props.onKeyDown(e)
						}}
						disabled={props.disabled}
						placeholder={props.placeholder}
						size={props.size}
						autoFocus={props.autoFocus ?? false}
					/>
					<SvgIcon
						name={img}
						className={iconClassName}
						onClick={() => {
							if (
								props.type == "text" ||
								props.type == undefined
							) {
								if (props.onChange) props.onChange("")
							}
							if (
								type == "password" &&
								props.type == "password"
							) {
								setImg("eye-close")
								setType("text")
							}
							if (type == "text" && props.type == "password") {
								setImg("eye")
								setType("password")
							}
						}}
					/>
					<SvgIcon
						name="circle-checked"
						className={
							"u-icon input-icon input-success-icon" +
							(props.type == "password" ? " --hide" : "")
						}
					/>
					<SvgIcon
						name="alert-circle"
						className={
							"u-icon input-icon input-alert-icon" +
							(props.type == "password" ? " --hide" : "")
						}
					/>
				</div>
			</label>
			{infoText && <div className="info-text">{infoText}</div>}
		</div>
		</div>
	)
}
