import NullableSelect from "../../app/common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import { SvgIcon } from "../icons/SvgIcon"
import React from "react"
import {
	IEntityScopeOfApplication,
	IScopeOfApplication,
} from "../../domain/models/scopes/IScopeOfApplication"

/**
 * Управляет областями применения у продуктов и групп продуктов
 **/
function AdScopeApplication(props: {
	canControl: boolean
	selectedScope: IScopeOfApplication | null
	scopes: IScopeOfApplication[]
	currentScopes: IEntityScopeOfApplication[]

	addScope: () => void
	changeScope: () => void
	removeScope: () => void

	setSelectedScope: (id: number) => void
	setSelectedCurrentScope: (id: number) => void
	canAddScope: boolean
	canChangeScope: boolean
	canRemoveScope: boolean
	canChooseScope: boolean
}) {
	return (
		<>
			<div className="u-droppod-section-header header-with-toolbar">
				Область применения
				{props.canControl ? (
					<>
						<div className="u-toolbar">
							<div className="toolbar-row">
								<NullableSelect
									value={props.selectedScope}
									isDisabled={
										!props.canControl ||
										!props.canChooseScope
									}
									options={props.scopes}
									onChange={(value) =>
										props.setSelectedScope(value as number)
									}
									toOption={ToOptionProvider.scopeToOption}
									className={"selector"}
									height={31}
									placeholder={"Выберите область применения"}
								/>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										if (!props.canControl) return
										props.addScope()
									}}
									disabled={!props.canAddScope}
								>
									<SvgIcon name="circle-plus" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										if (!props.canControl) return
										props.changeScope()
									}}
									disabled={!props.canChangeScope}
								>
									<SvgIcon name="pencil-create" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => {
										if (!props.canControl) return
										props.removeScope()
									}}
									disabled={!props.canRemoveScope}
								>
									<SvgIcon name="circle-x" />
								</button>
							</div>
						</div>
					</>
				) : null}
			</div>

			<div className="u-table__scroll-wrapper">
				<table className="u-table">
					{props.currentScopes.isEmpty() ? (
						<tbody className="u-tbody">
							<tr className="top-border">
								<td colSpan={2}>Пока ничего нет</td>
							</tr>
						</tbody>
					) : (
						<>
							<thead className="u-thead">
								<tr>
									<th>Код</th>
									<th>Наименование</th>
								</tr>
							</thead>
							<tbody className="u-tbody">
								{props.currentScopes.map((x) => {
									return (
										<tr
											key={x.id}
											className={
												x.selected
													? "table-row--selected"
													: ""
											}
											onClick={() => {
												if (!props.canControl) return
												props.setSelectedCurrentScope(
													x.id
												)
											}}
										>
											<td>{x.id}</td>
											<td>{x.name}</td>
										</tr>
									)
								})}
								<tr></tr>
							</tbody>
						</>
					)}
				</table>
			</div>
		</>
	)
}

export default AdScopeApplication
