export class SellmarkToCatalogProvider{
	static sellMarkToCatalogProxy(prop: number): number {
		const sellMarkToCatalog : Map<number, number> = new Map<number, number>([
			[1, 23],
			[2, 28],
			[3, 29],
			[10, 31]
		])
		const result = sellMarkToCatalog.get(prop);
		if (result !== undefined) 
			return result;
		else 
			return 5;
	}
}