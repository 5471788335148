import { combineReducers } from "redux"
import { reducer as authReducer } from "./auth"
import { reducer as langReducer } from "./languages"
import { reducer as priceGroupReducer } from "./priceGroups"
import { reducer as catalogReducer } from "./catalogs"
import { reducer as sellmarksReducer } from "./sellmarks"
import { reducer as catalogGroupReducer } from "./catalogGroups"
import { reducer as distributionTypesReducer } from "./distributionsTypes"
import { reducer as categoriesReducer } from "./categories"
import { reducer as webSiteReducer } from "./webSites"
import { reducer as optimisticControllerReducer } from "./optimisticController"
import { reducer as informerReducer } from "./informer"
import { reducer as pictogramsReducer } from "./pictograms"
import { reducer as imageTypesReducer } from "./imageTypes"
import { reducer as acquireMutexReducer } from "./aquireMutex"
import { reducer as messageBusReducer } from "./messageBus"
import { reducer as productsReducer } from "./pages/productsPage"
import { reducer as guideReducer } from "./pages/guidePage"
import pageReducer from "./pages"

const rootReducer = combineReducers({
	authState: authReducer,
	acquirePageState: acquireMutexReducer,
	languageState: langReducer,
	priceGroupsState: priceGroupReducer,
	catalogState: catalogReducer,
	sellmarkState: sellmarksReducer,
	catalogGroupState: catalogGroupReducer,
	distributionTypesState: distributionTypesReducer,
	categoriesState: categoriesReducer,
	websitesState: webSiteReducer,
	optimisticControllerState: optimisticControllerReducer,
	pages: pageReducer,
	informerState: informerReducer,
	pictogramsState: pictogramsReducer,
	imageTypesState: imageTypesReducer,
	messageBus: messageBusReducer,
	products: productsReducer,
	guide: guideReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
