import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IImageType } from "../../domain/models/imageTypes/ImageType"
import { INamedImage } from "../../domain/models/images/NamedImage"

export default class ImageApi extends BaseApi {
	getImageTypes = (): Promise<IApplicationResponse<IImageType[]>> =>
		this.sendQuery<IImageType[]>(
			"/api/images/types",
			null,
			actionTypes.get,
			true
		)

	getImages = (args: {
		productGroupId: string
	}): Promise<IApplicationResponse<INamedImage[]>> =>
		this.sendQuery<INamedImage[]>(
			"/api/images",
			args,
			actionTypes.get,
			true
		)

	getProductImages = (args: {
		productId: string
	}): Promise<IApplicationResponse<INamedImage[]>> =>
		this.sendQuery<INamedImage[]>(
			"/api/images/product",
			args,
			actionTypes.get,
			true
		)

	uploadImage = (args: {
		productGroupId: string
		imageType: number
		imageName: string | null
	}): Promise<IApplicationResponse<INamedImage>> =>
		this.sendQuery<INamedImage>("/api/images", args, actionTypes.post, true)

	uploadProductImage = (args: {
		productId: string
		imageType: number
		imageName: string | null
	}): Promise<IApplicationResponse<INamedImage>> =>
		this.sendQuery<INamedImage>(
			"/api/images/product",
			args,
			actionTypes.post,
			true
		)

	addVideo = (args: {
		productGroupId: string
		videoUrl: string
	}): Promise<IApplicationResponse<string>> =>
		this.sendQuery<string>(
			"/api/images/video",
			args,
			actionTypes.post,
			true
		)

	addProductVideo = (args: {
		productId: string
		videoUrl: string
	}): Promise<IApplicationResponse<INamedImage>> =>
		this.sendQuery<INamedImage>(
			"/api/images/video/product",
			args,
			actionTypes.post,
			true
		)

	removeImage = (args: {
		productGroupId: string
		imageType: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>("/api/images", args, actionTypes.delete, true)

	removeProductImage = (args: {
		productId: string
		imageType: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/images/product",
			args,
			actionTypes.delete,
			true
		)

	updateImage = (args: {
		productGroupId: string
		imageType: number
		imageName: string
	}): Promise<IApplicationResponse<INamedImage>> =>
		this.sendQuery<INamedImage>("/api/images", args, actionTypes.put, true)

	updateImageType = (args: {
		groupId: string
		currentTypeId: number
		targetTypeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/images/update-image-type",
			args,
			actionTypes.put,
			true
		)

	updateProductImageType = (args: {
		productId: string
		currentTypeId: number
		targetTypeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/images/update-product-image-type",
			args,
			actionTypes.put,
			true
		)

	getProductsImagesAsZip = (data: {
		productId: string
	}): Promise<IApplicationResponse<void>> =>
		this.downloadFile("/api/images/product/zip", data, true)
}
