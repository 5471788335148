import React from "react"
import "./product.scss"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { actions as productListActions } from "../../redux/reducers/pages/productsPage/productList"
import { actions as productGroupsListActions } from "../../redux/reducers/pages/productsPage/groupList"
import ProductListToolbar from "./productList/ProductListToolbar"
import ProductListTable from "./productList/ProductListTable"
import ProductGroupListToolbar from "./productGroupList/ProductGroupListToolbar"
import ProductGroupListTable from "./productGroupList/ProductGroupListTable"

function Products() {
	const dispatch = useDispatch()

	useLayoutEffect(() => {
		return function () {
			dispatch(productListActions.clearStateOnUnmount())
			dispatch(productGroupsListActions.clearStateOnUnmount())
		}
	}, [])

	return (
		<>
			<div className="product-left-column u-droppod">
				<ProductListToolbar />
				<ProductListTable />
			</div>
			<div className="product-right-column u-droppod">
				<div className="u-droppod-header">
					<div className="header-text">Группы товаров</div>
				</div>
				<ProductGroupListToolbar />
				<ProductGroupListTable />
			</div>
		</>
	)
}

export default Products
