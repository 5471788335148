import { IOptionType } from "../app/common/basic/selectors/SimpleSelect"
import { GroupStatisticFilter } from "./GroupStatisticFilter"

export interface ICatalog {
	id: number
	name: string
	isPrinted: boolean
}

export interface ICategorySortBorder {
	id: number
	minSort: number
	maxSort: number
}

export interface IProductGroup {
	id: string
	name: string
	description: string
	descriptionWeb: string
	attributesColumnOrder: { [key: number]: IGroupAttributeDescr }
	seriesId: number | null
	signId: number | null
	sellmarkId: number | null
	priceGroupId: number | null
	mainAttributeId: number | null
	isDescriptionChecked: boolean
	isToolset: boolean
	isImageChecked: boolean
	siteId: number
	wasCreate: boolean
}

export interface IGroupAttributeDescr {
	sort: number
	isImportant: boolean
	importantSort: number | null
}

export interface ISeries {
	id: number
	name: string
	titleEng: string
	imageUrl: string
}

export interface ISign {
	id: number
	name: string
	imageUrl: string
}

export interface IAttribute {
	id: number
	name: string
}

export interface IProductGroupScope {
	productGroupId: string
	scopeId: number
}

export interface IGroupWithCategories {
	productGroupId: string
	categoryPath: number[]
	mainCategory: boolean | null
}

export interface IProductWithCategories {
	productId: string
	categoryPath: number[]
	mainCategory: boolean | null
}

export enum DistributionType {
	NotDistributed = 1,
	Distributed = 2,
}

export enum CatalogGroup {
	Web = 0,
	Printed = 1,
	Web_Latvia = 3,
	All = 100,
}

export enum WebCategoryType {
	Ru = 1,
	Rg = 2,
	WebShop = 3,
}

export interface IGroupRecommendation {
	productGroupId: string
	recommendations: IRecommendation[]
}

export interface IRecommendation {
	productId: string
	name: string
	sort: number
}

export interface ICatalogGroup {
	id: number
	name: string
}

export interface IWebCategoryType {
	id: number
	name: string
}

export interface ICatalogGroupOption extends ICatalogGroup {
	isTree: boolean
}

export interface ILanguage {
	id: number
	name: string
}

export interface IProductGroupIdentity {
	id: string
	name: string
	isDescriptionChecked: boolean
	isImageChecked: boolean
	priceGroupId: number | null
	sort: number | null
}

export interface IImagesCatalogGroups {
	[key: number]: {
		[key: number]: string
	}
}

export interface IProductGroupIdentityWithCategory
	extends IProductGroupIdentity {
	categoryId: number
	catalogCategoryId: number | null
}

export interface IProductGroupCatalog {
	productGroupId: string
	catalogId: number
	catalogName: string
	catalogCategoryId: number | null
	catalogParentCategoryId: number | null
	webCategoryId: number | null
	sort: number | null
	showStatus: boolean
}

export interface IPictogram {
	id: number
	name: string
	imageUrl: string
	sort: number
	isSet: boolean
}

export interface IProductGroupSort {
	id: string
	sort: number
}

export interface ITranslate {
	translateId: number
	russian: string
	translate: string
	source: TranslateSource
	sourceId: string
	additionalInfo?: Record<string, string>
}

export interface INotReadyToDeployGroup {
	productGroupId: string
	name: string
	reason: DeployException
}

export enum DeployException {
	ShowStatus,
	NameNotTranslated,
	NoDescriptionTranslated,
	NotCheckedDescription,
	NotCheckedImage,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeployException {
	export function getMessage(ex: DeployException): string {
		let exName
		switch (ex) {
			case DeployException.NameNotTranslated:
				exName = "Наименование не переведено"
				break
			case DeployException.NoDescriptionTranslated:
				exName = "Описание не переведено"
				break
			case DeployException.NotCheckedDescription:
				exName = "Описание не проверено"
				break
			case DeployException.ShowStatus:
				exName = "Продукт помечен, как не предназначенный для загрузки"
				break
			case DeployException.NotCheckedImage:
				exName = "Не проверено изображение"
				break
		}
		return exName
	}
}

export enum TranslateSource {
	All = 0,
	AttrNames = 1,
	CatKat = 2,
	CatWeb = 3,
	GroupName = 4,
	PictGroup = 5,
	PictGroupDesc = 6,
	ValueDescription = 7,
}

export interface ITranslateWithUrl extends ITranslate {
	symbolCount: number
	url: string
}

export interface ITranslateValuesByTranslator {
	original: string
	translated: string
}

export interface ITranslatedValue {
	translateId: number
	sourceId: string
	sourceTable: TranslateSource
	translate: string
}

export interface IToolsetProduct {
	productId: string
	pictogramId: number | null
	sort: number
	name: string
	pictogramName: string
}

export interface IToolsetProductIdentity {
	productId: string
	pictogramId: number | null
	sort: number
}

export type ICardDistributionType = IOptionType

export interface IGroupStatisticFilterType {
	label: string
	value: GroupStatisticFilter
}

export interface IProductIdentity {
	id: string
	name: string
	priceGroupId: number
}

export interface IProductBase extends IProductIdentity {
	sort: number | null
}

export interface IProductWithAttributes extends IProductIdentity {
	stockRus: number | null
	isInStatistic: boolean
	sort: number | null
	attributeValues: IAttributeValue[]
}

export interface IAttributeValue {
	id: number
	value: string
}

export interface IPriceGroup {
	id: number
	name: string
}

export interface ISellmark {
	id: number
	name: string
	imageUrl?: string | null
}

export interface IWebsite {
	id: number
	name: string
	schema: WebsiteSchema
}

export interface IStatistic {
	id: number
	createdAtUtc: string
	invoiceCount: number
	approvedImageCount: number
	pendingImageCount: number
	declinedImageCount: number
	approvedProductCount: number
	pendingProductCount: number
	declinedProductCount: number
	readyToShipInvoiceCount: number
	inProcessInvoiceCount: number
	approvedImageInvoiceCount: number
	approvedImageProductCount: number
	pendingImageInvoiceCount: number
	pendingImageProductCount: number
	pendingCheckImageCount: number
	pendingCheckImageInvoiceCount: number
	pendingCheckImageProductCount: number
	declinedImageInvoiceCount: number
	declinedImageProductCount: number
}

export enum WebsiteSchema {
	Russian = 1,
	Latvian = 2,
	ProductSite = 3,
}
