import {createSelector} from "@reduxjs/toolkit"
import {AppState} from "../../../index"

export class ProductListSelectors {

    static getFilter = (state: AppState) => state.pages.productsPage.products.filter
    private static getProducts = (state: AppState) => state.pages.productsPage.products.products

    private static getFilteredProductsByNameMemoized = createSelector([this.getProducts,
        this.getFilter],
    (products, filter) => (filter === ""
        ? products
        : products.filter(product => (product.name != null
            && product.name.toLowerCase().includes(filter.toLowerCase()) || (
              product.id != null && product.id.toLowerCase().includes(filter.toLowerCase())
        )))))

    static getFilteredProductsMemoized = createSelector(
        [this.getFilteredProductsByNameMemoized,
            (state: AppState) => state.pages.productsPage.common.chosenPriceGroup],
        (products, priceGroup) => priceGroup != null
            ? products.filter(x => x.priceGroupId == priceGroup)
            : products)

    static isLoading = (state: AppState) => state.pages.productsPage.products.isLoading
    static getSelectedProducts = (state: AppState) => state.pages.productsPage.products.selectedProducts
}