import { createSlice } from "@reduxjs/toolkit"
import { WatchtowerPageThunk } from "./thunk"
import { IStatistic } from "../../../../domain/types"

export type WatchtowerState = {
	statistic: IStatistic
}
const INITIAL_STATE: WatchtowerState = {
	statistic: {
		id: 0,
		createdAtUtc: "",
		invoiceCount: 0,
		approvedImageCount: 0,
		pendingImageCount: 0,
		declinedImageCount: 0,
		approvedProductCount: 0,
		pendingProductCount: 0,
		declinedProductCount: 0,
		readyToShipInvoiceCount: 0,
		inProcessInvoiceCount: 0,
		approvedImageInvoiceCount: 0,
		approvedImageProductCount: 0,
		pendingImageInvoiceCount: 0,
		pendingImageProductCount: 0,
		pendingCheckImageCount: 0,
		pendingCheckImageInvoiceCount: 0,
		pendingCheckImageProductCount: 0,
		declinedImageInvoiceCount: 0,
		declinedImageProductCount: 0,
	},
}
const slice = createSlice({
	name: "watchtower",
	initialState: INITIAL_STATE,
	reducers: {
		clearStateOnUnmount() {
			return { ...INITIAL_STATE }
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			WatchtowerPageThunk.getLastStatistic.fulfilled,
			(state, action) => {
				state.statistic = action.payload
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
