import {AppState} from "../../../index"
import {createSelector} from "@reduxjs/toolkit"

export class GroupListSelectors {
    static getGroups = (state: AppState) => state.pages.productsPage.groups.groups
    static getFilteredByPriceGroupsMemoized = createSelector(
        [this.getGroups,
            (state: AppState) => state.pages.productsPage.common.chosenPriceGroup],
        (groups, priceGroup) =>
            priceGroup != null
                ? groups.filter(x => x.priceGroupId === priceGroup)
                : groups)

    static getLoadedGroupsLength = createSelector([this.getGroups], groups => groups.length)
    static getIsGroupsLoading = (state: AppState) => state.pages.productsPage.groups.isGroupsLoading
    static getFilter = (state: AppState) => state.pages.productsPage.groups.filter
    static getSelectedCardType = (state: AppState) => state.pages.productsPage.groups.selectedCardType
    static getCardTypes = (state: AppState) => state.pages.productsPage.groups.cardTypes
    static getGroupFilterTypes = (state: AppState) => state.pages.productsPage.groups.groupStatisticFilterTypes
    static getSelectedGroup = (state: AppState) => state.pages.productsPage.groups.selectedGroup
    static getSelectedGroupStatisticFilter = (state: AppState) => state.pages.productsPage.groups.selectedGroupStatisticFilter
    static getIsImagesUpdating = (state: AppState) => state.pages.productsPage.groups.isImagesUpdating
}