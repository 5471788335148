import React, { useEffect, useLayoutEffect } from "react"
import "./categories.scss"
import ProductGroupList from "./productGroupsList/ProductGroupList"
import CategoryForm from "./CategoryForm"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/reducers/pages/categoryPage"
import RouteProvider from "../../utils/RouteProvider"
import Protector from "../../library/smartComponents/authorization/Protector"
import { CategoriesPageSelector } from "../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import CategoryImages from "./groupImages/CategoryImages"
import { CategoriesSelectors } from "../../redux/reducers/categories/categoriesSelectors"

export default function CategoriesPage() {
    const isShowImageSettings = useSelector(CategoriesSelectors.isShowImageSettings)

    const dispatch = useDispatch()

    useLayoutEffect(() => {
        return function () {
            dispatch(actions.clearStateOnUnmount())
        }
    }, [])

    return <Protector availableRoles={RouteProvider.categories.roles!}>
        <div className={`page-categories ${isShowImageSettings ? "image-settings" : ""}`}>
            <div className="default-catagories">
                <ProductGroupList />
                <CategoryFormWrapper />
            </div>
            {isShowImageSettings && <div><CategoryImages /></div>}
        </div>
    </Protector>
}

function CategoryFormWrapper() {
    const catPath = useSelector(CategoriesPageSelector.getCategoriesPathMemoized)
    return <CategoryForm highlightedCategories={catPath} />
}