import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { ProductRelatedProductsSelectors } from "../../../redux/reducers/pages/productPage/relatedProducts/ProductRelatedProductsSelectors"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { actions as informerActions } from "../../../redux/reducers/informer"
import React, { useEffect, useRef } from "react"
import NullableSelect from "../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { keyDownMessage } from "../../../utils/KeysCodes"
import { actions } from "../../../redux/reducers/pages/productPage/relatedProducts"
import { AdLinkProductsModal } from "../../../library/modal/AdLinkProductsModal"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export default function ProductRelatedProducts() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	const language = useSelector(LanguageSelectors.getSelected)
	if (!productId) return <></>

	const [shouldShowModal, setShouldShowModal] = React.useState(false)

	const dispatch = useDispatch()
	const ref = useRef<HTMLDivElement>(null)

	const products = useSelector(
		ProductRelatedProductsSelectors.getPossibleRelatedProducts
	)
	const related = useSelector(
		ProductRelatedProductsSelectors.getRelatedProducts
	)
	const selectedProduct = useSelector(
		ProductRelatedProductsSelectors.getSelectedProduct
	)
	const canAddRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanAddRelatedProducts
	)
	const canChangeRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanChangeRelatedProducts
	)
	const canRemoveRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanRemoveRelatedProducts
	)
	const canSwapSortRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanSwapSortRelatedProducts
	)

	const canAddVideoRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanAddVideoRelatedProducts
	)
	const canChooseRelatedProducts = useSelector(
		ProductPageAuthSelectors.getCanChooseRelatedProducts
	)
	useEffect(() => {
		dispatch(
			ProductPageThunks.getPossibleRelatedProducts({
				productId: productId,
				languageId: language.id,
			})
		)
		dispatch(
			ProductPageThunks.getRelatedProducts({
				productId: productId,
				languageId: language.id,
			})
		)
	}, [language.id])

	const setSelectedProduct = (id: string) =>
		dispatch(actions.setSelectedProduct(id))

	const setRelatedSelected = (id: string) =>
		dispatch(actions.setRelatedSelected(id))

	const addRelated = () => {
		if (!selectedProduct) {
			dispatch(informerActions.inform("Выберите товар для добавления"))
			return
		}

		addManyRelated([selectedProduct.id])
	}

	const addManyRelated = (relatedProducts: string[]) => {
		if (relatedProducts.length === 0) return

		dispatch(
			ProductPageThunks.addRelatedProduct({
				productId: productId,
				relatedProductsIds: relatedProducts,
			})
		)
	}

	const removeRelated = () => {
		const productToRemove = related.find((p) => p.selected)
		if (!productToRemove) {
			dispatch(
				informerActions.inform(
					"Выберите сопутствующий товар для удаления"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.removeRelatedProduct({
				productId: productId,
				relatedProductId: productToRemove.relatedId,
			})
		)
	}

	const replaceRelatedProduct = () => {
		const productToChange = related.find((p) => p.selected)
		if (!productToChange) {
			dispatch(
				informerActions.inform(
					"Выберите сопутствующий товар для изменения"
				)
			)
			return
		}

		if (!selectedProduct) {
			dispatch(
				informerActions.inform(
					"Выберите товар, который заменит текущий"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.replaceRelatedProduct({
				productId: productId,
				currentRelatedId: productToChange.relatedId,
				targetRelatedId: selectedProduct.id,
			})
		)
	}

	const swapSort = (addition: number) => {
		const productToChange = related.find((p) => p.selected)
		if (!productToChange) {
			dispatch(informerActions.inform("Выберите сопутствующий товар"))
			return
		}

		const target = related.find(
			(p) => p.sort === productToChange.sort + addition
		)
		if (!target) {
			dispatch(
				informerActions.inform(
					"Целевое значение выходит за пределы сортировки"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.swapRelatedProductSort({
				productId: productId,
				currentRelatedId: productToChange.relatedId,
				targetRelatedId: target.relatedId,
			})
		)
	}

	return (
		<>
			<div
				ref={ref}
				className="u-droppod-section-header header-with-toolbar"
			>
				Сопутствующие товары
				<div className="u-toolbar">
					<div className="toolbar-row">
						<NullableSelect
							value={selectedProduct}
							options={products}
							onChange={(id) => {
								setSelectedProduct(id as string)
							}}
							toOption={ToOptionProvider.productIdentityToOption}
							className={"selector"}
							placeholder={"Наименование товара"}
							height={31}
							isDisabled={!canChooseRelatedProducts}
							noOptionsMessage={"Нет подходящих товаров"}
						/>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canAddRelatedProducts}
							onClick={() => addRelated()}
						>
							<SvgIcon name="circle-plus" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canChangeRelatedProducts}
							onClick={replaceRelatedProduct}
						>
							<SvgIcon name="pencil-create" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canRemoveRelatedProducts}
							onClick={() => removeRelated()}
						>
							<SvgIcon name="circle-x" />
						</button>

						<button
							type="button"
							className="u-button square primary"
							disabled={!canSwapSortRelatedProducts}
							onClick={() => swapSort(-1)}
						>
							<SvgIcon name="arrow-top" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canSwapSortRelatedProducts}
							onClick={() => swapSort(1)}
						>
							<SvgIcon name="arrow-bottom" />
						</button>

						<button
							type="button"
							className="u-button square primary"
							disabled={!canAddVideoRelatedProducts}
							onClick={() => setShouldShowModal(true)}
						>
							Добавить
						</button>
					</div>
				</div>
			</div>
			{/*@ts-ignore*/}
			<div className="u-table__scroll-wrapper almost-full-table">
				<table
					className="u-table"
					id="recs-table"
					tabIndex={0}
					onKeyDown={(e) => {
						keyDownMessage(e, "recs-table", dispatch)
					}}
				>
					<thead className="u-thead">
						<tr>
							<th>Код</th>
							<th>Наименование</th>
							<th>№</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{related.length === 0 ? (
							<tr>
								<td colSpan={3}>Пока ничего нет</td>
							</tr>
						) : (
							<>
								{related.map((x) => {
									return (
										<tr
											key={x.productId}
											id={x.relatedId}
											onClick={() =>
												setRelatedSelected(x.relatedId)
											}
											className={
												x.selected
													? "table-row--selected"
													: ""
											}
										>
											<td>{x.relatedId}</td>
											<td>{x.name}</td>
											<td>{x.sort}</td>
										</tr>
									)
								})}
							</>
						)}
					</tbody>
				</table>
			</div>
			<AdLinkProductsModal
				isOpen={shouldShowModal}
				close={() => setShouldShowModal(false)}
				products={products}
				addProductsToCurrentEntity={addManyRelated}
			/>
		</>
	)
}
