import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { ProductPageWebCategorySelectors } from "../../../redux/reducers/pages/productPage/webCategories/ProductPageWebCategorySelectors"
import { useEffect } from "react"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { CatalogGroup } from "../../../domain/types"
import { ISelectableIndexModel } from "../../../redux/types"
import { ICategory } from "../../../domain/models/categories/ICategory"
import { actions as productCategoryActions } from "../../../redux/reducers/pages/productPage/webCategories"
import { actions as informerActions } from "../../../redux/reducers/informer"
import AdCategoryToSite from "../../../library/categories/AdCategoryToSite"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export function ProductWebCategories() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	const language = useSelector(LanguageSelectors.getSelected)
	if (!productId) return <></>

	const categories = useSelector(
		ProductPageWebCategorySelectors.getCategories
	)
	const selectedCategories = useSelector(
		ProductPageWebCategorySelectors.getSelectedCategories
	)
	const productCategories = useSelector(
		ProductPageWebCategorySelectors.getProductCategories
	)
	const selectedProductCategory = useSelector(
		ProductPageWebCategorySelectors.getSelectedProductCategory
	)
	const shouldResetRows = useSelector(
		ProductPageWebCategorySelectors.getShouldReset
	)
	const canAddProductCategory = useSelector(
		ProductPageAuthSelectors.getCanAddProductCategory
	)
	const canChangeProductCategory = useSelector(
		ProductPageAuthSelectors.getCanChangeProductCategory
	)
	const canRemoveProductCategory = useSelector(
		ProductPageAuthSelectors.getCanRemoveProductCategory
	)
	const canDoMainProductCategory = useSelector(
		ProductPageAuthSelectors.getCanDoMainProductCategory
	)
	const canChooseProductCategory = useSelector(
		ProductPageAuthSelectors.getCanChooseProductCategory
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			ProductPageThunks.getWebCategories({
				catalogGroup: CatalogGroup.Web,
				languageId: language.id,
			})
		)
	}, [language.id])

	useEffect(() => {
		if (!categories || categories.length === 0) return
		dispatch(
			ProductPageThunks.getProductWebCategories({
				productId: productId,
			})
		)
	}, [categories])

	const setSelectedCategory = (category: ISelectableIndexModel<ICategory>) =>
		dispatch(
			productCategoryActions.setSelectedCategory({
				rowIndex: category.index,
			})
		)

	const setRowPath = (category: ICategory | null) =>
		dispatch(productCategoryActions.setRowPath({ category }))

	const setShouldReset = () =>
		dispatch(productCategoryActions.setShouldReset())

	const addCategory = () => {
		if (!selectedCategories || selectedCategories.length === 0) {
			dispatch(
				informerActions.inform("Выберите категорию для добавления")
			)
			return
		}

		const category = selectedCategories[selectedCategories.length - 1]

		dispatch(
			ProductPageThunks.addProductToWebCategory({
				productId: productId,
				categoryId: category.id,
			})
		)
	}

	const changeCategory = () => {
		if (!selectedProductCategory) {
			dispatch(informerActions.inform("Выберите категорию для изменения"))
			return
		}
		if (!selectedCategories || selectedCategories.length === 0) {
			dispatch(
				informerActions.inform(
					"Выберите категорию из списка для изменения"
				)
			)
			return
		}

		const targetCategory = selectedCategories[selectedCategories.length - 1]
		dispatch(
			ProductPageThunks.changeProductWebCategory({
				productId: productId,
				categoryId: selectedProductCategory.id,
				newCategoryId: targetCategory.id,
			})
		)
	}

	const removeCategory = () => {
		if (!selectedProductCategory) {
			dispatch(informerActions.inform("Выберите категорию для удаления"))
			return
		}

		dispatch(
			ProductPageThunks.removeProductFromWebCategory({
				productId: productId,
				categoryId: selectedProductCategory.id,
			})
		)
	}

	const setCategoryAsMain = () => {
		if (!selectedProductCategory) {
			dispatch(
				informerActions.inform(
					"Выберите категорию, чтобы сделать ее основной"
				)
			)
			return
		}

		if (selectedProductCategory.mainCategory) return

		dispatch(
			ProductPageThunks.setCategoryAsMain({
				productId: productId,
				categoryId: selectedProductCategory.id,
			})
		)
	}

	return (
		<AdCategoryToSite
			canControl={true}
			entityCategories={productCategories}
			webCategories={categories}
			shouldReset={shouldResetRows}
			addCategory={addCategory}
			changeCategory={changeCategory}
			removeCategory={removeCategory}
			setCategoryPath={setRowPath}
			setCategoryAsMain={setCategoryAsMain}
			onCategoryRowReset={setShouldReset}
			setSelectedCategory={setSelectedCategory}
			canAddProductCategory={canAddProductCategory}
			canChangeProductCategory={canChangeProductCategory}
			canRemoveProductCategory={canRemoveProductCategory}
			canDoMainProductCategory={canDoMainProductCategory}
			canChooseProductCategory={canChooseProductCategory}
		/>
	)
}
