import React from "react"
import {AppState} from "../../../redux/reducers"
import {OptimisticControllerState} from "../../../redux/reducers/optimisticController"
import {useSelector} from "react-redux"
import {ClipLoader} from "react-spinners"

//штука, которая отслеживает запросы в "оптимистичных" блоках
export default function OptimisticController() {

    const local = useSelector<AppState, OptimisticControllerState>(x => x.optimisticControllerState)

    return local.currentQueries === 0
        ? <></>
        : <ClipLoader loading={true} size={20} color={"#dee2e6"}/>
}