/**
 * Отслеживает последний запрос и отвечает должен ли результат запроса быть обработан
 * Это нужно в таких ситуациях как например с тулсетами: можно несколько раз подряд кликнуть на разные тулсеты и их
 * продукты будут запрошены несколько раз
 **/
export default class QueryTrimmer {
    private currentQuery: string | null = null

    public addQuery(id: string) {
        this.currentQuery = id
    }

    public shouldBeProcessed(id: string) {
        if(this.currentQuery === id) {
            this.currentQuery = null
            return true
        }
        return false
    }
}