import React, { useEffect, useRef, useState } from "react"
import { SvgIcon } from "../icons/SvgIcon"
import { AdInputStatus } from "./AdDefaultInput"

interface AdDynamicTextProps {
	id?: string
	// placeholder?: string;
	value?: string
	rows?: number
	onChange?: (newValue: string) => void
	onKeyDown?: (e: any) => void
	onFocus?: (e: any) => void
	style?: any
	className?: string
	hidden?: boolean
	disabled?: boolean
	status?: AdInputStatus
}

const AdInputTextareaStyles: { [key in AdInputStatus]?: string } = {
	[AdInputStatus.default]: "",
	[AdInputStatus.error]: "error",
	[AdInputStatus.success]: "success",
}

export default function AdDynamicTextarea(props: AdDynamicTextProps) {
	const [initValue, setInitValue] = useState<string>(props.value || "")
	const [value, setValue] = useState<string>(props.value || "")
	const [init, setInit] = useState<boolean>(false)

	const ref = useRef<HTMLDivElement>(null)

	const iconClassName =
		"u-icon input-icon " +
		(value.length == 0 ? "--hide " : "") +
		AdInputTextareaStyles[props.status || AdInputStatus.default]

	useEffect(() => {
		if (!init) {
			setInit(true)
			return
		}
		if (props.onChange) {
			props.onChange(value)
		}
	}, [value])

	useEffect(() => {
		if (props.value != value) {
			setInitValue(props.value ?? "")
		}
	}, [props.value])

	function setFocusToEnd() {
		if (ref.current && ref.current.childElementCount > 1) {
			const range = document.createRange()
			const sel = window.getSelection()
			range.setStart(ref.current, 1)
			range.collapse(true)
			sel?.removeAllRanges()
			sel?.addRange(range)
			ref.current.focus()
		}
	}

	return (
		// @ts-ignore
		<div
			style={{ ...props.style, "--rows": props.rows || 3 }}
			className={
				(props.className || "") +
				" u-textarea dynamic-textarea " +
				AdInputTextareaStyles[props.status || AdInputStatus.default]
			}
			hidden={props.hidden}
		>
			<div
				id={props.id}
				role="textbox"
				ref={ref}
				className={"textarea"}
				onFocus={() => {
					setInitValue(props.value || "")
					setFocusToEnd()
				}}
				onInput={(e) => {
					setValue(e.currentTarget.textContent || "")
				}}
				contentEditable={!props.disabled}
				onKeyDown={props.onKeyDown}
				suppressContentEditableWarning={true}
			>
				{initValue}
			</div>
			<SvgIcon
				name="circle-x"
				className={iconClassName}
				onClick={() => {
					if (ref.current) ref.current.textContent = ""
					setValue("")
				}}
			/>

			<SvgIcon
				name="circle-checked"
				className={"u-icon input-icon input-success-icon"}
			/>
			<SvgIcon
				name="alert-circle"
				className={"u-icon input-icon input-alert-icon"}
			/>
		</div>
	)
}

