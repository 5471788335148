export function getNameAndExtension(
	filename: string
): [name: string, extension: string] {
	const extension =
		filename.substring(filename.lastIndexOf(".") + 1, filename.length) || ""
	const name = filename.substring(0, filename.lastIndexOf(".")) || ""
	return name != "" ? [name, extension] : [filename, ""]
}

export function getFullname(name: string, extension: string): string {
	return `${name}.${extension}`
}
