import { ReactComponent as AlertCircle } from "../../assets/icons/i-alert-circle.svg"
import { ReactComponent as ArrowBottom } from "../../assets/icons/i-arrow-bottom.svg"
import { ReactComponent as ArrowLeft } from "../../assets/icons/i-arrow-left.svg"
import { ReactComponent as ArrowRight } from "../../assets/icons/i-arrow-right.svg"
import { ReactComponent as ArrowTop } from "../../assets/icons/i-arrow-top.svg"
import { ReactComponent as ArrowUndo } from "../../assets/icons/i-arrow-undo.svg"
import { ReactComponent as Calculator } from "../../assets/icons/i-calculator.svg"
import { ReactComponent as Camera } from "../../assets/icons/i-camera.svg"
import { ReactComponent as Cart } from "../../assets/icons/i-cart.svg"
import { ReactComponent as ChevronBottom } from "../../assets/icons/i-chevron-bottom.svg"
import { ReactComponent as ChevronLeft } from "../../assets/icons/i-chevron-left.svg"
import { ReactComponent as ChevronRight } from "../../assets/icons/i-chevron-right.svg"
import { ReactComponent as ChevronTop } from "../../assets/icons/i-chevron-top.svg"
import { ReactComponent as ChevronsRight } from "../../assets/icons/i-chevrons-right.svg"
import { ReactComponent as Circle } from "../../assets/icons/i-circle.svg"
import { ReactComponent as CircleChecked } from "../../assets/icons/i-circle-checked.svg"
import { ReactComponent as CirclePlus } from "../../assets/icons/i-circle-plus.svg"
import { ReactComponent as CircleX } from "../../assets/icons/i-circle-x.svg"
import { ReactComponent as ClipboardText } from "../../assets/icons/i-clipboard-text.svg"
import { ReactComponent as Close } from "../../assets/icons/i-close.svg"
import { ReactComponent as Download } from "../../assets/icons/i-download.svg"
import { ReactComponent as Eye } from "../../assets/icons/i-eye.svg"
import { ReactComponent as EyeClose } from "../../assets/icons/i-eye-close.svg"
import { ReactComponent as FileDelete } from "../../assets/icons/i-file-delete.svg"
import { ReactComponent as FileDownload } from "../../assets/icons/i-file-download.svg"
import { ReactComponent as Floppy } from "../../assets/icons/i-floppy.svg"
import { ReactComponent as Info } from "../../assets/icons/i-info.svg"
import { ReactComponent as Link } from "../../assets/icons/i-link.svg"
import { ReactComponent as Loader } from "../../assets/icons/i-loader.svg"
import { ReactComponent as Logout } from "../../assets/icons/i-logout.svg"
import { ReactComponent as MoreHorizontal } from "../../assets/icons/i-more-horizontal.svg"
import { ReactComponent as NoteText } from "../../assets/icons/i-note-text.svg"
import { ReactComponent as PaperText } from "../../assets/icons/i-paper-text.svg"
import { ReactComponent as PaperClip } from "../../assets/icons/i-paperclip.svg"
import { ReactComponent as PencilCreate } from "../../assets/icons/i-pencil-create.svg"
import { ReactComponent as PlayCircle } from "../../assets/icons/i-play-circle.svg"
import { ReactComponent as Plus } from "../../assets/icons/i-plus.svg"
import { ReactComponent as RefreshCw } from "../../assets/icons/i-refresh-cw.svg"
import { ReactComponent as Search } from "../../assets/icons/i-search.svg"
import { ReactComponent as Server } from "../../assets/icons/i-server.svg"
import { ReactComponent as Settings } from "../../assets/icons/i-settings.svg"
import { ReactComponent as Share } from "../../assets/icons/i-share.svg"
import { ReactComponent as Star } from "../../assets/icons/i-star.svg"
import { ReactComponent as Table } from "../../assets/icons/i-table.svg"
import { ReactComponent as Tag } from "../../assets/icons/i-tag.svg"
import { ReactComponent as Text } from "../../assets/icons/i-text.svg"
import { ReactComponent as Translate } from "../../assets/icons/i-translate.svg"
import { ReactComponent as TreeIcon } from "../../assets/icons/i-tree-icon.svg"
import { ReactComponent as Video } from "../../assets/icons/i-video.svg"
import { ReactComponent as Checked } from "../../assets/icons/i-checked.svg"
import { ReactComponent as MinusWarning } from "../../assets/icons/i-circle-minus-warning.svg"
import { ReactComponent as NotificationRed } from "../../assets/icons/i-circle-notification-red.svg"
import { ReactComponent as CameraWarning } from "../../assets/icons/i-camera-warning.svg"
import { ReactComponent as ImportFile } from "../../assets/icons/i-import-file.svg"
import { ReactComponent as LoadingFile } from "../../assets/icons/i-file-loading.svg"
import { ReactComponent as CloseDanger } from "../../assets/icons/i-close-danger.svg"
import { ReactComponent as CheckSuccess } from "../../assets/icons/i-check-success.svg"
import { ReactComponent as Detail } from "../../assets/pdf/i-detail.svg"
import { ReactComponent as Photo } from "../../assets/icons/i-photo.svg"
import { ReactComponent as PhotoOrange } from "../../assets/icons/i-photo-orange.svg"
import React from "react"

interface IAdeSvgProps extends React.SVGProps<SVGSVGElement> {
	name: string
}

const svgs = {
	"alert-circle": AlertCircle,
	"arrow-bottom": ArrowBottom,
	"arrow-left": ArrowLeft,
	"arrow-right": ArrowRight,
	"arrow-top": ArrowTop,
	"arrow-undo": ArrowUndo,
	calculator: Calculator,
	camera: Camera,
	cart: Cart,
	"chevron-bottom": ChevronBottom,
	"chevron-left": ChevronLeft,
	"chevron-right": ChevronRight,
	"chevron-top": ChevronTop,
	"chevrons-right": ChevronsRight,
	circle: Circle,
	"circle-checked": CircleChecked,
	"circle-plus": CirclePlus,
	"circle-x": CircleX,
	"clipboard-text": ClipboardText,
	close: Close,
	download: Download,
	eye: Eye,
	"eye-close": EyeClose,
	"file-delete": FileDelete,
	"file-download": FileDownload,
	floppy: Floppy,
	info: Info,
	link: Link,
	loader: Loader,
	logout: Logout,
	"more-horizontal": MoreHorizontal,
	"note-text": NoteText,
	"paper-text": PaperText,
	paperclip: PaperClip,
	"pencil-create": PencilCreate,
	"play-circle": PlayCircle,
	plus: Plus,
	"refresh-cw": RefreshCw,
	search: Search,
	server: Server,
	settings: Settings,
	share: Share,
	star: Star,
	table: Table,
	tag: Tag,
	text: Text,
	translate: Translate,
	"tree-icon": TreeIcon,
	video: Video,
	checked: Checked,
	minusWarning: MinusWarning,
	notificationRed: NotificationRed,
	cameraWarning: CameraWarning,
	"import-file": ImportFile,
	"loading-file": LoadingFile,
	"close-danger": CloseDanger,
	"check-success": CheckSuccess,
	detail: Detail,
	"photo": Photo,
	"photo-orange": PhotoOrange
}

const SvgIcon: React.FC<IAdeSvgProps> = ({ name, ...other }) => {
	return React.createElement(svgs[name], {
		className: "u-icon " + (other.className || ""),
		onClick: other.onClick,
		onMouseDown: (e) => e.preventDefault(),
		...other,
	})
}

export { SvgIcon }
