import React from "react"
import {useDispatch, useSelector} from "react-redux"
import SimpleSelect from "../../../app/common/basic/selectors/SimpleSelect"
import {actions} from "../../../redux/reducers/distributionsTypes"
import {DistributionTypeSelectors} from "../../../redux/reducers/distributionsTypes/DistributionTypeSelectors"

function DistributionTypeSelector() {

    const dispatch = useDispatch()

    const types = useSelector(DistributionTypeSelectors.getTypes)
    const selected = useSelector(DistributionTypeSelectors.getSelected)


    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={x => x}
        options={types}
        className={"selector"}
        onChange={newValue => changeSelected(newValue)}
        value={selected}
        traceName={"DistributionTypeSelector"}
    />
}

export default DistributionTypeSelector