import React from "react"
import "./ToolsetProducts.scss"
import {useSearchParams} from "react-router-dom"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {AppState} from "../../redux/reducers"
import {actions, ToolsetProductsState} from "../../redux/reducers/pages/toolsetProductsPage"
import {LanguageState} from "../../redux/reducers/languages"
import {
    getAllPictogramsThunk,
    getPriceGroupProductsThunk,
    getToolsetProductsThunk,
    addProductsToToolsetThunk,
    getOrReserveThunk,
    isGroupContainsToolsetThunk,
} from "../../redux/reducers/pages/toolsetProductsPage/thunks"
import {useDebouncedCallback} from "use-debounce"
import NullableSelect from "../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import _ from "lodash"
import DebounceProvider from "../../utils/DebounceProvider"
import {actions as informerActions} from "../../redux/reducers/informer"
import {AdLoadingRow} from "../../library/tables/AdLoadingRow"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import {
    ToolsetProductPageAuthSelectors
} from "../../redux/reducers/pages/toolsetProductsPage/toolsetProductPageAuthSelectors"
import { ToolsetComponentThunks } from "../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"


export default function ToolsetProducts() {
    const [searchParams] = useSearchParams()
    const productGroupId = searchParams.get("productGroupId")
    const toolsetId = searchParams.get("toolsetId")
    const dispatch = useDispatch()

    const languageState = useSelector<AppState, LanguageState>(x => x.languageState)
    const local = useSelector<AppState, ToolsetProductsState>(x => x.pages.toolsetProductsPage)
    const canAccess = useSelector(ToolsetProductPageAuthSelectors.canAccess)

    useEffect(() => {
        if(toolsetId !== null && productGroupId !== null) {
            //проверяем что группа существует
            if(!local.isGroupLoaded) {
                dispatch(getOrReserveThunk({
                    languageId: languageState.selected.id,
                    productGroupId: productGroupId,
                    productsIds: []
                }))
            }
            //проверяем что продукт принадлежит группе в случае, предыдущая проверка прошла
            if(!local.isToolsetCheckLoaded && local.isGroupLoaded && local.productGroup !== null) {
                dispatch(isGroupContainsToolsetThunk({
                    productGroupId: productGroupId,
                    productId: toolsetId
                }))
            }
            if(local.isGroupLoaded && local.isToolsetCheckLoaded && local.isGroupContainsToolset) {

                dispatch(getPriceGroupProductsThunk({
                    productGroupId: productGroupId!,
                    toolsetId: toolsetId!,
                    languageId: languageState.selected.id,
                }))

                dispatch(getToolsetProductsThunk({
                    productGroupId: productGroupId!,
                    toolsetId: toolsetId!,
                    languageId: languageState.selected.id
                }))

                dispatch(getAllPictogramsThunk({
                    languageId: languageState.selected.id
                }))
            }
        }
    }, [languageState.selected.id,
        local.isGroupLoaded,
        local.isToolsetCheckLoaded,
        local.isGroupContainsToolset])

    const setRowClicked = (productId: string) => dispatch(actions.setSelectedProduct(productId))
    const setSearch = (search: string) => dispatch(actions.setSearch(search.toLowerCase()))
    const debouncedSearch = useDebouncedCallback((args) => setSearch(args), DebounceProvider.getDebounce())
    const setSelectedPictogram = (pictId: number) => dispatch(actions.setSelectedPictogram(pictId))

    const addProductsToToolset = () => {
        const selectedProducts = local.products.filter(x => x.selected)
        if(selectedProducts.length === 0)
        {
            dispatch(informerActions.inform("Выберите продукты для добавления"))
            return
        }
        const selectedPictogram = local.selectedPictogram
        if(selectedPictogram === null)
        {
            dispatch(informerActions.inform("Выберите группу пиктограмм"))
            return
        }

        dispatch(addProductsToToolsetThunk({
            toolsetId: toolsetId!,
            products: _.orderBy(selectedProducts, x => x.sort).map(x => {
                return {
                    productId: x.id,
                    pictogramId: selectedPictogram?.id ?? null,
                    sort: 0
                }
            }),
            productGroupId: productGroupId!
        }))
    }

    return <>
        {
            productGroupId === null || toolsetId === null
                ? <>Переданы неверные аргументы</>
                : !local.isToolsetCheckLoaded || !local.isGroupLoaded
                    ? <AdLoadingRow colSpan={7}/>
                    :
                    canAccess
                        ? local.isGroupContainsToolset
                            ? <div className="recommendations toolset-wrapper u-droppod">

                                <div className="u-droppod-header">
                                    <div className="header-text">
                                    Продукты набора
                                    </div>
                                </div>

                                <div className="u-toolbar">
                                    <div className={"toolbar-row"}>
                                        <AdSearchInput onChange={debouncedSearch} />
                                        <NullableSelect value={local.selectedPictogram}
                                            options={local.pictograms}
                                            onChange={value => setSelectedPictogram(value as number)}
                                            toOption={ToOptionProvider.pictogramToOption}
                                            className={"selector"}
                                            placeholder={"Выберите группу пиктограмм"}/>
                                        <button className={"u-button large primary"} onClick={() => addProductsToToolset()}>Добавить</button>
                                    </div>
                                </div>


                                <div className="u-table__scroll-wrapper">
                                    <table className="u-table">
                                        <thead className="u-thead">
                                            <tr>
                                                <th>Код</th>
                                                <th>Наименование</th>
                                                <th className="u-width-30"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="u-tbody">
                                            {
                                                _.orderBy(local.products
                                                    .filter(x => x.name.toLowerCase().includes(local.searchString) || x.id.includes(local.searchString)), x => x.name.toLowerCase())
                                                    .map(x => {
                                                        return <tr key={x.id} onClick={() => setRowClicked(x.id)} className={x.selected ? "table-row--selected" : ""}>
                                                            <td>{x.id}</td>
                                                            <td>{x.name}</td>
                                                            <td className="checkbox-cell"><input type={"checkbox"} checked={x.selected} readOnly={true}/></td>
                                                        </tr>
                                                    })
                                            }
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : <>Продукт не принадлежит группе</>
                        : <>Недостаточно прав для просмотра</>
        }
    </>
}