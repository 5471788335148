import { useEffect } from "react"
import ProductRelatedProducts from "../../modules/ProductRelatedProducts"
import ProductSimilarProducts from "../../modules/ProductSimilarProducts"
import ProductCatalogBlock from "../../modules/ProductCatalogBlock"

export function AdditionalTab(props: { onMount: () => void }) {
	useEffect(() => {
		props.onMount()
	}, [])

	return (
		<>
			<ProductRelatedProducts />
			<ProductSimilarProducts />
			<ProductCatalogBlock />
		</>
	)
}
