import {IProductGroupIdentity} from "../../../../domain/types"
import {createSlice, PayloadAction} from "@reduxjs/toolkit" 
import {CompareContentThunks} from "./thunks"

export type CompareContentState = {
    productGroups: IProductGroupIdentity[]
    search: string,
    isLoading: boolean
}

const INITIAL_STATE: CompareContentState = {
    productGroups: [],
    isLoading: false,
    search: ""
}

const slice = createSlice({
    name: "compare-content",
    initialState: INITIAL_STATE,
    reducers: {
        setSearch(state: CompareContentState, action: PayloadAction<string>) {
            state.search = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(CompareContentThunks.getCatalogDifference.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(CompareContentThunks.getCatalogDifference.fulfilled, (state, action) => {
            state.productGroups = action.payload
            state.isLoading = false
        })
        builder.addCase(CompareContentThunks.getCatalogDifference.rejected, (state, action) => {
            state.isLoading = false
        })
    }
})

const actions = slice.actions
const reducer = slice.reducer

export {actions, reducer}