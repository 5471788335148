import React from "react"
import {useDispatch, useSelector} from "react-redux"
import SimpleSelect, {IOptionType} from "../../../app/common/basic/selectors/SimpleSelect"
import {ICatalogGroup} from "../../../domain/types"
import {actions} from "../../../redux/reducers/catalogGroups"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"

const toOption = (catalog: ICatalogGroup): IOptionType => {
    return {
        value: catalog.id,
        label: catalog.name
    }
}

function CatalogGroupSelector() {
    const groups = useSelector(CatalogGroupSelectors.getGroups)
    const selected = useSelector(CatalogGroupSelectors.getSelected)

    const dispatch = useDispatch()

    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={toOption}
        options={groups}
        className={"selector"}
        onChange={newValue => changeSelected(newValue)}
        value={selected}
    />
}

export default CatalogGroupSelector