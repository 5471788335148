export default class Constants {
    static SortAttributeId = 1
    static WeightInGramAttributeId = 57
    static IgnoreAttributeId = 0
    static BaseImageType = 1
    static PlanImageType = 2
    static BoxImageType = 3
    static PinImageType = 5
    static YoutubeImageType = 15
    static WebBaseParent = 2000
    static PrintBaseParent = 0
    static RussianLanguage = 11
    static Avtodelo2021CatalogId = 23
    static AllPriceGroupsId = 100000
    static RigaPriceGroupId = 1000
    static BerilPriceGroupId = 148
    static AvtodeloPriceGroupId = 6
    static BerilCatalogId = 31
    static AttributeComplexId = 27
}

export class AdditionalTranslateKeys {
    static AttributeShortname = "AttributeShortName".toLowerCase()
    static AttributeUnit = "AttributeUnit".toLowerCase()
}