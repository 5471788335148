import React, { useState } from "react"
import { Modal } from "react-bootstrap"

export function AdVideoModal(props: {
	uploadVideo: (url: string) => void
	closeModal: () => void
	show: boolean
}) {
	const [url, setUrl] = useState("")

	return (
		<Modal
			show={props.show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					Добавление видео
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Ссылка на видео (вся ссылка целиком):</p>
				<input
					type="text"
					className="form-control"
					onChange={(e) => setUrl(e.currentTarget.value)}
					value={url}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					className={"u-button large primary"}
					onClick={() => props.closeModal()}
				>
					Закрыть
				</button>
				<button
					className={"u-button large accent"}
					onClick={() => props.uploadVideo(url)}
					disabled={url === ""}
				>
					Добавить
				</button>
			</Modal.Footer>
		</Modal>
	)
}
