import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {actions as categoryActions} from "../../redux/reducers/categories"
import {useEffect} from "react"
import {LanguageSelectors} from "../../redux/reducers/languages/languageSelectors"
import {CatalogGroupSelectors} from "../../redux/reducers/catalogGroups/catalogGroupSelectors"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import TreeSelectorBlock from "./treeGroupTable/TreeSelectorBlock"

export default function CategoryGroupInTreeToolbar() {

    const language = useSelector(LanguageSelectors.getSelected)
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)

    const dispatch = useDispatch()

    function setCategoryFilter(filter: string) {
        dispatch(categoryActions.setFilter(filter))
    }


    useEffect(() => {
        dispatch(categoryActions.clearToolbarState())
    }, [language.id, catalogGroup.id])

    return (
        <div className="cat-category-group-toolbar-container u-toolbar">
            <div className="toolbar-row u-flex-1-inside">
                <TreeSelectorBlock/>
            </div>
            <div className="toolbar-row">
                <AdSearchInput onChange={setCategoryFilter} />
            </div>
        </div>
    )
}