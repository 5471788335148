import React, { useState } from "react"
import { Link } from "react-router-dom"
import RouteProvider from "../../../../utils/RouteProvider"
import { IGroupWithCatViewModel, IGroupViewModel } from "./types"
import { SvgIcon } from "../../../icons/SvgIcon"
import { AdNotFoundRow } from "../../../tables/AdNotFoundRow"
import { AdLoadingRow } from "../../../tables/AdLoadingRow"
import { keyDownMessage } from "../../../../utils/KeysCodes"
import { useDispatch } from "react-redux"

export default function ExpandedProductGroupTable(
	props: IExpandedProductGroupTableProps
) {
	const colSpan = props.showSortColumn ? 7 : 6
	const dispatch = useDispatch()

	return (
		<table
			tabIndex={0}
			className={"u-table"}
			id={props.id}
			onKeyDown={(e) => {
				keyDownMessage(e, props.id || null, dispatch)
			}}
		>
			<thead className="u-thead">
				<tr>
					{props.disabled ? null : (
						<th className="u-table-column-checkbox-wrapper" />
					)}
					<th className="product-right-column-plus-wrapper" />
					<th>Код</th>
					<th className="u-width-100%">Наименование</th>
					{props.showSortColumn ? <th>№</th> : <></>}
					{/*{*/}
					{/*    props.disabled ? null : <th className="">Статус</th>*/}
					{/*}*/}
					<th className="">Статус</th>
					<th className="">···</th>
				</tr>
			</thead>
			<tbody className="u-tbody">
				{!props.isProductGroupsLoading ? (
					props.productGroups.isEmpty() ? (
						<AdNotFoundRow colSpan={colSpan} />
					) : (
						props.productGroups.map((x) => (
							<ProductGroupTogglingRow
								key={x.id + x.name}
								model={x}
								colSpan={colSpan}
								showSortColumn={props.showSortColumn}
								onCheckBoxClick={props.onCheckBoxClick}
								onExpanderClick={props.loadProducts}
								disabled={props.disabled}
								onRowClick={props.onRowClick}
							/>
						))
					)
				) : (
					<AdLoadingRow colSpan={colSpan} />
				)}
				<tr></tr>
			</tbody>
		</table>
	)
}

interface IExpandedProductGroupTableProps {
	isProductGroupsLoading: boolean
	productGroups: IGroupViewModel[] | IGroupWithCatViewModel[]
	loadProducts: (
		productGroup: IGroupViewModel | IGroupWithCatViewModel
	) => void
	onRowClick: (productGroup: IGroupViewModel | IGroupWithCatViewModel) => void
	onCheckBoxClick: (model: IGroupViewModel | IGroupWithCatViewModel) => void
	showSortColumn: boolean
	disabled?: boolean
	id?: string
}

function ProductGroupTogglingRow(props: ITogglingRowProps): JSX.Element {
	const [isToggle, setIsToggle] = useState(false)
	const status = getStatus(props.model)
	const icon =
		status === GroupStatus.Checked ||
		status === GroupStatus.CheckedButNotDistributed ? (
			<SvgIcon name="circle-checked" className="u-status-icon checked" />
		) : status === GroupStatus.UncheckedPhoto ? (
			<SvgIcon name="camera" className="u-status-icon p-photo" />
		) : status === GroupStatus.UncheckedInfo ? (
			<SvgIcon name="text" className="u-status-icon p-info" />
		) : (
			<SvgIcon
				name="alert-circle"
				className="u-status-icon not-checked"
			/>
		)

	let rowClass = props.model.isLastActive
		? "table-row--lastActive"
		: props.model.checked
		? "table-row--selected"
		: ""

	rowClass +=
		status === GroupStatus.UncheckedAndNotDistributed
			? " table-row--red"
			: status === GroupStatus.CheckedButNotDistributed
			? " table-row--orange"
			: ""

	return (
		<>
			<tr className={rowClass}>
				{props.disabled ? null : (
					<td
						className="u-table-column-checkbox-wrapper"
						onClick={() => props.onCheckBoxClick(props.model)}
					>
						<input
							disabled={props.disabled ?? false}
							type="checkbox"
							checked={props.model.checked}
							readOnly={true}
						/>
					</td>
				)}
				<td
					className="product-right-column-plus-wrapper"
					onClick={() => {
						//проверка на то что уже загружено поддерево
						if (props.model.products != null && !isToggle) {
							setIsToggle(!isToggle)
						} else {
							setIsToggle(!isToggle)
							props.onExpanderClick(props.model)
						}
					}}
				>
					{isToggle ? (
						<SvgIcon
							name="chevron-top"
							className="rem-icon"
						></SvgIcon>
					) : (
						<SvgIcon
							name="chevron-bottom"
							className="rem-icon"
						></SvgIcon>
					)}
				</td>
				<td>{props.model.id}</td>
				<td
					onClick={() => {
						if (props.disabled) return
						props.onRowClick(props.model)
					}}
				>
					{`${props.model.name}`}
				</td>
				{props.showSortColumn ? (
					<td
						onClick={() => {
							if (props.disabled) return
							props.onRowClick(props.model)
						}}
						className={"u-width-30 u-center-text"}
					>
						{props.model.sort}
					</td>
				) : (
					<></>
				)}
				<td
					onClick={() => {
						if (props.disabled) return
						props.onRowClick(props.model)
					}}
				>
					{icon}
				</td>
				<td className={"info"}>
					<Link
						to={RouteProvider.productGroup.generateParameterPath({
							productGroupId: props.model.id,
						})}
						target={"_blank"}
					>
						<SvgIcon name="clipboard-text" />
					</Link>
				</td>
			</tr>
			{isToggle ? (
				<>
					{props.model.isLoading ? (
						<AdLoadingRow colSpan={6} />
					) : props.model.products === null ? (
						<AdNotFoundRow colSpan={6} />
					) : (
						props.model.products.map((x) => (
							<tr key={x.id} className="shifted-row no-hover">
								{props.disabled ? null : <td />}
								<td />
								<td>{x.id}</td>
								<td colSpan={6}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										{x.name}
										<Link
											to={RouteProvider.productPage.generateParameterPath(
												{ productId: x.id }
											)}
											target={"_blank"}
											style={{
												marginRight:
													"calc(1.4285714286em * var(--shift-amount))",
												marginLeft: "0.5em",
											}}
										>
											<SvgIcon name="clipboard-text" />
										</Link>
									</div>
								</td>
								{/*<td>*/}
								{/*	<Link*/}
								{/*		to={RouteProvider.productPage.generateParameterPath(*/}
								{/*			{ productId: x.id }*/}
								{/*		)}*/}
								{/*		target={"_blank"}*/}
								{/*	>*/}
								{/*		<SvgIcon name="clipboard-text" />*/}
								{/*	</Link>*/}
								{/*</td>*/}
							</tr>
						))
					)}
				</>
			) : null}
		</>
	)
}

enum GroupStatus {
	Unchecked,
	UncheckedPhoto,
	UncheckedInfo,
	Checked,
	CheckedButNotDistributed,
	UncheckedAndNotDistributed,
}

function getStatus(
	model: IGroupViewModel | IGroupWithCatViewModel
): GroupStatus {
	let status = GroupStatus.Checked
	if (!model.isImageChecked && !model.isDescriptionChecked) {
		status = GroupStatus.Unchecked
	} else if (!model.isImageChecked) {
		status = GroupStatus.UncheckedPhoto
	} else if (!model.isDescriptionChecked) {
		status = GroupStatus.UncheckedInfo
	}

	if (model.sort !== null) {
		if (model.sort === 0) {
			status =
				status === GroupStatus.Checked
					? GroupStatus.CheckedButNotDistributed
					: GroupStatus.UncheckedAndNotDistributed
		}
	}

	return status
}

interface ITogglingRowProps {
	model: IGroupViewModel | IGroupWithCatViewModel
	onExpanderClick: (model: IGroupViewModel | IGroupWithCatViewModel) => void
	onRowClick: (model: IGroupViewModel | IGroupWithCatViewModel) => void
	onCheckBoxClick: (model: IGroupViewModel | IGroupWithCatViewModel) => void
	colSpan: number
	showSortColumn: boolean
	disabled?: boolean
}
