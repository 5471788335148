import {AppState} from "../../../index"

export class AdditionalTabSelectors {
    static getGroupRecommendations = (state: AppState) => state.pages.productGroupPage.additionalInfoState.groupRecommendations
    static getSelectedGroupRecommendation = (state: AppState) => state.pages.productGroupPage.additionalInfoState.selectedGroupRecommendation
    static getAllRecommendations = (state: AppState) => state.pages.productGroupPage.additionalInfoState.allRecommendations
    static getSelectedRecommendation = (state: AppState) => state.pages.productGroupPage.additionalInfoState.selectedRecommendation
    static getGroupCatalogs = (state: AppState) => state.pages.productGroupPage.additionalInfoState.groupCatalogs
    static getIsOnSite = (state: AppState) => state.pages.productGroupPage.additionalInfoState.isOnSite
    static getIsAddingOrRemovingFromSite = (state: AppState) => state.pages.productGroupPage.additionalInfoState.isAddingOrRemovingFromSite
    static getIsChangingStatusOnSite = (state: AppState) => state.pages.productGroupPage.additionalInfoState.isChangingStatusOnSite
}