import {
	IPictogram,
	IProductGroup,
	IToolsetProduct,
} from "../../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	addProductsToToolsetThunk,
	getAllPictogramsThunk,
	getOrReserveThunk,
	getPriceGroupProductsThunk,
	getToolsetProductsThunk,
	isGroupContainsToolsetThunk,
} from "./thunks"
import { ISortedSelectable } from "../../../types"
import { IProductIdentity } from "../../../../domain/models/products/ProductIdentity"

export type Product = IProductIdentity & ISortedSelectable

export type ToolsetProductsState = {
	pictograms: IPictogram[]
	selectedPictogram: IPictogram | null
	searchString: string

	maxSort: number
	products: Product[]

	toolsetProductId: null
	toolsetProducts: IToolsetProduct[]

	productGroup: IProductGroup | null
	isGroupContainsToolset: boolean

	isGroupLoaded: boolean
	isToolsetCheckLoaded: boolean
}

const INITIAL_PICTOGRAMS: IPictogram[] = [
	{ id: -1, name: "loading", imageUrl: "", sort: -1, isSet: false },
]

const INITIAL_STATE: ToolsetProductsState = {
	pictograms: INITIAL_PICTOGRAMS,
	selectedPictogram: INITIAL_PICTOGRAMS[0],

	searchString: "",

	maxSort: 0,
	products: [],

	toolsetProducts: [],
	toolsetProductId: null,

	productGroup: null,
	isGroupContainsToolset: false,

	isGroupLoaded: false,
	isToolsetCheckLoaded: false,
}

const slice = createSlice({
	name: "toolset-products--page",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedPictogram(
			state: ToolsetProductsState,
			action: PayloadAction<number | null>
		) {
			if (action.payload === null) {
				state.selectedPictogram = null
				return
			}
			const pictogram = state.pictograms.find(
				(x) => x.id === action.payload
			)
			state.selectedPictogram = pictogram!
		},
		setSelectedProduct(
			state: ToolsetProductsState,
			action: PayloadAction<string>
		) {
			const product = state.products.find((x) => x.id === action.payload)
			if (product === undefined) return
			// мы не изменяем sort в остальных selected продуктах, потому что этот sort нужен только
			// для сортировки при отправке на сервер и больше нигде не используется
			if (!product.selected) {
				product.selected = true
				state.maxSort += 1
				product.sort = state.maxSort
			} else {
				product.selected = false
				product.sort = 0
			}
		},
		setSearch(state: ToolsetProductsState, action: PayloadAction<string>) {
			state.searchString = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getAllPictogramsThunk.fulfilled, (state, action) => {
			state.pictograms = action.payload
			state.selectedPictogram = null
		})
		builder.addCase(getOrReserveThunk.fulfilled, (state, action) => {
			state.productGroup = action.payload
			state.isGroupLoaded = true
		})
		builder.addCase(
			isGroupContainsToolsetThunk.fulfilled,
			(state, action) => {
				state.isGroupContainsToolset = action.payload
				state.isToolsetCheckLoaded = true
			}
		)
		builder.addCase(
			isGroupContainsToolsetThunk.rejected,
			(state, action) => {
				state.isToolsetCheckLoaded = true
			}
		)
		builder.addCase(
			addProductsToToolsetThunk.fulfilled,
			(state, action) => {
				const addedProducts = action.meta.arg.products
				state.products = state.products.filter(
					(x) =>
						addedProducts.findIndex((a) => a.productId === x.id) ===
						-1
				)
				addedProducts.forEach((x) => {
					//нам нужны только id добавленных продуктов для последующей фильтрации
					state.toolsetProducts.push({
						name: "",
						sort: 0,
						productId: x.productId,
						pictogramId: 0,
						pictogramName: "",
					})
				})
			}
		)
		builder.addCase(getToolsetProductsThunk.fulfilled, (state, action) => {
			state.toolsetProducts = action.payload
			if (state.products.length !== 0) {
				state.products = state.products.filter(
					(x) =>
						action.payload.findIndex(
							(a) => a.productId === x.id
						) === -1
				)
			}
		})
		builder.addCase(
			getPriceGroupProductsThunk.fulfilled,
			(state, action) => {
				//в случае, если тулсеты загрузились первыми или есть searchString, то фильтруем
				if (state.toolsetProducts.length !== 0) {
					state.products = action.payload
						.filter(
							(x) =>
								state.toolsetProducts.findIndex(
									(a) => a.productId === x.id
								) === -1
						)
						.map((x) => {
							return {
								id: x.id,
								name: x.name,
								priceGroupId: x.priceGroupId,
								selected: false,
								sort: 0,
							}
						})
				} else {
					state.products = action.payload.map((x) => {
						return {
							id: x.id,
							name: x.name,
							priceGroupId: x.priceGroupId,
							selected: false,
							sort: 0,
						}
					})
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
