import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { useDispatch, useSelector } from "react-redux"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import React, { useEffect, useRef } from "react"
import { ProductPageSimilarProductsSelectors } from "../../../redux/reducers/pages/productPage/similarProducts/ProductPageSimilarProductsSelectors"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { actions } from "../../../redux/reducers/pages/productPage/similarProducts"
import { actions as informerActions } from "../../../redux/reducers/informer"
import NullableSelect from "../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { keyDownMessage } from "../../../utils/KeysCodes"
import { AdLinkProductsModal } from "../../../library/modal/AdLinkProductsModal"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export default function ProductSimilarProducts() {
	const productId = useSelector(ProductPageSelectors.getProductId)
	const language = useSelector(LanguageSelectors.getSelected)
	if (!productId) return <></>

	const dispatch = useDispatch()
	const ref = useRef<HTMLDivElement>(null)

	const similar = useSelector(
		ProductPageSimilarProductsSelectors.getSimilarProducts
	)
	const products = useSelector(
		ProductPageSimilarProductsSelectors.getPossibleSimilarProducts
	)
	const recommended = useSelector(
		ProductPageSimilarProductsSelectors.getRecommendedSimilarProducts
	)
	const selectedProduct = useSelector(
		ProductPageSimilarProductsSelectors.getSelectedProduct
	)

	const isRecommendedLoading = useSelector(
		ProductPageSimilarProductsSelectors.getIsRecommendedLoading
	)

	const [shouldShowProductModal, setShouldShowProductModal] =
		React.useState(false)

	const [shouldShowRecommendedModal, setShouldShowRecommendedModal] =
		React.useState(false)
	const canAddSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanAddSimilarProducts
	)
	const canChangeSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanChangeSimilarProducts
	)
	const canRemoveSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanRemoveSimilarProducts
	)
	const canSwapSortSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanSwapSortSimilarProducts
	)

	const canAddVideoSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanAddVideoSimilarProducts
	)
	const canChooseAnAnalogSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanChooseAnAnalogSimilarProducts
	)
	const canChooseSimilarProducts = useSelector(
		ProductPageAuthSelectors.getCanChooseSimilarProducts
	)

	useEffect(() => {
		dispatch(
			ProductPageThunks.getPossibleSimilarProducts({
				productId: productId,
				languageId: language.id,
			})
		)
		dispatch(
			ProductPageThunks.getSimilarProducts({
				productId: productId,
				languageId: language.id,
			})
		)
	}, [language.id])

	useEffect(() => {
		if (shouldShowRecommendedModal) {
			dispatch(
				ProductPageThunks.getRecommendedSimilarProducts({
					productId: productId,
					count: 10,
				})
			)
		}
	}, [shouldShowRecommendedModal])

	const setSelectedProduct = (id: string) =>
		dispatch(actions.setSelectedProduct(id))

	const setProductSearch = (nameOrId: string) =>
		dispatch(actions.setProductSearch(nameOrId))

	const setSimilarSelected = (id: string) =>
		dispatch(actions.setSimilarSelected(id))

	const addSimilar = () => {
		if (!selectedProduct) {
			dispatch(informerActions.inform("Выберите товар для добавления"))
			return
		}

		addManySimilar([selectedProduct.id])
	}

	const addManySimilar = (similarProducts: string[]) => {
		if (similarProducts.length === 0) {
			dispatch(informerActions.inform("Выберите товары для добавления"))
			return
		}
		dispatch(
			ProductPageThunks.addSimilarProducts({
				productId: productId,
				similarProductsIds: similarProducts,
			})
		)
	}

	const removeSimilar = () => {
		const productToRemove = similar.find((x) => x.selected)
		if (!productToRemove) {
			dispatch(
				informerActions.inform(
					"Выберите аналогичный товар для удаления"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.removeSimilarProduct({
				productId: productId,
				similarProductId: productToRemove.similarId,
			})
		)
	}

	const replaceSimilar = () => {
		const selected = similar.find((x) => x.selected)
		if (!selected) {
			dispatch(
				informerActions.inform(
					"Выберите аналогичный товар для изменения"
				)
			)
			return
		}

		if (!selectedProduct) {
			dispatch(
				informerActions.inform(
					"Выберите товар, который заменит текущий"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.replaceSimilarProduct({
				productId: productId,
				currentSimilarId: selected.similarId,
				targetSimilarId: selectedProduct.id,
			})
		)
	}

	const swapSort = (addition: number) => {
		const productToSwap = similar.find((x) => x.selected)
		if (!productToSwap) {
			dispatch(
				informerActions.inform(
					"Выберите аналогичный товар для изменения сортировки"
				)
			)
			return
		}

		const target = similar.find(
			(x) => x.sort === productToSwap.sort + addition
		)
		if (!target) {
			dispatch(
				informerActions.inform(
					"Целевое значение выходит за пределы сортировки"
				)
			)
			return
		}

		dispatch(
			ProductPageThunks.swapSimilarProductSort({
				productId: productId,
				currentSimilarId: productToSwap.similarId,
				targetSimilarId: target.similarId,
				currentSort: productToSwap.sort,
				targetSort: target.sort,
			})
		)
	}

	return (
		<>
			<div
				ref={ref}
				className="u-droppod-section-header header-with-toolbar"
			>
				Аналогичные товары
				<div className="u-toolbar">
					<div className="toolbar-row">
						<NullableSelect
							value={selectedProduct}
							options={products}
							onChange={(id) => {
								setSelectedProduct(id as string)
							}}
							toOption={ToOptionProvider.productIdentityToOption}
							className={"selector"}
							placeholder={"Введите наименование товара"}
							height={31}
							isDisabled={!canChooseSimilarProducts}
							noOptionsMessage={"Нет подходящих товаров"}
							onInputChange={(value) => {
								setProductSearch(value)
							}}
						/>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canAddSimilarProducts}
							onClick={() => addSimilar()}
						>
							<SvgIcon name="circle-plus" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canChangeSimilarProducts}
							onClick={replaceSimilar}
						>
							<SvgIcon name="pencil-create" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canRemoveSimilarProducts}
							onClick={() => removeSimilar()}
						>
							<SvgIcon name="circle-x" />
						</button>

						<button
							type="button"
							className="u-button square primary"
							disabled={!canSwapSortSimilarProducts}
							onClick={() => swapSort(-1)}
						>
							<SvgIcon name="arrow-top" />
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canSwapSortSimilarProducts}
							onClick={() => swapSort(1)}
						>
							<SvgIcon name="arrow-bottom" />
						</button>

						<button
							type="button"
							className="u-button square primary"
							disabled={!canAddVideoSimilarProducts}
							onClick={() => setShouldShowProductModal(true)}
						>
							Добавить
						</button>
						<button
							type="button"
							className="u-button square primary"
							disabled={!canChooseAnAnalogSimilarProducts}
							onClick={() => setShouldShowRecommendedModal(true)}
						>
							Подобрать аналог
						</button>
					</div>
				</div>
			</div>
			<div className="u-table__scroll-wrapper almost-full-table">
				<table
					className="u-table"
					id="recs-table"
					tabIndex={0}
					onKeyDown={(e) => {
						keyDownMessage(e, "recs-table", dispatch)
					}}
				>
					<thead className="u-thead">
						<tr>
							<th>Код</th>
							<th>Наименование</th>
							<th>№</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{similar.length === 0 ? (
							<tr>
								<td colSpan={3}>Пока ничего нет</td>
							</tr>
						) : (
							<>
								{similar.map((x) => {
									return (
										<tr
											key={x.productId}
											id={x.similarId}
											onClick={() =>
												setSimilarSelected(x.similarId)
											}
											className={
												x.selected
													? "table-row--selected"
													: ""
											}
										>
											<td>{x.similarId}</td>
											<td>{x.name}</td>
											<td>{x.sort}</td>
										</tr>
									)
								})}
							</>
						)}
					</tbody>
				</table>
			</div>
			<AdLinkProductsModal
				isOpen={shouldShowProductModal}
				close={() => setShouldShowProductModal(false)}
				products={products}
				addProductsToCurrentEntity={addManySimilar}
			/>
			<AdLinkProductsModal
				isOpen={shouldShowRecommendedModal}
				close={() => {
					setShouldShowRecommendedModal(false)
					dispatch(actions.clearRecommended())
				}}
				products={recommended}
				addProductsToCurrentEntity={addManySimilar}
				isLoading={isRecommendedLoading}
			/>
		</>
	)
}
