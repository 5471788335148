import { AppState } from "../../../index"
import { createSelector } from "@reduxjs/toolkit"
import _ from "lodash"

export class ProductPageSimilarProductsSelectors {
	static getSimilarProducts = createSelector(
		[
			(state: AppState) =>
				state.pages.productPage.similarProductsState.similar,
		],
		(similar) => _.orderBy(similar, (x) => x.sort)
	)

	static getPossibleSimilarProducts = createSelector(
		[
			(state: AppState) =>
				state.pages.productPage.similarProductsState.products,
			(state: AppState) =>
				state.pages.productPage.similarProductsState.productSearch,
		],
		(products, productSearch) => {
			return products
			/*if (productSearch === "") return []
			return products.filter(
				(x) =>
					x.name
						.toLowerCase()
						.includes(productSearch.toLowerCase()) ||
					x.id.toLowerCase().includes(productSearch.toLowerCase())
			)*/
		}
	)

	static getRecommendedSimilarProducts = createSelector(
		[
			(state: AppState) =>
				state.pages.productPage.similarProductsState.recommended,
			ProductPageSimilarProductsSelectors.getSimilarProducts,
		],
		(recommended, similar) => {
			return recommended.filter(
				(x) => !similar.some((s) => s.similarId === x.id)
			)
		}
	)

	static getSelectedProduct = (state: AppState) =>
		state.pages.productPage.similarProductsState.selectedProduct

	static getIsRecommendedLoading = (state: AppState) =>
		state.pages.productPage.similarProductsState.isRecommendedLoading
}
