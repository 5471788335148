import {AppState} from "../index"
import {Role} from "../../../domain/Role"

export class AuthSelectors {
    static hasOneOfRoles = (state: AppState, roles: Role[]): boolean => {
        const hasRole = roles.some(role => state.authState.roles.includes(role))
        const isAdmin = state.authState.roles.includes(Role.Admin)
        return hasRole || isAdmin
    }

    static isContentLv = (state: AppState): boolean => {
        return state.authState.roles.includes(Role.ContentManagerLv) || state.authState.roles.includes(Role.Admin)
    }
    static isContentRf = (state: AppState): boolean => {
        return state.authState.roles.includes(Role.ContentManagerRF) || state.authState.roles.includes(Role.Admin)
    }
    static isPhotographer = (state: AppState): boolean => {
        return state.authState.roles.includes(Role.Photographer) || state.authState.roles.includes(Role.Admin)
    }
    static isContentRg = (state: AppState): boolean => {
        return state.authState.roles.includes(Role.ContentManagerRg) || state.authState.roles.includes(Role.Admin)
    }

    static isAdmin = (state: AppState): boolean => {
        return state.authState.roles.includes(Role.Admin)
    }
}