import { Route, Routes } from "react-router-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import React from "react"
import { AppState } from "../redux/reducers"
import { ProtectedRoute } from "./ProtectedRoute"
import Login from "./login/Login"
import Layout from "./layout/Layout"
import { store } from "../redux/store"
import RouteProvider from "../utils/RouteProvider"
import AdResultModal, { ModalStatus } from "../library/modal/AdResultModal"
import { InformerSelectors } from "../redux/reducers/informer/InformerSelectors"
import { actions as informerActions } from "../redux/reducers/informer/index"

export function App() {
	return (
		<Provider store={store}>
			<MainRouter />
			<GlobalLoader />
		</Provider>
	)
}

function GlobalLoader() {
	const dispatch = useDispatch()
	const shouldShow = useSelector(InformerSelectors.getIsShowing)
	const status = useSelector(InformerSelectors.getStatus)
	const text = useSelector(InformerSelectors.getText)

	return shouldShow ? (
		<AdResultModal
			text={text}
			status={status}
			onClick={() => {
				if (status === ModalStatus.loading) return

				dispatch(informerActions.closeLoader())
			}}
		/>
	) : null
}

function MainRouter() {
	const authState = useSelector((state: AppState) => state.authState)
	const isLogged = authState.token != null && authState.username != null

	return (
		<Routes>
			<Route path={RouteProvider.login.path} element={<Login />} />
			<Route
				path={RouteProvider.allAnother.path}
				element={
					<ProtectedRoute isLogged={isLogged} shouldBeLogged={true}>
						<Layout />
					</ProtectedRoute>
				}
			></Route>
		</Routes>
	)
}
