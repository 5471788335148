import { AppState } from "../../../index"

export class CategoryTabSelectors {
	static getWebCategories = (state: AppState) =>
		state.pages.productGroupPage.categoryState.categoriesWeb
	static getPrintedCategories = (state: AppState) =>
		state.pages.productGroupPage.categoryState.categoriesPrinted
	static getCurrentWebCats = (state: AppState) =>
		state.pages.productGroupPage.categoryState.currentWebCategories
	static getCurrentPrintedCats = (state: AppState) =>
		state.pages.productGroupPage.categoryState.currentPrintedCategories
	static getSelectedWebCats = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedWebCategories
	static getSelectedPrintedCats = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedPrintedCategories
	static getSelectedCurrentWebCat = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedCurrentWebCategory
	static getSelectedCurrentPrintedCat = (state: AppState) =>
		state.pages.productGroupPage.categoryState
			.selectedCurrentPrintedCategory
	static getShouldResetWeb = (state: AppState) =>
		state.pages.productGroupPage.categoryState.shouldResetWeb
	static getShouldResetPrinted = (state: AppState) =>
		state.pages.productGroupPage.categoryState.shouldResetPrinted
	static getScopes = (state: AppState) =>
		state.pages.productGroupPage.categoryState.scopes
	static getIsScopesWasInit = (state: AppState) =>
		state.pages.productGroupPage.categoryState.isScopesWasInit
	static getCurrentScopes = (state: AppState) =>
		state.pages.productGroupPage.categoryState.currentScopes
	static getSelectedScope = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedScope
	static getSelectedCurrentScope = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedCurrentScope
	static getIsAreaEditorOpen = (state: AppState) =>
		state.pages.productGroupPage.categoryState.isAreaEditorMenuOpen

	static getGroups = (state: AppState) =>
		state.pages.productGroupPage.categoryState.productGroups

	static getSelectedCatalogGroup = (state: AppState) =>
		state.pages.productGroupPage.categoryState.selectedCatalogGroup
}
