import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/productsPage/groupList"
import { actions as commonActions } from "../../../redux/reducers/pages/productsPage"
import SimpleSelect from "../../common/basic/selectors/SimpleSelect"
import "./ProductGroupListToolbar.scss"
import { useDebouncedCallback } from "use-debounce"
import { GroupsListThunks } from "../../../redux/reducers/pages/productsPage/groupList/thunk"
import DebounceProvider from "../../../utils/DebounceProvider"
import { PriceGroupSelectors } from "../../../redux/reducers/priceGroups/priceGroupSelectors"
import { GroupListSelectors } from "../../../redux/reducers/pages/productsPage/groupList/groupListSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import AdSearchInput from "../../../library/inputs/AdSearchInput"
import { WebsiteSelectors } from "../../../redux/reducers/webSites/websiteSelectors"
import { IWebsite, WebsiteSchema } from "../../../domain/types"
import { AppState } from "../../../redux/reducers"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import NullableSelect from "../../common/basic/selectors/NullableSelect"
import { ProductsPageSelectors } from "../../../redux/reducers/pages/productsPage/productsPageSelectors"
import { WebsiteThunks } from "../../../redux/reducers/webSites/thunks"

function ProductGroupListToolbar() {
	return (
		<div
			id="groupTableToolBar"
			className="product-right-column-toolbar u-toolbar"
		>
			<div className="toolbar-row">
				<SearchWrapper />
			</div>
			<div className="toolbar-row">
				<SelectorWrapper />
				<GroupSelectorWrapper />
				<span style={{ minWidth: 50 }}>Нет на </span>
				<WebsiteSelectorWrapper />
			</div>
		</div>
	)
}

/**
 * Wrapper нужен для оптимизации перерисовки
 * **/
function SearchWrapper() {
	const priceGroup = useSelector(PriceGroupSelectors.getSelected)
	const language = useSelector(LanguageSelectors.getSelected)
	const filter = useSelector(GroupListSelectors.getFilter)
	const selectedCardType = useSelector(GroupListSelectors.getSelectedCardType)
	const isGroupsLoading = useSelector(GroupListSelectors.getIsGroupsLoading)
	const groupStatisticFilter = useSelector(
		GroupListSelectors.getSelectedGroupStatisticFilter
	)

	const dispatch = useDispatch()
	const website = useSelector(ProductsPageSelectors.getSelectedWebsite)

	const onEnter = (event) => {
		if (isGroupsLoading) return
		if (event.key === "Enter") {
			dispatch(
				GroupsListThunks.getGroups({
					priceGroupId: priceGroup.id,
					languageId: language.id,
					searchString: filter,
					checkType: selectedCardType.value,
					groupFilter: groupStatisticFilter.value,
					websiteId: website?.id ?? null,
				})
			)
		}
	}

	const setFilter = (filter: string) => dispatch(actions.setFilter(filter))
	const debouncedFilter = useDebouncedCallback(
		(args) => setFilter(args),
		DebounceProvider.getDebounce()
	)

	return <AdSearchInput onChange={debouncedFilter} onKeyUp={onEnter} />
}

/**
 * Wrapper нужен для оптимизации перерисовки
 * **/
function SelectorWrapper() {
	const dispatch = useDispatch()
	const selectedCardType = useSelector(GroupListSelectors.getSelectedCardType)
	const cardTypes = useSelector(GroupListSelectors.getCardTypes)
	const changeSelected = (id: number) =>
		dispatch(actions.setSelectedCardType(id))

	return (
		<SimpleSelect
			traceName={"Card types"}
			value={selectedCardType}
			className={"selector"}
			options={cardTypes}
			onChange={(e) => changeSelected(e)}
			toOption={(opt) => opt}
		/>
	)
}

function GroupSelectorWrapper() {
	const dispatch = useDispatch()
	const selectedGroupType = useSelector(
		GroupListSelectors.getSelectedGroupStatisticFilter
	)
	const groupTypes = useSelector(GroupListSelectors.getGroupFilterTypes)
	const changeSelected = (id: number) =>
		dispatch(actions.setSelectedGroupFilter(id))

	return (
		<SimpleSelect
			traceName={"Card types"}
			value={selectedGroupType}
			className={"selector"}
			options={groupTypes}
			onChange={(e) => changeSelected(e)}
			toOption={(opt) => opt}
		/>
	)
}

interface IWebsiteSelectorProps {
	height?: number
	isDisabled?: boolean
	className?: string
	schema?: WebsiteSchema
}

function WebsiteSelectorWrapper(props: IWebsiteSelectorProps) {
	const website = useSelector(ProductsPageSelectors.getSelectedWebsite)

	const websites = useSelector<AppState, IWebsite[]>((x) =>
		WebsiteSelectors.getWebsites(x, props.schema)
	).filter((x) => x.schema != WebsiteSchema.ProductSite)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(WebsiteThunks.getWebsites())
	}, [])

	const changeSelected = (id: number | null) => {
		const website = websites.find((x) => x.id === id)
		if (website) {
			dispatch(commonActions.setSelectedWebsite(website))
		} else {
			dispatch(commonActions.setSelectedWebsite(null))
		}
	}

	return (
		<NullableSelect
			value={website}
			options={websites}
			placeholder={"Выберите сайт"}
			onChange={(newValue) => changeSelected(newValue as number)}
			toOption={ToOptionProvider.websiteToOption}
			height={props.height}
			className={"selector"}
		/>
	)
}

export default ProductGroupListToolbar
