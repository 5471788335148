import { AppState } from "../../../../index"
import { IOptionType } from "../../../../../../app/common/basic/selectors/SimpleSelect"
import { TimeFilterEnum } from "../../../../../../domain/models/TimeFilterEnum"
import { createSelector } from "@reduxjs/toolkit"
import { ProductFilesSelectors } from "../ProductFilesSelectors"

export class ProductGraphicFilesSelectors {
	static getFormatFilter = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.formatFilter

	static getTimeFilter = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.timeFilter

	static getTypeFilter = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.typeFilter

	static getPossibleFormatFilters(state: AppState) {
		const files =
			state.pages.productPage.filesState.graphicFiles.graphicFiles
		const filters = files.map((x) => x.extension)
		const distinctFilters = [...new Set(filters)]

		const _allFormats = { value: 0, label: "Все форматы" }
		const result = distinctFilters.map((x, index) => {
			return { value: index + 1, label: x }
		})

		result.unshift(_allFormats)
		return result
	}

	static getPossibleTimeFilters = (): IOptionType[] => [
		{
			value: TimeFilterEnum.ToOld,
			label: "От нового к старому",
		},
		{
			value: TimeFilterEnum.ToNew,
			label: "От старого к новому",
		},
	]

	private static getAllGraphicFiles = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.graphicFiles

	static getPossibleTypeFilters = createSelector(
		[
			ProductGraphicFilesSelectors.getAllGraphicFiles,
			ProductFilesSelectors.getFileTypes,
		],
		(files, fileTypes) => {
			const curFileTypes = files.map((x) => x.fileTypeId)
			let result = fileTypes.filter((x) => x.subType === 1)
			result = result.filter((x) => curFileTypes.includes(x.id))

			const _allTypes = {
				id: 0,
				name: "Все типы",
				subType: 1,
				fileFormats: [],
			}
			result.unshift(_allTypes)
			return result
		}
	)

	static getGraphicFiles = createSelector(
		[
			ProductGraphicFilesSelectors.getAllGraphicFiles,
			ProductGraphicFilesSelectors.getFormatFilter,
			ProductGraphicFilesSelectors.getTimeFilter,
			ProductGraphicFilesSelectors.getTypeFilter,
		],
		(files, formatFilter, timeFilter, typeFilter) => {
			const filteredByFormat =
				formatFilter.value === 0
					? files
					: files.filter((x) => x.extension === formatFilter.label)

			const filteredByType =
				typeFilter.id === 0
					? filteredByFormat
					: filteredByFormat.filter(
							(x) => x.fileTypeId === typeFilter.id
					  )

			return [...filteredByType].sort((a, b) => {
				const aDate = new Date(a.modifyDate)
				const bDate = new Date(b.modifyDate)

				return timeFilter.value === TimeFilterEnum.ToOld
					? bDate.getTime() - aDate.getTime()
					: aDate.getTime() - bDate.getTime()
			})
		}
	)

	static getChangedGraphicFiles = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.graphicFiles.filter(
			(x) => x.wasChanged
		)

	static getDeletedGraphicFiles = (state: AppState) =>
		state.pages.productPage.filesState.graphicFiles.deletedFiles
}
