import React from "react"
import "./ToolsetProductsBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { LanguageSelectors } from "../../../../../../redux/reducers/languages/languageSelectors"
import { ToolsetProductAppendController } from "./ToolsetProductAppendController"
import { ToolsetSorterController } from "./ToolsetSorterController"
import { ToolsetPictogramController } from "./ToolsetPictogramController"
import { ToolsetProductAppendRedirector } from "./ToolsetProductAppendRedirector"
import { ToolsetProductsTable } from "./ToolsetProductsTable"

export default function ToolsetProductsBlock(props: IToolsetProductsProps) {
	const groups = useSelector(GroupPageSelectors.getGroup)
	const language = useSelector(LanguageSelectors.getSelected)

	const dispatch = useDispatch()

	//Загружаем пиктограммы и продукты для селектора
	useEffect(() => {
		dispatch(
			ToolsetComponentThunks.getPictograms({ languageId: language.id })
		)
		dispatch(
			ToolsetComponentThunks.getPossibleToolsetProducts({
				languageId: language.id,
				productGroupId: groups.id,
				toolsetId: props.toolsetProductId
			})
		)
	}, [language.id])

	//загружаем продукты тулсета
	useEffect(() => {
		dispatch(
			ToolsetComponentThunks.getToolsetProducts({
				productGroupId: groups.id,
				toolsetId: props.toolsetProductId,
				languageId: language.id,
			})
		)
	}, [language.id, props.toolsetProductId])

	return (
		<>
			<div className="u-toolbar">
				<div className={"toolbar-row"}>
					<ToolsetProductAppendController
						toolsetProductId={props.toolsetProductId}
					/>
					<ToolsetSorterController
						toolsetProductId={props.toolsetProductId}
					/>
					<ToolsetPictogramController
						toolsetProductId={props.toolsetProductId}
					/>
					<ToolsetProductAppendRedirector
						toolsetProductId={props.toolsetProductId}
					/>
				</div>
			</div>
			<div className="toolset-pb-table-wrapper u-table__scroll-wrapper">
				<ToolsetProductsTable
					toolsetProductId={props.toolsetProductId}
					toolsetName={props.toolsetName}
				/>
			</div>
		</>
	)
}

interface IToolsetProductsProps {
	toolsetProductId: string
	toolsetName: string
}
