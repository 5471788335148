import {CatalogGroup, ICatalog} from "../../../domain/types"
import {createSelector} from "@reduxjs/toolkit"
import {AppState} from "../index"

export class CatalogSelectors {
    static getByCatalogGroup = (catalogGroup: CatalogGroup) => {
        if (catalogGroup === CatalogGroup.All)
            return createSelector([(state: AppState) => state.catalogState.catalogs], (catalogs: ICatalog[]) => catalogs)

        const isPrinted = catalogGroup === CatalogGroup.Printed
        return createSelector([(state: AppState) => state.catalogState.catalogs],
            (catalogs: ICatalog[]) => catalogs.filter(x => x.isPrinted === isPrinted))
    }
    static getSelected = (state: AppState) => state.catalogState.selected
    static getWasInit = (state: AppState) => state.catalogState.wasInit
}