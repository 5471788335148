import React from "react"
import { actions } from "../../../../../redux/reducers/pages/groupPage"
import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import useAuth from "../../../../../hooks/authorizationHook"
import { Role } from "../../../../../domain/Role"
import { LanguageSelectors } from "../../../../../redux/reducers/languages/languageSelectors"
import Constants from "../../../../../domain/Constants"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../domain/GroupMembership"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

export default function Characteristics() {
	const dispatch = useDispatch()
	const group = useSelector(GroupPageSelectors.getGroup)
	const canControlDescription = useSelector(
		GroupPageAuthSelectors.getCanControlDescription
	)

	const setDescr = (value) => {
		dispatch(actions.setDescription(value))
	}
	const setWebDescr = (value) => {
		dispatch(actions.setWebDescription(value))
	}

	return (
		<div className="u-droppod text-part">
			<div className="u-droppod-section-header">Характеристики</div>
			<div className={"common-wrapper u-droppod-scroll-wrapper"}>
				<div className="textarea-wrapper">
					<div className="u-droppod-item-header">
						Техническое описание
					</div>
					<div className="u-textarea">
						<textarea
							disabled={!canControlDescription}
							rows={4}
							onChange={(e) => setDescr(e.target.value)}
							value={group.description}
						/>
					</div>
				</div>

				<div className="textarea-wrapper">
					<div className="u-droppod-item-header">
						Техническое описание карточки товара
					</div>
					<div className="u-textarea">
						<textarea
							disabled={!canControlDescription}
							rows={4}
							onChange={(e) => setWebDescr(e.target.value)}
							value={group.descriptionWeb}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
