import React, { useEffect, useState } from "react"
import "./ImageBlock.scss"
import { useDispatch, useSelector } from "react-redux"
import {
	actions,
	IGuidImage,
} from "../../../../../redux/reducers/pages/groupPage/graphicComponent"
import { GraphicComponentsThunks } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/thunks"
import ToOptionProvider from "../../../../../utils/ToOptionProvider"
import Constants from "../../../../../domain/Constants"
import YoutubePlayer from "../../../../../library/images/YoutubePlayer"
import VideoModal from "./VideoModal"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { GraphicComponentSelectors } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/graphicComponentSelectors"
import { actions as informerActions } from "../../../../../redux/reducers/informer"
import { actions as imageTypeActions } from "../../../../../redux/reducers/imageTypes"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import AdDragAndDrop from "../../../../../library/drapAndDrop/AdDragAndDrop"
import ImageTypeSelector from "../../../../../library/smartComponents/imageTypeSelector/ImageTypeSelector"
import { ImageTypeSelectors } from "../../../../../redux/reducers/imageTypes/imageTypeSelectors"
import SimpleSelect from "../../../../common/basic/selectors/SimpleSelect"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API

export default function ImageBlock() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const imageType = useSelector(ImageTypeSelectors.getSelected)
	const groupImages = useSelector(GraphicComponentSelectors.getGroupImages)
	const canControlImages = useSelector(
		GroupPageAuthSelectors.getCanControlImages
	)

	const dispatch = useDispatch()
	const [file, setFile] = useState<File | null>(null)

	useEffect(() => {
		dispatch(
			GraphicComponentsThunks.getProductImages({
				productGroupId: group.id,
			})
		)
		return () => {
			dispatch(imageTypeActions.setFilter([]))
		}
	}, [])

	useEffect(() => {
		dispatch(imageTypeActions.setFilter(groupImages.map((x) => x.typeId)))
	}, [groupImages])

	const setFileNull = () => {
		if (!canControlImages) return
		setFile(null)
	}

	const addImage = () => {
		if (!canControlImages) return
		if (imageType === null) {
			dispatch(informerActions.inform("Выберите тип изображения"))
			return
		}

		if (groupImages.findIndex((x) => x.typeId === imageType.id) !== -1) {
			dispatch(informerActions.inform("Изображение уже добавлено"))
			return
		}

		dispatch(
			GraphicComponentsThunks.uploadImage({
				imageName: file?.name ?? null,
				imageType: imageType.id,
				productGroupId: group.id,
			})
		)

		setFileNull()
	}

	return (
		<>
			<div className="wrapper-grid">
				<div className={"image-block-wrapper"}>
					<ImageTypeSelector disabled={!canControlImages} />
					{canControlImages ? (
						<>
							<AdDragAndDrop
								style={{ height: 100 }}
								onFileAdded={(e) => setFile(e[0])}
								onFileCleared={setFileNull}
								file={file}
								fileType={".png, .jpg, .jpeg"}
								canAddImages={true}
							/>
							<div className={"u-droppod-info"}>
								Файл должен иметь формат jpg, png, gif.
							</div>
						</>
					) : (
						<div></div>
					)}
					<button
						className={"u-button large primary"}
						disabled={
							!canControlImages ||
							imageType === null ||
							file === null
						}
						onClick={() => addImage()}
					>
						Загрузить изображение
					</button>
				</div>

				{groupImages.notEmpty()
					? groupImages.map((x) => (
							<GroupImage
								key={x.typeId}
								groupId={group.id}
								image={x}
								file={file}
								onFileUsed={setFileNull}
								disabled={!canControlImages}
							/>
					  ))
					: null}
			</div>
			<VideoModal />
		</>
	)
}

interface IGroupImageProps {
	image: IGuidImage
	groupId: string
	file: File | null
	onFileUsed: () => void
	disabled?: boolean
}

function GroupImage(props: IGroupImageProps) {
	const filteredImageTypes = useSelector(
		ImageTypeSelectors.getFilteredImageTypes
	)
	const imageTypes = useSelector(ImageTypeSelectors.getImageTypes)

	const image = props.image
	const selected = imageTypes.find((i) => i.id === image.selectedTypeId)

	const dispatch = useDispatch()
	const file = props.file

	const setNewType = (image: IGuidImage, newTypeId: number) => {
		if (props.disabled) return
		dispatch(actions.setNewType({ image: image, newTypeId: newTypeId }))
	}

	const removeImage = (image: IGuidImage) => {
		if (props.disabled) return
		dispatch(
			GraphicComponentsThunks.removeImage({
				productGroupId: props.groupId,
				imageType: image.typeId,
			})
		)
	}

	const updateImage = (image: IGuidImage) => {
		if (props.disabled) return
		if (file === null || file === undefined) {
			if (image.typeId === image.selectedTypeId) {
				dispatch(informerActions.inform("Невозможно выбрать действие"))
				return
			} else {
				dispatch(
					GraphicComponentsThunks.updateImageType({
						groupId: props.groupId,
						currentTypeId: image.typeId,
						targetTypeId: image.selectedTypeId,
					})
				)
			}
		} else {
			dispatch(
				GraphicComponentsThunks.updateImage({
					imageType: image.typeId,
					imageName: file.name,
					productGroupId: props.groupId,
				})
			)
			props.onFileUsed()
		}
	}

	return selected ? (
		<div className={"image-block-wrapper"} key={image.typeId}>
			<SimpleSelect
				value={selected}
				options={filteredImageTypes}
				onChange={(newTypeId) => setNewType(image, newTypeId)}
				isDisabled={props.disabled}
				toOption={ToOptionProvider.imageTypeToOption}
				placeholder={"Выберите тип"}
			/>
			{image.typeId === Constants.YoutubeImageType ? (
				<YoutubePlayer
					url={image.imageUrl}
					className={"middle-block__ala-image"}
				/>
			) : (
				<img
					className="middle-block__ala-image"
					key={image.key}
					src={`${staticServer}/${image.imageUrl}?${image.key}`}
					alt={image.typeId.toString()}
				/>
			)}
			<div className={"u-droppod-info"} title={image.imageUrl}>
				{image.imageUrl}
			</div>
			{props.disabled ? null : (
				<div className={"control-buttons"}>
					{image.selectedTypeId !== image.typeId || file !== null ? (
						<SvgIcon
							name={"pencil-create"}
							className="edit-image"
							onClick={() => updateImage(image)}
						/>
					) : null}
					<SvgIcon
						name={"circle-x"}
						className="remove-image"
						onClick={() => removeImage(image)}
					/>
				</div>
			)}
		</div>
	) : null
}
