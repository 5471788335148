import BaseApi from "../BaseApi"
import actionTypes, {IApplicationResponse} from "../baseTypes"
import {IProductIdentity, IToolsetProduct, IToolsetProductIdentity} from "../../domain/types"

export default class ToolsetApi extends BaseApi {

    getToolsetProducts = (data: {
        productGroupId: string,
        toolsetId: string,
        languageId: number
    }): Promise<IApplicationResponse<IToolsetProduct[]>> =>
        this.sendQuery<IToolsetProduct[]>("/api/toolsets", data, actionTypes.get, true)

    getPossibleToolsetProducts = (data: {
        productGroupId: string,
        toolsetId: string,
        languageId: number
    }): Promise<IApplicationResponse<IProductIdentity[]>> =>
        this.sendQuery<IProductIdentity[]>("/api/toolsets/get-possible-toolset-products", data, actionTypes.get, true)

    addProductsToToolset = (data: {
        toolsetId: string,
        products: IToolsetProductIdentity[],
        productGroupId: string
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/toolsets/add-to-toolset", data, actionTypes.post, true)

    changeAssignment = (data: {
        productGroupId: string,
        toolsetId: string,
        productId: string,
        anotherProductId: string
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/toolsets/change-assignment", data, actionTypes.put, true)

    removeProductFromToolset = (data: { productGroupId: string, toolsetId: string, productId: string }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/toolsets/remove-from-toolset", data, actionTypes.delete, true)

    swapSort = (data: {
        productGroupId: string,
        toolsetId: string,
        productId: string,
        targetProductId: string
        currentSort: number,
        targetSort: number,
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/toolsets/swap-sort", data, actionTypes.put, true)

    changePictGroup = (data: {
        productGroupId: string,
        toolsetId: string,
        productId: string,
        pictogramId: number
    }): Promise<IApplicationResponse<void>> =>
        this.sendQuery<void>("/api/toolsets/change-pict", data, actionTypes.put, true)
}