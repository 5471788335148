import {AppState} from "../../../../index"

export class ToolsetComponentSelectors {
    static getIsToolsetProductsLoading = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.isToolsetProductsLoading
    static getProducts = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.products
    static getSelectedProduct = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.selectedProduct
    static getToolsetProducts = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.toolsetProducts
    static getSelectedToolsetProduct = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.selectedToolsetProduct
    static getPictograms = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.pictograms
    static getSelectedPictogram = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.selectedPictogram
    static getArticle = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.article
    static getTargetSort = (state: AppState) => state.pages.productGroupPage.tableTabState.toolsetState.targetSort
}