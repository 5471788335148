import AdDragAndDrop from "../drapAndDrop/AdDragAndDrop"
import { ISystemFileLoadModel } from "../../domain/models/files/FileLoadModel"
import AdFileLoadModal from "../modal/AdFileLoadModal"
import React, { useEffect, useState } from "react"
import { IFileType } from "../../domain/models/files/FileType"
import SimpleSelect from "../../app/common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import { FileSubtype } from "../../domain/models/files/FileSubtype"
import { FileStatus } from "../../domain/models/files/FileStatus"
import { IShortEcmFileDescription } from "../../domain/models/files/EcmFileDescription"
import { getNameAndExtension } from "../../utils/FileUtils"
import { ProductFileTypeFilters } from "../../redux/reducers/pages/productPage/files/ProductFileTypeFilters"
import { createPortal } from "react-dom"
import { SvgIcon } from "../icons/SvgIcon"

export default function AdFileLoader(props: {
	onFileToLoadAdded: (file: IShortEcmFileDescription) => void
	filesToLoad: ISystemFileLoadModel[]
	possibleFileTypeMask: string
	loadedImagesCount: number
	loadingImagesCount: number
	canUploadFiles: boolean
	uploadFiles: () => void
	fileTypes: IFileType[]
	setFileToLoadType: (
		file: IShortEcmFileDescription,
		fileType: number
	) => void
	setFileToLoadSubType: (
		file: IShortEcmFileDescription,
		fileSubType: number
	) => void
	onClose: () => void
	canAddImages: boolean
	canAuth: boolean
}) {
	const [showFileModal, setShowFileModal] = useState(false)

	const addFileToLoad = (files: FileList) => {
		console.log("addImageToLoad")
		setShowFileModal(true)

		for (const file of files) {
			const [filename, extension] = getNameAndExtension(file.name)
			if (props.filesToLoad.some((x) => x.file.name === filename))
				continue

			props.onFileToLoadAdded({
				name: filename,
				extension: extension,
			})
		}
	}

	const onClose = () => {
		setShowFileModal(false)
		props.onClose()
	}

	const isAllFilesLoadedWithoutErrors = () => {
		if (
			props.loadedImagesCount > 0 &&
			props.loadedImagesCount == props.loadingImagesCount
		) {
			return props.filesToLoad.every(
				(x) => x.loadStatus === FileStatus.LoadedToServer
			)
		}
	}

	useEffect(() => {
		if (
			props.loadedImagesCount > 0 &&
			props.loadedImagesCount == props.loadingImagesCount
		) {
			if (isAllFilesLoadedWithoutErrors()) {
				setTimeout(() => {
					setShowFileModal(false)
					props.onClose()
				}, 2000)
			}
		}
	}, [props.loadedImagesCount])

	const uploadImages = () => {
		props.uploadFiles()
	}

	return (
		<>
			<AdDragAndDrop
				onFileAdded={(e) => {
					addFileToLoad(e)
				}}
				onFileCleared={() => {
					console.log("")
				}}
				fileType={"*"}
				file={null}
				multiple={true}
				canAddImages={props.canAddImages}
			/>
			{createPortal(
				<AdFileLoadModal
					isOpen={showFileModal}
					loadedFilesCount={props.loadedImagesCount}
					loadingFilesCount={props.loadingImagesCount}
					addFileToLoad={addFileToLoad}
					possibleFileTypeMask={props.possibleFileTypeMask}
					canAddImages={props.canAddImages}
				>
					<AdFileLoadModalBody
						canUploadFiles={props.canUploadFiles}
						files={props.filesToLoad}
						uploadFiles={uploadImages}
						fileTypes={props.fileTypes}
						setFileToLoadSubType={props.setFileToLoadSubType}
						setFileToLoadType={props.setFileToLoadType}
						onClose={onClose}
						canAuth={props.canAuth}
					/>
				</AdFileLoadModal>,
				document.body
			)}
		</>
	)
}

function AdFileLoadModalBody(props: {
	canUploadFiles: boolean
	files: ISystemFileLoadModel[]
	fileTypes: IFileType[]
	uploadFiles: () => void
	setFileToLoadType: (
		file: IShortEcmFileDescription,
		fileType: number
	) => void
	setFileToLoadSubType: (
		file: IShortEcmFileDescription,
		fileSubType: number
	) => void
	onClose: () => void
	canAuth: boolean
}) {
	const getStatusInfo = (
		file: ISystemFileLoadModel
	): [statusText: string, statusColor: string] => {
		let statusText = ""
		let statusColor = ""
		switch (file.loadStatus) {
			case FileStatus.Loaded:
				statusText = "Загружено"
				statusColor = "green"
				break
			case FileStatus.Error:
				statusText = "Ошибка, не подходящий формат файла"
				statusColor = "red"
				break
			case FileStatus.MoreInfoNeeded:
				statusText = "Выберите тип и подтип файла"
				statusColor = "orange"
				break
			case FileStatus.LoadedToServer:
				statusText = "Сохранено"
				statusColor = "green"
				break
			case FileStatus.ErrorOnServer:
				statusText = file.uploadErrorMessage ?? "Ошибка на сервере"
				statusColor = "red"
				break
		}

		return [statusText, statusColor]
	}

	return (
		<>
			{props.files.map((file) => {
				const possibleFileSubTypes =
					ProductFileTypeFilters.getPossibleFileSubtypesByLoadModel(
						file,
						props.fileTypes
					)
				const subType = possibleFileSubTypes.find(
					(x) => x === file.subtypeId
				)

				const possibleFileTypes =
					ProductFileTypeFilters.getPossibleFileTypesByLoadModel(
						file,
						props.fileTypes
					).filter((x) => x.subType == file.subtypeId)

				const cur = possibleFileTypes.firstOrDefault(
					(x) => x.id === file.typeId
				)

				if (!cur && possibleFileTypes.length > 0) {
					props.setFileToLoadType(file.file, possibleFileTypes[0].id)
				}

				const [statusText, statusColor] = getStatusInfo(file)

				return (
					<div
						key={file.file.name}
						className={`file-load-modal-body-item ${statusColor}`}
					>
						<>
							<SvgIcon
								name={"loader"}
								className={"big-icon load"}
							/>
							<SvgIcon
								name={"circle-checked"}
								className={"big-icon good"}
							/>
							<SvgIcon
								name={"circle-x"}
								className={"big-icon bad"}
							/>
						</>
						<div className={"info-holder"}>
							{file.file.name}
							<div className={"status-text"}>{statusText}</div>
							{file.loadStatus === FileStatus.Error ||
							file.loadStatus === FileStatus.ErrorOnServer ? (
								<></>
							) : (
								<>
									<SimpleSelect
										isMenuTop={true}
										value={subType ?? FileSubtype.unknown}
										options={possibleFileSubTypes}
										onChange={(e) => {
											props.setFileToLoadSubType(
												file.file,
												e
											)
										}}
										toOption={
											ToOptionProvider.fileSubtypeToOption
										}
									/>
									{file.subtypeId != FileSubtype.unknown &&
									cur ? (
										<SimpleSelect
											isMenuTop={true}
											value={cur}
											options={possibleFileTypes}
											onChange={(e) => {
												props.setFileToLoadType(
													file.file,
													e
												)
											}}
											toOption={
												ToOptionProvider.fileTypeToOption
											}
										/>
									) : (
										<></>
									)}
								</>
							)}
						</div>
						<div className={"time"}>
							{new Date().toLocaleTimeString([], {
								hour: "2-digit",
								minute: "2-digit",
							})}
						</div>
					</div>
				)
			})}
			{props.canUploadFiles ? (
				<button
					className={"u-button accent large"}
					onClick={props.uploadFiles}
					disabled={!props.canAuth}
				>
					Сохранить
				</button>
			) : (
				<></>
			)}
			<button
				className={"u-button outline large"}
				onClick={() => props.onClose()}
			>
				Закрыть
			</button>
		</>
	)
}
