import {
	IOptionType,
	IStringOptionType,
} from "../app/common/basic/selectors/SimpleSelect"
import {
	IAttribute,
	ICatalog,
	ILanguage,
	IPictogram,
	IPriceGroup,
	ISeries,
	ISign,
	IWebsite,
} from "../domain/types"
import { PhraseType } from "../redux/reducers/pages/translatePage"
import { ProductNameAttributeStrategy } from "../domain/ProductNameAttributeStrategy"
import { toNumber } from "lodash"
import { IProductIdentity } from "../domain/models/products/ProductIdentity"
import { IImageType } from "../domain/models/imageTypes/ImageType"
import { IScopeOfApplication } from "../domain/models/scopes/IScopeOfApplication"
import { ICategory } from "../domain/models/categories/ICategory"
import { IFileType } from "../domain/models/files/FileType"
import { FileSubtype } from "../domain/models/files/FileSubtype"

export default class ToOptionProvider {
	static languageToOption = (language: ILanguage): IOptionType => {
		return { value: language.id, label: language.name }
	}

	static websiteToOption = (website: IWebsite): IOptionType => {
		return { value: website.id, label: website.name }
	}

	static seriesToOption = (series: ISeries): IOptionType => {
		return { value: series.id, label: series.name }
	}

	static signToOption = (sign: ISign): IOptionType => {
		return { value: sign.id, label: sign.name }
	}

	static attributeToOption = (attribute: IAttribute): IOptionType => {
		return { value: attribute.id, label: attribute.name }
	}

	static priceGroupToOption = (priceGroup: IPriceGroup): IOptionType => {
		return { value: priceGroup.id, label: priceGroup.name }
	}

	static categoryToOption = (category: ICategory): IOptionType => {
		return { value: category.id, label: category.name }
	}

	static scopeToOption = (scope: IScopeOfApplication): IOptionType => {
		return { value: scope.id, label: scope.name }
	}

	static productIdentityToOption = (
		product: IProductIdentity
	): IStringOptionType => {
		return { value: product.id, label: product.name }
	}

	static imageTypeToOption = (imageType: IImageType): IOptionType => {
		return { value: imageType.id, label: imageType.name }
	}

	static pictogramToOption = (pictogram: IPictogram): IOptionType => {
		return { value: pictogram.id, label: pictogram.name }
	}

	static catalogToOption = (catalog: ICatalog): IOptionType => {
		return { value: catalog.id, label: catalog.name }
	}

	static phraseTypeToOption = (phraseType: PhraseType): IOptionType => {
		return {
			value: phraseType,
			label:
				phraseType == PhraseType.All
					? "Все"
					: phraseType == PhraseType.NotTranslated
					? "Не переведенные"
					: "Неизвестный тип фразы",
		}
	}

	static fileTypeToOption = (fileType: IFileType): IOptionType => {
		return {
			value: fileType.id,
			label: fileType.name,
		}
	}

	static fileSubtypeToOption = (subType: FileSubtype): IOptionType => {
		const name =
			subType == 0
				? "Не определен"
				: subType == 2
				? "Текстовый файл"
				: "Графический файл"
		return {
			value: subType,
			label: name,
		}
	}

	static productNameAttributeStrategyToOption = (
		value: ProductNameAttributeStrategy
	): IOptionType => {
		let name = ""
		if (value === ProductNameAttributeStrategy.ByAttributeShortName) {
			name = "По короткому названию атрибута"
		} else if (value === ProductNameAttributeStrategy.ByAttributeName) {
			name = "По названию атрибута"
		} else if (
			value ===
			ProductNameAttributeStrategy.ByAttributeShortNameAndAttributeName
		) {
			name = "По короткому или полному названию атрибута"
		}
		const numberValue = toNumber(value)

		return {
			value: numberValue,
			label: name,
		}
	}
}
