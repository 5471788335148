import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GuidePrroductGroupWithProductThunks } from "./thunks"
import { IGuideAttributeForPdf, IGuideCatalogForPdf, IGuideCatalogGroupForPdf, IGuideGroupForPdf, IGuidePictogramForPdf, IGuideSeriesForPdf, IGuideSignForPdf } from "../../../../domain/models/guide/GuideForPdf"
import { IOptionType } from "../../../../app/common/basic/selectors/SimpleSelect"
import { stat } from "fs"

export type GuideGroupWithProducts = {
	parentCategories: IGuideCatalogForPdf[]
	data: IGuideCatalogGroupForPdf[],
	pictograms: IGuidePictogramForPdf[],
	attributes: IGuideAttributeForPdf[],
	series: IGuideSeriesForPdf[],
	signs: IGuideSignForPdf[],
	loaded: boolean,
	options: IOptionType[],
	selectedGroup: IOptionType | null,
	created: boolean
}

const INITIAL_STATE: GuideGroupWithProducts = {
	parentCategories: [],
	data: [],
	pictograms: [],
	attributes: [],
	series: [],
	signs: [],
	loaded: false,
	created: false,
	options: [],
	selectedGroup: null
}

const slice = createSlice({
	name: "guide",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedGroup(state, { payload }: PayloadAction<number | undefined>) {
			if (!payload)
				state.selectedGroup = null;
			else
				state.selectedGroup = state.options.find(u => u.value == payload)!;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			GuidePrroductGroupWithProductThunks.getGuide.fulfilled,
			(state, action) => {
				state.data = action.payload.data
				state.pictograms = action.payload.pictograms
				state.attributes = action.payload.attributes
				state.series = action.payload.series
				state.signs = action.payload.signs
				state.parentCategories = action.payload.parentCategories
				state.options = state.parentCategories.map(u => {
					return {
						value: u.id,
						label: u.name
					} as IOptionType
				})
				//state.selectedGroup = state.options[0] ?? null;
				state.loaded = true;
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
