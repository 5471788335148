import { CatalogGroup } from "../../domain/types"
import { SvgIcon } from "../icons/SvgIcon"
import React from "react"
import { ISelectableIndexModel } from "../../redux/types"
import { ICategory } from "../../domain/models/categories/ICategory"
import AdCategorySelectRow from "./AdCategorySelectRow"
import AdCategoryDynamicTable from "./AdCategoryDynamicTable"

function AdCategoryToSite(props: {
	canControl: boolean
	entityCategories: ISelectableIndexModel<ICategory>[]
	webCategories: ICategory[]
	shouldReset: boolean

	addCategory: () => void
	changeCategory: () => void
	removeCategory: () => void
	setCategoryPath: (category: ICategory | null) => void
	setCategoryAsMain: () => void
	onCategoryRowReset: () => void
	setSelectedCategory: (tableRow: ISelectableIndexModel<ICategory>) => void
	canAddProductCategory: boolean
	canChangeProductCategory: boolean
	canRemoveProductCategory: boolean
	canDoMainProductCategory: boolean
	canChooseProductCategory: boolean
}) {
	return (
		<>
			<div className="u-droppod-section-header header-with-toolbar">
				Категории на сайт
				{props.canControl ? (
					<div className="u-toolbar">
						<div className="toolbar-row">
							<AdCategorySelectRow
								shouldReset={props.shouldReset}
								onReset={() => props.onCategoryRowReset()}
								categories={props.webCategories}
								height={31}
								onChange={(cat, level) => {
									props.setCategoryPath(cat)
								}}
								isDisabled={!props.canChooseProductCategory}
								//canChooseProductCategory={props.canChooseProductCategory}
							/>
							<button
								type="button"
								className="u-button square primary"
								onClick={() => props.addCategory()}
								disabled={!props.canAddProductCategory}
							>
								<SvgIcon name="circle-plus" />
							</button>
							<button
								type="button"
								className="u-button square primary"
								onClick={() => props.changeCategory()}
								disabled={!props.canChangeProductCategory}
							>
								<SvgIcon name="pencil-create" />
							</button>
							<button
								type="button"
								className="u-button square primary"
								onClick={() => props.removeCategory()}
								disabled={!props.canRemoveProductCategory}
							>
								<SvgIcon name="circle-x" />
							</button>
							<button
								type="button"
								className="u-button large primary make-main"
								onClick={() => props.setCategoryAsMain()}
							>
								<SvgIcon name="star" className="link-icon" />
								Сделать главной
							</button>
							<button
								type="button"
								className="u-button square primary make-main__small"
								onClick={() => props.setCategoryAsMain()}
								disabled={!props.canDoMainProductCategory}
							>
								<SvgIcon name="star" />
							</button>
						</div>
					</div>
				) : null}
			</div>
			<AdCategoryDynamicTable
				isDisabled={!props.canControl}
				catalogGroup={CatalogGroup.Web}
				onRowClicked={(e) => props.setSelectedCategory(e)}
				rows={props.entityCategories}
			/>
		</>
	)
}

export default AdCategoryToSite
