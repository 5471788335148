import { SvgIcon } from "../../../library/icons/SvgIcon"
import { useDispatch, useSelector } from "react-redux"
import { CategoriesPageSelector } from "../../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import { CategoriesSelectors } from "../../../redux/reducers/categories/categoriesSelectors"

import "./categoryImages.scss"
import ImageBlock from "./UploadFile"
import { WebCategoryOptions } from "../../../domain/database/WebCategoryType"
import { CatalogGroupSelectors } from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { CatalogGroup } from "../../../domain/types"

export default function CategoryImages() {
	const category = useSelector(CategoriesSelectors.groupImageSettings)
	const selectedGroup = useSelector(CatalogGroupSelectors.getSelected)

	const createBody = () => {

		if (![CatalogGroup.Web, CatalogGroup.Web_Latvia].includes(selectedGroup.id))
			return CategoryImagesBodyPrint();

		if (!category)
			return CategoryImagesBodyEmpty();

		let count = WebCategoryOptions.length;

		if (category.imageUrl) {
			count = 0;
			const categoryKeys = Object.keys(category.imageUrl).map(u => Number(u))
			const optionsKeys = WebCategoryOptions.map(u => u.id)

			for (let i = 0; i < optionsKeys.length; i++) {
				if (!categoryKeys.includes(optionsKeys[i]) || !category.imageUrl[optionsKeys[i]]) {
					count++;
				}
			}
		}

		return (<>
			<div className="category-images-header">
				<div className="title">Фото на сайтах</div>
				<div className="count"> Ожидание {count} фото</div>
			</div>
			<div className="category-images-grid">
				{
					WebCategoryOptions?.map(u => <ImageBlock category={category} catalogId={u.id} key={u.id.toString() + category.imageUrl[u.id]} />)
				}
			</div>
		</>);
	}

	return (
		<div className="cat-product-group-list-container u-droppod category-images" style={{ height: "100%" }}>
			<div className="u-droppod-header">
				<div className="header-text">
					{category ? category.name : "Редактирование фото к категории"}
				</div>
			</div>
			{createBody()}
		</div>
	)
}

function CategoryImagesBodyPrint() {

	return (<>
		<div className="empty">
			<SvgIcon name={"photo"} />
			<div className="empty-text">Изображения могут содержать категории только для интернет-сайтов. Смените дерево категорий</div>
		</div>
	</>)
}

function CategoryImagesBodyEmpty() {

	return (<>
		<div className="empty">
			<SvgIcon name={"photo"} />
			<div className="empty-text">Необходимо выбрать категорию товаров из списка слева</div>
		</div>
	</>)
}