export type ActionType = "post" | "get" | "delete" | "put" | "postFile" | "postNullableFile" | "putFile" | "blob";

export interface IApplicationResponse<T> {
    data: T | null;
    status: number;
    success: boolean;
    exception: IQueryException | null;
}

export function createTestSuccessApplicationResponse<T>(statusCode = 200, data: T | null = null): IApplicationResponse<T> {
    return {
        // @ts-ignore
        data: data ?? "",
        status: statusCode,
        success: statusCode == 200 || statusCode == 201,
        exception: null
    }
}

export interface IQueryException {
    text: string
}

export interface IActionTypes {
    post: ActionType;
    get: ActionType;
    delete: ActionType;
    put: ActionType;
    postFile: ActionType
    postNullableFile: ActionType
    putFile: ActionType,
    blob: ActionType
}

const actionTypes: IActionTypes = {
    post: "post",
    get: "get",
    delete: "delete",
    put: "put",
    postFile: "postFile",
    postNullableFile: "postNullableFile",
    putFile: "putFile",
    blob: "blob"
}

export default actionTypes
