import { Link } from "react-router-dom"
import RouteProvider from "../../../../../../utils/RouteProvider"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import React from "react"
import { ToolsetComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/toolsetComponent/thunks"
import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { LanguageSelectors } from "../../../../../../redux/reducers/languages/languageSelectors"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../../domain/GroupMembership"
import useAuth from "../../../../../../hooks/authorizationHook"
import { Role } from "../../../../../../domain/Role"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

interface IToolsetProductAppendRedirectorProps {
	toolsetProductId: string
}

/**
 * Перенаправляет на страницу добавления продуктов в тулсет
 * */
export function ToolsetProductAppendRedirector(
	props: IToolsetProductAppendRedirectorProps
) {
	const group = useSelector(GroupPageSelectors.getGroup)
	const language = useSelector(LanguageSelectors.getSelected)

	const dispatch = useDispatch()
	const canAccess = useSelector(
		GroupPageAuthSelectors.getCanControlToolsetProducts
	)

	const updateToolsetProducts = () => {
		dispatch(
			ToolsetComponentThunks.getToolsetProducts({
				productGroupId: group.id,
				toolsetId: props.toolsetProductId,
				languageId: language.id,
			})
		)
	}

	return (
		<>
			{canAccess ? (
				<>
					<Link
						className={"u-button large primary"}
						aria-disabled={!canAccess}
						to={RouteProvider.possibleToolsetProducts.generateParameterPath(
							{
								productGroupId: group.id,
								toolsetId: props.toolsetProductId,
							}
						)}
						target={"_blank"}
					>
						Добавить
					</Link>
				</>
			) : (
				<></>
			)}

			<button
				className={"u-button square primary"}
				onClick={() => updateToolsetProducts()}
			>
				<SvgIcon name={"refresh-cw"} />
			</button>
		</>
	)
}
