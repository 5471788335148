import {createSlice} from "@reduxjs/toolkit"

export type OptimisticControllerState = {
    currentQueries: number
}

const INITIAL_STATE: OptimisticControllerState = {
    currentQueries: 0
}

const slice = createSlice({
    name: "optimistic-controller",
    initialState: INITIAL_STATE,
    reducers: {
        addQuery(state: OptimisticControllerState) {
            state.currentQueries += 1
            if(state.currentQueries === 1) {
                window.onbeforeunload = () => "Подождите, пожалуйста! Еще не все технические запросы выполнены"
            }
        },
        removeQuery(state: OptimisticControllerState) {
            state.currentQueries -= 1
            if(state.currentQueries === 0) {
                window.onbeforeunload = null
            }
        }
    }
})

const actions = slice.actions
const reducer = slice.reducer

export {actions, reducer}