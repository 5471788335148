import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"

export const getProductGroupsByCatalogsThunk = createAutogenApiThunk({
    typePrefix: "category-page/get-product-groups",
    apiCall: Api.productGroups.getProductsGroupsFromCatalog,
    errorMessage: "Не удалось получить группы продуктов"
})

export const getProductsByGroupThunk = createAutogenApiThunk({
    typePrefix: "categoryPage/get-product-by-group",
    apiCall: Api.products.getProductsIdentityByGroup,
    errorMessage: "Не удалось получить список продуктов группы"
})

export const getProductGroupCatsThunk = createAutogenApiThunk({
    typePrefix: "get/product-group-cats",
    apiCall: Api.category.getProductGroupCats,
    errorMessage: "Не удалось получить список категорий группы",
})

export const addProductGroupToCatsThunk = createAutogenApiThunk({
    typePrefix: "add/product-groups-to-cats",
    apiCall: Api.category.addProductGroupToCats,
    errorMessage: "Произошла ошибка добавления продукта к категориям. Перезагрузите страницу."
})