import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {LocalStorageProvider} from "../../../api/LocalStorageProvider"
import {LanguagesThunks} from "./thunk"
import {ILanguage} from "../../../domain/types"
import _ from "lodash"

export interface LanguageState {
    languages: ILanguage[]
    selected: ILanguage,
}

const INITIAL_STATE: LanguageState = {
    languages: [{id: 11, name: "Русский"}],
    selected: LocalStorageProvider.getLanguage() ?? {
        id: 11,
        name: "Русский"
    }
}

const TEST_INITIAL_STATE: LanguageState = INITIAL_STATE

const languageSlice = createSlice({
    name: "languages",
    initialState: INITIAL_STATE,
    reducers: {
        setSelected(state: LanguageState, action: PayloadAction<number>){
            const language = action.payload
            state.selected = state.languages.find(x => x.id === language) ?? state.selected
            LocalStorageProvider.setLanguage(state.selected)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(LanguagesThunks.getLanguages.fulfilled, (state, {payload}) => {
            state.languages = _.orderBy(payload, x => x.name)
        })
        builder.addCase(LanguagesThunks.addLanguage.fulfilled, (state, action) => {
            state.languages.push({
                id: action.payload,
                name: action.meta.arg.language
            })
        })
        builder.addCase(LanguagesThunks.removeLanguage.fulfilled, (state, {meta}) => {
            const removedId = meta.arg.languageId

            state.languages = state.languages.filter(x => x.id != removedId)
            if(state.selected.id === removedId)
                state.selected = state.languages.first()
        })
    }
})

const reducer = languageSlice.reducer
const actions = languageSlice.actions

export {reducer, actions, TEST_INITIAL_STATE}