import { ICategory } from "../../../../../domain/models/categories/ICategory"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import CategoryTreeUtils from "../../../../../CategoryTreeUtils"
import { ISelectableIndexModel } from "../../../../types"
import { ProductPageThunks } from "../thunks"
import _ from "lodash"

export type ProductWebCategoriesState = {
	categories: ICategory[]
	////выбранная категория в ряду
	selectedCategories: ICategory[]
	shouldReset: boolean

	productCategories: ISelectableIndexModel<ICategory>[]
	selectedProductCategory: ICategory | null
}

const INITIAL_STATE: ProductWebCategoriesState = {
	categories: [],
	selectedCategories: [],
	shouldReset: false,

	productCategories: [],
	selectedProductCategory: null,
}

const slice = createSlice({
	name: "webCategories",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedCategory(
			state,
			action: PayloadAction<{
				rowIndex: number
			}>
		) {
			let categoryFound = false
			for (const row of state.productCategories) {
				if (row.index === action.payload.rowIndex) {
					row.selected = true
					categoryFound = true
					//значения в ряду отфильтрованы, поэтому мы можем брать последний элемент
					state.selectedProductCategory =
						row.model[row.model.length - 1]
				} else row.selected = false
			}
			if (!categoryFound) state.selectedProductCategory = null
		},
		setRowPath: (
			state,
			{
				payload: { category },
			}: PayloadAction<{ category: ICategory | null }>
		) => {
			if (category === null) {
				state.selectedCategories = []
				return
			}
			if (category.children.length === 0) {
				state.selectedCategories =
					CategoryTreeUtils.getCategoriesByParent(
						category.id,
						state.categories
					)
			} else state.selectedCategories = []
		},
		setShouldReset(state) {
			state.shouldReset = !state.shouldReset
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getWebCategories.fulfilled,
			(state, { payload }) => {
				state.categories = payload
			}
		)
		builder.addCase(
			ProductPageThunks.getProductWebCategories.fulfilled,
			(state, { payload }) => {
				state.productCategories = payload.map((pc, i) => {
					return {
						selected: false,
						index: i,
						model: pc.categoryPath
							.map((y) =>
								CategoryTreeUtils.findCategory(
									y,
									state.categories
								)
							)
							.map((x) => {
								if (!x) return null
								return {
									...x,
									mainCategory: pc.mainCategory,
								}
							})
							.filter((x) => x != null)
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							.map((x) => x!)
							.reverse(),
					}
				})
			}
		)

		builder.addCase(
			ProductPageThunks.addProductToWebCategory.fulfilled,
			(state, { payload }) => {
				state.productCategories.unshift({
					index:
						state.productCategories.length !== 0
							? state.productCategories[0].index - 1
							: 0,
					model: state.selectedCategories.map((x) => {
						return {
							id: x.id,
							name: x.name,
							parentId: x.parentId,
							imageUrl: x.imageUrl,
							children: x.children,
							mainCategory: state.productCategories.length === 0,
							sort: x.sort,
						}
					}),
					selected: false,
				})
				state.shouldReset = true
				state.selectedCategories = []
			}
		)

		builder.addCase(
			ProductPageThunks.removeProductFromWebCategory.fulfilled,
			(state, { payload, meta }) => {
				state.productCategories = state.productCategories.filter(
					(x) =>
						x.model[x.model.length - 1].id !== meta.arg.categoryId
				)
				const newMainId = payload
				if (newMainId >= 0) {
					const newMainCategory = state.productCategories
						.filter(
							(x) => x.model[x.model.length - 1].id == newMainId
						)
						.first()
					newMainCategory.model[
						newMainCategory.model.length - 1
					].mainCategory = true
				}
			}
		)

		builder.addCase(
			ProductPageThunks.changeProductWebCategory.fulfilled,
			(state, { meta }) => {
				const indexToChange: number = state.productCategories.findIndex(
					(x) => {
						for (const category of x.model) {
							if (category.id === meta.arg.categoryId) return true
						}
						return false
					}
				)
				const currentCategory = _.last(
					state.productCategories[indexToChange].model
				)
				const newCategoryRow = CategoryTreeUtils.getCategoriesByParent(
					meta.arg.newCategoryId,
					state.categories
				)
				const newRow = {
					index: indexToChange,
					model: newCategoryRow.map((x) => {
						return {
							id: x.id,
							name: x.name,
							parentId: x.parentId,
							imageUrl: x.imageUrl,
							children: x.children,
							mainCategory: currentCategory!.mainCategory,
							sort: x.sort,
						}
					}),
					selected: true,
				}
				state.productCategories.splice(indexToChange, 1, newRow)
				state.selectedProductCategory =
					newRow.model[newRow.model.length - 1]
				state.shouldReset = true
			}
		)

		builder.addCase(
			ProductPageThunks.setCategoryAsMain.fulfilled,
			(state, { meta }) => {
				state.productCategories.forEach((x) => {
					const last = _.last(x.model)
					if (last === undefined) return

					last.mainCategory = last.id === meta.arg.categoryId
				})
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
