import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../../../redux/reducers/pages/groupPage/graphicComponent"
import { GraphicComponentsThunks } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/thunks"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { GraphicComponentSelectors } from "../../../../../redux/reducers/pages/groupPage/graphicComponent/graphicComponentSelectors"

export default function VideoModal() {
	const group = useSelector(GroupPageSelectors.getGroup)
	const videoLink = useSelector(GraphicComponentSelectors.getVideoLink)
	const shouldOpenModal = useSelector(
		GraphicComponentSelectors.getShouldOpenVideoModel
	)

	const dispatch = useDispatch()

	const closeModal = () => dispatch(actions.setShouldOpenModal())
	const setLink = (link: string) => dispatch(actions.setVideoLink(link))

	const uploadVideo = () => {
		dispatch(
			GraphicComponentsThunks.uploadVideo({
				videoUrl: videoLink,
				productGroupId: group.id,
			})
		)
	}

	return (
		<Modal
			show={shouldOpenModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					Добавление видео
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Ссылка на видео (вся ссылка целиком):</p>
				<input
					type="text"
					className="form-control"
					onChange={(e) => setLink(e.currentTarget.value)}
					value={videoLink}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					className={"u-button large primary"}
					onClick={() => closeModal()}
				>
					Закрыть
				</button>
				<button
					className={"u-button large accent"}
					onClick={() => uploadVideo()}
					disabled={videoLink === ""}
				>
					Добавить
				</button>
			</Modal.Footer>
		</Modal>
	)
}
