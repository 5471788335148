import { ISystemFileLoadModel } from "../../../../../domain/models/files/FileLoadModel"
import { IFileType } from "../../../../../domain/models/files/FileType"
import { FileSubtype } from "../../../../../domain/models/files/FileSubtype"
import { IEcmFileDescription } from "../../../../../domain/models/files/EcmFileDescription"

export class ProductFileTypeFilters {
	static getPossibleFileTypesByLoadModel(
		file: ISystemFileLoadModel,
		fileTypes: IFileType[]
	) {
		const extension = file.file.extension
		const possibleFileTypes: IFileType[] = []
		for (const fileType of fileTypes) {
			for (const fileFormat of fileType.fileFormats) {
				if (fileFormat.name === extension) {
					possibleFileTypes.push(fileType)
				}
			}
		}
		return possibleFileTypes
	}

	static getPossibleFileTypesByFile(
		file: IEcmFileDescription,
		fileTypes: IFileType[]
	) {
		const extension = file.extension
		const possibleFileTypes: IFileType[] = []
		for (const fileType of fileTypes) {
			for (const fileFormat of fileType.fileFormats) {
				if (fileFormat.name === extension) {
					possibleFileTypes.push(fileType)
				}
			}
		}
		return possibleFileTypes
	}

	static getPossibleFileSubtypesByLoadModel(
		file: ISystemFileLoadModel,
		fileTypes: IFileType[]
	) {
		const possibleFileTypes = this.getPossibleFileTypesByLoadModel(
			file,
			fileTypes
		)
		const possibleFileSubtypes: FileSubtype[] = []
		for (const possibleFileType of possibleFileTypes) {
			if (
				!possibleFileSubtypes.some(
					(x) => x === possibleFileType.subType
				)
			) {
				possibleFileSubtypes.push(possibleFileType.subType)
			}
		}
		return possibleFileSubtypes
	}

	static getPossibleFileSubtypesByFile(
		file: IEcmFileDescription,
		fileTypes: IFileType[]
	) {
		const possibleFileTypes = this.getPossibleFileTypesByFile(
			file,
			fileTypes
		)
		const possibleFileSubtypes: FileSubtype[] = []
		for (const possibleFileType of possibleFileTypes) {
			if (
				!possibleFileSubtypes.some(
					(x) => x === possibleFileType.subType
				)
			) {
				possibleFileSubtypes.push(possibleFileType.subType)
			}
		}
		return possibleFileSubtypes
	}
}
