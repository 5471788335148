import Api from "../../../../api"
import {createAutogenApiThunk} from "../../../createApiThunkV2"
 
export class BullfactsThunks {
    static getTranslates = createAutogenApiThunk({
        typePrefix: "bullfacts/get-translates",
        apiCall: Api.translate.getTranslates,
        errorMessage: "Не удалось получить переводы"
    })

    static updateTranslate = createAutogenApiThunk({
        typePrefix: "bullfacts/update-translates",
        apiCall: Api.translate.updateTranslates,
        showSuccess: true,
        successMessage: "Строка успешно изменена",
        errorMessage: "Не удалось обновить перевод"
    })

    static updateAttributeUnit = createAutogenApiThunk({
        typePrefix: "bullfacts/update-attribute-unit",
        apiCall: Api.attributes.changeAttributeUnit,
        showSuccess: true,
        successMessage: "Строка успешно изменена",
        errorMessage: "Не удалось обновить аттрибут"
    })

    static updateAttributeShortname = createAutogenApiThunk({
        typePrefix: "bullfacts/update-attribute-shortname",
        apiCall: Api.attributes.changeAttributeShortname,
        showSuccess: true,
        successMessage: "Строка успешно изменена",
        errorMessage: "Не удалось обновить короткое имя аттрибута"
    })

    static translateValuesByTranslator = createAutogenApiThunk({
        typePrefix: "bullfacts/translate-values-by-translator",
        apiCall: Api.translate.translateValuesByTranslator,
        showLoader: false 
    })
}