import React from "react"
import "./adLoadingRow.scss"
import {SvgIcon} from "../icons/SvgIcon"

export function AdLoadingRow (props: IAdLoadingRowProps) {
    return <tr className="no-hover loading-wrapper">
        <td colSpan={props.colSpan} className="td-loading">
            <div className="wrapper">
                <SvgIcon name="loader" className="loader-icon" />
            </div>
        </td>
    </tr>
}

interface IAdLoadingRowProps {
    colSpan: number
}