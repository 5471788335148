import { useEffect, useState } from "react"
import AdSearchInput from "../../../../../../../library/inputs/AdSearchInput"
import NullableSelect from "../../../../../../common/basic/selectors/NullableSelect"
import { actions as actions1 } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent"
import { IProductGroupIdentity } from "../../../../../../../domain/types";
import { CategoryTabComponentThunks } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/thunks";
import { useDispatch, useSelector } from "react-redux";
import { CategoryTabSelectors } from "../../../../../../../redux/reducers/pages/groupPage/categoryTabComponent/categoryTabSelectors";
import ToOptionProvider from "../../../../../../../utils/ToOptionProvider";
import _ from "lodash";

export function MassAppointmentTable()
{
    const dispatch = useDispatch();
    const scopes = useSelector(CategoryTabSelectors.getScopes)
    const selectedScope = useSelector(CategoryTabSelectors.getSelectedScope)
    const groupsSelector = useSelector(CategoryTabSelectors.getGroups)
    const [searchValueGroup, setSearchValueGroup] = useState("")
    const [checkboxState, setCheckboxState] = useState<{[key: string]: boolean}>({})
    const [groups, setGroups] = useState<IProductGroupIdentity[]>(groupsSelector)
    const [filteredGroups, setFilteredGroups] = useState(groups)
    const setSelectedScope = (id: number | null) => dispatch(actions1.setSelectedScope(id))

	useEffect(() => {
		setGroups(groupsSelector)
	}, [groupsSelector])

    const handleSearchChangeGroup = (newValue) => {
		setSearchValueGroup(newValue)
		const lowerCaseValue = newValue.toLowerCase()
		const filtered = groups.filter((group) =>
			group.name.toLowerCase().includes(lowerCaseValue) ||
            group.id.toString().toLowerCase().includes(lowerCaseValue)
		)
		setFilteredGroups(filtered)
	}
    const handleCheckboxChange = (id: string) => {
		setCheckboxState((prevState) => ({
			...prevState,
			[id]: !prevState[id], // Переключаем состояние чекбокса
		}))
	}
    function handleAppoint(scopeId) {
		if (isAssignButtonDisabled) {
			// Если кнопка заблокирована, ничего не делаем
			return
		}
		// Получаем список выбранных productGroupId
		const selectedProductGroupIds = Object.keys(checkboxState).filter(
			(id) => checkboxState[id]
		)

		// Выполняем вашу логику для назначения с передачей выбранных данных
		dispatch(
			CategoryTabComponentThunks.addAllGroupsToScope({
				productGroupId: selectedProductGroupIds,
				scopeId: scopeId.id, // Передаем только id
			})
		)
		handleAppointSuccess()
	}
	// Обработчик успешного назначения групп
	const handleAppointSuccess = () => {
		setCheckboxState({}) // Сбрасываем состояние чекбоксов
		setSelectedScope(null) // Сбрасываем выбранную область
	}

    const isChecked = (id: string) => {
		return !!checkboxState[id] // Возвращает true, если чекбокс выбран, иначе - false
	}

    const isAssignButtonDisabled =
		!Object.values(checkboxState).some((value) => value) ||
		selectedScope === null

    return (
        <div>
            <div
                className="toolbar-row"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "60px",
                }}
            >
                <AdSearchInput
                    onChange={handleSearchChangeGroup}
                    value={searchValueGroup}
                    placeholder="Поиск"
                    style={{
                        width: "100px",
                        marginRight: "10px",
                    }}
                />
                <NullableSelect
                    value={selectedScope}
                    options={scopes}
                    onChange={(value) =>
                        setSelectedScope(value as number)
                    }
                    toOption={ToOptionProvider.scopeToOption}
                    className={"selector"}
                    height={31}
                    placeholder={"Выберите область применения"}
                />
                <button
                    className="u-button small accent"
                    title="Назначить"
                    onClick={() => handleAppoint(selectedScope)}
                    disabled={isAssignButtonDisabled}
                    style={{
                        marginLeft: "10px",
                        width: "100px",
                        height: "50px",
                        fontSize: "14px",
                    }}
                >
                    Назначить
                </button>
            </div>
            <div className="u-table__scroll-wrapper" style={{maxHeight: "770px"}}>
                <table className="u-table">
                    <thead className="u-thead">
                        <tr>
                            <th></th>
                            <th>Код</th>
                            <th>Наименование</th>
                        </tr>
                    </thead>
                    <tbody className="u-tbody">
                        {_.orderBy(filteredGroups, (x) => x.id).map(
                            (x) => {
                                return (
                                    <tr 
                                        key={x.id}
                                        onClick={() => handleCheckboxChange(x.id)}
                                    >
                                        <td className="u-table-column-checkbox-wrapper">
                                            {" "}
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${x.id}`}
                                                checked={isChecked(x.id)}
                                            />
                                        </td>
                                        <td>{x.id}</td>
                                        <td>{x.name}</td>
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}