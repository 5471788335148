import { IOptionType, IStringOptionType } from "./SimpleSelect"
import Select, { components } from "react-select"
import { isString } from "lodash"

interface INullableSelectorProps<T> {
	value: T | null
	options: T[]
	onChange: (value: number | string | null) => void
	toOption: (value: T) => IOptionType | IStringOptionType
	className?: string
	placeholder?: string
	height?: number
	isDisabled?: boolean
	noOptionsMessage?: string
	onInputChange?: (value: string) => void
	isMenuTop?: boolean
}

export default function NullableSelect<T>(props: INullableSelectorProps<T>) {
	const styles = {
		menu: (base) => ({
			...base,
			border: "1px solid var(--color-neutral-200)",
			boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
			borderRadius: "0.75em",
			marginTop: "5px",
		}),
		menuList: (base) => ({
			...base,

			padding: "0.75em 0",

			overflow: "overlay",

			scrollbarWidth: "thin",
			scrollbarColor: "var(--color-neutral-300) #ffffff00",

			"::-webkit-scrollbar": {
				width: "0.375em",
				height: "0px",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f102",
				margin: "0.5em",
			},
			"::-webkit-scrollbar-thumb": {
				background: "var(--color-neutral-300)",
				borderRadius: "5px",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
		control: (base, { isFocused, isDisabled }) => {
			function themes() {
				if (isDisabled) {
					return {
						backgroundColor: "var(--color-neutral-200)",
						color: "var(--color-texticon-primary)",
						border: "1px solid var(--color-texticon-primary)",
					}
				} else if (isFocused) {
					return {
						backgroundColor: "var(--color-primary-100)",
						color: "var(--color-primary-700)",
						border: "1px solid var(--color-accent-700)",
						outline: "4px solid var(--color-accent-100)",
					}
				} else {
					return {
						backgroundColor: "var(--color-primary-100)",
						color: "var(--color-primary-700)",
						border: "1px solid var(--color-neutral-200)",
						boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.04)",
					}
				}
			}

			return {
				...base,
				borderRadius: "0.75em",
				// height: props.height || 38,
				cursor: "pointer",
				// minHeight: props.height || 38,
				height: "auto",
				":hover": {
					borderColor:
						!isFocused && !isDisabled && "var(--color-neutral-200)",
				},
				...themes(),
			}
		},

		option: (base, { isDisabled, isFocused, isSelected }) => {
			// let background
			function background() {
				if (isDisabled) {
					return "var(--color-neutral-200)"
				} else if (isSelected) {
					return "var(--color-accent-700)"
				} else if (isFocused) {
					return "var(--color-accent-100)"
				} else {
					return "var(--color-primary-100)"
				}
			}

			function color() {
				if (isDisabled) {
					return "var(--color-texticon-primary)"
				} else if (isSelected) {
					return "var(--color-primary-100)"
				} else {
					return "var(--color-primary-700)"
				}
			}

			return {
				...base,
				backgroundColor: background(),
				color: color(),
				paddingTop: "4px",
				paddingBottom: "4px",
				wordBreak: "break-word",
			}
		},
		valueContainer: (provided) => ({
			...provided,
			// height: props.height || 38,
			padding: "0.7em 0.8em",
		}),

		input: (provided) => ({
			...provided,
			margin: "0px",
			padding: 0,
		}),

		singleValue: (base) => ({
			...base,
			lineHeight: "normal",
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			// height: props.height || 38
			height: "auto",
		}),

		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
	}

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				{
					// eslint-disable-next-line react/prop-types
					props.selectProps.menuIsOpen ? (
						<svg
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "1.5em", height: "1.5em" }}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.5 9.79452L4.38058 17L3 15.6027L11.5 7L20 15.6027L18.6194 17L11.5 9.79452Z"
								fill="#686868"
							/>
						</svg>
					) : (
						<svg
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "1.5em", height: "1.5em" }}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M18.6194 7L20 8.39726L11.5 17L3 8.39726L4.38058 7L11.5 14.2055L18.6194 7Z"
								fill="#686868"
							/>
						</svg>
					)
				}
			</components.DropdownIndicator>
		)
	}

	const options = props.options.map((x) => props.toOption(x))
	const selected = props.value !== null ? props.toOption(props.value) : null

	return (
		<Select
			options={options}
			menuPlacement={props.isMenuTop ? "top" : "auto"}
			className={props.className ?? ""}
			components={{ DropdownIndicator }}
			styles={styles}
			onChange={(newValue) => {
				if (isString(newValue)) {
					props.onChange((newValue?.value as string) ?? null)
				} else {
					props.onChange((newValue?.value as number) ?? null)
				}
			}}
			value={selected}
			placeholder={props.placeholder || "Выберите..."}
			isDisabled={props.isDisabled ?? false}
			noOptionsMessage={() => props.noOptionsMessage}
			isClearable={true}
			onInputChange={props.onInputChange}
		/>
	)
}
