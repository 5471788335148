import { AppState } from "../../../index"

export class ProductProductCatalogsSelectors {
	static getProductCatalogs = (state: AppState) =>
		state.pages.productPage.productCatalogsState.productsCatalogs

	static getIsChangingShowStatus = (state: AppState) =>
		state.pages.productPage.productCatalogsState.isChangingShowStatus

	static getIsOnSite = (state: AppState) =>
		state.pages.productPage.productCatalogsState.isOnSite
}
