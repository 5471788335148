import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import "./login.scss"
import {useNavigate} from "react-router-dom"
import {useLocation} from "react-router"
import {IHistoryState} from "../ProtectedRoute"
import {AppState} from "../../redux/reducers"
import {actions, InputStatus, LoginComponentState} from "../../redux/reducers/pages/loginPage"
import {actions as authActions, AuthState} from "../../redux/reducers/auth"
import {actions as informActions} from "../../redux/reducers/informer"
import AdDefaultInput, {AdInputStatus} from "../../library/inputs/AdDefaultInput"
import {ReactComponent as ImgAvtodeloLogo} from "../../assets/logos/img-avtodelo-logo.svg"
import {ReactComponent as ImgAvtodeloLogoPic} from "../../assets/logos/img-avtodelo-logo-pic.svg"
import {hashPassword} from "../../utils/passwordHasher"
import Api from "../../api"
import {ModalStatus} from "../../library/modal/AdResultModal"
import RouteProvider from "../../utils/RouteProvider"


function Login() {
    const location = useLocation()
    const navigate = useNavigate()
    const authState = useSelector<AppState, AuthState>(s => s.authState)
    const local = useSelector<AppState, LoginComponentState>(x => x.pages.loginPage)
    const history = location.state as IHistoryState
    const dispatch = useDispatch()

    /**On mount we check that user already logged and redirect to index, called once**/
    useEffect(() => {
        navigateToIndexIfUsersAlreadyLogged()
    }, [])

    const setPassword = (newPass: string) => {
        dispatch(actions.passwordChanged(newPass))
    }

    const setUsername = (username: string) => {
        dispatch(actions.usernameChanged(username))
    }

    const navigateToIndexIfUsersAlreadyLogged = () =>  {
        if(authState.username != null && authState.token != null)
            navigate(RouteProvider.allAnother.path)
    }

    const navigateToPreviousPage = () => {
        let to: string
        if(history === undefined) {
            to = RouteProvider.allAnother.path
        } else {
            to = history?.from === location.pathname ? RouteProvider.allAnother.path : history?.from
        }
        navigate(to)
    }

    async function login() {
        dispatch(actions.setLoading(true))
        try {
            const passwordHash = hashPassword(local.password)
            const username = local.username

            const response = await Api.auth.login({username: username, password: passwordHash})
            if(!response.success) {
                if(response.status === 0) {
                    dispatch(informActions.showLoader({text: "Сервер недоступен", status: ModalStatus.error}))
                    return
                }
                let errorText = response.exception?.text
                if(errorText?.isNullOrEmpty())
                    errorText = "Введенный логин или пароль не верен"
                dispatch(informActions.showLoader({text: errorText! , status: ModalStatus.error}))
                return
            }

            dispatch(authActions.setCredentials({username: username, token: response.data!}))
            dispatch(actions.clearAfterLogin())

            navigateToPreviousPage()
        } catch (e) {
            dispatch(informActions.showLoader({text: "Сервер недоступен", status: ModalStatus.error}))
        } finally {
            dispatch(actions.setLoading(false))
        }
    }

    const isButtonDisabled = local.isLoginDisabled || local.isLoading
    const usernameError = local.usernameInputStatus !== InputStatus.AllOkay && local.username.length !== 0
    const passwordError = local.passwordInputStatus !== InputStatus.AllOkay && local.password.length !== 0

    const buttonText = local.isLoading
        ? "Загрузка"
        : "Войти"

    return (
        <div className="login-background">
            <div className="login-form-wrapper">
                <ImgAvtodeloLogoPic className="logo-pic" />
                <ImgAvtodeloLogo className="logo-text" />
                <form className="login-form"
                    onSubmit={async event => {
                        event.preventDefault()
                        await login()
                    }}>
                    <AdDefaultInput onChange={setUsername}
                        value={local.username}
                        type="text"
                        className="u-input"
                        errorText={local.usernameInputStatus}
                        status={usernameError
                            ? AdInputStatus.error
                            : AdInputStatus.default}
                        label="Логин"/>

                    <AdDefaultInput onChange={setPassword}
                        value={local.password}
                        type="password"
                        className="u-input last-input"
                        errorText={local.passwordInputStatus}
                        status={passwordError
                            ? AdInputStatus.error
                            : AdInputStatus.default}
                        label="Пароль" />

                    <button disabled={isButtonDisabled}
                        type="submit"
                        className={"u-button accent large"}>
                        <span>{buttonText}</span>
                    </button>
                    <div className="login-error">{local.errorText}</div>
                </form>
                <a href='/' className="password-forgot">Забыли пароль? Обратиться к администратору</a>
            </div>
        </div>
    )
}

export default Login