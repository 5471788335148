import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import { IProductWithAttributes } from "../../../../../../domain/types"
import { Dispatch } from "@reduxjs/toolkit"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"

export const saveAttributeValues = (
	accumulatedChanges: IProductWithAttributes[],
	groupId: string,
	canAccess: boolean,
	dispatch: Dispatch<any>
) => {
	if (!canAccess) return
	if (accumulatedChanges.isEmpty()) return

	const values: { attributeId: number; value: string; productId: string }[] = []
	const isInStatisticChanges: { productId: string, isInStatistic: boolean}[] = []
	for (const productChanges of accumulatedChanges) {
		productChanges.attributeValues.map((x) => {
			values.push({
				value: x.value,
				attributeId: x.id,
				productId: productChanges.id,
			})
		})
		isInStatisticChanges.push({ productId: productChanges.id, isInStatistic: productChanges.isInStatistic})
	}
	console.log(isInStatisticChanges)

	dispatch(
		TablePartComponentThunks.changeAttributesValues({
			productGroupId: groupId,
			values: values,
		})
	)
}

export const swapProductSortCommon = async (
	selectedGroupProduct: IProductWithAttributes | null,
	groupProducts: IProductWithAttributes[],
	groupId: string,
	targetSort: number,
	dispatch: Dispatch<any>
) => {
	if (!selectedGroupProduct) {
		dispatch(informerActions.inform("Выберите продукт для перемещения"))
		return
	}

	if (targetSort > groupProducts.length || targetSort < 1) {
		dispatch(informerActions.inform("Выходит за границы сортировки"))
		return
	}

	if (targetSort === selectedGroupProduct.sort) return

	const targetProductToSwap = groupProducts.find((x) => x.sort === targetSort)

	const a = await dispatch(
		TablePartComponentThunks.swapProductSort({
			productGroupId: groupId,
			firstProductId: selectedGroupProduct.id!,
			secondProductId: targetProductToSwap!.id,
		})
	)

	return a
}
