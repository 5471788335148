import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {useEffect} from "react"
import {actions} from "../../../redux/reducers/catalogs"
import {CatalogsThunks} from "../../../redux/reducers/catalogs/thunk"
import SimpleSelect, {IOptionType} from "../../../app/common/basic/selectors/SimpleSelect"
import {CatalogGroup, ICatalog} from "../../../domain/types"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"

const toOption = (catalog: ICatalog): IOptionType => {
    return {
        value: catalog.id,
        label: catalog.name
    }
}

function CatalogSelector(props: ICatalogSelectorGroups) {
    const dispatch = useDispatch()
    const catalogs = useSelector(CatalogSelectors.getByCatalogGroup(props.catalogGroup))
    const selected = useSelector(CatalogSelectors.getSelected)

    useEffect(() => {
        dispatch(CatalogsThunks.getCatalogs())
    }, [])

    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={toOption}
        isDisabled={props.isDisabled}
        options={catalogs}
        className={"selector " + props.className}
        onChange={newValue => changeSelected(newValue)}
        value={selected}
        title={"Каталог"}
        height={props.height}
    />
}

interface ICatalogSelectorGroups {
    catalogGroup: CatalogGroup,
    height?: number,
    className?: string,
    isDisabled?: boolean
}

export default CatalogSelector