import React, {useEffect} from "react"
import DistributionTypeSelector from "../../../library/smartComponents/distributionSelector/DistributionSelector"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/pages/categoryPage"
import "./productGroupListToolbar.scss"
import {getProductGroupsByCatalogsThunk} from "../../../redux/reducers/pages/categoryPage/thunk"
import {useDebouncedCallback} from "use-debounce"
import DebounceProvider from "../../../utils/DebounceProvider"
import AdSearchInput from "../../../library/inputs/AdSearchInput"
import SelectorBlock from "./SelectorBlock"
import {CategoriesPageSelector} from "../../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import {CatalogGroupSelectors} from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import {CatalogSelectors} from "../../../redux/reducers/catalogs/catalogSelectors"
import {PriceGroupSelectors} from "../../../redux/reducers/priceGroups/priceGroupSelectors"
import {SellmarksSelectors} from "../../../redux/reducers/sellmarks/SellmarksSelectors"
import {LanguageSelectors} from "../../../redux/reducers/languages/languageSelectors"
import {DistributionTypeSelectors} from "../../../redux/reducers/distributionsTypes/DistributionTypeSelectors"
import {isEnter, isEscape} from "../../../utils/KeysCodes"

export default function ProductGroupListToolbar() {
    return (
        <div className="cat-product-group-list-toolbar-container u-toolbar">
            <div className="toolbar-row">
                <SearchWrapper/>
                <DistributionTypeSelector/>
            </div>
            <div className="toolbar-row small-selectors">
                <SelectorBlock/>
            </div>
        </div>
    )
}

function SearchWrapper() {

    const dispatch = useDispatch()
    const isGroupsLoading = useSelector(CategoriesPageSelector.getIsGroupsLoading)
    const filter = useSelector(CategoriesPageSelector.getFilter)

    const catalog = useSelector(CatalogSelectors.getSelected)
    const priceGroup = useSelector(PriceGroupSelectors.getSelected)
    const sellmark = useSelector(SellmarksSelectors.getSelected)
    const language = useSelector(LanguageSelectors.getSelected)
    const distrType = useSelector(DistributionTypeSelectors.getSelected)
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)
    const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)

    const debouncedFilter = useDebouncedCallback((args) => setFilter(args), DebounceProvider.getDebounce())

    function setFilter(filter: string) {
        dispatch(actions.setFilter(filter))
    }

    const loadGroups = () => {
        dispatch(getProductGroupsByCatalogsThunk({
            priceGroupId: priceGroup.id,
            languageId: language.id,
            sellmarkId: sellmark.id,
            catalogId: catalog.id,
            distributionType: distrType.value,
            catalogGroup: catalogGroup.id,
            searchString: filter
        }))
    }

    useEffect(() => {
        if (wasCatalogsInit && priceGroup.id > -1 && sellmark.id > -1) {
            loadGroups()
        }
    }, [priceGroup.id,
        language.id,
        sellmark.id,
        catalog.id,
        wasCatalogsInit,
        distrType.value,
        filter])

    const onKeyUp = (event) => {
        if (isGroupsLoading)
            return
        if (isEnter(event)) {
            dispatch(getProductGroupsByCatalogsThunk({
                priceGroupId: priceGroup.id,
                languageId: language.id,
                sellmarkId: sellmark.id,
                catalogId: catalog.id,
                distributionType: distrType.value,
                catalogGroup: catalogGroup.id,
                searchString: filter
            }))
        }
        if (isEscape(event)) {
            setFilter("")
        }
    }

    return <AdSearchInput onChange={debouncedFilter} onKeyUp={onKeyUp}/>
}
