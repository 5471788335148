import { IFileType } from "../../../../../domain/models/files/FileType"
import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"
import { reducer as unresolvedFilesReducer } from "./unresolvedFiles"
import { reducer as textFilesReducer } from "./textFiles"
import { reducer as graphicFilesReducer } from "./graphicFiles"
import { ISystemFileLoadModel } from "../../../../../domain/models/files/FileLoadModel"
import { FileStatus } from "../../../../../domain/models/files/FileStatus"
import { FileSubtype } from "../../../../../domain/models/files/FileSubtype"
import { IShortEcmFileDescription } from "../../../../../domain/models/files/EcmFileDescription"
import { getNameAndExtension } from "../../../../../utils/FileUtils"

export type ProductFilesCommonState = {
	fileTypes: IFileType[]
	filesToLoad: ISystemFileLoadModel[]

	/**
	 * Количество загружаемых на сервер изображений
	 * */
	loadingFilesCount: number
	/**
	 * Количество загруженных на сервер изображений
	 * */
	loadedFilesCount: number
}

const INITIAL_STATE: ProductFilesCommonState = {
	fileTypes: [],
	filesToLoad: [],
	loadingFilesCount: 0,
	loadedFilesCount: 0,
}

const slice = createSlice({
	name: "productFiles",
	initialState: INITIAL_STATE,
	reducers: {
		clearFilesToLoad(state) {
			state.filesToLoad = []
			state.loadedFilesCount = 0
			state.loadingFilesCount = 0
		},
		addFileToLoad(
			state,
			{ payload }: PayloadAction<IShortEcmFileDescription>
		) {
			let loadStatus = FileStatus.MoreInfoNeeded
			const possibleFileTypes = state.fileTypes.flatMap((fileType) =>
				fileType.fileFormats.map((fileFormat) => fileFormat.name)
			)
			if (!possibleFileTypes.includes(payload.extension)) {
				loadStatus = FileStatus.Error
			}
			if (
				state.filesToLoad.some(
					(x) =>
						x.file.name === payload.name &&
						x.file.extension === payload.extension
				)
			)
				return
			state.filesToLoad.push({
				file: payload,
				loadStatus: loadStatus,
				typeId: 0,
				subtypeId: 0,
			})
		},
		setFileToLoadType(
			state,
			{
				payload,
			}: PayloadAction<{ file: IShortEcmFileDescription; typeId: number }>
		) {
			const type = state.fileTypes.find((x) => x.id === payload.typeId)
			if (!type) return
			const fileToLoad = state.filesToLoad.find(
				(x) =>
					x.file.name === payload.file.name &&
					x.file.extension === payload.file.extension
			)
			if (!fileToLoad) return

			fileToLoad.typeId = type.id
		},
		setFileToLoadSubtype(
			state,
			{
				payload: { file, subtypeId },
			}: PayloadAction<{
				file: IShortEcmFileDescription
				subtypeId: number
			}>
		) {
			const subType = state.fileTypes.filter(
				(x) => x.subType === subtypeId
			)
			if (subType.length === 0) return

			const fileToLoad = state.filesToLoad.find(
				(x) =>
					x.file.name === file.name &&
					x.file.extension === file.extension
			)
			if (!fileToLoad) return
			if (fileToLoad.loadStatus === FileStatus.ErrorOnServer) return

			fileToLoad.subtypeId = subtypeId
			fileToLoad.typeId = subType[0].id

			if (fileToLoad.subtypeId !== FileSubtype.unknown) {
				fileToLoad.loadStatus = FileStatus.Loaded
			} else {
				fileToLoad.loadStatus = FileStatus.MoreInfoNeeded
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getFileTypes.fulfilled,
			(state, { payload }) => {
				state.fileTypes = payload
			}
		)
		builder.addCase(ProductPageThunks.uploadFile.pending, (state) => {
			state.loadingFilesCount += 1
		})
		builder.addCase(
			ProductPageThunks.uploadFile.fulfilled,
			(state, { payload }) => {
				state.loadedFilesCount += 1
				const fileToLoad = state.filesToLoad.find(
					(x) =>
						x.file.name === payload.name &&
						x.file.extension === payload.extension
				)
				if (!fileToLoad) return

				fileToLoad.loadStatus = FileStatus.LoadedToServer
			}
		)
		builder.addCase(
			ProductPageThunks.uploadFile.rejected,
			(state, action) => {
				const fileWithErrors = action.meta.arg.path
				const [name, extension] = getNameAndExtension(fileWithErrors)
				const fileToLoad = state.filesToLoad.find(
					(x) =>
						x.file.name === name && x.file.extension === extension
				)

				if (!fileToLoad) return

				fileToLoad.loadStatus = FileStatus.ErrorOnServer
				fileToLoad.uploadErrorMessage =
					action.payload?.exception ?? "Ошибка загрузки файла"
			}
		)
	},
})

const { actions } = slice
const reducer = combineReducers({
	common: slice.reducer,
	unresolvedFiles: unresolvedFilesReducer,
	textFiles: textFilesReducer,
	graphicFiles: graphicFilesReducer,
})
export { actions, reducer }
