import { useDispatch, useSelector } from "react-redux"
import { GroupPageSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { TablePartSelectors } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/tablePartSelectors"
import { actions } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent"
import { actions as informerActions } from "../../../../../../redux/reducers/informer"
import { TablePartComponentThunks } from "../../../../../../redux/reducers/pages/groupPage/tablePartComponent/thunk"
import AdDefaultInput from "../../../../../../library/inputs/AdDefaultInput"
import NullableSelect from "../../../../../common/basic/selectors/NullableSelect"
import ToOptionProvider from "../../../../../../utils/ToOptionProvider"
import { SvgIcon } from "../../../../../../library/icons/SvgIcon"
import React from "react"
import { GroupPageAuthSelectors } from "../../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

/***
 * Функционал добавления/удаления/замены товара в группу
 * */
export function ProductToGroupAppendController() {
	const dispatch = useDispatch()
	const group = useSelector(GroupPageSelectors.getGroup)
	const article = useSelector(TablePartSelectors.getArticle)
	const selectedProduct = useSelector(TablePartSelectors.getSelectedProduct)
	const products = useSelector(TablePartSelectors.getProducts)
	const groupProducts = useSelector(TablePartSelectors.getGroupProducts)
	const selectedGroupProduct = useSelector(
		TablePartSelectors.getSelectedGroupProduct
	)
	const canControl = useSelector(GroupPageAuthSelectors.getCanControlProducts)

	const setSelectedProduct = (id: string | null) =>
		dispatch(actions.setSelectedProduct(id))
	const setArticle = (value: string) => dispatch(actions.setArticle(value))

	const onArticleEnter = (event) => {
		if (event.key === "Enter") {
			const trimmedArticle = article.trim()
			const productToAdd = products.find((x) => x.id === trimmedArticle)
			const isAlreadyAdded =
				groupProducts.findIndex((x) => x.id === trimmedArticle) !== -1
			if (isAlreadyAdded) {
				dispatch(
					informerActions.inform(
						"Данный товар уже добавлен в карточку"
					)
				)
				return
			}
			if (productToAdd === undefined) {
				dispatch(informerActions.inform("Данный товар не существует"))
				return
			}
			setArticle("")
			dispatch(
				TablePartComponentThunks.addProductToGroup({
					productGroupId: group.id,
					productIds: [productToAdd.id],
				})
			)
		}
	}

	const addProductToProductGroup = () => {
		if (group.isToolset && groupProducts.notEmpty()) {
			dispatch(
				informerActions.inform(
					"Набор может содержать только один продукт"
				)
			)
			return
		}
		if (selectedProduct?.id === null) {
			dispatch(informerActions.inform("Выберите продукт для добавления"))
			return
		}
		if (groupProducts.findIndex((x) => x.id === selectedProduct!.id) > -1) {
			dispatch(informerActions.inform("Продукт уже добавлен"))
			return
		}
		dispatch(
			TablePartComponentThunks.addProductToGroup({
				productGroupId: group.id,
				productIds: [selectedProduct!.id],
			})
		)
	}

	const removeProductFromGroup = () => {
		if (selectedGroupProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для удаления"))
			return
		}
		dispatch(
			TablePartComponentThunks.removeProductFromGroup({
				productGroupId: group.id,
				productId: selectedGroupProduct.id,
			})
		)
	}

	const replaceProductInGroup = () => {
		if (selectedGroupProduct === null) {
			dispatch(informerActions.inform("Выберите продукт для замены"))
			return
		}
		if (selectedProduct?.id === null) {
			dispatch(
				informerActions.inform(
					"Выберите продукт которым заменить текущий"
				)
			)
			return
		}

		dispatch(
			TablePartComponentThunks.replaceProductInGroup({
				productGroupId: group.id,
				productId: selectedGroupProduct.id,
				newProductId: selectedProduct!.id,
			})
		)
	}

	return (
		<>
			{canControl ? (
				<>
					<div>Товар</div>
					<AdDefaultInput
						className={"attribute-input"}
						noControls={true}
						value={article}
						onChange={setArticle}
						onKeyUp={(e) => {
							e.nativeEvent.stopPropagation()
							onArticleEnter(e)
						}}
						disabled={!canControl}
						placeholder="Артикул"
					/>

					<NullableSelect
						value={selectedProduct}
						placeholder={"Наименование товара"}
						options={products}
						isDisabled={!canControl}
						onChange={(e) => setSelectedProduct(e as string)}
						toOption={ToOptionProvider.productIdentityToOption}
						noOptionsMessage={"No options"}
						className={"selector selector--name"}
					/>

					<button
						className={"u-button square primary"}
						onClick={() => addProductToProductGroup()}
						disabled={!canControl}
					>
						<SvgIcon name={"circle-plus"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => replaceProductInGroup()}
						disabled={!canControl}
					>
						<SvgIcon name={"pencil-create"} />
					</button>
					<button
						className={"u-button square primary"}
						onClick={() => removeProductFromGroup()}
						disabled={!canControl}
					>
						<SvgIcon name={"circle-x"} />
					</button>
				</>
			) : null}
		</>
	)
}
