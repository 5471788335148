import {AppState} from "../index"
import {CatalogGroup} from "../../../domain/types"
import {createSelector} from "@reduxjs/toolkit"
import _ from "lodash"
import {CategoryTree} from "../../../domain/services/CategoryTree"

export class CategoriesSelectors {

    private static getFilteredByCatalogGroup = (catalogGroup: CatalogGroup) => createSelector(
        [(state: AppState) => state.categoriesState.categories],
        (categories) =>
            catalogGroup === CatalogGroup.All || catalogGroup === CatalogGroup.Web
                ? categories
                : _.orderBy(categories, x => x.sort)
    )

    static getCategories = (catalogGroup: CatalogGroup) => createSelector(
        [CategoriesSelectors.getFilteredByCatalogGroup(catalogGroup), (state: AppState) => state.categoriesState.filter],
        (categories, filter) =>
            filter.isNullOrEmpty()
                ? categories
                : CategoryTree.searchByName(categories, filter)
    )

    static getSelected = (state: AppState) => state.categoriesState.selected
    static getSelectedGroups = (state: AppState) => state.categoriesState.selectedGroups
    static getLoadingState = (state: AppState) => state.categoriesState.loadingState
    static getCheckCategories = (state: AppState) => state.categoriesState.checkCategories
    static getIsLoading = (state: AppState) => state.categoriesState.isLoading
    static isShowImageSettings = (state: AppState) => state.categoriesState.imageSettings.isShow
    static groupImageSettings = (state: AppState) => state.categoriesState.imageSettings.group
    static getWebCategories = (state: AppState) => state.categoriesState.webCategories
    static getSelectedWebCategory = (state: AppState) => state.categoriesState.selectedWebCategory
}