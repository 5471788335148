import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	actions,
	CheckedProduct,
} from "../../../redux/reducers/pages/productsPage/productList"
import "./productListTable.scss"
import "../../../library/smartComponents/tables/tables-styles.scss"
import { ProductsListThunks } from "../../../redux/reducers/pages/productsPage/productList/thunks"
import { PriceGroupSelectors } from "../../../redux/reducers/priceGroups/priceGroupSelectors"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { ProductListSelectors } from "../../../redux/reducers/pages/productsPage/productList/productListSelectors"
import { AdNotFoundRow } from "../../../library/tables/AdNotFoundRow"
import { AdLoadingRow } from "../../../library/tables/AdLoadingRow"
import Constants from "../../../domain/Constants"
import { ProductsPageSelectors } from "../../../redux/reducers/pages/productsPage/productsPageSelectors"
import { actions as productPageActions } from "../../../redux/reducers/pages/productsPage"
import { GroupListSelectors } from "../../../redux/reducers/pages/productsPage/groupList/groupListSelectors"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productsPage/productPageAuthSelectors"
import RouteProvider from "../../../utils/RouteProvider"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { Link } from "react-router-dom"

function ProductListTable() {
	const priceGroup = useSelector(PriceGroupSelectors.getSelected)
	const language = useSelector(LanguageSelectors.getSelected)
	const isProductsLoading = useSelector(ProductListSelectors.isLoading)
	const filteredProducts = useSelector(
		ProductListSelectors.getFilteredProductsMemoized
	)
	const checkedPriceGroup = useSelector(
		ProductsPageSelectors.getCheckedPriceGroup
	)
	const isGroupChecked =
		useSelector(GroupListSelectors.getSelectedGroup) != null

	const selectedProducts = useSelector(
		ProductListSelectors.getSelectedProducts
	)
	const statisticsFilter = useSelector(
		GroupListSelectors.getSelectedGroupStatisticFilter
	)

	const canAddToGroup = useSelector(ProductPageAuthSelectors.canAddProduct)

	const dispatch = useDispatch()

	useEffect(() => {
		if (priceGroup.id > -1) {
			const priceGroupId =
				priceGroup.id === Constants.AllPriceGroupsId
					? null
					: priceGroup.id
			dispatch(
				ProductsListThunks.getProducts({
					priceGroupId: priceGroupId,
					languageId: language.id,
					statisticFilter: statisticsFilter.value,
				})
			)
		}
	}, [language.id, priceGroup.id, statisticsFilter.value])

	useEffect(() => {
		dispatch(actions.clearSelected())
	}, [language.id])

	const setSelected = (product: CheckedProduct) => {
		if (!canAddToGroup) return

		dispatch(actions.setSelected(product.id))

		const isLastProductWillUncheck =
			selectedProducts.length === 1 && product.checked
		const isNoGroupOrProductChecked = checkedPriceGroup === null
		const isProductsSetUpChecked = selectedProducts.length !== 0
		const shouldDisableCheck = isLastProductWillUncheck && !isGroupChecked

		if (isLastProductWillUncheck) {
			dispatch(productPageActions.setProductChecked(false))
		}
		if (isNoGroupOrProductChecked) {
			dispatch(
				productPageActions.setChosenPriceGroup(product.priceGroupId)
			)
			dispatch(productPageActions.setProductChecked(true))
		} else if (!isProductsSetUpChecked) {
			dispatch(productPageActions.setProductChecked(true))
		} else if (shouldDisableCheck) {
			dispatch(productPageActions.setChosenPriceGroup(null))
			dispatch(productPageActions.setProductChecked(false))
		}
	}

	return (
		<div className="product-left-column-table u-table__scroll-wrapper">
			<table className="u-table">
				<thead className="u-thead">
					<tr>
						{!canAddToGroup ? (
							<></>
						) : (
							<th className="u-table-column-checkbox-wrapper"></th>
						)}
						<th>Код</th>
						<th className="u-width-100%">Наименование</th>
						<th>···</th>
					</tr>
				</thead>
				<tbody className="u-tbody">
					{!isProductsLoading ? (
						filteredProducts.isEmpty() ? (
							<AdNotFoundRow colSpan={4} />
						) : (
							filteredProducts.map((x) => {
								return (
									<tr
										className={
											x.checked
												? "table-row--selected"
												: ""
										}
										key={x.id}
									>
										{!canAddToGroup ? (
											<></>
										) : (
											<td className="u-table-column-checkbox-wrapper">
												<input
													disabled={!canAddToGroup}
													id={x.id}
													checked={x.checked}
													type="checkbox"
													onChange={() =>
														setSelected(x)
													}
												/>
											</td>
										)}
										<td>{x.id}</td>
										<td>
											{x.name}
											<label
												className="label"
												htmlFor={x.id}
											/>
										</td>
										<td>
											<Link
												to={RouteProvider.productPage.generateParameterPath(
													{ productId: x.id }
												)}
												target={"_blank"}
												/*style={{
													marginRight:
														"calc(1.4285714286em * var(--shift-amount))",
													marginLeft: "0.5em",
												}}*/
											>
												<SvgIcon name="clipboard-text" />
											</Link>
										</td>
									</tr>
								)
							})
						)
					) : (
						<AdLoadingRow colSpan={3} />
					)}
					<tr></tr>
				</tbody>
			</table>
		</div>
	)
}

export default ProductListTable
