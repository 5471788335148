import { LocalStorageKeys } from "../domain/LocalStorageKeys"
import { ICatalogGroup, ILanguage } from "../domain/types"
import Constants from "../domain/Constants"
import { CatalogGroupsOptions } from "../domain/database/CatalogGroup"

export class LocalStorageProvider {
    static getToken(): string | null {
        return localStorage.getItem(LocalStorageKeys.TokenKey)
    }

    static setToken(token: string) {
        localStorage.setItem(LocalStorageKeys.TokenKey, token)
    }

    static removeToken() {
        localStorage.removeItem(LocalStorageKeys.TokenKey)
    }

    static getUser(): string | null {
        return localStorage.getItem(LocalStorageKeys.UsernameKey)
    }

    static setUser(username: string) {
        localStorage.setItem(LocalStorageKeys.UsernameKey, username)
    }

    static removeUser() {
        localStorage.removeItem(LocalStorageKeys.TokenKey)
    }

    static getLanguage(): ILanguage | null {
        const idString = localStorage.getItem(LocalStorageKeys.LanguageIdKey)
        const name = localStorage.getItem(LocalStorageKeys.LanguageNameKey)
        if (idString === null || name === null) {
            this.removeLanguage()
            return null
        }
        return {
            name: name,
            id: Number(idString)
        }
    }

    static setLanguage(language: ILanguage) {
        localStorage.setItem(LocalStorageKeys.LanguageIdKey, language.id.toString())
        localStorage.setItem(LocalStorageKeys.LanguageNameKey, language.name)
    }

    private static removeLanguage() {
        localStorage.removeItem(LocalStorageKeys.LanguageIdKey)
        localStorage.removeItem(LocalStorageKeys.LanguageNameKey)
    }

    static getTreePrintCatalog(): number {
        const catalogIdStr = localStorage.getItem(LocalStorageKeys.TreePrintCatalogKey)
        if (catalogIdStr === null)
            return Constants.Avtodelo2021CatalogId
        return Number(catalogIdStr)
    }

    static setTreePrintCatalog(catalogId: number) {
        localStorage.setItem(LocalStorageKeys.TreePrintCatalogKey, catalogId.toString())
    }

    static getCategoriesPrintCatalog(): number {
        const catalogIdStr = localStorage.getItem(LocalStorageKeys.CategoriesPrintCatalogKey)
        if (catalogIdStr === null)
            return Constants.Avtodelo2021CatalogId
        return Number(catalogIdStr)
    }

    static setCategoriesPrintCatalog(catalogId: number) {
        localStorage.setItem(LocalStorageKeys.CategoriesPrintCatalogKey, catalogId.toString())
    }

    static setProductGroupSelectCatalogGroup(catalogGroup: ICatalogGroup) {
        localStorage.setItem(LocalStorageKeys.ProductGroupSelectCatalogGroupKey, JSON.stringify(catalogGroup))
    }

    static getProductGroupSelectCatalogGroup(): ICatalogGroup {
        const catalogGroup = localStorage.getItem(LocalStorageKeys.ProductGroupSelectCatalogGroupKey)

        if (!catalogGroup) {
            const catalogGroupFirst = CatalogGroupsOptions.filter(u => u.isTree)[0];
            this.setProductGroupSelectCatalogGroup(catalogGroupFirst)

            return catalogGroupFirst;
        }

        return JSON.parse(catalogGroup) as ICatalogGroup
    }
}