import { ISelectable, ISelectableIndexModel } from "../../../../types"
import {
	CatalogGroup,
	ICatalogGroup,
	IProductGroupIdentity,
} from "../../../../../domain/types"
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit"
import CategoryTreeUtils from "../../../../../CategoryTreeUtils"
import { CategoryTabComponentThunks } from "./thunks"
import _ from "lodash"
import { IScopeOfApplication } from "../../../../../domain/models/scopes/IScopeOfApplication"
import { ICategory } from "../../../../../domain/models/categories/ICategory"
import { IGroupViewModel } from "../../../../../library/smartComponents/tables/productGroupTable/types"
import { LocalStorageProvider } from "../../../../../api/LocalStorageProvider"
import { CatalogGroupsOptions } from "../../../../../domain/database/CatalogGroup"

export type ScopeOfApplication = IScopeOfApplication & ISelectable

export type CategoriesTabState = {
	//текущие категории, которые есть у группы продуктов
	currentPrintedCategories: ISelectableIndexModel<ICategory>[]
	currentWebCategories: ISelectableIndexModel<ICategory>[]

	//все категории
	categoriesWeb: ICategory[]
	categoriesPrinted: ICategory[]

	//выбранная категория в ряду
	selectedWebCategories: ICategory[]
	selectedPrintedCategories: ICategory[]
	shouldResetPrinted: boolean
	shouldResetWeb: boolean

	//выбранная категория в таблице
	selectedCurrentPrintedCategory: ICategory | null
	selectedCurrentWebCategory: ICategory | null

	scopes: IScopeOfApplication[]
	selectedScope: IScopeOfApplication | null
	isScopesWasInit: boolean

	//Таблица скоупов
	currentScopes: ScopeOfApplication[]
	selectedCurrentScope: ScopeOfApplication | null

	isAreaEditorMenuOpen: boolean
	productGroups: IProductGroupIdentity[]

	selectedCatalogGroup: ICatalogGroup
}

const INITIAL_SCOPES: ScopeOfApplication[] = [
	{ id: -1, name: "loading", selected: false, sort: null },
]
const INITIAL_GROUPS: IProductGroupIdentity[] = [
	{
		id: "0",
		name: "loading",
		isDescriptionChecked: false,
		isImageChecked: false,
		priceGroupId: null,
		sort: null,
	},
]

const INITIAL_STATE: CategoriesTabState = {
	selectedCatalogGroup: LocalStorageProvider.getProductGroupSelectCatalogGroup(),
	selectedWebCategories: [],
	categoriesPrinted: [],
	categoriesWeb: [],
	selectedPrintedCategories: [],
	currentPrintedCategories: [],
	currentWebCategories: [],
	selectedCurrentWebCategory: null,
	selectedCurrentPrintedCategory: null,
	shouldResetPrinted: false,
	shouldResetWeb: false,
	isAreaEditorMenuOpen: false,
	productGroups: INITIAL_GROUPS,

	scopes: INITIAL_SCOPES,
	selectedScope: INITIAL_SCOPES[0],
	isScopesWasInit: false,
	currentScopes: [],
	selectedCurrentScope: null
}

const slice = createSlice({
	name: "new-product-cat-tab",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedCategory(
			state: CategoriesTabState,
			action: PayloadAction<{
				rowIndex: number
				catalogGroup: CatalogGroup
			}>
		) {
			let categoryFound = false
			if (action.payload.catalogGroup === CatalogGroup.Printed) {
				for (const row of state.currentPrintedCategories) {
					if (row.index === action.payload.rowIndex) {
						row.selected = true
						categoryFound = true
						//значения в ряду отфильтрованы, поэтому мы можем брать последний элемент
						const category = row.model[row.model.length - 1]
						state.selectedCurrentPrintedCategory = {
							mainCategory: null,
							children: category.children ?? [],
							name: category.name,
							imageUrl: category.imageUrl,
							parentId: category.parentId,
							id: category.id,
							sort: category.sort,
						}
					} else row.selected = false
				}
				if (!categoryFound) state.selectedCurrentPrintedCategory = null
				//отключаем выбранную веб категорию
				if (state.selectedCurrentWebCategory != null) {
					state.currentWebCategories.forEach(
						(x) => (x.selected = false)
					)
					state.selectedCurrentWebCategory = null
				}
				if (state.selectedCurrentScope != null) {
					state.currentScopes.forEach((x) => (x.selected = false))
					state.selectedCurrentScope = null
				}
			} else {
				for (const row of state.currentWebCategories) {
					if (row.index === action.payload.rowIndex) {
						row.selected = true
						categoryFound = true
						//значения в ряду отфильтрованы, поэтому мы можем брать последний элемент
						const category = row.model[row.model.length - 1]
						state.selectedCurrentWebCategory = {
							mainCategory: category?.mainCategory ?? false,
							children: category.children ?? [],
							name: category.name,
							imageUrl: category.imageUrl,
							parentId: category.parentId,
							id: category.id,
							sort: category.sort,
						}
					} else row.selected = false
				}
				if (!categoryFound) state.selectedCurrentPrintedCategory = null
				//отключаем выбранную категорию из каталога
				if (state.selectedCurrentPrintedCategory != null) {
					state.currentPrintedCategories.forEach(
						(x) => (x.selected = false)
					)
					state.selectedCurrentPrintedCategory = null
				}
				if (state.selectedCurrentScope != null) {
					state.currentScopes.forEach((x) => (x.selected = false))
					state.selectedCurrentScope = null
				}
			}
		},

		setSelectedCatalogGroup(state: CategoriesTabState, action: PayloadAction<number>) {
			const catalogGroup = CatalogGroupsOptions.first(u => u.id === action.payload)
			if (!catalogGroup)
				return

			state.selectedWebCategories = []
			state.currentWebCategories = []
			state.selectedCurrentWebCategory = null

			LocalStorageProvider.setProductGroupSelectCatalogGroup(catalogGroup)
			state.selectedCatalogGroup = catalogGroup
		},

		setShouldReset(
			state: CategoriesTabState,
			action: PayloadAction<CatalogGroup>
		) {
			if (action.payload === CatalogGroup.Printed)
				state.shouldResetPrinted = !state.shouldResetPrinted
			else state.shouldResetWeb = !state.shouldResetWeb
		},
		setRowPath(
			state: CategoriesTabState,
			action: PayloadAction<{
				category: ICategory | null
				catalogGroup: CatalogGroup
				level: number
			}>
		) {
			if (action.payload.category === null) {
				if (action.payload.catalogGroup === CatalogGroup.Printed)
					state.selectedPrintedCategories = []
				else state.selectedWebCategories = []
				return
			}
			if (action.payload.catalogGroup === CatalogGroup.Printed) {
				if (action.payload.category.children.length === 0) {
					state.selectedPrintedCategories =
						CategoryTreeUtils.getCategoriesByParent(
							action.payload.category.id,
							state.categoriesPrinted
						)
				} else state.selectedPrintedCategories = []
			} else {
				if (action.payload.category.children.length === 0) {
					state.selectedWebCategories =
						CategoryTreeUtils.getCategoriesByParent(
							action.payload.category.id,
							state.categoriesWeb
						)
				} else state.selectedWebCategories = []
			}
		},
		setScopes(
			state: CategoriesTabState,
			action: PayloadAction<IScopeOfApplication[]>
		) {
			state.scopes = action.payload;
		},
		setSelectedScope(
			state: CategoriesTabState,
			action: PayloadAction<number | null>
		) {
			if (action.payload === null) state.selectedScope = null

			const scope = state.scopes.find((x) => x.id === action.payload)
			if (scope === undefined) state.selectedScope = null
			else state.selectedScope = scope
		},
		setSelectedCurrentScope(
			state: CategoriesTabState,
			action: PayloadAction<number>
		) {
			for (const currentScope of state.currentScopes) {
				if (currentScope.id === action.payload) {
					currentScope.selected = true
					state.selectedCurrentScope = currentScope
				} else {
					currentScope.selected = false
				}
			}
			//отключаем подстветку у таблиц категорий
			if (state.selectedCurrentPrintedCategory != null) {
				state.currentPrintedCategories.forEach(
					(x) => (x.selected = false)
				)
				state.selectedCurrentPrintedCategory = null
			}
			if (state.selectedCurrentWebCategory != null) {
				state.currentWebCategories.forEach((x) => (x.selected = false))
				state.selectedCurrentWebCategory = null
			}
		},
		clearStateOnUnmount(state: CategoriesTabState) {
			state = INITIAL_STATE
		},
		setIsAreaEditorMenuOpen(
			state: CategoriesTabState,
			action: PayloadAction<boolean>
		) {
			state.isAreaEditorMenuOpen = action.payload
		},
		setProductGroups(
			state: CategoriesTabState,
			action: PayloadAction<IGroupViewModel[]>
		) {
			state.productGroups = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			CategoryTabComponentThunks.getProductGroupCategories.fulfilled,
			(state, action) => {
				switch (action.meta.arg.catalogGroup) {
					case CatalogGroup.Web:
					case CatalogGroup.Web_Latvia:
						state.currentWebCategories = action.payload.map(
							(pg, i) => {
								return {
									selected: false,
									index: i,
									model: pg.categoryPath
										.map((y) =>
											CategoryTreeUtils.findCategory(
												y,
												state.categoriesWeb
											)
										)
										.filter(u => u != null)
										.map((x) => {
											return {
												mainCategory: pg.mainCategory,
												children: x!.children,
												name: x!.name,
												imageUrl: x!.imageUrl,
												parentId: x!.parentId,
												id: x!.id,
												sort: 0,
											}
										})
										.filter((x) => x != null)
										.reverse(),
								}
							}
						)
						break
					case CatalogGroup.Printed:
						state.currentPrintedCategories = action.payload.map(
							(x, i) => {
								return {
									index: i,
									selected: false,
									model: x.categoryPath
										.map((y) =>
											CategoryTreeUtils.findCategory(
												y,
												state.categoriesPrinted
											)
										)
										.filter((x) => x != null)
										.map((x) => {
											return {
												mainCategory: null,
												children: x!.children,
												name: x!.name,
												imageUrl: null,
												parentId: x!.parentId,
												id: x!.id,
												sort: x!.sort,
											}
										})
										.reverse(),
								}
							}
						)
						break
				}
			}
		)
		builder.addCase(
			CategoryTabComponentThunks.getCategories.fulfilled,
			(state, action) => {
				if (action.meta.arg.catalogGroup === CatalogGroup.Printed)
					state.categoriesPrinted = action.payload
				else state.categoriesWeb = action.payload
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.changeProductGroupCategory.fulfilled,
			(state, action) => {
				if (action.meta.arg.catalogGroup === CatalogGroup.Printed) {
					const indexToChange: number =
						state.currentPrintedCategories.findIndex((x) => {
							for (const category of x.model) {
								if (category.id === action.meta.arg.categoryId)
									return true
							}
							return false
						})
					const newCategoryRow =
						CategoryTreeUtils.getCategoriesByParent(
							action.meta.arg.newCategoryId,
							state.categoriesPrinted
						)
					const newRow = {
						index: indexToChange,
						model: newCategoryRow.map((x) => {
							return {
								id: x.id,
								name: x.name,
								parentId: x.parentId,
								imageUrl: x.imageUrl,
								children: x.children,
								mainCategory: null,
								sort: x.sort,
							}
						}),
						selected: true,
					}
					state.currentPrintedCategories.splice(
						indexToChange,
						1,
						newRow
					)
					state.selectedCurrentPrintedCategory =
						newRow.model[newRow.model.length - 1]
				} else {
					const indexToChange: number =
						state.currentWebCategories.findIndex((x) => {
							for (const category of x.model) {
								if (category.id === action.meta.arg.categoryId)
									return true
							}
							return false
						})
					const currentCategory = _.last(
						state.currentWebCategories[indexToChange].model
					)
					const newCategoryRow =
						CategoryTreeUtils.getCategoriesByParent(
							action.meta.arg.newCategoryId,
							state.categoriesWeb
						)
					const newRow = {
						index: indexToChange,
						model: newCategoryRow.map((x) => {
							return {
								id: x.id,
								name: x.name,
								parentId: x.parentId,
								imageUrl: x.imageUrl,
								children: x.children,
								mainCategory: currentCategory!.mainCategory,
								sort: x.sort,
							}
						}),
						selected: true,
					}
					state.currentWebCategories.splice(indexToChange, 1, newRow)
					state.selectedCurrentWebCategory =
						newRow.model[newRow.model.length - 1]
				}
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.addProductGroupToCats.fulfilled,
			(state, action) => {
				if (action.meta.arg.catalogGroup === CatalogGroup.Printed) {
					//чтобы не перерасчитывать индексы всех рядов - просто отправляем индекс в минус,
					//он нужен только для внутренних вычислений, поэтому без разницы какой он
					state.currentPrintedCategories.unshift({
						index:
							state.currentPrintedCategories.length !== 0
								? state.currentPrintedCategories[0].index - 1
								: 0,
						model: state.selectedPrintedCategories.map((x) => {
							return {
								id: x.id,
								name: x.name,
								parentId: x.parentId,
								imageUrl: x.imageUrl,
								children: x.children,
								mainCategory: null,
								sort: x.sort,
							}
						}),
						selected: false,
					})
					state.shouldResetPrinted = true
					state.selectedPrintedCategories = []
				} else {
					state.currentWebCategories.unshift({
						index:
							state.currentWebCategories.length !== 0
								? state.currentWebCategories[0].index - 1
								: 0,
						model: state.selectedWebCategories.map((x) => {
							return {
								id: x.id,
								name: x.name,
								parentId: x.parentId,
								imageUrl: x.imageUrl,
								children: x.children,
								mainCategory:
									state.currentWebCategories.length === 0,
								sort: x.sort,
							}
						}),
						selected: false,
					})
					state.shouldResetWeb = true
					state.selectedWebCategories = []
				}
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.removeProductGroupFromCats.fulfilled,
			(state, action) => {
				if (action.meta.arg.catalogGroup === CatalogGroup.Printed) {
					state.currentPrintedCategories =
						state.currentPrintedCategories.filter(
							(x) =>
								x.model[x.model.length - 1].id !==
								action.meta.arg.categoryId
						)
				} else {
					state.currentWebCategories =
						state.currentWebCategories.filter(
							(x) =>
								x.model[x.model.length - 1].id !==
								action.meta.arg.categoryId
						)
					const newMainId = action.payload
					if (newMainId >= 0) {
						const newMainCategory = state.currentWebCategories
							.filter(
								(x) =>
									x.model[x.model.length - 1].id == newMainId
							)
							.first()
						newMainCategory.model[
							newMainCategory.model.length - 1
						].mainCategory = true
					}
				}
			}
		)
		builder.addCase(
			CategoryTabComponentThunks.setCategoryAsMain.fulfilled,
			(state, action) => {
				state.currentWebCategories.forEach((x) => {
					const last = _.last(x.model)
					if (last === undefined) return

					last.mainCategory = last.id === action.meta.arg.categoryId
				})
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.getScopesOfApplication.fulfilled,
			(state, action) => {
				state.scopes = action.payload
				state.isScopesWasInit = true
				state.selectedScope = null
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.getProductGroupsScopes.fulfilled,
			(state, action) => {
				state.currentScopes = action.payload
					.map((x) => {
						const scope = state.scopes.find(
							(scope) => scope.id === x.scopeId
						)
						if (scope === undefined) return null
						return {
							id: x.scopeId,
							name: scope.name,
							selected: false,
							sort: scope.sort,
						}
					})
					.filter((x) => x !== null)
					.map((x) => x!)
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.addProductGroupsToScope.fulfilled,
			(state, action) => {
				const scopeId = action.meta.arg.scopeId
				const scope = state.scopes.find((scope) => scope.id === scopeId)
				if (scope === undefined) return
				const maxSort: number =
					_.maxBy(state.scopes, (s) => s.sort)?.sort ?? 1

				state.currentScopes.push({
					id: scopeId,
					name: scope.name,
					selected: false,
					sort: maxSort,
				})
				state.selectedScope = null
			}
		)

		builder.addCase(
			CategoryTabComponentThunks.removeProductGroupsFromScope.fulfilled,
			(state, action) => {
				const scopeId = action.meta.arg.scopeId
				state.currentScopes = state.currentScopes.filter(
					(x) => x.id !== scopeId
				)
				state.selectedCurrentScope = null
			}
		)
		builder.addCase(
			CategoryTabComponentThunks.changeProductGroupsScope.fulfilled,
			(state, action) => {
				const scopeId = action.meta.arg.scopeId
				const newScopeId = action.meta.arg.newScopeId
				const newScope = state.scopes.find(
					(scope) => scope.id === newScopeId
				)
				if (newScope === undefined) return

				const index = state.currentScopes.findIndex(
					(x) => x.id === scopeId
				)
				if (index > -1) {
					state.currentScopes[index] = {
						id: newScope.id,
						name: newScope.name,
						selected: true,
						sort: newScope.sort,
					}
					state.selectedCurrentScope = state.currentScopes[index]
				}
			}
		)
		builder.addCase(
			CategoryTabComponentThunks.getGroups.fulfilled,
			(state, action) => {
				state.productGroups = action.payload
			}
		)
		/*builder.addCase(
			CategoryTabComponentThunks.getGroups.fulfilled,
			(state, action) => {
				if (queryTrimmer.shouldBeProcessed(action.meta.requestId)) {
					state.productGroups = action.payload.map((x) => ({
						id: x.id,
						name: x.name,
						checked: false,
						products: null,
						isDescriptionChecked: x.isDescriptionChecked,
						isLoading: false,
						isImageChecked: x.isImageChecked,
						sort: null,
						isLastActive: false,
						shouldHighlight: false,
						priceGroupId: x.priceGroupId,
					}))
				}
			}
		)*/
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
