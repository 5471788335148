import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { INotReadyToDeployGroup, IWebsite } from "../../domain/types"
import { IHideGroupByProductRequest, ITaxonomyGroupResponse } from "../../redux/reducers/pages/uploadDataToWebsitePage/type"

export default class WebsitesApi extends BaseApi {
	getWebsitesIdentity = (): Promise<IApplicationResponse<IWebsite[]>> =>
		this.sendQuery<IWebsite[]>(
			"/api/websites/identity",
			null,
			actionTypes.get,
			true
		)

	isOnSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<boolean>> =>
		this.sendQuery<boolean>(
			"/api/websites/is-on-site",
			data,
			actionTypes.get,
			true
		)

	isShowOnSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<boolean>> =>
		this.sendQuery<boolean>(
			"/api/websites/is-show-on-site",
			data,
			actionTypes.get,
			true
		)

	taxonomyOnSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<ITaxonomyGroupResponse>> =>
		this.sendQuery<ITaxonomyGroupResponse>(
			"/api/websites/groups/taxonomy-on-site",
			data,
			actionTypes.get,
			true
		)

	showOnSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/groups/status/show",
			data,
			actionTypes.put,
			true
		)

	unShowOnSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/groups/status/unshow",
			data,
			actionTypes.put,
			true
		)

	changeStatusGoupsOnSite = async (data: {
		productGroupIds: string[],
		show: boolean,
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/groups/statuses",
			data,
			actionTypes.put,
			true
		)

	hideGroupByProductsOnSite = async (data: {
		values: IHideGroupByProductRequest[]
		websiteId: number
	}): Promise<IApplicationResponse<number[] | null>> =>
		this.sendQuery<number[] | null>(
			"/api/websites/groups/hide-by-products",
			data,
			actionTypes.put,
			true
		)

	addGroupToSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/add-group",
			data,
			actionTypes.post,
			true
		)

	removeFromSite = async (data: {
		productGroupId: string
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/remove-group",
			data,
			actionTypes.delete,
			true
		)

	getNotReadyToDeploy = async (data: {
		catalogId: number
		websiteId: number
	}): Promise<IApplicationResponse<INotReadyToDeployGroup[]>> =>
		this.sendQuery<INotReadyToDeployGroup[]>(
			"/api/websites/not-ready-to-deploy",
			data,
			actionTypes.get,
			true,
			1800 * 1000
		)

	addCatalogToSite = async (data: {
		catalogId: number
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/add-catalog-to-site",
			data,
			actionTypes.put,
			true,
			1800 * 1000
		)

	removeCatalogFromSite = async (data: {
		catalogId: number
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/remove-catalog-to-site",
			data,
			actionTypes.delete,
			true,
			1800 * 1000
		)

	refreshCatalogOnSite = async (data: {
		catalogId: number
		websiteId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/refresh-catalog",
			data,
			actionTypes.put,
			true,
			18000 * 10000
		)

	fillWebsiteTable = async (data: {
		catalogId: number
		websiteId: number
		table: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/fill-website-table",
			data,
			actionTypes.put,
			true,
			1800 * 1000
		)

	partialWebsiteRefresh = async (data: {
		websiteId: number
		table: number
		catalogId: number
		type: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/websites/partial-refresh",
			data,
			actionTypes.put,
			true,
			1800 * 1000
		)
	removeProductFromSite = async (data: {
		file: File
		websiteId: number
	}): Promise<IApplicationResponse<string>> => {
		return this.sendQuery<string>(
			"/api/websites/remove-product-to-site",
			data,
			actionTypes.postFile,
			true,
			1800 * 1000
		)
	}
}
