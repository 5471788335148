import React from "react"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {actions} from "../../../redux/reducers/priceGroups"
import {PriceGroupThunks} from "../../../redux/reducers/priceGroups/thunk"
import SimpleSelect from "../../../app/common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../../utils/ToOptionProvider"
import {PriceGroupSelectors} from "../../../redux/reducers/priceGroups/priceGroupSelectors"
import Constants from "../../../domain/Constants"

interface IPriceGroupSelectorProps {
    height?: number
    shouldShowAllPriceGroupsOption?: boolean
    shouldShowRigaPriceGroupOption?: boolean
}

function PriceGroupSelector(props: IPriceGroupSelectorProps) {
    const notVisiblePriceGroups: number[] = []
    if(!props.shouldShowAllPriceGroupsOption)
        notVisiblePriceGroups.push(Constants.AllPriceGroupsId)
    if(!props.shouldShowRigaPriceGroupOption)
        notVisiblePriceGroups.push(Constants.RigaPriceGroupId)

    const priceGroups = useSelector(PriceGroupSelectors.getPriceGroups(notVisiblePriceGroups))
    const selected = useSelector(PriceGroupSelectors.getSelected)

    const dispatch = useDispatch()

    if(!priceGroups.some(x => x.id === selected.id))
        dispatch(actions.setSelected(priceGroups.first().id))

    useEffect(() => {
        dispatch(PriceGroupThunks.getPriceGroups())
    }, [])

    const changeSelected = (id: number) => {
        dispatch(actions.setSelected(id))
    }

    return <SimpleSelect toOption={ToOptionProvider.priceGroupToOption}
        traceName={"Price group"}
        options={priceGroups}
        className={"selector"}
        onChange={newValue => changeSelected(newValue)}
        height={props.height}
        value={selected}
    />
}

export default PriceGroupSelector