export class DateTimeLocaleProvider {
	static getDefaultLocale() {
		return "ru-RU"
	}

	static getDefaultLocaleOptions(): Intl.DateTimeFormatOptions {
		return {
			hourCycle: "h24",
			month: "short",
			year: "numeric",
			day: "numeric",
			weekday: "long",
			hour: "numeric",
			minute: "numeric",
		}
	}
}
