import { IPictogram } from "../../../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GraphicComponentsThunks } from "./thunks"
import Constants from "../../../../../domain/Constants"
import { INamedImage } from "../../../../../domain/models/images/NamedImage"

export type IGuidImage = INamedImage & {
	selectedTypeId: number
}

export type IPictogramViewModel = IPictogram & { selectedPictogramId: number }

export type GraphicTabState = {
	groupImages: IGuidImage[]
	groupPictograms: IPictogramViewModel[]

	shouldOpenVideoModel: boolean
	videoLink: string
}

const INITIAL_STATE: GraphicTabState = {
	groupImages: [],
	groupPictograms: [],

	shouldOpenVideoModel: false,
	videoLink: "",
}

const slice = createSlice({
	name: "new-product-graphic-tab",
	initialState: INITIAL_STATE,
	reducers: {
		setNewType(
			state: GraphicTabState,
			action: PayloadAction<{ image: IGuidImage; newTypeId: number }>
		) {
			const image = state.groupImages.find(
				(x) => x.typeId === action.payload.image.typeId
			)
			if (image) {
				image.selectedTypeId = action.payload.newTypeId
			}
		},
		setNewPictogram(
			state: GraphicTabState,
			action: PayloadAction<{
				pictogram: IPictogramViewModel
				newPictogramId: number
			}>
		) {
			const pictogram = state.groupPictograms.find(
				(x) => x.id === action.payload.pictogram.id
			)
			if (pictogram) {
				pictogram.selectedPictogramId = action.payload.newPictogramId
			}
		},
		setShouldOpenModal(state: GraphicTabState) {
			state.shouldOpenVideoModel = !state.shouldOpenVideoModel
		},
		setVideoLink(state: GraphicTabState, action: PayloadAction<string>) {
			state.videoLink = action.payload
		},
		clearStateOnUnmount() {
			return { ...INITIAL_STATE }
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			GraphicComponentsThunks.getProductImages.fulfilled,
			(state, action) => {
				state.groupImages = action.payload.map((x) => {
					return {
						...x,
						key: Date.now(),
						selectedTypeId: x.typeId,
						typeId: x.typeId,
						name: x.name,
						imageUrl: x.imageUrl,
					}
				})
			}
		)

		builder.addCase(
			GraphicComponentsThunks.uploadImage.fulfilled,
			(state, action) => {
				const imageType = action.meta.arg.imageType
				const imageUrl = action.payload.imageUrl
				const name = action.payload.name
				state.groupImages.push({
					imageUrl: imageUrl,
					typeId: imageType,
					key: Date.now(),
					name: name,
					selectedTypeId: imageType,
					modifyDate: action.payload.modifyDate,
					extension: action.payload.extension,
				})
			}
		)

		builder.addCase(
			GraphicComponentsThunks.uploadVideo.fulfilled,
			(state, action) => {
				const index = state.groupImages.findIndex(
					(x) => x.typeId === Constants.YoutubeImageType
				)
				if (index === -1)
					state.groupImages.push({
						imageUrl: action.payload,
						typeId: Constants.YoutubeImageType,
						key: Date.now(),
						name: "youtube",
						selectedTypeId: Constants.YoutubeImageType,
						modifyDate: new Date().toString(),
						extension: "youtube",
					})
				else state.groupImages[index].imageUrl = action.payload
				state.videoLink = ""
				state.shouldOpenVideoModel = !state.shouldOpenVideoModel
			}
		)

		builder.addCase(
			GraphicComponentsThunks.removeImage.fulfilled,
			(state, action) => {
				state.groupImages = state.groupImages.filter(
					(x) => x.typeId !== action.meta.arg.imageType
				)
			}
		)

		builder.addCase(
			GraphicComponentsThunks.getGroupPictograms.fulfilled,
			(state, action) => {
				state.groupPictograms = action.payload.map((x) => {
					return { ...x, selectedPictogramId: x.id }
				})
			}
		)

		builder.addCase(
			GraphicComponentsThunks.addPictogramToGroup.fulfilled,
			(state, action) => {
				if (action.payload)
					state.groupPictograms.push({
						...action.payload,
						selectedPictogramId: action.payload.id,
					})
			}
		)

		builder.addCase(
			GraphicComponentsThunks.removePictogramFromGroup.fulfilled,
			(state, action) => {
				const index = state.groupPictograms.findIndex(
					(x) => x.id === action.meta.arg.pictogramId
				)
				state.groupPictograms.splice(index, 1)
			}
		)

		builder.addCase(
			GraphicComponentsThunks.changeGroupPictogram.fulfilled,
			(state, action) => {
				const current = state.groupPictograms.find(
					(x) => x.id === action.meta.arg.pictogramId
				)
				if (current && action.payload) {
					current.id = action.payload.id
					current.imageUrl = action.payload.imageUrl
					current.sort = action.payload.sort
					current.selectedPictogramId = action.payload.id
					current.name = action.payload.name
				}
			}
		)
		builder.addCase(
			GraphicComponentsThunks.updateImage.fulfilled,
			(state, action) => {
				const image = state.groupImages.find(
					(x) => x.typeId === action.meta.arg.imageType
				)
				if (image) {
					image.imageUrl = action.payload.imageUrl
					image.name = action.payload.name
					image.key = Date.now()
				}
			}
		)
		builder.addCase(
			GraphicComponentsThunks.updateImageType.fulfilled,
			(state, action) => {
				const image = state.groupImages.find(
					(x) => x.typeId === action.meta.arg.currentTypeId
				)
				if (image) {
					image.typeId = action.meta.arg.targetTypeId
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
