import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import ImageBlock from "./ImageBlock"
import PictogramBlock from "./PictogramBlock"
import React from "react"
import { actions as graphicActions } from "../../../../../redux/reducers/pages/groupPage/graphicComponent"
import { useDispatch, useSelector } from "react-redux"
import useAuth from "../../../../../hooks/authorizationHook"
import { Role } from "../../../../../domain/Role"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import {
	getGroupMembership,
	GroupMembership,
} from "../../../../../domain/GroupMembership"

export default function Graphics() {
	return (
		<div className="u-droppod graphic-part">
			<div className="u-droppod-section-header">
				Графическая часть
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						<AddVideoModalControl />
					</div>
				</div>
			</div>

			<div className="u-droppod-scroll-wrapper">
				<div className="u-droppod-item-header">Изображения</div>
				<ImageBlock />

				<div className="u-droppod-item-header">Пиктограммы</div>
				<PictogramBlock />
			</div>
		</div>
	)
}

function AddVideoModalControl() {
	const dispatch = useDispatch()
	const group = useSelector(GroupPageSelectors.getGroup)

	const isContentRf = useAuth([Role.ContentManagerRF])
	const isPhotographer = useAuth([Role.Photographer])
	const isContentLv = useAuth([Role.ContentManagerLv])
	const isContentRg = useAuth([Role.ContentManagerRg])

	const groupMembership = getGroupMembership(group.priceGroupId)
	let canAccess = false
	switch (groupMembership) {
		case GroupMembership.Nothing:
		case GroupMembership.Russian:
			canAccess = isContentRf || isPhotographer
			break
		case GroupMembership.Latvian:
			canAccess = isContentLv || isContentRg
			break
	}

	const openModel = () => {
		if (!canAccess) return
		dispatch(graphicActions.setShouldOpenModal())
	}

	return (
		<button
			type="button"
			className="u-button large primary"
			onClick={() => openModel()}
			disabled={!canAccess}
		>
			<SvgIcon name={"video"} className={"link-icon"} />
			Добавить видео
		</button>
	)
}
