import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICatalog, INotReadyToDeployGroup, IProductGroup, IWebsite } from "../../../../domain/types"
import { UploadDataToSitesThunk } from "./thunks"
import { IOptionType } from "../../../../app/common/basic/selectors/SimpleSelect"

export enum WebsiteTable {
    "oc_product",
    "oc_product_attribute",
    "oc_attribute",
    "oc_product_complect",
    "oc_product_description",
    "oc_category",
    "oc_product_product_to_group",
    "oc_product_product_to_complect",
    "oc_option",
    "oc_product_group",
    "oc_product_group_to_product",
    "oc_partners",
    "oc_product_to_category",
    "oc_product_option",
    "oc_product_image"
}

export type UploadDataToWebsiteState = {
    isCatalogOnSiteOpen: boolean
    selectedCatalogOnSite: ICatalog
    selectedWebsiteOnSite: IWebsite
    notReadyToDeployGroups: INotReadyToDeployGroup[] | null
    //когда добавляем каталог к сайту или удаляем, мы должны обновить список групп, которые не будут задеплоены
    shouldUpdateNotDeployedGroups: boolean
    //получаем из инпута
    productGroupId: string
    productGroup: IProductGroup | null
    isGroupOnSite: boolean
    isShowGroupOnSite: boolean
    isGroupExists: boolean | null
    selectedTable: IOptionType,
    websiteTables: IOptionType[],
    file: {
        options: {
            isGroup: boolean
        },
        taxonomy: {
            isGroup: boolean,
            isProduct: boolean,
            isShow: boolean
        }
        group: {
            loading: boolean
        }
    }
}

const websiteTables: IOptionType[] = [
    { value: WebsiteTable.oc_product, label: "oc_product" },
    { value: WebsiteTable.oc_product_attribute, label: "oc_product_attribute" },
    { value: WebsiteTable.oc_attribute, label: "oc_attribute" },
    { value: WebsiteTable.oc_product_complect, label: "oc_product_complect" },
    { value: WebsiteTable.oc_product_description, label: "oc_product_description" },
    { value: WebsiteTable.oc_category, label: "oc_category" },
    { value: WebsiteTable.oc_product_product_to_group, label: "oc_product_product_to_group" },
    { value: WebsiteTable.oc_product_product_to_complect, label: "oc_product_product_to_complect" },
    { value: WebsiteTable.oc_option, label: "oc_option" },
    { value: WebsiteTable.oc_product_group, label: "oc_product_group" },
    { value: WebsiteTable.oc_product_group_to_product, label: "oc_product_group_to_product" },
    { value: WebsiteTable.oc_partners, label: "oc_partners" },
    { value: WebsiteTable.oc_product_to_category, label: "oc_product_to_category" },
    { value: WebsiteTable.oc_product_option, label: "oc_product_option" },
    { value: WebsiteTable.oc_product_image, label: "oc_product_image" },
]

const INITIAL_STATE: UploadDataToWebsiteState = {
    isCatalogOnSiteOpen: false,
    selectedCatalogOnSite: { id: 0, name: "loading", isPrinted: true },
    selectedWebsiteOnSite: { id: 0, name: "loading", schema: -1 },
    notReadyToDeployGroups: [],
    shouldUpdateNotDeployedGroups: true,
    productGroupId: "",
    productGroup: null,
    isGroupOnSite: false,
    isShowGroupOnSite: false,
    isGroupExists: null,
    selectedTable: websiteTables[0],
    websiteTables: websiteTables,
    file: {
        options: {
            isGroup: false,
        },
        taxonomy: {
            isGroup: false,
            isProduct: false,
            isShow: false
        },
        group: {
            loading: false
        }
    }
}

const slice = createSlice({
    name: "upload-data-to-site",
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedTable(state: UploadDataToWebsiteState, action: PayloadAction<number>) {
            const selected = state.websiteTables.find(x => x.value === action.payload)
            if (selected) {
                state.selectedTable = selected
            }
        },
        setIsCatalogOnSiteMenuOpen(state: UploadDataToWebsiteState, action: PayloadAction<boolean>) {
            state.isCatalogOnSiteOpen = action.payload
        },
        setSelectedCatalogOnSiteMenu(state: UploadDataToWebsiteState, action: PayloadAction<ICatalog>) {
            state.selectedCatalogOnSite = action.payload
        },
        setSelectedWebsiteOnSiteMenu(state: UploadDataToWebsiteState, action: PayloadAction<IWebsite>) {
            state.selectedWebsiteOnSite = action.payload
        },
        setProductGroupId(state: UploadDataToWebsiteState, action: PayloadAction<string>) {
            state.productGroupId = action.payload
        },
        setProductGroupAsNull(state: UploadDataToWebsiteState) {
            state.productGroup = null
        },
        setShouldUpdateNotDeployedGroups(state: UploadDataToWebsiteState, action: PayloadAction<boolean>) {
            state.shouldUpdateNotDeployedGroups = action.payload
        },
        setFileOptionIsGroup(state: UploadDataToWebsiteState, action: PayloadAction<boolean>) {
            state.file.options.isGroup = action.payload
            if (!state.file.options.isGroup)
                state.file.group.loading = false;
        },
        setFileGroupLoading(state: UploadDataToWebsiteState, action: PayloadAction<boolean>) {
            state.file.group.loading = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(UploadDataToSitesThunk.getNotReadyToDeploy.fulfilled, (state, action) => {
            state.notReadyToDeployGroups = action.payload
            state.shouldUpdateNotDeployedGroups = false
        })
        builder.addCase(UploadDataToSitesThunk.getNotReadyToDeploy.rejected, (state) => {
            state.shouldUpdateNotDeployedGroups = false
            state.notReadyToDeployGroups = null
        })

        builder.addCase(UploadDataToSitesThunk.getProductGroup.fulfilled, (state, action) => {
            if (!action.payload.wasCreate) {
                state.productGroup = null
                state.isGroupExists = false
            }
            else {
                state.isGroupExists = true
                state.productGroup = action.payload
            }
        })
        builder.addCase(UploadDataToSitesThunk.getProductGroup.rejected, (state) => {
            state.productGroup = null
            state.isGroupExists = false
        })

        builder.addCase(UploadDataToSitesThunk.isGroupOnSite.fulfilled, (state, action) => {
            state.isGroupOnSite = action.payload
        })

        builder.addCase(UploadDataToSitesThunk.addGroupToSite.fulfilled, (state) => {
            state.isGroupOnSite = true
        })

        builder.addCase(UploadDataToSitesThunk.removeGroupFromSite.fulfilled, (state) => {
            state.isGroupOnSite = false
        })

        builder.addCase(UploadDataToSitesThunk.isShowGroupOnSite.fulfilled, (state, action) => {
            state.isShowGroupOnSite = action.payload
        })

        builder.addCase(UploadDataToSitesThunk.showGroupOnSite.fulfilled, (state) => {
            state.isShowGroupOnSite = true
        })

        builder.addCase(UploadDataToSitesThunk.unSowGroupOnSite.fulfilled, (state) => {
            state.isShowGroupOnSite = false
        })

        builder.addCase(UploadDataToSitesThunk.checkGroup.fulfilled, (state, action) => {
            if (!action.payload.group.element?.wasCreate) {
                state.productGroup = null
                state.isGroupExists = false
            }
            else {
                state.isGroupExists = true
                state.productGroup = action.payload.group.element
            }

            state.isShowGroupOnSite = action.payload.isShowOnSite
            state.isGroupOnSite = action.payload.isOnSite
        })

        builder.addCase(UploadDataToSitesThunk.checkFirstGroupInFile.fulfilled, (state, action) => {
            state.file.taxonomy.isShow = action.payload.isShow
            state.file.taxonomy.isGroup = action.payload.isGroup
            state.file.taxonomy.isProduct = action.payload.isProduct
            state.file.group.loading = false
        })

        builder.addCase(UploadDataToSitesThunk.changeStatusGoupsOnSite.fulfilled, (state) => {
            state.file.taxonomy.isShow = !state.file.taxonomy.isShow;
        })
    }
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }