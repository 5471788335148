import { DeployException } from "../../domain/types"
import { Link } from "react-router-dom"
import RouteProvider from "../../utils/RouteProvider"
import { SvgIcon } from "../../library/icons/SvgIcon"
import React, { useEffect } from "react"
import { UploadDataToSitesThunk } from "../../redux/reducers/pages/uploadDataToWebsitePage/thunks"
import { useDispatch, useSelector } from "react-redux"
import { UploadDataSelectors } from "../../redux/reducers/pages/uploadDataToWebsitePage/uploadDataSelectors"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"

export default function NotReadyToDeployTable() {
	const notReadyToDeployGroups = useSelector(
		UploadDataSelectors.getNotReadyToDeployGroups
	)

	const shouldUpdateNotDeployedGroups = useSelector(
		UploadDataSelectors.getShouldUpdateNotDeployed
	)
	const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)
	const wasWebsitesInit = useSelector(WebsiteSelectors.getWasInit)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const website = useSelector(WebsiteSelectors.getSelected)

	const dispatch = useDispatch()

	useEffect(() => {
		if (
			(wasCatalogsInit && wasWebsitesInit && website.id > 0) ||
			(shouldUpdateNotDeployedGroups && website.id > 0)
		) {
			dispatch(
				UploadDataToSitesThunk.getNotReadyToDeploy({
					catalogId: catalog.id,
					websiteId: website.id,
				})
			)
		}
	}, [
		shouldUpdateNotDeployedGroups,
		wasCatalogsInit,
		wasWebsitesInit,
		catalog.id,
		website.id,
	])

	return (
		<>
			<div className="u-droppod-section-header">
				Группы, которые не будут записаны на сайт
			</div>
			<div className="upload-data__table u-table__scroll-wrapper">
				<table className="u-table">
					<thead className="u-thead">
						<tr className="">
							<th>Код</th>
							<th>Наименование</th>
							<th>Причина</th>
							<th className="">Url</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{notReadyToDeployGroups === null ? (
							<tr>
								<td colSpan={4}>Can`t load not ready groups</td>
							</tr>
						) : notReadyToDeployGroups.isEmpty() ? (
							<tr>
								<td colSpan={4}>All groups are ready</td>
							</tr>
						) : (
							notReadyToDeployGroups.map((x) => {
								return (
									<tr key={x.productGroupId}>
										<td>{x.productGroupId}</td>
										<td>{x.name}</td>
										<td>
											{DeployException.getMessage(
												x.reason
											)}
										</td>
										<td>
											<Link
												to={RouteProvider.productGroup.generateParameterPath(
													{
														productGroupId:
															x.productGroupId,
													}
												)}
											>
												<SvgIcon name={"link"} />
											</Link>
										</td>
									</tr>
								)
							})
						)}
						<tr></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
