import Api from "../../../../api"
import {createAutogenApiThunk, createResultFactoryApiThunk} from "../../../createApiThunkV2"

export class TranslatorPageThunks {
    static getTranslatesWithUrl = createAutogenApiThunk({
        typePrefix: "translator-page/get-translates-with-url",
        apiCall: Api.translate.getTranslatesWithUrl,
        errorMessage: "Не удалось получить переводы",
        showLoader: true,
    })

    static translateValuesByTranslator = createAutogenApiThunk({
        typePrefix: "translator-page/translate-values-by-translator",
        apiCall: Api.translate.translateValuesByTranslator,
        showLoader: true 
    })

    static saveTranslatedValues = createAutogenApiThunk({
        typePrefix: "translator-page/save-translated-values",
        apiCall: Api.translate.saveTranslatedValues,
        errorMessage: "Не удалось сохранить переводы.",
        successMessage: "Переводы успешно сохранены",
        showSuccess: true,
        showLoader: true
    })

    static downloadTranslates = createAutogenApiThunk({
        typePrefix: "translator-page/download-translates",
        apiCall: Api.files.getTranslatesInfo,
        errorMessage: "Не удалось скачать переводы",
        showLoader: true
    })
}