import React from "react"
import "../modules/productPageToolbar.scss"
import TextCheckbox from "../../common/basic/checkboxes/TextCheckbox"
import AdDefaultInput from "../../../library/inputs/AdDefaultInput"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { useDispatch, useSelector } from "react-redux"
import { ProductPageSelectors } from "../../../redux/reducers/pages/productPage/productPageSelectors"
import { actions } from "../../../redux/reducers/pages/productPage"
import { ProductPageThunks } from "../../../redux/reducers/pages/productPage/thunks"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { useNavigate } from "react-router-dom"
import RouteProvider from "../../../utils/RouteProvider"
import { ProductAttributesTable } from "../modules/ProductAttributesTable"
import ProductPageCharacteristicsSelectors from "../../../redux/reducers/pages/productPage/characteristics/ProductPageCharacteristicsSelectors"
import { ProductPageAuthSelectors } from "../../../redux/reducers/pages/productPage/productPageAuthSelector/productPageAuthSelector"

export default function ProductPageToolbar() {
	return (
		<>
			<MainToolbar />

			<div className={"toolbar-row"}>
				<div className={"u-table__scroll-wrapper"}>
					<ProductAttributesTable />
				</div>
			</div>
		</>
	)
	// @ts-ignore
}

function MainToolbar() {
	const product = useSelector(ProductPageSelectors.getProduct)
	if (!product) {
		return <></>
	}

	const dispatch = useDispatch()

	const setName = (name: string) => dispatch(actions.setName(name))
	const setPhotoChecked = (isChecked: boolean) =>
		dispatch(actions.setPhotoChecked(isChecked))
	const setDescriptionChecked = (isChecked: boolean) =>
		dispatch(actions.setDescriptionChecked(isChecked))

	const canChangeProductName = useSelector(
		ProductPageAuthSelectors.getCanChangeProductName
	)
	const canChangePhotoVerification = useSelector(
		ProductPageAuthSelectors.getCanChangePhotoVerification
	)
	const canChangeDescriptionVerification = useSelector(
		ProductPageAuthSelectors.getCanChangeDescriptionVerification
	)

	return (
		<>
			<div className="u-droppod-section-header">О товаре</div>
			<div className="u-toolbar">
				<div className="new-product-pb-toolbar__first-row-wrapper toolbar-row">
					<AdDefaultInput
						className="input--id"
						value={product?.id ?? ""}
						disabled={true}
						placeholder="№"
					/>
					<AdDefaultInput
						className="input--name"
						value={product?.name ?? ""}
						onChange={setName}
						placeholder="Наименование продукта"
						disabled={!canChangeProductName}
					/>
					<>
						<TextCheckbox
							onChange={(value) => setPhotoChecked(value)}
							text={"Фото"}
							isChecked={product.isImagesChecked}
							readonly={!canChangePhotoVerification}
						/>
						<TextCheckbox
							onChange={(value) => setDescriptionChecked(value)}
							text={"Описание"}
							isChecked={product.isDescriptionChecked}
							readonly={!canChangeDescriptionVerification}
						/>
					</>
					<AdDefaultInput
						className="sized-input"
						value={product.priceGroupName}
						disabled={true}
						placeholder="№"
						noControls={true}
						size={product.priceGroupName.length}
					/>
					<ProductControls />
				</div>
			</div>
		</>
	)
}

function ProductControls() {
	const product = useSelector(ProductPageSelectors.getProduct)
	if (product === null) return <></>

	const language = useSelector(LanguageSelectors.getSelected)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const isProductUpdating = useSelector(
		ProductPageSelectors.getIsProductUpdating
	)
	const isProductRemoving = useSelector(
		ProductPageSelectors.getIsProductRemoving
	)

	const canDeletedProduct = useSelector(
		ProductPageAuthSelectors.getCanDeleteProduct
	)
	const updateProduct = () => {
		if (isProductUpdating || isProductRemoving) return
		dispatch(
			ProductPageThunks.updateProduct({
				productId: product.id,
				languageId: language.id,
				name: product.name,
				isImagesChecked: product.isImagesChecked,
				isDescriptionChecked: product.isDescriptionChecked,
			})
		)
	}

	const deleteProduct = async () => {
		if (isProductUpdating || isProductRemoving) return

		const res = await dispatch(
			ProductPageThunks.deleteProduct({
				productId: product.id,
			})
		)
		// @ts-ignore
		if (!res.meta.rejectedWithValue) {
			navigate(RouteProvider.products.path)
		}
	}

	return (
		<>
			<button
				className="u-button primary square"
				onClick={() => updateProduct()}
				title="Сохранить изменения"
			>
				{isProductUpdating ? (
					<SvgIcon name="refresh-cw" className="u-rotating" />
				) : (
					<SvgIcon name="floppy" />
				)}
			</button>
			<button
				className="u-button primary square"
				onClick={() => deleteProduct()}
				title="Удалить товар"
				disabled={!canDeletedProduct}
			>
				{isProductRemoving ? (
					<SvgIcon name="refresh-cw" className="u-rotating" />
				) : (
					<SvgIcon name="circle-x" />
				)}
			</button>
			<button
				className="u-button primary square"
				onClick={() => navigator.clipboard.writeText("•")}
				title="В буфер обмена"
			>
				<SvgIcon name="circle" />
			</button>
			<button
				className="u-button accent large"
				onClick={() => {
					console.log("clicked")
				}}
			>
				Обновить
			</button>
		</>
	)
}
