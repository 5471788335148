import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import { IProductGroupIdentity, IProductGroupScope } from "../../domain/types"
import { IScopeOfApplication } from "../../domain/models/scopes/IScopeOfApplication"
import { GroupStatisticFilter } from "../../domain/GroupStatisticFilter"

export default class ScopeOfApplicationApi extends BaseApi {
	getScopes = async (data: {
		languageId: number
	}): Promise<IApplicationResponse<IScopeOfApplication[]>> =>
		this.sendQuery<IScopeOfApplication[]>(
			"/api/application-scope",
			data,
			actionTypes.get,
			true
		)

	getProductGroupsScopes = async (data: {
		productGroupId: string
	}): Promise<IApplicationResponse<IProductGroupScope[]>> =>
		this.sendQuery<IProductGroupScope[]>(
			"/api/application-scope/product-group-scopes",
			data,
			actionTypes.get,
			true
		)

	getProductScopes = async (data: {
		productId: string
		languageId: number
	}): Promise<IApplicationResponse<IScopeOfApplication[]>> =>
		this.sendQuery<IScopeOfApplication[]>(
			"/api/application-scope/product-scopes",
			data,
			actionTypes.get,
			true
		)

	addProductGroupsToScope = async (data: {
		productGroupId: string
		scopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/add-product-group-to-scope",
			data,
			actionTypes.post,
			true
		)

	addProductToScope = async (data: {
		productId: string
		scopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/add-product-to-scope",
			data,
			actionTypes.post,
			true
		)

	removeProductGroupsFromScope = async (data: {
		productGroupId: string
		scopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/remove-product-group-from-scope",
			data,
			actionTypes.delete,
			true
		)

	removeProductFromScope = async (data: {
		productId: string
		scopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/remove-product-from-scope",
			data,
			actionTypes.delete,
			true
		)

	changeProductGroupsScope = async (data: {
		productGroupId: string
		scopeId: number
		newScopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/change-product-group-from-scope",
			data,
			actionTypes.put,
			true
		)

	changeProductScope = async (data: {
		productId: string
		scopeId: number
		targetScopeId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/application-scope/change-product-from-scope",
			data,
			actionTypes.put,
			true
		)
	changeScopeApplication = async (data: {
		name: string
		scopeId: number
		languageId: number
	}): Promise<IApplicationResponse<void>> => {
		console.log(" ID =", data.scopeId, ", Name =", data.name)
		return this.sendQuery<void>(
			"/api/application-scope/change-scope-application-name",
			data,
			actionTypes.put,
			true
		)
	}

	addScopeApplication = async (data: {
		name: string
		languageId: number
	}): Promise<IApplicationResponse<void>> => {
		console.log("scopeId:", data.name, " languageId:", data.languageId)
		return this.sendQuery<void>(
			"/api/application-scope/add-scope-application",
			data,
			actionTypes.post,
			true
		)
	}
	removeScopeApplication = async (data: {
		scopeId: number
	}): Promise<IApplicationResponse<void>> => {
		console.log("scopeId:", data.scopeId)
		return this.sendQuery<void>(
			"/api/application-scope/remove-scope-application",
			data,
			actionTypes.delete,
			true
		)
	}
	getProductsGroups = async (data: {
		languageId: number
	}): Promise<IApplicationResponse<IProductGroupIdentity[]>> => {
		console.log("id языка", data.languageId)
		return this.sendQuery<IProductGroupIdentity[]>(
			"/api/product-group/get-all-group",
			data,
			actionTypes.get,
			true
		)
	}
	addAllGroupsToScope = async (data: {
		productGroupId: string[]
		scopeId: number
	}): Promise<IApplicationResponse<void>> => {
		console.log("scopeId:", data.scopeId)
		console.log("productGroupId:")
		data.productGroupId.forEach((id, index) => {
			console.log(`[${index}]:`, id)
		})
		return this.sendQuery<void>(
			"/api/application-scope/add-product-groups-to-scope",
			data,
			actionTypes.post,
			true
		)
	}
}
