import { IChecked, ILoading, ISelectable } from "../../../../redux/types"
import { IProductGroupIdentity } from "../../../../domain/types"
import { IProductIdentity } from "../../../../domain/models/products/ProductIdentity"
import { ICategory } from "../../../../domain/models/categories/ICategory"

//isLastActive нужна только для того, чтобы подсвечивать последний выбранный элемент на странице /tree
export type IGroupViewModel = IProductGroupIdentity &
	ILoading & { products: IProductIdentity[] | null } & {
		checked: boolean
	} & { isLastActive: boolean }

export type IGroupWithCatViewModel = IGroupViewModel & {
	catalogCategoryId: number | null
}

export type ICategoryViewModel = {
	id: number
	parentId: number
	name: string
	children: ICategoryViewModel[],
	imageUrl: {
		[key: number]: string
	},
	sort: number
} & ISelectable &
	IChecked

export function mapCategoryToModel(category: ICategory): ICategoryViewModel {
	return {
		id: category.id,
		checked: false,
		name: category.name,
		parentId: category.parentId,
		imageUrl: category.imageUrl!,
		children: category.children.map((x) => mapCategoryToModel(x)),
		selected: false,
		sort: category.sort,
	}
}
