import Api from "../../../../api"
import {
	createAutogenApiThunk,
	createAutogenNoArgsApiThunk,
} from "../../../createApiThunkV2"

export class NewProductThunks {
	static getSigns = createAutogenNoArgsApiThunk({
		typePrefix: "new-product/get-signs",
		apiCall: Api.sign.getSigns,
		errorMessage: "Не удалось получить список значков",
	})

	static getSeries = createAutogenNoArgsApiThunk({
		typePrefix: "new-product/get-series",
		apiCall: Api.series.getSeries,
		errorMessage: "Не удалось получить список серий",
	})

	static getAttributes = createAutogenApiThunk({
		typePrefix: "new-product/get-attributes",
		apiCall: Api.attributes.getAttributes,
		errorMessage: "Не удалось получить список аттрибутов",
	})

	static getSellmarks = createAutogenNoArgsApiThunk({
		typePrefix: "new-product/get-sellmarks",
		apiCall: Api.sellmarks.getSellmarks,
		errorMessage: "Не удалось получить список торговых марок",
	})

	static getOrReserve = createAutogenApiThunk({
		typePrefix: "new-product/get-or-reserve-product-group",
		apiCall: Api.productGroups.getOrReserve,
		errorMessage:
			"Не удалось получить или зарезервировать группу продуктов. Попробуйте перезагрузить страницу.",
		errorMessagesByStatusCode: {
			403: "У вас нет прав создавать группы продуктов.",
		},
	})

	static createProductGroup = createAutogenApiThunk({
		typePrefix: "new-product/create-product-group",
		apiCall: Api.productGroups.createProductGroup,
		errorMessage: "Не удалось создать группу продуктов.",
		errorMessagesByStatusCode: {
			409: "Группа продуктов с таким id уже существует.",
		},
	})

	static discardReserve = createAutogenApiThunk({
		typePrefix: "new-product/discard-reserve",
		apiCall: Api.productGroups.discardReserve,
		errorMessage: "Не удалось отменить резервирование группы продуктов",
	})

	static deleteProductGroup = createAutogenApiThunk({
		typePrefix: "new-product/delete-product-group",
		apiCall: Api.productGroups.deleteProductGroup,
		errorMessage: "Не удалось удалить группу продуктов",
	})

	static updateProductGroup = createAutogenApiThunk({
		typePrefix: "new-product/update-product-group",
		apiCall: Api.productGroups.updateProductGroup,
		errorMessage: "Не удалось обновить группу продуктов",
	})
}
