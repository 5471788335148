import { AppState } from "../../index"

export class TranslateSelectors {
	static getPhraseType = (state: AppState) =>
		state.pages.translatePage.phraseType
	static getSelectedLanguage = (state: AppState) =>
		state.pages.translatePage.selectedLanguage
	static getTranslateCategories = (state: AppState) =>
		state.pages.translatePage.translateCategories
	static getIsLoading = (state: AppState) =>
		state.pages.translatePage.isLoading
	static getNewLanguage = (state: AppState) =>
		state.pages.translatePage.newLanguage
	static getLanguageToRemove = (state: AppState) =>
		state.pages.translatePage.languageToRemove
	static getProductNameAttributeGenerationStrategy = (state: AppState) =>
		state.pages.translatePage.productNameAttributeGenerationStrategy
}
