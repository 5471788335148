import { IEcmFileDescription } from "../../domain/models/files/EcmFileDescription"
import { IFileType } from "../../domain/models/files/FileType"
import React, { useState } from "react"
import { SvgIcon } from "../icons/SvgIcon"
import { ProductFileTypeFilters } from "../../redux/reducers/pages/productPage/files/ProductFileTypeFilters"
import { FileNameLabelProvider } from "../../utils/FileNameLabelProvider"
import { SvgTag } from "../icons/SvgTag"
import SimpleSelect from "../../app/common/basic/selectors/SimpleSelect"
import ToOptionProvider from "../../utils/ToOptionProvider"
import "./AdGraphicFiles.scss"
import { createPortal } from "react-dom"
import { Menu, MenuItem } from "@szhsin/react-menu"
import { DateTimeLocaleProvider } from "../../utils/DateTimeLocaleProvider"

export default function AdGraphicFiles(props: {
	header: string
	files: IEcmFileDescription[]
	deletedFiles: IEcmFileDescription[]
	fileTypes: IFileType[]
	onTypeChanged: (file: IEcmFileDescription, type: IFileType) => void
	onSave: () => void
	deleteFile: (file: IEcmFileDescription) => void
	children?: React.ReactNode
	downloadFile: (file: IEcmFileDescription) => void
	downloadFiles: () => void
	onModalClose: () => void
	canEditGraphicFiles: boolean
}) {
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)
	const [isAnyChangesWereDone, setIsAnyChangesWereDone] = useState(false)

	const onTypeChanged = (file: IEcmFileDescription, type: number) => {
		const fileType = props.fileTypes.find((x) => x.id === type)
		if (!fileType) return
		props.onTypeChanged(file, fileType)
		setIsAnyChangesWereDone(true)
	}

	const onSave = () => {
		props.onSave()
		setIsAnyChangesWereDone(false)
		setIsEditModeEnabled(false)
	}

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false)

	const [fileToDelete, setFileToDelete] =
		useState<IEcmFileDescription | null>(null)

	const openDeleteMenu = (file: IEcmFileDescription) => {
		setFileToDelete(file)
		setIsDeleteConfirmationDialogOpen(true)
	}

	if (props.files.length === 0) return <></>

	return (
		<>
			<div
				className={
					"u-droppod-section-header__small header-with-toolbar"
				}
			>
				{props.header}
				<div className={"u-toolbar"}>
					<div className={"toolbar-row"}>
						{props.children}
						{!isEditModeEnabled ? (
							<>
								<button
									type="button"
									className="u-button square primary"
									onClick={() => setIsEditModeEnabled(true)}
									disabled={
										false || !props.canEditGraphicFiles
									}
								>
									<SvgIcon
										name={"pencil-create"}
										className={"u-icon"}
									/>
								</button>
								<button
									className={"u-button primary large"}
									onClick={props.downloadFiles}
								>
									Скачать все файлы
								</button>
							</>
						) : (
							<>
								<button
									type="button"
									className="u-button large primary"
									onClick={() => setIsEditModeEnabled(false)}
									disabled={false}
								>
									{/*<SvgIcon*/}
									{/*	name={"pencil-create"}*/}
									{/*	className={"link-icon"}*/}
									{/*/>*/}
									Выйти из режима редактирования
								</button>
								{isAnyChangesWereDone ? (
									<button
										className="u-button square primary"
										onClick={onSave}
									>
										<SvgIcon
											name={"floppy"}
											className={"u-icon"}
										/>
									</button>
								) : (
									<></>
								)}
								<Menu
									menuButton={
										<div>
											<SvgIcon
												name={"more-horizontal"}
												className={"u-icon"}
											/>
										</div>
									}
								>
									<MenuItem onClick={props.downloadFiles}>
										Скачать все файлы
									</MenuItem>
									{/*<MenuItem>Удалить все</MenuItem>*/}
								</Menu>
							</>
						)}
					</div>
				</div>
			</div>

			{/*<div className={"u-table__scroll-wrapper"}>*/}
			<table className="u-table" style={{ overflow: "visible" }}>
				<thead className={"u-thead"}>
					<tr>
						<th>Тип документа</th>
						<th>Формат</th>
						<th>Наименование</th>
						<th>Дата загрузки</th>
						<th>Отправитель</th>
						<th></th>
					</tr>
				</thead>
				<tbody className={"u-tbody"}>
					{props.files.map((file) => (
						<GraphicTableRow
							openDeleteMenu={(file) => {
								openDeleteMenu(file)
							}}
							downloadFile={props.downloadFile}
							file={file}
							fileTypes={props.fileTypes}
							isEditable={isEditModeEnabled}
							changeFileType={onTypeChanged}
							key={file.fileId}
						/>
					))}
				</tbody>
			</table>
			{isDeleteConfirmationDialogOpen ? (
				createPortal(
					<DeleteImageConfirmationDialog
						file={fileToDelete}
						onCancel={() =>
							setIsDeleteConfirmationDialogOpen(false)
						}
						onConfirm={() => {
							if (!fileToDelete) return
							props.deleteFile(fileToDelete)
							setIsDeleteConfirmationDialogOpen(false)
						}}
					/>,
					document.body
				)
			) : (
				<></>
			)}
			{/*</div>*/}
		</>
	)
}

function GraphicTableRow(props: {
	file: IEcmFileDescription
	fileTypes: IFileType[]
	isEditable: boolean
	changeFileType: (file: IEcmFileDescription, type: number) => void
	downloadFile: (file: IEcmFileDescription) => void
	openDeleteMenu: (file: IEcmFileDescription) => void
}) {
	const fileType = props.fileTypes.find((x) => x.id === props.file.fileTypeId)
	if (!fileType) return <></>

	const possibleTypes = ProductFileTypeFilters.getPossibleFileTypesByFile(
		props.file,
		props.fileTypes
	).filter((x) => x.subType == props.file.fileSubTypeId)

	const typeTagName = FileNameLabelProvider.getFileTypeTagName(
		props.file.fileTypeId
	)

	const extensionsTagName = FileNameLabelProvider.getFileLabelTagName(
		props.file.extension
	)

	const changeFileType = (fileTypeId: number) => {
		props.changeFileType(props.file, fileTypeId)
	}

	return (
		<>
			<tr key={props.file.fileId}>
				<td>
					{!props.isEditable ? (
						<SvgTag name={typeTagName} />
					) : (
						<>
							<SimpleSelect
								value={fileType}
								options={possibleTypes}
								onChange={changeFileType}
								toOption={ToOptionProvider.fileTypeToOption}
							/>
						</>
					)}
				</td>
				<td>
					<SvgTag name={extensionsTagName} />
				</td>
				<td>{props.file.name}</td>
				<td>
					{new Date(props.file.modifyDate).toLocaleDateString(
						DateTimeLocaleProvider.getDefaultLocale(),
						DateTimeLocaleProvider.getDefaultLocaleOptions()
					)}
				</td>
				<td>-</td>
				<td>
					<div style={{ display: "flex", gap: "0.5em" }}>
						<SvgIcon
							name={"download"}
							className={"u-icon file-downloader"}
							onClick={() => props.downloadFile(props.file)}
						/>
						{props.isEditable ? (
							<SvgIcon
								name={"close"}
								className={"u-icon file-downloader"}
								onClick={() => props.openDeleteMenu(props.file)}
							/>
						) : (
							<></>
						)}
					</div>
				</td>
			</tr>
		</>
	)
}

function DeleteImageConfirmationDialog(props: {
	file: IEcmFileDescription | null
	onCancel: () => void
	onConfirm: () => void
}) {
	if (!props.file) return <></>
	return (
		<div className={"delete-image-confirmation custom"}>
			<div className={"header-wrapper"}>
				<div className={"header"}>
					Вы уверены, что хотите удалить файл?
				</div>
				Данное действие нельзя отменить.
			</div>
			<div className={"buttons-wrapper"}>
				<button
					onClick={props.onCancel}
					className={"u-button small outline"}
				>
					Назад
				</button>
				<button
					onClick={props.onConfirm}
					className={"u-button small accent"}
				>
					Удалить
				</button>
			</div>
		</div>
	)
}
