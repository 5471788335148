import {AppState} from "../index"
import {IWebsite, WebsiteSchema} from "../../../domain/types"

export class WebsiteSelectors {
    static getWebsites = (state: AppState, schema?: WebsiteSchema): IWebsite[] =>
        schema == null
            ? state.websitesState.websites
            : state.websitesState.websites.filter(x => x.schema == schema)
    static getSelected = (state: AppState) => state.websitesState.selected
    static getWasInit = (state: AppState) => state.websitesState.wasInit 
}