import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import _ from "lodash"
import { ImageTypeThunks } from "./thunk"
import Constants from "../../../domain/Constants"
import { IImageType } from "../../../domain/models/imageTypes/ImageType"

export type ImageTypeState = {
	imageTypes: IImageType[]
	selected: IImageType
	filter: number[]
}

const defaultImageType: IImageType = { id: -1, name: "Загрузка" }

const INITIAL_STATE: ImageTypeState = {
	imageTypes: [defaultImageType],
	selected: defaultImageType,
	filter: [],
}

const slice = createSlice({
	name: "imageTypes",
	initialState: INITIAL_STATE,
	reducers: {
		setFilter(state: ImageTypeState, action: PayloadAction<number[]>) {
			state.filter = action.payload
			if (state.filter.includes(state.selected.id))
				state.selected = state.imageTypes.first(
					(x) =>
						!state.filter.includes(x.id) &&
						x.id != Constants.YoutubeImageType
				)
		},
		setSelected(state: ImageTypeState, action: PayloadAction<number>) {
			state.selected =
				state.imageTypes.find((x) => x.id === action.payload) ??
				state.selected
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ImageTypeThunks.getImageTypes.fulfilled,
			(state, { payload }) => {
				if (payload.length === 0) {
					state.imageTypes.first().name = "Нет типов изображений"
					state.selected = state.imageTypes.first()
				} else {
					state.imageTypes = _.orderBy(payload, (x) => x.name)
					if (state.selected.id === -1)
						state.selected = state.imageTypes.first(
							(x) => x.id != Constants.YoutubeImageType
						)
					if (payload.some((x) => x.id === state.selected.id))
						state.selected =
							payload.find((x) => x.id === state.selected.id) ??
							state.selected
					else
						state.selected = state.imageTypes.first(
							(x) => x.id != Constants.YoutubeImageType
						)
				}
			}
		)
		builder.addCase(ImageTypeThunks.getImageTypes.rejected, (state) => {
			state.imageTypes = [{ ...defaultImageType, name: "Ошибка" }]
		})
	},
})

const reducer = slice.reducer
const actions = slice.actions

export { reducer, actions }
