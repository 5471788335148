import React from "react"
import {Role} from "../../../domain/Role"
import NotAuthorize from "../../../app/notAuthorize/NotAuthorize"
import useAuth from "../../../hooks/authorizationHook"

export default function Protector(props: IProtectorProps) {
    const hasRole = useAuth(props.availableRoles)

    return <>
        {
            hasRole
                ? props.children
                : props.justHide
                    ? null
                    : <NotAuthorize/>
        }
    </>
}

interface IProtectorProps {
    children: JSX.Element | JSX.Element[],
    availableRoles: Role[]
    justHide?: boolean
}
