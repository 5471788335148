import React from "react"
import "./productGroupListTable.scss"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/reducers/pages/categoryPage"
import {
	getProductGroupCatsThunk,
	getProductsByGroupThunk,
} from "../../../redux/reducers/pages/categoryPage/thunk"
import ExpandedProductGroupTable from "../../../library/smartComponents/tables/productGroupTable/ExpandedProductGroupTable"
import { IGroupViewModel } from "../../../library/smartComponents/tables/productGroupTable/types"
import { DistributionType } from "../../../domain/types"
import _ from "lodash"
import { LanguageSelectors } from "../../../redux/reducers/languages/languageSelectors"
import { CatalogSelectors } from "../../../redux/reducers/catalogs/catalogSelectors"
import { DistributionTypeSelectors } from "../../../redux/reducers/distributionsTypes/DistributionTypeSelectors"
import { CatalogGroupSelectors } from "../../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { CategoriesPageSelector } from "../../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import AreaEditorModal from "../../group/groupTabs/tabs/categoryTab/AreaEditorModal/AreaEditorModal"

export default function ProductGroupListTable() {
	const isGroupsLoading = useSelector(
		CategoriesPageSelector.getIsGroupsLoading
	)
	const productGroups = useSelector(CategoriesPageSelector.getGroups)
	const catalog = useSelector(CatalogSelectors.getSelected)
	const language = useSelector(LanguageSelectors.getSelected)
	const distrType = useSelector(DistributionTypeSelectors.getSelected)
	const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)

	const loadedCategories = useSelector(
		CategoriesPageSelector.getGroupCategories
	)

	const dispatch = useDispatch()

	function onSelect(group: IGroupViewModel) {
		dispatch(actions.setSelectedProductGroup(group))

		if (distrType.value === DistributionType.Distributed) {
			if (!loadedCategories.some((x) => x.productGroupId === group.id)) {
				dispatch(
					getProductGroupCatsThunk({
						productGroupId: group.id,
						languageId: language.id,
						catalogGroup: catalogGroup.id,
						catalogId: catalog.id,
					})
				)
			} else {
				//при отключении чекбокса должно происходить отключение подсветки
				dispatch(actions.removeProductGroupWithCatPath(group.id))
			}
		}
	}

	function loadProducts(productGroup: IGroupViewModel) {
		dispatch(
			getProductsByGroupThunk({
				productGroupId: productGroup.id,
				languageId: language.id,
			})
		)
	}

	return (
		<div className="u-table__scroll-wrapper">
			<ExpandedProductGroupTable
				showSortColumn={false}
				isProductGroupsLoading={isGroupsLoading}
				productGroups={_.orderBy(productGroups, (x) =>
					x.name.toLowerCase()
				)}
				loadProducts={loadProducts}
				onRowClick={(x) => onSelect(x)}
				onCheckBoxClick={(x) => onSelect(x)}
			/>
		</div>
	)
}
