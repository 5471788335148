import { IEcmFileDescription } from "../../../../../../domain/models/files/EcmFileDescription"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FileSubtype } from "../../../../../../domain/models/files/FileSubtype"
import { ProductPageThunks } from "../../thunks"
import { IFileType } from "../../../../../../domain/models/files/FileType"
import { TimeFilterEnum } from "../../../../../../domain/models/TimeFilterEnum"
import { IOptionType } from "../../../../../../app/common/basic/selectors/SimpleSelect"

export type ProductUnresolvedFilesState = {
	unresolvedFiles: IEcmFileDescription[]
	deletedFiles: IEcmFileDescription[]

	formatFilter: IOptionType
	timeFilter: IOptionType
}

const INITIAL_STATE: ProductUnresolvedFilesState = {
	unresolvedFiles: [],
	deletedFiles: [],
	formatFilter: { value: 0, label: "Все форматы" },
	timeFilter: { value: TimeFilterEnum.ToOld, label: "От нового к старому" },
}

const slice = createSlice({
	name: "product/UnresolvedFiles",
	initialState: INITIAL_STATE,
	reducers: {
		setSubtype: (
			state,
			{
				payload: { fileDescr, fileTypes, subType },
			}: PayloadAction<{
				fileDescr: IEcmFileDescription
				subType: FileSubtype
				fileTypes: IFileType[]
			}>
		) => {
			const subTypeFileType = fileTypes.find((x) => x.subType === subType)
			if (!subTypeFileType) return

			const file = state.unresolvedFiles.find(
				(x) =>
					x.name === fileDescr.name &&
					x.extension === fileDescr.extension
			)
			if (!file) return

			file.fileSubTypeId = subTypeFileType.subType
			file.fileTypeId = subTypeFileType.id
		},

		setFileType: (
			state,
			{
				payload: { fileDescr, fileType },
			}: PayloadAction<{
				fileDescr: IEcmFileDescription
				fileType: IFileType
			}>
		) => {
			const file = state.unresolvedFiles.find(
				(x) =>
					x.name === fileDescr.name &&
					x.extension === fileDescr.extension
			)
			if (!file) return

			file.fileTypeId = fileType.id
			file.fileSubTypeId = fileType.subType
		},
		setFormatFilter: (state, { payload }: PayloadAction<IOptionType>) => {
			state.formatFilter = payload
		},

		setTimeFilter: (state, { payload }: PayloadAction<IOptionType>) => {
			state.timeFilter = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getFiles.fulfilled,
			(state, action) => {
				state.unresolvedFiles = action.payload.filter(
					(file) => file.fileSubTypeId === FileSubtype.unknown
				)
			}
		)
		builder.addCase(
			ProductPageThunks.uploadFile.fulfilled,
			(state, { payload }) => {
				if (payload.fileSubTypeId === FileSubtype.unknown)
					state.unresolvedFiles.push(payload)
			}
		)
		builder.addCase(
			ProductPageThunks.changeFileType.fulfilled,
			(state, { meta }) => {
				for (const removedFile of meta.arg.files) {
					const file = state.unresolvedFiles.find(
						(x) => x.fileId === removedFile.fileId
					)
					if (!file) continue

					state.unresolvedFiles = state.unresolvedFiles.filter(
						(x) => x.fileId !== removedFile.fileId
					)
				}
			}
		)
		builder.addCase(
			ProductPageThunks.deleteFile.fulfilled,
			(state, { meta }) => {
				const file = state.unresolvedFiles.find(
					(x) => x.fileId === meta.arg.fileId
				)
				if (!file) return

				state.unresolvedFiles = state.unresolvedFiles.filter(
					(x) => x.fileId !== file.fileId
				)

				state.deletedFiles.push(file)
			}
		)
	},
})

const { actions, reducer } = slice

export { actions, reducer }
