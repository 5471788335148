import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	actions,
	BullfactsSelectors,
	Translate,
} from "../../redux/reducers/pages/bullfactsPage"
import { BullfactsThunks } from "../../redux/reducers/pages/bullfactsPage/thunks"
import { IOptionType } from "../common/basic/selectors/SimpleSelect"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { AdditionalTranslateKeys } from "../../domain/Constants"
import AdDefaultInput from "../../library/inputs/AdDefaultInput"
import { AdLoadingRow } from "../../library/tables/AdLoadingRow"
import AdDynamicTextarea from "../../library/inputs/AdDynamicTextarea"
import { CatalogGroup } from "../../domain/types"

import { SvgIcon } from "../../library/icons/SvgIcon"

// Идентификатор исходного языка по умолчанию (русский).
const DEFAULT_SOURCE_LANGUAGE_ID = 11

export default function BullfactsTable() {
	const additionalColumnCache: Record<
		string,
		[
			columnName: string,
			handler: (translate: Translate, value: string) => void,
		]
	> = useMemo(() => {return {}}, [])

	const isLoading = useSelector(BullfactsSelectors.getIsLoading)
	const catalog = useSelector(BullfactsSelectors.getSelectedCatalog)
	const catalogs = useSelector(
		CatalogSelectors.getByCatalogGroup(CatalogGroup.Printed)
	)
	const wasCatalogsInit = useSelector(CatalogSelectors.getWasInit)
	const search = useSelector(BullfactsSelectors.getSearch)
	const isSemanticSearchDisabled = useSelector(
		BullfactsSelectors.getIsSemanticSearchDisabled
	)
	const language = useSelector(BullfactsSelectors.getSelectedLanguage)
	const translateSource = useSelector(
		BullfactsSelectors.getSelectedTranslateSource
	)
	const possibleTranslateSources = useSelector(
		BullfactsSelectors.getPossibleTranslateSources
	)
	const translateType = useSelector(
		BullfactsSelectors.getSelectedTranslateType
	)
	const semanticSearch = useSelector(BullfactsSelectors.getSemanticSearch)
	const translates = useSelector(BullfactsSelectors.getTranslates)
	const shouldShowAdditionalInfo = useSelector(
		BullfactsSelectors.getShouldShowAdditionalInfo
	)
	const additionalInfoServerKeys = useSelector(
		BullfactsSelectors.getAdditionalInfoServerKeys
	)

	const dispatch = useDispatch()

	useEffect(() => {
		loadTranslates()
	}, [
		language.id,
		translateType.value,
		translateSource.value,
		search,
		semanticSearch,
		catalog.id,
	])

	useEffect(() => {
		catalogs.length > 0 && dispatch(actions.setSelectedCatalog(catalogs[0]))
	}, [wasCatalogsInit])

	const loadTranslates = () => {
		if (!wasCatalogsInit || catalog.id < 0) return
		dispatch(
			BullfactsThunks.getTranslates({
				search: search,
				translateSource: translateSource.value,
				catalogId: catalog.id == 0 ? null : catalog.id,
				isEmpty: translateType.value === 1,
				targetLanguageId: language.id,
				isSemantic: isSemanticSearchDisabled,
				semanticSearch: semanticSearch,
			})
		)
	}

	const updateValue = useCallback((translate: Translate, newTranslateValue: string) => {
		dispatch(
			BullfactsThunks.updateTranslate({
				translateSource: translate.source,
				sourceId: translate.sourceId,
				translate: newTranslateValue,
				languageId: language.id,
				translateId: translate.translateId,
			})
		)
	},[language])

	const updateAttrShortName = useCallback((
		translate: Translate,
		newShortName: string
	) => {
		dispatch(
			BullfactsThunks.updateAttributeShortname({
				attributeId: Number(translate.sourceId),
				shortname: newShortName,
				languageId: language.id,
			})
		)
	}, [language])

	const updateAttrUnit = useCallback((translate: Translate, newUnitName: string) => {
		dispatch(
			BullfactsThunks.updateAttributeUnit({
				attributeId: Number(translate.sourceId),
				unit: newUnitName,
				languageId: language.id,
			})
		)
	}, [language])

	const additionalColumnProvider = useCallback((
		serverKey: string
	): [
		columnName: string,
		handler: (translate: Translate, value: string) => void,
	]  => {
		if (additionalColumnCache[serverKey])
			return additionalColumnCache[serverKey]

		switch (serverKey.toLowerCase()) {
			case AdditionalTranslateKeys.AttributeShortname:
				additionalColumnCache[serverKey] = [
					"Короткое название",
					updateAttrShortName,
				]
				break
			case AdditionalTranslateKeys.AttributeUnit:
				additionalColumnCache[serverKey] = [
					"Единица измерения",
					updateAttrUnit,
				]
				break
		}
		return (
			additionalColumnCache[serverKey] ?? [
				"",
				() => {
					console.log(
						`Column provider cant create entry for ${serverKey}`
					)
				},
			]
		)
	}, [additionalColumnCache, updateAttrShortName, updateAttrUnit])



	return (
		<div className="u-table__scroll-wrapper">
			<table className="u-table">
				<thead className="u-thead">
					<tr>
						<th>Код</th>
						<th>Русский</th>
						<th>Перевод</th>
						<th>Перевести</th>
						<th>Источник</th>
						<th style={{ minWidth: 105 }}>Код объекта</th>
						{
							/*
                                  Для аттрибутов мы должны выводить колонку с коротким названием аттрибута
                             */
							shouldShowAdditionalInfo
								? additionalInfoServerKeys.map((x) => {
										const [columnName, _] =
											additionalColumnProvider(x)
										return <th key={x}>{columnName}</th>
								  })
								: null
						}
					</tr>
				</thead>
				<tbody className="u-tbody">
					{isLoading ? (
						<AdLoadingRow colSpan={7} />
					) : (
						translates.map((x, i) => {
							return (
								<TableRow
									key={i}
									translateSources={possibleTranslateSources}
									counter={i.toString()}
									translate={x}
									onEnterPressed={updateValue}
									shouldShowAdditionalInfo={
										shouldShowAdditionalInfo
									}
									additionalInfoServerKeys={
										additionalInfoServerKeys
									}
									additionalColumnProvider={additionalColumnProvider}
								/>
							)
						})
					)}
					<tr></tr>
				</tbody>
			</table>
		</div>
	)
}

interface ITableRowProps {
	translateSources: IOptionType[]
	counter: string
	translate: Translate
	onEnterPressed: (translate: Translate, newStrValue: string) => void
	shouldShowAdditionalInfo: boolean
	additionalInfoServerKeys: string[]
	additionalColumnProvider: (
		serverKey: string
	) => [
		columnName: string,
		handler: (translate: Translate, value: string) => void,
	]
}

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log('Changed props:', changedProps);
		}
		prev.current = props;
	});
}

/**
 * Этот компонент нужен, чтобы не проводить изменения через стор и чтобы быстро рендерились изменения в инпуте
 **/
function TableRow(props: ITableRowProps) {

	useTraceUpdate(props)

	const [translateValue, setTranslateValue] = useState(
		props.translate.translate ?? ""
	)
	const [additionalInfo, setAdditionalInfo] = useState(
		props.translate.additionalInfo ?? {}
	)

	const [isTranslateButtonClicked, setIsTranslateButtonClicked] =
		useState(false)

	const language = useSelector(BullfactsSelectors.getSelectedLanguage)
	const dispatch = useDispatch()

	useEffect(() => {
		setTranslateValue(props.translate.translate ?? "")
	}, [props.translate])

	useEffect(() => {
		if (isTranslateButtonClicked) {
			setIsTranslateButtonClicked(false)
			setTimeout(() => {
				const elementById = document.getElementById(
					props.counter + "_input"
				) as HTMLDivElement
				if (!elementById) return
				elementById.focus()
			}, 0)
			setTranslateValue(props.translate.translate ?? "")
		}
	}, [props.translate.wasChangedByDeepl])

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			event.nativeEvent.preventDefault()
			let res = translateValue
			if (res.startsWith("•")) {
				if (res.length > 2) {
					if (res.charAt(1) !== " ")
						res =
							res.substring(0, 1) +
							" " +
							res.substring(1, translateValue.length)
				}
			}
			res = trim(trim(res, ".").trim(), ".")

			props.onEnterPressed(props.translate, res)
			setTranslateValue(res)
		}
		if (event.key === "Escape") {
			event.currentTarget.textContent = props.translate.translate
			setTranslateValue(props.translate.translate)
			event.target.blur()
		}
	}

	const onAdditionalInfoKeyUp = (event, serverKey: string) => {
		let res = additionalInfo[serverKey]
		if (event.key === "Enter") {
			res = trim(trim(res, ".").trim(), ".")
			const [_, handler] = props.additionalColumnProvider(serverKey)
			handler(props.translate, res)
		}
		if (event.key === "Escape") {
			setAdditionalInfo({
				...additionalInfo,
				[serverKey]: props.translate.additionalInfo
					? props.translate.additionalInfo[serverKey]
					: "",
			})
		}
	}

	function trim(str: string, ch: string): string {
		let start = 0,
			end = str.length

		while (start < end && str[start] === ch) ++start

		while (end > start && str[end - 1] === ch) --end

		return start > 0 || end < str.length ? str.substring(start, end) : str
	}

	return (
		<tr key={props.counter}>
			<td>{props.translate.translateId}</td>
			<td>{props.translate.russian}</td>
			<td className="wide-input">
				<AdDynamicTextarea
					id={props.counter + "_input"}
					rows={7}
					onChange={setTranslateValue}
					onKeyDown={onKeyUp}
					value={translateValue}
				/>
			</td>
			<td>
				<button
					className="u-button square accent"
					onClick={() => {
						dispatch(
							BullfactsThunks.translateValuesByTranslator({
								sourceLanguageId: DEFAULT_SOURCE_LANGUAGE_ID,
								targetLanguageId: language.id,
								values: [props.translate.russian],
							})
						)
						setIsTranslateButtonClicked(true)
					}}
					disabled={false}
					title="Перевести"
				>
					<SvgIcon name={"translate"} />
				</button>
			</td>
			<td>
				{
					props.translateSources.find(
						(x) => x.value === props.translate!.source
					)?.label
				}
			</td>
			<td>{props.translate.sourceId}</td>
			{props.shouldShowAdditionalInfo
				? props.additionalInfoServerKeys.map((serverKey) => {
						const value = additionalInfo
							? additionalInfo[serverKey] ?? ""
							: ""
						return (
							<td key={serverKey}>
								<AdDefaultInput
									value={value}
									onChange={(e) => {
										setAdditionalInfo({
											...additionalInfo,
											[serverKey]: e,
										})
									}}
									onKeyUp={(e) =>
										onAdditionalInfoKeyUp(e, serverKey)
									}
								/>
							</td>
						)
				  })
				: null}
		</tr>
	)
}
