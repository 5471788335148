import React from "react"
import BullfactsToolbar from "./BullfactsToolbar"
import BullfactsTable from "./BullfactsTable"
import "./Bullfacts.scss"
import Protector from "../../library/smartComponents/authorization/Protector"
import RouteProvider from "../../utils/RouteProvider"

export default function Bullfacts() {
    return <Protector availableRoles={RouteProvider.bullfacts.roles!}>
        <div className="bullfacts u-droppod">

            <div className="u-droppod-header">
                <div className="header-text">
                    Справочник переводов
                </div>
            </div>
            <BullfactsToolbar/>
            <BullfactsTable/>
        </div>
    </Protector>
}