import React, { useEffect, useLayoutEffect, useRef } from "react"
import "./RecommendationBlock.scss"
import ToOptionProvider from "../../../../../utils/ToOptionProvider"
import _ from "lodash"
import { AdditionalTabThunks } from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent/thunks"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent"
import { Link } from "react-router-dom"
import RouteProvider from "../../../../../utils/RouteProvider"
import { LanguageSelectors } from "../../../../../redux/reducers/languages/languageSelectors"
import { GroupPageSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageSelectors"
import { AdditionalTabSelectors } from "../../../../../redux/reducers/pages/groupPage/additionalInfoComponent/additionalTabSelectors"
import { SvgIcon } from "../../../../../library/icons/SvgIcon"
import NullableSelect from "../../../../common/basic/selectors/NullableSelect"
import { actions as informActions } from "../../../../../redux/reducers/informer"
import { actions as productGroupActions } from "../../../../../redux/reducers/pages/groupPage"
import KeysCodes, { keyDownMessage } from "../../../../../utils/KeysCodes"
import { MessageBusSelectors } from "../../../../../redux/reducers/messageBus/messageBusSelectors"
import { actions as messageBusActions } from "../../../../../redux/reducers/messageBus"
import { GroupPageAuthSelectors } from "../../../../../redux/reducers/pages/groupPage/groupPageAuthSelectors"

export default function RecommendationBlock(props: {
	setHeaderHeight: (n: number) => void
}) {
	const language = useSelector(LanguageSelectors.getSelected)
	const group = useSelector(GroupPageSelectors.getGroup)
	const recommendations = useSelector(
		AdditionalTabSelectors.getAllRecommendations
	)
	const selectedRecommendation = useSelector(
		AdditionalTabSelectors.getSelectedRecommendation
	)
	const groupRecommendations = useSelector(
		AdditionalTabSelectors.getGroupRecommendations
	)
	const selectedGroupRecommendation = useSelector(
		AdditionalTabSelectors.getSelectedGroupRecommendation
	)
	const isTableRefreshRequested = useSelector(
		GroupPageSelectors.getIsTableRefreshRequested
	)

	const canControlRecs = useSelector(
		GroupPageAuthSelectors.getCanControlRecommendations
	)

	const dispatch = useDispatch()

	useEffect(() => {
		updateRecommendations()
	}, [language.id, group.priceGroupId])

	useEffect(() => {
		if (isTableRefreshRequested) {
			updateRecommendations()
			dispatch(productGroupActions.tableRefreshed())
		}
	}, [isTableRefreshRequested])

	const setSelectedRec = (id: string) => {
		if (!canControlRecs) return
		dispatch(actions.setSelectedRec(id))
	}
	const setSelectedGroupRec = (id: string) => {
		if (!canControlRecs) return
		dispatch(actions.setSelectedGroupRec(id))
	}

	const ref = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		props.setHeaderHeight(ref.current?.clientHeight || 0)
	}, [])

	const updateRecommendations = () => {
		dispatch(
			AdditionalTabThunks.getAllRecommendation({
				productGroupId: group.id,
				priceGroupId: group.priceGroupId!,
				search: "",
				languageId: language.id,
			})
		)
		dispatch(
			AdditionalTabThunks.getGroupRecommendation({
				languageId: language.id,
				productGroupId: group.id,
			})
		)
	}

	const changeRecommendation = () => {
		if (!canControlRecs) return
		if (!selectedRecommendation) {
			dispatch(informActions.inform("Выберите продукт"))
			return
		}
		if (!selectedGroupRecommendation) {
			dispatch(informActions.inform("Выберите рекомендацию для замены"))
			return
		}
		dispatch(
			AdditionalTabThunks.changeRecommendation({
				groupId: group.id,
				targetProductId: selectedRecommendation.id,
				currentProductId: selectedGroupRecommendation.productId,
			})
		)
	}

	const addRec = () => {
		if (!canControlRecs) return
		if (!selectedRecommendation) {
			dispatch(informActions.inform("Выберите продукт"))
			return
		}

		if (
			groupRecommendations.findIndex(
				(x) => x.productId === selectedRecommendation!.id
			) !== -1
		) {
			dispatch(
				informActions.inform("Продукт уже добавлен в рекомендации")
			)
			return
		}

		dispatch(
			AdditionalTabThunks.addRecommendations({
				productGroupId: group.id,
				productsIds: [selectedRecommendation!.id!],
			})
		)
	}

	const removeRec = () => {
		if (!canControlRecs) return
		if (!selectedGroupRecommendation) {
			dispatch(informActions.inform("Выберите рекомендацию для удаления"))
			return
		}

		const recToRemove = groupRecommendations.find(
			(x) => x.productId === selectedGroupRecommendation!.productId
		)
		if (recToRemove === undefined) {
			dispatch(
				informActions.inform("Ошибка состояния, перезагрузите страницу")
			)
			return
		}

		dispatch(
			AdditionalTabThunks.removeRecommendation({
				productId: selectedGroupRecommendation.productId,
				productGroupId: group.id,
			})
		)
	}

	const swapSort = async (addition: number) => {
		if (!canControlRecs) return
		const current = selectedGroupRecommendation
		if (!current) {
			dispatch(informActions.inform("Выберите рекомендацию"))
			return
		}
		const sort = current.sort!
		const targetSort = sort + addition
		const target = groupRecommendations.find((x) => x.sort === targetSort)
		if (target === undefined) {
			dispatch(
				informActions.inform(
					"Новое значение сортировки выходит за границы доступного диапазона"
				)
			)
			return
		}

		await dispatch(
			AdditionalTabThunks.swapRecommendationSort({
				productGroupId: group.id,
				firstProductId: current.productId,
				secondProductId: target.productId,
			})
		)

		const table = document.getElementById("recs-table")

		if (table) {
			const current_line: Element = table.getElementsByClassName(
				"table-row--selected"
			)[0]
			const next_line: Element = current_line.nextSibling as Element
			const previous_line: Element =
				(current_line.previousSibling as Element) ||
				table.getElementsByTagName("thead")[0]

			current_line.scrollIntoView({
				block: "nearest",
				behavior: "smooth",
			})
			if (addition < 0) {
				if (!previous_line) return
				previous_line.scrollIntoView({
					block: "nearest",
					behavior: "smooth",
				})
			} else {
				if (!next_line) return
				next_line.scrollIntoView({
					block: "nearest",
					behavior: "smooth",
				})
			}
		}
	}

	const message = useSelector(MessageBusSelectors.getMessage)

	useEffect(() => {
		if (message.table_id === "recs-table") {
			dispatch(messageBusActions.clearMessage())
			switch (message.key) {
				case KeysCodes.ARROWDOWN:
					swapSort(1)
					break
				case KeysCodes.ARROWUP:
					swapSort(-1)
					break
				case KeysCodes.DELETE:
					removeRec()
					break
				default:
					break
			}
			console.log(message)
		}
	}, [message.rand])

	return (
		<>
			<div
				ref={ref}
				className="u-droppod-section-header header-with-toolbar"
			>
				Сопутствующие товары
				<div className="u-toolbar">
					<div className="toolbar-row">
						{canControlRecs ? (
							<>
								<NullableSelect
									value={selectedRecommendation}
									options={recommendations}
									onChange={(id) => {
										setSelectedRec(id as string)
									}}
									toOption={
										ToOptionProvider.productIdentityToOption
									}
									className={"selector"}
									placeholder={"Наименование товара"}
									height={31}
									isDisabled={!canControlRecs}
									noOptionsMessage={"Нет подходящих товаров"}
								/>
								<button
									type="button"
									className="u-button square primary"
									disabled={!canControlRecs}
									onClick={() => addRec()}
								>
									<SvgIcon name="circle-plus" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									disabled={!canControlRecs}
									onClick={changeRecommendation}
								>
									<SvgIcon name="pencil-create" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									disabled={!canControlRecs}
									onClick={() => removeRec()}
								>
									<SvgIcon name="circle-x" />
								</button>

								<button
									type="button"
									className="u-button square primary"
									disabled={!canControlRecs}
									onClick={() => swapSort(-1)}
								>
									<SvgIcon name="arrow-top" />
								</button>
								<button
									type="button"
									className="u-button square primary"
									disabled={!canControlRecs}
									onClick={() => swapSort(1)}
								>
									<SvgIcon name="arrow-bottom" />
								</button>

								<Link
									className="u-button primary large"
									to={RouteProvider.recommendations.generateParameterPath(
										{ productGroupId: group.id }
									)}
									aria-disabled={!canControlRecs}
									target="_blank"
								>
									Добавить
								</Link>
							</>
						) : null}
					</div>
				</div>
			</div>
			{/*@ts-ignore*/}
			<div className="u-table__scroll-wrapper almost-full-table">
				<table
					className="u-table"
					id="recs-table"
					tabIndex={0}
					onKeyDown={(e) => {
						keyDownMessage(e, "recs-table", dispatch)
					}}
				>
					<thead className="u-thead">
						<tr>
							<th>Код</th>
							<th>Наименование</th>
							<th>№</th>
						</tr>
					</thead>
					<tbody className="u-tbody">
						{groupRecommendations.length === 0 ? (
							<tr>
								<td colSpan={3}>Пока ничего нет</td>
							</tr>
						) : (
							_.orderBy(groupRecommendations, (x) => x.sort)!.map(
								(x) => {
									return (
										<tr
											key={x.productId}
											id={x.productId}
											onClick={() =>
												setSelectedGroupRec(x.productId)
											}
											className={
												x.selected
													? "table-row--selected"
													: ""
											}
										>
											<td>{x.productId}</td>
											<td>{x.name}</td>
											<td>{x.sort}</td>
										</tr>
									)
								}
							)
						)}
						<tr></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
