import React, {useEffect} from "react"
import "./Recommendations.scss"
import {useSearchParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {actions, Recommendation} from "../../redux/reducers/pages/recommendationsPage"
import {RecommendationThunks} from "../../redux/reducers/pages/recommendationsPage/thunks"
import {useDebouncedCallback} from "use-debounce"
import DebounceProvider from "../../utils/DebounceProvider"
import {LanguageSelectors} from "../../redux/reducers/languages/languageSelectors"
import {RecommendationSelectors} from "../../redux/reducers/pages/recommendationsPage/recommendationSelectors"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import {actions as informerActions} from "../../redux/reducers/informer"
import useAuth from "../../hooks/authorizationHook"
import {Role} from "../../domain/Role"
import {getGroupMembership, GroupMembership} from "../../domain/GroupMembership"

export default function Recommendations() {
    const [searchParams] = useSearchParams()
    const paramGroupId = searchParams.get("productGroupId")
    const dispatch = useDispatch()
    const language = useSelector(LanguageSelectors.getSelected)
    const group = useSelector(RecommendationSelectors.getProductGroup)
    const recommendations = useSelector(RecommendationSelectors.getRecommendations)
    const searchString = useSelector(RecommendationSelectors.getSearchString)

    const isContentRf = useAuth([Role.ContentManagerRF])
    const isContentLv = useAuth([Role.ContentManagerLv])
	const isContentRg = useAuth([Role.ContentManagerRg])

    let canAccess = false

    const groupMembership = getGroupMembership(group?.priceGroupId ?? null)
    switch (groupMembership) {
    case GroupMembership.Nothing:
    case GroupMembership.Russian:
        canAccess = isContentRf
        break
    case GroupMembership.Latvian:
        canAccess = isContentLv || isContentRg
        break
    }

    useEffect(() => {
        if (paramGroupId !== null && language.id !== 0) {
            dispatch(RecommendationThunks.getOrReserve({
                languageId: language.id,
                productGroupId: paramGroupId,
                productsIds: []
            }))
        }
    }, [language.id])

    useEffect(() => {
        if (group != null) {
            updateRecommendations()
        }
    }, [group, searchString, language.id])

    const updateRecommendations = () => {
        if (group != null)
            dispatch(RecommendationThunks.getAllRecommendation({
                productGroupId: group.id,
                languageId: language.id,
                priceGroupId: group.priceGroupId!,
                search: searchString
            }))
    }

    const addRecommendations = () => {
        const productsToAdd = recommendations.filter(x => x.selected)
        if (productsToAdd.length === 0) {
            dispatch(informerActions.inform("Выберите продукты для добавления"))
            return
        }
        dispatch(RecommendationThunks.addRecommendations({
            productGroupId: paramGroupId!,
            productsIds: productsToAdd.map(x => x.id)
        }))
    }

    const setSelectedProduct = (recommendation: Recommendation) => {
        dispatch(actions.setSelectedRecommendation(recommendation.id))
    }

    const setSearchString = (search: string) => dispatch(actions.setSearch(search))
    const debouncedSetSearch = useDebouncedCallback(args => setSearchString(args), DebounceProvider.getDebounce())

    return <>
        {
            paramGroupId === null
                ? <>Переданы неверные аргументы</>
                : <>
                    {
                        canAccess
                            ? <div className="recommendations recommendations-wrapper u-droppod">
                                <div className="u-droppod-header">
                                    <div className="header-text">
                                        Сопутствующие товары
                                    </div>
                                    <AdSearchInput onChange={debouncedSetSearch} placeholder={"Поиск"}/>
                                    <button className="u-button large primary" onClick={() => addRecommendations()}>Добавить
                                    </button>
                                </div>

                                <div className="recommendations__table u-table__scroll-wrapper">
                                    <table className="u-table">
                                        <thead className="u-thead">
                                            <tr>
                                                <th>Код</th>
                                                <th>Наименование</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="u-tbody">
                                            {
                                                recommendations.map(x => {
                                                    return <tr key={x.id}
                                                        onClick={() => setSelectedProduct(x)}
                                                        className={x.selected ? "table-row--selected" : ""}>
                                                        <td>{x.id}</td>
                                                        <td>{x.name}</td>
                                                        <td className="checkbox-cell"><input type={"checkbox"}
                                                            checked={x.selected}
                                                            readOnly={true}/></td>
                                                    </tr>
                                                })
                                            }
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null
                    }
                </>
        }
    </>
}