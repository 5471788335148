import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/reducers/categories"
import { CategoriesComponentThunks } from "../../redux/reducers/categories/thunk"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { CatalogGroupSelectors } from "../../redux/reducers/catalogGroups/catalogGroupSelectors"
import { CategoriesSelectors } from "../../redux/reducers/categories/categoriesSelectors"
import AdSearchInput from "../../library/inputs/AdSearchInput"
import AdDefaultInput from "../../library/inputs/AdDefaultInput"
import { SvgIcon } from "../../library/icons/SvgIcon"
import { actions as informActions } from "../../redux/reducers/informer"
import { actions as categoriesPageActions } from "../../redux/reducers/pages/categoryPage"
import { CategoriesPageSelector } from "../../redux/reducers/pages/categoryPage/categoriesPageSelector"
import { IOptionType } from "../common/basic/selectors/MultiSelect"
import { IWebCategoryType } from "../../domain/types"

import "./categoryGroupToolbar.scss"
import SimpleSelect from "../common/basic/selectors/SimpleSelect"

const toOption = (catalog: IWebCategoryType): IOptionType => {
    return {
        value: catalog.id,
        label: catalog.name
    }
}

export default function CategoryGroupToolbar(props: ICategoryGroupToolbarProps) {
    const shouldDisableControls = props.shouldDisableControls ?? false
    const selected = useSelector(CategoriesSelectors.getSelected)
    const newName = useSelector(CategoriesPageSelector.getNewCategoryName)
    const loadingState = useSelector(CategoriesSelectors.getLoadingState)


    const webCategories = useSelector(CategoriesSelectors.getWebCategories)
    const selectedWebCategory = useSelector(CategoriesSelectors.getSelectedWebCategory)

    const language = useSelector(LanguageSelectors.getSelected)
    const catalogGroup = useSelector(CatalogGroupSelectors.getSelected)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.clearToolbarState())
    }, [language.id, catalogGroup.id])

    function setFilter(filter: string) {
        dispatch(actions.setFilter(filter))
    }

    function setNewCategoryName(name: string) {
        dispatch(categoriesPageActions.setNewCategoryName(name))
    }

    function createCategory() {
        if (!newName || newName.trim() === "") {
            dispatch(informActions.inform("Введите наименование"))
            return
        }
        const parentId = selected ? selected.id : null
        dispatch(CategoriesComponentThunks.createCategory({
            languageId: language.id,
            name: newName,
            parentId: parentId,
            catalogGroup: catalogGroup.id
        }))
    }

    const changeSelected = (value: any) => {
        const category = webCategories.find(u => u.id == value)
        if (!category)
            return;

        dispatch(actions.setSelectedWebCategory(category))
    }

    return (
        <div className="cat-category-group-toolbar-container">
            <div className="category-group-toolbar-search">
                <AdSearchInput onChange={setFilter} />

                <SimpleSelect toOption={toOption}
                    options={webCategories}
                    className={"selector"}
                    onChange={newValue => changeSelected(newValue)}
                    value={selectedWebCategory} />
            </div>
            <div className="category-group-toolbar-create">
                <AdDefaultInput value={newName}
                    onChange={setNewCategoryName}
                    disabled={shouldDisableControls}
                    placeholder="Наименование новой категории" />
                <button onClick={() => createCategory()}
                    title="Добавить категорию"
                    type="button"
                    className="u-button square accent">
                    {
                        loadingState.isAddingCat
                            ? <SvgIcon name="refresh-cw" className="u-rotating" />
                            : <SvgIcon name="circle-plus" />

                    }
                </button>
            </div>
        </div>
    )
}

interface ICategoryGroupToolbarProps {
    shouldDisableControls?: boolean
}