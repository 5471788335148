import AdDefaultInput from "../inputs/AdDefaultInput"
import { SvgIcon } from "../icons/SvgIcon"
import _ from "lodash"
import "./adCharacteristics.scss"
import React, { useEffect, useState } from "react"
import { isEnter } from "../../utils/KeysCodes"

export default function AdCharacteristics(props: {
	sourceCharacteristics: Record<number, string>
	characteristics: Record<number, EditableValue>
	onChanged: (sort: number, value: string) => void
	setCharacteristicEditing: (sort: number) => void

	saveCharacteristic: (sort: number) => void
	createCharacteristic: (sort: number) => void
	deleteCharacteristics: (sort: number) => void

	onAddNewRow: () => void
	onDeleteNewRow: (sort: number) => void

	canEdit: boolean
	canAddNewRows: boolean
	canChangeProductCharacteristics: boolean
}) {
	const isAnyRowEditing = _.some(props.characteristics, (x) => x.isEditing)

	const [characteristicsAsText, setCharacteristicsAsText] = useState("")

	useEffect(() => {
		let str = ""
		for (const key in props.characteristics) {
			const item = props.characteristics[key]
			str += "• " + item.value + ". \r\n"
		}
		setCharacteristicsAsText(str)
	}, [props.characteristics])

	const onAddNewRow = () => {
		props.onAddNewRow()
	}

	const setCharacteristicEditing = (sort: number) => {
		props.setCharacteristicEditing(sort)
	}

	const onEditableRowKeyUp = (e: KeyboardEvent, sort: number) => {
		if (isEnter(e)) {
			saveCharacteristic(sort)
		}
	}

	const saveCharacteristic = (sort: number) => {
		if (
			Object.keys(props.sourceCharacteristics).includes(sort.toString())
		) {
			if (
				props.sourceCharacteristics[sort] ===
				props.characteristics[sort].value
			) {
				props.setCharacteristicEditing(sort)
				return
			}
			props.saveCharacteristic(sort)
		} else {
			props.createCharacteristic(sort)
		}
	}

	const deleteRow = (sort: number) => {
		if (
			Object.keys(props.sourceCharacteristics).includes(sort.toString())
		) {
			props.deleteCharacteristics(sort)
		} else {
			props.onDeleteNewRow(sort)
		}
	}

	return (
		<div
			className={"ad-characteristics u-flex-column"}
			style={{ gap: "0.625em" }}
		>
			<div className="u-droppod-section-header header-with-toolbar">
				Характеристики
				{/*<div className="u-toolbar">
					<div className="toolbar-row">
						<button className={"u-button large primary"}>
							<SvgIcon name={"close"} className={"link-icon"} />
							Очистить все
						</button>
					</div>
				</div>*/}
			</div>
			<>
				{Object.keys(props.characteristics).map((key) => {
					const sort = Number(key)
					const item = props.characteristics[sort]

					return (
						<div
							key={sort}
							className={"u-flex"}
							style={{ alignItems: "center", gap: "0.5em" }}
						>
							<AdDefaultInput
								key={key}
								disabled={
									!item.isEditing ||
									!props.canChangeProductCharacteristics
								}
								value={item.value}
								onKeyUp={(e) => onEditableRowKeyUp(e, sort)}
								onChange={(value) =>
									props.onChanged(sort, value)
								}
							/>

							{item.isEditing ? (
								<SvgIcon
									name={"circle-checked"}
									onClick={() => saveCharacteristic(sort)}
									className={"u-icon u-icon--green"}
									cursor={"pointer"}
								/>
							) : (
								<SvgIcon
									name={"pencil-create"}
									className={"u-icon u-icon--grey"}
									onClick={() => {
										setCharacteristicEditing(sort)
									}}
								/>
							)}

							{item.isEditing ? (
								<SvgIcon
									name={"close"}
									className={"u-icon u-icon--grey"}
									onClick={() => {
										deleteRow(sort)
									}}
									cursor={"pointer"}
								/>
							) : (
								<></>
							)}
						</div>
					)
				})}
			</>

			<div
				className="textarea-wrapper"
				style={{ marginRight: "calc(0.5em + var(--size-header-5))" }}
			>
				<div className="u-textarea">
					<textarea
						disabled={true}
						rows={4}
						value={characteristicsAsText}
					/>
				</div>
			</div>
			{props.canAddNewRows ? (
				<>
					<button
						className={"u-button accent large"}
						onClick={() => {
							onAddNewRow()
						}}
						disabled={
							isAnyRowEditing ||
							!props.canChangeProductCharacteristics
						}
						style={{
							marginRight: "calc(0.5em + var(--size-header-5))",
						}}
					>
						<SvgIcon name={"plus"} className={"link-icon"} />
						<>Добавить</>
					</button>
				</>
			) : null}
		</div>
	)
}

type EditableValue = {
	value: string
	isEditing: boolean
}
