import React from "react"
import { ReactComponent as AdditionalAnimation } from "../../assets/tags/T-AddAnim.svg"
import { ReactComponent as Ai } from "../../assets/tags/T-AI.svg"
import { ReactComponent as Animation } from "../../assets/tags/T-Anim.svg"
import { ReactComponent as Badge } from "../../assets/tags/T-Badge.svg"
import { ReactComponent as Cdr } from "../../assets/tags/T-CDR.svg"
import { ReactComponent as Certificate } from "../../assets/tags/T-Certificate.svg"
import { ReactComponent as Design } from "../../assets/tags/T-Design.svg"
import { ReactComponent as Doc } from "../../assets/tags/T-DOC.svg"
import { ReactComponent as File } from "../../assets/tags/T-File.svg"
import { ReactComponent as Final } from "../../assets/tags/T-Final.svg"
import { ReactComponent as Gif } from "../../assets/tags/T-GIF.svg"
import { ReactComponent as Image } from "../../assets/tags/T-Image.svg"
import { ReactComponent as Instruction } from "../../assets/tags/T-Instruction.svg"
import { ReactComponent as Jpg } from "../../assets/tags/T-JPG.svg"
import { ReactComponent as Link } from "../../assets/tags/T-Link.svg"
import { ReactComponent as Pack } from "../../assets/tags/T-Pack.svg"
import { ReactComponent as PackDis } from "../../assets/tags/T-PackDis.svg"
import { ReactComponent as PackImg } from "../../assets/tags/T-PackImg.svg"
import { ReactComponent as PackImg1 } from "../../assets/tags/T-PackImg1.svg"
import { ReactComponent as PackImg2 } from "../../assets/tags/T-PackImg2.svg"
import { ReactComponent as PackImg3 } from "../../assets/tags/T-PackImg3.svg"
import { ReactComponent as PackImg4 } from "../../assets/tags/T-PackImg4.svg"
import { ReactComponent as PackImg5 } from "../../assets/tags/T-PackImg5.svg"
import { ReactComponent as PDF } from "../../assets/tags/T-PDF.svg"
import { ReactComponent as Photo } from "../../assets/tags/T-Photo.svg"
import { ReactComponent as Photo1 } from "../../assets/tags/T-Photo1.svg"
import { ReactComponent as Photo2 } from "../../assets/tags/T-Photo2.svg"
import { ReactComponent as Photo3 } from "../../assets/tags/T-Photo3.svg"
import { ReactComponent as Photo4 } from "../../assets/tags/T-Photo4.svg"
import { ReactComponent as Drawing } from "../../assets/tags/T-Drawing.svg"
import { ReactComponent as Drawing1 } from "../../assets/tags/T-Drawing1.svg"
import { ReactComponent as PNG } from "../../assets/tags/T-PNG.svg"
import { ReactComponent as PSD } from "../../assets/tags/T-PSD.svg"
import { ReactComponent as Tasks } from "../../assets/tags/T-Tasks.svg"
import { ReactComponent as TextDoc } from "../../assets/tags/T-TextDoc.svg"
import { ReactComponent as Tiff } from "../../assets/tags/T-TIFF.svg"
import { ReactComponent as Txt } from "../../assets/tags/T-TXT.svg"
import { ReactComponent as Xls } from "../../assets/tags/T-XLS.svg"
import { ReactComponent as Youtube } from "../../assets/tags/T-Youtube.svg"

interface IAdeSvgProps extends React.SVGProps<SVGSVGElement> {
	name: string
}

const svgs = {
	"additional-animation": AdditionalAnimation,
	ai: Ai,
	animation: Animation,
	badge: Badge,
	cdr: Cdr,
	certificate: Certificate,
	design: Design,
	doc: Doc,
	file: File,
	final: Final,
	gif: Gif,
	image: Image,
	instruction: Instruction,
	jpg: Jpg,
	link: Link,
	pack: Pack,
	"pack-dis": PackDis,
	"pack-img": PackImg,
	"pack-img1": PackImg1,
	"pack-img2": PackImg2,
	"pack-img3": PackImg3,
	"pack-img4": PackImg4,
	"pack-img5": PackImg5,
	pdf: PDF,
	photo: Photo,
	photo1: Photo1,
	photo2: Photo2,
	photo3: Photo3,
	photo4: Photo4,
	drawing: Drawing,
	drawing1: Drawing1,
	png: PNG,
	psd: PSD,
	tasks: Tasks,
	"text-doc": TextDoc,
	tiff: Tiff,
	txt: Txt,
	xls: Xls,
	youtube: Youtube,
}

const SvgTag: React.FC<IAdeSvgProps> = ({ name, ...other }) => {
	return React.createElement(svgs[name], {
		className: " " + (other.className || ""),
		onClick: other.onClick,
		onMouseDown: (e) => e.preventDefault(),
		...other,
	})
}

export { SvgTag }
