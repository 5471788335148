import BaseApi from "../BaseApi"
import { IApplicationResponse } from "../baseTypes"
import { IEcmFileDescription } from "../../domain/models/files/EcmFileDescription"

export default class ProductFilesApi extends BaseApi {
	getProductFiles = async (data: {
		productId: string
	}): Promise<IApplicationResponse<IEcmFileDescription[]>> =>
		this.sendQuery<IEcmFileDescription[]>(
			"/api/product-files",
			data,
			"get",
			true
		)

	addProductFile = async (data: {
		path: string
		productId: string
		fileTypeId: number
	}): Promise<IApplicationResponse<IEcmFileDescription>> =>
		this.sendQuery<IEcmFileDescription>(
			"/api/product-files",
			data,
			"post",
			true
		)

	deleteProductFile = async (data: {
		productId: string
		fileId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>("/api/product-files", data, "delete", true)

	changeProductFileType = async (data: {
		productId: string
		files: IEcmFileDescription[]
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>("/api/product-files", data, "put", true)

	getFile = async (data: {
		productId: string
		fileId: number
	}): Promise<IApplicationResponse<void>> =>
		this.downloadFile("/api/product-files/file", data, true)

	getFiles = async (data: {
		productId: string
		subType: number
	}): Promise<IApplicationResponse<void>> =>
		this.downloadFile("/api/product-files/files", data, true)
}
