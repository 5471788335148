import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SvgIcon } from "../../../library/icons/SvgIcon"
import { ICatalogGroup, IWebCategoryType } from "../../../domain/types"
import SimpleSelect, { IOptionType } from "../../common/basic/selectors/SimpleSelect"
import AdDragAndDropBig from "../../../library/drapAndDrop/AdDragAndDropBig"
import { CategoriesComponentThunks } from "../../../redux/reducers/categories/thunk"
import { CategoriesSelectors } from "../../../redux/reducers/categories/categoriesSelectors"
import { ICategoryViewModel } from "../../../library/smartComponents/tables/productGroupTable/types"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API

export interface IUploadFileProps {
    category: ICategoryViewModel,
    catalogId: number
}

const toOption = (catalog: IWebCategoryType): IOptionType => {
    return {
        value: catalog.id,
        label: catalog.name
    }
}

export default function UploadFile(props: IUploadFileProps) {
    const webCategories = useSelector(CategoriesSelectors.getWebCategories)
    const selectedWebCategories = toOption(webCategories.find(u => u.id == props.catalogId)!)

    const itemImage = props.category.imageUrl[props.catalogId];
    const [file, setFile] = useState<File | null>(null)
	const dispatch = useDispatch()

    const addFile = (file) => {
        dispatch(
			CategoriesComponentThunks.addImage({
				imageName: file?.name ?? null,
				type: props.catalogId,
				categoryId: props.category.id
			})
		)
    }

    const deleteFile = () => {
        dispatch(
			CategoriesComponentThunks.deleteImage({
				type: props.catalogId,
				categoryId: props.category.id
			})
		)
    }

    return (<>
        <div className="item-image">
            <SimpleSelect
                onChange={(e) => e}
                toOption={(opt) => opt}
                options={webCategories.map(u => toOption(u))}
                className={"selector"}
                isDisabled={true}
                value={selectedWebCategories} />

            {!itemImage ? <AdDragAndDropBig onFileAdded={addFile}
                fileType={".png, .jpg, .jpeg"}
                onFileCleared={() => deleteFile()}
                file={file} className="view" />

                : <div className="view">
                    <img src={`${staticServer}/${itemImage}`} className="image" />
                    <div className="control">
                        <SvgIcon
                            name={"circle-x"}
                            className="remove-image"
                            onClick={() => deleteFile()}
                        />
                    </div>
                </div>
            }
        </div>

    </>)
}