import React, {useLayoutEffect, useRef, useState} from "react"
// import {useDispatch, useSelector} from "react-redux"
// import {actions} from "../../../../../redux/reducers/pages/productGroupPage"
import ProductAttributesBlock from "./productAttributes/ProductAttributesBlock"
import "./tablePartTab.scss"
import {useEffect} from "react"
import {IMountableProps} from "../../../../../redux/types"
// import {NewProductSelectors} from "../../../../../redux/reducers/pages/productGroupPage/newProductSelectors"
// import useAuth from "../../../../../hooks/authorizationHook"
// import {Role} from "../../../../../domain/Role"

export default function TablePartTab(props: IMountableProps) {

    useEffect(() => {
        props.onMount()
        window.addEventListener("resize", resize)

        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    const ref = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState(0)

    const [headerHeight, setHeaderHeight] = useState(0)

    useLayoutEffect(() => {
        setHeight(ref.current?.clientHeight || 0)
    }, [])

    function resize () {
        setHeight(ref.current?.clientHeight || 0)
    }

    return (
        //@ts-ignore
        <div id="products" className="u-droppod" ref={ref} style={{"--offset-height": height + "px", "--header-height": headerHeight + "px"}}>
            <ProductAttributesBlock setHeaderHeight={setHeaderHeight}/>
        </div>
    )
}