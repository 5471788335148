import React from "react"
import CategoryTree from "../../library/smartComponents/category/CategoryTree"
import CategoryGroupInTreeToolbar from "./CategoryGroupInTreeToolbar"

export default function CategoryFormInTree(props: ICategoryFormProps) {
    return <div className="cat-product-cat-list-container u-droppod">
        <div className="u-droppod-header">
            <div className="header-text">
                Категории
            </div>
        </div>
        <CategoryGroupInTreeToolbar/>
        <CategoryTree highlightedRows={props.highlightedCategories}/>
    </div>
}

interface ICategoryFormProps {
    highlightedCategories: number[],
}