import { IProductIdentity } from "../../../../../domain/models/products/ProductIdentity"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProductPageThunks } from "../thunks"
import { IEntitySimilarProduct } from "../../../../../domain/models/similarProducts/ISimilarProduct"
import _ from "lodash"

export type ProductPageSimilarProductsState = {
	products: IProductIdentity[]
	productSearch: string
	selectedProduct: IProductIdentity | null
	recommended: IProductIdentity[]
	isRecommendedLoading: boolean

	similar: IEntitySimilarProduct[]
}

const INITIAL_STATE: ProductPageSimilarProductsState = {
	products: [],
	productSearch: "",
	selectedProduct: null,
	recommended: [],
	isRecommendedLoading: false,

	similar: [],
}

const slice = createSlice({
	name: "productPageSimilarProducts",
	initialState: INITIAL_STATE,
	reducers: {
		setSimilarSelected: (state, { payload }: PayloadAction<string>) => {
			const currentSimilar = state.similar.find((x) => x.selected)
			if (currentSimilar) {
				currentSimilar.selected = false
			}
			const newSimilar = state.similar.find(
				(x) => x.similarId === payload
			)
			if (
				newSimilar &&
				newSimilar.similarId !== currentSimilar?.similarId
			) {
				newSimilar.selected = true
			}
		},
		setProductSearch: (state, { payload }: PayloadAction<string>) => {
			state.productSearch = payload
		},
		setSelectedProduct: (
			state,
			{ payload }: PayloadAction<string | null>
		) => {
			if (payload === null) {
				state.selectedProduct = null
				return
			}
			const product = state.products.find((x) => x.id === payload)
			if (product) {
				state.selectedProduct = product
			}
		},
		clearRecommended: (state) => {
			state.recommended = []
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ProductPageThunks.getSimilarProducts.fulfilled,
			(state, action) => {
				state.similar = action.payload.map((x) => ({
					...x,
					selected: false,
				}))
			}
		)
		builder.addCase(
			ProductPageThunks.getPossibleSimilarProducts.fulfilled,
			(state, action) => {
				const currentSimilarIds = state.similar.map((x) => x.similarId)
				state.products = action.payload.filter(
					(x) => !currentSimilarIds.includes(x.id)
				)
			}
		)
		builder.addCase(
			ProductPageThunks.getRecommendedSimilarProducts.pending,
			(state) => {
				state.isRecommendedLoading = true
			}
		)
		builder.addCase(
			ProductPageThunks.getRecommendedSimilarProducts.fulfilled,
			(state, { payload }) => {
				state.recommended = payload
				state.isRecommendedLoading = false
			}
		)
		builder.addCase(
			ProductPageThunks.getRecommendedSimilarProducts.rejected,
			(state) => {
				state.isRecommendedLoading = false
			}
		)
		builder.addCase(
			ProductPageThunks.addSimilarProducts.fulfilled,
			(
				state,
				{
					meta: {
						arg: { productId, similarProductsIds },
					},
				}
			) => {
				const products = state.products.filter((x) =>
					similarProductsIds.includes(x.id)
				)
				const maxSort = _.maxBy(state.similar, (x) => x.sort)?.sort || 0
				let sort = maxSort + 1

				for (const product of products) {
					state.similar.push({
						similarId: product.id,
						name: product.name,
						selected: false,
						sort: sort,
						productId: productId,
					})
					sort++
				}
				state.products = state.products.filter(
					(x) => !similarProductsIds.includes(x.id)
				)
				state.selectedProduct = null
			}
		)
		builder.addCase(
			ProductPageThunks.removeSimilarProduct.fulfilled,
			(
				state,
				{
					meta: {
						arg: { similarProductId },
					},
				}
			) => {
				const removedSimilar = state.similar.find(
					(x) => x.similarId === similarProductId
				)
				if (removedSimilar) {
					state.products.push({
						id: removedSimilar.similarId,
						name: removedSimilar.name,
						priceGroupId: 0,
					})
					state.similar = state.similar.filter(
						(x) => x.similarId !== similarProductId
					)

					for (const similarElement of state.similar) {
						if (similarElement.sort > removedSimilar.sort)
							similarElement.sort--
					}
				}
			}
		)
		builder.addCase(
			ProductPageThunks.swapSimilarProductSort.fulfilled,
			(
				state,
				{
					meta: {
						arg: { targetSimilarId, currentSimilarId },
					},
				}
			) => {
				const similar = state.similar.find(
					(x) => x.similarId === currentSimilarId
				)
				const targetSimilar = state.similar.find(
					(x) => x.similarId === targetSimilarId
				)
				if (similar && targetSimilar) {
					const temp = similar.sort
					similar.sort = targetSimilar.sort
					targetSimilar.sort = temp
				}
			}
		)
		builder.addCase(
			ProductPageThunks.replaceSimilarProduct.fulfilled,
			(
				state,
				{
					meta: {
						arg: { targetSimilarId, currentSimilarId },
					},
				}
			) => {
				const similar = state.similar.find(
					(x) => x.similarId === currentSimilarId
				)
				const target = state.products.find(
					(x) => x.id === targetSimilarId
				)
				if (similar && target) {
					const temp = _.clone(similar)

					similar.similarId = target.id
					similar.name = target.name

					target.id = temp.similarId
					target.name = temp.name
				}
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
