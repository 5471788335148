import {AppState} from "../../index"

export class UploadDataSelectors {
    static getProductGroupId = (state: AppState) => state.pages.uploadDataToWebsitePage.productGroupId.trim()
    static getGroup = (state: AppState) => state.pages.uploadDataToWebsitePage.productGroup
    static getIsGroupExists = (state: AppState) => state.pages.uploadDataToWebsitePage.isGroupExists
    static getShouldUpdateNotDeployed = (state: AppState) => state.pages.uploadDataToWebsitePage.shouldUpdateNotDeployedGroups
    static getNotReadyToDeployGroups = (state: AppState) => state.pages.uploadDataToWebsitePage.notReadyToDeployGroups
    static getIsGroupOnSite = (state: AppState) => state.pages.uploadDataToWebsitePage.isGroupOnSite
    static getIsShowGroupOnSite = (state: AppState) => state.pages.uploadDataToWebsitePage.isShowGroupOnSite
    static getSelectedCatalog = (state: AppState) => state.pages.uploadDataToWebsitePage.selectedCatalogOnSite
    static getSelectedWebsite = (state: AppState) => state.pages.uploadDataToWebsitePage.selectedWebsiteOnSite
    static getIsCatalogOnSiteOpen = (state: AppState) => state.pages.uploadDataToWebsitePage.isCatalogOnSiteOpen
    static getSelectedTable = (state: AppState) => state.pages.uploadDataToWebsitePage.selectedTable
    static getWebsiteTables = (state: AppState) => state.pages.uploadDataToWebsitePage.websiteTables
    static getFile = (state: AppState) => state.pages.uploadDataToWebsitePage.file
}