export class FileNameLabelProvider {
	static _imageTagLabels: Map<string, string>
	static _fileTagLabels: Map<string, string>

	static _ctor() {
		FileNameLabelProvider._imageTagLabels = new Map<string, string>()
		FileNameLabelProvider._imageTagLabels.set("jpeg", "jpg")
		FileNameLabelProvider._imageTagLabels.set("jpg", "jpg")
		FileNameLabelProvider._imageTagLabels.set("png", "png")

		FileNameLabelProvider._fileTagLabels = new Map<string, string>()
		FileNameLabelProvider._fileTagLabels.set("xls", "xls")
		FileNameLabelProvider._fileTagLabels.set("xlsx", "xls")
		FileNameLabelProvider._fileTagLabels.set("doc", "doc")
		FileNameLabelProvider._fileTagLabels.set("docx", "doc")
		FileNameLabelProvider._fileTagLabels.set("cdr", "cdr")
		FileNameLabelProvider._fileTagLabels.set("ai", "ai")
		FileNameLabelProvider._fileTagLabels.set("jpeg", "jpg")
		FileNameLabelProvider._fileTagLabels.set("jpg", "jpg")
		FileNameLabelProvider._fileTagLabels.set("png", "png")
		FileNameLabelProvider._fileTagLabels.set("tiff", "tiff")
		FileNameLabelProvider._fileTagLabels.set("pdf", "pdf")
		FileNameLabelProvider._fileTagLabels.set("psd", "psd")
		FileNameLabelProvider._fileTagLabels.set("gif", "gif")
		FileNameLabelProvider._fileTagLabels.set("txt", "txt")
	}

	static getImageLabelTagName(extension: string): string {
		if (!FileNameLabelProvider._imageTagLabels) {
			FileNameLabelProvider._ctor()
		}

		return FileNameLabelProvider._imageTagLabels.get(extension) ?? "jpg"
	}

	static getImageTypeTagName(imageType: number): string {
		switch (imageType) {
			case 1:
				return "photo"
			case 2:
				return "drawing"
			case 3:
				return "pack-img"
			case 4:
				return "drawing1"
			case 5:
				return "badge"
			case 6:
				return "pack-img1"
			case 9:
				return "photo1"
			case 10:
				return "photo2"
			case 11:
				return "photo3"
			case 12:
				return "photo4"
			case 13:
				return "animation"
			case 14:
				return "additional-animation"
			case 15:
				return "youtube"
		}
		throw new Error(`Unknown image type: ${imageType}`)
	}

	static getFileLabelTagName(extension: string): string {
		if (!FileNameLabelProvider._fileTagLabels) {
			FileNameLabelProvider._ctor()
		}

		const tagName = FileNameLabelProvider._fileTagLabels.get(extension)
		if (tagName) return tagName

		throw new Error(`Unknown file extension: ${extension}`)
	}

	/// <summary>
	/// Неполный список
	/// Returns the tag name for the given file type.
	/// </summary>
	static getFileTypeTagName(fileTypeId: number): string {
		switch (fileTypeId) {
			case 2:
				return "pack"
			case 3:
				throw "instruction"
			case 4:
				return "file"
			case 5:
				return "image"
			case 6:
				return "design"
			case 7:
				return "instruction"
			case 8:
				return "certificate"
			case 9:
				return "tasks"
			case 10:
				return "text-doc"
			case 11:
				return "certificate"
		}
		throw new Error(`Unknown file type: ${fileTypeId}`)
	}
}
