import {
	ICatalog,
	ILanguage,
	ITranslate,
	TranslateSource,
} from "../../../../domain/types"
import { IOptionType } from "../../../../app/common/basic/selectors/SimpleSelect"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BullfactsThunks } from "./thunks"
import Constants, {
	AdditionalTranslateKeys,
} from "../../../../domain/Constants"
import { AppState } from "../../index"

//чтобы отслеживать изменения в объектах, когда пользователь загружает переводы через deepl
export type Translate = ITranslate & { wasChangedByDeepl?: boolean }

export type BullfactState = {
	translates: Translate[]
	additionalInfoServerKeys: string[]
	search: string
	semanticSearch: string
	selectedLanguage: ILanguage
	selectedCatalog: ICatalog
	translateTypes: IOptionType[]
	selectedTranslateType: IOptionType
	translateSources: IOptionType[]
	selectedTranslateSource: IOptionType
	isSemanticSearchDisabled: boolean
	isLoading: boolean
}
const INITIAL_TRANSLATE_TYPES: IOptionType[] = [
	{ value: 0, label: "Все объекты" },
	{ value: 1, label: "Пустые объекты" },
]
const INITIAL_TRANSLATE_SOURCES: IOptionType[] = [
	{ value: 0, label: "Все разделы" },
	{ value: 1, label: "AttrNames" },
	{ value: 2, label: "CatKat" },
	{ value: 3, label: "CatWeb" },
	{ value: 4, label: "GroupName" },
	{ value: 5, label: "PictGroup" },
	{ value: 6, label: "PictGroupDesc" },
	{ value: 7, label: "ValueDescription" },
]

const SOURCES_FOR_TRANSLATES_WITHOUT_CATALOG = [
	{ value: 0, label: "Все разделы" },
]

const INITIAL_STATE: BullfactState = {
	translates: [],
	search: "",
	semanticSearch: "",
	additionalInfoServerKeys: [],
	selectedLanguage: { id: 1, name: "Английский" },
	selectedCatalog: { id: -1, name: "Загрузка", isPrinted: false },

	translateTypes: INITIAL_TRANSLATE_TYPES,
	selectedTranslateType: INITIAL_TRANSLATE_TYPES[0],

	translateSources: INITIAL_TRANSLATE_SOURCES,
	selectedTranslateSource: INITIAL_TRANSLATE_SOURCES[0],

	isSemanticSearchDisabled: false,
	isLoading: false,
}

const slice = createSlice({
	name: "bullfacts",
	initialState: INITIAL_STATE,
	reducers: {
		setIsSemanticSearch(state: BullfactState) {
			state.isSemanticSearchDisabled = !state.isSemanticSearchDisabled
		},
		setSearch(state: BullfactState, action: PayloadAction<string>) {
			state.search = action.payload
		},
		setSemanticSearch(state: BullfactState, action: PayloadAction<string>) {
			state.semanticSearch = action.payload
		},
		setSelectedCatalog(
			state: BullfactState,
			action: PayloadAction<ICatalog>
		) {
			const previous = state.selectedCatalog
			state.selectedCatalog = action.payload
			if (state.selectedCatalog.id === 0) {
				state.translateSources = SOURCES_FOR_TRANSLATES_WITHOUT_CATALOG
				state.selectedTranslateSource =
					SOURCES_FOR_TRANSLATES_WITHOUT_CATALOG[0]
			}
			if (previous.id === 0) {
				state.translateSources = INITIAL_TRANSLATE_SOURCES
				state.selectedTranslateSource = INITIAL_TRANSLATE_SOURCES[0]
			}
			state.translates = []
		},
		setLanguage(state: BullfactState, action: PayloadAction<ILanguage>) {
			state.selectedLanguage = action.payload
		},
		setTranslateType(state: BullfactState, action: PayloadAction<number>) {
			const translateType = state.translateTypes.find(
				(x) => x.value === action.payload
			)
			state.selectedTranslateType = translateType!
		},
		setTranslatedSource(
			state: BullfactState,
			action: PayloadAction<number>
		) {
			const translateSource = state.translateSources.find(
				(x) => x.value === action.payload
			)
			state.selectedTranslateSource = translateSource!
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			BullfactsThunks.translateValuesByTranslator.fulfilled,
			(state, action) => {
				const arg = action.meta.arg
				if (arg.values.isEmpty()) return
				const translate = state.translates.find(
					(x) => x.russian === arg.values.firstOrDefault()
				)
				if (!translate) return
				if (action.payload[0] !== null) {
					translate.translate = action.payload[0].translated
					translate.wasChangedByDeepl = !translate.wasChangedByDeepl
				}
			}
		)
		builder.addCase(BullfactsThunks.getTranslates.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(
			BullfactsThunks.getTranslates.fulfilled,
			(state, action) => {
				state.translates = []
				action.payload.map((x) => {
					const additionalInfo = {}
					if (x.additionalInfo)
						Object.keys(x.additionalInfo).forEach((key) => {
							additionalInfo[key] = x.additionalInfo![key]
						})
					state.translates.push({
						translateId: x.translateId,
						russian: x.russian,
						translate: x.translate,
						source: x.source,
						sourceId: x.sourceId,
						additionalInfo: additionalInfo,
					})
				})
				state.isLoading = false

				const empty = {}
				if (action.payload.length != 0) {
					const additionalInfo = action.payload[0].additionalInfo
					state.additionalInfoServerKeys = Object.keys(
						additionalInfo ?? empty
					)
				}
			}
		)
		builder.addCase(BullfactsThunks.getTranslates.rejected, (state) => {
			state.isLoading = false
		})
		builder.addCase(
			BullfactsThunks.updateTranslate.fulfilled,
			(state, action) => {
				const arg = action.meta.arg
				const translate = state.translates.find(
					(x) =>
						x.translateId === arg.translateId &&
						x.source === arg.translateSource &&
						x.sourceId === arg.sourceId
				)
				if (!translate) return
				translate.wasChangedByDeepl = !translate.wasChangedByDeepl
				translate.translate = action.payload
				if (state.selectedLanguage.id === Constants.RussianLanguage)
					translate.russian = action.payload
			}
		)
		builder.addCase(
			BullfactsThunks.updateAttributeShortname.fulfilled,
			(state, action) => {
				const arg = action.meta.arg
				const strAttributeId = arg.attributeId.toString()
				const translate = state.translates.find(
					(x) =>
						x.source === TranslateSource.AttrNames &&
						x.sourceId === strAttributeId
				)
				if (!translate) return
				translate.additionalInfo![
					AdditionalTranslateKeys.AttributeShortname
				] = arg.shortname
			}
		)
		builder.addCase(
			BullfactsThunks.updateAttributeUnit.fulfilled,
			(state, action) => {
				const arg = action.meta.arg
				const strAttributeId = arg.attributeId.toString()
				const translate = state.translates.find(
					(x) =>
						x.source === TranslateSource.AttrNames &&
						x.sourceId === strAttributeId
				)
				if (!translate) return
				translate.additionalInfo![
					AdditionalTranslateKeys.AttributeUnit
				] = arg.unit
			}
		)
	},
})

export class BullfactsSelectors {
	static getTranslates = (state: AppState) =>
		state.pages.bullfactsPage.translates
	static getSearch = (state: AppState) => state.pages.bullfactsPage.search
	static getSemanticSearch = (state: AppState) =>
		state.pages.bullfactsPage.semanticSearch
	static getSelectedLanguage = (state: AppState) =>
		state.pages.bullfactsPage.selectedLanguage
	static getSelectedCatalog = (state: AppState) =>
		state.pages.bullfactsPage.selectedCatalog
	static getTranslateTypes = (state: AppState) =>
		state.pages.bullfactsPage.translateTypes
	static getSelectedTranslateType = (state: AppState) =>
		state.pages.bullfactsPage.selectedTranslateType
	static getIsSemanticSearchDisabled = (state: AppState) =>
		state.pages.bullfactsPage.isSemanticSearchDisabled
	static getTranslateSources = (state: AppState) =>
		state.pages.bullfactsPage.translateSources
	static getPossibleTranslateSources = () => INITIAL_TRANSLATE_SOURCES
	static getSelectedTranslateSource = (state: AppState) =>
		state.pages.bullfactsPage.selectedTranslateSource
	static getIsLoading = (state: AppState) =>
		state.pages.bullfactsPage.isLoading
	static getShouldShowAdditionalInfo = (state: AppState) =>
		objectsHaveSameKeys(
			Object.values(state.pages.bullfactsPage.translates)
				.map((x) => x.additionalInfo)
				.filter((x) => x !== null && x !== undefined)
		)

	static getAdditionalInfoServerKeys = (state: AppState) =>
		state.pages.bullfactsPage.additionalInfoServerKeys
}

function objectsHaveSameKeys(...objects) {
	const allKeys = objects.reduce(
		(keys, object) => keys.concat(Object.keys(object)),
		[]
	)
	const union = new Set(allKeys)
	return objects.every((object) => union.size === Object.keys(object).length)
}

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
