import React from "react"
import RouteProvider from "../../utils/RouteProvider"
import Protector from "../../library/smartComponents/authorization/Protector"
import Statistic from "./Statistic"
import "./watchtower.scss"
import axios from "axios"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import { useDispatch, useSelector } from "react-redux"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"
import { actions as loaderActions } from "../../redux/reducers/informer"
import { ModalStatus } from "../../library/modal/AdResultModal"

export default function Watchtower() {
	const [logs, setLogs] = React.useState("")
	const website = useSelector(WebsiteSelectors.getSelected)
	const dispatch = useDispatch()

	const loadSiteDiff = async () => {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.loading,
				text: "Loading",
			})
		)

		axios
			.get("/api/test/find-problems", {
				timeout: 1800 * 1000,
			})
			.then(
				(response) => {
					setLogs(response.data)
				},
				(rejected) => {
					setLogs("Error " + rejected)
				}
			)
			.catch((error) => {
				setLogs("Error " + error)
			})
			.finally(() => {
				dispatch(loaderActions.closeLoader())
			})
	}

	const updateOurSiteIds = async () => {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.loading,
				text: "Loading",
			})
		)

		axios
			.get("/api/test/update-our-db-from-lv", {
				timeout: 1800 * 1000,
			})
			.then(
				(response) => {
					setLogs(response.data)
				},
				(rejected) => {
					setLogs("Error " + rejected)
				}
			)
			.catch((error) => {
				setLogs("Error " + error)
			})
			.finally(() => {
				dispatch(loaderActions.closeLoader())
			})
	}

	const updateSiteIdsOnSite = async () => {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.loading,
				text: "Loading",
			})
		)

		axios
			.get("/api/test/update-site-id-on-site/" + website.id, {
				timeout: 1800 * 1000,
			})
			.then(
				(response) => {
					setLogs(response.data)
				},
				(rejected) => {
					setLogs("Error " + rejected)
				}
			)
			.catch((error) => {
				setLogs("Error " + error)
			})
			.finally(() => {
				dispatch(loaderActions.closeLoader())
			})
	}

	const getModels = async () => {
		dispatch(
			loaderActions.showLoader({
				status: ModalStatus.loading,
				text: "Loading",
			})
		)

		axios
			.get("/api/test/get-models/" + website.id, {
				timeout: 1800 * 1000,
			})
			.then(
				(response) => {
					setLogs(response.data)
				},
				(rejected) => {
					setLogs("Error " + rejected)
				}
			)
			.catch((error) => {
				setLogs("Error " + error)
			})
			.finally(() => {
				dispatch(loaderActions.closeLoader())
			})
	}

	return (
		<Protector availableRoles={RouteProvider.Watchtower.roles!}>
			<div className="u-droppod watchtower-droppod">
				<div className="u-droppod-header">
					<div className="header-text">
						{RouteProvider.Watchtower.ruName}
					</div>
				</div>
				<Statistic />
				<div style={{ display: "flex" }}>
					<button onClick={() => loadSiteDiff()}>
						LoadDiff on sites
					</button>
					<button onClick={() => getModels()}>Get models</button>

					<button onClick={() => updateOurSiteIds()}>
						UPDATE OUR SITE IDS FROM LV
					</button>

					<WebsiteSelector />
					<button onClick={() => updateSiteIdsOnSite()}>
						UPDATE SITE IDS ON SITE {website.name}
					</button>
				</div>

				<div>LOGS</div>
				<div style={{ whiteSpace: "pre-line" }}>{logs}</div>
			</div>
		</Protector>
	)
}
