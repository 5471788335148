import { IPictogram, IToolsetProduct } from "../../../../../../domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ToolsetComponentThunks } from "./thunks"
import { IProductIdentity } from "../../../../../../domain/types"
import QueryTrimmer from "../../../../../../utils/QueryTrimmer"
import _ from "lodash"

const queryTrimmer = new QueryTrimmer()

export type ToolsetState = {
	isToolsetProductsLoading: boolean
	toolsetProducts: IToolsetProduct[]
	selectedToolsetProduct: IToolsetProduct | null

	pictograms: IPictogram[]
	selectedPictogram: IPictogram | null

	products: IProductIdentity[]
	selectedProduct: IProductIdentity | null
	article: string
	targetSort: string
}

const INITIAL_STATE: ToolsetState = {
	isToolsetProductsLoading: false,
	toolsetProducts: [],
	selectedToolsetProduct: null,

	pictograms: [],
	selectedPictogram: null,

	products: [],
	selectedProduct: null,
	article: "",
	targetSort: "",
}

const slice = createSlice({
	name: "toolsets",
	initialState: INITIAL_STATE,
	reducers: {
		setSelectedPossibleToolsetProduct(
			state: ToolsetState,
			action: PayloadAction<string | null>
		) {
			if (action.payload === null) state.selectedProduct = null
			else
				state.selectedProduct =
					state.products.find((x) => x.id === action.payload) ?? null
		},
		setSelectedProduct(
			state: ToolsetState,
			action: PayloadAction<string | null>
		) {
			if (action.payload === null) state.selectedProduct = null
			else
				state.selectedProduct =
					state.products.find((x) => x.id === action.payload) ?? null
		},
		setSelectedPictogram(
			state: ToolsetState,
			action: PayloadAction<number | null>
		) {
			if (action.payload === null) state.selectedPictogram = null
			else
				state.selectedPictogram =
					state.pictograms.find((x) => x.id === action.payload) ??
					null
		},
		setSelectedToolsetProduct(
			state: ToolsetState,
			action: PayloadAction<string | null>
		) {
			if (action.payload === null) state.selectedToolsetProduct = null
			else
				state.selectedToolsetProduct =
					state.toolsetProducts.find(
						(x) => x.productId === action.payload
					) ?? null
		},
		setArticle(state: ToolsetState, action: PayloadAction<string>) {
			state.article = action.payload
		},
		setNewSort(state: ToolsetState, action: PayloadAction<string>) {
			state.targetSort = action.payload
		},
		setToolsetProductsEmpty(state: ToolsetState) {
			state.toolsetProducts = []
		},
		clearStateOnUnmount() {
			return INITIAL_STATE
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			ToolsetComponentThunks.getPossibleToolsetProducts.fulfilled,
			(state, action) => {
				state.products = _.orderBy(action.payload, (x) => x.name)
				state.selectedProduct = null
			}
		)

		builder.addCase(
			ToolsetComponentThunks.getPriceGroupProducts.fulfilled,
			(state, action) => {
				state.products = _.orderBy(action.payload, (x) => x.name)
			}
		)

		builder.addCase(
			ToolsetComponentThunks.getToolsetProducts.pending,
			(state, action) => {
				queryTrimmer.addQuery(action.meta.requestId)
				state.isToolsetProductsLoading = true
				state.toolsetProducts = []
				state.selectedToolsetProduct = null
				state.targetSort = ""
				state.article = ""
			}
		)

		builder.addCase(
			ToolsetComponentThunks.getToolsetProducts.fulfilled,
			(state, action) => {
				if (queryTrimmer.shouldBeProcessed(action.meta.requestId)) {
					state.toolsetProducts = action.payload.map((x) => {
						return {
							name: x.name,
							sort: x.sort,
							productId: x.productId,
							pictogramName: x.pictogramName,
							pictogramId: x.pictogramId,
							newIdentifier: x.productId,
						}
					})
					state.isToolsetProductsLoading = false
				}
			}
		)
		builder.addCase(
			ToolsetComponentThunks.getPictograms.fulfilled,
			(state, action) => {
				state.pictograms = action.payload
			}
		)

		builder.addCase(
			ToolsetComponentThunks.addProductsToToolset.pending,
			(state, action) => {
				let maxSort = 0
				for (const toolsetProducts of state.toolsetProducts) {
					if (toolsetProducts.sort > maxSort)
						maxSort = toolsetProducts.sort
				}
				action.meta.arg.products.forEach((x) => {
					const product = state.products.find(
						(p) => p.id === x.productId
					)!
					const pictName =
						x.pictogramId !== null
							? state.pictograms.find(
									(p) => p.id === x.pictogramId
							  )!.name
							: "-"
					maxSort += 1
					state.toolsetProducts.push({
						name: product.name,
						sort: maxSort,
						productId: product.id,
						pictogramId: x.pictogramId,
						pictogramName: pictName,
					})
				})
			}
		)

		builder.addCase(
			ToolsetComponentThunks.addProductsToToolset.rejected,
			(state, action) => {
				state.toolsetProducts = state.toolsetProducts.filter(
					(x) =>
						action.meta.arg.products.findIndex(
							(p) => p.productId === x.productId
						) === -1
				)
				state.selectedToolsetProduct = null
			}
		)

		builder.addCase(
			ToolsetComponentThunks.changeAssignment.pending,
			(state, action) => {
				const product = state.products.find(
					(x) => action.meta.arg.anotherProductId === x.id
				)!
				const toolsetProduct = state.toolsetProducts.find(
					(x) => x.productId === action.meta.arg.productId
				)!

				toolsetProduct.productId = product.id
				toolsetProduct.name = product.name
				toolsetProduct.pictogramId = null
				toolsetProduct.pictogramName = ""

				state.selectedToolsetProduct = toolsetProduct
			}
		)

		builder.addCase(
			ToolsetComponentThunks.removeProductFromToolset.pending,
			(state, action) => {
				const index = state.toolsetProducts.findIndex(
					(x) => x.productId === action.meta.arg.productId
				)
				const toolsetProduct = state.toolsetProducts[index]
				state.toolsetProducts.forEach((x) => {
					if (x.sort > toolsetProduct.sort) x.sort = x.sort - 1
				})
				state.toolsetProducts.splice(index, 1)
			}
		)

		builder.addCase(
			ToolsetComponentThunks.swapSort.pending,
			(state, action) => {
				const first = state.toolsetProducts.find(
					(x) => x.productId === action.meta.arg.productId
				)!
				const second = state.toolsetProducts.find(
					(x) => x.sort === action.meta.arg.targetSort
				)!
				if (second !== undefined) {
					const temp = first.sort
					first.sort = second.sort
					second.sort = temp
				} else {
					first.sort = action.meta.arg.targetSort
				}
				state.selectedToolsetProduct!.sort = first.sort
			}
		)

		builder.addCase(
			ToolsetComponentThunks.changePictGroup.pending,
			(state, action) => {
				const first = state.toolsetProducts.find(
					(x) => x.productId === action.meta.arg.productId
				)!
				const pictogram = state.pictograms.find(
					(x) => x.id === action.meta.arg.pictogramId
				)!
				first.pictogramId = pictogram.id
				first.pictogramName = pictogram.name
			}
		)
	},
})

const actions = slice.actions
const reducer = slice.reducer

export { actions, reducer }
