import { createSelector } from "@reduxjs/toolkit"
import { AuthSelectors } from "../../auth/authSelectors"
import { getGroupMembership, GroupMembership } from "../../../../domain/GroupMembership"
import { AppState } from "../../index"

export class ToolsetProductPageAuthSelectors {
    static canAccess = createSelector([
        AuthSelectors.isContentLv,
        AuthSelectors.isContentRg,
        AuthSelectors.isContentRf,
        (state: AppState) => state.pages.toolsetProductsPage.productGroup
    ], (isContentLv, isContentRg, isContentRf, group) => {
        const groupMembership = getGroupMembership(group?.priceGroupId ?? null)

        let canAccess = false
        switch (groupMembership) {
            case GroupMembership.Russian:
                canAccess = isContentRf
                break
            case GroupMembership.Latvian:
                canAccess = isContentLv || isContentRg
                break
        }

        return canAccess

    })
}